import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import TopicalDashboard from '../../Components/Topical/TopicalDashboard'

const TopicalPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer visible={false}  header={"Güncel"}  >
                <TopicalDashboard />
            </TablePageContainer>
        </div>
    )
}

export default TopicalPage