import { Button, DatePicker, Input, Select, Table, TimePicker, Modal as AntModal } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from "styled-components"
import { formatDate } from "date-fns"
import trLocale from "date-fns/locale/tr"
import dayjs from 'dayjs'
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'
import { FaTrash } from 'react-icons/fa'
import { IoReload } from 'react-icons/io5'
import { UserContext } from '../../context/user'

const MeetingDashboard = () => {
    const [context, dispatch] = useContext(UserContext)
    const [modalVisible, setModalVisible] = useState({
        visible: false
    })
    const [activeType, setActiveType] = useState(0)
    const [selectedProgramData, setSelectedProgramData] = useState({
        startTime: new Date(),
        duration: 0,
        id: ""
    })
    const [loading, setLoading] = useState(false)
    const [calendarList, setCalendarList] = useState([])

  
    const isAdmin = useMemo(() => {
        return context?.role == "Süper Admin"
    }, [context])

    const addMeeting = async () => {
        try {
            setLoading(true)
            await axiosInstance.post("/meetings/add", {
                calendar: new Date(selectedProgramData.startTime).toUTCString(),
                duration: selectedProgramData?.duration
            })
            toast.success("Toplantı oluşturuldu", {
                position: "top-center",
                autoClose: 2000
            })
            setModalVisible({
                visible: false
            })
            setSelectedProgramData({
                duration: 0,
                id: "",
                startTime: new Date()
            })

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const formatDateTr = (date) => {
        return formatDate(new Date(date), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })
    }

    const getCalendars = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/meetings/getAll", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/meetings/getAll", {
                page: 0,
                pageSize: response.data?.count
            })
            setCalendarList(response2.data?.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const startMeeting = async (id) => {
        try {
            setLoading(true)
            await axiosInstance.put("/meetings/start", {
                id: id
            })
            toast.success("Toplantı aktif edildi", {
                position: "top-center",
                autoClose: 2000
            })
            await getCalendars()
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const calendarData = useMemo(() => {
        if (activeType == 0) {
            const ileriTarih = filtreleIleriTarihler(0)
            return ileriTarih
        }
        else if (activeType == 1) {
            const gecmis = filtreleIleriTarihler(1)
            return gecmis
        }
        else {
            const active = filtreleIleriTarihler(2)
            return active
        }
    }, [activeType, calendarList])


    function filtreleIleriTarihler(status = 0) {
        if (status == 0) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = calendarList?.filter(function (course) {
                var kursBaslangicTarihi = new Date(course?.calendar); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a?.calendar) - new Date(b?.calendar);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = calendarList.filter(function (course) {
                const mainEndTime = new Date(course?.calendar)
                const endTime = new Date(mainEndTime.getTime() + course?.duration * 60000)
                return endTime < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b?.calendar) - new Date(a?.calendar);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = calendarList?.filter(function (course) {
                const mainEndTime = new Date(course?.calendar)
                const endTime = new Date(mainEndTime?.getTime() + course?.duration * 60000)
                var kursBaslangicTarihi = new Date(course?.calendar); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < endTime; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a?.calendar) - suankiTarih) - Math.abs(new Date(b?.calendar) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }

    const joinLink = (url, id) => {
        if (activeType == 1) {
            if (url == "Zaman Bekleniyor.") {
                return (
                    <Button danger type='primary' style={{
                        pointerEvents: "none"
                    }} >
                        Toplantı Yapılmadı
                    </Button>
                )
            }
            else {
                return (
                    <Button type='primary' style={{
                        pointerEvents: "none",
                        backgroundColor: "#24D17B"
                    }} >
                        Toplantı Yapıldı
                    </Button>
                )
            }

        }
        else if (activeType == 0) {

            return (
                <Button loading={loading} type='primary'  >
                    Zaman Bekleniyor
                </Button>
            )


        }
        else {
            if (url == "Zaman Bekleniyor.") {
                if (isAdmin) {
                    return (
                        <Button loading={loading} type='primary' onClick={() => {
                            startMeeting(id)
                        }} >
                            Oluştur
                        </Button>
                    )
                }
                else {
                    <Button loading={loading} type='primary' style={{
                        cursor: "not-allowed"
                    }}  >
                        Yöneticinin toplantıyı başlatması bekleniyor.
                    </Button>
                }
            }
            else {
                return (
                    <Button type='primary' onClick={() => {
                        window.open(url, "_blank")
                    }} >
                        Katıl
                    </Button>
                )
            }
        }
    }


    const disableRemoveBtn = (url) => {
        if (activeType == 2) {
            return true
        }
        else if (activeType == 1) {
            if (url == "Zaman Bekleniyor.") {
                return false
            }
            return true
        }
        else {
            return false
        }
    }


    useEffect(() => {
        getCalendars()
    }, [])


    return (
        <StyledContainer>
            <StyledHeader>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px"
                }} >
                    <AntSelect
                        value={activeType}
                        onChange={(e) => {
                            setActiveType(e)
                        }}
                        options={[
                            {
                                "label": "Bekleyen",
                                "value": 0
                            },
                            {
                                "label": "Geçmiş",
                                "value": 1
                            },
                            {
                                "label": "Aktif",
                                "value": 2
                            }
                        ]}
                    />
                    <Button loading={loading} type='primary' onClick={() => {
                        getCalendars()
                    }} style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px"
                    }} >
                        <IoReload /> Yenile
                    </Button>
                </div>
                {
                    isAdmin && (
                        <Button type='primary' onClick={() => {
                            setModalVisible({
                                visible: true
                            })
                        }} >
                            Toplantı Oluştur
                        </Button>
                    )
                }


            </StyledHeader>


            <StyledTableContainer>
                <Table
                    loading={loading}
                    scroll={{
                        x: 600
                    }}
                    pagination={{
                        pageSize: 10
                    }}
                    dataSource={calendarData}
                    columns={[
                        {
                            title: "Başlangıç Saati",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {formatDateTr(record?.calendar)}
                                    </p>
                                )
                            },
                            sorter: (a, b) => new Date(a.calendar) - new Date(b.calendar)
                        },
                        {
                            title: "Toplantı Süresi",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.duration}(Dk)
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Durum",
                            render: (row, record) => {
                                return joinLink(record?.url, record?.id)
                            }
                        },
                        {
                            title: "İşlem",
                            hidden: !isAdmin,
                            render: (row, record) => {
                                return (
                                    <Button disabled={
                                        disableRemoveBtn(record?.url)
                                    } loading={loading} danger type='primary' onClick={() => {
                                        AntModal.confirm({
                                            title: "Silmek istediğinize emin misiniz ?",
                                            okText: "Eminim",
                                            cancelText: "İptal",
                                            onOk: async () => {
                                                try {
                                                    setLoading(true)
                                                    await axiosInstance.delete(`/meetings/delete/${record?.id}`)

                                                    toast.success("Program silindi", {
                                                        autoClose: 2000,
                                                        position: "top-center"
                                                    })
                                                    getCalendars()
                                                }
                                                catch (err) {

                                                }
                                                finally {
                                                    setLoading(false)
                                                }
                                            }
                                        })
                                    }}  >
                                        <FaTrash />
                                    </Button>
                                )
                            }
                        }
                    ]}
                />
            </StyledTableContainer>

            <Modal isOpen={modalVisible.visible} size='md'  >
                <ModalHeader>
                    Toplantı Programı
                </ModalHeader>
                <ModalBody>
                    <ModalBodyContent>

                        <div style={{ width: "100%" }} >

                            <div className="mb-3">
                                <Label className="form-label">
                                    Başlangıç Saati
                                </Label>
                                <div>
                                    <DatePicker
                                        style={{
                                            width: "100%",
                                            padding: "8px"
                                        }}
                                        placeholder='Başlangıç saati seçiniz'
                                        allowClear={false}
                                        showTime
                                        value={selectedProgramData.startTime == "12:00" ? dayjs(new Date()) : dayjs(selectedProgramData.startTime)}
                                        onChange={(value, dateString) => {
                                            setSelectedProgramData({
                                                ...selectedProgramData,
                                                startTime: value
                                            })
                                            /*   formik.setFieldValue("startDate", value) */
                                        }}

                                    />
                                </div>
                            </div>
                            <div className="">
                                <Label className="form-label">
                                    Canlı yayın süresi
                                </Label>
                                <div>
                                    <Input style={{ marginLeft: 0, height: "35px" }} type='number' value={selectedProgramData.duration} onChange={(e) => {
                                        setSelectedProgramData({
                                            ...selectedProgramData,
                                            duration: e.target.value
                                        })
                                    }}
                                        onBlur={(e) => {
                                            e.target.focus()
                                        }}
                                    />

                                </div>


                            </div>

                        </div>
                    </ModalBodyContent>
                </ModalBody>
                <ModalFooter>
                    <StyledAntButton loading={loading} type='primary' disabled={
                        selectedProgramData.duration < 1 || selectedProgramData.duration > 60
                    } onClick={() => {
                        addMeeting()
                    }} >
                        Onayla
                    </StyledAntButton>
                    <StyledAntButton danger type='primary' onClick={() => {
                        setModalVisible({
                            visible: false
                        })
                        setSelectedProgramData({
                            day: "Pazartesi",
                            startTime: "12:00",
                            duration: 0,
                            id: ""
                        })
                    }} >
                        İptal
                    </StyledAntButton>
                </ModalFooter>
            </Modal>
        </StyledContainer>
    )
}

export default MeetingDashboard



const ModalBodyContent = styled.div`
display: flex;

flex-direction: column;
`

const StyledAntButton = styled(Button)`

`


const SelectAddProgramType = styled.div`
display: grid;
width: 100%;
grid-template-columns: 1fr 1fr;
column-gap: 15px;
`

const SelectProgramItem = styled.div`
    padding:  15px;
    border-radius: 10px;
    cursor: pointer;
    color : ${props => props.code == addProgramType ? "white" : "black"};
    background-color: ${props => addProgramType == props.code ? "#088F7D" : "#efefef"};

`

const StyledContainer = styled.div`
    padding: 10px;
`

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
    border-bottom: 1px dashed gray;
`

const StyledTableContainer = styled.div`
    padding-top: 20px;
`

const AntSelect = styled(Select)`
        width: 200px;

        @media screen  and (max-width:550px){
            width: 100%;
      
        }
    `