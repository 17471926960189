import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { FcStatistics } from "react-icons/fc";
import { axiosInstance } from '../../api/axiosInstance';
import { Col, Row, Select, Skeleton } from 'antd';
import ReactEcharts from 'echarts-for-react';
const AdminStatisticDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const [selectedSemester, setSelectedSemester] = useState("Güz")
    const [branchLoading, setBranchLoading] = useState(false)
    const [branchList, setBranchList] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(0)
    const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear())
    const [statisticData, setStatisticData] = useState({
        "totalRegisterCount": "",
        "totalLimitCount": "",
        "totalCourseRegisterCount": "",
        "totalCourseRegisterDeleteCount": ""
    })
    const [selectedCourse, setSelectedCourse] = useState()
    const [barCharData, setBarChartData] = useState({
        "Branş Bazında Dönemsel Kayıtlı Öğrenci Sayısı:": 0,
        "Branş Bazında Dönemsel Kayıtlı Erkek Öğrenci Sayısı:": 0,
        "Branş Bazında Dönemsel Kayıtlı Kadın Öğrenci Sayısı:": 0,
        "Dönemsel Kurslara Kayıt Sayısı:": 0,
        "Dönemsel Kurslara Erkek Kayıt Sayısı:": 0,
        "Dönemsel Kurslara Kadın Kayıt Sayısı:": 0,
        "Toplam Sertifika Kazanan Sayısı::": 0,
        "Dönemsel Toplam Açılan Sınıf Sayısı:": 0,
        "Dönemsel Toplam Açılan Branş Sayısı:": 0,
        "Dönemsel Toplam Kategori Sayısı:": 0,
        "Devamsızlıktan Kalan Öğrenci Sayısı: ": 0
    })

    const getAllCourseList = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/courses/GetAll?type=Online&status=0", {
                page: 0,
                pageSize: 5
            })
            const activeCourseList = await axiosInstance.post("/courses/GetAll?type=Online&status=0", {
                page: 0,
                pageSize: response.data.count
            })
            const result = [...activeCourseList.data.items]
            setCourseList(result)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getStatistic = async (id = 0) => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/Users/GetStatistics/${id}`)

            setStatisticData(response.data)
        }
        catch (err) {


        }
        finally {
            setLoading(false)

        }
    }

    const yearList = useMemo(() => {
        const year = new Date().getUTCFullYear()
        const result = [year]
        for (let i = 1; i < 3; i++) {
            result.push(year + i)
        }
        return result
    }, [])


    const getAllBranch = async () => {
        try {
            setBranchLoading(true)

            const responsev1 = await axiosInstance.post("/branches/getAll?type=Online", {
                page: 0,
                pageSize: 1
            })
            const responsev2 = await axiosInstance.post("/branches/getAll?type=Online", {
                page: 0,
                pageSize: responsev1.data.count
            })
            setBranchList(responsev2.data.items)
        }
        catch (err) {

        }
        finally {
            setBranchLoading(false)
        }
    }

    const selectedCourseName = useMemo(() => {
        let course = courseList?.find(el => el?.id == selectedCourse)
        return course?.name
    }, [selectedCourse, courseList])


    const getChartData = async (semester, branchId, year) => {
        try {
            setBranchLoading(true)

            const response = await axiosInstance.post("/users/getChart", {
                type: semester,
                branchId: branchId,
                year: year
            })
            setBarChartData(response.data)
        }
        catch (err) {

        }
        finally {
            setBranchLoading(false)
        }
    }



    useEffect(() => {
        getAllCourseList()
        getStatistic()
        getAllBranch()
        getChartData("Güz", 0, new Date().getUTCFullYear())
    }, [])


    const firstChartData = useMemo(() => {
        return [
            barCharData['Dönemsel Kurslara Kayıt Sayısı:'],
            barCharData["Dönemsel Kurslara Erkek Kayıt Sayısı:"],
            barCharData["Dönemsel Kurslara Kadın Kayıt Sayısı:"],

        ]
    }, [barCharData])


    const secondChartData = useMemo(() => {
        return [
            barCharData["Branş Bazında Dönemsel Kayıtlı Öğrenci Sayısı:"],
            barCharData["Branş Bazında Dönemsel Kayıtlı Erkek Öğrenci Sayısı:"],
            barCharData["Branş Bazında Dönemsel Kayıtlı Kadın Öğrenci Sayısı:"]
        ]

    }, [barCharData])

    const thirdChartData = useMemo(() => {
        console.log("barChartData", barCharData)
        return [
            barCharData["Dönemsel Toplam Açılan Sınıf Sayısı:"],
            barCharData["Dönemsel Toplam Açılan Branş Sayısı:"],
            barCharData["Dönemsel Toplam Kategori Sayısı:"],
            barCharData["Toplam Sertifika Kazanan Sayısı::"],
            barCharData["Devamsızlıktan Kalan Öğrenci Sayısı: "]
        ]
    }, [barCharData])

    const option1 = {
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ["Toplam Kayıt", "Erkek", "Kadın"],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: ["#24D198", "#EAFBF5"],
        tooltip: {
            trigger: 'axis', // Verilerin eksen üstünde görünmesi için
            axisPointer: {
                type: 'shadow' // Barlar için gölge göstergesi
            }
        },
        series: [{
            data: firstChartData,
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };


    const option2 = {
        title: {
            text: 'Seçim yapılmadı', // Grafiğin başlığı
            top: 'center', // Başlığı ortalamak için
            left: "center",
            show: selectedBranch == 0 ? true : false,
            textStyle: {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 16,
                fontWeight: 'bold',
                color: '#333' // Başlık rengi
            }
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ["Toplam Kayıt", "Erkek", "Kadın"],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: ["#24D198", "#EAFBF5"],
        tooltip: {
            trigger: 'axis', // Verilerin eksen üstünde görünmesi için
            axisPointer: {
                type: 'shadow' // Barlar için gölge göstergesi
            }
        },
        series: [{
            data: secondChartData,
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };




    const option3 = {
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true,

        },
        xAxis: {
            type: 'category',
            data: ["Toplam Sınıf", "Toplam Branş", "Toplam Kategori", "Toplam Sertifika", "Devamsızlık"],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: ["#24D198", "#EAFBF5"],
        tooltip: {
            trigger: 'axis', // Verilerin eksen üstünde görünmesi için
            axisPointer: {
                type: 'shadow' // Barlar için gölge göstergesi
            }
        },
        series: [{
            data: thirdChartData,
            type: 'bar',
            showBackground: false,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };


    return (
        <StyledContainer>
            <StyledHeader>
                <StyledSelect
                    loading={loading}
                    placeholder="Kurs seçiniz"
                    showSearch
                    options={courseList?.map((item) => {
                        return {
                            "label": `${item?.name}-${item?.id}`,
                            "value": `${item?.id}`
                        }
                    })}
                    onChange={(e) => {
                        setSelectedCourse(e)
                        getStatistic(e)
                    }}
                    filterOption={(input, option) => {
                        return (
                            option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                        )
                    }}
                />
            </StyledHeader>
            <StyledContentContainer>
                {
                    loading ? (
                        <div style={{ marginTop: "10px" }}>
                            <Skeleton active={true} />
                        </div>
                    ) : (
                        <>
                            <Row style={{
                                width: "100%",
                                borderBottom: "1px solid gray",
                                paddingBottom: "20px"
                            }} gutter={[{
                                md: 10
                            }, {
                                md: 10,
                                sm: 40,
                                xs: 20
                            }]} >
                                <Col md={12} sm={24} xs={24} >
                                    <StyledCardElement   >
                                        <FcStatistics className='statistic_card_icon' />
                                        <div className='card_text_wrapper'>
                                            <p className='number_text'>
                                                {statisticData?.totalLimitCount}
                                            </p>
                                            <p className='card_text_title'>Toplam Kontenjan (Bütün Kurslar) </p>
                                        </div>
                                    </StyledCardElement>
                                </Col>
                                <Col md={12} sm={24} xs={24} >
                                    <StyledCardElement   >
                                        <FcStatistics className='statistic_card_icon' />
                                        <div className='card_text_wrapper'>
                                            <p className='number_text'>
                                                {
                                                    statisticData?.totalRegisterCount
                                                }
                                            </p>
                                            <p className='card_text_title'>Toplam Kayıtlı Öğrenci (Bütün Kurslar) </p>
                                        </div>
                                    </StyledCardElement>
                                </Col>
                                <Col md={12} sm={24} xs={24} >
                                    <StyledCardElement   >
                                        <FcStatistics className='statistic_card_icon' />
                                        <div className='card_text_wrapper'>
                                            <p className='number_text'>
                                                {statisticData?.totalCourseRegisterCount}

                                            </p>
                                            <div className='card_text_title'>
                                                <span>
                                                    Kayıtlı Öğrenci
                                                </span>
                                                {
                                                    selectedCourseName && (
                                                        <span>
                                                            ( {`${selectedCourseName}`} )
                                                        </span>
                                                    )
                                                } </div>
                                        </div>
                                    </StyledCardElement>
                                </Col>
                                <Col md={12} sm={24} xs={24} >
                                    <StyledCardElement   >
                                        <FcStatistics className='statistic_card_icon' />
                                        <div className='card_text_wrapper'>
                                            <p className='number_text'>
                                                {statisticData?.totalCourseRegisterDeleteCount}

                                            </p>
                                            <div className='card_text_title'>
                                                <span>
                                                    Kayıt Silen Öğrenci
                                                </span>
                                                {
                                                    selectedCourseName && (
                                                        <span>
                                                            ( {`${selectedCourseName}`} )
                                                        </span>
                                                    )
                                                } </div>
                                        </div>
                                    </StyledCardElement>
                                </Col>

                            </Row>
                        </>

                    )
                }

            </StyledContentContainer>

            <StyledHeader>
                <Row style={{
                    width: "100%"
                }}
                    gutter={[
                        {
                            md: 12,
                            xs: 10
                        }
                    ]}
                >
                    <Col md={6} xs={16} >
                        <StyledSelect
                            placeholder="Yıl Seçiniz"
                            options={yearList?.map(item => {
                                return {
                                    label: item,
                                    value: item
                                }
                            })}
                            value={selectedYear}
                            onChange={(e) => {
                                setSelectedYear(e)
                                getChartData(selectedSemester, selectedBranch, e)
                                /*   setSelectedCourse(e)
                                  getStatistic(e) */
                            }}
                            filterOption={(input, option) => {
                                return (
                                    option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                                )
                            }}
                        />
                    </Col>
                    <Col md={6} xs={16} >
                        <StyledSelect
                            placeholder="Dönem Seçiniz"

                            options={[
                                {
                                    label: "Güz",
                                    value: "Güz"
                                },
                                {
                                    label: "Yaz",
                                    value: "Yaz",
                                },
                                {
                                    label: "Bahar",
                                    value: "Bahar"
                                }
                            ]}
                            value={selectedSemester}
                            onChange={(e) => {
                                setSelectedSemester(e)
                                getChartData(e, selectedBranch, selectedYear)
                                /*   setSelectedCourse(e)
                                  getStatistic(e) */
                            }}
                            filterOption={(input, option) => {
                                return (
                                    option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                                )
                            }}
                        />
                    </Col>
                    <Col md={12} xs={24} >
                        <StyledSelect
                            loading={branchLoading}
                            placeholder="Branş seçiniz"
                            showSearch
                            options={[
                                {
                                    label: "Seçim Yapınız",
                                    value: 0
                                },
                                ...branchList?.map((item) => {
                                    return {
                                        "label": `${item?.name}`,
                                        "value": `${item?.id}`
                                    }
                                })
                            ]}
                            value={selectedBranch}
                            onChange={(e) => {
                                setSelectedBranch(e)
                                getChartData(selectedSemester, e, selectedYear)
                                /*   setSelectedCourse(e)
                                  getStatistic(e) */
                            }}
                            filterOption={(input, option) => {
                                return (
                                    option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                                )
                            }}
                        />
                    </Col>
                </Row>

            </StyledHeader>
            <ChartContaier>
                <Row style={{ width: "100%" }} gutter={[{
                    md: 40
                }, {
                    xs: 40,
                    md: 50
                }]} >
                    <Col md={12} xs={24}  >
                        <ChartElement>
                            <h5 style={{
                                textAlign: "center"
                            }} >
                                Dönemsel Kişi Dağılım ({selectedSemester})
                            </h5>
                            <ReactEcharts option={option1} showLoading={branchLoading} />
                        </ChartElement>

                    </Col>
                    <Col md={12} xs={24}  >
                        <ChartElement>
                            <h5 style={{
                                textAlign: "center"
                            }} >
                                Branşa Göre Kişi Dağılımı
                            </h5>
                            <ReactEcharts option={option2} showLoading={branchLoading} />
                        </ChartElement>

                    </Col>
                    <Col md={24} xs={24} >
                        <ChartElement>
                            <h5 style={{
                                textAlign: "center"
                            }} >
                                Dönemsel  Genel Bilgiler ({selectedSemester})
                            </h5>
                            <ReactEcharts option={option3} showLoading={branchLoading} />
                        </ChartElement>


                    </Col>

                </Row>

            </ChartContaier>

        </StyledContainer>
    )
}

export default AdminStatisticDashboard


const StyledContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media screen and (max-width:600px) {
        padding: 5px;
    }

`

const StyledHeader = styled.header`
    display: flex;
    border-bottom:  1px dashed gray;
    padding-bottom: 10px;
    margin-top: 10px;
    width: 100%;
`

const StyledCardElement = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 30px;
    background-color: #f5f5f5;
    padding: 15px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.45);
    border-radius: 5px;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        width: 100%; /* Genişliği tam ekran yap */
        flex-direction: column; /* İçerikleri dikey hizala */
        justify-content: center;
        column-gap: 10px; /* Gerekirse boşlukları küçült */
        align-items: center;
        padding: 10px; /* Mobilde paddingi küçült */
    }

    .card_text_wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        @media screen and (max-width: 600px) {
            padding-left: 10px;
            text-align: center; /* Metinleri merkeze hizala */
        }

        .number_text {
            font-size: 20px;
            font-weight: bold;
            color: #1D2026;

            @media screen and (max-width: 600px) {
                font-size: 18px; /* Mobilde biraz küçült */
            }
        }

        .card_text_title {
            display: flex;
            align-items: center;
            column-gap: 5px;
            color: #4E5566;
            font-weight: 600;
            margin-right: 20px;

            @media screen and (max-width: 600px) {
                flex-direction: column;
                font-size: 12px; /* Font boyutunu uygun yap */
                text-align: center; /* Metin hizasını düzenle */
            }
        }
    }

    .statistic_card_icon {
        font-size: 40px;

        @media screen and (max-width: 600px) {
            display: none;
        }
    }
`;



const StyledSelect = styled(Select)`
    width: 100%;
    height: 40px;
`

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ChartContaier = styled.div`
    margin-top: 30px;
`

const ChartElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
   /*  align-items: center; */
`