import { Button, Input, Modal, Table } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

const RecommendedCourseModal = ({ modalInfo, setModalInfo, selectedCourse, setSelectedCourse, courseData, loading, addRecommendedCourse }) => {

    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchText, setSearchText] = useState('');
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });








    return (
        <Modal title="Kurs ekle" open={modalInfo.visible} onCancel={() => {
            setModalInfo({
                visible: false
            })
            setSelectedCourse()

        }}
            footer={() => {
                return (
                    <div style={{ display: "flex", columnGap: "10px" }}  >
                        <Button danger type='primary' onClick={() => {
                            setSelectedCourse()
                            setModalInfo({
                                visible: false
                            })

                        }} >
                            Kapat
                        </Button>
                        <Button loading={loading} disabled={selectedCourse == undefined} type='primary' onClick={() => {
                            addRecommendedCourse()
                        }} >
                            Kaydet
                        </Button>
                    </div>
                )
            }}
        >
            <StyledTable
                rowClassName={(record) => {
                    return record.id === selectedCourse ? "active_row" : "";
                }}
                dataSource={courseData}
                pagination={{
                    pageSize: 5
                }}

                loading={loading}
                columns={[
                    {
                        title: "id",
                        dataIndex: "id",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record.id}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("id")
                    },
                    {
                        title: "isim",
                        dataIndex: "name",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record.name}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("name")
                    },
                    {
                        type: "Tür",
                        dataIndex: "type",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.type}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("type")
                    },
                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <Button type='primary' onClick={() => {
                                    setSelectedCourse(record.id)
                                }} >
                                    Seç
                                </Button>
                            )
                        }
                    }
                ]}
            />

        </Modal>
    )
}

export default RecommendedCourseModal

const StyledTable = styled(Table)`
  .ant-table-tbody > tr:hover > td {
    background: none !important; /* Varsayılan hover arka planını kaldır */
  }
  .active_row {
    background-color: #0f78a9; /* Seçili satırın arka plan rengi */
    color: white;
  }
`;