import { Button, Checkbox, Modal } from 'antd'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { UserContext } from '../../context/user'
import { axiosInstance } from '../../api/axiosInstance'


const PanelNotice = () => {
    const [context, dispatch] = useContext(UserContext)


    const role = useMemo(() => {
        return context.role == "Süper Admin"
    }, [context])

    const [modalInfo, setModalInfo] = useState({
        visible: false,
        img: null,
        id: ""
    })

    const getPanelNotices = async () => {
        try {
            const response = await axiosInstance.get("/notices/getForPanel")

            if (response.data.success) {

                let noticeControl = localStorage.getItem(`panel-notice-${response.data.notice?.id}`)

                if (!noticeControl) {
                    setModalInfo({
                        visible: true,
                        img: response.data.notice?.imageUrl,
                        id: response.data?.notice?.id
                    })

                }




            }
            else {
                setModalInfo({
                    visible: false,
                    img: response.data.notice?.imageUrl
                })
            }
        }
        catch (err) {

        }
    }

    useEffect(() => {
        if (!role) {
            getPanelNotices()
        }

    }, [role])


    return (
        <Modal
            width={550}
            title="Duyuru"
            footer={
                () => {
                    return (
                        <StyledFooter>
                            <div className='check_once' >
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        localStorage.setItem(`panel-notice-${modalInfo.id}`, true)
                                    }
                                    else {
                                        localStorage.removeItem(`panel-notice-${modalInfo.id}`)
                                    }
                                }} >
                                    Bir daha gösterme
                                </Checkbox>
                            </div>
                            <Button danger type='primary' onClick={() => {
                                setModalInfo({
                                    visible: false,
                                    img: null,
                                    id: ""
                                })
                            }} >
                                Kapat
                            </Button>
                        </StyledFooter>
                    )
                }
            }
            open={modalInfo.visible} onCancel={() => {
                setModalInfo({
                    visible: false
                })
            }} >
            <StyledImg src={modalInfo.img} />

        </Modal>
    )
}

export default PanelNotice


const StyledImg = styled.img`
    width: 100%;
    /* height: 300px; */
  /*   object-fit: contain; */
    margin: 20px 0;
`

const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .check_once{
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
`