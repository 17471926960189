import React, { useMemo } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const BreadCrumb = ({ title, pageTitle }) => {
    const navigate = useNavigate()

    const mainTitle = useMemo(() => {
        if (title?.length > 30) {
            return `${title.substring(0, 30)}...`
        }
        return title
    }, [title])

    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <div className='d-flex align-items-center' >
                            <BsArrowLeft style={{ marginRight: "15px", cursor: "pointer" }} size={22} onClick={() => {
                                navigate(-1)
                            }} />
                            <h5 className="mb-sm-0" style={{ textTransform: "capitalize" }} >{mainTitle}</h5>
                        </div>

                        {/*  <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div> */}

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;