import { Button, Modal, Select, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FaEye, FaPlus, FaTrash, FaUpload } from 'react-icons/fa'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'

const AnnouncementDashboard = () => {
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        data: null
    })
    const [noticesList, setNoticeList] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileInfo, setFileInfo] = useState()
    const [noticeData, setNoticeData] = useState({
        type: "",
        status: ""
    })
    const inputRef = useRef()
    const [imageSrc, setImageSrc] = useState(null);
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setImageSrc(fileUrl);
            setFileInfo(file);
        }
    };


    const CreateNoticeApi = async () => {
        try {
            setLoading(true)
            const form = new FormData()
            form.append("formFile", fileInfo)
            const response = await axiosInstance.post(`/notices/add?type=${noticeData.type}&status=${noticeData.status}`, form)

            toast.success("Duyuru Eklendi", {
                autoClose: 2000,
                position: "top-center"
            })
            GetAllNotice()
            setModalInfo({
                visible: false
            })
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const GetAllNotice = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/notices/getall", {
                page: 0,
                pageSize: 100
            })

            setNoticeList(response.data.items)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        GetAllNotice()
    }, [])


    return (
        <StyledContainer>
            <StyledHeader>
                <Button onClick={() => {
                    setModalInfo({
                        visible: true,
                        data: null
                    })
                }} type='primary' style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                    Ekle <FaPlus />
                </Button>
            </StyledHeader>
            <StyledContent>
                <Table
                    loading={loading}
                    scroll={{
                        x: 600
                    }}
                    style={{
                        width: "100%"
                    }}
                    dataSource={noticesList}
                    columns={[
                        {
                            "title": "id",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Kim İçin",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.type}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "İçerik",
                            render: (row, record) => {
                                return (
                                    <Button type='primary' onClick={() => {
                                        window.open(`${record?.imageUrl}`, "_blank", "noopener,noreferrer");
                                    }} >
                                        <FaEye />
                                    </Button>
                                )
                            }
                        },
                        {
                            title: "İşlem",
                            render: (row, record) => {
                                return (
                                    <Button danger type='primary' onClick={() => {
                                        Modal.confirm({
                                            title: "Silmek istediğinize emin misiniz?",
                                            okText: "Eminim",
                                            cancelText: "İptal",
                                            onOk: async () => {
                                                try {
                                                    setLoading(true)
                                                    await axiosInstance.delete(`/notices/delete/${record?.id}`)

                                                    toast.success("Duyuru silindi", {
                                                        position: "top-center",
                                                        autoClose: 2000
                                                    })
                                                    GetAllNotice()
                                                }
                                                catch (err) {

                                                }
                                                finally {
                                                    setLoading(false)
                                                }
                                            }
                                        })
                                    }} >
                                        <FaTrash />
                                    </Button>
                                )
                            }
                        }
                    ]}
                />

            </StyledContent>

            <Modal
                open={modalInfo.visible}
                onCancel={() => {
                    setModalInfo({
                        visible: false,
                        data: null
                    })
                }}
                title="Duyuru Ekle"
                footer={() => {

                    return (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "35px" }}>
                            <Button type='primary' style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "5px"
                            }} onClick={() => {
                                inputRef.current.click()
                            }} >
                                Fotoğraf <FaUpload />
                            </Button>
                            <div style={{ display: "flex", columnGap: "10px" }}>
                                <Button loading={loading} type='primary' disabled={noticeData.status == "" || noticeData.type == "" || !imageSrc} onClick={() => {
                                    /* setModalInfo({
                                        visible: false,
                                        data: null
                                    }) */
                                    CreateNoticeApi()
                                }}  >
                                    Kaydet
                                </Button>
                                <Button type='primary' danger onClick={() => {
                                    setModalInfo({
                                        visible: false,
                                        data: null
                                    })
                                }}  >
                                    İptal
                                </Button>
                            </div>
                        </div>
                    )
                }}
            >
                <input type="file" style={{ display: "none" }} ref={inputRef} onChange={handleImageChange} />
                <Label style={{ marginTop: "10px" }} >
                    Tip
                </Label>
                <StyledSelect
                    value={noticeData.type}
                    placeholder="Tip"
                    onChange={(e) => {
                        setNoticeData({
                            ...noticeData,
                            type: e
                        })
                    }}
                    options={[
                        {
                            "label": "Halk",
                            "value": "Halk"
                        },
                        {
                            "label": "Öğretmen",
                            "value": "Öğretmen"
                        },
                        {
                            "label": "Öğrenci",
                            "value": "Öğrenci"
                        }
                    ]}

                />
                <Label style={{ marginTop: "10px" }} >
                    Aktiflik
                </Label>
                <StyledSelect
                    value={noticeData.status}
                    onChange={(e) => {
                        setNoticeData({
                            ...noticeData,
                            status: e
                        })
                    }}
                    options={[
                        {
                            "label": "Aktif",
                            "value": true
                        },
                        {
                            "label": "Pasif",
                            "value": false
                        }

                    ]}
                />
                {imageSrc && (
                    <div style={{ marginTop: "10px" }}>
                        <img src={imageSrc} alt="Selected" style={{ maxWidth: "100%", maxHeight: "250px" }} />
                    </div>
                )}
            </Modal>
        </StyledContainer>
    )
}

export default AnnouncementDashboard


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    row-gap: 15px;
`

const StyledHeader = styled.div`

    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
`
const StyledContent = styled.div`

`

const StyledSelect = styled(Select)`
    width: 100%;
    margin-bottom: 15px;
`

