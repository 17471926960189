import React from 'react'
import { PropagateLoader } from 'react-spinners'
import styled from 'styled-components'

const StyledContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 20px 0;
`

const LoadingElement = ({ color, size }) => {
    return (
        <StyledContainer>
            <PropagateLoader size={size ?? 20} color={color ?? "black"} />
        </StyledContainer>
    )
}

export default LoadingElement