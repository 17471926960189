import { axiosInstance } from "./axiosInstance";

const createSurveyApi = (body) => axiosInstance.post("/Surveys/Add", body)
const createSurveyQuestionApi = (body) => axiosInstance.post("/SurveyQuestions/Add", body)
const deleteSurveyQuestionApi = (id) => axiosInstance.delete("/SurveyQuestion/Delete", id)
const surveyQuestionUpdateApi = (body) => axiosInstance.put("/SurveyQuestions/Update", body)

const surveysGetAll = (body) => axiosInstance.post("/Surveys/GetAll", body)
const updateSurveyApi = (body) => axiosInstance.put("/surveys/update", body)
const addSurveyAnswersApi = (body) => axiosInstance.post("/surveyAnswers/add", body)
const deleteSurveyAnswerApi = (id) => axiosInstance.delete(`/surveyAnswers/delete/${id}`)
const updateSurveyAnswerApi = (body) => axiosInstance.put("/surveyAnswers/update", body)
const surveyResultsAddApi = (body) => axiosInstance.post("/surveyResults/Add", body)
const surveyGetAllForResultApi = (id) => axiosInstance.get(`/surveys/GetAllForResult/${id}`)


export {
    createSurveyApi,
    createSurveyQuestionApi,
    surveysGetAll,
    updateSurveyApi,
    addSurveyAnswersApi,
    deleteSurveyQuestionApi,
    deleteSurveyAnswerApi,
    updateSurveyAnswerApi,
    surveyResultsAddApi,
    surveyGetAllForResultApi,
    surveyQuestionUpdateApi
}