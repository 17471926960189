import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Input, Table, Label, Toast } from "reactstrap"
import { ExamContext } from '../ExamManagementDashboard'
import { Button as AntButton } from "antd"
import { toast } from 'react-toastify'
import { uniqueId } from 'lodash'
import { IoIosClose, IoMdClose, IoMdCloseCircle } from 'react-icons/io'
import { v4 as uuid } from "uuid"
import styled from 'styled-components'
import { IoCloseCircle, IoCloseCircleOutline } from 'react-icons/io5'
import { AddQuestionModalBody, AddQuestionModalHeader, StyledAddQuestionModalContentContainer } from './createQuestionStyle'


const CreateQuestionModal = () => {
    const { examData, poolFlag, setPoolFlag, setExamData, previewSrc, setPreviewSrc, modalChoiceList, setModalChoiceList, editMode, setEditMode, modalQuestion, setModalQuestion, modalShow, setModalShow, courseList, setCourseList, questionPoint, setQuestionPoint } = useContext(ExamContext)
    const fileInputRef = useRef(null);

    function rakamToHarf(rakam) {
        const asciiOffset = 65;
        const harf = String.fromCharCode(asciiOffset + parseInt(rakam) - 1);
        return harf;
    }

    const examTypeControl = useMemo(() => {
        if (editMode.active) {

            let selected = examData.questions.find(el => el.id == editMode.id)

            return selected?.questionType == "Test"
        }
        else {
            return examData?.type == "Test"
        }

    }, [examData, editMode])


    const questionValidation = () => {
        for (let item of modalChoiceList) {
            if (item.content == "") {
                toast.error("Cevap Şıkkının içeriği boş bırakılamaz", {
                    autoClose: 1500
                })
                return false;
            }
        }
        if (modalChoiceList.length < 2) {
            toast.error("En az 2 adet cevap şıkkı olmalı", {
                autoClose: 1500
            })
            return false;
        }
        const checkControl = modalChoiceList.some(x => x.correct)
        if (!checkControl) {
            toast.error("En az bir adet doğru cevap seçiniz", {
                autoClose: 1500
            })
            return false
        }

        return true
    }


    const addQuestion = () => {
 
        if (modalQuestion == "") {
            toast.error("Soru boş bırakılamaz", {
                autoClose: 1500
            })
        }
        else if (questionPoint == 0 || questionPoint < 0 || questionPoint >= 100) {
            toast.error("Cevap Şıkkının puanı sıfırdan büyük ve 100'den küçük olmalı", {
                autoClose: 1500
            })
        }
        else {
            if (editMode.active) {
                let selectedQuestion = examData.questions.find(el => el.id == editMode.id)
                if (selectedQuestion.questionType == "Test") {
                    let control = questionValidation()
                    if (control) {
                        
                      
                        setExamData({
                            ...examData,
                            questions: examData.questions?.map(item => {
                                if (item.id == editMode.id) {
                                    return {
                                        ...item,
                                        questionContent: modalQuestion,
                                        point: parseInt(questionPoint),
                                        file: previewSrc,
                                        choices: modalChoiceList.map((el, index) => {
                                            return {
                                                ...el,
                                                letter: rakamToHarf(index + 1)
                                            }
                                        }),
                                    }
                                }
                                return item
                            })
                        })
                        setModalQuestion("")
                        setModalChoiceList([{
                            "content": "",
                            "id": 984,
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        }])
                        setQuestionPoint(0)

                        setModalShow(false)

                    }
                }
                else {
                    setExamData({
                        ...examData,
                        questions: examData.questions?.map(item => {
                            if (item.id == editMode.id) {
                                return {
                                    ...item,
                                    questionContent: modalQuestion,
                                    point: parseInt(questionPoint),
                                    file: previewSrc
                                }
                            }
                            return item
                        })
                    })
                    setModalQuestion("")
                    setModalChoiceList([{
                        "content": "",
                        "id": 984,
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    }])
                    setQuestionPoint(0)
                    setPreviewSrc(null)
                    setModalShow(false)
                }
            }
            else {
                if (examTypeControl) {
                    let validControl = questionValidation()
                    if (validControl) {
                        setExamData({
                            ...examData,
                            questions: [
                                ...examData.questions,
                                {
                                    "id": uuid(),
                                    "questionContent": modalQuestion,
                                    "point": parseInt(questionPoint),
                                    "choices": modalChoiceList.map((el, index) => {
                                        return {
                                            ...el,
                                            letter: rakamToHarf(index + 1)
                                        }
                                    }),
                                    "questionType": "Test",
                                    "file": previewSrc
                                }
                            ]
                        })
                        setModalQuestion("")
                        setModalChoiceList([{
                            "content": "",
                            "id": 984,
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        }])
                        setQuestionPoint(0)
                        setPreviewSrc(null)
                        setModalShow(false)
                    }

                }
                else {

                    setExamData({
                        ...examData,
                        questions: [
                            ...examData.questions,
                            {
                                "id": uuid(),
                                "questionContent": modalQuestion,
                                "point": parseInt(questionPoint),
                                "questionType": "Yazılı",
                                "file": previewSrc
                            }
                        ]
                    })
                    setModalQuestion("")
                    setModalChoiceList([{
                        "content": "",
                        "id": 984,
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    },
                    {
                        "content": "",
                        "id": uniqueId(),
                        "correct": false,
                    }])
                    setQuestionPoint(0)
                    setModalShow(false)
                    setModalQuestion("")

                    setQuestionPoint(0)
                    setPreviewSrc(null)
                    setModalShow(false)
                }


            }

        }
    }

    const StyledModalHeader = styled(ModalHeader)`
        display: flex;
        align-items: center;

        h5{
            flex-grow: 1;
            display: flex;

        }
    `

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith('image/')) {
                setPreviewSrc(file);
            } else {
                setPreviewSrc(null);
                toast.error("Lütfen fotoğraf seçiniz", {
                    autoClose: 1500
                })
            }
        }

        event.target.value = null
    };






    return (
        <>

            <Modal size='lg' isOpen={modalShow} >
                <StyledModalHeader  >
                    <AddQuestionModalHeader  >
                        <p>
                            {editMode.active ? "Soruyu Güncelle" : "Soru Ekle"}
                        </p>
                        {
                            !poolFlag && (
                                <Button size='sm' onClick={() => {
                                    fileInputRef.current.click()
                                }}  >
                                    Fotoğraf Ekle
                                </Button>
                            )
                        }

                    </AddQuestionModalHeader>
                </StyledModalHeader>
                <ModalBody>
                    <AddQuestionModalBody>
                        <StyledAddQuestionModalContentContainer file={previewSrc}  >
                            <Input disabled={poolFlag} value={modalQuestion} type='textarea' style={{ resize: "none" }} rows={5} cols={10} placeholder='Soru İçeriği' onChange={(e) => {
                                setModalQuestion(e.target.value)
                            }} />
                            {previewSrc && (
                                <div className='remove_img_wrapper'  >
                                    <img src={URL.createObjectURL(previewSrc)} alt="Seçilen Fotoğraf" style={{ maxWidth: '100%', width: "100%", objectFit: "cover", objectPosition: "top", height: '100%', maxHeight: "100%", borderRadius: 10 }} />
                                    <div className='remove_img_close_icon_container' onClick={() => {
                                        setPreviewSrc(null)
                                    }} >
                                        <IoIosClose className='remove_img_close_icon' />
                                    </div>

                                </div>
                            )}
                        </StyledAddQuestionModalContentContainer>
                        <div className='question_type_section' >
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <strong style={{ marginRight: "10px" }} >
                                    Puan
                                </strong>
                                <Input disabled={poolFlag} type='number' value={questionPoint} className='question_point' onChange={(e) => {
                                    setQuestionPoint(e.target.value)
                                }} />
                            </div>
                        </div>
                        {
                            examTypeControl ? (
                                <div className='add_question_model_choice_list'>
                                    {
                                        modalChoiceList?.map((el, index) => {
                                            return (
                                                <div className='add_question_modal_choice' >
                                                    <div className='add_question_choice_content' >
                                                        <p className='choice_letter' >
                                                            {rakamToHarf(index + 1)}
                                                        </p>
                                                        <Input disabled={poolFlag} value={el.content} className='choice_icerik' placeholder='Soru' type='textarea' onChange={(e) => {
                                                            setModalChoiceList(modalChoiceList?.map(x => {
                                                                if (x.id == el.id) {
                                                                    return {
                                                                        ...x,
                                                                        content: e.target.value
                                                                    }
                                                                }
                                                                return x
                                                            }))

                                                        }} />
                                                    </div>
                                                    <div className='add_question_choice_process'>
                                                        <Input disabled={poolFlag} checked={el.correct} type='checkbox' className='choice_check' onChange={(e) => {
                                                            setModalChoiceList(modalChoiceList?.map(x => {
                                                                if (x.id == el.id) {
                                                                    return {
                                                                        ...x,
                                                                        correct: e.target.checked
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        ...x,
                                                                        correct: false
                                                                    }
                                                                }

                                                            }))
                                                        }} />
                                                        {/* {
                                                            modalChoiceList?.length > 1 && (
                                                                <IoMdClose className='choice_delete' onClick={() => {
                                                                    setModalChoiceList(modalChoiceList?.filter(item => item.id !== el.id))
                                                                }} />
                                                            )
                                                        } */}
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            ) : (
                                <div style={{ marginTop: 20 }}>
                                    <Input placeholder='Bu sınav yazılı bir sınavdır' disabled />
                                </div>
                            )
                        }

                    </AddQuestionModalBody>
                </ModalBody>
                <ModalFooter>
                    {
                        !poolFlag && (
                            <AntButton type={"primary"} onClick={addQuestion} disabled={modalChoiceList?.length == 0} >
                                {
                                    editMode.active ? "Güncelle" : "Kaydet"
                                }
                            </AntButton>
                        )
                    }
                    <AntButton danger type='primary' onClick={() => {
                        setModalShow(false)

                        setModalChoiceList([{
                            "content": "",
                            "id": 984,
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        }])
                        setQuestionPoint(0)

                        setEditMode({
                            active: false,
                            id: 0
                        })
                        setModalQuestion("")

                        setPreviewSrc(null)
                        setPoolFlag(false)

                    }} >
                        İptal
                    </AntButton>


                </ModalFooter>
            </Modal>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>


    )
}

export default CreateQuestionModal






