import React, { useEffect, useState } from 'react'
import Editor from './Editor';
import axios from 'axios';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import TiptapEditor from './TiptapEditor';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';

Quill.register('modules/imageResize', ImageResize);

const Test2 = () => {
    const [editorHtml, setEditorHtml] = useState('');
    const [loading, setLoading] = useState(false)
    const { id } = useParams()

    const getByContent = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`http://localhost:4242/editor/${id}`)
            /* console.log("editorHtmlFix", response.data.data?.content) */
            setEditorHtml(response.data.data?.content)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getByContent()
    }, [])



    if (loading) {
        return (
            <Spin />
        )
    }

    return (
        <div>

            {/* <Editor editorHtml={editorHtml} setEditorHtml={setEditorHtml} /> */}


            <div className='ql-snow'>
                <div className='ql-editor' dangerouslySetInnerHTML={{ __html: editorHtml }}>

                </div>
            </div>




        </div>
    )
}


export default Test2