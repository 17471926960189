import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import TeacherSurveyDashboard from '../../Components/Teacher/TeacherSurvey/TeacherSurveyDashboard'
import styled from 'styled-components'

const TeacherSurveyPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Anket' header={"Anket"} visible={false}  >
                <StyledContainer>

                    <TeacherSurveyDashboard />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 15px;

    @media screen and (max-width:600px) {
        padding: 5px;
    }
`


export default TeacherSurveyPage