import React, { useEffect, useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import TeacherAllMessageDashboard from '../../Components/TeacherAllMessageDashboard/TeacherAllMessageDashboard'

const TeacherAllMessagePage = () => {

    return (
        <div className='page-content' >
            <TablePageContainer header={"Bütün Mesajlar"} visible={false}  >
                <TeacherAllMessageDashboard />
            </TablePageContainer>
        </div>
    )
}

export default TeacherAllMessagePage


