import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import StudentCourseTable from './StudentCourseTable'
import StudentOnlineMeeting from './StudentOnlineMeeting'
import StudentMessage from './StudentMessage'
import { GetCalendarForAllRoles } from '../../../api/Course'
import StudentCourseOldRecord from './StudentCourseOldRecord'
import MyCourseIcon from "../../../assets/images/MyCourseIcon.png"
import "./index.scss"

const StudentCourseMainPage = () => {
  return (
    <>
      <StudentCourseTable  />
    </>
  )
}

export default StudentCourseMainPage