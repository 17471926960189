// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 480px) {
  .btnRefresh {
    padding-left: 33px;
    padding-right: 33px; } }

@media screen and (max-width: 558px) {
  .btnRefresh {
    margin-top: 10px; } }

@media screen and (max-width: 400px) {
  #BtnMessage {
    margin-top: 10px; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Teacher/TeacherMessageDashboard.scss"],"names":[],"mappings":"AAAA;EACI;IACI,kBAAkB;IAClB,mBAAmB,EAAA,EACtB;;AAGL;EACI;IACE,gBAAgB,EAAA,EACjB;;AAEL;EACI;IACE,gBAAgB,EAAA,EACjB","sourcesContent":["@media screen and(min-width:480px) {\n    .btnRefresh{\n        padding-left: 33px;\n        padding-right: 33px;\n    }\n}\n\n@media screen  and (max-width:558px) {\n    .btnRefresh{\n      margin-top: 10px;\n    }\n}\n@media screen  and (max-width:400px) {\n    #BtnMessage{\n      margin-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
