
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Alert, Button, Card, Modal } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FaImage, FaPlus, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { axiosInstance } from '../../../../api/axiosInstance'
import { useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getDetailCourseApi } from '../../../../api/Course'
import { Navigation, Pagination } from 'swiper';


const CourseImages = () => {

    const { id } = useParams()
    const [modalInfo, setModalInfo] = useState({
        visible: false
    })
    const [courseInfo, setCourseInfo] = useState()
    const [loading, setLoading] = useState(false)
    const imageRef = useRef()
    const [selectedFile, setSelectedFile] = useState()

    const courseImageList = useMemo(() => {
    
        if (courseInfo?.courseImages) {
            return courseInfo?.courseImages
        }
        return []
    }, [courseInfo])

    const handleFileChange = (event) => {
        const file = event.target?.files[0]
        const fileType = file?.type;
        if (fileType?.startsWith('image/')) {
            setSelectedFile(file)
        }
        else {
            toast.warning("Lütfen fotoğraf seçiniz", {
                position: "top-center",
                autoClose: 2000
            })
        }
    }


    const detailCourseApiRequest = async () => {
        try {
            setLoading(true)
            const response = await getDetailCourseApi(id)
            setCourseInfo(response.data)
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }

    }

    const addCourseImage = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("formFile", selectedFile)
            const response = await axiosInstance.post(`/courseImages/add?courseId=${id}`, formData)

            toast.success("Fotoğraf kayıt edildi", {
                position: "top-center",
                autoClose: 2300
            })
            setModalInfo({
                visible: false
            })
            setSelectedFile()
            await detailCourseApiRequest()
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        detailCourseApiRequest()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <input ref={imageRef} type="file" style={{ display: "none" }} onChange={handleFileChange} />
                <Button type='primary' style={{ display: "flex", columnGap: "5px", alignItems: "center" }} onClick={() => {
                    setModalInfo({
                        visible: true
                    })
                }}  >
                    Ekle <FaPlus />
                </Button>
                <Alert type='info' style={{
                    color: "red"
                }} message="İlk fotoğraf kursun kapak fotoğrafı olacaktır " />
            </StyledHeader>
            <StyledImageContainer>
                {
                    courseImageList.length != 0 ? (
                        <StyledSwiper
                            className="mySwiper"
                            pagination={true}
                            modules={[Pagination]}
                            breakpoints={{
                                // Mobilde tek slide göster
                                0: {
                                    slidesPerView: 1,
                                },
                                // Tablet ve üstü ekranlarda 2 slide göster
                                768: {
                                    slidesPerView: 2,
                                },
                                // Daha büyük ekranlarda 4 slide göster
                                1024: {
                                    slidesPerView: 3,
                                },

                            }}
                            slidesPerView={3}
                        >
                            {
                                courseImageList?.map((item, index) => {
                                    return (
                                        <SwiperSlide key={`${index}`}>
                                            <StyledImageWrapper  >
                                                <StyledImg alt="example" src={item?.url} />
                                                <Button type='primary' className='trash_icon' onClick={() => {
                                                    Modal.confirm({
                                                        title: "Bu fotoğrafı silmek istediğinize emin misiniz?",
                                                        okText: "Eminim",
                                                        cancelText: "İptal",
                                                        onOk: async () => {
                                                            try {
                                                                await axiosInstance.delete(`/courseImages/delete/${item.id}`)

                                                                toast.success("Fotoğraf silindi", {
                                                                    position: "top-center",
                                                                    autoClose: 2000
                                                                })
                                                                detailCourseApiRequest()
                                                            }
                                                            catch (err) {

                                                            }

                                                        }
                                                    })
                                                }} >
                                                    <FaTrash />
                                                </Button>
                                            </StyledImageWrapper>
                                            {/* <Card
                                                onClick={() => {
                                                    navigate(`/egitmen/${item.userId}`)
                                                }}
                                                hoverable
                                                cover={<StyledImg alt="example" src={item?.url} />}
                                            >
                                            </Card> */}

                                        </SwiperSlide>
                                    )
                                })
                            }
                        </StyledSwiper>
                    ) : (
                        <Alert type='info' message="Henüz fotoğraf eklenmemiş" />
                    )
                }
            </StyledImageContainer>
            <Modal open={modalInfo.visible} title="Fotoğraf yükle" onCancel={() => {
                setModalInfo({
                    visible: false
                })
                setSelectedFile()
            }} footer={() => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button style={{ display: "flex", alignItems: "center", columnGap: "5px" }} type='primary' onClick={() => {
                            imageRef.current?.click()
                        }} >
                            Fotoğraf <FaImage />
                        </Button>
                        <div style={{ display: "flex", columnGap: "5px" }} >
                            <Button loading={loading} type='primary' disabled={!selectedFile} onClick={() => {
                                addCourseImage()
                            }} >
                                Kaydet
                            </Button>
                            <Button type='primary' danger onClick={() => {
                                setModalInfo({
                                    visible: false
                                })
                                setSelectedFile()
                            }} >
                                İptal
                            </Button>
                        </div>
                    </div>
                )
            }} >
                {
                    selectedFile ? (
                        <img src={URL.createObjectURL(selectedFile)} style={{ width: "100%", height: "100%", maxHeight: "300px", margin: "20px 0" }} alt="" />
                    ) : (
                        <Alert type='info' message="Henüz fotoğraf seçilmedi" style={{ margin: "30px 0" }} />
                    )
                }


            </Modal>
        </StyledContainer>
    )
}

export default CourseImages


const StyledContainer = styled.div`
    padding: 10px;
`
const StyledHeader = styled.div`
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    column-gap: 20px;
`

const StyledImageContainer = styled.div`
    margin-top: 30px;
`

const StyledImg = styled.img`
   height: 90%;
   width: 100%;
   border-radius: 10px;
   object-fit: cover;
`

const StyledImageWrapper = styled.div`
    position: relative;
    width: 95%;
    height: 300px;
    .trash_icon{
        position: absolute;
        top: 0;
        right: 0;
    }
`

const StyledSwiper = styled(Swiper)`

    .swiper-pagination-bullets{
        position: absolute;
        bottom: 0;

        .swiper-pagination-bullet{
            background-color: black !important;
        }
    }
`