// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contDot {
  display: flex;
  justify-content: center; }
  .contDot #dot {
    width: 10%;
    color: #946868; }

#dot {
  transition: transform 0.3s ease; }

#dot.flip {
  transform: rotate(180deg); }
`, "",{"version":3,"sources":["webpack://./src/Public/components/MySlider/mySlider.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB,EAAA;EAF3B;IAKQ,UAAU;IACV,cAAc,EAAA;;AAKtB;EACI,+BAA+B,EAAA;;AAGnC;EACI,yBAAyB,EAAA","sourcesContent":[".contDot {\n    display: flex;\n    justify-content: center;\n\n    #dot {\n        width: 10%;\n        color: #946868;\n    }\n\n}\n\n#dot {\n    transition: transform 0.3s ease;\n}\n\n#dot.flip {\n    transform: rotate(180deg);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
