import React from 'react'
import ExamManagementDashboard from '../../Components/ExamManagement/ExamManagementDashboard'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import styled from 'styled-components'

const ExamManagement = () => {
    return (
        <div className='page-content'>
            <TablePageContainer header={"Sınavlar"} visible={false} >
                <ExamDashboardContainer>
                    <ExamManagementDashboard />
                </ExamDashboardContainer>

            </TablePageContainer>
        </div>
    )
}
const ExamDashboardContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:500px)  {
        padding: 5px;
    }
`

export default ExamManagement