import { Button } from 'reactstrap';
import "./buttonPurple.scss"
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const ButtonPurple = (props) => {
    return (
        <>

            <Button id='primaryButton' onClick={() => {
                if (props?.onClick) {
                    props?.onClick()
                }

            }} size='sm' style={{ backgroundColor: props.btnColor }} >
                {props.buttonText}
            </Button>

        </>
    )
}
ButtonPurple.propTypes = {
    buttonText: PropTypes.string.isRequired,
    btnColor: PropTypes.any,
    page: PropTypes.string,

};
export default ButtonPurple;
