import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import RecommendedCourseDashboard from '../../Components/RecommendedCourse/RecommendedCourseDashboard'

const RecommendedCoursePage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer header={"Önerilen Kurslar"} visible={false} >
                <RecommendedCourseDashboard />
            </TablePageContainer>
        </div>
    )
}

export default RecommendedCoursePage