import React, { useEffect, useMemo, useState } from 'react'
import MainNavbar from '../../components/navbar/MainNavbar'
import FontHead from '../../components/fontHead/FontHead'
import styled from 'styled-components'
import MainFooter from '../../components/mainFooter/MainFooter'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../../api/axiosInstance'
import { Avatar, Skeleton } from 'antd'
import Item from 'antd/es/list/Item'
import ImgErkek from "../../../assets/images/profile.png"
import EgitimlerBg from "../../../assets/images/egitimlerBg.png"
import EgitmenCourses from './EgitmenCourses'
import TopBgElement from '../TopBgElement/TopBgElement'
/* import ImgKadın from "../../../assets/images/imgProfile6.png" */

const EgitmenPage = () => {


    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [teacherData, setTeacherData] = useState()
    const [tabId, setTabId] = useState(1)
    const getTeacherCv = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`TeacherInformations/GetByUserId/${id}`)
            setTeacherData(response.data)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const courseList = useMemo(() => {
        return teacherData?.courses
    }, [teacherData])


    const teacherImg = (teacherImg) => {
        if (teacherImg == "Bulunamad�.") {
            return ImgErkek
        }
        else {
            return teacherImg
        }
    }

    useEffect(() => {
        getTeacherCv()
    }, [])



    return (
        <StyledMainContainer>
            <MainNavbar />
            <TopBgElement img={EgitimlerBg} mainTitle={"Eğitmen"} subTitle={teacherData?.firstAndLastName} />
            {
                loading ? (
                    <LoadingContainer>
                        <Skeleton active={true} />
                    </LoadingContainer>
                ) : (
                    <StyledContainer>
                        <TeacherInfoContainer>
                            <TeacherGeneralInfo>
                                <StyledImg src={teacherImg(teacherData?.imageUrl)} />
                                <div className='teacher_name_content' >
                                    <h4 className='name_tag' >
                                        {teacherData?.firstAndLastName ?? "Henüz Bilgi Eklenmemiş"}
                                    </h4>
                                    <h5 className='title_tag' >
                                        Eğitmen
                                    </h5>
                                </div>
                            </TeacherGeneralInfo>
                            <TeacherDetailContainer   >
                                <div className='tabs_container' >
                                    <p onClick={() => {
                                        setTabId(1)
                                    }} className='tab_item' style={{
                                        borderBottom: tabId == 1 ? "2px solid black" : "2px solid transparent"
                                    }} >
                                        Hakkında
                                    </p>
                                    {
                                        courseList ? (
                                            <p onClick={() => {
                                                setTabId(2)
                                            }} className='tab_item' style={{
                                                borderBottom: tabId == 2 ? "2px solid black" : "2px solid transparent"
                                            }} >
                                                Eğitimler
                                            </p>
                                        ) : null
                                    }

                                </div>
                                <div className='teacher_detail_wrapper' >
                                    {
                                        tabId == 1 ? (
                                            <div className='teacher_description_container' >
                                                <p className='description_text' >
                                                    {teacherData?.content == "Bulunamad�." ? "İçerik Eklenmedi" : teacherData?.content}
                                                </p>
                                            </div>
                                        ) : (
                                            <EgitmenCourses courseList={courseList} />

                                        )
                                    }
                                </div>

                            </TeacherDetailContainer>

                        </TeacherInfoContainer>


                    </StyledContainer>
                )
            }

            <MainFooter visibility={'hidden'} />
        </StyledMainContainer>
    )

}

export default EgitmenPage

const StyledContainer = styled.div`

    padding: 20px 120px;
    margin-bottom: 100px;


    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    margin: 20px 0;
    }

`


const LoadingContainer = styled.div`
    padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }
`

const StyledImg = styled.img`
    width: 250px;
    height: 220px;
    border-radius: 50%;
`

const TeacherInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 60px;
`

const TeacherGeneralInfo = styled.div`
    display: flex;
    align-items: center;
    column-gap: 80px;
    width: 100%;

    @media screen and (max-width:600px) {
        flex-direction: column;
    }

    .teacher_name_content{
        width: 100%;
        display: flex;
        flex-direction: column;



        .name_tag{
            color: black;
            font-size: 30px;
            line-height: 5rem;
            font-weight: bold;

            @media screen and (max-width:600px) {
                font-size: 30px;
                line-height: 5rem;
                text-align: center;
             }
        }

        .title_tag{
            @media screen and (max-width:600px) {
                text-align: center;
             }
        }


    }
`

const TeacherDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .tabs_container{
        display: flex;
        column-gap: 25px;
        border-bottom: 1px solid black;

        .tab_item{
            font-size: 20px;
            font-weight:bold;
            cursor: pointer;
            transition: .5s all;
          /*   transition: .5s all; */
            padding-bottom: 5px;
        }
    }


    .teacher_description_container {
        background-color: white;
        padding: 20px;
        border-radius: 5px;

        .description_text{
            font-weight: 600;
            text-transform: capitalize;
            font-size: 16px;
        }
    }

    .teacher_detail_wrapper{
        margin-top: 20px;
    }

`


const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`