import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import AddBranchDashboard from '../../Components/AddBranchDashboard/AddBranchDashboard'

const AddBranchPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer title='Branş Ekle' header={"Branş Ekle"} visible={false} >
                <AddBranchDashboard />
            </TablePageContainer>
        </div>
    )
}

export default AddBranchPage