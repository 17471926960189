import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import QuillEditor from '../Editor/QuillEditor'
import { FaFile } from 'react-icons/fa'
import { axiosInstance } from '../../api/axiosInstance'
import { decodeEntity } from 'html-entities'

const BlogUpdateDashboard = () => {
    const [title, setTitle] = useState("")
    const [editorHtml, setEditorHtml] = useState({
        value: null
    })

    const fileRef = useRef()
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState({
        name: "",
        file: null
    })
    const { id } = useParams()



    const getByBlog = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/blogs/GetById/${id}`)
            console.log("rexp", response.data)
            let decodeHtml = decodeEntity(response.data.description)
            setEditorHtml({
                value: decodeHtml
            })
            setTitle(response.data?.title)
            setSelectedFile({
                file: response?.data?.imageUrl,
                name: response?.data?.imageUrl
            })
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }
    const PreviewTopicImg = () => {
        if (selectedFile.file instanceof File) {
            window.open(URL.createObjectURL(selectedFile.file), "_blank")
        }
        else {
            window.open(selectedFile.file, "_blank")
        }
    }

    const updateBlog = async () => {

        if (title == "") {
            toast.error("Başlık boş bırakılamaz", {
                position: "top-center",
                autoClose: 2500
            })
        }

        else {
            /* console.log("editoHtmlxx", editorHtml.value) */
            Modal.confirm({
                title: "Güncellemek istediğinze emin misiniz?",
                okText: "Eminim",
                cancelText: "İptal",
                onOk: async () => {
                    try {
                        setLoading(true)
                        const formData = new FormData()
                        if (selectedFile.file instanceof File) {
                            formData.append("formFile", selectedFile.file)
                        }
                        const decodeHtml = decodeURIComponent(editorHtml?.value)
                        const response = await axiosInstance.put(`/blogs/update?title=${title}&description=${decodeHtml}&id=${id}`, formData)
                        toast.success("Güncelleme başarılı", {
                            position: "top-center",
                            autoClose: 2500
                        })
                        getByBlog()
                    }
                    catch (err) {

                    }
                    finally {
                        setLoading(false)
                    }
                }
            })

        }


    }

    useEffect(() => {
        getByBlog()
    }, [])

    return (
        <StyledContainer>
            <StyledElement>
                <p className='title_element'>
                    Başlık
                </p>
                <Input className='title_input' placeholder='Başlık' value={title} onChange={(e) => {
                    setTitle(e.target.value)
                }} />
            </StyledElement>
            <StyledElement>
                <p className='title_element'>
                    İçerik
                </p>
                <QuillEditor editorHtml={editorHtml} setEditorHtml={setEditorHtml} />
            </StyledElement>
            <StyledElement>
                <p className='title_element'>
                    Dosya
                </p>
                {
                    selectedFile.file ? (
                        <p style={{
                            border: "1px solid gray",
                            borderRadius: "10px",
                            padding: "10px",
                            cursor: "pointer"
                        }}
                            onClick={() => {
                                PreviewTopicImg()
                            }}
                        >
                            {selectedFile?.name}
                        </p>
                    ) : (
                        <p>
                            Henüz Dosya Seçilmedi
                        </p>
                    )
                }

            </StyledElement>
            <StyledFooter>
                <Button loading={loading} type='primary' style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "5px"
                }} onClick={() => {
                    fileRef?.current?.click()
                }}  >
                    <FaFile /> Dosya
                </Button>
                <Button type='primary' loading={loading} onClick={() => {
                    updateBlog()
                }} >
                    Güncelle
                </Button>
            </StyledFooter>

            <input type="file" ref={fileRef} style={{
                display: "none"
            }} onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                    if (file.type.startsWith('image/')) {
                        setSelectedFile({
                            name: file?.name,
                            file: file
                        })
                    } else {
                        toast.error("Lütfen fotoğraf seçiniz", {
                            autoClose: 2000,
                            position: "top-center"
                        })
                    }
                }

            }} />
        </StyledContainer>
    )
}

export default BlogUpdateDashboard




const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
`

const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
/*     border: 1px solid whitesmoke; */
    padding: 5px 0px;
    border-radius: 20px;

    .title_element{
        font-weight: bold;
        font-size: 18px;
    }

    .title_input{
        height: 35px;
    }
`

const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;

`