import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import QuillEditor from '../Editor/QuillEditor'
import { Button, Input, Modal } from 'antd'
import { FaFile } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../api/axiosInstance'

const AddBlogDashboard = () => {
    const [editorHtml, setEditorHtml] = useState({
        value: null
    })
    const [selectedFile, setSelectedFile] = useState({
        name: "",
        file: null
    })
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState("")
    const fileRef = useRef()

    const isValid = () => {
        if (title == "") {
            toast.info("Başlık giriniz", {
                position: "top-center",
                autoClose: 2000
            })
            return false;
        }
        else if (!selectedFile?.file) {
            toast.info("Dosya seçiniz", {
                position: "top-center",
                autoClose: 2000
            })
            return false;
        }
        else if (editorHtml.value == null || editorHtml.value == "") {

            toast.info("İçerik giriniz", {
                position: "top-center",
                autoClose: 2000
            })
            return false
        }
        return true
    }

    const createBlog = async () => {

        if (isValid()) {
            Modal.confirm({
                title: "Eklemek istediğinize emin misiniz?",
                cancelText: "İptal",
                okText: "Eminim",
                onOk: async () => {
                    try {
                        setLoading(true)
                        const form = new FormData()
                        if (selectedFile) {
                            form.append("formFile", selectedFile.file)
                        }
                        /* let html = `
                            <div>
                                ${editorHtml.value}
                            </div>
                        ` */
                        let test = encodeURIComponent(editorHtml.value)
                        const response = await axiosInstance.post(`/blogs/add?description=${test}&title=${title}`, form)
                        toast.success("İçerik eklendi", {
                            autoClose: 2000,
                            position: "top-center"
                        })

                        setSelectedFile()
                        setTitle("")
                        setEditorHtml({
                            value: null
                        })

                    }
                    catch (err) {

                    }
                    finally {
                        setLoading(false)
                    }
                }
            })
        }


    }


    return (
        <StyledContainer>
            <StyledElement>
                <p className='title_element'>
                    Başlık
                </p>
                <Input className='title_input' placeholder='Başlık' onChange={(e) => {
                    setTitle(e.target.value)
                }} />
            </StyledElement>
            <StyledElement>
                <p className='title_element'>
                    İçerik
                </p>
                <QuillEditor editorHtml={editorHtml} setEditorHtml={setEditorHtml} />
            </StyledElement>
            <StyledElement>
                <p className='title_element'>
                    Dosya
                </p>
                {
                    selectedFile?.file ? (
                        <p style={{
                            border: "1px solid gray",
                            borderRadius: "10px",
                            padding: "10px"
                        }} >
                            {selectedFile?.name}
                        </p>
                    ) : (
                        <p>
                            Henüz Dosya Seçilmedi
                        </p>
                    )
                }

            </StyledElement>
            <StyledFooter>
                <Button loading={loading} type='primary' style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "5px"
                }} onClick={() => {
                    fileRef?.current?.click()
                }}  >
                    <FaFile /> Dosya
                </Button>
                <Button type='primary' loading={loading} onClick={() => {
                    createBlog()
                }} >
                    Kaydet
                </Button>
            </StyledFooter>
            {/*  <div dangerouslySetInnerHTML={{
                    __html : editorHtml.value
                }} >

                </div> */}
            <input type="file" ref={fileRef} style={{
                display: "none"
            }} onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                    if (file.type.startsWith('image/')) {
                        setSelectedFile({
                            name: file?.name,
                            file: file
                        })
                    } else {
                        toast.error("Lütfen fotoğraf seçiniz", {
                            autoClose: 2000,
                            position: "top-center"
                        })
                    }
                }

            }} />
        </StyledContainer>
    )
}

export default AddBlogDashboard


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px;
`

const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
/*     border: 1px solid whitesmoke; */
    padding: 5px 0px;
    border-radius: 20px;

    .title_element{
        font-weight: bold;
        font-size: 18px;
    }

    .title_input{
        height: 35px;
    }
`

const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 10px;
    margin-top: 20px;

`