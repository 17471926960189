import { Button, Collapse, Input, Modal, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { SlArrowDown } from 'react-icons/sl'
import { useParams } from 'react-router-dom'
import { Label } from 'reactstrap'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'

const EditBranchContent = () => {

    const [modalValues, setModalValues] = useState({
        content: "",
        title: ""
    })
    const [loading, setLoading] = useState(false)
    const [contentList, setContentList] = useState([])
    const { id } = useParams()
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        editMode: false,
        id: null
    })

    const disableBtn = useMemo(() => {
        return modalValues.content == "" || modalValues.title == ""
    }, [modalValues])

    const fetchCreateContent = async () => {
        try {
            setLoading(true)
            await axiosInstance.post("/BranchContents/Add", {
                "branchId": id,
                "content": modalValues.content,
                "title": modalValues.title
            })
            toast.success("İçerik Eklendi", {
                position: "top-center",
                autoClose: 2000
            })
            setModalInfo({
                visible: false,
                editMode: false,
                id: null
            })
            setModalValues({
                content: "",
                title: ""
            })
            getAllBranchContent()
        }
        catch (err) {
          
        }
        finally {
            setLoading(false)
        }
    }



    const updateBranchContent = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.put("/BranchContents/Update", {
                "id": modalInfo.id,
                "branchId": id,
                "content": modalValues.content,
                "title": modalValues.title
            })
            toast.success("Güncelleme başarılı", {
                position: "top-center",
                autoClose: 2000
            })
            setModalInfo({
                visible: false,
                editMode: false,
                id: null
            })
            setModalValues({
                content: "",
                title: ""
            })
            getAllBranchContent()
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getAllBranchContent = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchContents/GetAll", {
                page: 0,
                pageSize: 200
            })
            setContentList(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        getAllBranchContent()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <Button type='primary' onClick={() => {
                    setModalInfo({
                        visible: true
                    })
                }} style={{
                    columnGap: "10px",
                    display: "flex",
                    alignItems: "center"
                }} >
                    <FaPlus /> Ekle
                </Button>
            </StyledHeader>
            <StyledContentContainer  >
                <Table
                    loading={loading}
                    style={{
                        width: "100%"
                    }}
                    dataSource={contentList}
                    columns={[
                        {
                            title: "Başlık",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.title}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "İçerik",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.content?.substring(0, 300)}...
                                    </p>
                                )
                            }
                        },
                        {
                            title: "İşlem",
                            render: (row, record) => {
                                return (
                                    <div style={{
                                        display: "flex",
                                        columnGap: "10px"
                                    }} >
                                        <Button type='primary' onClick={() => {
                                            setModalInfo({
                                                visible: true,
                                                editMode: true,
                                                id: record?.id
                                            })
                                            setModalValues({
                                                title: record?.title,
                                                content: record?.content
                                            })
                                        }} >
                                            <FaEdit />
                                        </Button>
                                        <Button type='primary' danger onClick={() => {
                                            Modal.confirm({
                                                title: "Silmek istediğinize emin misiniz?",
                                                cancelText: "iptal",
                                                okText: "Eminim",
                                                onOk: async () => {
                                                    try {
                                                        setLoading(true)
                                                        await axiosInstance.delete(`/BranchContents/Delete/${record?.id}`)
                                                        toast.success("İçerik silindi", {
                                                            autoClose: 2000,
                                                            position: "top-center"
                                                        })
                                                        await getAllBranchContent()
                                                    }
                                                    catch (err) {

                                                    }
                                                    finally {
                                                        setLoading(false)
                                                    }
                                                }
                                            })
                                        }} >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                )
                            }
                        }
                    ]}
                />
            </StyledContentContainer>
            <Modal
                open={modalInfo.visible}
                title={
                    modalInfo.editMode ? "Güncelle" : "İçerik Ekle"
                }
                onCancel={() => {
                    setModalInfo({
                        visible: false,
                        editMode: false,
                        id: null
                    })
                    setModalValues({
                        content: "",
                        title: ""
                    })
                }}
                footer={() => {
                    return (
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "10px",
                            justifyContent: "flex-end"
                        }} >
                            <Button type='primary' disabled={disableBtn} loading={loading} onClick={() => {
                                if (modalInfo.editMode) {
                                    updateBranchContent()
                                }
                                else {
                                    fetchCreateContent()
                                }
                            }} >
                                {modalInfo.editMode ? "Güncelle" : "Kaydet"}
                            </Button>

                            <Button danger type='primary' onClick={() => {
                                setModalInfo({
                                    visible: false,
                                    editMode: false,
                                    id: null
                                })
                            }} >
                                İptal
                            </Button>
                        </div>
                    )
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "15px",
                    paddingTop: "15px"
                }} >
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }} >
                        <Label>
                            Başlık
                        </Label>
                        <StyledInput value={modalValues.title} placeholder='Başlık' onChange={(e) => {
                            setModalValues({
                                ...modalValues,
                                title: e.target.value
                            })
                        }} />
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }} >
                        <Label>
                            İçerik
                        </Label>
                        <StyledInput.TextArea value={modalValues.content} style={{
                            resize: "none"
                        }} rows={6} placeholder='içerik' onChange={(e) => {
                            setModalValues({
                                ...modalValues,
                                content: e.target.value
                            })
                        }} />
                    </div>
                </div>


            </Modal>
        </StyledContainer>
    )
}

export default EditBranchContent


const StyledContainer = styled.div`
/*    padding: 20px; */
  padding-top: 20px;
`

const StyledHeader = styled.div`
    border-bottom: 1px dashed gray;
 /*    border-radius: 5px; */
    padding: 10px;
    display: flex;
    justify-content: flex-end;
`

const StyledContentContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
`


const StyledCollapse = styled(Collapse)`
  margin-bottom: 30px;
`


const SubElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
  .index_key{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #1677FF;
    color: white;
  }

  .sub_item_desc{
    width: "100%";
  background-color: #8080807d;
  padding: 10px;
  border-radius: 10px;
  transition: .5s all;
  cursor: pointer;
  width: 100%;
  &:hover{
    opacity: .7;
  }
  }
`

const StyledInput = styled(Input)`
    height: 35px;
    border: 1px solid #1677FF;
    resize: none
`