import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import basgan from "../../../assets/images/basgan.png"

const KurumsalContent = () => {
    const { t } = useTranslation()
    return (
        <KurumsalContentContainer  >
            <h4 id="kurumsal_title">{t("kurumsal1")}</h4>
            <p id="kurumsal_text_list">
                {t("kurumsal2")}<br /> <br />

                {t("kurumsal3")} <br />
                {t("kurumsal4")} <br />
                {t("kurumsal5")}  <br /> <br />

                {t("kurumsal6")}  <br />
                {t("kurumsal7")}  <br /> <br />

                {t("kurumsal8")}
            </p>


            <div className="kurumsal_basgan_container">
                <div className='baskan_img_container'>
                    <img src={basgan} alt="" id='baskan_img' />
                </div>

                <div className='kurumsal_basgan_text_list' >
                    <h4 id="basgan_msg_title">{t("kurumsal9")}</h4>
                    <p id="basgan_msg_text_list">

                        {t("kurumsal10")}<br />
                        {t("kurumsal11")} <br />
                        {t("kurumsal12")}  <br />
                        {t("kurumsal13")}  <br />
                        {t("kurumsal14")}   <br />
                        {t("kurumsal15")}  <br />
                        {t("kurumsal16")} <br />
                        {t("kurumsal17")} <br />
                        {t("kurumsal18")}   <br />
                        {t("kurumsal19")}  <br />
                        {t("kurumsal20")}  <br />
                        {t("kurumsal21")} <br />
                        {t("kurumsal22")}   <br />
                    </p>
                    <div className='basgan_name_container' >
                        <p className='name_text' >Uğur İbrahim Altay </p>
                        <p  >{t("kurumsal23")}</p>
                    </div>
                </div>
            </div>
        </KurumsalContentContainer>
    )
}

export default KurumsalContent

const KurumsalContentContainer = styled.div`
    padding: 20px 120px;


@media screen and (max-width:1100px) {
    padding: 20px 40px;
}


@media screen and (max-width:500px) {
    padding: 20px 20px;
}


#kurumsal_title {
    font-weight: bolder;
}

#kurumsal_text_list {
    margin-top: 20px;
}


.kurumsal_basgan_container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-top: 50px;
    column-gap: 40px;
    margin-bottom: 30px;

    @media screen and (max-width:1100px) {
        grid-template-columns: 1fr 2fr;
    }

    @media screen and (max-width:860px) {
        grid-template-columns: 1fr 2fr;
    }

    @media screen and (max-width:500px) {
        grid-template-columns: 1fr;
        row-gap: 30px;
    }

    .kurumsal_basgan_text_list {
        display: flex;
        flex: 1;
        flex-direction: column;
        row-gap: 20px;

        #basgan_msg_title {
            font-weight: bold;
        }

        #basgan_msg_text_list {
            line-height: 16px;
            flex-grow: 1;

            @media screen and (min-width:1600px) {
                line-height: 20px;
            }
        }
    }

    .basgan_name_container {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;

        .name_text {
            font-weight: bold;
            font-size: 16px;
        }
    }
}

    .baskan_img_container {
        img {
            width: 100%;

        }
    }
`