import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Button, Input } from 'reactstrap'
import "./examQuestion.scss"
import Countdown from 'react-countdown'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useNavigate, useParams } from 'react-router-dom'
import { examTestResultApi, examYaziliResultApi, studentGetByExamIdApi } from '../../../api/exam'
import styled from 'styled-components'
import LoadingElement from '../../../Components/Loader/LoadinElement'
import { ExamContext } from '../ExamDashboard/ExamDashboard'
import { getMinuteDifference } from '../../../utils/utils'
import { Alert, Modal, Button as AntButton } from 'antd'
import { useFullScreenHandle } from 'react-full-screen'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../api/axiosInstance'
import { Modal as RModal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import Timer from '../../../Components/Timer/Timer'


const ExamQuestions = () => {
  const { detailExam, handle } = useContext(ExamContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState()
  const [prelimTime, setPrelimTime] = useState({
    startDate: null,
    endDate: null
  })
  const [imageModal, setImageModal] = useState({
    visible: false,
    img: null
  })
  const [questionNumber, setQuestionNumber] = useState(0)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [questionInfo, setQuestionInfo] = useState()
  const [timeControl, setTimeControl] = useState(true)

  const [isModalOpen, setIsModalOpen] = useState({
    visible: false,
    children: null,
  });

  const [selectedOption, setSelectedOption] = useState()




  const examType = useMemo(() => {
    return detailExam?.type == "Test"
  }, [detailExam])


  const getQuestionProcess = async () => {
    try {
      const response = await studentGetByExamIdApi(id)

      setLoading(true)
      setPrelimTime({
        endDate: response.data?.endDate,
        startDate: response.data?.startDate
      })
      setTotalQuestion(response.data.count)
      setQuestionNumber(response.data.index)
      setQuestionInfo(response.data.items[0])
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }


  const answerList = useMemo(() => {
    return questionInfo?.examAnswers
  }, [questionInfo])

  const remainingEndTime = useMemo(() => {
    if (detailExam?.formal == "Ön Sınav") {

      const now = new Date();
      const future = new Date(prelimTime.endDate);
      const differenceInMilliseconds = future - now;
      if (differenceInMilliseconds >= 0) {
        return Math.floor(differenceInMilliseconds / 1000);
      }
      return 0


    } else {
      const now = new Date();
      const future = new Date(detailExam?.endTime);
      const differenceInMilliseconds = future - now;
      console.log("nowww", now)
      console.log("futuree", future)
      console.log("differenceInMilliseconds", differenceInMilliseconds)
      console.log("differenceInMilliseconds / 1000", differenceInMilliseconds / 1000)
      if (differenceInMilliseconds >= 0) {
        return Math.floor(differenceInMilliseconds / 1000);
      }
      return 0

    }


  }, [detailExam, prelimTime])


  const formatRemainingTime = (remainingTime) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  function beklet(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // burası yorum satırı
  const saveAndFinishExamTest = async () => {
    try {
      const response = await examTestResultApi({
        examAnswerId: selectedOption?.id
      })

      if (questionNumber == totalQuestion) {
        if (detailExam?.formal == "Ön Sınav") {
          const prelimResult = await axiosInstance.get(`/examResults/GetResultForPrelim/${id}`)

          toast.success("Soru cevaplandı , sınav tamamlandı.", {
            autoClose: 20000,
            position: "top-center"
          })
          setIsModalOpen({
            children: (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
                <p style={{ fontSize: 20, textAlign: "center", margin: "15px 0" }} >
                  Alınan Puan : <span style={{ fontWeight: "bold" }} >
                    {prelimResult.data?.point}
                  </span>
                </p>
                <p style={{ textAlign: "center", fontSize: 20 }} >
                  Geçer Puan : <span style={{ fontWeight: "bold", color: "red" }} >
                    60
                  </span>
                </p>
                <p style={{ textAlign: "center", fontSize: 14, marginTop: 10, marginBottom: "10px", border: "1px solid gray", borderRadius: 5, padding: 10 }} >
                  {
                    prelimResult.data?.message
                  }
                </p>
                <AntButton type='primary' onClick={() => {
                  navigate("/panel/sinavlarim")
                }} >
                  Panele Dön
                </AntButton>
                {/* <Alert type='info' message="Panele yönlendiriliyorsunuz" /> */}
              </div>
            ),
            visible: true
          })
          /* await beklet(20000)
          navigate("/panel/sinavlarim") */
        }
        else {
          toast.success("Soru cevaplandı , sınav tamamlandı.", {
            autoClose: 5000,
            position: "top-center"
          })
          setIsModalOpen({
            ...isModalOpen,
            visible: true,
            children: (
              <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", margin: "50px 0" }} >
                Sınav Tamamlandı cevaplarınız kayıt ediliyor.
              </p>
            )
          })
          await beklet(5000)
          navigate("/panel/sinavlarim")
        }


      }
      else {
        await getQuestionProcess()
        toast.success("Cevabınız kayıt edildi.", {
          autoClose: 2000,
          position: "top-center"
        })
      }

      setSelectedOption()
    }
    catch (err) {

    }
  }



  const saveAndNextQuestion = async () => {
    try {
      if (examType) {
        await saveAndFinishExamTest()

      }
      else {
        if (questionNumber == totalQuestion) {
          await examYaziliResultApi({
            examQuestionId: questionInfo.id,
            answer: selectedOption
          })

          toast.success("Soru cevaplandı , sınav tamamlandı.", {
            autoClose: 5000,
            position: "top-center"
          })
          setIsModalOpen({
            ...isModalOpen,
            visible: true,
            children: (
              <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", margin: "50px 0" }} >
                Sınav tamamlandı panele yönlendiriliyorsunuz
              </p>
            )
          })
          await beklet(5000)

          navigate("/panel/sinavlarim")
        }
        else {
          await examYaziliResultApi({
            examQuestionId: questionInfo.id,
            answer: selectedOption
          })
          await getQuestionProcess()
          toast.success("Soru cevaplandı", {
            autoClose: 2000,
            position: "top-center"
          })
        }

        setSelectedOption("")
      }
    }
    catch (err) {

    }
  }

  const disableNextBtn = useMemo(() => {
    if (selectedOption) {
      return false
    }
    return true
  }, [selectedOption])


  useEffect(() => {
    getQuestionProcess()
  }, [])

  return (
    <>

      <div className='exam_question_container' >
        {
          loading ? (
            <LoadingElement />
          ) : (
            <div className='exam_question_wrapper'>
              <ImageWrapper onClick={() => {

              }} imgGrid={questionInfo?.imageUrl !== "Dosya Seçilmedi."} >
                {
                  questionInfo?.imageUrl !== "Dosya Seçilmedi." && (
                    <QuestionImg src={questionInfo?.imageUrl} alt="" onClick={() => {

                      setImageModal({
                        visible: true,
                        img: questionInfo?.imageUrl
                      })
                    }} />
                  )
                }
                <QuestionTitleInput disabled value={questionInfo?.question ?? "içerik yok"} />
              </ImageWrapper>
              <PointElement  >
                <p style={{ backgroundColor: "green", color: "white", padding: "5px 10px", borderRadius: 5 }} >
                  Puan : {questionInfo?.point}
                </p>
              </PointElement>
              {
                examType ? (
                  <div className='question_options_wrapper' >
                    {
                      answerList?.map(el => {
                        return (
                          <QuestionOptionItem
                            key={el?.id}
                            selected={selectedOption?.id == el?.id}
                            onClick={() => {
                              setSelectedOption(el)
                            }}
                          >
                            {el?.answer}
                          </QuestionOptionItem>

                        )
                      })
                    }
                  </div>

                ) : (
                  <ExamYaziliQuestionWrapper>
                    <p>
                      Cevap
                    </p>
                    <Input placeholder='cevap' value={selectedOption} type='textarea' rows={6} onChange={(e) => {
                      setSelectedOption(e.target.value)
                    }} />
                  </ExamYaziliQuestionWrapper>
                )
              }
              <div>
              </div>
            </div>
          )
        }

        <div className='exam_question_info_tab' >
          <div className='exam_question_info_student_name' >
            <p>
              Kalan Süre
            </p>
            <Timer
              duration={remainingEndTime}
              onTimeEnd={async () => {
                setIsModalOpen({
                  ...isModalOpen,
                  visible: true,
                  children: (
                    <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", margin: "50px 0" }} >
                      Sınav Süresi bitti, panele yönlendiriliyorsunuz
                    </p>
                  )
                })
                await beklet(7000)

                navigate("/panel/sinavlarim")
              }}
            />
            {/* <CountdownCircleTimer
              isPlaying={timeControl}
              duration={remainingEndTime}
              onComplete={async () => {

                setIsModalOpen({
                  ...isModalOpen,
                  visible: true,
                  children: (
                    <p style={{ fontWeight: "bold", fontSize: 20, textAlign: "center", margin: "50px 0" }} >
                      Sınav süresi bitti, panele yönlendiriliyorsunuz
                    </p>
                  )
                })
                await beklet(10000)

                navigate("/panel/sinavlarim")
              }}
              colors={['#004777', '#F7B801', '#A30000', '#A30000']}
              colorsTime={[(remainingEndTime / 4), (remainingEndTime / 4), (remainingEndTime / 4), (remainingEndTime / 4)]}
              size={60}

              strokeWidth={5}
            >
              {({ remainingTime }) => <span style={{ fontSize: 12 }} >
                {formatRemainingTime(remainingTime)}
              </span>}
            </CountdownCircleTimer> */}

          </div>
          <div className='exam_question_info_content' >
            <QuestionInfoViews  >
              {
                [...Array(totalQuestion).keys()].map((el, index) => {
                  return <QuestionIndexTime selected={index < questionNumber} >
                    {index + 1}
                  </QuestionIndexTime>
                })
              }
            </QuestionInfoViews>
          </div>
          <div className='question_status_bar' >
            <StyledNextButton disabled={disableNextBtn} onClick={async () => {
              saveAndNextQuestion()

              /* saveAndNextQuestion() */
              /*  setIsModalOpen(true)
               await beklet(5000)
                  setIsModalOpen(false) */
            }}>
              {questionNumber == totalQuestion ? "Sınavı tamamla" : "ilerle"}
            </StyledNextButton>
          </div>
        </div>
      </div >

      <Modal closable={false} title="Uyarı" open={isModalOpen.visible} centered={true}  onOk={() => {
      }} zIndex={10000} footer={[]}  >
        {isModalOpen.children}
      </Modal>
      <RModal isOpen={imageModal.visible} size='lg'  centered >
        <ModalHeader>
          Fotoğraf
        </ModalHeader>
        <ModalBody>
          <img src={imageModal.img} alt="" style={{ width: "100%" }} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => {
            setImageModal({
              visible: false,
              img: null
            })
          }} >
            Kapat
          </Button>
        </ModalFooter>
      </RModal>
      {/* <Modal title="Fotoğraf" open={imageModal.visible} onCancel={() => {
        setImageModal({
          visible: false,
          img: null
        })
      }} okText={null}  >

      </Modal> */}


    </>

  )
}

export default ExamQuestions


const StyledNextButton = styled(Button)`
  width:100%;
`


const QuestionInfoViews = styled.div`
 display: grid;
                width: 100%;
                height: max-content;
                grid-template-columns: repeat(6,1fr);
                margin-top: 20px;
                row-gap: 20px;
`



const QuestionIndexTime = styled.div`
              background-color: ${props => props.selected ? "green" : "gray"};
              display: flex;
          align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    color: white;
                    border-radius: 50%;
`


const QuestionOptionItem = styled.div`
  background-color: ${props => props.selected ? "#088F7D" : "#80808029"};
  color: ${props => props.selected ? "white" : "black"};
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;
  border: 1px solid gray;
  resize: none;
  height: 60px;
  overflow-y: auto;
  white-space: pre-wrap;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.imgGrid ? "1fr 2fr" : "1fr"};

  column-gap: 20px;
  @media (max-width:1000px){
    grid-template-columns:  1fr;
    row-gap: 10px;
  }
`

const QuestionTitleInput = styled.textarea`
            height: 300px;
            min-height: 150px !important;
            resize: none;

            background-color: #EFF2F7;
        /*     box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.75); */
            padding: 20px 30px;
            border-radius: 5px;
            border: 1px solid gray;
            max-height: 150px;
            overflow-y: scroll;
            width: 100%;
            height: 100%;
            flex-grow: 1;
            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;

            }

            &::-webkit-scrollbar-thumb {
                background: gray;
                border-radius: 30px;
            }
`

const QuestionImg = styled.img`
  height: 100%;
  border-radius: 5px;
  min-height: 150px;
  max-height: 150px;    
  width:100% ;
  /* object-fit: contain; */
  border-radius: 5px;
  cursor: zoom-in;
`

const ExamYaziliQuestionWrapper = styled.div`
   display: flex;
  flex-direction: column;
  row-gap: 20px;
/*   margin-top: 70px; */
  p{
    font-weight: 600;
    font-size: 16px;
  }
`

const PointElement = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`