import React, { useMemo, useState } from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter, Input, Button } from "reactstrap"
import { Table as AntTable, Input as AntInput, Button as AntButton } from "antd"
import styled from 'styled-components'

const CourseUserAbsencesModal = ({ modalData, setModalData }) => {
    const [searchText, setSearchText] = useState("")

    const searchData = useMemo(() => {
        return modalData?.data.filter(el => el != undefined).filter(el => {
            return el?.firstName?.toLowerCase()?.search(searchText.toLowerCase()) !== -1
        })
    }, [modalData, searchText])

    console.log("search", searchData)

    return (
        <Modal isOpen={modalData.show} size='lg' >
            <StyledModalHeader>

                <StyledAbsenceModalTime >
                    {modalData.calendarInfo?.startTime}
                </StyledAbsenceModalTime>
                <StyledCourseName >
                    {modalData.calendarInfo?.courseName}
                </StyledCourseName>

            </StyledModalHeader>
            <ModalBody>
                <AntInput placeholder='Ara' style={{ marginBottom: "10px", height: "35px" }} onChange={(e) => {
                    setSearchText(e.target.value)
                }} />
                <AntTable
                    size='middle'
                    dataSource={searchData}
                    pagination={{
                        pageSize: 5
                    }}
                    scroll={{
                        x: 600
                    }}
                    columns={[
                        {
                            title: "Ad Soyad",
                            render: (row, record) => {
                                return (
                                    <p style={{ textTransform: "capitalize" }} >
                                        <span style={{ marginRight: "5px" }}>
                                            {record?.firstName} {record?.lastName}
                                        </span>
                                        {
                                            record.userOperationClaims[0]?.operationClaimId == 2 && (
                                                <span>
                                                    ( Eğitmen )
                                                </span>
                                            )
                                        }
                                    </p>
                                )
                            }
                        },
   
                        {
                            title: "Aktif Süre",
                            render: (row, record) => {
                                return (
                                    <span>
                                        {record?.userActiveTime.toFixed(2)} (Dk)
                                    </span>
                                )
                            },
                            sorter: (a, b) => a?.userActiveTime - b?.userActiveTime
                        },
                        {
                            title: "Giriş Vakti",
                            render: (row, record) => {
                                return (
                                    <span>
                                        {new Date(record?.registeredOn).toLocaleTimeString()}
                                    </span>
                                )
                            }
                        },
                        {
                            title: "Ayrılma Vakti",
                            render: (row, record) => {
                                return (
                                    <span>
                                        {new Date(record.leftOn).toLocaleTimeString()}
                                    </span>
                                )
                            }
                        }
                    ]}
                />
            </ModalBody>

            <ModalFooter>
                <AntButton type="primary" danger onClick={() => {
                    setModalData({
                        show: false,
                        data: [],
                        calendarInfo: null
                    })
                }} >
                    Kapat
                </AntButton>
            </ModalFooter>
        </Modal>
    )
}

export default CourseUserAbsencesModal

const StyledModalHeader = styled(ModalHeader)`
    width: 100%;
    h5{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const StyledAbsenceModalTime = styled.p`
    font-size: 14px;

    @media screen and (max-width:600px) {
        font-size: 10px;
    }
`

const StyledCourseName = styled.p`
     font-size: 14px;
    text-transform: capitalize;
@media screen and (max-width:600px) {
    font-size: 10px;
}
`