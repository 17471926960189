import React, { useEffect, useMemo, useState } from 'react'
import { Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { Button as AntButton, Select } from "antd"
import { axiosInstance } from '../../api/axiosInstance'
import styled from 'styled-components'
const AddBranchModal = ({ formik, modalShow, setModalShow }) => {


    const [categorieList, setCategorieList] = useState([])
    const [categoryLoading, setCategoryLoading] = useState(false)


    const getAllCategories = async () => {
        try {
            setCategoryLoading(true)
            const response1 = await axiosInstance.post("/categories/getall", {
                page: 0,
                pageSize: 5
            })
            const response2 = await axiosInstance.post("/categories/getall", {
                page: 0,
                pageSize: response1.data?.count ?? 200
            })
            setCategorieList(response2.data.items)

        }
        catch (err) {

        }
        finally {
            setCategoryLoading(false)
        }
    }


    const disableBtnControl = useMemo(() => {

        if (Object.values(formik.errors).length === 0) {
            return false
        }
        return true

    }, [formik])

    useEffect(() => {
        getAllCategories()
    }, [])

    return (
        <Modal isOpen={modalShow.show}>
            <ModalHeader>
                {
                    modalShow.type == "edit" ? "Branş Düzenle" : "Branş Ekle"
                }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={12}>
                        <div className="mb-3">
                            <Label className="form-label" style={{ fontWeight: "600" }} >
                                İsim
                            </Label>
                            <Input
                                style={{
                                    marginLeft: 0
                                }}
                                type="text" className="form-control" id="name" name='name'
                                placeholder='isim'
                                value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched.name && formik.errors.name ? true : false
                                }
                            />
                            {formik.touched.name && formik.errors.name ? (
                                <FormFeedback type="invalid"><div>{formik.errors.name}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mb-3">
                            <Label className="form-label" style={{ fontWeight: "600" }} >
                                Etiket
                            </Label>
                            <Input
                                style={{
                                    marginLeft: 0
                                }}
                                type="text" className="form-control" id="tag" name='tag'
                                placeholder='isim'
                                value={formik.values.tag} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched.tag && formik.errors.tag ? true : false
                                }
                            />
                            {formik.touched.tag && formik.errors.tag ? (
                                <FormFeedback type="invalid"><div>{formik.errors.tag}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mb-3">
                            <Label className="form-label">
                                Kategori
                            </Label>
                            <StyledSelect
                                onChange={(e) => {
                                    formik.setFieldValue("categoryId", e)
                                }}
                                showSearch
                                optionFilterProp='label'
                                filterOption={(input, option) => {
                                    
                                    return (
                                        option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                                    )
                                }}
                                options={[
                                    {
                                        "label": "Kategori Seçiniz",
                                        "value": ""
                                    },
                                    ...categorieList.map((el, index) => {
                                        return {
                                            "label": el?.name,
                                            "value": el?.id
                                        }
                                    })
                                ]}
                                value={formik.values.categoryId} />
                            {formik.touched.categoryId && formik.errors.categoryId ? (
                                <StyledErrorMessage >{formik.errors.categoryId}</StyledErrorMessage>
                            ) : null}
                        </div>
                    </Col>

                    <Col lg={12}>
                        <div className="mb-3">
                            <Label className="form-label" style={{ fontWeight: "600" }} >
                                Açıklama
                            </Label>
                            <Input
                                style={{
                                    marginLeft: 0,
                                    resize: "none"
                                }}

                                rows={6}
                                type="textarea" className="form-control" id="description" name='description'
                                placeholder='açıklama'
                                value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched.description && formik.errors.description ? true : false
                                }
                            />
                            {formik.touched.description && formik.errors.description ? (
                                <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <AntButton disabled={disableBtnControl} type='primary' onClick={() => {
                    formik.handleSubmit()
                }} >
                    {
                        modalShow.type == "edit" ? "Güncelle" : "Ekle"
                    }
                </AntButton>
                <AntButton type='primary' danger onClick={() => {
                    formik.resetForm()
                    setModalShow({
                        show: false,
                        type: "create"
                    })
                }} >
                    İptal
                </AntButton>

            </ModalFooter>
        </Modal>
    )
}

export default AddBranchModal


const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 3px;
font-size: 11px;

`


const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`