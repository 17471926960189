import { Table, Input as AntInput, Button as AntButton, Select } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'

const OgrencilerimDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [studentList, setStudenList] = useState([])
    const [searchTextName, setSearchTextName] = useState("")
    const [takenCourseList, setTakenCourseList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState("hepsi")
    const fetchRegisterStudents = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/CourseRegisters/GetAllTeacherAndStudentForNotifications", {
                page: 0,
                pageSize: 10000
            })

            setStudenList(response.data.items.map(item => {
                const { user, ...rest } = item
                return {
                    ...rest,
                    ...user
                }
            }))

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const getMyTakenCourse = async () => {
        try {

            const response = await axiosInstance.post("/courses/GetCalendarForAllRoles", {
                page: 0,
                pageSize: 1
            })
            const responsev2 = await axiosInstance.post("/courses/GetCalendarForAllRoles", {
                page: 0,
                pageSize: response.data.count
            })

            setTakenCourseList(responsev2.data?.items)

        }
        catch (err) {

        }
        finally {

        }
    }

    const courseList = useMemo(() => {
        let result = takenCourseList?.map(item => {
            return {
                name: item?.name,
                id: item?.id
            }
        })
        return result ?? []
    }, [takenCourseList])


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                   
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });


    const resultData = useMemo(() => {
        if (selectedCourse == "hepsi") {
            return studentList
        }
        return studentList.filter(item => item?.courseId == selectedCourse)

    }, [selectedCourse, studentList])


    useEffect(() => {
        fetchRegisterStudents()
        getMyTakenCourse()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledCourseListSelect
                    onChange={(e) => {
                        setSelectedCourse(e)
                    }}
                    value={selectedCourse}
                    options={[
                        {
                            label: "Hepsi",
                            value: "hepsi"
                        },
                        ...courseList?.map(item => {
                            return {
                                label: `${item?.name}-${item?.id}`,
                                value: item?.id
                            }
                        })
                    ]}

                />
            </StyledHeader>
            <Table
                loading={loading}
                scroll={{
                    x: 600
                }}
                dataSource={resultData}
                columns={[
                    {
                        "title": "İsim",
                        dataIndex: "firstName",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.firstName}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("firstName")
                    },
                    {
                        "title": "Soyisim",
                        dataIndex: "lastName",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.lastName}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("lastName")
                    },
                    {
                        "title": "Email",
                        dataIndex: "email",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.email}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("email")
                    },
                    {
                        "title": "Telefon",
                        dataIndex: "phone",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.phone}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("phone")
                    }
                ]}
            />
        </StyledContainer>
    )
}

export default OgrencilerimDashboard


const StyledContainer = styled.div`
    padding: 15px;

`

const StyledCourseListSelect = styled(Select)`
    width: 100%
`

const StyledHeader = styled.header`
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px dashed gray;
    margin-bottom: 10px;
`