import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Button, Col, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { createCourseDatesApi } from '../../../api/bbb';
import { Card, Collapse, DatePicker, Select as AntSelect, Skeleton, Alert } from 'antd';
import moment from 'moment';
import "./index.scss"
import * as yup from "yup"
import { SlArrowDown } from "react-icons/sl";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import CustomAccordionHeader from '../Common/CustomAccordionHeader';
import styled from 'styled-components';
import { GetCalendarForAllRoles } from '../../../api/Course';

const TeacherCourseTable = () => {

    const [courseType, setCourseType] = useState(0)
    const [userData, setUserData] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const getUserCourse = async () => {
        try {
            setLoading(true)
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 100
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })
            console.log("responvexx",responsev2.data)
            const result = responsev2?.data.items?.filter(el => el?.status == false) ?? []
            console.log("rexx",result)
            setUserData(result)
        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }

    const convertTrFormat = (date) => {
        const tarih = new Date(date)
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const turkishDate = new Intl.DateTimeFormat('tr-TR', options).format(tarih);
        return turkishDate
    }

    const mainData = useMemo(() => {
        if (courseType === 0) {
            return userData;
        } else if (courseType === 1) {
            return userData?.filter(el => el?.type === "Offline");
        } else {
            return userData?.filter(el => el?.type === "Online");
        }
    }, [userData, courseType]);



    const OfflineOrOnline = (el) => {
        if (el.type == "Online") {
            return (
                <Row>
                    <Col lg={6}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Kurs Başlangıç Tarihi</Label>
                            <p>{convertTrFormat(el.startDate)}</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Kurs Bitiş Tarihi</Label>
                            <p>{convertTrFormat(el.endDate)}</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Dönem</Label>
                            <p>{el.semester}</p>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Kontenjan</Label>
                            <p>{el.limit}</p>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Açıklama</Label>
                            <p>{el.description}</p>
                        </div>
                    </Col>
                </Row>
            )
        }
        else {
            return (
                <Row>
                    <Col lg={12}>
                        <div className='course_card_item'>
                            <Label className='course_card_item_title'>Açıklama</Label>
                            <p>{el.description}</p>
                        </div>
                    </Col>
                </Row>
            )

        }
    }


    useEffect(() => {
        getUserCourse()
    }, [])


    console.log("mainData",mainData)

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px" }} >
                <Skeleton />
            </div>
        )
    }


    return (
        <>
            <div>
                <StyledHeader>
                    <StyledAntSelect
                        value={courseType}
                        onChange={(e) => {
                            setCourseType(e)
                        }}
                        options={[
                            {
                                "label": "Hepsi",
                                value: 0
                            },
                            {
                                "label": "Çevrimdışı",
                                "value": 1
                            },
                            {
                                "label": "Çevrimiçi",
                                "value": 2
                            }
                        ]}
                    >
                    </StyledAntSelect>
                </StyledHeader>
                <div>
                    {mainData?.length != 0 ? mainData?.map((el, index) => (
                        <Collapse
                            style={{ marginTop: "30px" }}
                            expandIcon={({ isActive }) => null}
                            key={index}
                        >
                            <Collapse.Panel header={CustomAccordionHeader(el, navigate)} key={index}>
                                {OfflineOrOnline(el)}
                            </Collapse.Panel>
                        </Collapse>
                    )) : (
                        <StyledAlert>
                            <Alert type='info' message="Kurs bulunamadı" />

                        </StyledAlert>

                    )}
                </div>

            </div>
        </>
    )
}

export default TeacherCourseTable

const StyledHeader = styled.div`
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    display: flex;
    /* justify-content: flex-end; */
`

const StyledAntSelect = styled(AntSelect)`
    width: 250px;
`
const StyledAlert = styled.div`
    margin-top: 30px;
`