import React, { useState } from 'react'
import AddOfflineCourseForm from './AddOfflineCourseForm';
import { useFormik } from "formik"
import * as yup from "yup"
import { Steps, Modal as AntModal } from "antd"
import { toast } from "react-toastify"
import { createCourseApi } from '../../../api/Course';
import AddOfflineCourseContent from './CreateContentOfflineCourse/AddOfflineCourseContent';
const CreateOfflineCourseDashboard = () => {

    const formik = useFormik({
        initialValues: {
            description: "",
            limit: 0,
            semester: "Güz",
            type: "Offline",
            startDate: "",
            endDate: "",
            registerTime: "",
            genderType: "",
            startYear: 0,
            endYear: 0,
            discontinuity: 0,
            registerDeleteStatus: "",
            previewStatus: "",
            categoryId: "",
            prelim: false,
            totalTime: "",
            branchId: "",
        },
        validationSchema: yup.object({
            previewStatus: yup.string().required(),
            registerDeleteStatus: yup.string().required(),
            categoryId: yup.string().required(),
            branchId: yup.string().required(),
            totalTime: yup.number().required(),
            description: yup.string().required(),
        }),
        onSubmit: async (value, { resetForm }) => {
            AntModal.confirm({
                content: (
                    <p>
                        Bu kursu eklemek istediğinize emin misiniz ?
                    </p>
                ),
                cancelText: "İptal",
                okText: "Onayla",
                onOk: async () => {
                    try {
                        const { startYear, genderType, totalTime, branchId, limit, prelim, endYear, startDate, endDate, registerTime, previewStatus, registerDeleteStatus, discontinuity, categoryId, ...rest } = value
                 
                        const reqBody = {
                            ...rest,
                            prelim: prelim,
                            startYear: 1901,
                            endYear: 1902,
                            limit: 1,
                            previewStatus: previewStatus == "Evet",
                            registerDeleteStatus: registerDeleteStatus == "Evet",
                            discontinuity: 1,
                            categoryId: parseInt(categoryId),
                            branchId: branchId,
                            genderType: "Hepsi",
                            startDate: new Date("2024-06-26T11:00:00Z"),
                            endDate: new Date("2024-06-30T11:00:00Z"),
                            registerTime: new Date("2024-06-25T11:00:00Z"),
                            registerStartTime: new Date("2024-06-24T11:00:00Z"),
                            totalTime: totalTime
                        }
                        /* setCurrent(1) */
                  
                        const response = await createCourseApi(reqBody)
                        resetForm()
                        toast.success("Kurs Kaydedildi", {
                            autoClose: 1500
                        })
                    }
                    catch (err) {


                    }
                }
            })



        },

    })


    const ornke = {
        "name": "matematik",
        "semester": "sasdsadsad",
        "description": "string",
        "type": "online",
        "genderType": "string",
        "limit": 10,
        "startYear": 2000,
        "endYear": 2005,
        "startDate": "2024-03-20T10:08:40.190Z",
        "endDate": "2024-03-25T10:08:40.190Z"
    }

    return (
        <>
            <AddOfflineCourseForm formik={formik} />
        </>
    )
}

export default CreateOfflineCourseDashboard