import { Button, Popover, Select, Steps, Modal, Alert } from 'antd'
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { createBranch } from '../../api/Branch';
import { axiosInstance } from '../../api/axiosInstance';
import * as yup from "yup"
import { FaFile } from 'react-icons/fa';
const AddBranchDashboard = () => {



  const [categorieList, setCategorieList] = useState([])
  const [categoryLoading, setCategoryLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const fileRef = useRef()
  const [branchFile, setBranchFile] = useState({
    name: "",
    file: null
  })
  const getAllCategories = async () => {
    try {
      setCategoryLoading(true)
      const response1 = await axiosInstance.post("/categories/getall", {
        page: 0,
        pageSize: 5
      })
      const response2 = await axiosInstance.post("/categories/getall", {
        page: 0,
        pageSize: response1.data?.count ?? 200
      })
      setCategorieList(response2.data.items)

    }
    catch (err) {

    }
    finally {
      setCategoryLoading(false)
    }
  }




  const handleImage = (e) => {
    const file = e.target.files[0]
    setBranchFile({
      name: file?.name,
      file: file
    })

  }



  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      description: "",
      tag: "",
      categoryId: "",
      type: ""
    },
    validationSchema: yup.object({
      name: yup.string().required("İsim Giriniz"),
      categoryId: yup.string().required("Kategori seçiniz"),
      type: yup.string().required("Tip seçiniz"),
      tag: yup.string().required("Etiket Giriniz"),
      description: yup.string().required("Açıklama giriniz")
    }),
    onSubmit: async (value, { resetForm }) => {

      if (!branchFile.file) {
        toast.error("Lütfen dosya seçiniz", {
          position: "top-center",
          autoClose: 2000
        })
      }
      else {
        Modal.confirm({
          title: "Branşı eklemek istediğinize emin misiniz?",
          okText: "Eminim",
          cancelText: "İptal",
          onOk: async () => {
            try {
              setSaveLoading(true)
              const formData = new FormData()
              formData.append("formFile", branchFile.file)
              const encodedDescription = encodeURIComponent(value.description)
              const encodedName = encodeURIComponent(value.name)
              const encodedTag = encodeURIComponent(value?.tag)
              await axiosInstance.post(`/branches/add?name=${encodedName}&description=${encodedDescription}&label=${encodedTag}&categoryId=${value.categoryId}&type=${value.type}`, formData)

              toast.success("branş kayıt edildi", {
                autoClose: 2500,
                position: "top-center"
              })
              resetForm()
              setBranchFile({
                name: "",
                file: null
              })
            }
            catch (err) {

            }
            finally {
              setSaveLoading(false)
            }
          }
        })
      }


    }



  })



  useEffect(() => {
    getAllCategories()
  }, [])

  return (
    <>
      <StyledContainer>


        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" style={{ fontWeight: "600" }} >
                İsim
              </Label>
              <Input
                style={{
                  marginLeft: 0
                }}
                type="text" className="form-control" id="name" name='name'
                placeholder='isim'
                value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                invalid={
                  formik.touched.name && formik.errors.name ? true : false
                }
              />
              {formik.touched.name && formik.errors.name ? (
                <FormFeedback type="invalid"><div>{formik.errors.name}</div></FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label" style={{ fontWeight: "600" }} >
                Etiket
              </Label>
              <Input
                style={{
                  marginLeft: 0
                }}
                type="text" className="form-control" id="tag" name='tag'
                placeholder='isim'
                value={formik.values.tag} onChange={formik.handleChange} onBlur={formik.handleBlur}
                invalid={
                  formik.touched.tag && formik.errors.tag ? true : false
                }
              />
              {formik.touched.tag && formik.errors.tag ? (
                <FormFeedback type="invalid"><div>{formik.errors.tag}</div></FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">
                Kategori
              </Label>
              <StyledSelect
                onChange={(e) => {
                  formik.setFieldValue("categoryId", e)
                }}
                showSearch
                optionFilterProp='label'
                filterOption={(input, option) => {

                  return (
                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                  )
                }}
                options={[
                  {
                    "label": "Kategori Seçiniz",
                    "value": ""
                  },
                  ...categorieList.map((el, index) => {
                    return {
                      "label": el?.name,
                      "value": el?.id
                    }
                  })
                ]}
                value={formik.values.categoryId} />
              {formik.touched.categoryId && formik.errors.categoryId ? (
                <StyledErrorMessage >{formik.errors.categoryId}</StyledErrorMessage>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <Label className="form-label">
                Kurs Türü
              </Label>
              <StyledSelect
                onChange={(e) => {
                  formik.setFieldValue("type", e)
                }}
                showSearch
                optionFilterProp='label'
                filterOption={(input, option) => {

                  return (
                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                  )
                }}
                options={[
                  {
                    "label": "Tip Seçiniz",
                    "value": ""
                  },
                  {
                    label: "Canlı Eğitim",
                    value: "Online"
                  },
                  {
                    label: "Video Eğitim",
                    value: "Offline"
                  }
                ]}
                value={formik.values.type} />
              {formik.touched.type && formik.errors.type ? (
                <StyledErrorMessage >{formik.errors.type}</StyledErrorMessage>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label className="form-label" style={{ fontWeight: "600" }} >
                Açıklama
              </Label>
              <Input
                style={{
                  marginLeft: 0,
                  resize: "none"
                }}

                rows={6}
                type="textarea" className="form-control" id="description" name='description'
                placeholder='açıklama'
                value={formik.values.description} onChange={formik.handleChange} onBlur={formik.handleBlur}
                invalid={
                  formik.touched.description && formik.errors.description ? true : false
                }
              />
              {formik.touched.description && formik.errors.description ? (
                <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3">
              <Label className="form-label" style={{ fontWeight: "600" }} >
                Dosya
              </Label>
              {
                branchFile.file ? (
                  <StyledFileName  >
                    {branchFile?.name}
                  </StyledFileName>
                ) : (
                  <Alert message="Lütfen dosya seçiniz" type='info' />
                )
              }

            </div>
          </Col>
        </Row>
        <input type="file" style={{ display: "none" }} ref={fileRef} onChange={handleImage} />
        <StyledFooter>
          <Button loading={saveLoading} type='primary' style={{
            display: "flex",
            alignItems: "center"
          }} onClick={() => {
            fileRef.current.click()
          }} >
            <FaFile />  Dosya
          </Button>
          <Button type='primary' onClick={() => {
            formik.handleSubmit()
          }} >
            Kaydet
          </Button>
        </StyledFooter>
      </StyledContainer>

    </>

  )
}

export default AddBranchDashboard


const StyledContainer = styled.div`
   padding: 20px;

`

const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 3px;
font-size: 11px;

`


const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`

const StyledFooter = styled.div`
  display: flex;
  justify-content:space-between;
  align-items: center;
  border: 1px dashed gray;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
`

const StyledFileName = styled.p`
  padding: 5px;
  border: 1px dashed gray;
  border-radius: 5px;
`