import React from 'react'
import imgLesson from "../../../../assets/images/imgLesson.png"
import Star from "../../../../assets/images/star.png"
import HalfEllipse from "../../../../assets/images/halfEllipse.png"
import Ellipse from "../../../../assets/images/ellipse.png"
import EllipseEmpty from "../../../../assets/images/ellipseEmpty.png"
import { useTranslation } from 'react-i18next'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const HomeUzaktanEgitim = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()


    return (
        <StyledContainer  >
            <div className='uzaktan_egitim_image_container' >
                <img src={imgLesson} alt="" id='imgLesson' />
                <img src={Star} alt=" " id='imgStar' />
                <img src={HalfEllipse} alt="" id='halfEllipse' />
                <img src={Ellipse} alt="" id='img_ellipse' />
                <img src={EllipseEmpty} alt="" id='img_ellipse_empty' />

            </div>
            <div className='uzaktan_egitim_content' >
                <p className='uzaktan_egitim_content_p1' >
                    {t("text")}

                    <span>
                        {t("text2")}
                    </span>
                </p>
                <p className='uzaktan_egitim_content_description' >
                    {t("textHomeModal")}
                </p>
                <Button className='uzaktan_egitim_devamini_oku_btn' onClick={() => {
                    navigate("/uzaktanegitim")
                }} >
                    {t("btnfisrt")}
                </Button>
                {/*  <button className='uzaktan_egitim_devamini_oku_btn' >
                        {t("text4")} 
                    </button>  */}
                {/*   <NewModal></NewModal> */}
            </div>
        </StyledContainer>
    )
}

export default HomeUzaktanEgitim

const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding: 60px 80px;
    margin: 60px 0;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        grid-template-columns: 1fr;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }

    .uzaktan_egitim_image_container {
        position: relative;
        /* z-index: 1000; */

        @media screen and (max-width:500px) {
            display: none;
        }

        #imgLesson {
            width: 100%;
            z-index: 1000;

            @media screen and (max-width:1100px) {
                height: 450px;
            }
        }

        #imgStar {
            position: absolute;
            left: 10%;
            top: -25%;
            width: 15%;

            @media screen and (max-width:1100px) {
                display: none;
            }
        }

        #halfEllipse {
            position: absolute;
            right: 3%;
            bottom: -15%;
            z-index: -1555;

            @media screen and (max-width:1100px) {
                display: none;
            }
        }

        #img_ellipse {
            position: absolute;
            bottom: -20%;
            left: 0;

            @media screen and (max-width:1100px) {
                display: none;
            }
        }

        #img_ellipse_empty {
            position: absolute;
            top: -15%;
            right: 0;
            z-index: -100;

            @media screen and (max-width:1100px) {
                display: none;
            }
        }
    }


    .uzaktan_egitim_content {
        padding: 0px 120px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        @media screen and (max-width:1100px) {
            padding: 20px 20px;
            row-gap: 20px;
        }


        @media screen and (max-width:500px) {
            padding: 20px 20px;
            row-gap: 20px;
        }

        .uzaktan_egitim_content_p1 {
            color: black;
            font-weight: 600;
            font-size: 36px;

            span {
                color: #7F56D9;
                font-weight: 600;
                margin-left: 10px;

            }
        }

        .uzaktan_egitim_content_description {
            color: #7E7EAA;
            line-height: 25px;
            font-size: 14px;

            @media screen and (max-width:1100px) {
                font-size: 20px;
                line-height: 30px;
            }

            @media screen and (max-width:500px) {
                font-size: 20px;
                line-height: 25px;
            }
        }

        .uzaktan_egitim_devamini_oku_btn {
            background-color: #7F56D9;
            color: white;
            width: max-content;
            border: none;
            padding: 10px 15px;
            border-radius: 10px;
            transition: .5s all;

            &:hover {
                opacity: .5;
            }
        }
    }
`