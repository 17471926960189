import { Alert, Button, Collapse, Tabs } from 'antd'
import React, { useMemo, useState } from 'react'
import { FaPlay } from 'react-icons/fa';
import { IoMdPlayCircle } from 'react-icons/io';
import { SlArrowDown } from 'react-icons/sl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

const VidePageTab = ({ data, status }) => {
   
    const [previewModal, setPreviewModal] = useState({
        visible: false,
        data: null
    })
    const onChange = (key) => {
        
    };

    const courseInfo = useMemo(() => {
  
        let lastElement = data[data.length - 1]
   
        return lastElement?.course
    }, [data])

    const courseType = useMemo(() => {
        
        return courseInfo?.type
    }, [courseInfo])


    const items = [
        {
            key: '1',
            label: 'Açıklama',
            children: (
                <VideoTabDescriptionElement style={{ backgroundColor: "#F6F6F6" }}>
                    {courseInfo?.description}
                </VideoTabDescriptionElement>
            ),
        },
        {
            key: '2',
            label: 'SSS',
            children: (
                <Collapse expandIcon={({ isActive }) => null} defaultActiveKey={['0', "1"]} >

                    <Collapse.Panel header={
                        <AccHeaderElement>
                            <p>
                                Kurs Kimler için
                            </p>
                            <SlArrowDown />
                        </AccHeaderElement>
                    } >

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias quod numquam accusamus esse ratione illum minima impedit! Dolorum voluptatum, esse aspernatur ad iste et rerum vero pariatur, molestiae inventore consequuntur?

                    </Collapse.Panel>
                    <Collapse.Panel header={
                        <AccHeaderElement>
                            <p>

                                Eğitim, hangi sektörlerde iş imkanına sahip olacağım?
                            </p>
                            <SlArrowDown />
                        </AccHeaderElement>
                    } >

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias quod numquam accusamus esse ratione illum minima impedit! Dolorum voluptatum, esse aspernatur ad iste et rerum vero pariatur, molestiae inventore consequuntur?

                    </Collapse.Panel>

                </Collapse>
            )
        },
        {
            key: '3',
            label: 'İçerik',
            children: (
                <Collapse expandIcon={({ isActive }) => null} defaultActiveKey={['0', "1"]} >
                    {
                        data?.map((el, index) => {
                            return (
                                <Collapse.Panel header={
                                    <AccHeaderElement>
                                        <p>
                                            {el.name}
                                        </p>
                                        <SlArrowDown />
                                    </AccHeaderElement>
                                } >
                                    {
                                        courseType == "Offline" ? el?.courseFiles?.map((item, idx) => {
                                            return (
                                                <CollapseVideoContentElement preview={item?.preview} onClick={() => {
                                                    if (item.preview) {
                                                     
                                                        setPreviewModal({
                                                            visible: true,
                                                            data: item
                                                        })
                                                    }
                                                }} >

                                                    <IoMdPlayCircle color={item.preview ? "white" : "black"} />
                                                    <p style={{ textTransform: "capitalize", color: item.preview ? "white" : "black" }}>
                                                        {item?.description}
                                                    </p>
                                                    {
                                                        item.preview && (
                                                            <p style={{ color: "white" }} >
                                                                (Önizleme)
                                                            </p>
                                                        )
                                                    }
                                                </CollapseVideoContentElement>
                                            )
                                        }) :
                                            (
                                                <div>
                                                    {
                                                        el
                                                    }
                                                </div>
                                            )
                                    }
                                </Collapse.Panel>
                            )
                        })
                    }

                </Collapse>
            )
        },
    ];

    return (
        <VideoPageTabContainer>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            <Modal isOpen={previewModal.visible} centered>
                <StyledHeader >
                    <p  >
                        {previewModal.data?.description}
                    </p>
                </StyledHeader>
                <ModalBody>
                    <video controls controlsList='nodownload' style={{ maxWidth: "100%", width: "100%", maxHeight: "400px" }}  >
                        <source src={previewModal?.data?.url} type='video/mp4' />
                    </video>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setPreviewModal({
                            visible: false,
                            data: null
                        })
                    }} danger type='primary' >
                        Kapat
                    </Button>
                </ModalFooter>
            </Modal>
        </VideoPageTabContainer>
    )
}

export default VidePageTab


const VideoPageTabContainer = styled.div`
    background-color: white;
    padding: 20px;

    @media screen and (max-width:600px) {
        padding: 10px;
    }
`

const VideoTabDescriptionElement = styled.div`
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
`

const AccHeaderElement = styled.div`
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CollapseVideoContentElement = styled.div`
    background-color: ${props => props.preview ? "#41B3A2" : "aliceblue"};
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #809CC4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: ${props => props.preview ? "pointer" : ""};
    font-size: 16px;
`

const StyledHeader = styled(ModalHeader)`

    h5{
        width: 100%;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 5px;
    }
`