
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input as RInput } from "reactstrap"
import { Button, Input, Select, Modal as AntModal } from "antd"
import { toast } from 'react-toastify'
import { uniqueId } from 'lodash'
import { axiosInstance } from '../../api/axiosInstance'

const EgitmenSoruGuncelle = ({ editMode, setEditMode, branchList, refreshData }) => {

    const [mainData, setMainData] = useState({
        questionId: "",
        point: 0,
        question: "",
        branchId: "",
        level: "0",
        branchQuestionAnswers: []
    })
    const SubmitValidate = () => {

        if (mainData.question == "") {
            toast.error("Soru içeriği boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (mainData?.level == "0") {
            toast.error("Zorluk derecesi seçiniz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (mainData?.branchQuestionAnswers.some(item => item.answer == "")) {
            toast.error("Cevap şıkkı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (!mainData?.branchQuestionAnswers.some(item => item.status)) {
            toast.error("Bir tane doğru şık seçiniz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (mainData?.branchId == "") {
            toast.error("Branş seçiniz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (mainData.point > 100 || mainData.point <= 0) {
            toast.error("Puan 0 ile 100 arasında olmalı", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }

        return true
    }
    useEffect(() => {
        if (editMode.data) {
            setMainData({
                point: editMode.data.point,
                question: editMode.data.question,
                branchId: editMode.data.branchId,
                branchQuestionAnswers: editMode.data.branchQuestionAnswers,
                questionId: editMode.data.id,
                level: editMode?.data?.level
            })
        }

    }, [editMode])


    return (
        <Modal isOpen={editMode.visible} size='lg' >
            <StyledModalHeader>
                Soru Güncelle

                <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                    <p>
                        Branş :
                    </p>
                    <BranchSelect
                        value={mainData.branchId}
                        placeholder="Branş Seçiniz"
                        onChange={e => {
                            setMainData({
                                ...mainData,
                                branchId: e
                            })
                        }}
                        showSearch
                        filterOption={(input, option) => {

                            return (
                                option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                            )
                        }}
                        options={branchList?.map(item => {
                            return {
                                "label": item?.branch?.name ? `${item?.id} - ${item?.branch?.name}` : `${item?.id}-${item?.name}`,
                                "value": item?.branch?.id ?? item.id
                            }
                        })}

                    />
                </div>
            </StyledModalHeader>
            <ModalBody  >
                <QuestionContainer>
                    <Input.TextArea value={mainData.question} placeholder='Soru İçeriği' style={{ resize: "none" }} rows={4} onChange={(e) => {

                        setMainData({
                            ...mainData,
                            question: e.target.value
                        })
                    }} />


                </QuestionContainer>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }} >
                        Puan : <Input value={mainData.point} onChange={e => {
                            setMainData({
                                ...mainData,
                                point: e.target.value
                            })
                        }} type='number' style={{ width: "100px", marginLeft: "10px" }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }} >
                        Zorluk : <Select
                            style={{
                                minWidth: "120px",
                                marginLeft: "10px"
                            }}
                            value={mainData.level}
                            onChange={(e) => {
                                setMainData({
                                    ...mainData,
                                    level: e
                                })
                            }}
                            options={[
                                {
                                    label: "Seçilmedi",
                                    value: "0"
                                },
                                {
                                    label: "Kolay",
                                    value: "Kolay"
                                }, {
                                    label: "Orta",
                                    value: "Orta"
                                },
                                {
                                    label: "Zor",
                                    value: "Zor"
                                }

                            ]}

                        />
                    </div>
                </div>
                <QuestionAnswerContainer>
                    {
                        mainData.branchQuestionAnswers?.map(item => {
                            return (
                                <AnswerItem key={`${item.id}`} >
                                    <div className='option_item' >
                                        {item.option}
                                    </div>
                                    <Input className='answer_input' value={item.answer} onChange={(e) => {
                                        setMainData({
                                            ...mainData,
                                            branchQuestionAnswers: mainData?.branchQuestionAnswers?.map(el => {
                                                if (el.id == item.id) {
                                                    return {
                                                        ...el,
                                                        answer: e.target.value
                                                    }
                                                }
                                                return el
                                            })
                                        })
                                    }} />
                                    <StyledCheckElement type='checkbox' checked={item.status} onChange={e => {
                                        setMainData({
                                            ...mainData,
                                            branchQuestionAnswers: mainData?.branchQuestionAnswers?.map(el => {
                                                if (el.id == item.id) {
                                                    return {
                                                        ...el,
                                                        status: e.target.checked
                                                    }
                                                }
                                                else {
                                                    return {
                                                        ...el,
                                                        status: false
                                                    }
                                                }
                                            })
                                        })
                                    }} />
                                </AnswerItem>
                            )
                        })
                    }


                </QuestionAnswerContainer>
            </ModalBody>
            <ModalFooter>
                <Button type='primary' style={{ marginRight: "10px" }} onClick={() => {
                    if (SubmitValidate()) {
                        AntModal.confirm({
                            title: "Güncellemek istediğinize emin misiniz?",
                            cancelText: "İptal",
                            okText: "Eminim",
                            onOk: async () => {
                                try {

                                    const response = await axiosInstance.put("/BranchQuestions/Update",
                                        {
                                            id: mainData.questionId,
                                            branchId: mainData.branchId,
                                            point: mainData.point,
                                            question: mainData.question,
                                            level: mainData?.level
                                        }
                                       
                                    );


                                    if (mainData.branchQuestionAnswers.length !== 0) {

                                        await axiosInstance.put("/BranchQuestionAnswers/update", {
                                            branchQuestionAnswers: mainData.branchQuestionAnswers
                                        },
                                        { showToast: true } // Toast mesajını kapatmak için bu parametreyi ekleyin
                                    )
                                    }
                                    toast.success("Soru güncellendi", {
                                        autoClose: 3000,
                                        position: "top-center"
                                    })
                                    setEditMode({
                                        visible: false,
                                        data: null
                                    })
                                    refreshData()
                                }
                                catch (err) {


                                }
                            }
                        })
                    }


                }}  >
                    Güncelle
                </Button>
                <Button danger type='primary' onClick={() => {
                    setEditMode({
                        visible: false,
                        data: null
                    })
                }} >
                    Kapat
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EgitmenSoruGuncelle

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const QuestionAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;

`

const BranchSelect = styled(Select)`
    width:240px;

`
const AnswerItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;

    .option_item{
        width: 30px;
        height: 30px;
        border-radius: 20px;
        text-transform: capitalize;
        background-color: #3577F1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .answer_input{
        height: 40px;
    }
`


const StyledModalHeader = styled(ModalHeader)`
    width: 100%;

    h5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`

const StyledCheckElement = styled(RInput)`

                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-left: 10px;
                   margin-top: 0;

`