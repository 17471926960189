import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Col, Form, FormFeedback, Input, Label, Row, TabPane } from 'reactstrap'
import withRouter from '../Common/withRouter'
import { withTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from "yup"
import { changePasswordApi } from '../../api/UserApi'
import { UserContext } from '../../context/user'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button, Modal } from 'antd'
const ChangePasswordTab = ({ t }) => {

    const [state, dispatch] = useContext(UserContext)


    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: yup.object({
            oldPassword: yup.string().required('Güncel parola gereklidir'),
            newPassword: yup.string().required('Yeni parola gereklidir'),
            confirmPassword: yup.string()
                .oneOf([yup.ref('newPassword')], 'Parolalar eşleşmiyor')
                .required('Parola tekrarı gereklidir')
        }),
        onSubmit: async (value) => {
            const { oldPassword, newPassword } = value

            Modal.confirm({
                title: 'Onay',
                content: 'Parolayı değiştirmek istediğinize emin misiniz ?',
                okText: "Eminim",
                cancelText: 'İptal',
                onOk: async () => {
                    try {
                        const response = await changePasswordApi({
                            id: state.userId,
                            oldPassword,
                            newPassword
                        })
                       
                        toast.success("Parola Güncellendi", { autoClose: 1000 })
                        formik.resetForm()
                    }
                    catch (err) {

                    }

                }
            });

        }
    })




    return (

        <Form onSubmit={formik.handleSubmit}>
            <Row className="g-2">
                <Col lg={4}>
                    <div>
                        <Label htmlFor="oldpasswordInput" className="form-label">Eski Parola *</Label>
                        <Input type="password" className="form-control"
                            style={{ marginLeft: 0 }}
                            id="oldPassword"
                            name="oldPassword"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.oldPassword && formik.errors.oldPassword ? true : false
                            }
                            placeholder="Enter current password" value={formik.values.oldPassword} />
                        {formik.touched.oldPassword && formik.errors.oldPassword ? (
                            <FormFeedback type="invalid"><div>{formik.errors.oldPassword}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="newpasswordInput" className="form-label">
                            Yeni Parola
                            *</Label>
                        <Input type="password" className="form-control" name='newPassword'
                            placeholder="Yeni Parola"
                            invalid={
                                formik.touched.newPassword && formik.errors.newPassword ? true : false
                            }
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <FormFeedback type="invalid"><div>{formik.errors.newPassword}</div></FormFeedback>
                        ) : null}

                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="confirmpasswordInput" className="form-label">Parola Onay</Label>
                        <Input type="password" className="form-control"
                            id="confirmpassword"
                            name='confirmPassword'
                            onChange={formik.handleChange}
                            value={formik.values.confirmPassword}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false
                            }
                            placeholder="Confirm password"

                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <FormFeedback type="invalid"><div>{formik.errors.confirmPassword}</div></FormFeedback>
                        ) : null}

                    </div>
                </Col>
                <Col lg={12}>
                    <StyledPasswordContainer>
                        <Button type='primary' onClick={() => {
                            formik.handleSubmit()
                        }} >
                            Parolayı değiştir
                        </Button>
                 
                    </StyledPasswordContainer>

                </Col>

            </Row>

        </Form>

    )
}

const StyledPasswordContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`


export default ChangePasswordTab