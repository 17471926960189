import React, { useState, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as yup from "yup"
import { Col, Form, FormFeedback, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import { CircleLoader, DotLoader, PropagateLoader } from 'react-spinners'
import { cityList } from '../constants/cityList'
import { getUserByIdApi, updateUserApi } from '../../api/UserApi'
import { getAllBranch, GetAllBranch } from '../../api/Branch'
import { useNavigate, useParams } from 'react-router-dom'
import TeacherResponsibilityCourse from './TeacherResponsibilityCourse'
import styled from 'styled-components'
import { Button as AntButton, Select } from "antd"
import TeacherCv from './TeacherCv/TeacherCv'
import { axiosInstance } from '../../api/axiosInstance'
import TeacherBranch from './TeacherBranch/TeacherBranch'
import 'react-quill/dist/quill.snow.css';
const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
const eightyYearsAgo = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate());

const UpdateTeacherForm = ({ setTitle }) => {
    const navigate = useNavigate()
    const [eValData, setEval] = useState()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState([])

    const [activeTab, setActiveTab] = useState(1);
    const [tableData, setTableData] = useState()

    const setTeacherProfileData = async (data) => {
        try {

            const { birthDate, updatedAt, createdAt, ...rest } = data
            Object.entries(rest).map(([key, val]) => {

                formik.setFieldValue(key, val)

            })

            const formatBirthDate = new Date(birthDate).toISOString().split('T')[0];
            formik.setFieldValue('birthDate', formatBirthDate);
        }
        catch (err) {
            toast.error(err.message, {
                autoClose: 1000
            })
        }
    }

    const apiRequest = async () => {
        try {
            setLoading(true)
            const responseTeacher = await getUserByIdApi(id)

            setTeacherProfileData(responseTeacher.data)
            setTableData(responseTeacher.data)
            let fullName = responseTeacher.data.firstName + " " + responseTeacher.data.lastName
            setTitle(fullName)
        }
        catch (err) {
            navigate("/panel/egitmen")
        }
        finally {
            setLoading(false)
        }
    }







    useEffect(() => {
        apiRequest()

    }, [id])


    const formik = useFormik({
        initialValues: {
            birthDate: "",
            email: "",
            gender: "Erkek",
            firstName: "",
            phone: "",
            lastName: "",
        },
        validationSchema: yup.object({
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            email: yup.string().email().required(),
            phone: yup.string()
                .matches(/^(\d{10})$/, "Geçerli bir Türkiye telefon numarası girin") // Türkiye telefon numarası formatı (Başında 0 ve 10 rakam)
                .required("Telefon numarası boş bırakılamaz"),
            address: yup.string().required(),
         

        }),
        onSubmit: async (value) => {
            try {
                const { birthDate, ...rest } = value
                let response = await updateUserApi({
                    ...rest,
                    birthDate: new Date(birthDate).toUTCString()
                })
                toast.success("güncelleme başarılı", {
                    autoClose: 2500
                })
            }
            catch (err) {
                /*  console.log("err =>>", err)
                 toast.error(err.message, {
                     autoClose: 1500
                 }) */
            }
        }
    })






    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }} >
                <PropagateLoader color="#36d7b7" />
            </div>
        )
    }

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white" } : null}
                        onClick={() => {
                            setActiveTab(1)
                        }}
                    >
                        Bilgiler
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white" } : null}
                        onClick={() => {
                            setActiveTab(2)
                        }}
                    >
                        Branş
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 3 ? { backgroundColor: "#088F7D", color: "white" } : null}
                        onClick={() => {
                            setActiveTab(3)
                        }}
                    >
                        Sorumlu Kurslar
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 4 ? { backgroundColor: "#088F7D", color: "white" } : null}
                        onClick={() => {
                            setActiveTab(4)
                        }}
                    >
                        Özgeçmiş
                    </NavLink>
                </NavItem>
               
            </Nav>
            <TabContent activeTab={activeTab} style={{ paddingTop: "20px" }} className="tab_content" >
                <TabPane tabId={1}>
                    < Form onSubmit={formik.handleSubmit} >
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="firstnameInput" className="form-label">
                                        İsim
                                    </Label>
                                    <Input style={{ marginLeft: "0" }} disabled type="text" className="form-control" id="name" name='firstName'
                                        value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched.firstName && formik.errors.firstName ? true : false
                                        }
                                    />
                                    {formik.touched.firstName && formik.errors.firstName ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.firstName}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="lastnameInput" className="form-label">
                                        Soyisim
                                    </Label>
                                    <Input style={{ marginLeft: "0" }} disabled type="text" className="form-control" id="surname"
                                        placeholder="Soyadı" name='surname' value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched.lastName && formik.errors.firstName ? true : false
                                        }
                                    />
                                    {formik.touched.lastName && formik.errors.lastName ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.lastName}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="phonenumberInput" className="form-label">
                                        Tc No
                                    </Label>
                                    <Input style={{ marginLeft: "0" }} type="text" className="form-control disabled-input"
                                        value={formik.values.tc}
                                        disabled
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Label htmlFor="phonenumberInput" className="form-label">
                                        Doğum Tarihi
                                    </Label>
                                    <StyledBirthDate
                                        disabled
                                        name="birthDate"
                                        type="date"
                                        placeholde1r="Doğum Tarihi"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.birthDate}
                                        invalid={
                                            formik.touched.birthDate && formik.errors.birthDate ? true : false
                                        }
                                    />
                                    {formik.touched.birthDate && formik.errors.birthDate ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.birthDate}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="phonenumberInput" className="form-label">
                                        Telefon
                                    </Label>
                                    <Input style={{ marginLeft: "0" }} type="text" className="form-control"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        id="phone"
                                        name='phone'
                                        value={formik.values.phone}
                                        invalid={
                                            formik.touched.phone && formik.errors.phone ? true : false
                                        }
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.phone}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="emailInput" className="form-label ">Email</Label>
                                    <Input style={{ marginLeft: "0" }} disabled type="text" className="form-control disabled-input"
                                        onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        name='email'
                                        value={formik.values.email}
                                        invalid={
                                            formik.touched.email && formik.errors.email ? true : false
                                        }
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.email}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label htmlFor="emailInput" className="form-label">
                                        Cinsiyet
                                    </Label>
                                    <StyledGenderInput disabled className='form-control' value={formik.values.gender} name='gender' onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                        <option value="Erkek">
                                            Erkek
                                        </option>
                                        <option value="Kadın">
                                            Kadın
                                        </option>
                                    </StyledGenderInput>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <Label htmlFor="lastnameInput" className="form-label">
                                        Adres
                                    </Label>
                                    <Input rows={3} type="textarea" className="form-control" id="adress" style={{ resize: "none" }}
                                        placeholder="address" name='address' value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                        invalid={
                                            formik.touched.adress && formik.errors.adress ? true : false
                                        }
                                    />
                                    {formik.touched.address && formik.errors.address ? (
                                        <FormFeedback type="invalid"><div>{formik.errors.address}</div></FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col lg={12}>
                                <FooterContainer >
                                    <StyledAntButton type="primary" size='middle' onClick={() => {
                                        formik.handleSubmit()
                                    }} >
                                        Güncelle
                                    </StyledAntButton>

                                </FooterContainer>
                            </Col>



                        </Row>
                    </Form >
                </TabPane>
                <TabPane tabId={2} >
                    <TeacherBranch />
                </TabPane>
                <TabPane tabId={3}>
                    <TeacherResponsibilityCourse />
                    {/* <EditTeacherResponseCourseTable data={tableData} /> */}
                </TabPane>

                <TabPane tabId={4} >
                    <TeacherCv setEval={setEval} />
                </TabPane>
             
            </TabContent>

        </>


    )
}

export default UpdateTeacherForm


const StyledBirthDate = styled(Input)`
    padding: 6px 15px;
`

const StyledGenderInput = styled.select`
    padding: 6px 15px;
`

const FooterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
`

const StyledAntButton = styled(AntButton)`


    @media screen and (max-width:600px) {
        width: 100%;
    }
`

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`

