import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner,
} from "reactstrap";
import { Button as AntButton, Input as AntInput } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { UserContext } from "../../context/user";
/* import { loginJWT } from "../../api/user"; */

import AuthSlider from "../AuthenticationInner/AuthSlider";
import { toast } from "react-toastify";
import { loginApi } from "../../api/UserApi";
import styled from "styled-components";





const Login = (props) => {
    const navigate = useNavigate();

    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const location = useLocation()

    document.title = "KUZEM - KOMEK Uzaktan Eğitim Merkezi"

    const getQuery = () => {
        const query = new URLSearchParams(location.search)
        const returnPage = query.get("page")

        return returnPage
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            tc: userLogin.tc || "",
            password: userLogin.password || "",
        },
        validationSchema: Yup.object({
            tc: Yup
                .string()
                .length(11, "T.C. Kimlik Numarası 11 haneli olmalıdır.")
                .matches(/^[0-9]+$/, "T.C. Kimlik Numarası sadece rakamlardan oluşmalıdır.")
                .required("T.C. Kimlik Numarası boş bırakılamaz."),
            password: Yup.string().required("Şifre boş olamaz."),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const control = getQuery()
                const response = await loginApi(values);
                if (response?.status == 200 || response?.status == 201) {
                    dispatch({ type: "LOGIN", payload: response.data });
                    if (control) {
                        navigate(`/${control}`);
                    }
                    else {
                        navigate("/panel/anasayfa")
                    }

                } else {
                    toast.error("Error");
                }
            } catch (error) {

            } finally {
                setIsLoading(false);
            }
        },
    });



    useEffect(() => {
        // Enter tuşuna basıldığında tetiklenecek olay
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                validation.handleSubmit()
                // Enter tuşuna basıldığında yapmak istediğin işlemi buraya ekle
            }
        };

        // `keydown` olay dinleyicisini ekle
        window.addEventListener('keydown', handleKeyDown);

        // Component unmount olduğunda event listener'ı kaldır
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    useEffect(() => {
        let token = localStorage.getItem("authToken")
        if (token) {
            window.history.back()
        }
    }, [])


    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <StyledCard className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <div className="p-lg-5 p-4 mt-lg-5 mb-lg-5">
                                                <div className="text-center mt-2">
                                                    <h5 className="text-primary">
                                                        KUZEM Eğitim Platformu
                                                    </h5>
                                                    <p className="text-muted">
                                                        Devam etmek için giriş yapın.
                                                    </p>
                                                </div>

                                                <div className="mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#"
                                                    >
                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="tc"
                                                                className="form-label"
                                                            >
                                                                Tc No
                                                            </Label>
                                                            <StyledAntInput placeholder="Tc No" style={{
                                                                height: "35px"
                                                            }}

                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.tc}
                                                                max={11}
                                                                onChange={(e) => {
                                                                    validation.setFieldValue("tc", e.target.value)
                                                                }}
                                                            />
                                                            {validation.touched.tc &&
                                                                validation.errors.tc ? (
                                                                <StyledErrorMessage>
                                                                    {validation.errors.tc}
                                                                </StyledErrorMessage>
                                                            ) : null}
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="password-input"
                                                            >
                                                                Parola
                                                            </Label>
                                                            <div className=" mb-3">
                                                                <StyledAntInput.Password
                                                                    placeholder="Parola"
                                                                    style={{
                                                                        height: "35px"
                                                                    }}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("password", e.target.value)
                                                                    }}
                                                                    onBlur={validation.handleBlur}

                                                                />
                                                                {/*  <Input
                                                                    name="password"
                                                                    value={
                                                                        validation.values
                                                                            .password || ""
                                                                    }
                                                                    type={
                                                                        passwordShow
                                                                            ? "text"
                                                                            : "password"
                                                                    }
                                                                    className="form-control pe-5"
                                                                    placeholder="Parolanızı giriniz"
                                                                    onChange={
                                                                        validation.handleChange
                                                                    }
                                                                    onBlur={validation.handleBlur}
                                                                    invalid={
                                                                        validation.touched
                                                                            .password &&
                                                                            validation.errors.password
                                                                            ? true
                                                                            : false
                                                                    }
                                                                /> */}
                                                                {validation.touched.password &&
                                                                    validation.errors.password ? (
                                                                    <StyledErrorMessage >
                                                                        {validation.errors.password}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>


                                                        <div className="mt-4">
                                                            <AntButton
                                                                onClick={() => {
                                                                    validation.handleSubmit()
                                                                }}
                                                                loading={isLoading}
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                                type="primary"
                                                            >
                                                                Giriş Yap
                                                            </AntButton>
                                                        </div>
                                                    </Form>
                                                </div>

                                                <StyledLinkWrapper>
                                                    <Link to={"/kayit"} >
                                                        Hesap Oluştur
                                                    </Link>
                                                    <Link to={"/sifremiunuttum"} >
                                                        Şifremi unuttum
                                                    </Link>
                                                </StyledLinkWrapper>
                                            </div>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                        &copy; 2023 Şehir Teknolojileri Merkezi tarafından yapıldı.{" "}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);


const StyledAntInput = styled(AntInput)`
    height: 35px;
`

const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`


const StyledCard = styled(Card)`
     margin-right: 0;
`

const StyledLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
`