// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog_list_container {
  padding: 20px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 70px; }
  @media screen and (max-width: 1100px) {
    .blog_list_container {
      grid-template-columns: 1fr 1fr;
      padding: 20px 40px; } }
  @media screen and (max-width: 500px) {
    .blog_list_container {
      padding: 20px 20px;
      grid-template-columns: 1fr; } }
`, "",{"version":3,"sources":["webpack://./src/Public/pages/blog/index.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,aAAa;EACb,kCAAkC;EAClC,mBAAmB,EAAA;EAEnB;IANJ;MAOQ,8BAA8B;MAC9B,kBAAkB,EAAA,EAOzB;EAJG;IAXJ;MAYQ,kBAAkB;MAClB,0BAA0B,EAAA,EAEjC","sourcesContent":[".blog_list_container{\n    padding: 20px 120px;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    margin-bottom: 70px;\n\n    @media screen and (max-width:1100px) {\n        grid-template-columns: 1fr 1fr;\n        padding: 20px 40px;\n    }\n\n    @media screen and (max-width:500px) {\n        padding: 20px 20px;\n        grid-template-columns: 1fr;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
