import React from 'react'
import TeacherCourseTable from './TeacherCourseTable'

const TeacherCourseDashboard = () => {

    return (
        <TeacherCourseTable />
    )
}

export default TeacherCourseDashboard