// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ekitap_element_item {
  height: 200px;
  position: relative;
  width: 100%;
  background-color: #91E7Cb;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ekitap_element_item .clip_path_container .clip_path_item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
    width: 20%;
    background: black;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 48% 68%, 0 100%);
    /*  clip-path: polygon(100% 0, 100% 100%, 49% 68%, 0 100%, 0 0); */
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: absolute;
    top: -30px;
    left: 5%; }
    .ekitap_element_item .clip_path_container .clip_path_item .clip_path_item_img {
      transform: translateY(-20%);
      width: 60px; }
  .ekitap_element_item .ekitap_element_title {
    font-size: 25px;
    font-weight: bold; }
  .ekitap_element_item .ekitap_element_category {
    position: absolute;
    bottom: 3%;
    left: 8%;
    font-size: 16px; }
`, "",{"version":3,"sources":["webpack://./src/Public/pages/e-kitap/ekitapCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;EARvB;IAaY,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,UAAU;IACV,iBAAiB;IACjB,qEAAqE;IAErE,kEAAA;IACA,4BAA4B;IAC5B,6BAA6B;IAE7B,kBAAkB;IAClB,UAAU;IACV,QAAQ,EAAA;IA3BpB;MA+BgB,2BAA2B;MAC3B,WAAW,EAAA;EAhC3B;IA0CQ,eAAe;IACf,iBAAiB,EAAA;EA3CzB;IAgDQ,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,eAAe,EAAA","sourcesContent":[".ekitap_element_item {\n    height: 200px;\n    position: relative;\n    width: 100%;\n    background-color: #91E7Cb;\n    border-radius: 20px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    .clip_path_container {\n\n        .clip_path_item {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            height: 95%;\n            width: 20%;\n            background: black;\n            clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 48% 68%, 0 100%);\n\n            /*  clip-path: polygon(100% 0, 100% 100%, 49% 68%, 0 100%, 0 0); */\n            border-top-left-radius: 20px;\n            border-top-right-radius: 20px;\n\n            position: absolute;\n            top: -30px;\n            left: 5%;\n\n\n            .clip_path_item_img {\n                transform: translateY(-20%);\n                width: 60px;\n            }\n        }\n\n\n    }\n\n\n\n    .ekitap_element_title {\n        font-size: 25px;\n        font-weight: bold;\n    }\n\n\n    .ekitap_element_category {\n        position: absolute;\n        bottom: 3%;\n        left: 8%;\n        font-size: 16px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
