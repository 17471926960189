import React, { FC, useEffect, useMemo, useState } from 'react'
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Button as AntButton, Modal as AntModal } from "antd"
import * as yup from "yup"
import { updateUserApi } from '../../api/UserApi';
import styled from 'styled-components';


const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
const eightyYearsAgo = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate());

const ProfileDetail = ({ setUser, user }) => {
    const [region, setRegion] = useState([])

    const getUserProfileData = async () => {
        try {

            Object.entries(user).map(([key, val]) => {
                formik.setFieldValue(key, val)
            })
            const birthDate = new Date(user.birthDate).toISOString().split('T')[0];
      
            const year = birthDate.substring(0, 4)
            formik.setFieldValue('birthDate', year);
        }
        catch (err) {
            toast.error(err.response.data.message)
        }
    }

    useEffect(() => {
        
        getUserProfileData()
    }, [user])


    const formik = useFormik({
        initialValues: {
            id: "",
            birthDate: "",
            email: "",
            gender: "erkek",
            firstName: "",
            phone: "",
            lastName: "",
            tc: "",
            address: ""
        },
        validationSchema: yup.object({
            email: yup.string().email().required(),
            firstName: yup.string().required(),
            lastName: yup.string().required(),
            phone: yup.string().required("Telefon gerekli bir alandır"),
            address: yup.string().required("Adres gereklidir").min(20, "minimum 20 karakter olmalı"),
            birthDate: yup.date().max(eighteenYearsAgo, 'You must be at least 18 years old.').min(eightyYearsAgo, 'You must be at most 80 years old.').required("Doğum Tarihi Seçiniz"),

        }),
        onSubmit: async (value, { resetForm }) => {
 
            AntModal.confirm({
                okText: "Eminim",
                content: (
                    <p>
                        Güncellemek istediğinize emin misiniz ?
                    </p>
                ),
                cancelText: "İptal",
                onOk: async () => {
                    try {
                        const { phone, email, adress, id } = value
                        let data = await updateUserApi({
                            phone: phone,
                            email: email,
                            address: adress,
                            id: id
                        })
                        toast.success("Güncelleme Başarılı", {
                            autoClose: 2000
                        })
                    }
                    catch (err) {

                    }
                }
            })









        }
    })



    return (
        <Form onSubmit={formik.handleSubmit}>
            <Row style={{ marginTop: "15px" }} >
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="firstnameInput" className="form-label">
                            İsim
                        </Label>
                        <StyledInput disabled type="text" className="form-control" id="name" name='name'
                            value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.firstName && formik.errors.firstName ? true : false
                            }
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <FormFeedback type="invalid"><div>{formik.errors.firstName}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="lastnameInput" className="form-label">
                            Soyisim
                        </Label>
                        <StyledInput disabled type="text" className="form-control" id="surname"
                            placeholder="Soyadı" name='surname' value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.lastName && formik.errors.firstName ? true : false
                            }
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <FormFeedback type="invalid"><div>{formik.errors.lastName}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Tc No
                        </Label>
                        <StyledInput type="text" className="form-control disabled-input"
                            value={formik.values.tc}
                            disabled
                        />
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Doğum Yılı
                        </Label>
                        <StyledInput
                            disabled
                            name="birthDate"
                            type="text"
                            placeholde1r="Doğum Tarihi"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.birthDate}
                            invalid={
                                formik.touched.birthDate && formik.errors.birthDate ? true : false
                            }
                        />
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Telefon
                        </Label>
                        <StyledInput type="text" className="form-control "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="phone"
                            name='phone'
                            value={formik.values.phone}
                            invalid={
                                formik.touched.phone && formik.errors.phone ? true : false
                            }
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <FormFeedback type="invalid"><div>{formik.errors.phone}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label ">Email</Label>
                        <StyledInput type="email" className="form-control"
                            name='email'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.email && formik.errors.email ? true : false
                            }
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <FormFeedback type="invalid"><div>{formik.errors.email}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">
                            Cinsiyet
                        </Label>
                        <StyledInput type="text" disabled className="form-control disabled-input"
                            name='email'
                            value={formik.values.gender}
                        />

                    </div>
                </Col>
                <Col lg={12}>
                    <Label className="form-label ">Adres</Label>
                    <StyledInput type="textarea" className="form-control"
                        style={{ resize: "none", height: "50px" }}
                        name='address'
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                            formik.touched.address && formik.errors.address ? true : false
                        }
                    />
                    {formik.touched.address && formik.errors.address ? (
                        <FormFeedback type="invalid"><div>{formik.errors.address}</div></FormFeedback>
                    ) : null}

                </Col>

                <Col lg={12}>
                    <StyledSaveBtnContainer>
                        <AntButton id='saveProfileBtn' type='primary' onClick={() => { formik.handleSubmit() }}  >
                            Güncelle
                        </AntButton>
                    </StyledSaveBtnContainer>


                </Col>



            </Row>
        </Form>
    )
}

export default ProfileDetail


const StyledInput = styled(Input)`
    height: 35px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`
const StyledSaveBtnContainer = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    #saveProfileBtn{

        @media screen and (max-width:600px) {
            width: 100%;
        }
    }
`