

import { Container, Row, Col, FormGroup, Form, FormText, Label, Input } from "reactstrap"
import './index.scss'
/* import { Captcha } from "../../components/captcha/Captcha"; */
import { useTranslation } from "react-i18next";
import MainNavbar from '../../components/navbar/MainNavbar';
import Location from "../../../assets/images/Location.png"
import Line from "../../../assets/images/line.png"
import Call from "../../../assets/images/Call.png"
import Message from "../../../assets/images/Message.png"
import FontHead from '../../components/fontHead/FontHead';
import MainFooter from '../../components/mainFooter/MainFooter';
import { Captcha } from '../../components/Captcha/Captcha';
import { useEffect } from "react";
import İletisimBg from "../../../assets/images/iletisimBg.png"
import styled from "styled-components";
import TopBgElement from "../TopBgElement/TopBgElement";
import { Helmet } from "react-helmet-async";


const İletisim = () => {


    const { t } = useTranslation();

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <StyledMainContainer >
            <Helmet>
                <title>{'İletişim - Eğitim Platformu'}</title>
                <meta name="description" content={'Bizimle iletişime geçin ve KUZEM eğitim platformumuz hakkında daha fazla bilgi alın.'} />
                <meta name="keywords" content={'İletişim, Eğitim Platformu, Destek, Sorular, Bilgi'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kuzem.org/iletisim" />
                <meta property="og:image" content={İletisimBg} />
            </Helmet>
            <TopBgElement img={İletisimBg} mainTitle={"İletişim"} subTitle={"Anasayfa / İletişim"} />
            <MainNavbar />


            <div className='iletisim_content_container'>
                <div className='map_container'>
                    <iframe
                        title="Google Maps"
                        width="100%"
                        height="100%"
                        className='map_frame'
                        style={{ border: 0 }}
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1324.2457462393497!2d32.51493682903003!3d37.86953354785069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzfCsDUyJzA5LjkiTiAzMsKwMzAnNTMuMyJF!5e0!3m2!1str!2str!4v1708928928373!5m2!1str!2str"
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>

                </div>



                <div id="iletisim_form_container">
                    <h4 className="iletisim_form_title" >{t("iletisim2")} </h4>

                    <Form>
                        <FormGroup >
                            <Label htmlFor="disabledTextInput0"> {t("iletisim3")}</Label>
                            <Input id="disabledTextInput0" placeholder="" style={{ marginLeft: 0 }} />
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="formBasicEmail">
                            <Label> {t("iletisim4")}</Label>
                            <Input type="email" placeholder="" style={{ marginLeft: 0 }} />
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="formBasicEmail">
                            <Label> {t("iletisim5")}</Label>
                            <Input type="text" placeholder="" style={{ marginLeft: 0 }} />
                        </FormGroup>
                        <FormGroup className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Label>  {t("iletisim6")} </Label>
                            <Input type="textarea" rows={6} style={{ resize: "none" }} />
                        </FormGroup>
                        <p>{t("iletisim7")}</p>
                        <Captcha > </Captcha>
                    </Form>

                </div>



            </div>

            <Container className="mt-5 pt-5 mb-5 pb-5">
                <Row>
                    <Col sm={12} md={3}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={Location} alt="" className=" img" />
                            <h4 id="title" style={{ textAlign: 'center' }}> {t("iletisim8")}</h4>
                            <p id="info" style={{ textAlign: 'center' }}> {t("iletisim9")}  </p>
                        </div>

                    </Col>
                    <Col md={2} style={{ display: 'flex', alignItems: 'center' }} id="line">
                        <img src={Line} alt="" style={{ width: '80%', height: '8px' }} id="line" />
                    </Col>
                    <Col sm={12} md={2}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={Call} alt="" className=" img" />
                            <h4 id="title" style={{ textAlign: 'center' }}>  {t("iletisim10")} </h4>
                            <p id="info" style={{ textAlign: 'center' }}>444 55 42 - 4091-4092</p>
                        </div>

                    </Col>
                    <Col md={2} style={{ display: 'flex', alignItems: 'center' }} id="line">
                        <img src={Line} alt="" style={{ width: '80%', height: '8px' }} id="line" />
                    </Col>
                    <Col sm={12} md={2}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={Message} alt="" className="img" />
                            <h4 id="title" style={{ textAlign: 'center' }}>  {t("iletisim11")}  </h4>

                            <p id="info" style={{ textAlign: 'center' }}> bilgi@kuzem.org</p>

                        </div>
                    </Col>


                </Row>
            </Container>









            <MainFooter visibility={'hidden'}></MainFooter>
        </StyledMainContainer>
    )
}

export default İletisim



const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`