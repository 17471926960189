import { Button, Input, Table } from 'antd'
import React, { useState, useContext, useEffect, useMemo } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { axiosInstance } from '../../../api/axiosInstance'
import { ExamContext } from '../ExamManagementDashboard'
import { toast } from 'react-toastify'
const QuestionPool = () => {

    const { examData, setExamData, modalChoiceList, questionPoolModal, setQuestionPoolModal, previewSrc, setPreviewSrc, setModalChoiceList, editMode, setEditMode, modalQuestion, setModalQuestion, modalShow, setModalShow, courseList, setCourseList, questionPoint, setQuestionPoint } = useContext(ExamContext)
    const [loading, setLoading] = useState(false)
    const [questionArray, setQuestionArray] = useState([])
    const [selectedRowKey, setSelectedRowKey] = useState([])
    const [selectedPoolQuestion, setSelectedPoolQuestion] = useState([])
    const getAllForQuestionList = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/BranchQuestions/GetAllForTeacher", {
                branchId: examData?.branch?.id,
                pageRequest: {
                    page: 0,
                    pageSize: 1000
                }
            })
            console.log("rexxx", response.data.items)
            setQuestionArray(response.data.items?.map(item => {
                const { id, ...rest } = item

                return {
                    key: id,
                    ...rest,
                    id

                }
            }))
        }
        catch (err) {
            console.log("eee", err)
        }
        finally {
            setLoading(false)

        }
    }





    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [searchTextName, setSearchTextName] = useState("")

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset

                </Button>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });





    const poolQuestionList = useMemo(() => {
        return questionArray?.filter(el => {
            let control = examData?.questions?.findIndex(item => item?.id == el?.key)
            return control == -1

        })
    }, [questionArray, examData])








    useEffect(() => {
        getAllForQuestionList()
    }, [examData])

    return (
        <Modal isOpen={questionPoolModal.visible} size='lg' >
            <ModalHeader>
                Soru Havuzu
            </ModalHeader>
            <ModalBody>
                <Table
                    rowSelection={{
                        selectedRowKeys: selectedRowKey,
                        onChange: (e) => {
                            setSelectedRowKey(e)
                        }
                    }}
                    size='small'
                    scroll={{
                        x: 700
                    }}
                    /* rowClassName={(record) => {
                        return record?.id == selectedPoolQuestion?.id ? "highlight-row" : ""
                    }} */
                    dataSource={poolQuestionList ?? []}
                    columns={[

                        {
                            "title": "Soru",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.question}
                                    </p>
                                )
                            }
                        },
                        {
                            "title": "Puan",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.point}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Zorluk",
                            dataIndex: "level",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.level == 0 ? "Seçilmedi" : record?.level}
                                    </p>
                                )
                            },
                            ...getColumnSearchProps("level")
                        },
                        /* {
                            "title": "İşlem",
                            render: (row, record) => {
                                return (
                                    <Button type='primary' onClick={() => {

                                        setSelectedPoolQuestion(record)
                                    }} >
                                        Seç
                                    </Button>
                                )
                            }
                        }, */
                    ]}
                />
            </ModalBody>
            <ModalFooter>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%"
                }}>
                    <p style={{
                        fontWeight: "bold"
                    }}>
                        Seçilen Soru Adeti : {selectedRowKey?.length}
                    </p>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "10px"
                    }} >
                        <Button type='primary' disabled={selectedRowKey.length == 0} onClick={() => {
                            console.log("selectedRow", selectedRowKey)
                            console.log("arrQuestion", questionArray)
                            let finalArr = []
                            for (let item of selectedRowKey) {
                                console.log("itexx", item)
                                let selectedQuestion = questionArray?.find(el => el?.id == item)
                                finalArr.push(selectedQuestion)
                            }
                            setExamData({
                                ...examData,
                                questions: [
                                    ...examData.questions,
                                    ...finalArr?.map(record => {
                                        return {
                                            "id": record?.id,
                                            "poolFlag": true,
                                            "questionContent": record?.question,
                                            "point": record?.point,
                                            "choices": record?.branchQuestionAnswers?.map((el, index) => {
                                                return {
                                                    id: el.id,
                                                    letter: el?.option,
                                                    content: el?.answer,
                                                    correct: el?.status
                                                }
                                            }),
                                            "questionType": "Test",
                                            "file": null
                                        }
                                    })
                                ]
                            })
                            setSelectedRowKey([])
                            setQuestionPoolModal({
                                visible: false,
                                data: []
                            })
                        }} >
                            Kaydet
                        </Button>
                        <Button danger type='primary' onClick={() => {
                            setQuestionPoolModal({
                                visible: false,
                                data: []
                            })
                            setSelectedRowKey([])
                        }} >
                            Kapat
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default QuestionPool