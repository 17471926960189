
import "./CardLesson.scss"

import { Row, Col, Container, Card, CardFooter, CardBody, CardImg, CardTitle, CardText } from 'reactstrap';

import PropTypes from 'prop-types';
import ButtonPurple from '../buttons/ButtonPurple';
import styled from 'styled-components';
import { FaStar, FaRegStar } from 'react-icons/fa';
import Rating from 'react-rating';




const CardLesson = (props) => {
    const { infoText, onClick, hiddenBtn } = props;

    const fontSize = infoText?.length > 37 ? 'smaller' : 'larger';


    return (
        <StyledCard onClick={() => {
            onClick()
        }} >

            <StyledCardImg variant="top" src={props.imgcard} />
            {/*    <img src={VoteStars} alt='' style={{ width: "70px", height: "25px", }}></img>   */}
            <StyledCardBody >
                <StyledCardText className='info' style={{ fontWeight: "bolder", textTransform: "capitalize" }}>
                    {props?.infoText?.length > 30 ? `${props?.infoText?.substring(0, 35)}...` : props?.infoText}
                </StyledCardText>
            </StyledCardBody>

            <StyledCardFooter >
                <div className="card_lesson_footer_container">
                    <div className="card_lesson_footer_left" >
                        <img src={props?.imageSrc} alt="" className='imgProfile'></img>
                        <StyledTeacherName style={{ fontSize: `${props?.name?.length > 20 ? "xx-small" : ""}` }}>{props?.name}  </StyledTeacherName>
                    </div>
                    {
                        !hiddenBtn && (
                            <ButtonPurple onClick={onClick} buttonText={"incele"} btnColor={props?.btnColor} id="btn" ></ButtonPurple>
                        )
                    }

                </div>
            </StyledCardFooter>
        </StyledCard>

    )
}

export default CardLesson;


const StyledCardText = styled(CardText)`
    font-family: "New Amsterdam", sans-serif !important;
    font-size: 14px !important;
    letter-spacing: 2px;
    
`
const StyledImg = styled.img`
    width: 24px;
    height: 24px;
`


const StyledCardBody = styled(CardBody)`
    padding: 5px 10px;
    min-height: 90px;
`

const StyledCard = styled(Card)`
border: 1px solid transparent;
margin-right: 0 !important;
cursor: pointer;
/* margin-right: 0 !important; */

    @media screen and (max-width:600px) {
        margin-right: 0px;
    }
`

const StyledCardFooter = styled(CardFooter)`
    width: 100%;
    z-index: -122;

    .card_lesson_footer_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card_lesson_footer_left {
        display: flex;
        align-items: center;
        column-gap: 15px;


        .imgProfile{
            width: 30px;
            border-radius: 50%;
        }
    }
}
`

const StyledCardImg = styled(CardImg)`
    height: 150px;
   /*  object-fit: cover; */
  /*   border-radius:10px; */
    object-position: bottom;
`

const StyledTeacherName = styled.p`
    font-size: 12px;

`