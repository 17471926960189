import React from 'react'

const Kvk = () => {
  return (
    <div style={{ padding: "3% 3%", display: "flex", flexDirection: "column", rowGap: "20px" }}>
      <h1>AÇIK RIZA BEYAN FORMU</h1>
      <a>
        KONYA BÜYÜKŞEHİR BELEDİYE BAŞKANLIĞI, KOMEK tarafından Kişisel Verilerin Korunması ve İşlenmesi Politikasını Gösteren Hadim İletişim kayıtlarında Aydınlatma Metnine uygun olarak kişisel verileriniz işlenebilmektedir. Açık rızanız kapsamında, kampanya faaliyetlerinin planlanması ve icrası; Belediyemiz faaliyetlerinin ilgili mevzuata uygun olarak yürütülmesinin temini, yönetim süreçlerinin planlanması ve icrası gibi amaçlarla paylaşmış olduğunuz kişisel verileriniz belediyemiz şirketleri dâhil olmak üzere organizasyonun yürütülebilmesi için kanunen yetkili kılınmış kamu kurumları, şehrimiz ilçe belediyeleri ve hizmet alınan üçüncü taraflar ile yasal sınırlamalar çerçevesinde paylaşılabilir. KONYA BÜYÜKŞEHİR BELEDİYE BAŞKANLIĞI, KOMEK sistemine online olarak kayıt olanlar,Mesaj Gönderenler 6698 sayılı Kişisel Verilerin Korunması Hakkındaki Kanuna uyumlu şekilde toplanmasına, işlenmesine, güncellenmesine, periyodik olarak kontrol edilmesine, veri tabanında tutulmasına, saklanmasına, kanunen yetkili kılınmış kamu kurumları ve kuruluşlar ile paylaşılmasına, veri sorumlusunun kampanyada belirtilen hizmetlerini sunulabilmesi için paylaşımın gerekli ve/veya zorunlu olduğu özel kuruluşlarla da kişisel verilerin korunmasına ilişkin mevzuatın öngördüğü esaslar dâhilinde paylaşılmasına dair gerekli aydınlatmanın şahsına mevzuat hükümlerine uygun şekilde yapıldığını, açık rıza ve aydınlatma metninin tamamını eksiksiz şekilde okuyup anladığını, ayrıca sözleşmenin kurulması için gerekli olması veya veri sorumlusunun meşru menfaati halleri haricinde de kişisel verilerinin veri sorumlusu tarafından işlenmesine ve muhafaza edilmesine açık rızası bulunduğunu kabul, beyan ve taahhüt etmektedir. Yukarıda yer alan hususlara bilerek ve isteyerek rıza gösterdiğimi beyan ederim.
      </a>
    </div>
  )
}

export default Kvk
