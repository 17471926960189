import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import EKitapDashboard from '../../Components/EKitap/EKitapDashboard'

const EKitapPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer visible={false} header={"E-Kitap"} >
                <EKitapDashboard />
            </TablePageContainer>
        </div>
    )
}

export default EKitapPage