import React from 'react'
import "./Progress_Bar.scss"
import styled from 'styled-components'




const Progress_bar = ({ bgcolor, progress, height, text, text2 }) => {

  const Parentdiv = {
    height: height,
    width: '100%',
    backgroundColor: '#b8bec3',
    /*  borderRadius: 40, */

  }

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
    /*   borderRadius:40, */
    textAlign: 'right'
  }

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900,

  }

  const StyledContainer = styled.div`
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width:500px) {
        flex-direction: column;
      }
    `
  const StyledProgressText = styled.div`
    white-space: nowrap;
    margin-left: 15px;
    font-weight: 600;
    font-size: 15px;


    @media screen and (max-width:500px) {
      font-size: 10px !important;
      margin-top: 10px;
    }
   `


  return (
    <StyledContainer >
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <div className='parent-div-text-course'>
          <p style={{ marginRight: "15px", fontWeight: "500", fontSize: "14px", color: "#4E5566", whiteSpace: "nowrap" }}>{text2}</p>
        </div>
        <div style={Parentdiv}>
          <div style={Childdiv}>
          </div>
        </div>
      </div>
      <StyledProgressText >{progress}% {text}</StyledProgressText>
    </StyledContainer>

  )
}

export default Progress_bar;