import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/pagination';
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance';
import Slider from 'react-slick';
import CoverPhoto from "../../../assets/images/auth-one-bg.jpg"
import CoverPhoto2 from "../../../assets/images/b1.png"
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Carousel } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import CoverPhoto3 from "../../../assets/images/b3.png"

const SameCourseVideo = ({ otherCourseList, courseType }) => {

    const navigate = useNavigate()

    const getImg = (element) => {
        if (element?.url == "0") {
            return CoverPhoto3
        }
        return element?.url
    }



    if (otherCourseList?.length == 0) {
        return (
            <div style={{ height: "150px" }} >

            </div>
        )
    }

    return (
        <SameCourseContainer>
            <h5 className='same_course_header_title'>
                Diğer Eğitimler
            </h5>
            <div className='slider_same_course_container'>
                <Swiper modules={[
                    Navigation
                ]}
                    breakpoints={{
                        // Mobilde tek slide göster
                        0: {
                            slidesPerView: 1,
                        },
                        // Tablet ve üstü ekranlarda 2 slide göster
                        768: {
                            slidesPerView: 2,
                        },
                        // Daha büyük ekranlarda 4 slide göster
                        1024: {
                            slidesPerView: 5,
                        },

                    }}

                    slidesPerView={4}  >
                    {
                        otherCourseList?.map((el, index) => {

                            return (
                                <SwiperSlide key={`${index}`} >
                                    <CardContainer onClick={() => {

                                    }} >
                                        <Container2>
                                            <CoverImg src={getImg(el)} alt="" />
                                            <div className='same_course_name_wrapper'>
                                                <p className='same_course_name'>
                                                    {el?.name?.length > 40 ? `${el?.name?.substring(0, 40)}...` : el.name}
                                                </p>
                                            </div>

                                            <div className='same_course_card_footer'>
                                                <div className='category_el' >
                                                    {el?.category?.name?.length > 20 ? `${el.category?.name?.substring(0, 20)}...` : el?.category?.name}
                                                </div>
                                                <Button type='primary' size='small' onClick={() => {
                                                    const uri = encodeURIComponent(el?.label)
                                                    navigate(`/kurs/${uri}`)
                                                }} >
                                                    İncele
                                                </Button>
                                            </div>
                                        </Container2>

                                    </CardContainer>
                                </SwiperSlide>


                            )
                        })
                    }

                </Swiper>


            </div>

        </SameCourseContainer>
    )
}

export default SameCourseVideo


const SameCourseContainer = styled.div`
  padding-left: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 0px;

  .same_course_header_title {
    font-size: 18px;
    font-weight: bold;
  }

  .slider_same_course_container {
    margin-bottom: 100px;
    position: relative; /* Relative konumlandırma ekleyin */

    .swiper-pagination-custom {
      position: absolute;
      bottom: -30px; /* Kartların altında olacak şekilde konumlandırma */
      left: 0;
      right: 0;
      text-align: center;

      .swiper-pagination-bullet {
        background: black; /* Noktaların rengini siyah yapın */
        opacity: 1;
        margin: 0 5px; /* Noktalar arasındaki boşluk */
      }

      .swiper-pagination-bullet-active {
        background: #000; /* Aktif noktanın rengi */
      }
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;


const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Container2 = styled.div`
    width: 90%;
    border: 1px solid #D1D5DB;
    border-radius: 10px;
    
  @media screen  and (max-width:600px){
    width: 95%;
  }

    .same_course_name_wrapper{
        height: 90px;
        padding-top: 10px;
        padding-left: 10px;
        .same_course_name{
        
            font-size: 14px;
            text-transform: capitalize;
            color: #80949F;


         }

    }
    
    .same_course_card_footer{
        background-color: #EFEFEF;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;  
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category_el{
           
            padding: 3px 5px;
            border-radius: 5px;
            letter-spacing: 2px;
            font-size: 10px;
            width: max-content;
            text-transform: capitalize;
        }
    }
`

const CoverImg = styled.img`
    width:100%;
    height: 150px;
    max-height: 150px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`