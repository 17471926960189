import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../api/axiosInstance'
import { Nav, NavItem, NavLink } from 'reactstrap'
import styled from 'styled-components'
import { current } from '@reduxjs/toolkit'
import EditBranchGeneralInfo from './EditBranchGeneralInfo'
import EditBranchContent from './EditBranchContent'

const EditBranchDashboard = ({ setTitle }) => {
    const [fetchLoading, setFetchLoading] = useState()
    const { id } = useParams()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(0)
    const [branchDetailData, setBranchDetailData] = useState()
    const getBranchDetail = async () => {
        try {
            setFetchLoading(true)
            const response = await axiosInstance.get(`/branches/getById/${id}`)
            setBranchDetailData(response.data)
       
            setTitle(response.data?.name)
            if (!response.data) {
                navigate(-1)
            }
        }
        catch (err) {
            navigate("/panel/brans")
        }
        finally {
            setFetchLoading(false)

        }
    }
    useEffect(() => {
        getBranchDetail()
    }, [])

    return (
        <StyledContainer>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={activeTab == 0 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setActiveTab(0)
                        }}
                    >
                        Bilgiler
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setActiveTab(1)
                        }}
                    >
                        İçerik
                    </NavLink>
                </NavItem>
            </Nav>
            {
                activeTab == 0 && (
                    <EditBranchGeneralInfo branchData={branchDetailData} branchDetailRequest={getBranchDetail} />
                )
            }
            {
                activeTab == 1 && (
                    <EditBranchContent />
                )
            }
        </StyledContainer>

    )
}

export default EditBranchDashboard


const StyledContainer = styled.div`
    padding: 20px;

`