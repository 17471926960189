import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input as RInput } from "reactstrap"
import { Button, Input, Select, Modal as AntModal } from "antd"
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { uniqueId } from 'lodash'
import { axiosInstance } from '../../api/axiosInstance'
const EgitmenSoruEkle = ({ branchList, addQuestionData, setAddQuestionData, modalInfo, setModalInfo, refreshMethod }) => {


    const SubmitValidate = () => {

        if (addQuestionData.questionContent == "") {
            toast.error("Soru içeriği boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (addQuestionData?.answers.some(item => item.content == "")) {
            toast.error("Cevap şıkkı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (!addQuestionData?.answers.some(item => item.correct)) {
            toast.error("Bir tane doğru şık seçiniz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (addQuestionData?.branchId == "") {
            toast.error("Branş seçiniz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (addQuestionData.point > 100 || addQuestionData.point <= 0) {
            toast.error("Puan 0 ile 100 arasında olmalı", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }

        return true
    }

    const resetAddQuestion = () => {
        setAddQuestionData({
            questionContent: "",
            point: 0,
            answers: [
                {
                    "content": "",
                    "id": uniqueId(),
                    "correct": false,
                    "option": "a"
                },
                {
                    "content": "",
                    "id": uniqueId(),
                    "correct": false,
                    "option": "b"
                },
                {
                    "content": "",
                    "id": uniqueId(),
                    "correct": false,
                    "option": "c"
                },
                {
                    "content": "",
                    "id": uniqueId(),
                    "correct": false,
                    "option": "d"
                },
                {
                    "content": "",
                    "id": uniqueId(),
                    "correct": false,
                    "option": "e"
                }
            ],
            branchId: ""
        })
    }


    return (
        <Modal isOpen={modalInfo.visible} size='lg' >
            <StyledModalHeader>
                Soru Ekle

                <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                    <p>
                        Branş :
                    </p>
                    <BranchSelect
                        value={addQuestionData.branchId}
                        placeholder="Branş Seçiniz"
                        onChange={e => {
                            setAddQuestionData({
                                ...addQuestionData,
                                branchId: e
                            })
                        }}
                        showSearch
                        filterOption={(input, option) => {

                            return (
                                option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                            )
                        }}
                        options={branchList?.map(item => {
                            return {
                                "label": `${item?.id}-${item?.branch.name}`,
                                "value": item?.branch?.id
                            }
                        })}

                    />
                </div>
            </StyledModalHeader>
            <ModalBody  >
                <QuestionContainer>
                    <Input.TextArea value={addQuestionData.questionContent} placeholder='Soru İçeriği' style={{ resize: "none" }} rows={3} onChange={(e) => {
                        setAddQuestionData({
                            ...addQuestionData,
                            questionContent: e.target.value
                        })

                    }} />


                </QuestionContainer>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }} >
                    <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }} >
                        <span style={{
                            fontWeight: "bold"
                        }} >
                            Puan :
                        </span> <Input value={addQuestionData.point} onChange={e => {
                            setAddQuestionData({
                                ...addQuestionData,
                                point: e.target.value
                            })
                        }} type='number' style={{ width: "100px", marginLeft: "10px" }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: "10px 0" }} >
                        <span style={{ fontWeight: "bold" }} >
                            Zorluk :
                        </span> <Select
                            value={addQuestionData?.level}
                            style={{
                                minWidth: "120px",
                                marginLeft: "10px"
                            }}
                            onChange={(e) => {
                                setAddQuestionData({
                                    ...addQuestionData,
                                    level: e
                                })
                            }}
                            options={[

                                {
                                    label: "Kolay",
                                    value: "Kolay"
                                },
                                {
                                    label: "Orta",
                                    value: "Orta"
                                },
                                {
                                    label: "Zor",
                                    value: "Zor"
                                }
                            ]}

                        />
                    </div>
                </div>
                <QuestionAnswerContainer>
                    {
                        addQuestionData.answers?.map(item => {
                            return (
                                <AnswerItem key={`${item.id}`} >
                                    <div className='option_item' >
                                        {item.option}
                                    </div>
                                    <Input className='answer_input' value={item.content} onChange={(e) => {
                                        setAddQuestionData({
                                            ...addQuestionData,
                                            answers: addQuestionData?.answers?.map(el => {
                                                if (el.id == item.id) {
                                                    return {
                                                        ...el,
                                                        content: e.target.value
                                                    }
                                                }
                                                return el
                                            })
                                        })
                                    }} />
                                    <StyledCheckElement type='checkbox' checked={item.correct} onChange={e => {
                                        setAddQuestionData({
                                            ...addQuestionData,
                                            answers: addQuestionData?.answers?.map(el => {
                                                if (el.id == item.id) {
                                                    return {
                                                        ...el,
                                                        correct: e.target.checked
                                                    }
                                                }
                                                else {
                                                    return {
                                                        ...el,
                                                        correct: false
                                                    }
                                                }
                                            })
                                        })
                                    }} />
                                </AnswerItem>
                            )
                        })
                    }


                </QuestionAnswerContainer>
            </ModalBody>
            <ModalFooter>
                <Button type='primary' style={{ marginRight: "10px" }} onClick={() => {
                    if (SubmitValidate()) {

                        AntModal.confirm({
                            title: "Bu soruyu eklemek istediğinize emin misiniz?",
                            okText: "Eminim",
                            cancelText: "iptal",
                            onOk: async () => {
                                try {

                                    const response = await axiosInstance.post("/branchQuestions/add", {
                                        "question": addQuestionData.questionContent,
                                        "branchId": addQuestionData?.branchId,
                                        "point": addQuestionData?.point,
                                        "level": addQuestionData?.level
                                    })
                                    for (let item of addQuestionData.answers) {
                                        await axiosInstance.post("/branchQuestionAnswers/Add", {
                                            answer: item.content,
                                            option: item.option.toUpperCase(),
                                            status: item.correct,
                                            branchQuestionId: response.data.id
                                        })
                                    }

                                    toast.success("Soru oluşturuldu", {
                                        autoClose: 3000,
                                        position: "top-center"
                                    })
                                    setModalInfo({
                                        visible: false
                                    })
                                    resetAddQuestion()
                                    refreshMethod()
                                }
                                catch (err) {

                                }
                            }
                        })
                    }


                }}  >
                    Kaydet
                </Button>
                <Button danger type='primary' onClick={() => {
                    setModalInfo({
                        visible: false
                    })
                    resetAddQuestion()
                }} >
                    Kapat
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default EgitmenSoruEkle

const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const QuestionAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;

`

const BranchSelect = styled(Select)`
    width:240px;

`
const AnswerItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;

    .option_item{
        width: 30px;
        height: 30px;
        border-radius: 20px;
        text-transform: capitalize;
        background-color: #3577F1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .answer_input{
        height: 40px;
    }
`


const StyledModalHeader = styled(ModalHeader)`
    width: 100%;

    h5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`

const StyledCheckElement = styled(RInput)`

                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-left: 10px;
                   margin-top: 0;

`