import { useTranslation } from "react-i18next"
import CardCatagories from '../cardCategories/CardCategories';
import React, { useState, useEffect } from "react";
import { axiosInstance } from '../../../api/axiosInstance';
import { AiFillBell } from "react-icons/ai";
import { AiFillBulb } from "react-icons/ai";
import { AiFillDashboard } from "react-icons/ai";
import { AiFillCrown } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import { AiFillFire } from "react-icons/ai";
import { MdOutlinePlayLesson } from "react-icons/md";
import { LiaSchoolSolid } from "react-icons/lia";
import { SiReactos } from "react-icons/si";
import { SiReactivex } from "react-icons/si";
import Slider from 'react-slick';
import "./mySlider.scss"




const MySlider = () => {
    const { t } = useTranslation();


    const [courses, setCourses] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/Categories/GetAll', {
                    page: 0,
                    pageSize: 10
                });
                const totalCourses = response.data.count;
                const initialCourses = response.data.items;
                setCourses([...initialCourses]);
            } catch (error) {
        
            }
        };

        fetchData();
    }, []);



    const slideCount = () => {
        const length = courses.length;
    
        if (length >= 5) {
            return 5;
        } else if (length === 4) {
            return 4;
        } else if (length === 3) {
            return 3;
        } else if (length === 2) {
            return 2;
        } else if (length === 1) {
            return 1;
        } else {
            return 1; 
        }
    }

   

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slideCount(),
        slidesToScroll: slideCount(),      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1100, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },

            {
                breakpoint: 1024, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 500, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ]
    };





    /*     icon mixer-----------*/

    function getRandomİcons() {
        const İconss = [
            <AiFillBell color='#7f56d9' fontSize={"60px"} />,
            <AiFillBulb color='#00C1FF' fontSize={"60px"} />,
            <AiFillDashboard color='#24D198' fontSize={"60px"} />,
            <AiFillCrown color='#0000FF' fontSize={"60px"} />,
            <AiFillLike color='#C0E5EB' fontSize={"60px"} />,
            <AiFillFire color='#FBDCD4' fontSize={"60px"} />,
            <MdOutlinePlayLesson  color='#572a2a' fontSize={"60px"} />,
            <LiaSchoolSolid  color='#0000FF' fontSize={"60px"}/>,
            <SiReactos  color='#af6868' fontSize={"60px"}/>,
            <SiReactivex   color='#946868' fontSize={"60px"}/>
        ];
        return İconss[Math.floor(Math.random() * İconss.length)];
    }
    
  

    return (
        <div>

            <Slider
                {...settings}
            >
                {courses.map((course, index) => (
                    <div className="photo_section">
                        {course ? (
                            <CardCatagories data={course} text={course.name.toUpperCase()} icon={getRandomİcons()}></CardCatagories>
                        ) : (
                            <p></p>
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default MySlider;
