import React, { useState } from 'react'
import Editor from './Editor'
import { Button } from 'antd'
import axios from 'axios'
import MainEditor from '../../Components/Editor/MainEditor'
import QuillEditor from '../../Components/Editor/QuillEditor'

const TestPage = () => {

  const [state, setState] = useState({ value: null });

  return (
    <div>
      {/* <MainEditor /> */}
      <QuillEditor editorHtml={state} setEditorHtml={setState} />
    </div>
  )
}

export default TestPage