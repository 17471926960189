// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.son-aktiviteler-box {
  padding: 0; }
  .son-aktiviteler-box .son-aktiviteler-head {
    display: flex;
    justify-content: space-between; }
    .son-aktiviteler-box .son-aktiviteler-head h4 {
      font-weight: 400;
      font-size: 16px;
      color: #1D2026;
      padding-top: 10px;
      padding-bottom: 10px; }
    .son-aktiviteler-box .son-aktiviteler-head p {
      padding-top: 10px;
      color: #6E7485; }

.son-aktiviteler-body {
  height: 400px;
  margin-top: 3%;
  overflow-y: scroll; }
  .son-aktiviteler-body::-webkit-scrollbar {
    width: 0; }
  .son-aktiviteler-body .comment-box {
    display: flex; }
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/LastActivities.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA;EADd;IAIQ,aAAa;IACb,8BAA8B,EAAA;IALtC;MAOQ,gBAAgB;MAChB,eAAe;MACf,cAAc;MACd,iBAAiB;MACjB,oBAAoB,EAAA;IAX5B;MAcQ,iBAAiB;MACjB,cAAc,EAAA;;AAKtB;EACI,aAAa;EACb,cAAc;EACf,kBAAkB,EAAA;EAHrB;IAMQ,QACJ,EAAA;EAPJ;IAUQ,aAAa,EAAA","sourcesContent":[".son-aktiviteler-box{\n    padding: 0;\n  \n    .son-aktiviteler-head{\n        display: flex;\n        justify-content: space-between;\n          h4{\n        font-weight: 400;\n        font-size: 16px;\n        color: #1D2026;\n        padding-top: 10px;\n        padding-bottom: 10px;\n    }\n    p{\n        padding-top: 10px;\n        color: #6E7485;\n    }\n    }\n  \n}\n.son-aktiviteler-body{\n    height: 400px;\n    margin-top: 3%;\n   overflow-y: scroll;\n\n    &::-webkit-scrollbar{\n        width: 0\n    }\n\n    .comment-box{\n        display: flex;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
