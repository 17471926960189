import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { get } from "lodash";
import languages from "./language";
import { useTranslation } from 'react-i18next';

const LanguagePicker = () => {
    const [selectedLang, setSelectedLang] = useState("");

    const { t, i18n } = useTranslation();

    useEffect(() => {
        
        const savedLang = localStorage.getItem("selectedLang");
        if (savedLang) {
            setSelectedLang(savedLang);
            i18n.changeLanguage(savedLang); 
        } else {
            setSelectedLang("tr"); 
            i18n.changeLanguage("tr"); 
        }
    }, [i18n]);

    const changeLanguageAction = async (lang) => {
        await i18n.changeLanguage(lang);
        localStorage.setItem("selectedLang", lang); 
        setSelectedLang(lang);
    };

    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
    const toggleLanguageDropdown = () => {
        setIsLanguageDropdown(!isLanguageDropdown);
    };

    return (
        <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
            <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                <img
                    src={get(languages, `${selectedLang}.flag`)}
                    alt="Header Language"
                    height="20"
                    className="rounded"
                />
            </DropdownToggle>
            <DropdownMenu className="notify-item language py-2">
                {Object.keys(languages).map(key => (
                    <DropdownItem
                        key={key}
                        onClick={() => changeLanguageAction(key)}
                        className={`notify-item ${selectedLang === key ? "active" : "none"}`}
                    >
                        <img
                            src={get(languages, `${key}.flag`)}
                            alt="Skote"
                            className="me-2 rounded"
                            height="18"
                        />
                        <span className="align-middle">
                            {get(languages, `${key}.label`)}
                        </span>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

export default LanguagePicker;
