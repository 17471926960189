import React, { useEffect, useState } from 'react'
import { Button, Collapse, Input, Modal as AntModal, Alert } from "antd"
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { axiosInstance } from '../../../../api/axiosInstance'
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toast } from 'react-toastify'
import { PropagateLoader } from 'react-spinners'
import { SlArrowDown } from 'react-icons/sl'
import { FaEdit, FaTrash } from 'react-icons/fa'
const OnlineCoursePreviewContent = () => {


  const { id } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [subModal, setSubModal] = useState({
    parentElement: null,
    visible: false,
    data: null,
    editMode: false
  })
  const [modalInfo, setModalInfo] = useState({
    visible: false,
    data: null,
    editMode: false,
    id: null
  })
  const fetchContent = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/CourseContents/getallForCourseID?courseId=${id}`, {
        page: 0,
        pageSize: 50
      })
     
      setData(response.data.items)
    }
    catch (err) {
    }
    finally {
      setLoading(false)
    }
  }

  const fetchAddContent = async () => {
    try {
      if (!modalInfo.editMode) {
        setLoading(true)
        const response = await axiosInstance.post("/CourseContents/Add", {
          name: modalInfo.data,
          courseId: id
        })
        toast.success("Başlık eklendi", {
          position: "top-center",
          autoClose: 2000
        })
        setModalInfo({
          visible: false,
          data: null,
          editMode: false,
          id: null
        })
        fetchContent()
      }
      else {
   
        const response = await axiosInstance.put("/CourseContents/Update", {
          id: modalInfo.id,
          name: modalInfo.data,
          courseId: modalInfo.courseId
        })

        toast.success("Güncelleme başarılı", {
          autoClose: 2000,
          position: "top-center"
        })
        setModalInfo({
          visible: false,
          data: null,
          editMode: false,
          id: null
        })
        setData(data.map(item => {
          if (item.id == modalInfo.id) {
            return {
              ...item,
              title: modalInfo.data
            }
          }
          return item
        }))
        fetchContent()
      }
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }

  const deleteSubElement = async () => {
    try {
    
      const response = await axiosInstance.delete(`/OnlineCourseContentDetails/delete/${subModal.parentElement.id}`)
      toast.success("Silme işlemi başarılı", {
        autoClose: 2000,
        position: "top-center"
      })
      setSubModal({
        visible: false,
        data: null,
        editMode: false,
        parentElement: null
      })

      /* setData(data.map(item => {
        if (item.id == subModal.parentElement?.onlineCourseContentPreviewModelId) {
          return {
            ...item,
            onlineCourseContentPreviewItem: item.onlineCourseContentPreviewItem?.filter(el => {
              return el.id !== subModal.parentElement.id
            })
          }
        }

        return item
      })) */
      fetchContent()
    }
    catch (err) {

    }
  }

  const deleteMainElement = async () => {
    try {

      const response = await axiosInstance.delete(`/CourseContents/delete/${modalInfo.id}`)
      toast.success("Başlık silindi", {
        position: "top-center",
        autoClose: 2000
      })
      setModalInfo({
        visible: false,
        data: null,
        editMode: false,
        id: null
      })
      fetchContent()
    }
    catch (err) {

    }
  }


  const addSubItem = async () => {
    try {
      if (!subModal.editMode) {
      
        const response = await axiosInstance.post("/OnlineCourseContentDetails/add", {
          courseContentId: subModal.parentElement.id,
          description: subModal.data
        })
        toast.success("Alt başlık eklendi", {
          position: "top-center",
          autoClose: 2000
        })
        setSubModal({
          data: null,
          editMode: false,
          parentElement: null,
          visible: false
        })
        fetchContent()

      }
      else {

        const response = await axiosInstance.put("/OnlineCourseContentDetails/update", {
          id: subModal.parentElement.id,
          courseContentId: subModal.parentElement?.courseContentId,
          description: subModal.data
        })
        toast.success("Alt başlık güncellendi", {
          position: "top-center",
          autoClose: 2000
        })
        setSubModal({
          data: null,
          editMode: false,
          parentElement: null,
          visible: false
        })
        
        setData(data.map(item => {
          if (item.id == subModal.parentElement?.courseContentId) {
            return {
              ...item,
              onlineCourseContentDetails: item.onlineCourseContentDetails?.map(el => {
                if (el.id == subModal.parentElement.id) {
                  return {
                    ...el,
                    description: subModal.data
                  }

                }
                return el
              })
            }
          }

          return item
        }))


      }

    }
    catch (err) {


    }
  }



  useEffect(() => {
    fetchContent()
  }, [])

  const customHeader = (element) => {
    
    return (
      <div style={{ padding: "15px", display: "flex", justifyContent: "space-between" }} >
        <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
          <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>{element.name}</span>
          <Button onClick={(e) => {
            e.stopPropagation()
            setModalInfo({
              editMode: true,
              visible: true,
              data: element.name,
              id: element.id,
              courseId: element.courseId
            })
          }} type='primary' size='small' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}  >
            <FaEdit />
          </Button>
        </div>
        <span style={{ paddingRight: "10px" }} >
          <SlArrowDown />
        </span>
      </div>
    )
  }

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: "70px 0" }}>
        <PropagateLoader color='green' />
      </div>

    )
  }

  return (
    <MainContainer>
      <MainHeader>
        <Button type='primary' onClick={() => {
          setModalInfo({
            visible: true,
            data: null,
            editMode: false
          })
        }} >
          Ana başlık ekle
        </Button>
      </MainHeader>
      <MainContent>
        {
          data.map((item, index) => {

            return (
              <StyledCollapse size='large' expandIcon={({ isActive }) => null} key={index}>
                <Collapse.Panel header={customHeader(item)} >
                  <div style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Button type='primary' onClick={() => {
                      setSubModal({
                        visible: true,
                        editMode: false,
                        data: null,
                        parentElement: item
                      })
                    }}  >
                      Alt başlık ekle
                    </Button>
                  </div>
                  <SubElementContainer>
                    {
                      item?.onlineCourseContentDetails.length !== 0 ? item.onlineCourseContentDetails?.map((el, index) => {
                        return (
                          <SubElement key={el?.id} onClick={() => {
                            setSubModal({
                              data: el.description,
                              editMode: true,
                              visible: true,
                              parentElement: el
                            })
                          }} >
                            <div className='index_key' >
                              {index + 1}
                            </div>
                            <p className='sub_item_desc' >
                              {el.description}
                            </p>

                          </SubElement>


                        )
                      }) : (
                        <Alert type='info' message="Henüz alt başlık eklenmedi" />
                      )
                    }

                  </SubElementContainer>
                </Collapse.Panel>
              </StyledCollapse>
            )
          })
        }


      </MainContent>

      <Modal isOpen={subModal.visible} >
        <ModalHeader>
          {subModal.editMode ? "Alt başlık güncelle" : "Alt başlık ekle"}
        </ModalHeader>
        <ModalBody>
          <Input value={subModal.data} placeholder='başlık' style={{
            height: "35px"
          }} onChange={(e) => {
            setSubModal({
              ...subModal,
              data: e.target.value
            })
          }} />
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
            {
              subModal.editMode && (
                <Button onClick={() => {
                  AntModal.confirm({
                    okText: "Eminim",
                    cancelText: "İptal",
                    title: "Silmek istediğinize emin misiniz?",
                    onOk: () => {
                      deleteSubElement()
                    }
                  })
                }} type='primary' danger style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                  Sil <FaTrash />
                </Button>
              )
            }
            <div style={{ display: "flex", columnGap: "10px" }}>
              <Button disabled={subModal.data == "" || subModal.data == null} type='primary' onClick={() => {
                addSubItem()
              }} >
                {subModal.editMode ? "Güncelle" : "Ekle"}
              </Button>
              <Button type='primary' danger onClick={() => {
                setSubModal({
                  editMode: false,
                  data: null,
                  parentElement: null,
                  visible: false
                })
              }} >
                İptal
              </Button>
            </div>
          </div>

        </ModalFooter>
      </Modal>

      <Modal isOpen={modalInfo.visible}>
        <ModalHeader>
          {
            modalInfo.editMode ? "Güncelle" : "Ana başlıık ekle"
          }

        </ModalHeader>
        <ModalBody>
          <Input value={modalInfo.data} style={{ height: "35px" }} placeholder='başlık ismi' onChange={(e) => {
            setModalInfo({
              ...modalInfo,
              data: e.target.value
            })
          }} />
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
            {
              modalInfo.editMode && (
                <Button onClick={() => {
                  AntModal.confirm({
                    title: "Silmek istediğinize emin misiniz?",
                    okText: "eminim",
                    cancelText: "İptal",
                    onOk: () => {
                      deleteMainElement()
                    }
                  })
                }} type='primary' danger style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                  Sil <FaTrash />
                </Button>
              )
            }
            <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <Button type='primary' disabled={modalInfo.data == "" || modalInfo.data == null} onClick={() => {
                fetchAddContent()
              }} >
                {
                  modalInfo.editMode ? "Güncelle" : "Ekle"
                }
              </Button>
              <Button type='primary' danger onClick={() => {
                setModalInfo({
                  visible: false,
                  data: null,
                  editMode: false,
                  id: null
                })
              }} >
                İptal
              </Button>
            </div>
          </div>


        </ModalFooter>
      </Modal>
    </MainContainer>
  )
}

export default OnlineCoursePreviewContent


const MainContainer = styled.div`
display: flex;
flex-direction: column;
`

const MainHeader = styled.header`
  display: flex;
  justify-content: flex-end;
`

const MainContent = styled.div`
  margin-top: 30px;
`

const StyledCollapse = styled(Collapse)`
  margin-bottom: 30px;
`
const SubElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 15px;
`

const SubElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 10px;
  .index_key{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #1677FF;
    color: white;
  }

  .sub_item_desc{
    width: "100%";
  background-color: #8080807d;
  padding: 10px;
  border-radius: 10px;
  transition: .5s all;
  cursor: pointer;
  width: 100%;
  &:hover{
    opacity: .7;
  }
  }
`