import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import ActiveCourseTable from '../../Components/Course/ActiveCourse/ActiveCourseTable'
import styled from 'styled-components'

const ActiveCourseDashboard = () => {
    return (
        <div className='page-content' >
            <TablePageContainer title='Canlı Eğitimler' header={"Canlı Eğitimler"} visible={false}  >
                <StyledContainer>
                    <ActiveCourseTable offlineOrOnline={"Online"} />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:500px) {
        padding: 0;
    }
`

export default ActiveCourseDashboard