
import { Pagination, Segmented, Skeleton, Tag, Button as AntButton } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { getResultByIdForStudent } from '../../api/exam'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'

const StudentExamResultModal = ({ modalInfo, setModalInfo }) => {
    const [loading, setLoading] = useState(false)
    const [examResultData, setExamResultData] = useState()
    const [tabId, setTabId] = useState("Sonuç")
    const [questionIndex, setQuestionIndex] = useState(0)
    const [imageModal, setImageModal] = useState({
        visible: false,
        src: null
    })
    console.log("modalInfxxxxxx", modalInfo)

    const examResultDetail = async (id) => {
        try {

            if (modalInfo.formal == "Ön Sınav") {
                const resp = await axiosInstance.get(`/ExamResults/GetResultForPrelim/${id}`)

                const { point, message, ...rest } = resp.data
                setExamResultData({
                    ...rest,
                    totalPoint: point,
                    avgExplain: message
                })
            }
            else {
                const response = await getResultByIdForStudent(id)

                setExamResultData(response.data)
            }

        }
        catch (err) {

        }
    }

    /* const examType = useMemo(() => {
        if (examResultData?.length !== 0) {
            return examResultData?.items[0]?.type
        }

    }, [examResultData]) */

    const examQuestions = useMemo(() => {
        if (examResultData?.items?.length !== 0 && modalInfo?.formal !== "Ön Sınav") {
            let data = examResultData?.items[0]?.examQuestions
            return data
        }
    }, [examResultData, modalInfo])

    const ExamResultPointSegment = useMemo(() => {
        return (
            <StyledModalResultBody>
                <p style={{ textAlign: "center", fontWeight: "bold", fontSize: 24, display: "flex", justifyContent: "center", alignItems: "center" }} >
                    Puan:
                    <span style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "green", color: "white", borderRadius: "50%", width: "40px", height: "40px", textAlign: "center", marginLeft: 20 }} >
                        {examResultData?.totalPoint ?? "?"}
                    </span>
                </p>
                {
                    examResultData?.avgExplain !== "" ? (
                        <p style={{ textAlign: "center", padding: 20, fontSize: 12, whiteSpace: "nowrap", backgroundColor: "#eee", borderRadius: 5 }}>
                            {examResultData?.avgExplain}
                        </p>

                    ) : null
                }

            </StyledModalResultBody>
        )
    }, [examResultData])


    const CorrectControl = (data) => {
        let ctrl1 = data?.examAnswers.find(el => {
            return el.examResults.length == 1
        })
        if (!ctrl1) {
            return "Bos"
        }
        let ctrl2 = data?.examAnswers.find(el => {
            return el.examResults.length == 1 && el.status
        })
        if (ctrl2) {
            return "Dogru"
        }
        return "Yanlis"


    }


    const ExamResultQuestions = useMemo(() => {

        if (examQuestions) {
            const selectedQuest = examQuestions[questionIndex]

            let ctr = CorrectControl(selectedQuest)

            return (
                <StyledExamResultQuestionWrapper>
                    {
                        examQuestions?.length !== 0 ? (
                            <>
                                <Input rows={3} type='textarea' value={selectedQuest?.question} disabled style={{ marginLeft: 0, resize: "none" }} />
                                {
                                    modalInfo?.type == "Test" && (
                                        <StyledQuestionAnswersContainer>

                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }} >
                                                <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                                                    <p>
                                                        Cevap şıkları
                                                    </p>
                                                    {
                                                        selectedQuest?.imageUrl !== "Dosya Seçilmedi." && (
                                                            <AntButton type='primary' size='small' onClick={() => {
                                                                setImageModal({
                                                                    visible: true,
                                                                    src: selectedQuest?.imageUrl
                                                                })
                                                            }} >
                                                                Fotoğrafı Gör
                                                            </AntButton>
                                                        )
                                                    }

                                                </div>
                                                <p style={{ backgroundColor: ctr == "Dogru" ? "green" : "red", padding: "5px 20px", color: "white", borderRadius: 5 }} >
                                                    {CorrectControl(selectedQuest)}
                                                </p>
                                            </div>
                                            {
                                                selectedQuest?.examAnswers?.map((el, index) => {
                                                    let bgColor = ""

                                                    if (el.examResults.length == 1) {
                                                        bgColor = "red"
                                                    }
                                                    if (el.status) {
                                                        bgColor = "green"
                                                    }
                                                    return (
                                                        <Input
                                                            style={{
                                                                marginLeft: 0,
                                                                backgroundColor: bgColor,
                                                                color: bgColor == "" ? "black" : "white"
                                                            }}

                                                            disabled
                                                            value={el?.answer}
                                                            type='text'
                                                            key={index} />
                                                    )
                                                })
                                            }

                                        </StyledQuestionAnswersContainer>
                                    )
                                }

                            </>

                        ) : (
                            <div class="alert alert-warning" role="alert">
                                Herhangi bir soru bulunamadı
                            </div>

                        )
                    }


                    <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }} >
                        <Pagination pageSize={1} total={examQuestions?.length} simple onChange={(e) => {
                            setQuestionIndex(e - 1)
                        }} />
                    </div>
                </StyledExamResultQuestionWrapper>
            )
        }

    }, [examQuestions, questionIndex])


    const segmentTabs = useMemo(() => {
        if (modalInfo?.formal == "Ön Sınav") {
            return ["Sonuç", "Sınav Soruları"]
        }
        else {
            if (modalInfo?.type == "Test") {
                return ["Sonuç", "Sınav Soruları"]
            }
            else {
                return ["Sonuç"]
            }

        }
    }, [modalInfo])

    useEffect(() => {
        if (modalInfo.visible) {
            examResultDetail(modalInfo.id)
        }
    }, [modalInfo])


    return (
        <>
            <Modal isOpen={modalInfo.visible} size='lg' centered >
                <StyledModalHeader>
                    <p style={{ textTransform: "capitalize" }} >
                        {modalInfo?.examName?.length > 20 ? `${modalInfo?.examName.substring(0, 20)}...` : modalInfo?.examName}
                    </p>
                    <AntButton danger type='primary' size='middle' onClick={() => {
                        setModalInfo({
                            id: "",
                            visible: false,
                            examName: ""
                        })
                        setTabId("Sonuç")
                        setExamResultData()
                    }} >
                        Kapat
                    </AntButton>
                </StyledModalHeader>
                <ModalBody>
                    {
                        loading ?
                            (
                                <Skeleton />
                            ) :
                            (
                                <StyledModalBodyContainer>
                                    <Segmented value={tabId} options={segmentTabs} onChange={(e) => {
                                        setTabId(e)
                                    }} block />
                                    {
                                        tabId == "Sonuç" ? ExamResultPointSegment : ExamResultQuestions
                                    }
                                </StyledModalBodyContainer>

                            )
                    }
                </ModalBody>
                <ModalFooter>

                </ModalFooter>
            </Modal>
            <Modal isOpen={imageModal.visible} size='lg' >

                <StyledModalHeader>
                    Fotoğraf
                    <AntButton type='primary' danger onClick={() => {
                        setImageModal({
                            visible: false,
                            src: null
                        })
                    }} >
                        Kapat
                    </AntButton>
                </StyledModalHeader>
                <ModalBody>
                    <img src={imageModal?.src} style={{ width: "100%" }} alt="" />
                </ModalBody>
            </Modal>
        </>

    )
}




const StyledModalResultBody = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin-top: 30px;
`

const StyledModalHeader = styled(ModalHeader)`
    h5{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
const StyledModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledExamResultQuestionWrapper = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 30px;
`
const StyledQuestionAnswersContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
`
const StyledTestAnswerInput = styled(Input)`
    margin-left: 0;
    background-color: ${props => props.correct ? "#1B9887 !important" : props.ctr ? "#1B9887 !important" : "red !important"};
    
`

export default StudentExamResultModal