import React from 'react'
import SoruHavuzu from '../../Components/SoruHavuzu/SoruHavuzu'
import TablePageContainer from '../../Components/Common/TablePageContainer'

const SoruHavuzuAdminPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer header={"Soru Havuzu"} visible={false} >
                <SoruHavuzu />
            </TablePageContainer>
        </div>

    )
}

export default SoruHavuzuAdminPage