import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as yup from "yup"
import { Steps, Modal as AntModal } from 'antd'
import { toast } from 'react-toastify';
import AddCourseDetailTab from "./AddCourseDetailTab"
import { createCourseApi } from '../../../api/Course';
import AddCourseProgram from "./AddCourseProgram"


const CreateCourseForm = () => {

    const [current, setCurrent] = useState(0);
    const [programData, setProgramData] = useState({
        day: "Pazartesi",
        startTime: "12:00",
        endTime: "12:30",
        id: ""
    })
    const [programList, setProgramList] = useState([])
    const [programDateList, setProgramDateList] = useState([])

    const isBetween8And20 = (date) => {
        const hour = new Date(date).getHours();
        return hour >= 8 && hour < 20;
    };

    const formik = useFormik({
        initialValues: {
            description: "",
            limit: 0,
            startDate: "",
            endDate: "",
            registerTime: "",
            registerStartTime: "",
            semester: "Güz",
            type: "",
            genderType: "",
            branchId: "",
            totalTime: "",
            startYear: 0,
            endYear: 0,
            discontinuity: 0,
            registerDeleteStatus: "",
            previewStatus: "",
            prelim: "",
        },
        validationSchema: yup.object({
            description: yup.string().required("Açıklama gereklidir"),
            semester: yup.string(),
            genderType: yup.string().required(),
            branchId: yup.string().required("Branş seçiniz"),
            discontinuity: yup.number().required().min(1, "minumum 1 olabilir").max(100, "maksimum 100 olabilir"),
            registerDeleteStatus: yup.string().required(),
            previewStatus: yup.string().required(),
            prelim: yup.string().required("Lütfen seçim yapınız"),
            totalTime: yup.number().min(1).required(),
            registerStartTime: yup.date()
                .required("Kayıt başlangıç zamanı gereklidir")
                .test('register-start-time', 'Kayıt başlangıç zamanı, kayıt bitiş tarihinden ve kurs başlangıç tarihinden önce olmalıdır', function (value) {
                    const { registerTime, startDate } = this.parent;
                    return new Date(value) < new Date(registerTime) && new Date(value) < new Date(startDate);
                }),

            registerTime: yup.date()
                .required()
                .test("register-time", "Kayıt zamanı başlangıç tarihinden önce ve günümüzden ileride olmalıdır", function (value) {
                    const startDate = this.parent.startDate;
                    return startDate && new Date(value) < new Date(startDate)
                }),
            startDate: yup
                .date()
                .required()
                .test('start-date', 'Başlangıç tarihi bitiş tarihinden önce ve günümüzden ileride olmalıdır', function (value) {
                    const endDate = this.parent.endDate;
                    return endDate && new Date(value) < new Date(endDate) && new Date() < new Date(value);
                }),
            endDate: yup
                .date()
                .required()
                .test('end-date', 'Bitiş tarihi başlangıç tarihinden sonra ve günümüzden ileride olmalıdır', function (value) {
                    const startDate = this.parent.startDate;
                    return startDate && new Date(startDate) < new Date(value) && new Date() < new Date(value);
                }),
            limit: yup.number().min(1, "minimum 1 kişi olabilir ").max(50, "maksimum 50 kişi olabilir").required(),
            startYear: yup.number()
                .required("Seçim yapınız")
                .min(1900, "Seçim yapınız")
                .test('start-year', 'Başlangıç yılı bitiş yılından küçük olmalıdır', function (value) {
                    const endYear = this.parent.endYear;
                    return !endYear || value <= endYear;
                }),
            endYear: yup.number()
                .required("Seçim yapınız")
                .min(1900, "Seçim yapınız")
                .test('end-year', 'Bitiş yılı başlangıç yılından büyük olmalıdır', function (value) {
                    const startYear = this.parent.startYear;
                    return !startYear || value >= startYear;
                })
        }),
        onSubmit: async (value, { resetForm }) => {

            const { branchId, startYear, totalTime, endYear, startDate, endDate, registerTime, type, previewStatus, registerDeleteStatus, discontinuity, description, prelim, registerStartTime, ...rest } = value

            AntModal.confirm({
                okText: "Eminim ?",
                cancelText: "İptal",
                content: "Bu kursu eklemek istediğinize emin misiniz ?",
                onOk: async () => {
                    try {
                        const encodedDescription = encodeURIComponent(description)
                        const response = await createCourseApi({
                            ...rest,
                            prelim: prelim == "Evet",
                            branchId: branchId,
                            description: encodedDescription,
                            startDate: new Date(startDate).toUTCString(),
                            endDate: new Date(endDate).toUTCString(),
                            registerTime: new Date(registerTime).toUTCString(),
                            registerStartTime: new Date(registerStartTime).toUTCString(),
                            startYear: parseInt(startYear),
                            endYear: parseInt(endYear),
                            type: "Online",
                            totalTime: totalTime,
                            previewStatus: previewStatus == "Evet",
                            registerDeleteStatus: registerDeleteStatus == "Evet",
                            discontinuity: discontinuity,

                        })

                        toast.success("Kurs Kaydedildi", {
                            autoClose: 2500,
                            position: "top-center"
                        })
                        resetForm()
                    }
                    catch (err) {

                    }
                }
            })


        },

    })


    return (
        <>
            <div  >
                {
                    current == 0 && (
                        <AddCourseDetailTab formik={formik} />
                    )
                }
                {
                    current == 1 && (
                        <AddCourseProgram programDateList={programDateList} setProgramDateList={setProgramDateList} formik={formik} setCurrent={setCurrent} programData={programData} setProgramData={setProgramData} programList={programList} setProgramList={setProgramList} />
                    )
                }

            </div>
        </>
    )
}

export default CreateCourseForm

/* {
    "description": "Php Test ",
    "limit": 50,
    "semester": "Güz",
    "genderType": "Hepsi",
    "categoryId": 39,
    "prelim": false,
    "branchId": 59,
    "startDate": "Sat, 27 Jul 2024 09:23:45 GMT",
    "endDate": "Sat, 24 Aug 2024 10:00:00 GMT",
    "startYear": 1939,
    "endYear": 2009,
    "type": "Online",
    "previewStatus": true,
    "registerDeleteStatus": true,
    "discontinuity": "15",
    "registerTime": "Thu, 25 Jul 2024 21:00:00 GMT"
} */