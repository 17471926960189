import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SliderGeneral from '../../sliderGeneral/SliderGeneral'
import { axiosInstance } from '../../../../api/axiosInstance'

const HomeOnerilenKurslar = () => {
    const { t } = useTranslation()
    const [recommanded, setRecommanded] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/RecommendedCourses/GetAll', {
                    page: 0,
                    pageSize: 10
                });

                setRecommanded(response.data?.items?.map(item => item?.course))

            } catch (error) {
       
            }
        };

        fetchData();
    }, []);


    if (recommanded.length == 0) {
        return (
            <div style={{ margin: "100px 0" }} >

            </div>
        )
    }

    return (
        <StyledContainer  >
            <p id='onerilen_kurslarimiz_header_p1' >
                {t("text5")}

                <span  >
                    {t("text6")}
                </span>
            </p>
            <SliderGeneral recommanded={recommanded} />
        </StyledContainer>
    )
}

export default HomeOnerilenKurslar


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px 80px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:860px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }

    #onerilen_kurslarimiz_header_p1 {
        font-size: 36px;
        font-weight: bold;

        @media screen and (max-width:500px) {
            font-size: 26px;
            text-align: center;
        }

        span {
            color: #7F56D9;
            margin-left: 20px;
            font-weight: bold;
        }
    }
`