
import styled from 'styled-components'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal, ModalFooter, ModalBody, ModalHeader, Input, Table, Label, Toast } from "reactstrap"
import { toast } from 'react-toastify'
import { uniqueId } from 'lodash'
import { IoIosClose } from 'react-icons/io'
import { v4 as uuid } from "uuid"
import { createExamAnswerApi, createExamQuestionApi } from '../../../api/exam'
import { useParams } from 'react-router-dom'
import { Button as AntButton } from "antd"
const UpdateSectionCreateQuestionModal = ({ refreshRequest, modalShow, setModalShow, examType }) => {
    const { id } = useParams()
    const fileInputRef = useRef(null);
    const [modalQuestion, setModalQuestion] = useState("")
    const [questionPoint, setQuestionPoint] = useState(0)
    const [editMode, setEditMode] = useState({
        id: 0,
        active: false
    })
    const [modalChoiceList, setModalChoiceList] = useState([{
        "content": "",
        "id": 984,
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    },
    {
        "content": "",
        "id": uniqueId(),
        "correct": false,
    }])
    const [previewSrc, setPreviewSrc] = useState(null);



    function rakamToHarf(rakam) {
        const asciiOffset = 65;
        const harf = String.fromCharCode(asciiOffset + parseInt(rakam) - 1);
        return harf;
    }

    const examTypeControl = useMemo(() => {
        return examType == "Test"
    }, [examType])



    const questionValidation = () => {
        for (let item of modalChoiceList) {
            if (item.content == "") {
                toast.error("Cevap Şıkkının içeriği boş bırakılamaz", {
                    autoClose: 1500
                })
                return false;
            }
        }
        if (modalChoiceList.length < 2) {
            toast.error("En az 2 adet cevap şıkkı olmalı", {
                autoClose: 1500
            })
            return false;
        }
        const checkControl = modalChoiceList.some(x => x.correct)
        if (!checkControl) {
            toast.error("En az bir adet doğru cevap seçiniz", {
                autoClose: 1500
            })
            return false
        }

        return true
    }

    const resetState = () => {
        setModalQuestion("")
        setModalChoiceList([{
            "content": "",
            "id": 984,
            "correct": false,
        },
        {
            "content": "",
            "id": uniqueId(),
            "correct": false,
        },
        {
            "content": "",
            "id": uniqueId(),
            "correct": false,
        },
        {
            "content": "",
            "id": uniqueId(),
            "correct": false,
        },
        {
            "content": "",
            "id": uniqueId(),
            "correct": false,
        }])
        setQuestionPoint(0)
        setModalShow(false)
        setModalQuestion("")

        setQuestionPoint(0)
        setPreviewSrc(null)
        setModalShow(false)
    }



    const addQuestion = async () => {
    
        if (modalQuestion == "") {
            toast.error("Soru boş bırakılamaz", {
                autoClose: 1500
            })
        }
        else if (questionPoint < 1 || questionPoint > 100) {
            toast.error("Puan 0 ile 100 arasında olmalı", {
                autoClose: 1500
            })
        }
        else {
            if (examTypeControl) {
                let validControl = questionValidation()
                if (validControl) {
                   

                    const formData = new FormData()
                    if (previewSrc) {
                        formData.append("formFile", previewSrc)
                    }
                    try {
                        const response = await createExamQuestionApi(formData, id, questionPoint, modalQuestion)

                        for (let [index, element] of modalChoiceList.entries()) {
                            await createExamAnswerApi({
                                "answer": element.content,
                                "option": rakamToHarf(index + 1),
                                "examQuestionId": response.data.id,
                                "status": element.correct
                            });
                        }
                        toast.success("Soru eklendi", {
                            autoClose: 2500
                        })

                        await refreshRequest()
                        resetState()

                    }
                    catch (err) {



                    }
                }
            }
            else {
                const formData = new FormData()
                if (previewSrc) {
                    formData.append("formFile", previewSrc)
                }
                const response = await createExamQuestionApi(formData, id, questionPoint, modalQuestion)
                toast.success("Soru eklendi", {
                    autoClose: 2500
                })

                await refreshRequest()
                resetState()
            }


        }
    }

    const StyledModalHeader = styled(ModalHeader)`
        display: flex;
        align-items: center;
        h5{
            flex-grow: 1;
            display: flex;
        }
    `

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file.type;
            if (fileType.startsWith('image/')) {
                setPreviewSrc(file);
            } else {
                setPreviewSrc(null);
                toast.error("Lütfen fotoğraf seçiniz", {
                    autoClose: 1500
                })
            }
        }
        event.target.value = null
    };

    return (
        <>
            <Modal size='lg' isOpen={modalShow} >
                <StyledModalHeader  >
                    <AddQuestionModalHeader  >
                        <p>
                            {editMode.active ? "Soruyu Güncelle" : "Soru Ekle"}
                        </p>
                        <Button size='sm' onClick={() => {
                            fileInputRef.current.click()
                        }}  >
                            Fotoğraf Ekle
                        </Button>
                    </AddQuestionModalHeader>
                </StyledModalHeader>
                <ModalBody>
                    <AddQuestionModalBody>
                        <StyledAddQuestionModalContentContainer file={previewSrc}  >
                            <Input value={modalQuestion} type='textarea' style={{ resize: "none" }} rows={4} cols={10} placeholder='Soru İçeriği' onChange={(e) => {
                                setModalQuestion(e.target.value)
                            }} />
                            {previewSrc && (
                                <div className='remove_img_wrapper'  >
                                    <img src={URL.createObjectURL(previewSrc)} alt="Seçilen Fotoğraf" style={{ maxWidth: '100%', width: "100%", objectFit: "cover", objectPosition: "top", height: '100%', maxHeight: "100%", borderRadius: 10 }} />
                                    <div className='remove_img_close_icon_container' onClick={() => {
                                        setPreviewSrc(null)
                                    }} >
                                        <IoIosClose className='remove_img_close_icon' />
                                    </div>

                                </div>
                            )}
                        </StyledAddQuestionModalContentContainer>
                        <div className='question_type_section' >
                            <div style={{ display: "flex", alignItems: "center" }} >
                                <strong style={{ marginRight: "10px" }} >
                                    Puan
                                </strong>
                                <Input type='number' value={questionPoint} className='question_point' onChange={(e) => {
                                    setQuestionPoint(e.target.value)
                                }} />
                            </div>
                        </div>
                        {
                            examTypeControl ? (
                                <div className='add_question_model_choice_list'>
                                    {
                                        modalChoiceList?.map((el, index) => {
                                            return (
                                                <div className='add_question_modal_choice' >
                                                    <div className='add_question_choice_content' >
                                                        <p className='choice_letter' >
                                                            {rakamToHarf(index + 1)}
                                                        </p>
                                                        <Input value={el.content} className='choice_icerik' placeholder='Soru' onChange={(e) => {
                                                            setModalChoiceList(modalChoiceList?.map(x => {
                                                                if (x.id == el.id) {
                                                                    return {
                                                                        ...x,
                                                                        content: e.target.value
                                                                    }
                                                                }
                                                                return x
                                                            }))

                                                        }} />
                                                    </div>
                                                    <div className='add_question_choice_process'>
                                                        <Input checked={el.correct} type='checkbox' className='choice_check' onChange={(e) => {
                                                            setModalChoiceList(modalChoiceList?.map(x => {
                                                                if (x.id == el.id) {
                                                                    return {
                                                                        ...x,
                                                                        correct: e.target.checked
                                                                    }
                                                                }
                                                                else {
                                                                    return {
                                                                        ...x,
                                                                        correct: false
                                                                    }
                                                                }

                                                            }))
                                                        }} />

                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            ) : (
                                <div style={{ marginTop: 20 }}>
                                    <Input placeholder='Bu sınav yazılı bir sınavdır' disabled />
                                </div>
                            )
                        }

                    </AddQuestionModalBody>
                </ModalBody>
                <ModalFooter>
                    <AntButton type='primary' style={{ marginRight: "5px" }} onClick={addQuestion} disabled={modalChoiceList?.length == 0} >

                        Kaydet

                    </AntButton>
                    <AntButton danger type='primary' onClick={() => {
                        setModalShow(false)

                        setModalChoiceList([{
                            "content": "",
                            "id": 984,
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        },
                        {
                            "content": "",
                            "id": uniqueId(),
                            "correct": false,
                        }])
                        setQuestionPoint(0)

                        setEditMode({
                            active: false,
                            id: 0
                        })
                        setModalQuestion("")

                        setPreviewSrc(null)

                    }} >
                        İptal
                    </AntButton>


                </ModalFooter>
            </Modal>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>


    )
}



const StyledAddQuestionModalContentContainer = styled.div`
display: grid;
column-gap: 20px;
grid-template-columns: ${props => props.file == null ? "1fr" : "4fr 2fr"};
margin: 10px 0;
.remove_img_wrapper {
    position: relative;
    max-height: 150px;
    .remove_img_close_icon_container {
        position: absolute;
        top: -7%;
        right: -2%;
        background-color: red;
        cursor: pointer;
        z-index: 1000;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        .remove_img_close_icon {
            color: white;
            font-size: 25px;
        }
    }

}
`

const AddQuestionModalHeader = styled.div`
  display: flex;
    justify-content: space-between;
    width: 100%;
`

const AddQuestionModalBody = styled.div`
    display: flex;
    flex-direction: column;

    .question_type_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;

        .question_point {
            width: 100px;
            
        }

        .question_time {
            width: 100px;
        }

        .select_question_type {
            width: 120px;
        }

        .add_question_btn {
            align-self: flex-end;

        }

    }

    .add_question_model_choice_list {
        margin-top: 20px;


        .add_question_modal_choice {
            display: grid;
            align-items: center;
            grid-template-columns: 17fr 1fr;
            margin-bottom: 10px;
            width: 100%;
            column-gap: 10px;
            .add_question_choice_content {
                display: flex;
                align-items: center;
                column-gap: 5px;
                .choice_icerik {
                    resize: none;
                    margin-left: 0;
                }

                .choice_letter {
                    
                    background-color: #3577F1;
                    border-radius: 50%;
                    padding: 5px 9px;
                    color: white;


                }

            }

            .add_question_choice_process {
                display: flex;
                align-items: center;
              

                .choice_check {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                   /*  margin-right: 10px;
                    margin-left: 10px; */

                    @media screen and (max-width:600px) {
                        margin-right: 0px;
                        margin-left: 0px;
                }
                }


                .choice_delete {
                    background-color: red;
                    color: white;
                    font-size: 27px;
                    padding: 5px;
                    border-radius: 50%;
                    transition: .5s all;
                    cursor: pointer;

                    &:hover {
                        opacity: .5;
                    }
                }
            }

        }
    }

`

export default UpdateSectionCreateQuestionModal