import React from 'react'
import TablePageContainer from '../../../Components/Common/TablePageContainer'
import StudentExamResultDashboard from '../../../Components/StudentExamResult/StudentExamResultDashboard'
import styled from 'styled-components'

const ExamStudenResultPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Sınav sonuçları"}  >
                <StyledContainer>
                    <StudentExamResultDashboard />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:600px)  {
        padding: 5px;
    }
`

export default ExamStudenResultPage