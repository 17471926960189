import React, { useEffect, useMemo, useState } from 'react'
import { ExamGivePointContentContainer, ExamGivePointHeaderContainer, ExamGivePointLoadingWrapper, ExamGivePointQuestionContainer, ExamGivePointSearchStudent, ExamGivePointStyledContainer, ExamGiveQuestionItem, SelectedOgrName, StyledGiveExamPointDataTableContainer } from './examGivePointStyle'
import { getExamListApi } from '../../../api/exam'
import { Pagination, Select, Skeleton, Button as AntButton } from 'antd'
import { useParams } from 'react-router-dom'
import { Button, Card, Input } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { BsArrowDown } from 'react-icons/bs'
import ExamGivePointModal from './ExamGivePointModal'

const ExamGivePoint = () => {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const [mainData, setMainData] = useState()
    const [selectedStudent, setSelectedStudent] = useState()
    const [questionIndex, setQuestionIndex] = useState(0)
    const [modalData, setModalData] = useState({
        visible: false,
        data: null
    })
    const getExamListRequest = async () => {
        try {
            setLoading(true)
            const responsev1 = await getExamListApi({
                "page": 0,
                "pageSize": 6000
            }, "Yazılı")
            const selectedElement = responsev1.data.items.find(el => el.id == id)
          
            setMainData(selectedElement)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const removeDuplicates = (data) => {
        const uniqueIds = new Set();
        return data.filter(item => {
            if (uniqueIds.has(item.id)) {
                return false;
            } else {
                uniqueIds.add(item.id);
                return true;
            }
        });
    };

    const ogrList = useMemo(() => {
        const ogrArr = []
        if (mainData) {
            mainData.examQuestions.map(el => {
                el.examWrittenAnswers.map(item => {

                    ogrArr.push({
                        id: item?.user?.id,
                        firstName: item?.user?.firstName,
                        lastName: item?.user?.lastName,
                        phone: item?.user?.phone
                    })
                })
            })
        }

        let ogrenciListe = removeDuplicates(ogrArr)
        return ogrenciListe
    }, [mainData])





    const Columns = [
        {
            "name": "İsim",
            selector: (row) => {
                return (
                    <p>
                        {row.firstName}
                    </p>
                )
            }
        },
        {
            "name": "Soyisim",
            selector: (row) => {
                return (
                    <p>
                        {row.lastName}
                    </p>
                )
            }
        },
        {
            "name": "Telefon",
            selector: (row) => {
                return (
                    <p>
                        {row.phone}
                    </p>
                )
            }
        },
        {
            "name": "İşlem",
            selector: (row) => {
                return (
                    <AntButton  type="primary" onClick={() => {
                       
                        let questList = mainData?.examQuestions
                     
                        const list1 = questList.map(el => {
                            const { examWrittenAnswers, ...rest } = el
                            const examAnswer = examWrittenAnswers.find(el => el.user.id == row.id)
                            return {
                                ...rest,
                                examAnswer: examAnswer
                            }
                        })
                     
                        setModalData({
                            visible: true,
                            data: {
                                answers: list1,
                                student: row
                            }
                        })
                    }} >
                        Puanla
                    </AntButton>
                )
            }
        }
    ]




    useEffect(() => {
        getExamListRequest()

    }, [])




    if (loading) {
        return (
            <ExamGivePointLoadingWrapper>
                <Skeleton active={true} />
            </ExamGivePointLoadingWrapper>
        )

    }


    return (
        <>
            <StyledGiveExamPointDataTableContainer>
                <DataTable
                    data={ogrList}
                    columns={Columns}
                    pagination
                    sortIcon={<BsArrowDown />}
                    noDataComponent={
                        <Card className="w-100 p-5" style={{ marginRight: 0 }}>
                            <center>
                                <h2>Herhangi bir veri bulunamadı.</h2>
                            </center>
                        </Card>
                    }
                    paginationComponentOptions={{
                        rowsPerPageText: "Satır Sayısı",
                        rangeSeparatorText: "-",
                        noRowsPerPage: true
                    }}
                    /*  progressPending={loading} */
                    customStyles={{
                        headCells: {
                            style: {

                                backgroundColor: "rgba(128, 128, 128, 0)"
                            }
                        }
                    }}
                />

            </StyledGiveExamPointDataTableContainer>
            <ExamGivePointModal modalData={modalData} setModalData={setModalData} refreshData={getExamListRequest} />


            {/* <ExamGivePointStyledContainer>
            <ExamGivePointHeaderContainer>
                <ExamGivePointSearchStudent
                    value={selectedStudent}
                    showSearch
                    placeholder="Öğrenci seçiniz"
                    optionFilterProp="children"
                    onChange={onChange}
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={ogrList.map(el => {
                        return {
                            "label": `${el.firstName} ${el.lastName} `,
                            "value": el.id
                        }
                    })}
                />
                <SelectedOgrName>
                    {selectedStudentName}
                </SelectedOgrName>
            </ExamGivePointHeaderContainer>
            <ExamGivePointContentContainer>
                {
                    selectedStudent ? (
                        <ExamGivePointQuestionContainer>
                            {
                                selectedQuestion?.map(el => {
                                    return (
                                        <ExamGiveQuestionItem>

                                            <Input type='textarea' value={el?.question} disabled style={{ resize: "none" }} />
                                            <Input type='textarea' value={el?.examAnswer?.answer} disabled style={{ resize: "none" }} />

                                        </ExamGiveQuestionItem>

                                    )
                                })
                            }
                           
                        </ExamGivePointQuestionContainer>

                    ) : (
                        <div style={{ marginTop: 20 }}>
                            <Skeleton active={true} />
                        </div>

                    )
                }

            </ExamGivePointContentContainer>
        </ExamGivePointStyledContainer> */}
        </>

    )
}

export default ExamGivePoint