import React, { useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import UpdateStudentForm from '../../Components/Student/EditStudent/UpdateStudentForm'
import styled from 'styled-components'

const UpdateStudentPage = () => {

    const [title, setTitle] = useState()
    return (
        <div className='page-content'>
            <TablePageContainer title={title} header={title} visible={false}  >
                <StyledContainer>
                    <UpdateStudentForm setTitle={setTitle} />

                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:600px)  {
        padding: 5px;
    }
`

export default UpdateStudentPage