import React, { useEffect, useMemo, useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import TeacherMessageDashboard from '../../Components/Teacher/TeacherMessageDashboard'
import { GetCalendarForAllRoles } from '../../api/Course'

const TeacherMessagePage = () => {
    const [userData, setUserData] = useState()

    const getUserCourse = async () => {
        try {
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 100
            })
 
            setUserData(response.data.items)
        }
        catch (err) {
        }
        finally {

        }
    }

    useEffect(() => {
        getUserCourse()
    }, [])


    return (
        <div className='page-content'>
            <TablePageContainer title='Eğitmen Mesaj' header={"Mesajlar"}  visible={false}>
                <TeacherMessageDashboard   courseDataList={userData} />
            </TablePageContainer>
        </div>

    )
}

export default TeacherMessagePage