import { useFormik } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import * as yup from "yup"
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllBranch } from '../../../../api/Branch';
import { getDetailCourseApi, updateCourseApi } from '../../../../api/Course';
import { getTeacherListApi } from '../../../../api/Teacher';
import { getAllSemesterApi } from '../../../../api/SemesterApi';
import { getAllCategories } from '../../../../api/Category';
import { DatePicker, Select, Button as AntButton, Modal as AntModal, Switch, Modal } from 'antd';
import moment from 'moment';
import dayjs from "dayjs"
import styled from 'styled-components';
import { axiosInstance } from '../../../../api/axiosInstance';
import { PropagateLoader } from 'react-spinners';

const EditCourseInfo = ({ data }) => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [branchList, setBranchList] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [courseType, setCourseType] = useState("Offline")
    const [categoryList, setCategoryList] = useState([])
    const [courseStatus, setCourseStatus] = useState(0)
    const navigate = useNavigate()

    let validObject = {}

    if (courseType == "Online") {
        validObject = {
            description: yup.string().required(),
            semester: yup.string().required(),
            type: yup.string().required(),
            prelim: yup.string().required(),
            startYear: yup.number()
                .required("Seçim yapınız")
                .min(1900, "Seçim yapınız")
                .test('start-year', 'Başlangıç yılı bitiş yılından küçük olmalıdır', function (value) {
                    const endYear = this.parent.endYear;
                    return !endYear || value <= endYear;
                }),
            endYear: yup.number()
                .required("Seçim yapınız")
                .min(1900, "Seçim yapınız")
                .test('end-year', 'Bitiş yılı başlangıç yılından büyük olmalıdır', function (value) {
                    const startYear = this.parent.startYear;
                    return !startYear || value >= startYear;
                }),

            limit: yup.number().min(1).max(50).required(),
            genderType: yup.string().required(),
            categoryId: yup.string().required(),
            branchId: yup.string().required(),
            discontinuity: yup.number().min(1).max(100).required(),
            previewStatus: yup.string().required(),
            startDate: yup
                .date()
                .required()
                .test('start-date', 'Başlangıç tarihi bitiş tarihinden önce olmalıdır', function (value) {
                    const endDate = this.parent.endDate;  // Değişiklik burada
                    return !endDate || value < endDate;
                }),
            endDate: yup
                .date()
                .required()
                .test('end-date', 'Bitiş tarihi başlangıç tarihinden sonra olmalıdır', function (value) {
                    const startDate = this.parent.startDate;  // Değişiklik burada
                    return !startDate || value > startDate;
                }).test("end-date", "Günümüzden ileri bir tarih olmalı", function (value) {
                    return new Date() < value
                }),
            registerTime: yup.date()
                .required()
                .test("register-time", "Kayıt zamanı başlangıç tarihinden önce olmalıdır", function (value) {
                    const startDate = this.parent.startDate;
                    return !startDate || new Date(value) < new Date(startDate);
                }),
            prelim: yup.string().required(),
        }
    }
    else {
        validObject = {
            description: yup.string().required(),
            categoryId: yup.string().required(),
            branchId: yup.string().required(),
            previewStatus: yup.string().required(),
        }

    }

    const formik = useFormik({
        initialValues: {
            description: "",
            limit: 0,
            startYear: 0,
            endYear: 0,
            type: "",
            semester: "",
            genderType: "",
            discontinuity: 0,
            previewStatus: "",
            categoryId: "",
            registerDeleteStatus: true,
            startDate: "",
            endDate: "",
            registerTime: "",
            registerStartTime: "",
            prelim: false,
            branchId: "",
            totalTime: ""
        },
        validationSchema: yup.object(validObject),
        onSubmit: async (value, { resetForm }) => {

            Modal.confirm({
                title: "Güncellemek istediğinize emin misiniz?",
                cancelText: "İptal",
                okText: "Eminim",
                onOk: async () => {
                    try {

                        const { prelim, limit, semester, branchId, type, genderType, totalTime, categoryId, endYear, startDate, endDate, startYear, registerTime, registerStartTime, ...rest } = value

                        const body = {
                            id: id,
                            prelim: prelim,
                            branchId: branchId,
                            genderType: courseType == "Online" ? genderType.charAt(0).toUpperCase() + genderType.substring(1) : "Hepsi",
                            startYear: courseType == "Online" ? startYear : 1901,
                            endYear: courseType == "Online" ? endYear : 1902,
                            startDate: courseType == "Online" ? new Date(startDate).toUTCString() : new Date("2024-06-28T11:00:00Z"),
                            endDate: courseType == "Online" ? new Date(endDate).toUTCString() : new Date("2024-06-30T11:00:00Z"),
                            registerTime: courseType == "Online" ? new Date(registerTime).toUTCString() : new Date("2024-06-27T11:00:00Z"),
                            registerStartTime: courseType == "Online" ? new Date(registerStartTime).toUTCString() : new Date("2024-06-26T11:00:00Z"),
                            categoryId: parseInt(categoryId),
                            registrationLowerLimit: 1,
                            totalTime: totalTime,
                            type: courseType,
                            semester: semester,
                            limit: courseType == "Online" ? limit : 1,
                            ...rest
                        }

                        const response = await updateCourseApi(body)
                        toast.success("Kurs Güncellendi", {
                            autoClose: 2000,
                            position: "top-center"
                        })
                    }
                    catch (err) {

                    }
                }
            })




        },

    })

    const detailCourseApiRequest = async () => {
        try {
            setLoading(true)
            const response = await getDetailCourseApi(id)
            const data = response.data
            setCourseType(data.type)
            if (data.type == "Online") {
                formik.setFieldValue("branchId", data.branchId)
                formik.setFieldValue("limit", data.limit)
                formik.setFieldValue("description", data.description)
                formik.setFieldValue("semester", data.semester)
                formik.setFieldValue("startYear", data.startYear)
                formik.setFieldValue("endYear", data.endYear)
                formik.setFieldValue("type", data.type)
                formik.setFieldValue("genderType", data.genderType)
                formik.setFieldValue("discontinuity", data.discontinuity)
                formik.setFieldValue("previewStatus", data.previewStatus)
                formik.setFieldValue("categoryId", data.categoryId)
                formik.setFieldValue("registerDeleteStatus", data.registerDeleteStatus)
                formik.setFieldValue("startDate", dayjs(data.startDate))
                formik.setFieldValue("endDate", dayjs(data.endDate))
                formik.setFieldValue("registerTime", dayjs(data.registerTime))
                formik.setFieldValue("registerStartTime", dayjs(data.registerStartTime))
                formik.setFieldValue("prelim", data.prelim)
                formik.setFieldValue("totalTime", data.totalTime)
            }
            else {
                formik.setFieldValue("branchId", data.branchId)
                formik.setFieldValue("description", data.description)
                formik.setFieldValue("categoryId", data.categoryId)
                formik.setFieldValue("totalTime", data.totalTime)
                formik.setFieldValue("previewStatus", data.previewStatus)
                formik.setFieldValue("semester", data.semester)
            }
            setCourseStatus(data.status)
        }
        catch (err) {
            console.log("error ", err)
        }
        finally {
            setLoading(false)
        }
    }

    const getCategoryList = async () => {
        try {
            const response = await getAllCategories({
                page: 0,
                pageSize: 500
            })
            setCategoryList(response.data.items)
        }
        catch (err) {

        }
    }



    const getBranchesList = async () => {
        try {
            const response = await getAllBranch({
                page: 0,
                pageSize: 2000
            })
            setBranchList(response.data?.items)

        }
        catch (err) {

        }
    }


    useEffect(() => {
        detailCourseApiRequest()
        getBranchesList()
        getCategoryList()
    }, [data])

    const semesterList = useMemo(() => {
        return ["Güz", "Bahar", "Yaz"]
    })


    const years = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1901;
        const yearArray = [];
        for (let year = currentYear - 15; year >= startYear; year--) {
            yearArray.push(year);
        }
        return yearArray;
    }, []);


    if (loading) {
        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "70px 0"
            }} >
                <PropagateLoader color='#088F7D' />
            </div>

        )
    }

    if (courseType == "Offline") {
        return (
            <Form onSubmit={formik.handleSubmit} >
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <div className="mb-3">
                                <Label className="form-label">
                                    Branş
                                </Label>
                                <StyledSelect
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        formik.setFieldValue("branchId", e)
                                    }}
                                    value={formik.values.branchId}

                                    options={[
                                        {
                                            "label": "Seçim Yapınız",
                                            "value": ""
                                        },
                                        ...branchList.map(el => {
                                            return {
                                                "label": el.name,
                                                "value": el.id
                                            }
                                        })
                                    ]}
                                />
                                {formik.touched.branchId && formik.errors.branchId ? (
                                    <StyledErrorMessage  >{formik.errors.branchId}</StyledErrorMessage>
                                ) : null}
                            </div>
                        </div>
                    </Col>



                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">
                                Kategori
                            </Label>
                            <StyledSelect
                                name="categoryId"
                                id="categoryId"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue("categoryId", e)
                                }}
                                value={formik.values.categoryId}

                                options={[
                                    {
                                        "label": "Seçim Yapınız",
                                        "value": ""
                                    },
                                    ...categoryList.map(el => {
                                        return {
                                            "label": el.name,
                                            "value": el.id
                                        }
                                    })
                                ]}
                            />
                            {formik.touched.categoryId && formik.errors.categoryId ? (
                                <StyledErrorMessage  >{formik.errors.categoryId}</StyledErrorMessage>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label className="form-label">
                                Toplam Süre
                            </Label>
                            <StyledInput
                                value={formik.values.totalTime}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                placeholder='Toplam Süre'
                                name='totalTime'
                                invalid={formik.touched.totalTime && formik.errors.totalTime ? true : false}
                                type='number' />
                            {formik.touched.totalTime && formik.errors.totalTime ? (
                                <FormFeedback type="invalid"><div>{formik.errors.totalTime}</div></FormFeedback>
                            ) : null}

                        </div>
                    </Col>

                    <Col lg={6} >
                        <div className="mb-3">
                            <Label className="form-label">
                                Kayıt Esnasında Görünsün mü ?
                            </Label>
                            <StyledSelect onChange={(e) => {
                                formik.setFieldValue("previewStatus", e)
                            }} value={formik.values.previewStatus} onBlur={formik.handleBlur} >
                                <option value="">
                                    Seçim Yapınız
                                </option>
                                {
                                    [{ "label": "Evet", value: true }, { "label": "Hayır", value: false }].map((el, index) => {
                                        return (
                                            <option value={el.value} key={`${index}`} >
                                                {el.label}
                                            </option>
                                        )
                                    })
                                }
                            </StyledSelect>
                            {formik.touched.previewStatus && formik.errors.previewStatus ? (
                                <StyledErrorMessage><div>{formik.errors.previewStatus}</div></StyledErrorMessage>
                            ) : null}
                        </div>
                    </Col>

                    <Col lg={10}>
                        <div className="mb-3">
                            <Label className="form-label">
                                Açıklama
                            </Label>
                            <Input value={formik.values.description}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.description && formik.errors.description ? true : false}
                                onChange={formik.handleChange}

                                className="form-control"
                                name='description' id='description' type='textarea'
                                rows={5} style={{ resize: "none" }} />

                            {formik.touched.description && formik.errors.description ? (
                                <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
                            <AntButton type='primary' style={{ padding: "0px 30px", textAlign: "center" }} onClick={() => {
                                formik.handleSubmit()
                            }}  >
                                Güncelle
                            </AntButton>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }


    return (
        <Form onSubmit={formik.handleSubmit} >
            <Row>
                <Col lg={4}>
                    <div className="mb-3">
                        <div className="mb-3">
                            <Label className="form-label">
                                Branş
                            </Label>
                            <StyledSelect
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue("branchId", e)
                                }}
                                value={formik.values.branchId}

                                options={[
                                    {
                                        "label": "Seçim Yapınız",
                                        "value": ""
                                    },
                                    ...branchList.map(el => {
                                        return {
                                            "label": el.name,
                                            "value": el.id
                                        }
                                    })
                                ]}
                            />
                            {formik.touched.branchId && formik.errors.branchId ? (
                                <StyledErrorMessage  >{formik.errors.branchId}</StyledErrorMessage>
                            ) : null}
                        </div>
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kontenjan
                        </Label>
                        <StyledInput

                            value={formik.values.limit}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder='Kontenjan'
                            name='limit'
                            invalid={formik.touched.limit && formik.errors.limit ? true : false}
                            type='number' />
                        {formik.touched.limit && formik.errors.limit ? (
                            <FormFeedback type="invalid"><div>{formik.errors.limit}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>



                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Dönem
                        </Label>
                        <StyledSelect
                            value={formik.values.semester}
                            onChange={(e) => {
                                formik.setFieldValue("semester", e)
                            }} >
                            <option value="">
                                Dönem Seçiniz
                            </option>
                            {
                                semesterList.map((item, index) => {
                                    return (
                                        <option key={`${index}`} value={item}>
                                            {item}
                                        </option>
                                    )
                                })
                            }
                        </StyledSelect>
                        {formik.touched.semester && formik.errors.semester ? (
                            <StyledErrorMessage >{formik.errors.semester}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>



                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kategori
                        </Label>
                        <StyledSelect
                            name="categoryId"
                            id="categoryId"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                                formik.setFieldValue("categoryId", e)
                            }}
                            value={formik.values.categoryId}

                            options={[
                                {
                                    "label": "Seçim Yapınız",
                                    "value": ""
                                },
                                ...categoryList.map(el => {
                                    return {
                                        "label": el.name,
                                        "value": el.id
                                    }
                                })
                            ]}
                        />
                        {formik.touched.categoryId && formik.errors.categoryId ? (
                            <StyledErrorMessage  >{formik.errors.categoryId}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4} >
                    <div className="mb-3">
                        <Label className="form-label">
                            Devamsızlık (%)
                        </Label>
                        <StyledInput
                            style={{ marginLeft: 0 }}
                            value={formik.values.discontinuity}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder='Kontenjan'
                            name='discontinuity'
                            invalid={formik.touched.discontinuity && formik.errors.discontinuity ? true : false}
                            type='number' />
                        {formik.touched.discontinuity && formik.errors.discontinuity ? (
                            <FormFeedback type="invalid"><div>{formik.errors.discontinuity}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4} >
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt Esnasında Görünsün mü ?
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("previewStatus", e)
                        }} value={formik.values.previewStatus} onBlur={formik.handleBlur} >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                [{ "label": "Evet", value: true }, { "label": "Hayır", value: false }].map((el, index) => {
                                    return (
                                        <option value={el.value} key={`${index}`} >
                                            {el.label}
                                        </option>
                                    )
                                })
                            }
                        </StyledSelect>
                        {formik.touched.previewStatus && formik.errors.previewStatus ? (
                            <StyledErrorMessage><div>{formik.errors.previewStatus}</div></StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>



                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Ön Sınav Olsun mu?
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("prelim", e)
                        }} value={formik.values.prelim} onBlur={formik.handleBlur} >
                            {
                                [{
                                    "label": "Evet",
                                    "value": true
                                }, {
                                    "label": "Hayır",
                                    "value": false
                                },
                                ].map((el, index) => {
                                    return (
                                        <option value={el.value} key={`${index}`} >
                                            {el.label}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.prelim && formik.errors.prelim ? (
                            <FormFeedback type="invalid"><div>{formik.errors.prelim}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>




                <Col lg={4} >
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Başlangıç Tarihi
                        </Label>

                        <div>
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                allowClear={false}
                                value={formik.values.startDate}
                                onChange={(date, dateString) => {
                                    formik.setFieldValue("startDate", date)
                                }}

                            />
                        </div>
                        {formik.touched.startDate && formik.errors.startDate ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.startDate}</div>

                        ) : null}
                    </div>
                </Col>




                <Col lg={4} >
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Bitiş Tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                allowClear={false}
                                value={formik.values.endDate}
                                onChange={(date, dateString) => {
                                    formik.setFieldValue("endDate", date)
                                }}

                            />
                        </div>
                        {/*  <Input
                             
                                name="endDate"
                                type="date"
                                placeholde1r="Bitiş Tarihi"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.endDate}
                                invalid={
                                    formik.touched.endDate && formik.errors.endDate ? true : false
                                }
                            /> */}
                        {formik.touched.endDate && formik.errors.endDate ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.endDate}</div>
                        ) : null}
                    </div>
                </Col>


                <Col lg={4} >
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Kayıt Başlangıç Tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                allowClear={false}
                                value={formik.values.registerStartTime}
                                onChange={(date, dateString) => {
                                    formik.setFieldValue("registerStartTime", date)
                                }}

                            />
                        </div>

                        {formik.touched.registerStartTime && formik.errors.registerStartTime ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.registerStartTime}</div>
                        ) : null}
                    </div>
                </Col>


                <Col lg={4} >
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Kayıt Tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{ width: "100%" }}
                                showTime
                                allowClear={false}
                                value={formik.values.registerTime}
                                onChange={(date, dateString) => {
                                    formik.setFieldValue("registerTime", date)
                                }}

                            />
                        </div>

                        {formik.touched.registerTime && formik.errors.registerTime ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.registerTime}</div>
                        ) : null}
                    </div>
                </Col>



                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Yaş Şartı (Başlangıç Yılı)
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("startYear", e)
                        }} value={formik.values.startYear}  >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                years.map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.startYear && formik.errors.startYear ? (
                            <StyledErrorMessage>{formik.errors.startYear}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>



                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Yaş Şartı (Bitiş Yılı)
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("endYear", e)
                        }} value={formik.values.endYear} >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                years.map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.endYear && formik.errors.endYear ? (
                            <FormFeedback type="invalid"><div>{formik.errors.endYear}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>


                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Cinsiyet
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("genderType", e)
                        }} value={formik.values.genderType}  >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                [{
                                    "label": "Erkek",
                                    "value": "Erkek"
                                }, {
                                    "label": "Kadın",
                                    "value": "Kadın"
                                },
                                {
                                    "label": "Hepsi",
                                    "value": "Hepsi"
                                }
                                ].map((el, index) => {
                                    return (
                                        <option value={el.value} key={`${index}`} >
                                            {el.label}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.genderType && formik.errors.genderType ? (
                            <StyledErrorMessage>{formik.errors.genderType}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>

                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt silme aktiflik durumu
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("registerDeleteStatus", e)
                        }} value={formik.values.registerDeleteStatus}  >
                            {
                                [

                                    {
                                        "label": "Evet",
                                        "value": true
                                    }, {
                                        "label": "Hayır",
                                        "value": false
                                    },
                                ].map((el, index) => {
                                    return (
                                        <option value={el.value} key={`${index}`} >
                                            {el.label}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.registerDeleteStatus && formik.errors.registerDeleteStatus ? (
                            <StyledErrorMessage>{formik.errors.registerDeleteStatus}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>

                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kurs Durumu
                        </Label>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }} >
                            <Select
                                style={{
                                    width: "100%"
                                }}
                                value={courseStatus}
                                onChange={(e) => {
                                    AntModal.confirm({
                                        okText: "eminim",
                                        title: "Kurs Durum Değişikliği",
                                        content: e == 0 ? (
                                            <p>
                                                Kursu aktif hale getirmek istediğinize emin misiniz?
                                            </p>
                                        ) : (
                                            <p style={{ color: "red" }} >
                                                Kursu pasif hale getirmek istediğinize emin misiniz ? Pasif hale getirilen kurslar tekrardan aktif edilemez.
                                            </p>
                                        ),

                                        cancelText: "iptal",
                                        onOk: async () => {
                                            try {
                                                const response = await axiosInstance.put("/courses/UpdateStatus", {
                                                    id: id,
                                                    status: e
                                                })
                                                toast.success("Güncelleme başarılı", {
                                                    autoClose: 2000,
                                                    position: "top-center"
                                                })

                                                detailCourseApiRequest()
                                            }
                                            catch (err) {

                                            }
                                        }
                                    })

                                }}
                                options={[
                                    {
                                        label: "Aktif",
                                        value: 0
                                    },
                                    {
                                        label: "Pasif",
                                        value: 2
                                    }
                                ]}

                            />

                        </div>

                    </div>
                </Col>




                <Col lg={10}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Açıklama
                        </Label>
                        <Input value={formik.values.description}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.description && formik.errors.description ? true : false}
                            onChange={formik.handleChange}

                            className="form-control"
                            name='description' id='description' type='textarea'
                            rows={5} style={{ resize: "none" }} />

                        {formik.touched.description && formik.errors.description ? (
                            <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={2}>
                    <div style={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "30px" }}>
                        <AntButton type='primary' style={{ padding: "0px 30px", textAlign: "center" }} onClick={() => {
                            formik.handleSubmit()
                        }}  >
                            Güncelle
                        </AntButton>
                    </div>
                </Col>

            </Row>

        </Form >
    )
}

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;
    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`
const StyledInput = styled(Input)`
    height: 35px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`

export default EditCourseInfo