import React, { useContext, useEffect, useMemo, useState } from 'react'
import { DynamicCourseSearch, getAllCourseByStatusApi } from '../../../api/Course'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../context/user'
import art from "../../../assets/images/img1.png"
import person from "../../../assets/images/person-image-card.png"
import line from "../../../assets/images/line-green.png"
import { FaFilter, FaUsers } from "react-icons/fa";
import { Pagination, Input as AntdInput, Select, Button, Drawer } from 'antd'
import { PropagateLoader } from 'react-spinners'
import styled from 'styled-components'
import "./ActiveCourseTable.scss"
import { ActiveCourseDashboardWrapper, AddCourseBtn, StyledAntSelect, StyledFilterBtn, StyledHeaderContainer, StyledHeaderFilter, StyledInput, StyledMainCourseContainer } from './ActiveCourseStyle'
import { Label } from 'reactstrap'
import FilterDrawer from './FilterDrawer/FilterDrawer'


const ActiveCourseTable = () => {
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [courseData, setCourseData] = useState([])
    const [tempData, setTempData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false)
    const [context, dispatch] = useContext(UserContext)
    const [filters, setFilters] = useState({
        semester: "",
        year: ""
    })
    const [activePageIndex, setActivePageIndex] = useState(1)
    const [staticNumber, setStaticNumber] = useState(9)
    const [limitStatus, setLimitStatus] = useState(2)

    function addOneDay(date) {
        // Verilen tarihten bir gün ekle
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }


    const getCourseList = async (page, status = 0) => {
        try {

            const role = context.role.replace(/\s+/g, '')
            let permControl = role == "SüperAdmin" || role == "Admin"
            setLoading(true)

            const response = await getAllCourseByStatusApi({
                page: 0,
                pageSize: staticNumber
            }, "Online", status)
            const responsev2 = await getAllCourseByStatusApi({
                page: page,
                pageSize: response.data.count
            }, "Online", status)

            if (permControl) {
                const result = responsev2?.data?.items?.filter(item => {
                    const updateEndDay = addOneDay(item?.endDate)
                    const currentDate = new Date();

                    return currentDate < updateEndDay
                })

                setCourseData(result)
                setTempData(result)
                let count = response.data.count
                let bolum = count / staticNumber
                let kalan = count % staticNumber
                if (kalan !== 0) {
                    bolum += 1
                }
                setTotalCount(response.data.count)
                setPageCount(parseInt(bolum))
            }
            else {
                let ogrenciData = responsev2.data.items.filter(el => el.status == false)?.filter(item => {
                    const specifiedDate = new Date(item?.registerTime);
                    const currentDate = new Date();
                    return currentDate < specifiedDate
                })

                setTempData(ogrenciData)
                setCourseData(ogrenciData)
                let count = ogrenciData.length
                let bolum = count / staticNumber
                let kalan = count % staticNumber
                if (kalan !== 0) {
                    bolum += 1
                }
                setTotalCount(count)
                setPageCount(parseInt(bolum))
            }

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCourseList(0, 0)
    }, [])


    const permissionRole = useMemo(() => {
        const role = context.role.replace(/\s+/g, '')
        return role == "SüperAdmin" || role == "Admin"
    }, [context])


    const searchElement = (text) => {
        const escapeRegex = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Özel karakterleri kaçış ile işaretler
        }

        let result = tempData.filter(item => {
            if (limitStatus == 0) {
                return item?.limitStatus
            }
            else if (limitStatus == 2) {
                return item
            }
            else {
                return item?.limitStatus == false
            }
        });

        // Metni regex için güvenli hale getiriyoruz
        const safeText = escapeRegex(text.toLocaleLowerCase());

        // Normal search yerine test() ve yeni güvenli regex kullanıyoruz
        result = result.filter(el => new RegExp(safeText).test(el.name.toLocaleLowerCase()));

        /*  let bolum = Math.floor(result.length / staticNumber);
         let kalan = result.length % staticNumber;
         if (kalan != 0) {
             bolum += 1;
         }
         setPageCount(parseInt(bolum)); */



        setCourseData(result);

    }


    const courseListData = useMemo(() => {
        let finalData = []
        if (activePageIndex == 1) {
            if (limitStatus == 0) {

                finalData = courseData.filter(item => item?.limitStatus).slice(0, staticNumber)

            }
            else if (limitStatus == 2) {
                finalData = courseData.slice(0, staticNumber)

            }
            else {
                finalData = courseData.filter(item => item?.limitStatus == false).slice(0, staticNumber)
            }

        }
        else {
            if (limitStatus == 0) {
                finalData = courseData.filter(item => item?.limitStatus).slice((activePageIndex - 1) * staticNumber, activePageIndex * staticNumber)
            }
            else if (limitStatus == 2) {
                finalData = courseData.slice((activePageIndex - 1) * staticNumber, activePageIndex * staticNumber)
            }
            else {
                finalData = courseData.filter(item => !item?.limitStatus).slice((activePageIndex - 1) * staticNumber, activePageIndex * staticNumber)
            }
        }
        return finalData
    }, [courseData, activePageIndex, limitStatus, staticNumber])



    const pageLimit = useMemo(() => {
        if (limitStatus == 2) {
            let bolum = Math.floor(courseData.length / staticNumber);
            let kalan = courseData.length % staticNumber;
            if (kalan != 0) {
                bolum += 1;
            }
            return parseInt(bolum)
        }
        else if (limitStatus == 1) {
            let data1 = courseData.filter(item => item?.limitStatus == false)
            let bolum = Math.floor(data1.length / staticNumber);
            let kalan = data1.length % staticNumber;
            if (kalan != 0) {
                bolum += 1;
            }
            return parseInt(bolum)
        }
        else {
            let data1 = courseData.filter(item => item?.limitStatus)
            let bolum = Math.floor(data1.length / staticNumber);
            let kalan = data1.length % staticNumber;
            if (kalan != 0) {
                bolum += 1;
            }
            return parseInt(bolum)
        }
    }, [courseData, staticNumber, limitStatus])



    const dateFormat = (date) => {
        const formatDate = new Date(date).toLocaleString()
        return formatDate
    }


    return (
        <>

            <StyledMainCourseContainer>
                <StyledHeaderContainer>
                    <StyledHeaderFilter  >
                        <StyledInput
                            placeholder="Arama yap..."
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                                searchElement(e.target.value)
                                /*   dynamicSearch(e.target.value) */
                                setActivePageIndex(1)
                            }}
                            className='inputHeader'

                        />
                        <Select
                            style={{
                                width: "150px"
                            }}
                            options={[
                                {
                                    label: "Hepsi",
                                    value: 2
                                },
                                {
                                    label: "Dolu Kontenjan",
                                    value: 0
                                },
                                {
                                    label: "Boş Kontenjan",
                                    value: 1
                                }
                            ]}
                            value={limitStatus}
                            onChange={(e) => {
                                if (e == 2) {
                                    setCourseData(tempData)
                                }
                                setLimitStatus(e)
                                setSearchTerm("")
                            }}
                        />
                    </StyledHeaderFilter>

                    {
                        permissionRole && (

                            <AddCourseBtn type='primary' onClick={() => {
                                navigate(`/panel/cevrimici/ekle`)
                            }}>
                                Kurs Ekle
                            </AddCourseBtn>

                        )
                    }

                </StyledHeaderContainer>

            </StyledMainCourseContainer>

            {
                loading ? (
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px", marginBottom: "50px" }} >
                        <PropagateLoader color="#36d7b7" />
                    </div>
                ) : (
                    <ActiveCourseDashboardWrapper  >
                        {
                            courseData && courseData.length !== 0 ? (
                                <div className='active_course_container' >
                                    {
                                        courseListData.map((el) => {

                                            return (
                                                <div className='parent-card-div' style={{ cursor: "pointer" }} onClick={() => {
                                                    navigate(`/panel/kurs/${el.id}`)
                                                }} >
                                                    {
                                                        context?.role == "Öğrenci" && el?.limitStatus && (
                                                            <div style={{
                                                                backgroundColor: "red",
                                                                position: "absolute",
                                                                top: "25px",
                                                                left: "20px",
                                                                width: "max-content",
                                                                borderRadius: "5px"
                                                            }} >
                                                                <p style={{ fontSize: "10px", color: "#fff", textAlign: "center", paddingLeft: "6px", paddingRight: "6px", paddingTop: "4px", paddingBottom: "4px" }}>
                                                                    Kontenjan Dolu
                                                                </p>
                                                            </div>

                                                        )
                                                    }
                                                    {
                                                        context?.role !== "Öğrenci" && (
                                                            <div className='person-count'>
                                                                <img src={person} alt="" />
                                                                <p className='count_item'  >{el.limit} / {el?.courseRegisterCount} </p>
                                                            </div>

                                                        )
                                                    }



                                                    <div className='all-info-card'>
                                                        <p className='info-1'>{el.type == "Online" ? "Canlı Eğitim" : "Videolu Eğitim"}</p>
                                                        <p className='info-2' style={{ fontSize: el.name.length > 17 ? "15px" : "19px", textTransform: "capitalize" }}>{el.name}</p>
                                                        <p style={{ color: "blue", fontSize: 14 }} >
                                                            # {el.id}
                                                        </p>
                                                        {/* <hr className='hr'/> */}
                                                        <img src={line} alt="" style={{ maxWidth: "200px" }} />
                                                        <div className='div-inside'>
                                                            <p className='dates'>{dateFormat(el?.startDate)} - </p>
                                                            <p className='dates' style={{ marginLeft: "5px" }} >{dateFormat(el?.endDate)}</p>
                                                        </div>
                                                        <p>
                                                            {el?.courseAdministrator ?? "Eğitmen Seçilmedi"}
                                                        </p>
                                                    </div>
                                                    <div className='saison-lesson'> {el.semester}</div>
                                                    <div className='container' id='base-card-lesson'></div>
                                                    <div className='corner-image'></div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            ) : <h3 style={{ width: "100%", textAlign: "center", marginTop: "20px", marginBottom: "20px" }} >
                                Aktif Kurs Yok
                            </h3>


                        }
                        {
                            courseData.length !== 0 && (
                                <div className='active_course_table_pagination' >
                                    <Pagination current={activePageIndex} total={pageLimit} pageSize={1} onChange={(e) => {
                                        /*  getCourseList(e - 1, courseStatus)*/
                                        setActivePageIndex(e)
                                    }} />
                                </div>
                            )
                        }


                    </ActiveCourseDashboardWrapper>
                )
            }

            <FilterDrawer
                staticNumber={staticNumber}
                tempData={tempData}
                filters={filters}
                setFilters={setFilters}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
                setSearchTerm={setSearchTerm}
                getCourseList={getCourseList}
                setActivePageIndex={setActivePageIndex}
                setCourseData={setCourseData}
                setPageCount={setPageCount} />
        </>
    )
}

export default ActiveCourseTable

