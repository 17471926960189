// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  position: sticky;
  top: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); }
  .card .col1 {
    display: flex;
    width: 90%;
    top: 0;
    padding-right: 0; }
    .card .col1 #img {
      height: auto;
      width: 60%; }
  .card .col2 {
    padding-left: 0;
    display: grid;
    grid-template-rows: 45%;
    gap: 0; }
    .card .col2 .p1 {
      font-family: "Caladea", serif;
      font-weight: bold;
      font-size: large; }
    .card .col2 .p2 {
      color: #7E7EAA;
      padding-top: 10px !important; }

@media screen and (max-width: 1200px) {
  .p2 {
    padding-top: 15px !important; } }

@media screen and (max-width: 1000px) and (min-width: 760px) {
  .p2 {
    font-size: xx-small; } }
`, "",{"version":3,"sources":["webpack://./src/Public/components/cardFooter/index.scss"],"names":[],"mappings":"AAEA;EAEI,gBAAgB;EAChB,MAAM;EACN,0CAA0C,EAAA;EAJ9C;IAMQ,aAAa;IACb,UAAU;IACV,MAAM;IACN,gBAAgB,EAAA;IATxB;MAYY,YAAY;MACZ,UAAU,EAAA;EAbtB;IAmBQ,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,MAAM,EAAA;IAtBd;MAyBY,6BA3BkB;MA4BlB,iBAAiB;MACjB,gBAAgB,EAAA;IA3B5B;MA+BY,cAAc;MACd,4BAA4B,EAAA;;AAMxC;EAEI;IACI,4BAA4B,EAAA,EAC/B;;AAEL;EAEI;IACI,mBAAmB,EAAA,EACtB","sourcesContent":["$firstFamily: 'Caladea', serif;\n\n.card {\n\n    position: sticky;\n    top: 0;\n    box-shadow: 1px 1px 1px rgba($color: black, $alpha: .2);\n    .col1 {\n        display: flex;\n        width: 90%;\n        top: 0;\n        padding-right: 0;\n\n        #img {\n            height: auto;\n            width: 60%;\n        }\n    }\n\n    \n    .col2 {\n        padding-left: 0;\n        display: grid;\n        grid-template-rows: 45%;\n        gap: 0;\n\n        .p1 {\n            font-family: $firstFamily;\n            font-weight: bold;\n            font-size: large;\n        }\n\n        .p2 {\n            color: #7E7EAA;\n            padding-top: 10px !important;\n\n        }\n    }\n}\n\n@media screen and (max-width: 1200px) {\n   \n    .p2{\n        padding-top: 15px !important;\n    }\n}\n@media screen and (max-width: 1000px) and (min-width:760px){\n   \n    .p2{\n        font-size: xx-small;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
