import { DatePicker, Select, TimePicker, Button as AntButton, Table as AntTable, Modal as AntModal } from 'antd'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { CgClose } from 'react-icons/cg'
import { Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { CourseCalendarGetById, CreateAddOneDateApi, CreateCourseCalendar, DeleteCourseCalendarApi, updateCourseProgramApi } from '../../../../api/Course'
import DataTable from 'react-data-table-component'
import { formatDate } from "date-fns"
import trLocale from "date-fns/locale/tr"
import styled from 'styled-components'
import { MdRefresh } from 'react-icons/md'
import { TbTrash } from 'react-icons/tb'
import "./EditCourseProgram.scss"
import { UpdateCourseContext } from '../UpdateCourseForm/UpdateCourseForm'
import { updateCourseContent } from '../../../../api/CourseContent'
import { formatInTimeZone } from 'date-fns-tz'



const EditCourseProgram = ({ mainData }) => {
    const { id } = useParams()

    const [showAddProgramModal, setShowAddProgramModal] = useState(false)
    const [calendarList, setCalendarList] = useState([])
    const [selectedProgramData, setSelectedProgramData] = useState({
        day: "Pazartesi",
        startTime: "12:00",
        duration: 0,
        id: ""
    })
    const [loading, setLoading] = useState()
    const [activeType, setActiveType] = useState(0)
    const [addProgramType, setAddProgramType] = useState(1)
    const { detailCourseApiRequest } = useContext(UpdateCourseContext)



    const statusControl = (row) => {
        if (activeType == 0) {
            return (
                <p style={{ backgroundColor: "#FF9800", padding: "5px 10px", color: "white", borderRadius: "5px" }} >
                    Bekleniyor
                </p>
            )
        }
        else if (activeType == 1) {
            return (
                <p style={{ backgroundColor: "#E72929", padding: "5px 10px", color: "white", borderRadius: "5px" }} >
                    Geçmiş
                </p>
            )
        }
        else {
            return (
                <p style={{ backgroundColor: "green", padding: "5px 10px", color: "white", borderRadius: "5px" }} >
                    Aktif
                </p>
            )
        }
    }


    const dayList = useMemo(() => {
        return ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"]
    }, [])
    const format = 'HH:mm'


    function filtreleIleriTarihler(courses, status = 0) {
        if (status == 0) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a.startTime) - new Date(b.startTime);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = courses.filter(function (course) {
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBitisTarihi < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b.startTime) - new Date(a.startTime);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < kursBitisTarihi; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a.startTime) - suankiTarih) - Math.abs(new Date(b.startTime) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }


    const getCalendar = async () => {
        try {
            setLoading(true)
            const response = await CourseCalendarGetById(id, {
                page: 0,
                pageSize: 1000
            })

            setCalendarList(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCalendar()
    }, [])


    const calendarData = useMemo(() => {
        if (activeType == 0) {
            const ileriTarih = filtreleIleriTarihler(calendarList, 0)
            return ileriTarih
        }
        else if (activeType == 1) {
            const gecmis = filtreleIleriTarihler(calendarList, 1)
            return gecmis
        }
        else {
            const active = filtreleIleriTarihler(calendarList, 2)
            return active
        }
    }, [calendarList, activeType])

    function isDateInRange(startDate, endDate, checkDate) {
        // Tarihleri Date nesnelerine dönüştür
        const start = new Date(startDate);
        const end = new Date(endDate);
        const check = new Date(checkDate);

        // Tarihlerin geçerli olup olmadığını kontrol et
        if (isNaN(start) || isNaN(end) || isNaN(check)) {
            throw new Error("Geçersiz tarih formatı");
        }

        // Kontrol tarihinin başlangıç ve bitiş tarihleri arasında olup olmadığını kontrol et
        return check >= start && check <= end;
    }


    function isValidDate(dateString) {
        const timestamp = Date.parse(dateString);
        return !isNaN(timestamp);
    }


    function getUtcTime(hourMinute) {
        // Şu anki tarihi al
        const now = new Date();

        // Saat ve dakikayı parametreden ayır
        const [hour, minute] = hourMinute.split(':').map(Number);

        // O günün saatini ve dakikasını ayarla
        now.setHours(hour, minute, 0, 0);

        // UTC saatini döndür
        return now.toISOString();
    }


    const confirmProgram = async () => {
        try {
            if (addProgramType == 1) {  // Toplu Program seçilmişsse
                if (selectedProgramData.duration < 10 || selectedProgramData.duration > 60) {
                    toast.error("Kurs uzunluğu minumum 10 maksimum 60dk olabilir", {
                        autoClose: 1000
                    })
                }
                else {
                    let formatHour = saatStringToISOString(selectedProgramData.startTime)

                    const response = await CreateCourseCalendar({
                        courseId: parseInt(id),
                        startTime: formatHour,
                        days: [selectedProgramData.day],
                        duration: parseInt(selectedProgramData.duration)
                    })
                    toast.success("Toplu Program kayıt edildi")
                    setShowAddProgramModal(false)
                    setSelectedProgramData({
                        day: "Pazartesi",
                        startTime: "12:00",
                        duration: 0,
                        id: ""
                    })
                    await getCalendar()
                    await detailCourseApiRequest()
                }
            }
            else {
                var suankiTarih = new Date()
                var secilenTarih = new Date(selectedProgramData.startTime);
                if (selectedProgramData?.duration < 10 || selectedProgramData?.duration > 60) {
                    toast.error("Kurs uzunluğu minumum 10 maksimum 60dk olabilir", {
                        autoClose: 1200
                    })
                }
                else {

                    let resultDate = ""
                    if (!isValidDate(selectedProgramData?.startTime)) {
                        resultDate = new Date().toUTCString()
                    }
                    else {
                        resultDate = new Date(selectedProgramData.startTime).toUTCString()
                    }


                    await CreateAddOneDateApi({
                        courseId: parseInt(id),
                        startTime: resultDate,
                        duration: parseInt(selectedProgramData.duration)
                    })
                    let format = formatDate(new Date(selectedProgramData.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })
                    toast.success(` ${format} tarihi için canlı yayın oluşturuldu `, {
                        autoClose: 2000
                    })
                    setShowAddProgramModal(false)
                    setSelectedProgramData({
                        day: "Pazartesi",
                        startTime: "12:00",
                        duration: 0,
                        id: ""
                    })
                    await getCalendar()
                    await detailCourseApiRequest()
                }

            }


        }
        catch (err) {

        }

    }


    function saatStringToISOString(saatString) {
        var simdikiZaman = new Date(mainData.startDate);

        // Yıl, ay ve gün bilgilerini al
        var yil = simdikiZaman.getFullYear();
        var ay = simdikiZaman.getMonth() + 1; // Ay 0-indexli olduğu için +1 ekliyoruz
        var gun = simdikiZaman.getDate();


        // Saat ve dakika bilgilerini saatString'den ayır
        var saat = parseInt(saatString.split(":")[0]);
        var dakika = parseInt(saatString.split(":")[1]);

        // Yeni tarih ve zaman oluştur
        var tarihVeZaman = new Date(yil, ay - 1, gun, saat, dakika);

        // Üç saat ekle
        tarihVeZaman.setHours(tarihVeZaman.getHours());

        // UTC formatına çevir ve sonucu döndür
        var istenenFormat = tarihVeZaman.toUTCString();


        return istenenFormat;
    }



    const formatDateTr = (date) => {
        return formatDate(new Date(date), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })
    }



    const KursEklemeHeader = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;
        width: 100%;

        @media screen  and (max-width:550px) {
            flex-direction: column-reverse;
            row-gap: 20px;
        }
    `

    const SelectFilter = styled.select`
         width:  150px;
    `


    const SelectAddProgramType = styled.div`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
    `

    const SelectProgramItem = styled.div`
            padding:  15px;
            border-radius: 10px;
            cursor: pointer;
            color : ${props => props.code == addProgramType ? "white" : "black"};
            background-color: ${props => addProgramType == props.code ? "#088F7D" : "#efefef"};

    `

    const AntSelect = styled(Select)`
        width: 200px;

        @media screen  and (max-width:550px){
            width: 100%;
      
        }
    `

    const ButtonContainer = styled.div`
        display: flex;
        column-gap: 20px;

        @media screen  and (max-width:550px){
            width: 100%;
            justify-content: space-between;
        }
    `

    const convertTime = (time) => {
        /* const parsedDate = parseISO(time); */
        /* const formattedDate = format(time, "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }); */
        const formattedDate = formatInTimeZone(time, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
        return formattedDate
    }



    return (
        <>
            <Row>
                <Col lg={12} >
                    <KursEklemeHeader    >

                        <div style={{ width: "100%" }} >
                            <AntSelect
                                value={activeType}
                                onChange={(e) => {
                                    setActiveType(e)
                                }}
                                options={[
                                    {
                                        "label": "Bekleyen",
                                        "value": 0
                                    },
                                    {
                                        "label": "Geçmiş",
                                        "value": 1
                                    },
                                    {
                                        "label": "Aktif",
                                        "value": 2
                                    }
                                ]}
                            />
                        </div>

                        <ButtonContainer >
                            <AntButton type='primary' style={{ whiteSpace: "nowrap", backgroundColor: "#088F7D" }} onClick={() => {
                                setShowAddProgramModal(true)
                            }}  >
                                Program Ekle
                            </AntButton>
                            <AntButton type='primary' loading={loading} onClick={() => {
                                getCalendar()
                            }} style={{ whiteSpace: "nowrap" }}>
                                Yenile{/* <MdRefresh style={{ fontSize: "18px" }} /> */}
                            </AntButton>
                        </ButtonContainer>
                    </KursEklemeHeader>

                </Col>
                <Col lg={12} style={{ marginTop: "10px" }}  >
                    <AntTable
                        size='middle'
                        dataSource={calendarData}
                        scroll={{
                            x: 800
                        }}
                        pagination={{
                            pageSize: 10
                        }}
                        columns={[
                            {
                                title: "CalendarId",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.onlineCourseUrls[0]?.courseCalendarId}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Başlangıç",
                                render: (row, record) => {
                                    return (
                                        <span  >
                                            {
                                                convertTime(record?.startTime)
                                            }
                                        </span>
                                    )
                                },
                                sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
                            },
                            {
                                title: "Bitiş",
                                render: (row, record) => {
                                    return (

                                        <span  >
                                            {
                                                convertTime(record?.endTime)
                                            }
                                        </span>
                                    )
                                }
                            },
                            {
                                title: "Süre",
                                render: (row, record) => {
                                    return (
                                        <span> {row?.duration} (dk) </span>
                                    )
                                },
                                sorter: (a, b) => a?.duration - b?.duration
                            },
                            {
                                title: "İşlem",
                                render: (row, record) => {
                                    return (
                                        <AntButton danger type='primary' disabled={activeType == 2} onClick={() => {
                                            AntModal.confirm({
                                                centered: true,
                                                okText: "Eminim",
                                                content: (
                                                    <p>
                                                        Silmek istediğinize emin misiniz ?
                                                    </p>
                                                ),
                                                cancelText: "İptal",
                                                onOk: async () => {
                                                    try {

                                                        const response = await DeleteCourseCalendarApi(record.id)
                                                        await getCalendar()
                                                        let formatDate = formatDateTr(record.startTime)
                                                        toast.success(`${formatDate} tarihli canlı kurs takvimi silindi`, {
                                                            autoClose: 2500
                                                        })
                                                        await detailCourseApiRequest()
                                                    }
                                                    catch (err) {


                                                    }
                                                }
                                            })
                                        }} >
                                            <TbTrash />
                                        </AntButton>)
                                }
                            }


                        ]}
                    />

                </Col>

            </Row >
            <Modal isOpen={showAddProgramModal} size='lg'  >
                <ModalHeader>
                    Kurs Programı
                </ModalHeader>
                <ModalBody>
                    <ModalBodyContent>
                        <SelectAddProgramType>
                            <SelectProgramItem code={1} onClick={() => {
                                setAddProgramType(1)
                                setSelectedProgramData({
                                    day: "Pazartesi",
                                    startTime: "12:00",
                                    duration: 0,
                                    id: ""
                                })
                            }} >   Toplu Program Ekle</SelectProgramItem>
                            <SelectProgramItem code={0} onClick={() => {
                                setAddProgramType(0)
                                setSelectedProgramData({
                                    day: "Pazartesi",
                                    startTime: "12:00",
                                    duration: 0,
                                    id: ""
                                })
                            }} >
                                Tekli Program Ekle

                            </SelectProgramItem>




                        </SelectAddProgramType>


                        <div style={{ width: "100%", marginTop: "20px" }} >
                            {
                                addProgramType == 1 && (
                                    <div className="mb-3">
                                        <Label className="form-label">
                                            Gün
                                        </Label>
                                        <select value={selectedProgramData.day} className={`form-control`} onChange={(e) => {
                                            setSelectedProgramData({
                                                ...selectedProgramData,
                                                day: e.target.value,
                                            })
                                        }} >
                                            {
                                                dayList.map((el, index) => {
                                                    return (
                                                        <option key={`${index}`} value={el}  >
                                                            {el}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>

                                    </div>
                                )
                            }

                            <div className="mb-3">
                                <Label className="form-label">
                                    Başlangıç Saati
                                </Label>
                                <div>
                                    {
                                        addProgramType == 1 ? (
                                            <TimePicker allowClear={false}
                                                onChange={(e) => {
                                                    setSelectedProgramData({
                                                        ...selectedProgramData,
                                                        startTime: `${e.hour()}:${e.minute().toString().padStart(2, "0")}`
                                                    })
                                                }}

                                                value={dayjs(selectedProgramData.startTime, format)} className='custom-time-picker' format={format} />
                                        ) : (
                                            <DatePicker
                                                style={{
                                                    width: "100%",
                                                    padding: "8px"
                                                }}
                                                placeholder='Başlangıç saati seçiniz'
                                                allowClear={false}
                                                showTime
                                                value={selectedProgramData.startTime == "12:00" ? dayjs(new Date()) : dayjs(selectedProgramData.startTime)}
                                                onChange={(value, dateString) => {
                                                    setSelectedProgramData({
                                                        ...selectedProgramData,
                                                        startTime: value
                                                    })
                                                    /*   formik.setFieldValue("startDate", value) */
                                                }}

                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <div className="">
                                <Label className="form-label">
                                    Canlı Kurs Süresi
                                </Label>
                                <div>
                                    <Input style={{ marginLeft: 0 }} type='number' value={selectedProgramData.duration} onChange={(e) => {
                                        setSelectedProgramData({
                                            ...selectedProgramData,
                                            duration: e.target.value
                                        })
                                    }}
                                        onBlur={(e) => {
                                            e.target.focus()
                                        }}
                                    />

                                </div>


                            </div>

                        </div>
                    </ModalBodyContent>




                </ModalBody>
                <ModalFooter>
                    <StyledAntButton type='primary' onClick={() => {
                        confirmProgram()
                    }} >
                        Onayla
                    </StyledAntButton>
                    <StyledAntButton danger type='primary' onClick={() => {
                        setShowAddProgramModal(false)
                        setAddProgramType(0)
                        setSelectedProgramData({
                            day: "Pazartesi",
                            startTime: "12:00",
                            duration: 0,
                            id: ""
                        })
                    }} >
                        İptal
                    </StyledAntButton>
                </ModalFooter>
            </Modal>
        </>

    )
}

export default EditCourseProgram


const ModalBodyContent = styled.div`
display: flex;
padding: 10px;
flex-direction: column;
`

const StyledAntButton = styled(AntButton)`

`