import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Timer = ({ duration, onTimeEnd }) => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    // Süre bittiğinde
    if (timeLeft <= 0) {
      if (onTimeEnd) {
        onTimeEnd();
      }
      return;
    }

    // Her saniye süreyi güncelle
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    // Component unmount olduğunda timer'ı temizle
    return () => clearInterval(timer);
  }, [timeLeft, onTimeEnd]);

  // duration değiştiğinde timeLeft'i güncelle
  useEffect(() => {
    setTimeLeft(duration);
  }, [duration]);

  // Süreyi saat:dakika:saniye formatına çevir
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="timer-container">
      <div className="timer">
        <span className="time-display">{formatTime(timeLeft)}</span>
      </div>
 
      <style jsx>{`
        .timer-container {
          padding: 10px;
          background-color: #f8f9fa;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          display: inline-block;
        }
        .timer {
          font-size: 1.5rem;
          font-weight: bold;
          color: #dc3545;
        }
        .time-display {
          font-family: monospace;
        }
      `}</style>
    </div>
  );
};

export default Timer;
