import React, { useContext, useEffect, useMemo, useState } from 'react'
import { axiosInstance } from '../../api/axiosInstance'
import styled from 'styled-components'
import { Col, DatePicker, Row, Table, Input, Button, Select } from 'antd'
import { formatInTimeZone } from 'date-fns-tz'
import trLocale from "date-fns/locale/tr"
import { UserContext } from '../../context/user'
import { toast } from 'react-toastify'

const CanlıYayınListe = () => {
    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const [calendarList, setCalendarList] = useState([])
    const [selectedTime, setSelectedTime] = useState("")
    const [selectedStatus, setSelectedStatus] = useState(0)
    const [context, dispatch] = useContext(UserContext)

    const getCourseList = async () => {
        try {
            setLoading(true)
            const response1 = await axiosInstance.post("/courses/getAll?type=Online&status=0", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/courses/getAll?type=Online&status=0", {
                page: 0,
                pageSize: response1.data?.count
            })
            const courseList = response2?.data?.items
            setCourseList(courseList)

            const calendarList = await Promise.all(courseList?.map(item => {
                return axiosInstance.post(`/courseCalendars/GetByCourseId?courseId=${item?.id}`, {
                    page: 0,
                    pageSize: 1000
                })
            }))

            const result = calendarList?.flatMap(el => el?.data?.items)?.map(el => {
                let course = courseList?.find(item => item?.id == el?.courseId)
                return {
                    courseId: el?.courseId,
                    startTime: el?.startTime,
                    endTime: el?.endTime,
                    duration: el?.duration,
                    name: course?.name,
                    limit: course?.limit,
                    admin: course?.courseAdministrator ?? "Eğitmen Seçilmedi",
                    calendarId: el?.onlineCourseUrls[0]?.courseCalendarId
                }
            })

            setCalendarList(result)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const convertTime = (time) => {
        const formattedDate = formatInTimeZone(time, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
        return formattedDate
    }


    function tariheGoreFiltrele(calendarDatas, status = 0) {
        if (status == 0) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = calendarDatas?.filter(function (item) {
                var kursBaslangicTarihi = new Date(item?.startTime); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a.startTime) - new Date(b.startTime);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = calendarDatas.filter(function (course) {
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBitisTarihi < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b.startTime) - new Date(a.startTime);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = calendarDatas.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < kursBitisTarihi; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a.startTime) - suankiTarih) - Math.abs(new Date(b.startTime) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }

    const getMeetingsAccordingToTİme = useMemo(() => {
        if (selectedTime == "") {

            return tariheGoreFiltrele(calendarList, selectedStatus)
        }
        else {
            const times = selectedTime.split("-")
            const selectedYear = times[0]
            const selectedMonth = times[1]
            const selectedDay = times[2]
            const filteredData = calendarList.filter(el => {
                let yıl = new Date(el?.startTime).getUTCFullYear()
                let ay = new Date(el?.startTime).getUTCMonth() + 1
                let gun = new Date(el?.startTime).getUTCDate()
                return selectedYear == yıl && selectedMonth == ay && selectedDay == gun
            })

            return tariheGoreFiltrele(filteredData, selectedStatus)

        }

    }, [calendarList, selectedTime, selectedStatus])

    useEffect(() => {
        getCourseList()
    }, [])

    const [searchTextName, setSearchTextName] = useState("")
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchTextName(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchTextName('');
        confirm()
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset

                </Button>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLocaleLowerCase().includes(value.toLocaleLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const joinMeeting = async (calendarId) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/OnlineCourseUrls/GetByCourseCalendarIdForSupervisor", {
                courseCalendarId: calendarId
            })

            if (response.data.url == "Zaman Bekleniyor.") {
                toast.info("Eğitmen bekleniyor yayın henüz oluşturulmadı", {
                    position: "top-center",
                    autoClose: 2000
                })

            }
            else {
                window.open(response.data?.url, "_blank")
            }
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }



    return (
        <StyledContainer>
            <Row style={{
                width: "100%",
                borderBottom: "1px dashed gray",
                paddingBottom: "10px"
            }}
                gutter={[
                    {
                        md: 12
                    }
                ]}
            >
                <Col md={6} xs={24} >
                    <StyledInput disabled={loading} onChange={(date, dateString) => {
                        setSelectedTime(dateString)
                    }} needConfirm />
                </Col>
                <Col md={6} xs={24} >
                    <Select
                        style={{
                            width: "100%"
                        }}
                        loading={loading}
                        onChange={(e) => {
                            setSelectedStatus(e)
                        }}
                        value={selectedStatus}
                        options={[
                            {
                                label: "Bekleyen",
                                value: 0
                            },
                            {
                                label: "Geçmiş",
                                value: 1
                            },
                            {
                                label: "Aktif",
                                value: 2
                            }
                        ]}

                    />
                </Col>
            </Row>

            <StyledTable
                loading={loading}
                scroll={{
                    x: 600
                }}
                bordered={true}

                dataSource={getMeetingsAccordingToTİme}
                footer={() => {
                    return (
                        <p style={{
                            fontWeight: "bold"
                        }}>
                            Toplam : {getMeetingsAccordingToTİme?.length}
                        </p>
                    )
                }}
                columns={[
                    {
                        title: "Kurs Id",
                        dataIndex: "courseId",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.courseId}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("courseId")
                    },
                    {
                        title: "CalendarId",
                        dataIndex: "calendarId",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.calendarId}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("calendarId")
                    },
                    {
                        title: "Kurs İsmi",
                        dataIndex: "name",

                        render: (row, record) => {
                            return {
                                children: (
                                    <p >
                                        {record?.name}
                                    </p>
                                )
                            }
                        },
                        ...getColumnSearchProps("name")
                    },
                    {
                        title: "Başlangıç",
                        render: (row, record) => {
                            return (
                                <p>
                                    {convertTime(record?.startTime)}
                                </p>
                            )
                        },
                        sorter: (a, b) => new Date(a?.startTime) - new Date(b?.startTime)
                    },
                    {
                        title: "Bitiş",
                        render: (row, record) => {
                            return (
                                <p>
                                    {convertTime(record?.endTime)}
                                </p>
                            )
                        },
                        sorter: (a, b) => new Date(a?.endTime) - new Date(b?.endTime)
                    },
                    {
                        title: "Süre",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.duration}(Dk)
                                </p>
                            )
                        }
                    },
                    {
                        title: "Kurs Kontenjan",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.limit}
                                </p>
                            )
                        }
                    },
                    {
                        title: "Eğitmen",
                        dataIndex: "admin",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.admin}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("admin")
                    },
                    {
                        title: "Bağlantı",
                        hidden: context?.userId != 7,
                        render: (row, record) => {
                            return (
                                <Button type='primary' loading={loading} disabled={selectedStatus != 2} onClick={() => {
                                    joinMeeting(record?.calendarId)
                                }}  >
                                    Katıl
                                </Button>
                            )
                        }
                    }
                ]}
            />
        </StyledContainer>
    )
}

export default CanlıYayınListe


const StyledContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

const StyledInput = styled(DatePicker)`
    width: 100%;
    margin-left: 0 !important;
`

const StyledCourseField = styled.p`
    font-size: 16px;
    color: blue !important;
    @media screen and (max-width:600px) {
        font-size: 12px;
        color: red;
    }
`

const StyledTable = styled(Table)`
    .ant-table-cell {
        font-size: 14px;


        @media screen and (max-width:600px) {
            font-size:11px;
        }
    }
`