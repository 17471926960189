import flagus from "../../../assets/images/usa.png";
import flagtr from "../../../assets/images/turkey.png";
import arap from "../../../assets/images/arap.png"

const languages = {
    en: {
        label: "English",
        flag: flagus
    },
    tr: {
        label: "Turkish",
        flag: flagtr
    },
    ar : {
        label : "Arabic",
        flag : arap
    }


}

export default languages
