import b1 from "../../../assets/images/b1.png"
import kuzemsss from "../../../assets/images/kuzemsss.jpg"
import seoImage from "../../../assets/images/seoimage.jpg"
const blogDataList = [
    {
        "id": 1,
        "label": "Uzaktan Eğitim Nedir ?",
        "image": b1,
        "content": [
            {
                "title": "Uzaktan Eğitim: Yarının Eğitim Modeli",
                "content": `
                    Geleneksel eğitim, fiziksel sınıflar, tahtalar ve kampüslerle özdeşleşirken, teknolojinin hızlı gelişimiyle birlikte uzaktan eğitim kavramı önemli bir yükseliş yaşamaktadır. Uzaktan eğitim, öğrencilere coğrafi konumlarına bakılmaksızın eğitim alma fırsatı sunan bir eğitim modelini temsil eder. İşte bu yeni eğitim modelinin ne olduğu, faydaları, zorlukları ve neden geleceğin eğitim modeli olabileceği hakkında bir derleme.
                `
            },
            {
                "title": "Uzaktan Eğitim Nedir?",
                "content": `
                      Uzaktan eğitim, öğrencilere derslere internet aracılığıyla erişim sağlayan bir eğitim modelidir. Bu model, öğrencilere dersleri istedikleri yerde ve istedikleri zaman takip etme özgürlüğü sunar. Bu, öğrencilere çalışma, aile ve diğer kişisel taahhütlerini eğitimle uyumlu hale getirme fırsatı verir.  
                `
            },
            {
                "title": "Faydaları Nelerdir?",
                "content": `
                    Uzaktan eğitimin önemli avantajlarından biri, esneklik sunmasıdır. Öğrenciler, geleneksel sınıf saatlerine bağlı kalmak zorunda değildirler; bu da çalışan yetişkinler ve uzak bölgelerde yaşayan öğrenciler için son derece önemlidir. Ayrıca, uzaktan eğitim, öğrencilere dünyanın dört bir yanından uzman öğretmenlerin bilgisine erişim olanağı tanır. Bu, öğrencilere geleneksel eğitimde nadiren bulunan bir çeşitlilik ve deneyim sunar. Ek olarak, çevrimiçi eğitim genellikle maliyet açısından daha avantajlıdır, çünkü öğrenciler taşıma ve konaklama masraflarından tasarruf edebilirler.
                `
            },
            {
                "title": "Zorlukları Nelerdir?",
                "content": `
                    Ancak, uzaktan eğitim modelinin de bazı zorlukları vardır. Öğrencilerin, disiplinli ve kendi başlarına çalışma yeteneklerine sahip olmaları gerekebilir. Eğitim materyali ve etkileşim eksikliği, bazı öğrencilerin dikkatlerini dağıtabilir ve anlama konusunda zorluk yaşatabilir. Ayrıca, teknolojik sorunlar bazı öğrenciler için çevrimiçi derslerde sıkıntı yaratabilir
                `
            }
        ]
    },
    {
        "id": 2,
        "image": kuzemsss,
        "label": "SIKÇA SORULAN SORULAR",
        "content": [
            {
                "title": "Uzaktan eğitim nedir?",
                "content": `
                    Uzaktan eğitim, öğrencilere derslere internet aracılığıyla erişim sağlayan bir eğitim modelidir. Bu model, öğrencilere dersleri istedikleri yerde ve istedikleri zaman takip etme özgürlüğü sunar. Bu, öğrencilere çalışma, aile ve diğer kişisel taahhütlerini eğitimle uyumlu hale getirme fırsatı verir. Uzaktan eğitim senkron (çevrim içi) ve asenkron (çevrim dışı) olarak ikiye ayrılır.
                `
            },
            {
                "title": "Senkron (çevrim içi) eğitim nedir?",
                "content": `
                    Eğitmen ve kursiyerin eş zamanlı olarak dijital bir platform üzerinde bir araya gelip, dersin canlı olarak işlendiği eğitim modelidir.    
                `
            },
            {
                "title": "Asenkron (çevrim dışı) eğitim nedir?",
                "content": `
                    Eğitmen ve kursiyerin aynı anda bir arada olmadığı, daha önceden hazırlanmış video, ses kaydı vb. dijital içeriklere kursiyerin erişmesi ve istediği zaman istediği yerde faydalanabileceği eğitim modelidir
                `
            },
            {
                "title": "KUZEM’de senkron eğitim var mı?",
                "content": "Evet, KUZEM’de senkron/çevrim içi eğitimler verilmektedir. 3 farklı dönemde kayıtlar alınmaktadır."
            },
            {
                "title": "KUZEM’de asenkron eğitim var mı?",
                "content": "Evet, KUZEM’de asenkron/çevrim dışı eğitimler verilmektedir. kuzem.org üzerinde bulunan üyelik butonunu kullanarak giriş yapabilir, eğitimini almak istediğiniz video derse kayıt yapabilir ve dilediğiniz zaman, dilediğiniz yerde bu eğitimleri takip edebilirsiniz."
            }
        ]

    },
    {
        "id": 3,
        "image": seoImage,
        "label": "Seo Nedir",
        "content": [
            {
                "title": "",
                "content": `
                    SEO yani Arama Motoru Optimizasyonu, web sitenizi arama motorlarında daha yüksek sıralara taşımanın bir yoludur.

İnternetin 1990’larda halka açılmasıyla web siteleri ve arama motorları da gelişmeye başladı. Bu dönemde SEO’nun ilk adımları atıldı. Ancak o zamanlar daha basit ve kuralların net olmadığı bir süreçti. Arama motorlarının yaygın olmadığı süreçte, web katalogları vardı. Kullanıcılar bu web kataloglarını (Yahoo, DMOZ vb.) kullanarak aradıkları internet sitelerini buluyordu. Web kataloglarına kayıt olmak o dönemdeki SEO’nun bir parçasıydı.

Günden güne gelişen yazılım teknolojisi ile birlikte arama motorları 2000’li yıllarda yükselişe geçti ve belirgin bir şekilde öne çıkmaya başladı. Bu dönemlerde arama motoru optimizasyonu için anahtar kelime yoğunluğu ve meta etiketleri gibi bugün bize basit gelen optimizasyon teknikleri popülerdi. Google, algoritmalarını geliştirdi ve SEO işi artık daha karmaşık hale geldi. Kaliteli içerik, backlinkler, site hızı gibi etkenler önem kazandı.

Mobil cihazların kullanımının artmasıyla birlikte, Google mobil uyumluluk ve hızın önemini artırdı. Google, mobil uyumlu siteleri ödüllendirmeye başlayarak ön sıralarda çıkarmaya başladı. Bu da mobil uyumlu internet sitelerinin önemini artırdı.

SEO’nun gelişim aşamasından kısaca bahsetmiş olduk. Peki SEO için neler yapabilirim? Arama motorlarında ilk sayfalarda çıkmak istiyorsam nasıl bir yol izlemem gerekir? Şimdi birlikte bunlara bakalım.

Anahtar Kelime Araştırması: İlk adım, hangi anahtar kelimelerin hedef kitlenizin aramalarında sıkça kullanıldığını belirlemektir. Bu anahtar kelimeleri belirleyerek, içeriğimizi bu terimlere göre optimize etmemiz gerekir. Anahtar kelimeleri belirlerken kullanılabilecek birçok araç var. Bunların da araştırmasını yaparak SEO çalışmalarımızda kullanabiliriz.

Kaliteli İçerik Üretme: İnternet sitesine giriş yaptığımız içeriğin, okuyuculara değer katan, ilgi çekici ve bilgilendirici olması gerekmektedir. Google, kullanıcıya hitap etmeyen, insanlara faydalı olmayacak şekilde rastgele hazırlanmış içerikleri olumsuz olarak görmektedir. Bu yüzden içeriğimizi oluştururken son kullanıcıya göre oluşturmamız gerekir. Oluşturduğumuz içerikleri düzenli olarak güncellemek, yeni içerik girmek arama motorları tarafından olumlu bir şekilde değerlendirilmektedir.

Sayfa Başlıkları ve Meta Açıklamaları: Her sayfanın benzersiz bir başlığı ve meta açıklaması olmalıdır. Başlıklar ve açıklamalar, içeriğinizin ne hakkında olduğunu özetlemeli ve kullanıcıyı yönlendirmelidir.

URL Yapısı: Sayfa adresleri basit ve anlaşılır olmalıdır. Kısa ve açıklayıcı URL'ler, arama motorları tarafından tercih edilir. Örneğin abc.com/haberdetayi?=25 gibi bir URL yapısı kullanmak yerine abc.com/uzaktan-egitim-kurslari şeklinde bir yapı oluşturmak arama motorlarında bizi öne çıkaracaktır.

Mobil Dostu Tasarım: Web sitenizin mobil cihazlarda iyi görünmesi ve hızlı yüklenmesi önemlidir, çünkü Google mobil uyumluluğu göz önünde bulundurur.

Site Hızı Optimizasyonu: Web sitenizin hızlı yüklenmesi kullanıcı deneyimini artırır ve SEO için olumlu bir faktördür. Resimleri sıkıştırın ve gereksiz eklentilerden kaçının.

SSL Sertifikası: Güvenlik, arama motorları için önemlidir. Bu nedenle web sitenizin SSL sertifikasıyla şifrelenmesi gerekir.

Analiz ve İzleme: SEO çalışmalarınızın etkisini izlemek için araçlar kullanın. Google Analytics ve Google Search Console gibi araçlar, web sitenizin performansını takip etmenize yardımcı olur.

Temel başlıklarıyla arama motoru optimizasyonunun tarihçesinden ve genel anlamda neler yapılabileceğinden bahsettik. Arama motorlarında ön planda yer almak, arama sonuçlarında ilk sayfada yer almak istiyorsanız bir SEO Uzmanı ile çalışabilirsiniz.
                `
            },
        ]

    }
]

export {
    blogDataList
}