
import "./index.scss"
import { useTranslation } from "react-i18next"
import zz from "../../../assets/images/zz.png"
import MainNavbar from "../../components/navbar/MainNavbar";
import FontHead from "../../components/fontHead/FontHead";
import MainFooter from "../../components/mainFooter/MainFooter";
import { blogDataList } from "../blog/blogData";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Button, Skeleton } from "antd";
import kmk1 from "../../../assets/images/kmk1.svg"
import kmk2 from "../../../assets/images/kmk2.svg"
import kmk3 from "../../../assets/images/kmk3.svg"
import kmk4 from "../../../assets/images/kmk4.png"
import kmk5 from "../../../assets/images/kmk5.svg"
import Slider from "react-slick";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../api/axiosInstance";
import BlogBg from "../../../assets/images/blogBg.png"
import OtherCourseSlider from "../../components/OtherCourseSlider/OtherCourseSlider";
import { FaNewspaper } from "react-icons/fa";
import TopBgElement from "../TopBgElement/TopBgElement";
import { Helmet } from "react-helmet-async";


const BlogDetay = () => {

    const [blogList, setBlogList] = useState([])
    const { id } = useParams()
    const [blogData, setBlogData] = useState([])
    const navigate = useNavigate()




    const getBlogById = async () => {
        try {

            const response = await axiosInstance.get(`/blogs/getById/${id}`)
            setBlogData(response.data)

            if (!response.data) {
                toast.error("İçerik bulunamadı", {
                    position: "top-center",
                    autoClose: 2000
                })
                navigate("/blog")
            }
        }
        catch (err) {

            navigate("/blog")
        }
    }

    const getAllBlog = async () => {
        try {
            const response = await axiosInstance.post("/blogs/getAll", {
                page: 0,
                pageSize: 10
            })
            setBlogList(response.data.items)
        }
        catch (err) {

        }
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [id])


    useEffect(() => {
        getAllBlog()
        getBlogById()
    }, [id])

    return (
        <StyledMainContainer>
            <Helmet>
                <meta name="description" content={'KUZEM Eğitim platformumuzda yer alan eğitici yazılar ve içerikleri inceleyin.'} />
                <meta name="keywords" content={'Blog, Eğitim, İçerik, Yazılar, Öğrenme, Eğitim Platformu'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kuzem.org/blog" />
                <meta property="og:image" content={BlogBg} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={BlogBg} mainTitle={"Blog"} subTitle={`${blogData?.title} `} />

            <StyledContainer>
                <div className="guncel_left_section" >
                    <img className="content_img" src={blogData?.imageUrl} alt="" />



                    <div className="guncel_detail">
                        <div className="label_wrapper" >
                            <h2 className="guncel_label">

                                {blogData?.title}

                            </h2>
                            <span className="label_date"  >
                                {new Date(blogData?.createdAt).toLocaleDateString()}
                            </span>
                        </div>

                        {/* <p className='ql-snow'>
                            <div className='ql-editor' dangerouslySetInnerHTML={{ __html: blogData?.description }}>

                            </div>
                        </p> */}
                        <p>
                            {blogData?.description}
                        </p>

                    </div>
                </div>
                <div className="other_texts_container">
                    <p className="other_texts_label">
                        Güncel
                    </p>
                    <div className="other_guncel_wrapper">
                        {
                            blogList?.length !== 0 ? blogList?.map(item => {
                                return (
                                    <>
                                        <p className="other_news_element" onClick={() => {
                                            navigate(`/blog/${item?.id}`)
                                        }} >
                                            <FaNewspaper /> {item?.title?.length > 40 ? `${item?.title?.substring(0, 40)}...` : item.title}
                                        </p>
                                    </>
                                )
                            }) : (
                                <Alert type="info" message="Başka yazı bulunamadı" />
                            )
                        }
                    </div>

                </div>
            </StyledContainer>
            <KomekSliderContainer>
                <OtherCourseSlider />
            </KomekSliderContainer>


            <MainFooter visibility={'hidden'} ></MainFooter>
        </StyledMainContainer>
    )
}


const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    padding: 20px 120px;
    margin-bottom: 100px;
    column-gap: 30px;
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .guncel_left_section{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow-x: hidden;
        .label_wrapper{
            background-color: white;
            border-radius: 5px;
            border-bottom-width:1px;
            border-bottom-style: dashed;
            border-bottom-color: #eee;
            padding-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width:500px) {
                font-size: 16px;
                padding-left: 10px;
            }
            .guncel_label{
                font-size: 18px;
                font-weight: bold;

            }

                .label_date{
                    color: gray;
                    opacity: .3;
                    
                }
        }

       
        img{
            width: 100%;
            height: 350px;
            border-radius: 10px;
            object-fit: cover;


            @media screen  and (max-width:600px) {
                object-fit: contain;
                height: 150px;
            }

        }

        .guncel_detail{
            background-color: white;
            border-radius:10px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            padding-bottom: 40px;
            h5 {
                font-weight: 600;
                font-size: 20px;
            }
            p {
                letter-spacing: 1px;
            }
        }
    }

    .other_texts_container{
        display: flex;
        background-color: white;
        border-radius: 10px;
        flex-direction: column;
        padding-top: 30px;
        padding: 10px;
        height: max-content;

        .other_texts_label{
            text-align: start;
            border-bottom: 1px solid gray;
            font-size: 16px;
            padding-bottom: 5px;
            margin-bottom: 30px;
        }

        .other_guncel_wrapper{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-bottom: 70px;
            .other_guncel_element{
                border: 2px solid #BBB1ED;
                border-radius: 5px;
                padding: 10px;
                font-size: 12px;
                cursor: pointer;
             }



             .other_news_element{
                display: flex;
                align-items: center;
                column-gap: 10px;
                font-size: 14px;
                border-bottom-width: 1px;
                border-bottom-style: dashed;
                border-bottom-color: #eeeeee;
                padding-bottom: 5px;
                cursor: pointer;
             }
        }

  
      
    }
`

const KomekSliderContainer = styled.div`
  padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }


    .slider_item_img{
        height: 80px;
        object-fit: contain;
    }
`


const StyledMainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export default BlogDetay
