import React, { useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import EditBranchDashboard from '../../Components/EditBranchDashboard/EditBranchDashboard'

const EditBranchPage = () => {
    const [title, setTitle] = useState()
    return (
        <div className='page-content' >
            <TablePageContainer  header={title ?? "Branş Düzenle"} visible={false} >
                <EditBranchDashboard setTitle={setTitle} />
            </TablePageContainer>
        </div>
    )
}

export default EditBranchPage