import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CourseDetailDashboard from '../../Components/Course/CourseDetailDashboard/CourseDetailDashboard'
import styled from 'styled-components'

const CourseDetailPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Kurs Detay' header={"Kurs Detay"} visible={false} >
                <StyledContainer>
                    <CourseDetailDashboard />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 10px;

    @media screen  and (max-width:500px) {
        padding: 0;
    }
`

export default CourseDetailPage