import { Alert, Button, Collapse, Tabs, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance';
import { useParams } from 'react-router-dom';
import { FaLock, FaPlay } from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

const CourseDetailPageTab = ({ courseInfo }) => {
    const [previewModal, setPreviewModal] = useState({
        visible: false,
        data: null
    })
    const [courseContent, setCourseContent] = useState()
    const { id } = useParams()

    const courseContentList = useMemo(() => {
        if (courseInfo?.type == "Offline") {
            return courseInfo?.course?.courseContents
        }
        return courseInfo?.branchContents
    }, [courseInfo])


    /*     console.log("courseInfo< =", courseContentList)
     */
    const courseImageDatas = useMemo(() => {
        if (courseInfo?.type == "Offline") {
            return courseInfo?.course?.courseImages
        }
    }, [courseInfo])


    // console.log("detailCourseIN", courseInfo)

    const courseType = useMemo(() => {
        return courseInfo?.type
    }, [courseInfo])

    const getDesc = (description) => {
        return decodeURI(description)
    }

    const courseTypeTabs = useMemo(() => {
        if (courseInfo?.type == "Online") {
            return [
                {
                    key: '1',
                    label: 'Açıklama',
                    children: (
                        <VideoTabDescriptionElement style={{ backgroundColor: "#F6F6F6" }}>
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {courseInfo?.description}
                            </p>
                        </VideoTabDescriptionElement>
                    ),
                },
                {
                    key: '2',
                    label: 'SSS',
                    children: (
                        <Collapse expandIcon={({ isActive }) => null}  >

                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem nedir?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >

                                <p>
                                    KUZEM, Konya Büyükşehir Belediyesi'nin yetişkinlere yönelik sunduğu uzaktan eğitim hizmetidir. Bu platform, çeşitli alanlarda yetişkinlere yönelik eğitimler sunarak kişisel ve mesleki gelişimi desteklemeyi amaçlar. Eğitimler, katılımcıların zaman ve mekân kısıtlaması olmadan öğrenmelerini sağlar ve dijital ortamda erişim imkânı sunar..
                                </p>

                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’den kimler faydalanabilir?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    KUZEM eğitimlerinden, Konya Büyükşehir Belediyesi'nin sunduğu bu hizmete ilgi duyan tüm yetişkinler faydalanabilir. Mesleki gelişim, kişisel gelişim ya da hobi alanlarında kendini geliştirmek isteyen herkes eğitim durumu fark etmeksizin bu eğitimlere katılabilir
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kayıt için yaş sınırı var mı?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Eğitimlerimize 16-64 yaş arası herkes katılabilir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kurslara devam zorunluluğu var mı?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kuzem kurslarında devam zorunluluğu vardır. Devamsızlık sınırını aşan öğrencilerimiz kurslarımıza 1 yıl kayıt yaptıramazlar.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Devamsızlık hakkı ne kadar?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    KUZEM eğitimlerinde her kurs için %20 devamsızlık hakkı tanınmaktadır. Bu, katılımcıların toplam ders süresinin en fazla %20’sine devamsızlık yapabilecekleri anlamına gelir
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem eğitimleri ücretsiz mi?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Katılımcılar kurslara ücretsiz kayıt olup faydalanabilirler.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem kurslarına nasıl kayıt olabilirim?


                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Eğitimlere katılmak isteyen kursiyerler kuzem.org adresinden hesap oluşturup T.C ve parola ile keşfet kısmından kurslara ücretsiz kayıt olabilirler.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem kurslarında belge veriliyor mu?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursu başarı ile tamamlayan kursiyere e-devlet onaylı katılım belgesi verilmektedir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Bir dönemde kurslardan kaç tanesine kayıt yaptırabilirim?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursiyer bir dönemde en fazla 2 kursa kayıt yaptırabilir.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’de nelerden faydalanacağım?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursa kayıt yaptıran öğrencilerimiz canlı eğitimlerle birlikte video eğitimlerden de faydalanacaktır.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kayıt yaptırdığım kursun saat ve tarihlerini nereden göreceğim?


                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>

                                    Kaydını oluşturan öğrenciler kuzem.org adresinden giriş yaptıktan sonra kurslarım kısmından kayıt olunan kursun tarih ve saatlerini görecektir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Yeni dönem için ayrıca kayıt yaptırmama gerek var mı?



                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Güz dönemine kayıt yaptıran kursiyerlerimiz bahar dönemi için belirtilen kayıt tarihlerinde istedikleri kursa yeniden kayıt yaptırmaları gerekmektedir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’de canlı dersler mi yoksa kaydedilmiş dersler mi var?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursiyerlerimiz canlı derslere katılım sağlayarak eğitim alacaktır. Ayrıca canlı derslere ek olarak video eğitimlerden de faydalanabilirler.


                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Hangi konularda eğitim veriliyor?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kuzem’de; Bilişim Teknolojileri, Yabancı Dil, Kişisel Gelişim, Sanat ve Tasarım, El Sanatları gibi kişisel ve mesleki gelişimi destekleyen birçok konuda eğitim verilmektedir.
                                </p>

                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Teknik sorunlar ya da sorular için destek hattı var mı?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    444 55 42 numaralı telefondan ve dahili 4093 hattından iletişim sağlayabilirsiniz
                                </p>
                            </Collapse.Panel>
                        </Collapse>
                    )
                },
                {
                    key: "3",
                    label: "İçerik",
                    children: courseContentList?.length != 0 ? (
                        <StyledOnlineContentWrapper>
                            <Collapse expandIcon={({ isActive }) => null} >
                                {
                                    courseContentList?.map(el => {
                                        return (
                                            <Collapse.Panel key={`${el?.id}`} header={(
                                                <AccHeaderElement>
                                                    <p style={{
                                                        textTransform: "capitalize"
                                                    }} >
                                                        {el?.title}
                                                    </p>
                                                    <SlArrowDown />
                                                </AccHeaderElement>
                                            )}  >
                                                <p>
                                                    {el?.content}
                                                </p>


                                            </Collapse.Panel>
                                        )
                                    })
                                }
                            </Collapse>
                            {
                                courseContentList?.map((item, index) => {
                                    return (
                                        <p>
                                            { }
                                        </p>
                                    )
                                })
                            }
                        </StyledOnlineContentWrapper>
                    ) : (
                        <div style={{ width: "100%" }} >
                            <Alert type='info' message="Henüz içerik eklenmedi" />
                        </div >
                    )
                }


            ];
        }
        else {
            return [
                {
                    key: '1',
                    label: 'Açıklama',
                    children: (
                        <VideoTabDescriptionElement style={{ backgroundColor: "#F6F6F6" }}>
                            <p style={{
                                whiteSpace: "pre-line"
                            }} >
                                {courseInfo?.course?.description}
                            </p>
                        </VideoTabDescriptionElement>
                    ),
                },
                {
                    key: '2',
                    label: 'SSS',
                    children: (
                        <Collapse expandIcon={({ isActive }) => null} >

                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem nedir?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >

                                <p>
                                    KUZEM, Konya Büyükşehir Belediyesi'nin yetişkinlere yönelik sunduğu uzaktan eğitim hizmetidir. Bu platform, çeşitli alanlarda yetişkinlere yönelik eğitimler sunarak kişisel ve mesleki gelişimi desteklemeyi amaçlar. Eğitimler, katılımcıların zaman ve mekân kısıtlaması olmadan öğrenmelerini sağlar ve dijital ortamda erişim imkânı sunar..
                                </p>

                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’den kimler faydalanabilir?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    KUZEM eğitimlerinden, Konya Büyükşehir Belediyesi'nin sunduğu bu hizmete ilgi duyan tüm yetişkinler faydalanabilir. Mesleki gelişim, kişisel gelişim ya da hobi alanlarında kendini geliştirmek isteyen herkes eğitim durumu fark etmeksizin bu eğitimlere katılabilir
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kayıt için yaş sınırı var mı?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Eğitimlerimize 16-64 yaş arası herkes katılabilir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kurslara devam zorunluluğu var mı?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kuzem kurslarında devam zorunluluğu vardır. Devamsızlık sınırını aşan öğrencilerimiz kurslarımıza 1 yıl kayıt yaptıramazlar.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Devamsızlık hakkı ne kadar?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    KUZEM eğitimlerinde her kurs için %20 devamsızlık hakkı tanınmaktadır. Bu, katılımcıların toplam ders süresinin en fazla %20’sine devamsızlık yapabilecekleri anlamına gelir
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem eğitimleri ücretsiz mi?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Katılımcılar kurslara ücretsiz kayıt olup faydalanabilirler.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem kurslarına nasıl kayıt olabilirim?


                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Eğitimlere katılmak isteyen kursiyerler kuzem.org adresinden hesap oluşturup T.C ve parola ile keşfet kısmından kurslara ücretsiz kayıt olabilirler.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem kurslarında belge veriliyor mu?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursu başarı ile tamamlayan kursiyere e-devlet onaylı katılım belgesi verilmektedir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Bir dönemde kurslardan kaç tanesine kayıt yaptırabilirim?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursiyer bir dönemde en fazla 2 kursa kayıt yaptırabilir.

                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’de nelerden faydalanacağım?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursa kayıt yaptıran öğrencilerimiz canlı eğitimlerle birlikte video eğitimlerden de faydalanacaktır.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kayıt yaptırdığım kursun saat ve tarihlerini nereden göreceğim?


                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>

                                    Kaydını oluşturan öğrenciler kuzem.org adresinden giriş yaptıktan sonra kurslarım kısmından kayıt olunan kursun tarih ve saatlerini görecektir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Yeni dönem için ayrıca kayıt yaptırmama gerek var mı?



                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Güz dönemine kayıt yaptıran kursiyerlerimiz bahar dönemi için belirtilen kayıt tarihlerinde istedikleri kursa yeniden kayıt yaptırmaları gerekmektedir.
                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Kuzem’de canlı dersler mi yoksa kaydedilmiş dersler mi var?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kursiyerlerimiz canlı derslere katılım sağlayarak eğitim alacaktır. Ayrıca canlı derslere ek olarak video eğitimlerden de faydalanabilirler.


                                </p>
                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Hangi konularda eğitim veriliyor?
                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    Kuzem’de; Bilişim Teknolojileri, Yabancı Dil, Kişisel Gelişim, Sanat ve Tasarım, El Sanatları gibi kişisel ve mesleki gelişimi destekleyen birçok konuda eğitim verilmektedir.
                                </p>

                            </Collapse.Panel>
                            <Collapse.Panel header={
                                <AccHeaderElement>
                                    <p>
                                        Teknik sorunlar ya da sorular için destek hattı var mı?

                                    </p>
                                    <SlArrowDown />
                                </AccHeaderElement>
                            } >
                                <p>
                                    444 55 42 numaralı telefondan ve dahili 4093 hattından iletişim sağlayabilirsiniz
                                </p>
                            </Collapse.Panel>
                        </Collapse>
                    )
                },
                {
                    key: "3",
                    label: "İçerik",
                    children: courseContent?.length == 0 ? (
                        <div style={{ width: "100%" }}>
                            <Alert type='info' message="Henüz içerik eklenmedi" />
                        </div>
                    ) : (
                        <Collapse expandIcon={({ isActive }) => null} >
                            {
                                courseContentList?.map(el => {
                                    return (
                                        <Collapse.Panel header={(
                                            <AccHeaderElement>
                                                <p style={{
                                                    textTransform: "capitalize"
                                                }} >
                                                    {el.name}
                                                </p>
                                                <SlArrowDown />
                                            </AccHeaderElement>
                                        )}  >
                                            {
                                                el.courseFiles.length != 0 ? (
                                                    <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
                                                        {el?.courseFiles.map((item, index) => {

                                                            if (item.preview) {
                                                                return (
                                                                    <OfflinePreviewContent style={{ cursor: "pointer" }} onClick={() => {
                                                                        setPreviewModal({
                                                                            visible: true,
                                                                            data: item
                                                                        })
                                                                    }} >
                                                                        <FaPlay />
                                                                        <p>
                                                                            {item.description.length > 40 ? `${item.description?.substring(0, 40)}...` : item.description}
                                                                        </p>
                                                                        <p>
                                                                            (Önizleme)
                                                                        </p>
                                                                    </OfflinePreviewContent>
                                                                )
                                                            }
                                                            else {



                                                                return (
                                                                    <Tooltip placement='topRight' title={item?.url == "Erişebilmeniz için kayıt olmanız gerekmektedir." || item?.url == "Lütfen Giriş Yapınız." ? item?.url : null} >
                                                                        <OfflinePreviewContent>
                                                                            <FaPlay />
                                                                            <p style={{ display: "flex", alignItems: "center", columnGap: "5px", justifyContent: "space-between", width: "100%" }} >
                                                                                {item.description.length > 40 ? `${item.description?.substring(0, 40)}...` : item.description}
                                                                                {
                                                                                    item?.url == "Lütfen Giriş Yapınız." && <FaLock />
                                                                                }
                                                                            </p>

                                                                        </OfflinePreviewContent>

                                                                    </Tooltip>

                                                                )
                                                            }


                                                        })}
                                                    </div>
                                                ) : (
                                                    <Alert type='info' message="Görüntülemek için kayıt olunuz" />
                                                )
                                            }


                                        </Collapse.Panel>
                                    )
                                })
                            }
                        </Collapse>
                    )
                },
                {
                    key: "4",
                    label: "Fotoğraflar",
                    children: <div style={{ width: "100%" }} >
                        {
                            courseImageDatas?.length == 0 ? (
                                <Alert type='info' message="Fotoğraf eklenmemiş" />
                            ) : (
                                <Swiper
                                    style={{ maxWidth: "100%" }}
                                    className="mySwiper"
                                    pagination={true}
                                    modules={[Pagination]}
                                    breakpoints={{
                                        // Mobilde tek slide göster
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        // Tablet ve üstü ekranlarda 2 slide göster
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        // Daha büyük ekranlarda 4 slide göster
                                        1024: {
                                            slidesPerView: 3,
                                        },

                                    }}
                                    slidesPerView={3}
                                >
                                    {
                                        courseImageDatas?.map((el, index) => {
                                            return (
                                                <SwiperSlide >

                                                    <StyledImageWrapper>
                                                        <StyledImg src={el?.url} />
                                                    </StyledImageWrapper>

                                                </SwiperSlide>


                                            )
                                        })
                                    }
                                </Swiper>
                            )
                        }
                    </div>

                }


            ];
        }

    }, [courseInfo])


    const items = [
        {
            key: '1',
            label: 'Açıklama',
            children: (
                <VideoTabDescriptionElement style={{ backgroundColor: "#F6F6F6" }}>
                    {courseInfo?.description}
                </VideoTabDescriptionElement>
            ),
        },
        {
            key: '2',
            label: 'SSS',
            children: (
                <Collapse expandIcon={({ isActive }) => null} defaultActiveKey={['0', "1"]} >

                    <Collapse.Panel header={
                        <AccHeaderElement>
                            <p>
                                Kurs Kimler için
                            </p>
                            <SlArrowDown />
                        </AccHeaderElement>
                    } >

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias quod numquam accusamus esse ratione illum minima impedit! Dolorum voluptatum, esse aspernatur ad iste et rerum vero pariatur, molestiae inventore consequuntur?

                    </Collapse.Panel>
                    <Collapse.Panel header={
                        <AccHeaderElement>
                            <p>

                                Eğitim, hangi sektörlerde iş imkanına sahip olacağım?
                            </p>
                            <SlArrowDown />
                        </AccHeaderElement>
                    } >

                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias quod numquam accusamus esse ratione illum minima impedit! Dolorum voluptatum, esse aspernatur ad iste et rerum vero pariatur, molestiae inventore consequuntur?

                    </Collapse.Panel>

                </Collapse>
            )
        },
        /* {
            key: "3",
            label: "içerik",
            children: courseContent?.length == 0 ? (
                <div style={{ width: "100%" }}>
                    <Alert type='info' message="Henüz içerik eklenmedi"  />
                </div>
    
            ) : courseType == "Online" ? (
                <Collapse expandIcon={({ isActive }) => null} >
                    {
                        courseContent?.map(el => {
                            return (
                                <Collapse.Panel header={(
                                    <AccHeaderElement>
                                        <p>
                                            {el.name}
                                        </p>
                                        <SlArrowDown />
                                    </AccHeaderElement>
                                )}  >
                                    {
                                        el?.onlineCourseContentDetails?.map(item => {
                                            return (
                                                <p style={{ fontWeight: "700", textTransform: "capitalize" }} >
                                                    - {item?.description}
                                                </p>
                                            )
                                        })
                                    }
                                </Collapse.Panel>
                            )
                        })
                    }
                </Collapse>
    
            ) : (
                <Collapse expandIcon={({ isActive }) => null} >
                    {
                        courseContent?.map(el => {
                            return (
                                <Collapse.Panel header={(
                                    <AccHeaderElement>
                                        <p>
                                            {el.name}
                                        </p>
                                        <SlArrowDown />
                                    </AccHeaderElement>
                                )}  >
                                    {
                                        el.courseFiles.length != 0 ? (
                                            <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }} >
                                                {el?.courseFiles.map((item, index) => {
                                                    if (item.preview) {
                                                        return (
                                                            <OfflinePreviewContent style={{ cursor: "pointer" }} onClick={() => {
                                                                setPreviewModal({
                                                                    visible: true,
                                                                    data: item
                                                                })
                                                            }} >
                                                                <FaPlay />
                                                                <p>
                                                                    {item.description.length > 40 ? `${item.description?.substring(0, 40)}...` : item.description}
                                                                </p>
                                                                <p>
                                                                    (Önizleme)
                                                                </p>
                                                            </OfflinePreviewContent>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <OfflinePreviewContent>
                                                                <FaPlay />
                                                                <p>
                                                                    {item.description.length > 40 ? `${item.description?.substring(0, 40)}...` : item.description}
                                                                </p>
    
                                                            </OfflinePreviewContent>
                                                        )
                                                    }
    
    
                                                })}
                                            </div>
                                        ) : (
                                            <Alert type='info' message="İçerik Yok" />
                                        )
                                    }
    
    
                                </Collapse.Panel>
                            )
                        })
                    }
                </Collapse>
    
            )
        }, */
        /*  {
             key: "4",
             label: "Fotoğraflar",
             children: (
                 <div style={{ width: "100%" }} >
                     {
                         courseImageList?.length == 0 ? (
                             <Alert type='info' message="Fotoğraf eklenmemiş" />
                         ) : (
                             <Swiper
                                 style={{ maxWidth: "100%" }}
                                 className="mySwiper"
                                 pagination={true}
                                 modules={[Pagination]}
                                 breakpoints={{
                                     // Mobilde tek slide göster
                                     0: {
                                         slidesPerView: 1,
                                     },
                                     // Tablet ve üstü ekranlarda 2 slide göster
                                     768: {
                                         slidesPerView: 2,
                                     },
                                     // Daha büyük ekranlarda 4 slide göster
                                     1024: {
                                         slidesPerView: 3,
                                     },
     
                                 }}
                                 slidesPerView={3}
                             >
                                 {
                                     courseImageList?.map((el, index) => {
                                         return (
                                             <SwiperSlide >
     
                                                 <StyledImageWrapper>
                                                     <StyledImg src={el?.url} />
                                                 </StyledImageWrapper>
     
                                             </SwiperSlide>
     
     
                                         )
                                     })
                                 }
                             </Swiper>
                         )
                     }
                 </div>
             )
         } */

    ];


    useEffect(() => {
        /*  getCourseContent() */
    }, [])

    return (
        <VideoPageTabContainer>
            <Tabs defaultActiveKey="1" items={courseTypeTabs} onChange={() => {

            }} />
            <Modal isOpen={previewModal.visible} centered>
                <StyledHeader >
                    <p  >
                        {previewModal.data?.description}
                    </p>
                </StyledHeader>
                <ModalBody>
                    <video controls controlsList='nodownload' style={{ maxWidth: "100%", width: "100%", maxHeight: "400px", borderRadius: "10px" }}  >
                        <source src={previewModal?.data?.url} type='video/mp4' />
                    </video>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        setPreviewModal({
                            visible: false,
                            data: null
                        })
                    }} danger type='primary' >
                        Kapat
                    </Button>
                </ModalFooter>
            </Modal>
        </VideoPageTabContainer>
    )
}

export default CourseDetailPageTab


const VideoPageTabContainer = styled.div`
    background-color: white;
    padding: 20px;

    @media screen and (max-width:600px) {
        padding: 10px;
    }
`

const VideoTabDescriptionElement = styled.div`
    background-color: #F6F6F6;
    padding: 20px;
    border-radius: 10px;
`

const AccHeaderElement = styled.div`
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CollapseVideoContentElement = styled.div`
    background-color: ${props => props.preview ? "#41B3A2" : "aliceblue"};
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #809CC4;
    border-radius: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: ${props => props.preview ? "pointer" : ""};
    font-size: 16px;
`

const StyledHeader = styled(ModalHeader)`

    h5{
        width: 100%;
        border: 1px solid gray;
        border-radius: 5px;
        padding: 5px;
    }
`

const OfflinePreviewContent = styled.div`
    display: flex;
    background-color: #E6F4FF;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    column-gap: 10px;
`


const StyledImageWrapper = styled.div`
    height: 170px;
    width: 100%;
`
const StyledImg = styled.img`
    height: 90%;
    width: 95%;
    border-radius: 15px;
    object-fit: cover;
`

const StyledOnlineContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`