import React, { useContext, useMemo } from "react";
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DashboardContext } from "./Dashboard";
import styled from "styled-components";

const CustomDataLabel = ({ dataColors }) => {
    /*  var chartDatalabelsBarColors = getChartColorsArray(dataColors); */

    const { dashboardUserData } = useContext(DashboardContext)

    const offlineBarData = useMemo(() => {
        return dashboardUserData?.offlineCourseMonitoringChartDto
    }, [dashboardUserData])

    const series = [{
        data: offlineBarData ? Object.values(offlineBarData).map(el => el) : []
    }];
    var options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                distributed: true,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                },
            }
        },
        colors: ['#8459EB', '#6d4abe', '#3f2c6b', '#4d2f95', '#6b32f0', '#4c22ad', '#2f1371'],
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#fff']
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
            },
            offsetX: 0,
            dropShadow: {
                enabled: false
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: ['Pzrts', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cmrts', 'Pazar',

            ],
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            theme: 'dark',
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function () {
                        return '';
                    }
                }
            }
        }
    };
    return (
        <React.Fragment>

            <Card style={{ border: "none" }} >
                <CardHeader className="align-items-center d-flex mt-3" style={{border:"none"}} >
                    <h4 className="card-title mb-0 flex-grow-1"> <b>Kurs İzlenmesi</b>  </h4>

                </CardHeader>
            </Card>
            <StyledApex dir="ltr"
                className="apex-charts"
                options={options}
                series={series}
                type="bar"
                height={350}

            />
        </React.Fragment>
    );
};


const StyledApex=styled(ReactApexChart)`
    .apexcharts-align-center{
        justify-content: space-between;
    }
    .apx-legend-position-bottom{
        justify-content: space-between !important;
    }
`

export default CustomDataLabel