import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import EditStudentInfoTab from './EditStudentInfoTab'
import StudentTakeCourse from './StudentTakeCourse'
import { useFormik } from 'formik'
import * as yup from "yup"
import { getUserByIdApi, updateUserApi } from '../../../api/UserApi'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import StudenStatusTab from './StudenStatusTab'

const UpdateStudentForm = ({ setTitle }) => {
    const [activeTab, setActiveTab] = useState(1)
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            birthDate: "",
            email: "",
            gender: "erkek",
            firstName: "",
            phone: "",
            lastName: "",
            tc: "",
            address: ""
        },
        validationSchema: yup.object({
            email: yup.string().email("email formatı yanlış").required("email gereklidir"),
            firstName: yup.string().required("isim gereklidir"),
            lastName: yup.string().required("soyisim gereklidir"),
            phone: yup.string()
                .matches(/^(\d{10})$/, "Geçerli bir Türkiye telefon numarası girin") // Türkiye telefon numarası formatı (Başında 0 ve 10 rakam)
                .required("Telefon numarası boş bırakılamaz"),
            address: yup.string().required("bu alan boş bırakılamaz").min(21),

        }),
        onSubmit: async (value) => {
            AntModal.confirm({
                title: "Güncellemek istediğinizden emin misiniz?",
                okText: "Eminim",
                cancelText: "İptal",
                onOk: async () => {

                    try {
                        const { birthDate, id, address, phone, email, ...rest } = value

                        const reqBody = {
                            id,
                            address: address,
                            phone,
                            email
                        }

                        let response = await updateUserApi(reqBody)
                        toast.success("güncelleme başarılı", {
                            autoClose: 1000
                        })
                    }
                    catch (err) {

                        toast.error(err.response.data.message, {
                            autoClose: 1500
                        })
                    }

                }
            })

        }
    })




    const setStudentProfileData = async (data) => {
        try {

            const { updatedAt, createdAt, roleName, birthDate, ...rest } = data
            Object.entries(rest).map(([key, val]) => {
                if (key == "address") {
                    formik.setFieldValue(key, val ?? "")
                }
                else {
                    formik.setFieldValue(key, val)
                }

            })
            const formatBirthDate = birthDate.split("T")[0]

            formik.setFieldValue('birthDate', formatBirthDate);
        }
        catch (err) {
            toast.error(err.message)
        }
    }



    const apiRequest = async () => {
        try {
            setLoading(true)
            const responseStudent = await getUserByIdApi(id)

            setStudentProfileData(responseStudent.data)
            setTitle(`${responseStudent.data.firstName} ${responseStudent.data.lastName} `)
        }
        catch (err) {
            toast.error(err.response.data.message, {
                autoClose: 1000
            })
            navigate("/panel/ogrenciler")
        }
        finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        apiRequest()
    }, [id])

    return (
        <>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => setActiveTab(1)} >
                        Bilgiler
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => { setActiveTab(2) }} >
                        Aldığı Kurslar
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={activeTab == 3 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : {
                            cursor: "pointer"
                        }}
                        onClick={() => { setActiveTab(3) }} >
                        Durum Bilgisi
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} style={{ paddingTop: "20px" }}>
                <TabPane tabId={1}  >
                    <EditStudentInfoTab setTitle={setTitle} formik={formik} loading={loading} />
                </TabPane>
                <TabPane tabId={2} >
                    <StudentTakeCourse />
                </TabPane>
                <TabPane tabId={3} >
                    <StudenStatusTab formik={formik} refreshMethod={() => {
                        apiRequest()
                    }} />
                </TabPane>
            </TabContent>
        </>
    )
}

export default UpdateStudentForm