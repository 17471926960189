import React, { useContext, useEffect, useMemo, useState } from 'react'
import { axiosInstance } from '../../../api/axiosInstance'
import styled from 'styled-components'
import { courseContentForCourseId } from '../../../api/CourseContent'
import { useNavigate, useParams } from 'react-router-dom'
import CourseVideoPageHeader from './CourseVideoPageHeader'
import CourseVideoBreadCrumb from './CourseVideoBreadCrumb'
import CourseVideoLeftContent from './CourseVideoLeftContent'
import CourseVideoRightContent from './CourseVideoRightContent'
import VideoPageFooter from '../VideoPage3/VideoPageFooter'
import CourseVideoFooter from './CourseVideoFooter'
import SameCourseVideo from './SameCourseVideo'
import { Skeleton } from 'antd'
import { UserContext } from '../../../context/user'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

const CourseVideoDashboard = () => {
    const [courseGeneralData, setCourseGeneralData] = useState()

    const { id } = useParams()
    const encodedId = encodeURIComponent(id);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [otherCourseList, setOtherCourseList] = useState([])
    const token = localStorage.getItem("authToken")
    const refreshToken = localStorage.getItem("refreshToken");
    const [context, dispatch] = useContext(UserContext)
    const baseURL = "https://api-kuzem.konya.bel.tr/api";
    /*     const token = localStorage.getItem("authToken")
        const [context, dispatch] = useContext(UserContext) */
    /*  const getCourseByıd = async () => {
         try {
             const response = await axiosInstance.get(`/courses/getById/${id}`)
 
             setCourseGeneralData(response.data)
         }
         catch (err) {
         }
     } */

    const getCourseByLabel = async () => {
        try {
            setLoading(true)

            const response = await axiosInstance.get(`/branches/getByLabel/${encodedId}`)

            const responsev2 = await axiosInstance.post(`/branches/GetAll?type=${response.data?.type ?? "Offline"}`, {
                page: 0,
                pageSize: 50
            });


            if (response.data?.course?.message == "Lütfen giriş yapınız.") {

                if (token && refreshToken) {
                    await axios.post(`${baseURL}/auth/CreateAccessTokenWithRefreshToken`, refreshToken, {
                        headers: {
                            "Accept": "*/*",
                            "Content-Type": "application/json-patch+json"
                        }
                    }).then(val => {
                        const { data } = val
                        localStorage.setItem("authToken", data?.accessToken.token);
                        localStorage.setItem("refreshToken", `"${data?.refreshToken?.token}"`);
                        window.location.reload()
                    }).catch(err => {
                        localStorage.removeItem("authToken")
                        localStorage.removeItem("refreshToken")
                    })

                }

            }



            setCourseGeneralData(response.data)


            if (response.data?.type == "Online") {
                setOtherCourseList(responsev2.data.items.filter(item => item?.label != encodedId))
            }
            else {

                setOtherCourseList(responsev2.data.items.filter(item => item?.label != encodedId)?.filter(el => {
                    return el?.coursePreviewStatus
                }))
            }


        }
        catch (err) {
            navigate(-1)
        }
        finally {
            setLoading(false)
        }
    }


    const statusControl = useMemo(() => {
        if (courseGeneralData?.type == "Offline") {
            return {
                message: courseGeneralData?.course?.message,
                success: courseGeneralData?.course?.success
            }
        }
        else {
            if (token && context?.isLoggedIn) {
                return {
                    message: "",
                    success: true
                }
            }
            else {
                return {
                    message: "Lütfen giriş yapınız.",
                    success: false
                }

            }

        }
    }, [courseGeneralData])



    const otherCourseListLength = useMemo(() => {
        return otherCourseList?.length
    }, [otherCourseList])



    useEffect(() => {
        /*  getCourseVideo() */
        /*  getCourseByıd() */
        getCourseByLabel()
    }, [id])



    return (
        <VideoPageContainer>
            <Helmet>
                <title>Eğitimler</title>
                <meta name="description" content={"KUZEM eğitim platformu Eğitimler sayfamızda sunulan eğitim programları hakkında bilgi edinebilirsiniz."} />
                <meta name="keywords" content={"Eğitimler,Canlı Eğitim, Video Eğitim, Kurslar, Programlar, Öğrenme"} />
            </Helmet>
            <CourseVideoPageHeader statusControl={statusControl} courseInfo={courseGeneralData} />
            {
                loading ? (
                    <Skeleton />
                ) : (
                    <>
                        <CourseVideoBreadCrumb courseInfo={courseGeneralData} />
                        <VideoPageMainContentContainer>
                            <CourseVideoLeftContent courseInfo={courseGeneralData} />
                            <CourseVideoRightContent courseInfo={courseGeneralData} statusControl={statusControl} fetchLabelRequest={getCourseByLabel} />
                        </VideoPageMainContentContainer>
                        <SameCourseVideo otherCourseList={otherCourseList} courseType={courseGeneralData?.type} />
                    </>
                )
            }
            {
                !loading && (
                    <CourseVideoFooter otherCourseListLength={otherCourseListLength} />
                )
            }

        </VideoPageContainer>
    )
}

export default CourseVideoDashboard


export const VideoPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F6F6F6;
    min-height: 100vh;
`


export const VideoPageMainContentContainer = styled.div`
    /* display: grid;
    grid-template-columns: 5fr 2fr; */
    display: flex;
    padding: 0 20px;
    column-gap: 20px;
    

    @media screen and (max-width:1100px)  {
        flex-direction: column;
        padding: 0 10px;
    }


    @media screen and (max-width:600px)  {
        flex-direction: column;
        padding: 0 10px;
    }
`