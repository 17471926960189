import React from 'react'
import AddBlogDashboard from '../../Components/Blog/AddBlogDashboard'
import TablePageContainer from '../../Components/Common/TablePageContainer'

const AddBlogData = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Blog Ekle"}  >
                <AddBlogDashboard />
            </TablePageContainer>
        </div>
    )
}

export default AddBlogData