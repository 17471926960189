import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import StudentCourseMainPage from '../../Components/Course/StudentCourse/StudentCourseMainPage'
import styled from 'styled-components'

const StudentCourseDashboard = () => {
  return (
    <div className='page-content' >
      <TablePageContainer title='Kurslarım' header={"Kurslarım"} visible={false} >
        <StyledContainer  >
          <StudentCourseMainPage />
        </StyledContainer>

      </TablePageContainer>
    </div>
  )
}

const StyledContainer = styled.div`
  padding: 20px;

  @media screen and (max-width:600px) {
      padding: 5px;
  }
`


export default StudentCourseDashboard