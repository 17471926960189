import React, { useEffect, useMemo, useState } from 'react'
import { GetCalendarForAllRoles } from '../../api/Course'
import styled from 'styled-components'
import { Alert, Col, Row, Select, Skeleton, Table } from 'antd'
import { axiosInstance } from '../../api/axiosInstance'
import { formatInTimeZone } from 'date-fns-tz'
import trLocale from "date-fns/locale/tr"


const OgrenciDevamsızlıkDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [courseData, setCourseData] = useState([])
    const [absenceData, setAbsenceData] = useState()
    const [selectedCourse, setSelectedCourse] = useState("")
    const getStudentCourse = async () => {
        try {
            setLoading(true)
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 10
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })


            setCourseData(responsev2.data.items)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }

    }

    const myCourses = useMemo(() => {
        return courseData?.filter(item => item?.type == "Online")
    }, [courseData])


    const getDetailAbsence = async (id) => {
        try {
            console.log("id", id)
            setLoading(true)
            const response = await axiosInstance.get(`/Absences/GetStudentResultByCourseId/${id}`)

            setAbsenceData(response.data)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const contentData = useMemo(() => {
        if (loading) {
            return (
                <Skeleton active />
            )
        }
        else if (selectedCourse == "") {
            return (
                <Alert message="Henüz kurs seçilmedi lütfen kurs seçiniz" style={{
                    textAlign: "center"
                }} />
            )
        }
        else {
            return (
                <Row style={{ width: "100%" }} gutter={[
                    {
                        md: 6,
                        xs: 4
                    },
                    {
                        md: 8,
                        xs: 4
                    }
                ]} >
                    <Col md={24} xs={24}  >
                        <Alert type='warning' message={`
                        Devamsızlık hakkı toplam yapılan eğitim süresinin  %20'si kadardır.
                        `} style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "14px"
                            }} />
                    </Col>
                    <Col md={12} xs={24}  >
                        <StyledItemElement>
                            <p className='element_title'>
                                Toplam Yapılan Eğitim Süresi
                            </p>
                            <p className='element_value'>
                                {absenceData?.totalEducationDuration} (Dk)
                            </p>
                        </StyledItemElement>
                    </Col>
                    <Col md={12} xs={24} >
                        <StyledItemElement>
                            <p className='element_title'>
                                Devamsızlık Hakkı (%20)
                            </p>
                            <p className='element_value'>
                                {absenceData?.absenceDuration} (Dk)
                            </p>
                        </StyledItemElement>
                    </Col>
                    <Col md={12} xs={24} >
                        <StyledItemElement>
                            <p className='element_title'>
                                Yapılan Eğitime Girilmeyen Toplam Süre (Devamsızlık Süreniz)
                            </p>
                            <p className='element_value'>
                                {absenceData?.notExistLessonTotalDuration?.toFixed(2)} (Dk)
                            </p>
                        </StyledItemElement>
                    </Col>

                    <Col md={12} xs={24} >
                        <StyledItemElement>
                            <p className='element_title'>
                                Yapılan Eğitime Girilen Toplam Süre
                            </p>
                            <p className='element_value'>
                                {absenceData?.totalActiveTime?.toFixed(2)} (Dk)
                            </p>
                        </StyledItemElement>
                    </Col>
                    <Col md={24} xs={24} >
                        <Alert type='warning' message={absenceData?.message} style={{
                            textAlign: "center",
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "18px"
                        }} />
                    </Col>

                    <Col md={24} xs={24} >
                        <StyledTableContainer >
                            <p className='header_element'  >
                                Girilen Canlı Eğitim Detayları
                            </p>
                            <Table
                                scroll={{
                                    x: 500
                                }}
                                pagination={{
                                    pageSize: 5,
                                    showSizeChanger: false
                                }}
                                bordered
                                dataSource={absenceData?.absences ?? []}
                                columns={[
                                    {
                                        title: "Canlı Eğitim Kodu",
                                        render: (row, record) => {
                                            return (
                                                <p>
                                                    #{record?.courseCalendarId}
                                                </p>
                                            )
                                        }
                                    },
                                    {
                                        title: "Eğitime Giriş Zamanı",


                                        render: (row, record) => {
                                            let formatDate = formatInTimeZone(record?.registeredOn, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
                                            return (
                                                <p>
                                                    {formatDate}
                                                </p>
                                            )
                                        }
                                    },
                                    {
                                        title: "Eğitimden Ayrılış Zamanı",

                                        render: (row, record) => {
                                            let formatDate = formatInTimeZone(record?.leftOn, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
                                            return (
                                                <p>
                                                    {formatDate}
                                                </p>
                                            )
                                        }
                                    },
                                    {
                                        title: "Eğitimde Geçirilen Süre",
                                        render: (row, record) => {
                                            return (
                                                <p>
                                                    {record?.userActiveTime?.toFixed(3)} (Dk)
                                                </p>
                                            )
                                        }
                                    },

                                ]}
                            />
                        </StyledTableContainer>
                    </Col>
                </Row>
            )
        }

    }, [loading, selectedCourse, absenceData])


    useEffect(() => {
        getStudentCourse()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <Row style={{
                    width: "100%",

                }}>
                    <Col md={24} xs={24} style={{

                    }} >
                        <div className='filter_element'>
                            <p>
                                Kurslarım :
                            </p>
                            <StyledSelect
                                onChange={(e) => {
                                    if (e != "") {
                                        getDetailAbsence(e)
                                    }
                                    setSelectedCourse(e)
                                }}
                                placeholder="Kurs seçiniz"
                                options={[
                                    {
                                        label: "Kurs seçiniz",
                                        value: ""
                                    },
                                    ...myCourses.map(item => {
                                        return {
                                            label: item?.name,
                                            value: item?.id
                                        }
                                    })
                                ]}
                            />
                        </div>

                    </Col>

                </Row>
            </StyledHeader>
            <StyledBody>
                {contentData}

            </StyledBody>
        </StyledContainer >
    )
}

export default OgrenciDevamsızlıkDashboard


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    row-gap: 30px;

    @media screen and (max-width:600px) {
        padding: 5px;
    }
`

const StyledHeader = styled.div`
    display: flex;
    
    .filter_element{
        display: flex;
        align-items: center;
        column-gap:10px;
        width: 100%;
        border-bottom: 1px solid gray;
        padding-bottom: 10px;
       
    }

`

const StyledSelect = styled(Select)`
    flex-grow: 1;
`

const StyledBody = styled.div`
    
`

const StyledItemElement = styled.div`
    width: 100%;
    background-color: cornflowerblue;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .element_title{
        color: white;
        text-align: center;
    }

    .element_value{
        color: white;
        font-weight: bold;
        text-align: center;
    }
`


const StyledTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 15px;
    .header_element{
        border: 1px solid gray;
        padding: 8px;
        border-radius: 10px;
        text-align: center;
        font-weight: bold;
    }
`
