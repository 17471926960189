import { Card, CardBody, CardTitle } from 'reactstrap';
import PropTypes from 'prop-types';
import "./index.scss"
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const CardCatagories = (props) => {
    const navigate = useNavigate()

    const text = props.text;
    const textLength = text.length;

    function Tr2En(text) {
        var Maps = {
            "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
            "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
        };
        Object.keys(Maps).forEach(function (Old) {
            text = text.replace(Old, Maps[Old]);
        });
        return text;
    }

    return (
        <StyledCard onClick={() => {
            navigate(`/egitimler/${Tr2En(props.data?.name?.toLowerCase()?.replace(/\s+/g, ''))}`)
        }}  >
            <StyledCardBody >
                {props.icon}
                <StyledCardTitle >{/* {props.text.slice(0, 20)} */}  {textLength > 20 ? props.text.slice(0, 20) + "..." : text} </StyledCardTitle>
            </StyledCardBody>
        </StyledCard>
    )
}
CardCatagories.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.any,

}

const StyledCard = styled(Card)`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   /*  width: 250px;
    height: 150px; */
`

const StyledCardBody = styled(CardBody)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
`

const StyledCardTitle = styled(CardTitle)`
    font-size: 14px !important;
`

export default CardCatagories;
