import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import AnnouncementDashboard from '../../Components/Announcement/AnnouncementDashboard'

const AnnouncementPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer  header={"Duyurular"} visible={false} >
                <AnnouncementDashboard />
            </TablePageContainer>
        </div>
    )
}

export default AnnouncementPage