// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branch_select:hover {
  border: none !important; }

.ant-select:hover {
  border-color: initial !important;
  box-shadow: none !important; }

.ant-select-selector {
  padding: 4px !important; }
`, "",{"version":3,"sources":["webpack://./src/Components/Teacher/index.scss"],"names":[],"mappings":"AAAA;EAGQ,uBAAuB,EAAA;;AAI/B;EACI,gCAAgC;EAChC,2BAA2B,EAAA;;AAG/B;EACI,uBAAuB,EAAA","sourcesContent":[".branch_select{\n\n    &:hover{\n        border: none !important;\n    }\n}\n\n.ant-select:hover {\n    border-color: initial !important;\n    box-shadow: none !important;\n}\n\n.ant-select-selector{\n    padding: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
