import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { UserContext } from '../../../../context/user'
import MobileProfileDropwn from '../MobileProfileDropdown/MobileProfileDropdown'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { Button } from 'reactstrap'

const MobileNavbarMenu = ({ toggle, setToggle }) => {
    const { t } = useTranslation()
    const [state, dispatch] = useContext(UserContext)
    const navigate = useNavigate()
    return (
        <MobileNavbar toggle={toggle}    >
            <div className='nav_header' >
                <h5>
                    Menü
                </h5>
                <IoCloseCircleSharp className='close_icon' onClick={() => {
                    setToggle(false)
                }} />

            </div>
            <div className='link_wrapper' >
                <NavLink to={"/"} className={"mobile_nav_link"} >
                    <p  >{t('Home')}</p>
                </NavLink>
                <NavLink to={"/kurumsal"} className={"mobile_nav_link"}  >
                    <p > {t('Kurumsal')}</p>
                </NavLink>
                <NavLink to={"/ekitap"} className={"mobile_nav_link"}   >
                    <p >  {t('ebook')}</p>
                </NavLink>
                <NavLink to={"/egitimler/tumegitimler"} className={"mobile_nav_link"}  >
                    <p >  {t('education')}</p>
                </NavLink>
                <NavLink to={"/guncel"} className={"mobile_nav_link"}  >
                    <p>  {t('news')}</p>
                </NavLink>
                <NavLink to={"/blog"} className={"mobile_nav_link"} >
                    <p >  {t('blog')}</p>
                </NavLink>
                <NavLink to={"/iletisim"} className={"mobile_nav_link"}>
                    <p >  {t('contact')}</p>
                </NavLink>
                <div style={{ width: "100%", justifyContent: "center" }} >
                    {
                        !state.isLoggedIn && (
                            <div style={{ display: "flex", flexDirection: "column", rowGap: "20px", alignItems: "center" }} >
                                <StyledButton onClick={() => {
                                    navigate("/giris")
                                }}  >
                                    Oturum Aç
                                </StyledButton>

                                <StyledButton style={{
                                    color: "white",
                                    backgroundColor: "#28a745",


                                }} onClick={() => {
                                    navigate("/kayit")
                                }}  >
                                    Kayıt Ol
                                </StyledButton>
                            </div>

                        )
                    }

                    {
                        state.isLoggedIn && (
                            <div  >
                                <MobileProfileDropwn />
                            </div>

                        )
                    }
                </div>


            </div>

        </MobileNavbar>
    )
}

export default MobileNavbarMenu


const MobileNavbar = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: ${props => props.toggle ? 0 : "-100%"};
    top: 0px;
    height: 100%;
    transition: .5s all;
    width: 100%;
    background-color: #F5F7F8;
    z-index: 9999999999;
    row-gap: 10px;


    @media screen and (min-width:1100px) {
        display: none;
    }

    .link_wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        margin-bottom: 40px;

        .mobile_nav_link{
            font-size: 16px;
        }
    }

    .nav_header{
        display: flex;
        justify-content: space-between;
        padding: 30px;
        
        h5 {
            font-size: 24px;
            font-weight: bold;
        }

        .close_icon{
            font-size: 35px;
            color: red;
        }
    }
`

const StyledButton = styled(Button)`
    width: 150px;
    border-radius: 15px 
`