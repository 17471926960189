import React, { useMemo } from 'react'
import { Col } from "reactstrap"
import "./ekitapCard.scss"
import i1 from "../../../assets/images/i1.png"
import styled from 'styled-components'


const EKitapCardComponent = ({ data, leftOrRight }) => {

    const LeftElementContainer = styled.div`
        height: 200px;
        position: relative;
        width: 100%;
        background-color: ${data.elementColor};
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width:1100px) {
            justify-content: ${leftOrRight == "left" ? "flex-end" : "flex-start"} ;
            padding-right : ${leftOrRight == "left" ? "30px" : "0"};
            padding-left : ${leftOrRight == "right" ? "30px" : "0"};
        }

        @media screen and (max-width:500px) {
            justify-content: ${leftOrRight == "left" ? "flex-end" : "flex-start"} ;
        }


        .clip_path_container {
            .clip_path_item {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 95%;
                width: 20%;
                background: ${data.clipPathColor};
                clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 48% 74%, 0 100%);
                /*  clip-path: polygon(100% 0, 100% 100%, 49% 68%, 0 100%, 0 0); */
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                position: absolute;
                top: -30px;
                left: ${leftOrRight == "left" ? "5%" : null};
                right: ${leftOrRight == "right" ? "5%" : null};

                @media screen and (max-width:1100px) {
                    width: 30%;
                }

                @media screen and (max-width:500px) {
                    width: 34%;
                }

                .clip_path_item_img {
                    transform: translateY(-20%);
                    width: 60px;
                    
                    @media screen and (max-width:500px) {
                        width: 50px;
                    }

                }
            }
        }
        .ekitap_element_title {
            font-size: 25px;
            font-weight: bold;

            @media screen  and (max-width:1100px){
                font-size: 14px;
            }


            @media screen and (max-width:500px) {
                font-size: 14px;
                margin-left: ${leftOrRight == "left" ? "0px" : "20px"};
                margin-right:${leftOrRight == "left" ? "20px" : "0"}; ;
            }
         }

        .ekitap_element_category {
            position: absolute;
            bottom: 3%;
            left: ${leftOrRight == "left" ? "5%" : null};
            right:${leftOrRight == "right" ? "5%" : null}; 
            width: 20%;
            text-align: center;
            font-size: 16px;

            @media screen and (max-width:1100px) {
                width: 30%;
                white-space: nowrap;
            }

            @media screen and (max-width:500px) {
                font-size: 12px;
                white-space: nowrap;
                width: 34%;
            }

            /* color: white; */
        }
    `





    return (
        <LeftElementContainer position={leftOrRight} >
            <div className="clip_path_container">
                <div className="clip_path_item" >
                    <img src={data.img} alt="" className="clip_path_item_img" />
                </div>
            </div>
            <p className="ekitap_element_title" >
                {data.title}
            </p>
            <p className="ekitap_element_category">
                {data.category}
            </p>
        </LeftElementContainer>
    )
}

export default EKitapCardComponent