import 'swiper/css';
import 'swiper/css/pagination';
import './index.scss'
import { useTranslation } from "react-i18next"
import CardLesson from "../cardLesson/CardLesson";
import imgProfile from "../../../assets/images/imgProfile.png"
import imgPlay from "../../../assets/images/imgPlay.png"
import imgCard from "../../../assets/images/imgcard.png"
import imgCard2 from "../../../assets/images/imgcard2.png"
import imgCard3 from "../../../assets/images/imgcard3.png"
import imgCard4 from "../../../assets/images/imgcard4.png"
import imgCard5 from "../../../assets/images/imgcard5.png"
import imgCard6 from "../../../assets/images/imgcard6.png"
import React, { useState, useEffect, useMemo } from "react";
import { axiosInstance } from "../../../api/axiosInstance";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardFooter, CardImg, CardText, CardTitle } from "reactstrap";
import Rating from "react-rating";
import { FaRegStar, FaStar } from "react-icons/fa";
import ButtonPurple from "../buttons/ButtonPurple";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ProfileAvatar from "../../../assets/images/profile.png"

function SliderGeneral({ recommanded }) {
    const { t } = useTranslation();
    const navigate = useNavigate()

    function getRandomImgCard() {
        const imgCards = [imgCard, imgCard2, imgCard3, imgCard4, imgCard5, imgCard6];
        return imgCards[Math.floor(Math.random() * imgCards.length)];
    }

    const getImg = (images) => {
        if (images.length == 0) {
            return getRandomImgCard()
        }
        return images[0]?.url
    }

    const courseAdminName = (name) => {
        if (!name) {
            return "Eğtmen seçilmedi"
        }
        else {
            if (name?.length > 15) {
                return `${name?.substring(0, 15)}...`
            }
            else {
                return `${name}`
            }
        }
    }

    return (
        <div className="slider-container mt-4" >
            <Swiper
                modules={[
                    Navigation,

                ]}
                breakpoints={{
                    // Mobilde tek slide göster
                    0: {
                        slidesPerView: 1,
                    },
                    // Tablet ve üstü ekranlarda 2 slide göster
                    768: {
                        slidesPerView: 2,
                    },
                    // Daha büyük ekranlarda 4 slide göster
                    1024: {
                        slidesPerView: 3,
                    },
                    
                }}


                slidesPerView={4}
            >
                {
                    recommanded.map((item, index) => {
                        return (
                            <SwiperSlide key={`${index}`} >
                                <StyledCard onClick={() => {
                                }} >

                                    <StyledCardImg variant="top" src={getImg(item?.courseImages)} />
                                    {/*    <img src={VoteStars} alt='' style={{ width: "70px", height: "25px", }}></img>   */}
                                    <StyledCardBody >

                                        <StyledCardText className='info' style={{ fontWeight: "bolder", textTransform: "capitalize" }}>
                                            {item?.branch?.name?.toUpperCase() > 30 ? `${item?.branch?.name?.toUpperCase()?.substring(0, 30)}...` : item?.branch?.name?.toUpperCase()}
                                        </StyledCardText>
                                    </StyledCardBody>

                                    <StyledCardFooter >
                                        <div className="card_footer_wrapper">
                                            <div className="card_footer_wrapper_left_content">
                                                <img src={ProfileAvatar} alt="" className="teacher_icon" ></img>
                                                <p className="teacher_name" style={{ fontSize: `${item?.courseAdministrator?.user?.userAndLastName?.length > 20 ? "xx-small" : ""}` }}>
                                                    {courseAdminName(item?.courseAdministrator?.user?.userAndLastName)}
                                                </p>
                                            </div>
                                            <ButtonPurple onClick={() => {
                                         
                                                navigate(`/kurs/${item?.branch?.label}`)
                                            }} buttonText={"incele"} btnColor={"#7f56d9"}  ></ButtonPurple>
                                        </div>

                                    </StyledCardFooter>
                                </StyledCard>

                            </SwiperSlide>
                        )
                    })
                }



            </Swiper>

        </div>
    );
}

export default SliderGeneral;


const StyledCardBody = styled(CardBody)`
`

const StyledCard = styled(Card)`
   border: 1px solid transparent;
   height: 350px;
   width: 95% !important;
    @media screen and (max-width:600px) {
        margin-right: 0px;
        width: 100% !important;
    }
`

const StyledCardFooter = styled(CardFooter)`
    width: 100%;
    z-index: -122;

    .card_footer_wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card_footer_wrapper_left_content{
            display: flex;
            align-items: center;
            .teacher_icon{
            width: 30px;
            height: 30px;
            margin-right: 5px;
            }

            .teacher_name{
                text-transform: capitalize;
            }
        }
        
    }

`

const StyledCardText = styled(CardText)`
    font-family: "New Amsterdam", sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 2px;
    
`
const StyledImg = styled.img`
    width: 24px;
    height: 24px;
`

const StyledCardImg = styled(CardImg)`
    height: 170px;
    /* object-fit: cover; */
    border-radius:10px;
`