import { useTranslation } from "react-i18next";
import MainNavbar from '../../components/navbar/MainNavbar';
import FontHead from '../../components/fontHead/FontHead';
import MainFooter from '../../components/mainFooter/MainFooter';
import './index.scss'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import styled from 'styled-components';
import ButtonPurple from '../../components/buttons/ButtonPurple';
import { axiosInstance } from '../../../api/axiosInstance';
import { Alert, Button } from 'antd';
import TopBgElement from '../TopBgElement/TopBgElement';
import GuncelBg from "../../../assets/images/guncelBg.png"
import Slider from "react-slick";
/* import CoverPhoto from "../../../assets/images/auth-one-bg.jpg" */
import CoverPhoto2 from "../../../assets/images/b1.png"
import CoverPhoto from "../../../assets/images/b3.png"
import OtherCourseSlider from "../../components/OtherCourseSlider/OtherCourseSlider";
import { Helmet } from "react-helmet-async";
const Guncel = () => {
    const { t } = useTranslation();

    const [news, setNews] = useState([])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [coursesList, setCoursesList] = useState()


    const getAllNews = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/news/getall", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/news/getall", {
                page: 0,
                pageSize: response.data.count
            })
            setNews(response2.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const getCoverImg = (element) => {

        if (element?.courseImages?.length == 0) {
            return CoverPhoto
        }
        else {
            let courseImage = element?.courseImages[0]?.url
            return courseImage
        }
    }

    const settings = {
        dots: true,
        /*         infinite: true, */
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 2000,

        slidesToScroll: 1,      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1025, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true
                }
            },
            {
                breakpoint: 860, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 500, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };


    const fetchData = async () => {
        try {
            const response = await axiosInstance.post(`/Courses/GetAll?type=Online&status=0`, {
                page: 0,
                pageSize: 6
            });
            const response2 = await axiosInstance.post(`/Courses/GetAll?type=Online&status=0`, {
                page: 0,
                pageSize: 6
            });
            const values = [...response.data.items, ...response2.data.items]
            setCoursesList(values)
        } catch (error) {

        }
    };

    useEffect(() => {
        window.scroll(0, 0)
        getAllNews()
        fetchData()
    }, [])

    return (
        <StyledMainContainer>
            <Helmet>
                <meta name="description" content={"KUZEM Eğitim platformumuzdaki en son güncellemeleri ve gelişmeleri takip edin."} />
                <meta name="keywords" content={'Güncel, Gelişmeler, Eğitim Platformu, Haberler, Duyurular'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kuzem.org/guncel" />
                <meta property="og:image" content={GuncelBg} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={GuncelBg} mainTitle={"Güncel"} subTitle={"Anasayfa / Güncel"} />
            {
                news.length !== 0 ? (
                    <StyledRowContainer>
                        <Row >
                            {
                                news.map((item, index) => {
                                    return (
                                        <Col lg={4} sm={1} key={`${index}`}  >
                                            <StyleCard  >
                                                <StyledCardImg variant="top" src={item.imageUrl} />
                                                <CardBody  >
                                                    <CardTitle style={{ textTransform: "capitalize", fontWeight: "700" }} > {item.title}  </CardTitle>
                                                    <StyledCardText >
                                                        {item?.description?.substring(0, 200)}...
                                                    </StyledCardText>
                                                </CardBody>

                                                <StyledCardFooter >
                                                    <StyledCardDate>
                                                        {new Date(item?.createdAt).toLocaleDateString()}
                                                    </StyledCardDate>
                                                    <ButtonPurple buttonText={t("btnxx")} /* onClick={props.onClick} */ onClick={() => {
                                                        navigate(`/guncel/${item.id}`)
                                                    }} ></ButtonPurple>
                                                </StyledCardFooter>
                                            </StyleCard>
                                        </Col>

                                    )
                                })
                            }
                        </Row>
                        <OtherCourseContainer>

                            <OtherCourseSlider />

                        </OtherCourseContainer>

                    </StyledRowContainer>

                ) : (
                    <StyledNoContentWrapper>
                        <Alert type='info' message="İçerik Bulunamadı" style={{ width: "100%" }} />
                        <OtherCourseContainer>

                            <OtherCourseSlider />

                        </OtherCourseContainer>
                    </StyledNoContentWrapper>

                )
            }


            <MainFooter visibility={'visible'} />
        </StyledMainContainer >
    )
}

export default Guncel

const StyleCard = styled(Card)`
width: "100%";
min-height: 370px;
max-height: 370px;

@media screen and (max-width:600px) {
    margin-right: 0;
}
`
const StyledCardImg = styled(CardImg)`
    object-fit :cover;
    height: 150px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`
const StyledCardText = styled(CardText)`
    font-size: 12px;
    
`
const StyledNoContentWrapper = styled.div`
        padding: 20px 120px;
        margin-bottom: 150px;
        @media screen and (max-width:1100px) {
        padding: 20px 40px;
        grid-template-columns: 1fr 1fr;
    }


    @media screen and (max-width:500px) {
        grid-template-columns: 1fr ;
        padding: 20px 20px;
    }
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledCardDate = styled(CardText)`
    color: gray;
    opacity: .3;
`

const StyledRowContainer = styled.div`
    padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }
`

const StyledCardFooter = styled(CardFooter)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 15px !important;
`

const StyledSliderCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`


const Container2 = styled.div`
    width: 90%;
    border: 1px solid #D1D5DB;
    border-radius: 10px;

    .same_course_name_wrapper{
        height: 70px;
        padding-top: 10px;
        padding-left: 10px;
        .same_course_name{
        
        font-size: 13px;
        text-transform: capitalize;
        color: #80949F;


     }

    }
    
    .same_course_card_footer{
        background-color: #EFEFEF;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;  
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category_el{
           /*  background-color: #B9F4B9; */
           /*  padding: 3px 10px; */
            border-radius: 5px;
            letter-spacing: 2px;
            width: max-content;
            text-transform: capitalize;
            font-size: 10px;
        }
    }
`


const OtherCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 70px;
    .other_course_title{

    }
`

const CoverImg = styled.img`
    width:100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 150px;
`


const StyledSliderElement = styled(Slider)`

   /*  .slick-dots{
        bottom: -5px;
    } */
`