import React, { useContext, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Button, Card, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { getDynamicUserSearchApi, getUserListApi } from '../../../api/UserApi'
import { Select, Button as AntButton, Table as AntTable, Input, Table } from 'antd'
import { toast } from 'react-toastify'
import { addCourseAdministrators, administratorsGetAll, deleteCourseAdministratorsApi } from '../../../api/Course'
import { useNavigate, useParams } from 'react-router-dom'
import { updateCourseContent } from '../../../api/CourseContent'
import { axiosInstance } from '../../../api/axiosInstance'
import { FaEye } from 'react-icons/fa'

const SelectTeacherTab = ({ mainData, apiRequest }) => {
    const [teacherList, setTeacherList] = useState([])
    const [teacherModal, setTeacherModal] = useState({
        visible: false,
        data: null
    })
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [conditionSearch, setConditionSearch] = useState("Tc")
    const [selectedTeacher, setSelectedTeacher] = useState()
    const { id } = useParams()
    const StyledContainer = styled.div`
        display: flex;
        flex-direction: column;
    `
    const StyledHeader = styled.div`
        display: flex;
        justify-content: flex-end;
        align-items: center;
    `




    const teacherData = useMemo(() => {

        if (mainData?.courseAdministrator) {
            return [{
                courseAdministrator: mainData?.courseAdministrator,
                courseAdministratorEmail: mainData?.courseAdministratorEmail,
                courseAdministratorPhone: mainData?.courseAdministratorPhone,
                courseAdministratorTc: mainData?.courseAdministratorTc,
                courseAdministratorId: mainData?.courseAdministratorId,
                courseAdministratorDeleteId: mainData?.courseAdministratorDeleteId
            }]
        }
        return []
    }, [mainData])






    const getTeacherListToBranch = async () => {
        try {
            const response = await axiosInstance.post(`/teacherBranches/GetAllByBranchId?branchId=${mainData?.branchId}`, {
                page: 0,
                pageSize: 1000
            })
            setTeacherList(response.data?.items)
        }
        catch (err) {

        }
    }



    const saveTeacher = async () => {
        try {
   

            if (teacherData.length) {
                await deleteCourseAdministratorsApi(teacherData[0]?.courseAdministratorDeleteId)
            }


            const response = await addCourseAdministrators({
                userId: selectedTeacher?.userId,
                courseId: id
            })
            toast.success("Eğitmen Kayıt Edildi", {
                autoClose: 2500,
                position: "top-center"
            })
            await apiRequest()

            setTeacherModal({
                visible: false,
                data: null
            })
        }
        catch (err) {

        }

    }

    useEffect(() => {
        getTeacherListToBranch()
    }, [])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <AntButton type='primary' onClick={() => {
                        setTeacherModal({
                            ...teacherModal,
                            visible: true
                        })
                    }} >
                        Eğitmen Seç
                    </AntButton>
                </StyledHeader>
                <StyledDataTableContainer>
                    <AntTable
                        dataSource={teacherData}
                        scroll={{
                            x: 600
                        }}
                        columns={[
                            {
                                title: "id",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.courseAdministratorId}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "İsim",
                                render: (row, record) => {
                                    return (
                                        <span> {record?.courseAdministrator} </span>
                                    )
                                }
                            },
                            {
                                title: "Tc",
                                render: (row, record) => {
                                    return (
                                        <span> {record?.courseAdministratorTc} </span>
                                    )
                                }
                            },
                            {
                                title: "Email",
                                render: (row, record) => {
                                    return (
                                        <span> {record?.courseAdministratorEmail} </span>
                                    )
                                }
                            },
                            {
                                title: "Telefon",
                                render: (row, record) => {
                                    return (
                                        <span> {record?.courseAdministratorPhone} </span>
                                    )
                                }
                            },

                        ]}
                    />

                </StyledDataTableContainer>

            </StyledContainer>
            <Modal isOpen={teacherModal.visible} size='lg' >
                <ModalHeader>
                    Eğitmen Seç
                </ModalHeader>
                <ModalBody>
                    <ContentContainer>
                        <StyledTable
                            loading={loading}
                            size='middle'
                            pagination={{
                                pageSize: 5
                            }}
                            scroll={{
                                x: 400
                            }}
                            onRow={(record) => {
                                return {
                                    onClick: () => {

                                        setSelectedTeacher(record)
                                    },
                                    style: {
                                        backgroundColor: selectedTeacher?.userId == record?.userId ? "#E6F4FF" : "",
                                        /*  color : selectedTeacher?.id == record?.id ? "white" : "", */
                                    }
                                }
                            }}
                            dataSource={teacherList}
                            columns={[
                                {
                                    title: "İsim",
                                    render: (row, record) => {

                                        return (
                                            <p style={{ fontSize: "12px" }}>
                                                {record?.firstName.length > 30 ? `${record?.firstName?.substring(0, 30)}...` : record?.firstName}
                                            </p>
                                        )
                                    }
                                },
                                {
                                    title: "Soyisim",
                                    render: (row, record) => {

                                        return (
                                            <p style={{ fontSize: "12px" }}>
                                                {record?.lastName.length > 30 ? `${record?.lastName?.substring(0, 30)}...` : record?.lastName}
                                            </p>
                                        )
                                    }
                                },
                                /* {
                                    title: "Seç",
                                    render: (row, record) => {
                                        return (
                                            <AntButton type='primary' size='small' >
                                                Seç
                                            </AntButton>
                                        )
                                    }
                                } */
                            ]}

                        />
                    </ContentContainer>

                </ModalBody>
                <ModalFooter>
                    <AntButton type='primary' disabled={selectedTeacher ? false : true} onClick={async () => {
                        saveTeacher()
                    }} >
                        Kaydet
                    </AntButton>
                    <AntButton type='primary' danger onClick={() => {
                        setTeacherModal({
                            visible: false,
                            data: null
                        })
                        setSearchText("")
                        setSelectedTeacher()
                        setTeacherList([])
                    }} >
                        İptal
                    </AntButton>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SelectTeacherTab


const StyledDataTableContainer = styled.div`
    margin-top: 20px;
`

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 30px;
`

const ContentContainer = styled.div`
    display: flex;
    
    flex-direction: column;
    row-gap: 20px;
`

const StyledSearchInput = styled(Input)`
    height: 30px;
    width: 100%;

`

const StyledSelect = styled(Select)`
    height: 30px;
    width: 30%;
`

const StyledTable = styled(AntTable)`
   .ant-table-content{
        /* scrollbar-width: 3px;
        background-color: red;
        &::-webkit-scrollbar{
            width: 3px;
            background-color: red;
        } */
    }
`