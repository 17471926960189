import React, { useEffect, useCallback, useContext } from 'react';
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from 'reactstrap';
// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { UserContext } from '../../context/user';
import useScreenSize from '../../hooks/useScreenSize';
import styled from 'styled-components';

const VerticalLayout = (props) => {
    const navigate = useNavigate()
    const navData = navdata().props.children;
    const path = props.router.location.pathname;
    const [context, dispatch] = useContext(UserContext)


    /*
 layout settings
 */
    const {
        leftsidbarSizeType, sidebarVisibilitytype, layoutType
    } = useSelector(state => ({
        leftsidbarSizeType: state.Layout.leftsidbarSizeType,
        sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
        layoutType: state.Layout.layoutType
    }));

    //vertical and semibox resize events
    const resizeSidebarMenu = useCallback(() => {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize >= 1025) {
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if ((sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") && document.querySelector(".hamburger-icon")) {
                document.querySelector(".hamburger-icon")?.classList.remove("open");
            } else {
                document.querySelector(".hamburger-icon")?.classList.add("open");
            }

        } else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("twocolumn-panel");
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.querySelector(".hamburger-icon")) {
                document.querySelector(".hamburger-icon").classList.add("open");
            }
        } else if (windowSize <= 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
            if (document.querySelector(".hamburger-icon")) {
                document.querySelector(".hamburger-icon").classList.add("open");
            }
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + path;
            const ul = document.getElementById("navbar-nav");
            const items = ul.getElementsByTagName("a");
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);
            let matchingMenuItem = itemsArray.find((x) => {
                return x.pathname === pathName;
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        if (props.layoutType === "vertical") {
            initMenu();
        }
    }, [path, props.layoutType]);

    function activateParentDropdown(item) {
        item.classList.add("active");
        let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add("show");
            parentCollapseDiv.parentElement.children[0].classList.add("active");
            parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
            if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse")) {
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").classList.add("show");
                    parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.closest(".collapse").previousElementSibling.classList.add("active");
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains("active"));

        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", false);
            }
            item.classList.remove("active");
        });
    };


    const rolePermissionControl = (roleList) => {
        let formatRole = context.role.replace(/\s+/g, '');
        return roleList.includes(formatRole)
    }

    const pathControl = (linkPath) => {
        return linkPath == path
    }
    const isScreenSmall = useScreenSize(766)

    return (
        <React.Fragment>
            {/* menu Items */}
            {(navData || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {

                            rolePermissionControl(item.role) && item.subItems ? (
                                <li className="nav-item">
                                    <StyledNavElement
                                        style={{
                                            color: "gray"
                                        }}
                                        onClick={(e) => {
                                            if (item.click) {
                                                item.click(e)
                                            }
                                            /*  else {
                                                 if (item.link) {
                                                     navigate(`${item.link}`)
                                                 }
                                                 else {
                                                     navigate("/")
                                                 }
                                             } */
                                        }}
                                        className="nav-link menu-link"
                                        /* to={item.link ? item.link : "/#"} */
                                        data-bs-toggle="collapse"
                                    >
                                        <i className={item.icon}></i>
                                        <span data-key="t-apps">{props.t(item.label)}</span>
                                        {item.badgeName ?
                                            <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                                            : null}
                                    </StyledNavElement>
                                    <Collapse
                                        className="menu-dropdown"
                                        isOpen={item.stateVariables}
                                        id="sidebarApps">
                                        <ul className="nav nav-sm flex-column test">
                                            {/* subItms  */}
                                            {item.subItems && ((item.subItems || []).map((subItem, key) => (
                                                <React.Fragment key={key}>
                                                    {!subItem.isChildItem && rolePermissionControl(subItem.role) ? (
                                                        <li className="nav-item">
                                                            <StyledNavElement
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: pathControl(subItem?.link) ? "#088F7D" : "gray"
                                                                }}
                                                                onClick={(e) => {
                                                                    if (subItem.link) {

                                                                        if (isScreenSmall) {
                                                                            document.body.classList.remove('vertical-sidebar-enable');
                                                                            navigate(`${subItem.link}`)
                                                                        }
                                                                        else {
                                                                            navigate(`${subItem.link}`)

                                                                        }


                                                                    }
                                                                    else {
                                                                        if (isScreenSmall) {
                                                                            document.body.classList.remove('vertical-sidebar-enable');

                                                                            navigate("/")
                                                                        }
                                                                        else {

                                                                            navigate("/")
                                                                        }

                                                                    }
                                                                }}
                                                                /* 
                                                                                                                                to={subItem.link ? subItem.link : "/#"} */
                                                                className="nav-link"
                                                            >
                                                                {props.t(subItem.label)}

                                                            </StyledNavElement>
                                                        </li>
                                                    ) : null}
                                                </React.Fragment>
                                            ))
                                            )}
                                        </ul>

                                    </Collapse>
                                </li>
                            ) : rolePermissionControl(item.role) ? (

                                <li className="nav-item">
                                    <StyledNavElement
                                        /* onClick={item?.click} */
                                        onClick={() => {
                                            if (isScreenSmall) {
                                                document.body.classList.remove('vertical-sidebar-enable');
                                                navigate(`${item?.link}`)
                                            }
                                            else {
                                                navigate(`${item?.link}`)
                                            }
                                        }}

                                        className="nav-link menu-link"
                                        style={{
                                            color: pathControl(item?.link) ? "#088F7D" : "gray",
                                            cursor: "pointer"
                                        }}
                                    /* to={item.link ? item.link : "/#"} */

                                    >
                                        <i className={item.icon}></i> <span>{props.t(item.label)}</span>
                                        {item.badgeName ?
                                            <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">{item.badgeName}</span>
                                            : null}
                                    </StyledNavElement>
                                </li>

                            ) : null


                        }
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

const StyledNavElement = styled.p`

    &:hover{
        color: #088F7D !important;
    }
`

export default withRouter(withTranslation()(VerticalLayout));