import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { getDetailCourseApi } from '../../api/Course'
import { Button, Col, Input, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { IoIosSend, IoMdRefresh } from 'react-icons/io'
import { getAllReceivedNotification, getAllSendNotifications, sendMessageApi } from '../../api/Notifications'
import { UserContext } from '../../context/user'
import { Select, Spin } from "antd"
import { toast } from "react-toastify"
import "./TeacherMessageDashboard.scss"
import { TbMessageCirclePlus } from 'react-icons/tb'
import { useLocation } from 'react-router-dom'
import Linkify from "react-linkify"
import styled from "styled-components"
import { axiosInstance } from '../../api/axiosInstance'


const TeacherMessageDashboard = ({ courseDataList }) => {

  const [registeredStudentList, setRegisteredStudentList] = useState([])
  const [formatMessageList, setFormatMessageList] = useState([])
  const [selectedUser, setSelectedUser] = useState("")
  const [sendingMessage, setSendingMessage] = useState([])
  const [message, setMessage] = useState("")
  const [context, dispatch] = useContext(UserContext)
  const [classList, setClassList] = useState([])
  const messageContentRef = useRef(null);
  const [modalData, setModalData] = useState({
    show: false,
    data: null,
    message: ""
  })
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");

  const courseIdList = useMemo(() => {
    if (courseDataList) {
      return courseDataList.map(el => el.id)
    }
    return []
  }, [courseDataList])

  const getStudentList = async () => {
    try {
      setLoading(true)
      const studentList = await Promise.all(courseIdList.map(x => getDetailCourseApi(x)))

      let formatList = studentList.map(item => {
        if (item.data.courseRegisters.length !== 0) {
          return item.data.courseRegisters.map(el => {
            return {
              ...el.user,
              courseId: el.courseId
            }
          })
        }
      }).filter(el => el !== undefined).flatMap(x => x)/* .filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id == value.id
        ))
      }) */

      setClassList(studentList.filter(x => x.data.courseRegisters.length !== 0).map(item => item.data))
      setFormatMessageList(formatList)
      setRegisteredStudentList(formatList.filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id == value.id
        ))
      }))
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }

  }

  const getMessageList = async () => {
    try {
      setLoading(true)
      const sendingMessage = await getAllSendNotifications({
        page: 0,
        pageSize: 1000
      })
      const receivedMessage = await getAllReceivedNotification({
        page: 0,
        pageSize: 1000
      })
      let sortDataList = [...sendingMessage.data.items, ...receivedMessage.data.items].sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
      })

      setSendingMessage(sortDataList)
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }


  useEffect(() => {

    if (courseIdList && courseIdList.length !== 0) {
      getStudentList()
      getMessageList()
    }
  }, [courseIdList])


  const topluMesaj = async () => {
    try {

      const filterStudentList = formatMessageList.filter(el => el.courseId == modalData.data)
      const response = await sendMessageApi({
        userId: parseInt(context.userId),
        receiverIds: filterStudentList.map(el => el.id),
        message: modalData.message
      })


      toast.success("Toplu mesaj gönderildi", {
        autoClose: 2000
      })
      setModalData({
        show: false,
        data: null,
        message: ""
      })
    }
    catch (err) {

    }
  }


  const sendMessage = async () => {
    try {

      if (message == "") {
        toast.error("Boş mesaj yollanamaz", {
          position: "top-center",
          autoClose: 2000
        })
      }
      else if (selectedUser == "" || selectedUser == null || selectedUser == undefined) {
        toast.error("Mesaj atılacak kişiyi seçiniz", {
          position: "top-center",
          autoClose: 2000
        })
      }
      else {
        const response = await sendMessageApi({
          userId: parseInt(context.userId),
          receiverIds: [selectedUser],
          message: message
        })
        setMessage("")
        toast.success("Mesaj iletildi", {
          position: "top-center",
          autoClose: 2000
        })
        await getMessageList()
      }

    }
    catch (err) {

    }
  }


  useEffect(() => {
    messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
  }, [sendingMessage, selectedUser])



  const selectedUserSendingMessage = useMemo(() => {

    const filterData = sendingMessage.filter(el => {
      return el.receiverUserId == selectedUser || el.sentUserId == selectedUser
    })

    return filterData
  }, [selectedUser, sendingMessage])


  console.log("messagfexx", selectedUserSendingMessage)

  useEffect(() => {

    setSelectedUser(userId)

  }, [registeredStudentList])


  const getFullName = () => {
    const findUser = registeredStudentList.find(item => item?.id == selectedUser)

    if (findUser) {
      return `${findUser?.firstName} ${findUser?.lastName}`
    }
    return ""
  }


  useEffect(() => {
    // Enter tuşuna basıldığında tetiklenecek olay
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        sendMessage()
        // Enter tuşuna basıldığında yapmak istediğin işlemi buraya ekle
      }
    };

    // `keydown` olay dinleyicisini ekle
    window.addEventListener('keydown', handleKeyDown);

    // Component unmount olduğunda event listener'ı kaldır
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [message]);


  const formatDate = (time) => {
    const day = time?.split("T")[0]
    const hour = time?.split("T")[1]?.substring(0, 5)

    return `${day}-${hour}`
  }

  const componentDecorator = (href, text, key) => (
    <StyledAhrefTag href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </StyledAhrefTag>
  );

  return (
    <div  >
      <Row style={{
        paddingRight: "3px",
        paddingLeft: "3px"
      }}  >
        <Col lg={12} >
          <div className='message_content_section' ref={messageContentRef} >
            <div className='message_list' >
              <div className="sending_msg_title">
                <div className='select_ogr' >
                  <Button className='toplu_mesaj_btn' color='primary' onClick={() => {
                    setModalData({
                      show: true,
                      data: null,
                      message: ""
                    })
                  }} style={{ maxHeight: "37.1px" }} >
                    <TbMessageCirclePlus />
                  </Button>
                  <Select
                    loading={loading}
                    className='student_select_box'
                    showSearch
                    value={getFullName()}
                    onChange={async (e) => {
                      try {
                        setLoading(true)

                        await axiosInstance.put("/notifications/updateStatus", {
                          userId: e
                        })

                        setSelectedUser(e)
                      }
                      catch (err) {

                      }
                      finally {
                        setLoading(false)
                      }

                    }}
                    filterOption={(input, option) => {
                      return (
                        option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                      )
                    }}
                    options={registeredStudentList.map(el => {
                      return {
                        label: el.firstName + " " + el.lastName,
                        value: el.id,
                      }
                    })}
                  />
                </div>
                <Button className='btn btn-warning' style={{ borderRadius: "0" }} onClick={async () => {
                  messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight;
                  getMessageList()
                }} >
                  <IoMdRefresh />
                </Button>
              </div>
              <div className='sending_msg_container'  >
                {
                  loading && (
                    <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height:"300px"
                    }} >
                      <Spin size='large' />
                    </div>
                  )
                }
                {
                  !loading && selectedUserSendingMessage.map((el, index) => {
                    if (el.sentUserId == context.userId) {
                      return (
                        <Linkify key={`${index}`} componentDecorator={componentDecorator} >
                          <h5 className='sending_msg'>
                            <span>
                              {el.message}
                            </span>
                            <StyledMessageTime  >
                              {formatDate(el?.createdAt)}
                            </StyledMessageTime>
                          </h5>
                        </Linkify>

                      )
                    }
                    else {
                      return (
                        <Linkify key={`${index}`} componentDecorator={componentDecorator} >
                          <h5 className='received_msg' >
                            <span>
                              {el.message}
                            </span>
                            <StyledMessageTime  >
                              {formatDate(el?.createdAt)}
                            </StyledMessageTime>
                          </h5>
                        </Linkify>

                      )
                    }
                  })
                }
              </div>

            </div>
            <div className='message_input_container'>
              <Input placeholder='Mesaj' value={message} className='message_input  ' onChange={(e) => {
                setMessage(e.target.value)
              }} />
              <Button className='send_message_btn' disabled={message == ""} onClick={() => {
                sendMessage()
              }} >
                <IoIosSend className='send_icon' />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modalData.show}>
        <ModalHeader>
          Toplu Mesaj
        </ModalHeader>
        <ModalBody>
          <Select
            showSearch
            onChange={(e) => {
              setModalData({
                ...modalData,
                data: e
              })
            }}
            value={modalData.data}
            style={{ width: "100%" }}
            filterOption={(input, option) => {
              return (
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0

              )
            }}
            options={[
              ...classList.map(x => {
                return {
                  "label": `${x?.name}-${x?.id}`,
                  "value": x?.id
                }
              })
              /*               {
                              label: "Bütün Sınıf",
                              value: "Bütün Sınıf"
                            },
                            ...registeredStundentList.map(el => {
                              return {
                                label: `${el.firstName} ${el.lastName} `,
                                value: el.id,
                              }
                            }) */
            ]}
          />
          <Input onChange={(e) => {
            setModalData({
              ...modalData,
              message: e.target.value
            })
          }} type='textarea' placeholder='Mesaj' rows={4} style={{ resize: "none", marginTop: "20px" }} />
        </ModalBody>
        <ModalFooter>
          <Button disabled={modalData.message == ""} onClick={(e) => {
            topluMesaj()
          }} >
            Gönder
          </Button>
          <Button className='btn btn-danger' onClick={() => {
            setModalData({
              show: false,
              data: null,
              message: ""
            })
          }} >
            Kapat
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default TeacherMessageDashboard



const StyledAhrefTag = styled.a`

    /* border-bottom: 1px solid white; */
    color: whitesmoke !important;
    transition: .3s all;
    z-index: 6;
    &:hover{
        color: white;
        opacity: .7;
    }
`

const StyledMessageTime = styled.span`
  color: #73EC8B;
  display: inline-block;
  margin-top: 5px;
`