import React from 'react'
import TablePageContainer from '../../../Components/Common/TablePageContainer'
import StudentSurveyDashboard from '../../../Components/Student/StudentSurvey/StudentSurveyDashboard'

const StudentSurveyPage = () => {
    return (
        <div className='page-content'  >
            <TablePageContainer title='Anketlerim' header={"Anketlerim"} visible={false} >
                <StudentSurveyDashboard />
            </TablePageContainer>
        </div>
    )
}

export default StudentSurveyPage