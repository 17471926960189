import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import { Button, Input, Modal, Select, Table } from 'antd'
import { FaSms } from 'react-icons/fa'
import { toast } from 'react-toastify'

const SmsCourseTab = () => {

    const [loading, setLoading] = useState(false)
    const [courseList, setCourseList] = useState([])
    const [studentList, setStudentList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState("")
    const getAllCourse = async () => {
        try {
            setLoading(true)
            const response1 = await axiosInstance.post("/courses/getAll?type=Online&status=0", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/courses/getAll?type=Online&status=0", {
                page: 0,
                pageSize: response1.data.count
            })
            const resultList = []

            response2.data?.items?.forEach(item => {
                if (resultList?.find(el => el?.id == item?.id) == undefined) {
                    resultList.push(item)
                }
            })
            setCourseList(resultList)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        message: ""
    })

    const getCourseRegisters = async (id) => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/courseRegisters/getAllForCourseId", {
                pageRequest: {
                    page: 0,
                    pageSize: 100
                },
                courseId: id
            })
            const result = response.data.items?.map(el => {
                const { user, ...rest } = el
                const { id, ...userRest } = user
                return {
                    ...rest,
                    ...userRest,
                    userId: id
                }
            })
            console.log("result", result)
            setStudentList(result)
        }
        catch (err) {
            console.log("err", err)
        }
        finally {
            setLoading(false)
        }
    }


    const sendSms = () => {

        const course = courseList?.find(item => item?.id == selectedCourse)
        Modal.confirm({
            title: `${course?.name} isimli kursa kayıtlı öğrencilere sms yollamak istediğinizden emin misiniz?`,
            okText: "Eminim",
            cancelText: "İptal",
            onOk: async () => {
                try {
                    setLoading(true)
                    console.log("stxx", studentList)
                    await axiosInstance.post("/users/AllUsersSendSms", {
                        phones: studentList?.map(item => item?.phone),
                        text: modalInfo.message
                    })
                    toast.success(`${course?.name} isimli kursa kayıtlı öğrencilere sms iletildi `, {
                        position: "top-center",
                        autoClose: 2500
                    })
                    setModalInfo({
                        visible: false,
                        message: ""
                    })
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })


    }



    useEffect(() => {
        getAllCourse()
    }, [])


    console.log("studentxxx", studentList)

    return (
        <StyledContainer>
            <StyledHeader>
                <StyledSelect
                    showSearch={true}
                    loading={loading}
                    onChange={(e) => {
                        getCourseRegisters(e)
                        setSelectedCourse(e)
                    }}
                    placeholder="Kurs Seçiniz"
                    options={courseList?.map(item => ({
                        label: `${item?.id}-${item?.name}`,
                        value: item?.id
                    }))}
                    filterOption={(input, option) => {
                        return (
                            option.label.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0

                        )
                    }}
                />
                <Button disabled={selectedCourse == ""} type='primary' onClick={() => {
                    setModalInfo({
                        visible: true,
                        message: ""
                    })
                }} style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px"
                }} >
                    <FaSms /> Gönder
                </Button>
            </StyledHeader>

            <StyledTableContainer >
                <Table
                    loading={loading}
                    dataSource={studentList}
                    scroll={{
                        x: 600
                    }}
                    columns={[
                        {
                            title: "UserId",
                            render: (row, record) => {
                                console.log("recrd", record)
                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )
                            }
                        },
                        {


                            title: "Ad Soyad",
                            render: (row, record) => {
                                console.log("recrd", record)
                                return (
                                    <p>
                                        {record?.firstName} {record?.lastName}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Telefon",
                            render: (row, record) => {
                                console.log("recrd", record)
                                return (
                                    <p>
                                        {record?.phone}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Email",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.email}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Tc No",
                            render: (row, record) => {
                                console.log("recrd", record)
                                return (
                                    <p>
                                        {record?.tc}
                                    </p>
                                )
                            }
                        }
                    ]}

                />

            </StyledTableContainer>
            <Modal open={modalInfo.visible} onCancel={() => {
                setModalInfo({
                    visible: false,
                    message: ""
                })
            }} title="Sms" footer={() => {
                return (
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }} >
                        <Button danger type='primary' onClick={() => {
                            setModalInfo({
                                visible: false,
                                message: ""
                            })
                        }} >
                            İptal
                        </Button>
                        <Button type='primary' loading={loading} disabled={modalInfo.message == ""} onClick={() => {
                            sendSms()
                        }} >
                            Gönder
                        </Button>
                    </div>
                )
            }} >
                <Input.TextArea placeholder='MESAJ' rows={6} value={modalInfo.message} style={{
                    resize: "none"
                }} onChange={(e) => {
                    setModalInfo({
                        ...modalInfo,
                        message: e.target.value
                    })
                }} />

            </Modal>
        </StyledContainer>
    )
}

export default SmsCourseTab

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledHeader = styled.div`
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    align-items: center;
`

const StyledSelect = styled(Select)`
    width: 400px;
`

const StyledTableContainer = styled.div`
    padding-top: 20px;
`