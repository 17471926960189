import { Breadcrumb } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const CourseVideoBreadCrumb = ({ courseInfo }) => {
 
    const courseCategory = useMemo(() => {
        return courseInfo?.category?.name
    }, [courseInfo])

    const courseType = useMemo(() => {
        return courseInfo?.type
    }, [courseInfo])

    function capitalizeWords(sentence) {
        return sentence?.toLowerCase()
            .split(' ') // Cümleyi kelimelere ayırır
            .map(word => word.toUpperCase()) // Her kelimenin ilk harfini büyük yapar
            .join(' '); // Kelimeleri yeniden birleştirir
    }

    const courseNameFormat = useMemo(() => {

        const name = capitalizeWords(courseInfo?.name)

        return name

    }, [courseInfo])

    return (
        <VideoPageBreadCrumbContainer>
            <Breadcrumb
                separator=">"
                items={[
                    {
                        title: courseInfo?.type == "Online" ? "Canlı Eğitim" : "Video Eğitim",
                    },
                    {
                        title: (
                            <p style={{ textTransform: "capitalize" }}>
                                {courseCategory}
                            </p>
                        ),
                    },
                    {
                        title: (
                            <p style={{fontSize:"12px"}} >
                                {courseNameFormat}
                            </p>
                        )
                    },
                ]}
            />
        </VideoPageBreadCrumbContainer>
    )
}

export default CourseVideoBreadCrumb

export const VideoPageBreadCrumbContainer = styled.div`
    padding-left:60px;
    display: flex;
    height: 60px;
    align-items: center;

    @media screen and (max-width:600px) {
        padding-left: 10px;
    }

`
