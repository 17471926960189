// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width:520px) {
    .active_course_container{
        display: block;
        
    }
    .active_course_item{
        margin-top: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Course/OfflineCourse/OfflineCourseDashboard.css"],"names":[],"mappings":"AAAA;IACI;QACI,cAAc;;IAElB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@media screen and (max-width:520px) {\n    .active_course_container{\n        display: block;\n        \n    }\n    .active_course_item{\n        margin-top: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
