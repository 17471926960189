import React, { useEffect, useState, useMemo } from 'react'
import { Button, Card, Col, Label, Row } from 'reactstrap'
import { MdOutlineRefresh, MdRefresh } from "react-icons/md";
import { PropagateLoader } from 'react-spinners'
import { Select, Button as AntButton, Table as AntTable } from 'antd'
import DataTable from 'react-data-table-component'
import { compareWithCurrentTime } from '../../../utils/utils'
import { format } from "date-fns"
import trLocale from "date-fns/locale/tr"
import styled from 'styled-components'
import { FaEye, FaFile, FaFileArchive } from 'react-icons/fa';
import EditOnlineContentModal from '../UpdateCourse/EditOnlineCourseContent/EditOnlineContentModal';

const StudentOnlineMeeting = ({ courseData, getStudentCourse }) => {
    const [activeType, setActiveType] = useState(0)
    const [contentModal, setContentModal] = useState({
        visible: false,
        data: null
    })


    const mainData = useMemo(() => {
        let test = courseData.map(item => {
            if (item.courseCalendars.length !== 0) {
                return item.courseCalendars.map(el => {
                    const { courseCalendars, ...restElements } = item
                    const { onlineCourseUrls, ...rest } = el
                    return {
                        ...restElements,
                        ...rest,
                        onlineCourseUrls: onlineCourseUrls[0]
                    }
                })
            }

        }).flatMap(x => x).filter(x => x != undefined)


        if (activeType == 0) {
            const ileriTarih = filtreleIleriTarihler(test, 0)
            return ileriTarih
        }
        else if (activeType == 1) {
            const gecmis = filtreleIleriTarihler(test, 1)
            return gecmis
        }
        else {
            const active = filtreleIleriTarihler(test, 2)
            return active
        }

    }, [courseData, activeType])


    function filtreleIleriTarihler(courses, status = 0) {

        if (status == 0) {

            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var ileriTarihler = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                return kursBaslangicTarihi > suankiTarih; // İleri tarihlerde olanları filtrele
            });
            ileriTarihler.sort(function (a, b) {
                return new Date(a.startTime) - new Date(b.startTime);
            });
            return ileriTarihler;  //bekleyenler
        }
        else if (status == 1) {
            var suankiTarih = new Date(); // Şu anki tarih ve zamanı al
            var gecmisTarihler = courses.filter(function (course) {
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBitisTarihi < suankiTarih; // Geçmiş tarihlerde olanları filtrele
            });
            gecmisTarihler.sort(function (a, b) {
                return new Date(b.startTime) - new Date(a.startTime);
            });
            return gecmisTarihler;
        }
        else {
            var suankiTarih = new Date();
            var simdikiZamandakiKurslar = courses.filter(function (course) {
                var kursBaslangicTarihi = new Date(course.startTime); // Kurs başlangıç tarihini al
                var kursBitisTarihi = new Date(course.endTime); // Kurs bitiş tarihini al
                return kursBaslangicTarihi < suankiTarih && suankiTarih < kursBitisTarihi; // Şu anki zamanın aralıkta olup olmadığını kontrol et
            });
            simdikiZamandakiKurslar.sort(function (a, b) {
                return Math.abs(new Date(a.startTime) - suankiTarih) - Math.abs(new Date(b.startTime) - suankiTarih);
            });
            return simdikiZamandakiKurslar;

        }
    }



    const openJoinUrl = (url) => {
        window.open(url, "_blank")
    }


    const joinLink = (row) => {
        
        const control = compareWithCurrentTime(row.endTime)
        if (!control) {
            return <AntButton danger type='primary' style={{ pointerEvents: "none" }} >
                Link Aktif Değil
            </AntButton>
        }
        else if (row?.onlineCourseUrls?.length == 0 || row?.onlineCourseUrls == undefined || row?.onlineCourseUrls?.meetingUrl == "Zaman Bekleniyor.") {
            return <AntButton type='primary' disabled style={{ backgroundColor: "#FF9800", border: "none", color: "white", fontSize: "8px" }}  >
                Zaman Bekleniyor
            </AntButton>
        }
        else {
            return <AntButton type='primary' onClick={() => {
                openJoinUrl(row?.onlineCourseUrls?.meetingUrl)
            }} >
                Katıl
            </AntButton>
        }
    }



    const KursEklemeHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    width: 100%;
`
    const SelectFilter = styled.select`
        width:  150px;
    `

    return (
        <>
            <Row>
                <Col lg={12} >
                    <KursEklemeHeader   >
                        <StyledAntSelect
                            value={activeType}
                            onChange={(e) => {
                                setActiveType(e)
                            }}
                            options={[
                                { value: 0, label: 'Bekleyen' },
                                { value: 1, label: 'Geçmiş' },
                                { value: 2, label: 'Aktif' },

                            ]}
                        />


                        <AntButton type='primary' style={{ display: "flex", alignItems: "center", columnGap: 10 }} onClick={() => {

                  
                            getStudentCourse()


                        }} >
                            Yenile<MdRefresh style={{ fontSize: "18px" }} />
                        </AntButton>


                    </KursEklemeHeader>
                </Col>
                <Col lg={12} >
                    <StyledDataTableContainer>
                        <AntTable
                            dataSource={mainData}
                            scroll={{
                                x: 800
                            }}
                            size='middle'
                            pagination={{
                                pageSize: 10
                            }}
                            columns={[
                                {
                                    title: "İsim",
                                    render: (row, record) => {
                                        return (
                                            <span>
                                                {record.name.length > 20 ? `${record.name.substring(0, 20)}...` : record.name}
                                            </span>
                                        )
                                    }
                                },
                                {
                                    title: "Başlangıç",
                                    render: (row, record) => {
                                        return (
                                            <p>
                                                <span>
                                                    {format(new Date(record.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
                                                </span>
                                            </p>
                                        )
                                    }
                                },
                                {
                                    title: "Kurs Süresi",
                                    render: (row, record) => {
                                        return (
                                            <span>
                                                {row.duration} (Dk)
                                            </span>
                                        )
                                    }
                                },
                                {
                                    title: "Kurs Dosyaları",
                                    render: (row, record) => {
                                        return (
                                            <AntButton type='primary' onClick={() => {
                                      
                                                setContentModal({
                                                    visible: true,
                                                    data: record
                                                })
                                            }} >
                                                <FaFileArchive />
                                            </AntButton>
                                        )
                                    }
                                },
                                {
                                    title: "Bağlantı",
                                    render: (row, record) => {
                                        return joinLink(record)
                                    }
                                }
                            ]}
                        />

                    </StyledDataTableContainer>

                </Col>

            </Row >
            <EditOnlineContentModal addFile={false} modalData={contentModal} setModalData={setContentModal} calendarList={[]} apiRequest={() => {

            }}

            />
        </>
    )
}

export default StudentOnlineMeeting

const StyledDataTableContainer = styled.div`
    margin-top: 20px;
`

const StyledAntSelect = styled(Select)`
    width: 150px;
    
    .ant-select-selector {
  border: 1px solid #3498db !important; /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */
}
`