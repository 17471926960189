import axios from "axios";
import { axiosInstance } from "./axiosInstance";

const getAllCourseByStatusApi = (data, query, status = 0) => axiosInstance.post(`/Courses/GetAll?type=${query}&status=${status}`, data)

const GetCalendarForAllRoles = (data) => axiosInstance.post("/courses/GetCalendarForAllRoles", data)

const CourseAdminListGetAll = (data) => axiosInstance.post("/CourseAdministrators/GetAll", data)

const createCourseApi = (data) => axiosInstance.post("/courses/add", data)

const CourseRegisterStudentApi = (data) => axiosInstance.post("/courseRegisters/GetAllForStudent", data)

const CourseRegisterGetAll = (data) => axiosInstance.post("/CourseRegisters/GetAll", data)

const CreateCourseCalendar = (data) => axiosInstance.post("/CourseCalendars/add", data)

const CreateAddOneDateApi = (body) => axiosInstance.post("/CourseCalendars/AddOneDate", body)

const CourseMeetingsForStudentApi = (data, courseId) => axiosInstance.post(`/courseDates/getAllCalendarForCourse?courseId=${courseId}`, data)

const getAllForRegistredUser = (data, courseId) => axiosInstance.post(`/OnlineCourseUrls/GetAllForRegistredUser?courseId=${courseId}`, data)



const getDetailCourseApi = (id) => axiosInstance.get(`/courses/GetById/${id}`)

const updateCourseApi = (data) => axiosInstance.put("/courses/update", data)

const registerCourseApi = (data) => axiosInstance.post("/CourseRegisters/Add", data)

const addCourseAdministrators = (data) => axiosInstance.post("/CourseAdministrators/Add", data)

const deleteCourseAdministratorsApi = (id) => axiosInstance.delete(`/CourseAdministrators/Delete/${id}`)

const administratorsGetAll = (data) => axiosInstance.post("/CourseAdministrators/GetAll", data)


const CourseCalendarGetById = (id, data) => axiosInstance.post(`/CourseCalendars/GetByCourseId?courseId=${id}`, data)

const DeleteCourseCalendarApi = (id) => axiosInstance.delete(`/CourseCalendars/Delete/${id}`)

const GetAllTeacherAndStudentNotification = (data) => axiosInstance.post("/CourseRegisters/GetAllTeacherAndStudentForNotifications", data)

const deleteCourseApi = (data) => axiosInstance.delete("/course/delete", {
    data: {
        ...data
    }
})

const deleteCourseSendEmailApi = (id) => axiosInstance.post(`/course/${id}`)



const deleteDocumentApi = (data) => axiosInstance.delete("/course/document", {
    data: {
        ...data
    }
})

const addPhotoApi = (data) => axiosInstance.post("/course/photo", data, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
})


const addDocumentApi = (data) => axiosInstance.post("/course/document", data, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
})

const deletePhotoApi = (data) => axiosInstance.delete(`/course/photo`, {
    data: {
        ...data
    }
})
const updateCourseProgramApi = (data) => axiosInstance.post("/course/program", data)


const deleteAnnouncementApi = (data) => axiosInstance.delete("/course/announcement", {
    data: {
        ...data
    }
})

const DynamicCourseSearch = (data, query) => axiosInstance.post(`/Courses/GetAllByDynamic?Page=${query.page}&PageSize=${query.pageSize}`, data)

const createAnnouncementApi = (data) => axiosInstance.post("/course/announcement", data)

const updateAnnouncementApi = (data) => axiosInstance.put("/course/announcement", data)




export {
    getAllCourseByStatusApi,
    getDetailCourseApi,
    createCourseApi,
    updateCourseApi,
    deleteCourseApi,
    deleteCourseSendEmailApi,
    addDocumentApi,
    addPhotoApi,
    deleteDocumentApi,
    deletePhotoApi,
    updateCourseProgramApi,
    deleteAnnouncementApi,
    createAnnouncementApi,
    updateAnnouncementApi,
    addCourseAdministrators,
    GetCalendarForAllRoles,
    CourseAdminListGetAll,
    registerCourseApi,
    CourseRegisterStudentApi,
    CourseMeetingsForStudentApi,
    getAllForRegistredUser,
    CourseRegisterGetAll,
    CreateCourseCalendar,
    administratorsGetAll,
    DynamicCourseSearch,
    CourseCalendarGetById,
    GetAllTeacherAndStudentNotification,
    CreateAddOneDateApi,
    DeleteCourseCalendarApi,
    deleteCourseAdministratorsApi
}