import React, { useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import UpdateCourseForm from '../../Components/Course/UpdateCourse/UpdateCourseForm/UpdateCourseForm'
import styled from 'styled-components'

const CourseUpdatePage = () => {
    const [title, setTitle] = useState()
    return (
        <div className='page-content'>
            <TablePageContainer title={title} header={title} visible={false}   >
                <StyledContainer>
                    <UpdateCourseForm setTitle={setTitle} />
                </StyledContainer>
            </TablePageContainer>
        </div>
    )
}


const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:500px)  {
        padding: 7px;
    }
`



export default CourseUpdatePage