import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PropagateLoader } from 'react-spinners'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from "yup"
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap'
import { cityList } from "../../constants/cityList"
import { getUserByIdApi, updateUserApi } from '../../../api/UserApi'
import styled from 'styled-components'
import { Button as AntButton, Modal as AntModal } from "antd"

const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
const eightyYearsAgo = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate());

const EditStudentInfoTab = ({ setTitle, formik, loading }) => {




    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", padding: "20px" }} >
                <PropagateLoader color="#36d7b7" />
            </div>
        )
    }

    return (
        <Form onSubmit={formik.handleSubmit}  >
            <Row  >
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="firstnameInput" className="form-label">
                            İsim
                        </Label>
                        <StyledInput disabled type="text" className="form-control" id="firstName" name='firstName'
                            value={formik.values.firstName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.firstName && formik.errors.firstName ? true : false
                            }
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <FormFeedback type="invalid"><div>{formik.errors.firstName}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="lastnameInput" className="form-label">
                            Soyisim
                        </Label>
                        <StyledInput disabled type="text" className="form-control" id="lastName"
                            placeholder="Soyadı" name='lastName' value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.lastName && formik.errors.lastName ? true : false
                            }
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <FormFeedback type="invalid"><div>{formik.errors.lastName}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Tc No
                        </Label>
                        <StyledInput type="text" className="form-control disabled-input"
                            value={formik.values.tc}
                            disabled
                        />
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Doğum tarihi
                        </Label>
                        <StyledInput
                            disabled
                            name="birthDate"
                            type="date"
                            placeholde1r="Doğum Tarihi"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.birthDate}
                            invalid={
                                formik.touched.birthDate && formik.errors.birthDate ? true : false
                            }
                        />
                        {formik.touched.birthDate && formik.errors.birthDate ? (
                            <FormFeedback type="invalid"><div>{formik.errors.birthDate}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="phonenumberInput" className="form-label">
                            Telefon
                        </Label>
                        <StyledInput type="text" className="form-control disabled-input"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            id="phone"
                            name='phone'
                            value={formik.values.phone}
                            invalid={
                                formik.touched.phone && formik.errors.phone ? true : false
                            }
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <FormFeedback type="invalid"><div>{formik.errors.phone}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label ">Email</Label>
                        <Input type="email" className="form-control"
                            name='email'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            invalid={
                                formik.touched.email && formik.errors.email ? true : false
                            }
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <FormFeedback type="invalid"><div>{formik.errors.email}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">
                            Cinsiyet
                        </Label>
                        <StyledSelectGender disabled className='form-control' value={formik.values.gender} name='gender' onChange={formik.handleChange} onBlur={formik.handleBlur} >
                            <option value="Erkek">
                                erkek
                            </option>
                            <option value="Kadın">
                                kadın
                            </option>
                        </StyledSelectGender>
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="mb-3">
                        <Label htmlFor="firstnameInput" className="form-label">
                            Adres
                        </Label>
                        <Input type="textarea" style={{ resize: "none" }} className="form-control" id="address" name='address'
                            value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.address && formik.errors.address ? true : false
                            }
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <FormFeedback type="invalid"><div>{formik.errors.address}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={12} style={{ marginTop: "20px" }} >
                    <AntButton type="primary" size='middle' style={{ padding: "0 40px" }} onClick={() => {
                        formik.handleSubmit()
                    }} >
                        Güncelle
                    </AntButton>
                </Col>

            </Row>
        </Form>
    )
}

export default EditStudentInfoTab


const StyledSelectGender = styled.select`
    padding: 6px 10px;
`
const StyledInput = styled(Input)`
    height: 35px;
    margin-left: 0;
`
