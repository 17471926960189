import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CreateOfflineCourseDashboard from '../../Components/Course/OfflineCourse/CreateOfflineCourseDashboard'
import styled from 'styled-components'

const CreateOfflineCoursePage = () => {
  return (
    <div className='page-content'>
      <TablePageContainer title='Çevrimdışı Kurs Ekleme' header={"Çevrimdışı Kurs Ekle"} visible={false}  >
        <StyledContainer>
          <CreateOfflineCourseDashboard />
        </StyledContainer>

      </TablePageContainer>
    </div>
  )
}

const StyledContainer = styled.div`
  padding: 20px;

  @media screen and (max-width:600px) {
    padding: 10px;
  }
`

export default CreateOfflineCoursePage