import React from 'react'
import styled from 'styled-components'
import { BsTwitterX } from 'react-icons/bs'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import KuzemLogo from "../../../../src/assets/images/KuzemBlueLogo.png"

const CourseVideoFooter = ({ offlineLength }) => {
    return (
        <StyledFooterContainer fixed={offlineLength == 0}  >

            <img src={KuzemLogo} alt="" id='logoBel1' />
            <div className='social_media_container'>
                <BsTwitterX className='social_icon' />
                <FaFacebook className='social_icon' />
                <FaLinkedin className='social_icon' />
                <FaInstagram className='social_icon' />
            </div>
        </StyledFooterContainer>
    )
}


const StyledFooterContainer = styled.footer`
   background-color: white !important;
 /*   position: fixed; */
 position: ${props => props.fixed ? "fixed" : "static"};
  bottom: 0;
   width: 100%;
   z-index: 1000;
 /*   right: 0;
   bottom: 0; */
         height: 70px;
       /*  padding: 15px; */
        padding-left: 60px;
        padding-right: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:600px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    #logoBel1 {
               height: 50px;
                margin-right: 20px;
               
                @media screen and (max-width:500px) {
                    height: 35px;
                    margin-right: 10px;
                }
            }


            .social_media_container{
                display: flex;
                align-items: center;
                column-gap: 20px;
                .social_icon{
                    font-size: 20px;
                    
                }
            }
`

export default CourseVideoFooter