import React, { useState } from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import styled from 'styled-components'
import SmsStudentTab from './SmsStudentTab'
import SmsCourseTab from './SmsCourseTab'

const SmsDashboard = () => {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <StyledContainer>
            <StyledNav tabs >
                <StyledNavItem active={true} >
                    <NavLink
                        style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                        /* className={`${activeTab == 1 && "active"}`} */
                        onClick={() => {
                            setActiveTab(1)
                        }}
                    >
                        Öğrenciler
                    </NavLink>
                </StyledNavItem>
                <StyledNavItem active={true} >
                    <NavLink
                        style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                        /* className={`${activeTab == 1 && "active"}`} */
                        onClick={() => {
                            setActiveTab(2)
                        }}
                    >
                        Kurslar
                    </NavLink>
                </StyledNavItem>
            </StyledNav>
            <StyledContentContainer>
                {
                    activeTab == 1 && (
                        <SmsStudentTab />
                    )
                }
                {
                    activeTab == 2 && (
                        <SmsCourseTab />
                    )
                }

            </StyledContentContainer>

        </StyledContainer>
    )
}

export default SmsDashboard


const StyledContainer = styled.div`
        padding: 10px;
`

const StyledContentContainer = styled.div`
    margin-top: 15px;
`

const StyledNav = styled(Nav)`
    width: 100%;
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /* Firefox için */
    scrollbar-width: none;

    /* Diğer tarayıcılar için */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    /* Edge ve IE 11 için */
    & {
        -ms-overflow-style: none; 
    }
`
const StyledNavItem = styled(NavItem)`
    white-space: nowrap;
`