import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CourseCalendarGetById, GetCalendarForAllRoles } from '../../../../api/Course'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Card, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { formatDate } from "date-fns"
import trLocale from "date-fns/locale/tr"
import { Select, message, Button as AntButton, Table as AntTable } from 'antd'
import { toast } from 'react-toastify'
import { FaEye, FaFileArchive } from 'react-icons/fa'
import { addOnlineCourseContentApi } from '../../../../api/CourseContent'
import DataTable from 'react-data-table-component'

import { BsArrowDown } from 'react-icons/bs'
import EditOnlineContentModal from './EditOnlineContentModal'
import { UpdateCourseContext } from '../UpdateCourseForm/UpdateCourseForm'


const EditOnlineCourseContent = () => {



  const { id } = useParams()
  const [calendarList, setCalendarList] = useState([])
  const [courseContentList, setCourseContentList] = useState()
  const [contentModal, setContentModal] = useState({
    visible: false,
    data: null
  })

  const getCalendar = async () => {
    try {
      const response = await CourseCalendarGetById(id, {
        page: 0,
        pageSize: 1000
      })

      let formatData = response.data.items.map(el => {
        const { onlineCourseUrls, ...rest } = el
        return {
          ...rest,
          courseCalendarId: onlineCourseUrls[0]?.courseCalendarId
        }
      })

      setCalendarList(formatData)
    }
    catch (err) {
  
    }
  }
  const courseDateList = useMemo(() => {
    if (calendarList.length !== 0) {
      return calendarList.map(item => {
        const { startTime, endTime } = item
        return {
          ...item,
          startTime: formatDate(new Date(startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }),
          endTime: formatDate(new Date(endTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })
        }
      })
    }
    return []
  }, [calendarList])




  const getOnlineContentList = async () => {
    try {
      const response = await GetCalendarForAllRoles({
        page: 0,
        pageSize: 1
      })
      const responsev2 = await GetCalendarForAllRoles({
        page: 0,
        pageSize: response.data.count
      })
  
      const selected = responsev2.data?.items?.find(el => el.id == id)
      setCourseContentList(selected)
    }
    catch (err) {

    }
  }

  const tableData = useMemo(() => {
    if (courseContentList) {
      return courseContentList?.courseCalendars
    }
    return []
  }, [courseContentList])


  useEffect(() => {
    getCalendar()
    getOnlineContentList()
  }, [])




  return (
    <>
      <AntTable
        size='middle'
        scroll={{
          x: 800
        }}
        pagination={{
          pageSize: 10
        }}
        dataSource={tableData}
        columns={[{
          title: "Başlangıç",
          render: (row, record) => {
            return (
              <span  >
                {formatDate(new Date(record.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
              </span>
            )
          },
          sorter: (a, b) => new Date(a.startTime) - new Date(b.startTime)
        },
        {
          title: "Bitiş",
          render: (row, record) => {
            return (
              <span  >
                {formatDate(new Date(record.endTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })}
              </span>
            )
          },
          sorter: (a, b) => new Date(a.endTime) - new Date(b.endTime)
        },
        {
          title: "Süre",
          render: (row, record) => {
            return (
              <span> {record?.duration} (dk) </span>
            )
          },
          sorter: (a, b) => a?.duration - b?.duration
        },
        {
          title: "işlem",
          render: (row, record) => {
            return (
              <AntButton type='primary' onClick={() => {
                setContentModal({
                  visible: true,
                  data: record
                })
              }} >
                <FaEye />
              </AntButton>
            )
          }
        }

        ]}
      />

      <EditOnlineContentModal addFile={true} modalData={contentModal} setModalData={setContentModal} apiRequest={getOnlineContentList} calendarList={courseDateList} />

    </>
  )
}

export default EditOnlineCourseContent


const StyledContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 20px;
`

const StyledHeader = styled.header`
display: flex;
justify-content: flex-end;
`
const StyledFooter = styled.footer`
display: flex;
width: 100%;
justify-content: space-between;
`

const StyledSelectedFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

`

const StyledModalHeader = styled(ModalHeader)`
  h5 {
    display: flex;
    width: 100%;
     align-items: center;
     justify-content: space-between;
  }
`