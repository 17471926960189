import { Button, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { axiosInstance } from '../../../../api/axiosInstance'
import { useParams } from 'react-router-dom'

const StudenAbsenceModel = ({ modalInfo, setModalInfo }) => {

    const [loading, setLoading] = useState(false)
    const [absenceData, setAbsenceData] = useState([])
   
    const { id } = useParams()
    const fetchAbsence = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.get(`/Absences/GetStudentResult?userId=${modalInfo.data?.userId}&courseId=${id}`)

            setAbsenceData([response.data])
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (modalInfo.data) {
            fetchAbsence()
        }

    }, [modalInfo])


    return (
        <Modal isOpen={modalInfo.visible} size='lg' >
            <ModalHeader>
                Devamsızlık Durumu
                <span style={{ color: "red", marginLeft: "10px" }} >
                    ( Bu bilgiler eğitim başladığından itibaren hesaplanır )
                </span>
            </ModalHeader>
            <ModalBody>
                <Table
                    loading={loading}
                    dataSource={absenceData}
                    scroll={{
                        x: 600
                    }}
                    pagination={false}
                    columns={[
                        {
                            title: "Toplam Aktif Süre(dk)",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.totalActiveTime?.toFixed(3)}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "Devamsızlık (%)",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.discontinuity}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "Toplam Eğitim Süresi (dk)",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.totalCourseTime}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "Kalan Devamsızlık (dk)",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.remainingAbsenceTime?.toFixed(2)}
                                    </p>
                                )

                            }
                        },
                        {
                            title: "Durum",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.message}
                                    </p>
                                )
                            }
                        }
                    ]}
                />
            </ModalBody>
            <ModalFooter>
                <Button type='primary' danger onClick={() => {
                    setModalInfo({
                        visible: false,
                        data: null
                    })
                }}  >
                    Kapat
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default StudenAbsenceModel