// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exam_update_general_info_container {
  margin-top: 20px; }
  .exam_update_general_info_container .update_btn_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
`, "",{"version":3,"sources":["webpack://./src/Components/ExamUpdate/ExamGeneralInfo/examGeneralInfo.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB,EAAA;EADpB;IAKQ,aAAa;IACb,8BAA8B;IAC9B,qBAAqB,EAAA","sourcesContent":[".exam_update_general_info_container{\n    margin-top: 20px;\n    \n\n    .update_btn_container{\n        display: flex;\n        justify-content: space-between;\n        align-items: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
