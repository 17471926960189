// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course_list_dashboard_accordion_header_item {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FAFAFA;
  padding-right: 40px; }
  .course_list_dashboard_accordion_header_item_img {
    height: 100%; }
  .course_list_dashboard_accordion_header_item .collapse_header_arrow_down_icon {
    font-size: 30px; }
    @media screen and (max-width: 600px) {
      .course_list_dashboard_accordion_header_item .collapse_header_arrow_down_icon {
        font-size: 16px; } }
  .course_list_dashboard_accordion_header_item .course_list_header_content {
    width: 100%;
    padding-left: 20px; }
    .course_list_dashboard_accordion_header_item .course_list_header_content_title {
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize; }
      @media screen and (max-width: 600px) {
        .course_list_dashboard_accordion_header_item .course_list_header_content_title {
          font-size: 12px; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Course/Common/customAccordionHeader.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB,EAAA;EAEnB;IACI,YAAY,EAAA;EATpB;IAaQ,eAAe,EAAA;IAEf;MAfR;QAgBY,eAAe,EAAA,EAEtB;EAlBL;IAqBQ,WAAW;IACX,kBAAkB,EAAA;IAtB1B;MAyBY,eAAe;MACf,gBAAgB;MAChB,0BAA0B,EAAA;MAE1B;QA7BZ;UA8BgB,eAAe,EAAA,EAEtB","sourcesContent":[".course_list_dashboard_accordion_header_item {\n    height: 100px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #FAFAFA;\n    padding-right: 40px;\n\n    &_img {\n        height: 100%;\n    }\n\n    .collapse_header_arrow_down_icon {\n        font-size: 30px;\n\n        @media screen and (max-width:600px)  {\n            font-size: 16px;\n        }\n    }\n\n    .course_list_header_content {\n        width: 100%;\n        padding-left: 20px;\n        \n        &_title {\n            font-size: 20px;\n            font-weight: 600;\n            text-transform: capitalize;\n\n            @media screen and (max-width:600px)  {\n                font-size: 12px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
