import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import AdminStatisticDashboard from '../../Components/AdminStatisticDashboard/AdminStatisticDashboard'

const AdminStatisticPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer header={"İstatistikler"} visible={false} >
                <AdminStatisticDashboard />
            </TablePageContainer>
        </div>
    )
}

export default AdminStatisticPage