import React, { useEffect, useRef, useState } from 'react'
import { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
const Delta = Quill.import('delta')
const TiptapEditor = () => {

    const [description, setDescription] = useState("")
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setPhoto(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };



    const [editorContent, setEditorContent] = useState('');
    const quillRef = useRef(null); // Quill editörünü DOM'da referans almak için kullanılır

    useEffect(() => {
        if (quillRef.current) {
            // Quill'i başlat
            const quill = new Quill(quillRef.current, {
                theme: 'snow', // Quill'in tema seçimi,

                formats: [
                    'header', 'font', 'size',
                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                    'list', 'bullet', 'indent',
                    'align', 'color', 'background',
                    'link', 'image'
                ],

                modules: {
                    toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'align': [] }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                        ['image'],
                        [{ 'color': [] }, { 'background': [] }],
                    ],
                    clipboard: {
                        matchVisual: false
                    },
                    imageResize : ImageResize
                },

            });
            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                const ops = delta.ops.map((op) => ({ insert: op.insert }));
                return new Delta(ops)
            })
            // Quill içeriğini güncelleme
            quill.on('text-change', () => {
                setEditorContent(quill.root.innerHTML);
            });

        }
    }, []);

    return (
        <div>
            <div ref={quillRef} style={{ height: 'max-content' }}></div>
        </div>
    )
}

export default TiptapEditor