import React, { useContext, useEffect, useMemo, useState } from 'react'
import MainFooter from '../../components/mainFooter/MainFooter'
import LogoBelediye from "../../../../src/assets/images/logo.png"
import VerticalLine from "../../../assets/images/lineVertical.png"
import "./videoPage2.scss"
import Trophy from "../../../assets/images/trophy.png"
import { CourseViewsAddApi, CourseViewsForStudentApi, courseContentForCourseId } from '../../../api/CourseContent'
import { useLocation, Link, useNavigate, useParams } from "react-router-dom"
import { FaCirclePlay } from "react-icons/fa6";
import {
    UncontrolledAccordion,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button
} from 'reactstrap';
import ProfileDropdown from '../../components/ProfileDropdown/ProfileDropdown'
import { UserContext } from '../../../context/user'
import { Progress, Skeleton } from 'antd'
import { FaChevronDown, FaLock } from 'react-icons/fa'
import styled from 'styled-components'


const VideoPage2 = () => {
    const { id } = useParams()
    const [statusControl, setStatusControl] = useState({
        status: false,
        message: ""
    })
    const [responseViewsList, setResponseViewsList] = useState({
        progress: "",
        items: []
    })
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [selectedVideo, setSelectedVideo] = useState()
    const navigate = useNavigate()
    const [open, setOpen] = useState('1');
    const [context, dispatch] = useContext(UserContext)

    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    const getCourseVideo = async () => {
        try {
            setLoading(true)
            const response = await courseContentForCourseId({
                page: 0,
                pageSize: 100
            }, id)
        
            let temp = response.data.items
        
            setStatusControl({
                message: response.data.message,
                status: response.data.status
            })
            setData(temp)

            if (response.data.status) {
                const responseViewsData = await CourseViewsForStudentApi(id)
                setResponseViewsList(responseViewsData.data)
            }


        }
        catch (err) {
       
        }
        finally {
            setLoading(false)
        }
    }


    const courseVideoCount = useMemo(() => {
        return data.reduce((acc, el) => {
            return acc += el?.courseFiles?.length
        }, 0)
    }, [data])

    const courseInfo = useMemo(() => {


        let lastElement = data[data.length - 1]
 
        return lastElement?.course
    }, [data])




    const videoLockControl = useMemo(() => {
        if (!statusControl.status) {
            return false
        }
        else {
            return open
        }
    }, [statusControl.status, open])


    const ExistWatchVideo = async (element, videoItem) => {
        try {
        
            if (!element?.success) {
                const response = await CourseViewsAddApi({
                    courseFileId: element.courseFileId,
                    courseId: id
                })

                setResponseViewsList({
                    ...responseViewsList,
                    items: responseViewsList.items.map(el => {
                        if (el.courseFileId == element.courseFileId) {
                            return {
                                ...el,
                                success: true
                            }
                        }
                        return el
                    })
                })
                setSelectedVideo(videoItem)
            }
            else {
                setSelectedVideo(videoItem)
            }
        }
        catch (err) { }
    }

    const circlePercent = useMemo(() => {
        if (statusControl.status) {
            
            let percentValue = responseViewsList.progress.split("%")[1]
            return parseInt(percentValue?.trim())
        }

    }, [responseViewsList, statusControl])



    useEffect(() => {
        getCourseVideo()
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let flatx = data?.map(el => el?.courseFiles).flatMap(x => x)
    

    if (loading) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, height: "100vh", padding: 20 }} >
                <Skeleton active={true} />
            </div>
        )
    }



    return (
        <div className='offline_video_page_container' >
            <div className='offline_video_page_header'>
                <div className='offline_video_page_header_leftContent' >
                    <Link to={"/"}>
                        <img src={LogoBelediye} alt="" id='logoBel' />
                    </Link>
                    <img src={VerticalLine} alt="" id='lineVertical' />
                    <div className='offline_video_page_header_leftContent_title_wrapper'  >
                        <h5 className='offline_video_page_course_title' >
                            {courseInfo?.name?.length > 20 ? `${courseInfo?.name?.substring(0, 20)}...` : courseInfo?.name}
                        </h5>
                    </div>
                </div>
                {
                    statusControl.status && (
                        <Progress size={60} strokeColor={"#3ABEF9"} type="circle" percent={circlePercent ?? 0} format={() => {
                            return (
                                <img src={Trophy} width={37} />

                            )
                        }} />
                    )
                }
            </div>
            {
                !statusControl.status && (
                    <div class="alert alert-warning text-center" role="alert" >
                        <p style={{ fontSize: "18px", cursor: "pointer" }} onClick={() => {

                            if (statusControl.message == "Lütfen giriş yapınız.") {
                                navigate(`/giris?page=panel/kurs/${id}`)
                            }
                            else {
                                navigate(`/panel/kurs/${id}`)
                            }
                        }} >
                            {statusControl.message == "Lütfen giriş yapınız." ? "Lütfen giriş yapınız. Giriş yapmak için tıklayınız" : "Erişebilmeniz için kayıt olmanız gerekmektedir, kayıt olmak için tıklayınız"}
                        </p>
                    </div>
                )
            }
            <div className='offline_video_page_main_content_container' >
                <div className='offline_watch_video_container'>
                    <div className='offline_watch_video_item'  >

                        {
                            selectedVideo ? (
                                <video controls src={selectedVideo.url} className='offline_selected_video'  ></video>
                            ) :
                                (
                                    <h1 style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "3%" }} >
                                        Video Seçilmedi
                                    </h1>
                                )
                        }

                    </div>
                    <div className='offline_watch_video_list' >

                        <Accordion
                            defaultOpen={[
                                "0",
                            ]}
                            stayOpen open={videoLockControl} toggle={toggle} >
                            {data.map((el, index) => {
                                return (
                                    <AccordionItem className='offline_watch_video_accordion_item'    >
                                        <StyledAccordionHeader targetId={`${index}`} >
                                            <p style={{ textTransform: "capitalize" }} >
                                                {
                                                    el.name.length > 20 ? el.name.substring(0, 20) + " ..." : el.name
                                                }
                                            </p>
                                            {
                                                statusControl.status ? (
                                                    <FaChevronDown />
                                                ) : (
                                                    <FaLock />
                                                )
                                            }
                                        </StyledAccordionHeader>
                                        <AccordionBody accordionId={`${index}`}>
                                            {
                                                el.courseFiles?.length !== 0 ? el.courseFiles.map((item) => {
                                            
                                                    let selectedCheck = responseViewsList.items.find(el => el.courseFileId == item.id)
                                                   
                                                    return (
                                                        <div className={`offline_watch_video_list_item ${selectedVideo?.id == item?.id ? "selected_video" : null}`} onClick={() => {

                                                            ExistWatchVideo(selectedCheck, item)
                                                        }}   >
                                                            <div className='offline_watch_video_list_item_left_wrapper' >
                                                                <FaCirclePlay className='offline_watch_video_list_item_play_icon' />
                                                                <p className='offline_watch_video_list_item_description' >
                                                                    {item.description.length > 20 ? item.description.substring(0, 25) + " ..." : item.description}
                                                                </p>
                                                            </div>
                                                            <input type='checkbox' checked={selectedCheck?.success} className='video_list_item_checkbox' />
                                                        </div>
                                                    )
                                                }) : (
                                                    <h6>
                                                        İçerik Henüz Yüklenmedi.
                                                    </h6>
                                                )
                                            }

                                        </AccordionBody>
                                    </AccordionItem>
                                )
                            })}

                        </Accordion>
                    </div>
                </div>
             
                <div className='offline_course_info_container' >
                    <div style={{ display: "flex", width: "100%" , overflow:"hidden"}}>
                        <p className='offline_course_info_name'>
                            {courseInfo?.name.toUpperCase()}
                        </p>
                    </div>

                    <div className='offline_course_info_container_content_wrapper'>
                        <div className='offline_course_info_container_content_wrapper_left_side'>
                           
                            <div className='offline_course_info_container_content_wrapper_left_side_item' >
                                <h5 className='offline_course_info_container_content_wrapper_left_side_item_title' >
                                    Eğitim Açıklaması
                                </h5>
                                <p className='offline_course_info_container_content_wrapper_left_side_item_desc'>
                             {courseInfo?.description}
                                </p>
                            </div>
                            <div className='offline_course_info_container_content_wrapper_left_side_item' >
                                <h5 className='offline_course_info_container_content_wrapper_left_side_item_title' >
                                    Sertifika
                                </h5>
                                <p className='offline_course_info_container_content_wrapper_left_side_item_desc'>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga assumenda eaque eum, sunt beatae necessitatibus eveniet dolore officia nesciunt, nam est quidem, veniam tempore enim alias reiciendis eius! Nisi, voluptate?Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus accusantium reprehenderit labore aperiam, exercitationem laudantium esse tempore officia, aut autem quas corporis id nemo? Mollitia voluptatum veritatis voluptatem nemo. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolorem laborum sapiente itaque tenetur, iusto maiores saepe ea. Cumque rerum voluptas quod possimus distinctio minima repellat nulla cupiditate? Deleniti, doloribus facilis.
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati tempora dolore corporis nemo quisquam sunt quae! Id voluptas non similique nihil, quos necessitatibus nisi modi, neque ex quo odio quisquam?
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Impedit, sint officiis minima architecto cum molestias harum et, deleniti voluptates, delectus temporibus in quibusdam distinctio aliquid quos laboriosam dolorum culpa repellendus.
                                </p>
                            </div>
                            <div className='offline_course_info_container_content_wrapper_left_side_item' >
                                <h5 className='offline_course_info_container_content_wrapper_left_side_item_title' >
                                    Bu Eğitim Kimler İçindir
                                </h5>
                                <p className='offline_course_info_container_content_wrapper_left_side_item_desc'>
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit vitae repudiandae eveniet tenetur quod vel quisquam molestias voluptate fuga voluptatibus itaque odit laborum ratione, sit sed corrupti cupiditate quasi adipisci?
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Pariatur incidunt, vero aperiam provident officiis ea eveniet a dicta culpa, ut consectetur suscipit! Ratione assumenda aut quod harum delectus excepturi illo?

                                </p>
                            </div>

                        </div>
                        <div className='offline_course_info_category_detail_container' >
                            <div className='offline_course_info_category_element' >
                                <h5>
                                    Kategori
                                </h5>
                                <p>
                                    {courseInfo?.category?.name}
                                </p>
                            </div>
                            <div className='offline_course_info_category_element' >
                                <h5>
                                    Eğitmen
                                </h5>
                                <p>
                                    {
                                        courseInfo?.courseAdministrator ?
                                            `${courseInfo?.courseAdministrator?.user?.firstName} ${courseInfo?.courseAdministrator?.user?.lastName} ` : "Eğitmen Seçilmedi"
                                    }

                                </p>
                            </div>

                            {/* <div className='offline_course_info_category_element' >
                                <h5>
                                    Ders Sayısı
                                </h5>
                                <p>
                                    {courseVideoCount}
                                </p>
                            </div> */}
                            <div className='offline_course_info_category_element' >
                                <h5>
                                    Sertifika
                                </h5>
                                <p>
                                    Evet
                                </p>
                            </div>
                            <div className='offline_course_info_category_element' >
                                <h5>
                                    Dil
                                </h5>
                                <p>
                                    Türkçe
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <MainFooter />
        </div>
    )
}

export default VideoPage2


const StyledAccordionHeader = styled(AccordionHeader)`

        .accordion-button {
            display: flex;
            justify-content: space-between;

            &::after{
                display: none;
            }
        }
`

const StyledMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #eee;
`