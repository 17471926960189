import React, { useEffect, useState } from 'react'
import { courseContentForCourseId } from '../../../api/CourseContent'

const CourseVideo = () => {

    const [data, setData] = useState([])
    const getCourseVideo = async () => {
        try {
            const response = await courseContentForCourseId({
                page: 0,
                pageSize: 100
            }, 21)
          
            let temp = [...response.data.items]
            setData(response.data.items)
        }
        catch (err) {

        }
    }



    useEffect(() => {
        getCourseVideo()
    }, [])

    return (
        <div className='offline_video_page_container' >
            
            {
                data.map(el => {
                    return (
                        <h3>
                            {el.name}
                        </h3>
                    )
                })
            }
        </div>
    )
}

export default CourseVideo