import { Button, Col, Row, Select, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { getUserListApi } from '../../api/UserApi'
import { axiosInstance } from '../../api/axiosInstance'
import { FaFilter } from 'react-icons/fa'
import { formatInTimeZone } from 'date-fns-tz'
import trLocale from "date-fns/locale/tr"

const PuantajDashboard = () => {
  const [loading, setLoading] = useState(false)
  const [teacherList, setTeacherList] = useState([])
  const [fetchParams, setFetchParams] = useState({
    teacherId: null,
    month: null,
    year: null
  })
  const [puantajData, setPuantajData] = useState()

  const getTeacherList = async () => {
    try {
      setLoading(true)
      const response = await getUserListApi({
        page: 0,
        pageSize: 10,
        roleId: 2
      })
      const responsev2 = await getUserListApi({
        page: 0,
        pageSize: response.data.count,
        roleId: 2
      })
      setTeacherList(response.data.items)
    }
    catch (err) {
      console.log("err", err)
    }
    finally {
      setLoading(false)
    }
  }

  const months = useMemo(() => {
    return ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
  }, [])

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear() + 1;
    const startYear = 2023;
    const yearArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearArray.push(year);
    }
    return yearArray;
  }, []);


  const getPuantajData = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/Absences/GetTeacherMonthlyTally", {
        teacherId: fetchParams?.teacherId,
        month: fetchParams.month,
        year: fetchParams?.year
      })
      setPuantajData(response.data)
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }

  const puantajList = useMemo(() => {
    return puantajData?.items
  }, [puantajData])


  useEffect(() => {
    getTeacherList()
  }, [])


  return (
    <StyledContainer>
      <StyledHeader>
        <Row style={{
          width: "100%"
        }}
          gutter={[
            {
              md: 20
            },
            {
              md: 12,
              xs: 6
            }
          ]}

        >
          <Col md={7} xs={24} >
            <div className='filter_element'>
              <p className='element_title'>
                Eğitmen :
              </p>
              <Select
                loading={loading}
                className='select_item'
                showSearch
                onChange={(e) => {
                  setFetchParams({
                    ...fetchParams,
                    teacherId: e
                  })
                }}
                placeholder="Eğitmen Seçiniz"
                filterOption={(input, option) => {

                  return (
                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                  )
                }}

                options={teacherList?.map(item => {
                  return {
                    label: `${item?.firstName} ${item?.lastName}`,
                    value: item?.id
                  }
                })}
              />
            </div>
          </Col>
          <Col md={7} xs={24} >
            <div className='filter_element'>
              <p className='element_title'>
                Ay :
              </p>
              <Select
                placeholder="Ay Seçiniz"
                loading={loading}
                className='select_item'
                onChange={(e) => {
                  setFetchParams({
                    ...fetchParams,
                    month: e
                  })
                }}
                showSearch
                filterOption={(input, option) => {

                  return (
                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                  )
                }}

                options={months?.map((item, index) => {
                  return {
                    label: item,
                    value: index + 1
                  }
                })}
              />
            </div>
          </Col>
          <Col md={7} xs={24} >
            <div className='filter_element'>
              <p className='element_title'>
                Yıl :
              </p>
              <Select
                placeholder="Ay Seçiniz"
                loading={loading}
                className='select_item'
                showSearch
                onChange={(e) => {
                  setFetchParams({
                    ...fetchParams,
                    year: e
                  })
                }}
                filterOption={(input, option) => {

                  return (
                    option.label?.toLocaleLowerCase()?.indexOf(input?.toLocaleLowerCase()) >= 0

                  )
                }}

                options={years?.map((item, index) => {
                  return {
                    label: item,
                    value: item
                  }
                })}
              />
            </div>
          </Col>
          <Col md={3} xs={24} >
            <Button type='primary' style={{
              width: "100%",
              display: "flex",
              columnGap: "5px",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center"
            }}
              disabled={fetchParams?.month == null || fetchParams.teacherId == null || fetchParams?.year == null}
              onClick={getPuantajData}
            >
              <FaFilter /> Filtrele
            </Button>
          </Col>
        </Row>
      </StyledHeader>
      <Table
        dataSource={puantajList}
        scroll={{
          x: 400
        }}
        bordered
        style={{
          width: "100%"
        }}
        pagination={{
          showSizeChanger: false
        }}
        loading={loading}
        footer={() => {
          return (
            <p style={{
              fontWeight: "bold"
            }}>
              Toplam Yapılan Eğitim Saati :  {puantajData?.totalHourDoesItEducation?.toFixed(2) ?? "0"}
            </p>
          )
        }}
        columns={[
          {
            title: "Zaman",
            render: (row, record) => {
              const date = formatInTimeZone(record?.date, 'Europe/Istanbul', "dd MMMM yyyy EEEE", { locale: trLocale });
              return (
                <p>
                  {date}
                </p>
              )
            },
            sorter: (a, b) => new Date(a?.date) - new Date(b?.date)
          },
          {
            title: "Toplam Süre",
            render: (row, record) => {
              return (
                <p>
                  {record?.totalMinutes} (Dk)
                </p>
              )
            }
          },
          {
            title: "Her bir eğitim süresi",
            render: (row, record) => {
              return (
                <p>
                  {record?.educationDuration} (Dk)
                </p>
              )
            }
          }
        ]}
      />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    row-gap: 20px;
`
const StyledHeader = styled.div`
  display: flex;
  
  .filter_element{
    display: flex;
    column-gap: 5px;
    align-items: center;
    width: 100%;
    .element_title{
      font-weight: bold;
    }

    .select_item {
     /*  width: 100%; */
      flex: 1;
    }
  }
`

export default PuantajDashboard