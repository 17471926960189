import React from "react";
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

const SplineAreaChart = ({dataColors}) => {
  /*   var areachartSplineColors = getChartColorsArray(dataColors);  */   
    const series = [{
        name: 'Geçen Ay',
        data: [31, 40, 28, 51, 42, 109, 100]
    },  {
        name: 'Bu Ay',
        data: [11, 32, 45, 32, 34, 52, 41]
    } ];
    var options = {
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
         colors: ['#8459EB', '#FF6905'], 
        xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
    };

    return (
        <React.Fragment >
             <Card style={{border:"none"}} >
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1"> <b>Kurs İzlenmesi</b>  </h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                    <span className="text-muted">Tümü<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                              
                            </UncontrolledDropdown>
                        </div>
                    </CardHeader>
                    </Card>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="area"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};
export default SplineAreaChart 