// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 990px) {
  #inputStable {
    margin-left: 0; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Student/StudentTable/StudentTable.scss"],"names":[],"mappings":"AAAA;EACI;IACI,cAAe,EAAA,EAElB","sourcesContent":["@media screen and (max-width:990px) {\n    #inputStable{\n        margin-left: 0 ;\n       \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
