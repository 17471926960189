import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import Quill from "quill"
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { Button as AntButton, Modal as AntModal, Input } from "antd"
import { useParams } from 'react-router-dom';
import { axiosInstance } from '../../../api/axiosInstance';
import { toast } from 'react-toastify';
import { ModalHeader, Modal } from 'reactstrap';
import Avatar from "../../../assets/images/profile.png"
import { FaCamera } from 'react-icons/fa';
import { IoCamera } from 'react-icons/io5';



const TeacherCv = () => {
    const [photo, setPhoto] = useState(Avatar);
    const [photoFile, setPhotoFile] = useState()
    const [description, setDescription] = useState("")
    const [cvData, setCvData] = useState()
    const [cvId, setCvId] = useState()
    const { id } = useParams()
    const handleFileChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            setPhoto(URL.createObjectURL(file))
            setPhotoFile(file)
        }
    };


    const addTeacherInformation = async () => {
        try {
            const formData = new FormData()
            formData.append("formFile", photoFile)
            const response = await axiosInstance.post(`/teacherInformations/add?userId=${id}&content=${description}`, formData)
            toast.success("Özgeçmiş eklendi", {
                autoClose: 2000,
                position: "top-center"
            })
            getTeacherInformation()
        }
        catch (err) {

        }
        finally {

        }
    }



    const getTeacherInformation = async () => {
        try {
            const response = await axiosInstance.get(`/TeacherInformations/GetByUserId/${id}`)
            setCvData(response.data)

            if (response.data?.firstAndLastName) {
                setPhoto(response.data?.imageUrl)
                setCvId(response.data.id)
                setDescription(response.data?.content)
            }


        }
        catch (err) {

        }

    }




    const updateTeacherInfo = async () => {
        try {
            if (photoFile) {
                const formData = new FormData()
                formData.append("formFile", photoFile)
                await axiosInstance.put(`/teacherInformations/update?userId=${id}&content=${description}&id=${cvId}`, formData)
                toast.success("Özgeçmiş güncellendi", {
                    position: "top-center",
                    autoClose: 2000
                })
                getTeacherInformation()
            }
            else {
                await axiosInstance.put(`/teacherInformations/update?userId=${id}&content=${description}&id=${cvId}`)
                toast.success("Özgeçmiş güncellendi", {
                    position: "top-center",
                    autoClose: 2000
                })
                getTeacherInformation()
            }

        }
        catch (err) {

        }
    }



    useEffect(() => {
        getTeacherInformation()
    }, [])



    return (
        <StyledEditorContainer>
            <StyledContentContainer>
                <PhotoElement>
                    <img className='img_photo' src={photo} style={{ width: "100%", height: "100%" }} alt="" />
                    <IoCamera className='camera_icon' onClick={() => {
                        document.getElementById('fileInput').click()
                    }} />
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleFileChange}
                    />
                </PhotoElement>
                <Input.TextArea value={description} placeholder='içerik' className='ant_input_desc' onChange={(e) => {
                    setDescription(e.target.value)

                }} />
            </StyledContentContainer>
            <StyledFooter>
                <AntButton disabled={description == ""} className='teacher_cv_save_btn' type='primary' onClick={() => {
                    AntModal.confirm({
                        title: "Bu özgeçmişi kaydetmek istediğinize emin misiniz ?",
                        cancelText: "İptal",
                        okText: "Eminim",
                        onOk: async () => {
                            try {
                                if (cvData.firstAndLastName) {
                                    updateTeacherInfo()
                                }
                                else {
                                    addTeacherInformation()
                                }

                            }
                            catch (err) {

                            }
                        }
                    })
                }} >
                    Kaydet
                </AntButton>
            </StyledFooter>

        </StyledEditorContainer>

    )
}

export default TeacherCv


const StyledEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .ql-container {
  height: 450px; /* Burada istediğiniz yüksekliği belirleyin */

  @media screen  and (max-width:600px) {
    height: 400px;
  }
}

.ql-editor {
  height: 400px; /* Editörün iç kısmının yüksekliği */

  @media screen  and (max-width:600px) {
    height: 300px;
  }
}
`
const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;

    .modal-content img {
        max-width: 100%; /* Resimlerin genişliğini modalin genişliğine sığdırır */
        height: auto; /* Oranları koruyarak yüksekliği otomatik ayarlar */
    }
`

const StyledEditorContent = styled.div`
    max-width: 100%;
    min-height: 300px;
    .ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
        
        img{
            max-width: 100%;
            height: auto;
        }
    
        blockquote {
    border-left: 5px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    color: #666;
    font-style: italic;
  }
`

const StyledModalHeader = styled(ModalHeader)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: sticky;
        top: 0;
        background-color: white;
        z-index: 10000;

    h5{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px dashed gray;
        padding: 5px 10px;
        border-radius: 10px;
   
    }
`
const StyledModal = styled(Modal)`
    max-height: 90vh;

    overflow-y: scroll;
`

const StyledContentContainer = styled.div`
    display: flex;
    margin-top: 30px;
    height: 200px;
    column-gap: 30px;
    .ant_input_desc{
        height: 100%;
        resize: none;
    }
`
const PhotoElement = styled.div`
    width: 280px;
    height: 100%;
    border: 1px solid gray;
    border-radius: 50%;
    position: relative;

    .img_photo{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
  
    }
    .camera_icon{
        position: absolute;   
        top: 10%;
        right: 2%;
        font-size: 32px;
        border-radius: 50%;
     /*    border: 1px solid white;
        padding: 3px; */
        color: black;
        cursor: pointer;
    }
`

/* 
<Modal isOpen={modalInfo.visible}>
<ModalHeader>
    Önizleme
</ModalHeader>
<ModalBody>

</ModalBody>
<ModalFooter>
    <AntButton danger type='primary' onClick={() => {
        setModalInfo({
            visible: false,
            content: ""
        })
    }}>
        Kapat
    </AntButton>
</ModalFooter>
</Modal> */