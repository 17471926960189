import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/user';
import { getUserByIdApi } from '../../api/UserApi';
import { CircleLoader } from 'react-spinners';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import ProfilePhotoTab from './ProfilePhotoTab';
import ProfileDetail from './ProfileDetail';
import ChangePasswordTab from './ChangePasswordTab';
import styled from 'styled-components';

const ProfileDashboard = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [userData, setUserData] = useState()
  const [state, dispatch] = useContext(UserContext)
  const navigate = useNavigate()
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  
  const getUserProfileData = async () => {
    try {
      
      let response = await getUserByIdApi(state.userId)
      
      setUserData(response.data)
    }
    catch (err) {

    }
  }
  useEffect(() => {
    getUserProfileData()
  }, [])

  return (
    <>
      {
        !userData ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircleLoader color="#36d7b7" />
          </div>
        ) : (
          <StyledMainWrapper >
            <Row>
              <Col xxl={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${activeTab == "1" ? "active" : ""}`}
                      onClick={() => {
                        tabChange("1");
                      }}>
                      <i className="fas fa-home"></i>
                      Profil Detay
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="#"
                      className={`${activeTab == "2" ? "active" : ""}`}
                      onClick={() => {
                        tabChange("2");
                      }}
                      type="button">
                      <i className="far fa-user"></i>
                      Parola Değiştir
                    </NavLink>
                  </NavItem>

                </Nav>
                <StyledContainer>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <ProfileDetail user={userData} setUser={setUserData} />
                    </TabPane>
                    <TabPane tabId="2">
                      <ChangePasswordTab />
                    </TabPane>


                  </TabContent>
                </StyledContainer>



              </Col>
            </Row>
          </StyledMainWrapper>
        )
      }
    </>
  )
}

export default ProfileDashboard

const StyledContainer = styled.div`
  margin-top: 30px;
  
`

const StyledMainWrapper = styled.div`

    @media screen and (max-width:600px) {
       padding: 10px;
    }
`