import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components';
import CoverPhoto from "../../../assets/images/b3.png"
import { axiosInstance } from '../../../api/axiosInstance';
import { useNavigate } from 'react-router-dom';
const OtherCourseSlider = () => {
    const [courseList, setCourseList] = useState([])
    const navigate = useNavigate()
    const settings = {
        dots: false,
        /*         infinite: true, */
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 2000,

        slidesToScroll: 1,      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1025, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: false
                }
            },
            {
                breakpoint: 860, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false
                }
            },
            {
                breakpoint: 500, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };



    const getCoverImg = (element) => {
        if (element?.url == "0" || element?.url == null || element?.url == undefined) {
            return CoverPhoto
        }
        return element?.url
    }

    const fetchData = async () => {
        try {
            const response = await axiosInstance.post(`/branches/getAll?type=Online`, {
                page: 0,
                pageSize: 6
            });
            const response2 = await axiosInstance.post(`/branches/getAll?type=Offline`, {
                page: 0,
                pageSize: 6
            });
            const values = [...response.data.items?.filter(item => item?.coursePreviewStatus), ...response2.data.items?.filter(item => item?.coursePreviewStatus)]
            setCourseList(values)
        } catch (error) {

        }
    };


    useEffect(() => {
        fetchData()
    }, [])


    return (
        <MainContainer>
            <h3>
                Bu Kurslarımızı Gördünüz mü ?
            </h3>
            <Slider {...settings}>
                {
                    courseList?.map((el, index) => {
                        return (
                            <StyledSliderCardContainer onClick={() => {

                            }} >
                                <Container2>
                                    <CoverImg src={getCoverImg(el)} alt="" />
                                    <div className='same_course_name_wrapper'>
                                        <p className='same_course_name'>
                                            {el?.name?.length > 30 ? `${el?.name?.substring(0, 30)}...` : el.name}
                                        </p>
                                    </div>

                                    <div className='same_course_card_footer'>
                                        <div className='category_el' >
                                            {el?.category?.name?.length > 15 ? `${el.category?.name?.substring(0, 15)}...` : el?.category?.name}
                                        </div>
                                        <Button type='primary' size='small' onClick={() => {
                                            navigate(`/kurs/${el?.label}`)
                                        }} >
                                            İncele
                                        </Button>
                                    </div>
                                </Container2>

                            </StyledSliderCardContainer>

                        )
                    })
                }



            </Slider>
        </MainContainer>

    )
}

export default OtherCourseSlider




const StyledSliderCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Container2 = styled.div`
    width: 90%;
    border: 1px solid #D1D5DB;
    border-radius: 10px;

    @media screen and (max-width:600px) {
        width: 100%;
    }

    .same_course_name_wrapper{
        height: 70px;
      /*   padding-top: 10px; */
        padding-left: 10px;
        padding-top: 10px;
        .same_course_name{
            
            font-size: 12px;
            text-transform: capitalize;
            color: #80949F;


        }

    }
    
    .same_course_card_footer{
        background-color: #EFEFEF;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;  
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category_el{
           /*  background-color: #B9F4B9; */
           /*  padding: 3px 10px; */
            border-radius: 5px;
            letter-spacing: 2px;
            width: max-content;
            text-transform: capitalize;
            font-size: 10px;
        }
    }
`

const CoverImg = styled.img`
    width:100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-height: 140px;
    max-height: 140px ;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
`