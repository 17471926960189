import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import VideoEgitimDetayDashboard from '../../Components/VideoEgitimDetayDashboard/VideoEgitimDetayDashboard'

const VideoEgitimDetay = () => {
  return (
    <div className='page-content' >
        <TablePageContainer visible={false} header={"Video eğitim detay"} >
           <VideoEgitimDetayDashboard />
        </TablePageContainer>
    </div>
  )
}

export default VideoEgitimDetay