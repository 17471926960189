import { Drawer, Select } from 'antd'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const MobileFilterElement = ({
    drawerOpen,
    onlineOrOffline,
    setOnlineOrOffline,
    setSelectedcategory,
    selectedCategory,
    catagories,
    setDrawerOpen
}) => {
    return (
        <StyledFilterDrawer width={"300px"} title="Filtrele" onClose={() => {
            setDrawerOpen(false)
        }} open={drawerOpen}>

            <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}  >
                <p style={{ fontWeight: "bold" }} >
                    Eğitim Tipi
                </p>
                <Select style={{ width: "100%" }} value={onlineOrOffline} onChange={(e) => {
                    setOnlineOrOffline(e)
                    toast.success("filtreleme başarılı", {
                        position: "top-center",
                        autoClose: 700
                    })
                }} options={[
                    {
                        "label": "Hepsi",
                        "value": ""
                    },
                    {
                        "label": "Videolu Eğitim",
                        "value": "offline"
                    },
                    {
                        "label": "Canlı Eğitim",
                        "value": "online"
                    }

                ]} />
            </div>
            <StyledCategoryWrapper  >
                <p style={{ fontWeight: "bold" }} >
                    Kategori
                </p>
                <Select style={{ width: "100%" }} value={selectedCategory} onChange={(e) => {
                    setSelectedcategory(e)
                    toast.success("filtreleme başarılı", {
                        position: "top-center",
                        autoClose: 700
                    })
                }} options={catagories.map(item => {
                    return {
                        "label": item,
                        "value": item
                    }
                })} />
            </StyledCategoryWrapper>
        </StyledFilterDrawer>
    )
}

export default MobileFilterElement


const StyledFilterDrawer = styled(Drawer)`
    display: none;

    @media screen and (max-width:600px) {
        display: block;
        z-index: 999999999999999999999999;
        background-color: red;
       /*  width: 300px  !important; */
    }

`

const StyledCategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
`

