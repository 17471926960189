import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import OgrenciDevamsızlıkDashboard from '../../Components/OgrenciDevamsızlıkDashboard/OgrenciDevamsızlıkDashboard'

const OgrenciDevamsızlıkPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Devamsızlık"} >
                <OgrenciDevamsızlıkDashboard />
            </TablePageContainer>
        </div>
    )
}

export default OgrenciDevamsızlıkPage