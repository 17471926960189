// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 400px) {
  .parent-div-text-course {
    display: none !important; }
  .text-progress {
    font-size: 10px !important; } }
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Progress_Bar.scss"],"names":[],"mappings":"AACA;EACI;IACI,wBAAwB,EAAA;EAE5B;IACI,0BAA0B,EAAA,EAC7B","sourcesContent":["\n@media screen and(max-width:400px) {\n    .parent-div-text-course{\n        display: none !important;\n    }\n    .text-progress{\n        font-size: 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
