import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, FormFeedback, Input, Label, Nav, Row, TabContent, TabPane } from 'reactstrap'
import "./index.scss"
import { getAllCategories } from '../../../api/Category';
import { Alert, DatePicker, Select } from 'antd';
import styled from 'styled-components';
import { getAllBranch } from '../../../api/Branch';


const AddCourseDetailTab = ({ formik }) => {
    const [branchList, setBranchLİst] = useState([])
    const [categorieList, setCategorieList] = useState([])
    const years = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 1900;
        const yearArray = [];
        for (let year = currentYear - 15; year >= startYear; year--) {
            yearArray.push(year);
        }
        return yearArray;
    }, []);


    const getCategorieList = async () => {
        try {
            const response = await getAllCategories({
                page: 0,
                pageSize: 500
            })
    
            setCategorieList(response.data.items)
        }
        catch (err) {

        }
    }

    const getBranchesList = async () => {
        try {
            const response = await getAllBranch({
                page: 0,
                pageSize: 2000
            })
            setBranchLİst(response.data?.items)
          
        }
        catch (err) {

        }
    }


    useEffect(() => {
        getCategorieList()
        getBranchesList()
    }, [])



    return (
        <Form onSubmit={formik.handleSubmit} >
            <Row>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Branş
                        </Label>
                        <StyledSelect
                            onChange={(e) => {
                                formik.setFieldValue("branchId", e)
                            }}
                            showSearch
                            optionFilterProp='label'
                            options={[
                                {
                                    "label": "Branş Seçiniz",
                                    "value": ""
                                },
                                ...branchList.map((el, index) => {
                                    return {
                                        "label": el?.name,
                                        "value": el?.id
                                    }
                                })
                            ]}
                            value={formik.values.branchId} />
                        {formik.touched.branchId && formik.errors.branchId ? (
                            <StyledErrorMessage >{formik.errors.branchId}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kontenjan
                        </Label>
                        <StyledInput
                            value={formik.values.limit}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder='Kontenjan'
                            name='limit'
                            invalid={formik.touched.limit && formik.errors.limit ? true : false}
                            type='number' />
                        {formik.touched.limit && formik.errors.limit ? (
                            <FormFeedback type="invalid"><div>{formik.errors.limit}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kategori
                        </Label>
                        <StyledSelect
                            onChange={(e) => {
                                formik.setFieldValue("categoryId", e)
                            }}
                            showSearch
                            optionFilterProp='label'
                            options={[
                                {
                                    "label": "Kategori Seçiniz",
                                    "value": ""
                                },
                                ...categorieList.map((el, index) => {
                                    return {
                                        "label": el?.name,
                                        "value": el?.id
                                    }
                                })
                            ]}
                            value={formik.values.categoryId} />
                        {formik.touched.categoryId && formik.errors.categoryId ? (
                            <StyledErrorMessage >{formik.errors.categoryId}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Başlangıç tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{
                                    width: "100%"
                                }}
                                allowClear={false}
                                showTime
                                onChange={(value, dateString) => {
                                    formik.setFieldValue("startDate", value)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.startDate}
                            />
                        </div>

                        {formik.touched.startDate && formik.errors.startDate ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.startDate}</div>
                        ) : null}



                    </div>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Bitiş tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{
                                    width: "100%"
                                }}
                                allowClear={false}
                                showTime
                                onChange={(value, dateString) => {
                      
                                    formik.setFieldValue("endDate", value)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.endDate}
                            />
                        </div>
                        {formik.touched.endDate && formik.errors.endDate ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.endDate}</div>
                        ) : null}
                    </div>
                </Col>

                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt Başlangıç Tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{
                                    width: "100%"
                                }}
                                allowClear={false}
                                showTime
                                onChange={(value, dateString) => {
                                    formik.setFieldValue("registerStartTime", value)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.registerStartTime}
                            />
                        </div>

                        {formik.touched.registerStartTime && formik.errors.registerStartTime ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.registerStartTime}</div>
                        ) : null}
                    </div>

                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt Bitiş Tarihi
                        </Label>
                        <div>
                            <DatePicker
                                style={{
                                    width: "100%"
                                }}
                                allowClear={false}
                                showTime
                                onChange={(value, dateString) => {
                                    formik.setFieldValue("registerTime", value)
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.registerTime}
                            />
                        </div>

                        {formik.touched.registerTime && formik.errors.registerTime ? (
                            <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.registerTime}</div>
                        ) : null}
                    </div>

                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Dönem
                        </Label>
                        <StyledSelect
                            status={formik.touched.semester && formik.errors.semester ? "error" : null}
                            onChange={(e) => {
                                formik.setFieldValue("semester", e)
                            }} value={formik.values.semester} >
                            <option value="Güz">
                                Güz
                            </option>
                            <option value="Bahar">
                                Bahar
                            </option>
                            <option value="Yaz">
                                Yaz
                            </option>
                        </StyledSelect>
                        {formik.touched.semester && formik.errors.semester ? (
                            <StyledErrorMessage>{formik.errors.semester}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>

                <Col lg={3}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Cinsiyet
                        </Label>
                        <StyledSelect
                            status={formik.touched.genderType && formik.errors.genderType ? "error" : null}
                            onChange={(e) => {
                                formik.setFieldValue("genderType", e)
                            }} value={formik.values.genderType} >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                ["Erkek", "Kadın", "Hepsi"].map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.genderType && formik.errors.genderType ? (
                            <StyledErrorMessage>{formik.errors.genderType}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={3}>

                    <div className="mb-3">
                        <Label className="form-label">
                            Devamsızlık Hakkı ( % )
                        </Label>
                        <StyledInput
                            value={formik.values.discontinuity}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                                formik.setFieldValue("discontinuity", e.target.value)
                            }}
                            placeholder='discontinuity'
                            name='discontinuity'
                            invalid={formik.touched.discontinuity && formik.errors.discontinuity ? true : false}
                            type='number' />
                        {formik.touched.discontinuity && formik.errors.discontinuity ? (
                            <FormFeedback type="invalid"><div>{formik.errors.discontinuity}</div></FormFeedback>
                        ) : null}
                    </div>



                </Col>
                <Col lg={3}>

                    <div className="mb-3">
                        <Label className="form-label">
                            Toplam Kurs Süresi ( dk )
                        </Label>
                        <StyledInput
                            value={formik.values.totalTime}
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                                formik.setFieldValue("totalTime", e.target.value)
                            }}
                            placeholder='Toplam Süre'
                            name='totalTime'
                            invalid={formik.touched.totalTime && formik.errors.totalTime ? true : false}
                            type='number' />
                        {formik.touched.totalTime && formik.errors.totalTime ? (
                            <FormFeedback type="invalid"><div>{formik.errors.totalTime}</div></FormFeedback>
                        ) : null}
                    </div>



                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Yaş Şartı (Başlangıç Yılı)
                        </Label>
                        <StyledSelect
                            status={formik.touched.startYear && formik.errors.startYear ? "error" : null}
                            onChange={(e) => {
                                formik.setFieldValue("startYear", e)
                            }} value={formik.values.startYear} >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                years.map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.startYear && formik.errors.startYear ? (
                            <StyledErrorMessage>{formik.errors.startYear}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Yaş Şartı (Bitiş Yılı)
                        </Label>
                        <StyledSelect
                            status={formik.touched.endYear && formik.errors.endYear ? "error" : null}
                            onChange={(e) => {
                                formik.setFieldValue("endYear", e)
                            }} value={formik.values.endYear}>
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                years.map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.endYear && formik.errors.endYear ? (
                            <StyledErrorMessage>{formik.errors.endYear}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>


                <Col lg={4}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt Silme Aktif Olsun mu?
                        </Label>
                        <StyledSelect

                            status={formik.touched.registerDeleteStatus && formik.errors.registerDeleteStatus ? "error" : null}
                            onChange={(e) => {
                                formik.setFieldValue("registerDeleteStatus", e)
                            }} value={formik.values.registerDeleteStatus} >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                ["Evet", "Hayır"].map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.registerDeleteStatus && formik.errors.registerDeleteStatus ? (
                            <StyledErrorMessage>{formik.errors.registerDeleteStatus}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>


                <Col lg={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Kayıt Esnasında Görünsün mü?
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("previewStatus", e)
                        }} value={formik.values.previewStatus}  >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                ["Evet", "Hayır"].map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.previewStatus && formik.errors.previewStatus ? (
                            <StyledErrorMessage >{formik.errors.previewStatus}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Ön Sınav olsun mu ?
                        </Label>
                        <StyledSelect onChange={(e) => {
                            formik.setFieldValue("prelim", e)
                        }} value={formik.values.prelim}  >
                            <option value="">
                                Seçim Yapınız
                            </option>
                            {
                                ["Evet", "Hayır"].map((el, index) => {
                                    return (
                                        <option value={el} key={`${index}`} >
                                            {el}
                                        </option>
                                    )
                                })

                            }
                        </StyledSelect>
                        {formik.touched.prelim && formik.errors.prelim ? (
                            <StyledErrorMessage >{formik.errors.prelim}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="mb-3">
                        <Label className="form-label">
                            Açıklama
                        </Label>
                        <Input value={formik.values.description}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.description && formik.errors.description ? true : false}
                            onChange={formik.handleChange}
                            name='description' id='description' type='textarea'
                            rows={2} style={{ resize: "none" }} />

                        {formik.touched.description && formik.errors.description ? (
                            <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                    <Button style={{ padding: "7px 40px" }} onClick={() => {
                        formik.handleSubmit()
                    }} >
                        Kaydet
                    </Button>
                </div>
            </Row>

        </Form>
    )
}

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`
const StyledInput = styled(Input)`
    height: 33px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 3px;
font-size: 11px;

`


export default AddCourseDetailTab