import { useTranslation } from "react-i18next";
import MainNavbar from "../../components/navbar/MainNavbar";
import MainFooter from '../../components/mainFooter/MainFooter';
import React, { useState, useEffect, useMemo } from "react";
import { axiosInstance } from '../../../api/axiosInstance';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import './index.scss'
import { Select, Button as AntButton, Drawer, Pagination } from 'antd';
import styled from 'styled-components';
import Posts from '../../components/pagination/Posts';
import { toast } from 'react-toastify';
import EgitimlerBg from "../../../assets/images/egitimlerBg.png"
import TopBgElement from "../TopBgElement/TopBgElement";
import FilterElement from "./FilterElement";
import MobileFilterElement from "./MobileFilterElement";
import { Helmet } from "react-helmet-async";



const Egitimler = () => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [searchParams] = useSearchParams()
    const { pathname } = useLocation()
    const [catagories, setCatagories] = useState([])
    const [selectedCategory, setSelectedcategory] = useState("Hepsi")
    const [posts, setPosts] = useState([]);
    const [onlineOrOffline, setOnlineOrOffline] = useState("")
    const [loading, setLoading] = useState(false);
    const [categorieList, setCategoryList] = useState([])
    const staticNumber = 9;
    const [activePageIndex, setActivePageIndex] = useState(1)
    const { id } = useParams()

    const courseTypes = ["canliegitim", "videoegitim"]

    function Tr2En(text) {
        var Maps = {
            "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
            "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
        };
        Object.keys(Maps).forEach(function (Old) {
            text = text.replace(Old, Maps[Old]);
        });
        return text;
    }

    const fetchCategories = async () => {
        try {
            const response = await axiosInstance.post('/Categories/GetAll', {
                page: 0,
                pageSize: 100
            });
            const initialCourses = response.data.items
            let result = ["Hepsi", ...initialCourses.map(el => el.name)]
            setCategoryList(result);
            if (!courseTypes.includes(id)) {
                let searchCategory = initialCourses.find(item => Tr2En(item?.name?.toLowerCase().replace(/\s+/g, '')) == id)
                if (searchCategory) {
                    setSelectedcategory(result.find(el => el == searchCategory.name))
                }
            }


        } catch (error) {

        }
    };

    const fetchPosts = async () => {
        try {
            setLoading(true);
            const res2 = await axiosInstance.post('/Branches/GetAll', {
                "page": 0,
                "pageSize": 500
            });

            setPosts([...res2.data.items?.filter(item => item?.coursePreviewStatus).map(el => ({
                ...el,

            }))

            ]);
            setLoading(false);

            if (courseTypes.includes(id)) {
                if (id == "canliegitim") {
                    setOnlineOrOffline("online")
                }
                else if (id == "videoegitim") {
                    setOnlineOrOffline("offline")
                }


            }


        }
        catch {

        }

    };

    useEffect(() => {
        fetchPosts();
        fetchCategories()
    }, []);

    const mainData = useMemo(() => {
        if (onlineOrOffline == "") {
            if (selectedCategory == "Hepsi") {
                return posts
            }
            let filtered = posts.filter(el => {
                return el?.category?.name == selectedCategory
            })
            return filtered
        }
        else if (onlineOrOffline == "offline") {
            let offlineCourses = posts.filter(item => item.type == "Offline")

            if (selectedCategory == "Hepsi") {
                return offlineCourses
            }
            let filtered = offlineCourses.filter(el => {
                return el?.category?.name == selectedCategory
            })
            return filtered
        }
        else if (onlineOrOffline == "online") {
            let onlineCourses = posts.filter(item => item.type == "Online")

            if (selectedCategory == "Hepsi") {
                return onlineCourses
            }
            let filtered = onlineCourses.filter(el => {
                return el?.category?.name == selectedCategory
            })
            return filtered
        }
        return posts
    }, [posts, selectedCategory, onlineOrOffline])




    const paginationLength = useMemo(() => {
        const kalan = mainData.length % staticNumber
        let bolum = parseInt(mainData?.length / staticNumber)
        if (kalan != 0) {
            bolum += 1
        }
        return bolum
    }, [mainData])

    const resultData = useMemo(() => {
        if (activePageIndex == 1) {
            return mainData?.slice(0, staticNumber)
        }
        else {
            return mainData.slice((activePageIndex - 1) * staticNumber, activePageIndex * staticNumber)
        }
    }, [mainData, activePageIndex])



    useEffect(() => {
        window.scroll({
            top : 80
        })
    }, [onlineOrOffline,selectedCategory])

    return (
        <StyledMainContainer>
            <Helmet>
                <title>Eğitimler</title>
                <meta name="description" content={"KUZEM eğitim platformu Eğitimler sayfamızda sunulan eğitim programları hakkında bilgi edinebilirsiniz."} />
                <meta name="keywords" content={"Eğitimler,Canlı Eğitim, Video Eğitim, Kurslar, Programlar, Öğrenme"} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={EgitimlerBg} mainTitle={"Eğitimler"} subTitle={"Anasayfa / Eğitimler"} />
            <StyledContainer>

                <StyledMobileFilterBtn type='primary' onClick={() => {
                    setDrawerOpen(true)
                }} >
                    Filtre
                </StyledMobileFilterBtn>


                <StyledLeftSection>
                    <Posts posts={resultData} loading={loading} />
                    {
                        !loading && (
                            <div style={{ display: "flex", justifyContent: "center" }} >
                                <Pagination current={activePageIndex} total={paginationLength} pageSize={1} onChange={(e) => {
                                    /*  getCourseList(e - 1, courseStatus)*/
                                    setActivePageIndex(e)
                                }} />
                            </div>
                        )
                    }

                </StyledLeftSection>
                <FilterElement selectedCategory={selectedCategory} categorieList={categorieList} onlineOrOffline={onlineOrOffline} setOnlineOrOffline={setOnlineOrOffline} setSelectedcategory={setSelectedcategory} />
            </StyledContainer>
            <MobileFilterElement catagories={categorieList} drawerOpen={drawerOpen} selectedCategory={selectedCategory}
                setOnlineOrOffline={setOnlineOrOffline}
                setSelectedcategory={setSelectedcategory}
                setDrawerOpen={setDrawerOpen}
                onlineOrOffline={onlineOrOffline}
            />


            <div style={{ height: '300px' }}></div>
            <MainFooter visibility={'visible'} />
        </StyledMainContainer>
    )
}

export default Egitimler;


const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 7fr 2fr;
    column-gap: 20px;
    padding-left: 120px;
    padding-right: 120px;
    width: 100%;
    margin-top: 30px;
    
    @media screen and (max-width:1024px) {
        padding-left: 20px;
        padding-right: 10px;
        grid-template-columns: 5fr 2fr;
        row-gap: 20px;
        column-gap: 10px;
    } 

    @media screen and (max-width:600px) {
        padding-left: 20px;
        padding-right: 10px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    } 

/* 
    .styled_mobile_btn_container{
        width: 100%;
        position: sticky;
        top: 90px;
        z-index: 1000;
        padding: 20px 0;
    } */
`

const StyledLeftSection = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width:600px) {
       order: 2;
    } 
`



const StyledMobileFilterBtn = styled(AntButton)`
    display: none;

    @media screen and (max-width:600px) {
        display: flex;
        justify-content: center;
        width: 100%;
    }
`


const StyledMainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`