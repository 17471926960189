import { Select } from "antd";
import styled from "styled-components";

export const StyledGiveExamPointDataTableContainer = styled.div`
    margin-top: 30px;
`


export const ExamGivePointStyledContainer = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: column;
`

export const ExamGivePointLoadingWrapper = styled.div`
    padding-top: 30px;
`

export const ExamGivePointSearchStudent = styled(Select)`
    width: 220px;
    display: flex;
`
export const ExamGivePointHeaderContainer = styled.div`
    border: 1px  dashed gray;
    padding: 15px 20px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
export const SelectedOgrName = styled.p`    
    font-size: 16px;
    font-weight: 600;
`

export const ExamGivePointContentContainer = styled.div`
    margin-top: 20px;
`


export const ExamGivePointQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
`

export const ExamGiveQuestionItem = styled.div`
        display: flex;
        width: 100%;
        flex-direction: column;
`