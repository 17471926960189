import React, { useContext, useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { MdModeEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getDynamicUserSearchApi } from '../../../api/UserApi';
import styled from 'styled-components';
import { Table as AntTable, Input as AntInput, Button as AntButton, Modal as AntModal, Select } from "antd"
import { IoMdTrash } from 'react-icons/io';
import { axiosInstance } from '../../../api/axiosInstance';
import { toast } from 'react-toastify';
import { UpdateCourseContext } from './UpdateCourseForm/UpdateCourseForm';
import { registerCourseApi } from '../../../api/Course';
import { UserContext } from '../../../context/user';
import { FaEye } from 'react-icons/fa';
import StudenAbsenceModel from './UpdateCourseForm/StudenAbsenceModel';


const EditCourseStudentTab = ({ userList: courseInfo }) => {
    const navigate = useNavigate()

    const { id } = useParams()
    const [filterType, setFilterType] = useState("firstName")
    const [filterSearchText, setFilterSearchText] = useState("")
    const [totalCount, setTotalCount] = useState(0)
    const [studentList, setStudentList] = useState([])
    const [selectedPage, setSelectedPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [addStudentList, setAddStudentList] = useState([])
    const [addStudentTotal, setAddStudentTotal] = useState(0)
    const [addSelectedPages, setAddSelectedPage] = useState(1)
    const { detailCourseApiRequest } = useContext(UpdateCourseContext)
    const [studentAbsenceInfo, setStudenAbsenceInfo] = useState({
        visible: false,
        data: null
    })
    const [addStudentModal, setAddStudentModal] = useState({
        visible: false,
        data: null
    })
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const staticPageNumber = 5

    const courseType = useMemo(() => {
        return courseInfo?.type
    }, [courseInfo])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm()
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    const dynamicSearch = async (e, page = 0) => {
        try {
            setLoading(true)
            if (e !== "") {
                const body = {
                    "sort": [
                        {
                            "field": "firstname",
                            "dir": "asc"
                        }
                    ],
                    "filter": {
                        "field": filterType,
                        "operator": "contains",
                        "value": e,
                    }
                }

                const response = await getDynamicUserSearchApi(body, {
                    page: page,
                    pageSize: staticPageNumber,
                    roleId: 3
                }
                )
                const selected = response.data.items
                setAddStudentList(selected)
                setAddStudentTotal(response.data.count)
            }
            else {
                setAddStudentList([])
                setAddSelectedPage(1)
                setAddStudentTotal(0)
            }
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const getAllCourseRegister = async () => {
        try {
            setLoading(true)
            const firstRequest = await axiosInstance.post("/CourseRegisters/GetAllForCourseId", {
                pageRequest: {
                    page: 0,
                    pageSize: 1
                },
                courseId: id
            })

            const secondRequest = await axiosInstance.post("/CourseRegisters/GetAllForCourseId", {
                pageRequest: {
                    page: 0,
                    pageSize: firstRequest.data?.count ?? 1000
                },
                courseId: id
            })

            setStudentList(secondRequest.data?.items?.map(item => {
                const { user, ...rest } = item
                const { id, ...userRest } = user
                return {
                    ...rest,
                    ...userRest,
                    userId: id
                }
            }))

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllCourseRegister()
    }, [])

    return (
        <>
            <StyledDataTableContainer>
                <StyledHeader>
                    <AntButton type='primary' onClick={() => {
                        setAddStudentModal({
                            visible: true,
                            data: null
                        })
                    }} >
                        Öğrenci Ekle
                    </AntButton>
                </StyledHeader>

                <AntTable
                    size='middle'
                    scroll={{
                        x: 800
                    }}
                    footer={() => {
                        return (
                            <div style={{fontWeight:"bold"}}>
                                Toplam Kayıt :{studentList?.length}
                            </div>
                        )
                    }}
                    loading={loading}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: false
                    }}
                    dataSource={studentList}
                    columns={[{
                        title: "İsim",
                        dataIndex: "firstName",
                        render: (row, record) => {
                            return (
                                <span> {record?.firstName} </span>
                            )
                        },
                        ...getColumnSearchProps("firstName")
                    },
                    {
                        title: "Soyisim",
                        dataIndex: "lastName",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {record?.lastName} </span>
                            )
                        },
                        ...getColumnSearchProps("lastName")
                    },
                    {
                        title: "Email",
                        dataIndex: "email",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {record?.email} </span>
                            )
                        },
                        ...getColumnSearchProps("email")
                    },
                    {
                        title: "Tc No",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {record?.tc} </span>
                            )
                        },
                        dataIndex: "tc",
                        ...getColumnSearchProps("tc")
                    },
                    {
                        title: "Yoklama Detayı",
                        hidden: courseType == "Offline",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {
                                    setStudenAbsenceInfo({
                                        visible: true,
                                        data: record
                                    })
                                }}  >
                                    <FaEye />
                                </AntButton>
                            )
                        }
                    },
                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <div style={{ display: "flex", columnGap: "10px" }}>
                                    <AntButton type='primary' onClick={() => {
                                        navigate(`/panel/ogrenci/duzenle/${record.id}`)
                                    }}  >
                                        <MdModeEdit />
                                    </AntButton>
                                    <AntButton type='primary' danger onClick={() => {
                                        AntModal.confirm({
                                            okText: "Eminim",
                                            cancelText: "İptal",
                                            title: `${record?.firstName} ${record?.lastName} isimli öğrenciyi bu kurstan silmek istediğinize emin misiniz ? `,
                                            onOk: async () => {

                                                try {

                                                    await axiosInstance.delete(`/CourseRegisters/Delete/${record?.id}`)
                                                    toast.success(`${record?.firstName} ${record?.lastName} isimli öğrencinin kursdan kayıdı silindi`, {
                                                        autoClose: 2500,
                                                        position: "top-center"
                                                    })
                                                    await detailCourseApiRequest()
                                                }
                                                catch (err) {

                                                }
                                            }
                                        })
                                    }}  >
                                        <IoMdTrash />
                                    </AntButton>
                                </div>
                            )
                        }
                    }
                    ]}
                />

            </StyledDataTableContainer>



            <StudenAbsenceModel modalInfo={studentAbsenceInfo} setModalInfo={setStudenAbsenceInfo} />



            <Modal isOpen={addStudentModal.visible} size='lg' >
                <ModalHeader>
                    Öğrenci Seç
                </ModalHeader>
                <ModalBody>
                    <AddStudenModalFilterContainer>
                        <Select
                            value={filterType}
                            onChange={(e) => {
                                setFilterType(e)
                            }}
                            className='select_filter_type'

                            options={
                                [
                                    {
                                        value: "tc",
                                        label: "Tc'ye göre ara"
                                    },
                                    {
                                        "value": "firstName",
                                        "label": "İsime göre ara"
                                    },
                                    {
                                        "value": "lastName",
                                        "label": "soyisime göre ara"
                                    },
                                    {
                                        "value": "email",
                                        "label": "emaile göre ara"
                                    },
                                    {
                                        "value": "phone",
                                        "label": "telefona göre ara"
                                    },
                                ]}
                        />
                        <AntInput className='select_filter_input' onChange={(e) => {
                            setFilterSearchText(e.target.value)
                            dynamicSearch(e.target.value)
                            setSelectedRowKeys([])
                            setSelectedRow(null)
                        }} />
                    </AddStudenModalFilterContainer>
                    <StyledAntTable
                        dataSource={addStudentList}
                        loading={loading}
                        pagination={{
                            current: addSelectedPages,
                            total: addStudentTotal,
                            pageSize: staticPageNumber,
                            onChange: (e) => {
                                setAddSelectedPage(e)
                                dynamicSearch(filterSearchText, e - 1)


                            }
                        }}
                        rowClassName={(record) => (record.id === selectedRow?.id ? 'selected-row' : '')}
                        columns={[
                            {
                                title: "İsim",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.firstName}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Soyad",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.lastName}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Tc",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.tc}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "İşlem",
                                render: (row, record) => {
                                    return (
                                        <AntButton type='primary' onClick={() => {

                                            setSelectedRow(record)
                                        }} >
                                            Seç
                                        </AntButton>
                                    )
                                }
                            }
                        ]}
                    />
                </ModalBody>
                <ModalFooter>
                    <AntButton type='primary' disabled={!selectedRow} onClick={async () => {
                        try {

                            const response = await registerCourseApi({
                                courseId: id,
                                userId: selectedRow?.id
                            })
                            toast.success("Kursa Kayıt işleminiz gerçekleşti", {
                                autoClose: 2500,
                                position: "top-center"
                            })
                            setAddStudentModal({
                                visible: false,
                                data: null
                            })
                            detailCourseApiRequest()
                        }
                        catch (err) {

                        }

                    }}  >
                        Kaydet
                    </AntButton>
                    <AntButton danger type='primary' onClick={() => {
                        setAddStudentModal({
                            visible: false,
                            data: null
                        })
                        setStudentList([])
                        setFilterSearchText("")
                        setSelectedRowKeys([])
                        setSelectedRow(null)
                        setSelectedPage(1)
                        setTotalCount(0)
                    }} >
                        İptal
                    </AntButton>
                </ModalFooter>
            </Modal>
        </>
    )
}

const StyledDataTableContainer = styled.div`
    margin-top: 20px;
    padding: 0px 0px ;
`

const StyledInputContainer = styled.div`
   /*  margin: 10px 0; */
    display: flex;
    justify-content: flex-start;
`

const StyledInput = styled(Input)`
    width: 200px;
`
const StyledHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
`
const AddStudenModalFilterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-bottom: 20px;
    .select_filter_type{
        width: 30%;
        height: 35px;
    }
    .select_filter_input{
        height: 35px;
        width: 70%;
    }

`

const StyledAntTable = styled(AntTable)`
    .selected-row {
    background-color: #e6f7ff; /* Seçili satırın arka plan rengi */
    }
`


export default EditCourseStudentTab