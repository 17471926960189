import React, { useEffect, useRef, useState } from 'react'
import "./index.scss"
import {
  Button, Modal, ModalBody, ModalHeader, ModalFooter, Input, Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Label,
} from "reactstrap"
import { useParams } from "react-router-dom"
import { Collapse, Button as AntButton, Modal as AntModal, Input as AntInput, Upload, Switch, Skeleton } from "antd"
import { SlArrowDown } from "react-icons/sl";
import { toast } from "react-toastify"
import 'swiper/css';
import 'swiper/css/pagination';
import { addCourseFiles, courseContentForCourseId, createCourseContent, deleteCourseContent, deleteCourseVideo, getAllCourseContent, updateCourseContent } from '../../../../api/CourseContent';
import { MdSlowMotionVideo } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { TbTrash } from 'react-icons/tb'
import styled from 'styled-components'
import { BsUpload } from 'react-icons/bs'
import { FaUpload } from 'react-icons/fa'
import { axiosInstance } from '../../../../api/axiosInstance'
import axios from 'axios'

const AddOfflineCourseContent = () => {
  const fileInputRef = useRef(null);
  const updateFileInputRef = useRef(null)
  const [loading, setLoading] = useState(false)

  const [modalData, setModalData] = useState({
    show: false,
    data: "",
    editMode: false,
    contentId: null
  })
  const [selectedContent, setSelectedContent] = useState()
  const [selectVideoModal, setSelectVideoModal] = useState({
    show: false,
    id: null
  })
  const [contentList, setContentList] = useState([{
    "name": "",
    "video": []
  }])
  const [selectedVideo, setSelectedVideo] = useState({
    description: "",
    file: undefined,
    preview: false
  });

  const [watchVideo, setWatchVideo] = useState({
    visible: false,
    data: null
  })
  const [updateVideo, setUpdateVideo] = useState({
    file: undefined
  })

  const [confirmModal, setConfirmModal] = useState(false)

  const { id } = useParams()


  const updateVideoChange = (e) => {
    try {

      const videoFile = e.target.files[0]
      if (videoFile && videoFile.type.startsWith("video/")) {
        setUpdateVideo({
          file: videoFile
        })
      } else {
        toast.error("Lütfen video formatında bir dosya seçiniz", {
          autoClose: 1500
        })
      }
    }
    catch (err) {

    }
  }

  const handleVideoChange = (e) => {
    const videoFile = e.target.files[0];

    if (videoFile && videoFile.type.startsWith("video/")) {
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';

      videoElement.onloadedmetadata = function () {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;


        setSelectedVideo({
          ...selectedVideo,
          file: videoFile,
          duration: duration // Videonun süresini burada set ediyoruz
        });
      };

      videoElement.src = URL.createObjectURL(videoFile);
    } else {
      toast.error("Lütfen video formatında bir dosya seçiniz", {
        autoClose: 1500
      });
    }
  };

  const customHeader = (title = "test1") => {
    return (
      <div style={{ padding: "15px", display: "flex", justifyContent: "space-between" }} >
        <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>{title}</span>
        <span style={{ paddingRight: "10px" }} >
          <SlArrowDown />
        </span>
      </div>
    )
  }

  const getCourseContent = async () => {
    try {
      const response = await courseContentForCourseId({
        page: 0,
        pageSize: 100
      }, id)

      setContentList(response.data.items)
    }
    catch (err) {

    }
  }


  const addCourseContent = async () => {
    try {
      if (modalData.editMode) {
        const updateResponse = await updateCourseContent({
          id: parseInt(modalData.contentId),
          courseId: id,
          name: modalData.data
        })
        setModalData({
          show: false,
          editMode: false,
          data: "",
          contentId: null,
        })
        toast.success("Kurs başlığı güncellendi", {
          autoClose: 2000,
          position: "top-center"
        })
      }
      else {
        const response = await createCourseContent({
          courseId: id,
          name: modalData.data
        })
        setModalData({
          show: false,
          editMode: false,
          data: "",
          contentId: null,
        })
        toast.success("Kurs başlığı eklendi", {
          autoClose: 2000,
          position: "top-center"
        })
      }
      getCourseContent()

    }
    catch (err) {

    }
  }

  const saveVideo = async () => {
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append("formFile", selectedVideo.file)

      const duration = await getVideoDuration(selectedVideo.file)
      const response = await addCourseFiles(selectVideoModal.id, selectedVideo.description, selectedVideo.preview ?? false, parseInt(duration), formData)

      setSelectVideoModal({
        show: false,
        id: null,

      })
      setSelectedVideo({
        description: "",
        file: undefined,
        preview: false
      })
      await getCourseContent()
      toast.success("Video Eklendi", {
        autoClose: 2500,
        position: "top-center"
      })
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");  // Geçici bir video elementi oluştur
      const url = URL.createObjectURL(file);  // Video dosyası için geçici URL oluştur

      video.src = url;

      video.onloadedmetadata = () => {
        // Metadata yüklendiğinde süresini alıyoruz
        resolve(video.duration);  // Süreyi döndürüyoruz
        URL.revokeObjectURL(url);  // Bellek sızıntılarını önlemek için URL'yi iptal et
      };

      video.onerror = () => {
        reject("Video dosyası yüklenirken bir hata oluştu.");
      };
    });
  };

  const deleteCourse = async () => {
    try {
      setLoading(true)
      const response = await deleteCourseContent(modalData.contentId)

      setModalData({
        contentId: null,
        editMode: false,
        data: "",
        show: false
      })
      await getCourseContent()
      toast.success("Kurs başlığı silindi", {
        autoClose: 2500,
        position: "top-center"
      })
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }


  const deleteVideo = async () => {
    try {
      setLoading(true)
      await deleteCourseVideo(watchVideo.data.id)
      setConfirmModal(false)
      setWatchVideo({
        data: null,
        visible: false
      })
      await getCourseContent()

      toast.success("Video Silindi", {
        autoClose: 2000,
        position: "top-center"
      })
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }
  const [currentTime, setCurrentTime] = useState(0);
  const videoRef = useRef(null);


  const fetchStream = async (url) => {
    try {

      const response = await axios.get(url, {
        responseType: "blob"
      })
      const videoBlob = new Blob([response.data], {
        type: "video/mp4"
      })
      const mainUrl = URL.createObjectURL(videoBlob)
      return mainUrl
    } catch (error) {
      console.error('Video yüklenirken hata oluştu:', error);
      return null;
    }
  };



  useEffect(() => {
    getCourseContent()
  }, [])

  return (
    <>
      <section className='offline_create_content_container'>
        <div className='offline_create_content_header'>
          <AntButton type='primary' onClick={() => setModalData({
            ...modalData,
            show: true
          })}>
            Başlık Ekle
          </AntButton>
        </div>
        <div>
          {contentList.map((item, index) => (
            <Collapse size='large' defaultActiveKey={[0, 1, 2]} className='offline_content_collapse_container' expandIcon={({ isActive }) => null} key={index}>
              <Collapse.Panel header={customHeader(item.name)} key={index}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <AntButton type='primary' style={{ backgroundColor: "#F7B84B" }} onClick={() => {
                    setModalData({
                      show: true,
                      editMode: true,
                      contentId: item.id,
                      data: item.name
                    })
                  }} >
                    Düzenle
                  </AntButton>
                  <AntButton type='primary' onClick={() => {
                    setSelectVideoModal({
                      show: true,
                      id: item.id
                    })
                  }} >
                    Video Ekle
                  </AntButton>
                </div>
                <div>
                  {
                    item?.courseFiles?.map((el, indx) => {
                      return (
                        <div className='course_content_video_item_container' onClick={async () => {

                          setWatchVideo({
                            visible: true,
                            data: {
                              ...el,
                            }
                          })

                          var result = await fetchStream(el.url)

                          setWatchVideo({
                            visible: true,
                            data: {
                              ...el,
                              url: result
                            }
                          })

                        }} >
                          <div className='course_content_video_item_icerik' >
                            <MdSlowMotionVideo className='course_content_video_item_icerik_icon' />
                            <span className='course_content_video_item_icerik_index' >
                              {indx + 1}-
                            </span>
                            <span className='course_content_video_item_icerik_description' >
                              {el.description.length > 20 ? el.description.substring(0, 20) + " ..." : el.description}
                            </span>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </Collapse.Panel>
            </Collapse>
          ))}
        </div>
      </section>

      <Modal isOpen={selectVideoModal.show}  >
        <ModalHeader>
          Video Seç
        </ModalHeader>
        <ModalBody key={selectedVideo.file ? selectedVideo.file.name : "empty"} >
          <div>
            <Input style={{ marginLeft: 0 }} value={selectedVideo.description} type='text' placeholder='Açıklama' onChange={(e) => {

              setSelectedVideo({
                ...selectedVideo,
                description: e.target.value
              })
            }} />

          </div>

          {
            selectedVideo.file ? (
              <video controls width="400" className='add_selected_video' >
                <source src={URL.createObjectURL(selectedVideo.file)} type={selectedVideo.file.type} />
                Tarayıcınız videoyu desteklemiyor.
              </video>
            ) : (
              <p style={{ width: "100%", textAlign: "center", marginTop: "20px" }} >
                Bir adet video seçiniz
              </p>
            )
          }
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <input ref={fileInputRef} type="file" accept="video/*" onChange={handleVideoChange} style={{ display: "none" }} />
            <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <AntButton type='primary' style={{ backgroundColor: "#088F7D" }} onClick={() => {
                fileInputRef.current.click()
              }}>
                <FaUpload />
                <span style={{ marginLeft: "5px", fontSize: "13px" }} >
                  Video
                </span>
              </AntButton>
              <div style={{ display: "flex", alignItems: "center", columnGap: "5px" }} >
                <Switch value={selectedVideo.preview} onChange={(e) => {
                  setSelectedVideo({
                    ...selectedVideo,
                    preview: e
                  })
                }} /> <span>
                  (Önizleme)
                </span>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "10px" }} >
              <AntButton type='primary' loading={loading} onClick={() => {
                saveVideo()

              }} disabled={selectedVideo.description == "" || selectedVideo.file == undefined || loading}  >
                <span>
                  {loading ? "Yükleniyor" : "Kaydet"}
                </span>
              </AntButton>

              <AntButton danger type='primary' onClick={() => {
                setSelectVideoModal({
                  ...selectVideoModal,
                  show: false
                })
                setSelectedVideo({
                  description: "",
                  file: undefined
                })
              }} >
                İptal
              </AntButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalData.show}>
        <ModalHeader>
          {modalData.editMode ? "Güncelle" : "Başlık Ekle"}
        </ModalHeader>
        <ModalBody>

          <Input value={modalData.data} placeholder='Başlık' type='textarea' rows={3} style={{ resize: "none" }} onChange={(e) => {
            setModalData({
              ...modalData,
              data: e.target.value
            })
          }} />
        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            {
              modalData.editMode && (
                <AntButton danger type='primary' onClick={() => deleteCourse()}>
                  Bu Başlığı Sil
                </AntButton>
              )
            }
            <div>
              <AntButton type='primary' disabled={modalData.data == ""} style={{ marginRight: "10px" }} onClick={() => {
                addCourseContent()
              }} >
                {modalData.editMode ? "Güncelle" : "Ekle"}
              </AntButton>
              <AntButton type='primary' style={{ backgroundColor: "#F7B84B" }} onClick={() => {
                setModalData({ ...modalData, show: false, editMode: false, data: "" })
                setSelectedVideo({
                  description: "",
                  file: undefined
                })
              }}>
                İptal
              </AntButton>
            </div>
          </div>
        </ModalFooter>
      </Modal>

      <Modal isOpen={watchVideo.visible}  >
        <StyledModalHeader>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "15px" }} >
            <Label style={{ fontWeight: "bold", marginBottom: "0" }} >
              Güncelle
            </Label>
            <AntButton type='primary' onClick={() => {
              setWatchVideo({ data: null, visible: false })
              setUpdateVideo({
                file: undefined
              })
            }} >
              İptal
            </AntButton>
          </div>
          <AntInput style={{ width: "100%", height: "35px" }} onChange={(e) => {

            setWatchVideo({
              ...watchVideo,
              data: {
                ...watchVideo.data,
                description: e.target.value
              }
            })
          }} value={watchVideo?.data?.description} />

        </StyledModalHeader>
        <ModalBody key={updateVideo.file ? updateVideo.file.name : "emptyUpdate"}  >
          {
            updateVideo.file ? (
              <video controls className='add_selected_video' >
                <source src={URL.createObjectURL(updateVideo.file)} type={updateVideo.file.type} />
                Tarayıcınız videoyu desteklemiyor.
              </video>
            ) : watchVideo.data?.url ? (
              <video controls className='add_selected_video'  >
                <source src={watchVideo?.data?.url} />

              </video>
            ) : (
              <Skeleton />
            )
          }

        </ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
              <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                <AntButton loading={loading} type='primary' style={{ display: "flex", alignItems: "center" }} danger onClick={() => {
                  AntModal.confirm({
                    
                    content: (
                      <p>
                        Bu videoyu silmek istediğinize emin misiniz?
                      </p>
                    ),
                    okText: "Eminim",
                    cancelText: "İptal",
                    onOk: () => {
                      deleteVideo()
                    }
                  })
                }} >
                  <TbTrash />
                  <span style={{ fontSize: "14px", marginLeft: "5px" }} >
                    Sil
                  </span>
                </AntButton>
                <div style={{ display: "flex", alignItems: "center", columnGap: "5px" }} >
                  <Switch value={watchVideo.data?.preview} onChange={(e) => {
                    setWatchVideo({
                      ...watchVideo,
                      data: {
                        ...watchVideo.data,
                        preview: e
                      }
                    })
                  }} /> <span>
                    (Önizleme)
                  </span>
                </div>
              </div>

              <div style={{ display: "flex", alignItems: "center", columnGap: "15px" }} >
                <input ref={updateFileInputRef} type="file" accept="video/*" onChange={updateVideoChange} style={{ display: "none" }} />

                <AntButton type='primary' onClick={(e) => {
                  updateFileInputRef.current.click()
                }} icon={<BsUpload />}>Video</AntButton>


                <AntButton loading={loading} disabled={
                  loading || watchVideo.data?.description == ""
                } type='primary' style={{
                  backgroundColor: "#088F7D",

                }}
                  onClick={() => {
                    AntModal.confirm({
                      okText: "Eminim",
                      cancelText: "İptal",
                      content: (
                        <p>
                          Güncellemek istediğinize emin misiniz ?
                        </p>
                      ),
                      onOk: async (close) => {
                        try {
                          setLoading(true)
                          const formData = new FormData()
                          if (updateVideo.file) {
                            formData.append("formFile", updateVideo.file)
                            const duration = await getVideoDuration(updateVideo.file)
                            const response = await axiosInstance.put(`/CourseFiles/Update?courseContentId=${watchVideo.data?.courseContentId}&description=${watchVideo.data?.description}&id=${watchVideo.data?.id}&preview=${watchVideo.data?.preview}&duration=${parseInt(duration)}`, formData)
                            close()
                            await getCourseContent()
                            toast.success("Video Güncellendi", {
                              autoClose: 2000,
                              position: "top-center"
                            })
                          }
                          else {

                            const response = await axiosInstance.put(`/CourseFiles/Update?courseContentId=${watchVideo.data?.courseContentId}&description=${watchVideo.data?.description}&id=${watchVideo.data?.id}&preview=${watchVideo.data?.preview}&duration=${watchVideo.data.duration}`)
                            close()
                            await getCourseContent()
                            toast.success("Video Güncellendi", {
                              autoClose: 2000,
                              position: "top-center"
                            })

                          }


                        }
                        catch (err) {

                        }
                        finally {
                          setLoading(false)
                        }
                      }
                    })
                  }}

                >
                  {
                    loading ? (
                      <span style={{ color: "white" }} >
                        Yükleniyor
                      </span>
                    ) :
                      (
                        <span style={{ color: "white" }} >
                          Güncelle
                        </span>
                      )
                  }
                </AntButton>
              </div>

            </div>

          </div>
        </ModalFooter>
      </Modal >

      <Modal isOpen={confirmModal} >
        <ModalHeader>
          Onay
        </ModalHeader>
        <ModalBody>
          Silmek istediğinize emin misiniz ?
        </ModalBody>
        <ModalFooter>
          <Button className='btn btn-danger' onClick={() => deleteVideo()} >
            Eminim
          </Button>
          <Button onClick={() => setConfirmModal(false)} >
            İptal
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AddOfflineCourseContent


const StyledModalHeader = styled(ModalHeader)`

   h5{
    width: 100%;
   }
`