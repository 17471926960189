import React, { useEffect, useMemo, useState } from 'react'
import "./studentSurveyDashboard.scss"
import { surveyResultsAddApi, surveysGetAll } from '../../../api/Survey'
import DataTable from 'react-data-table-component'
import { Button, Card, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Button as AntButton, Modal as AntModal, Table as AntTable, Input as AntInput } from "antd"
import { FaEye } from 'react-icons/fa'


const StudentSurveyDashboard = () => {
    const [surveyList, setSurveyList] = useState([])
    const [selectedSurveyModal, setSelectedSurveyModal] = useState({
        visible: false,
        data: null
    })
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState([])

    const getSurveyList = async () => {
        try {
            const response = await surveysGetAll({
                page: 0,
                pageSize: 50
            })
            const responsev2 = await surveysGetAll({
                page: 0,
                pageSize: response.data.count
            })

            setSurveyList(responsev2.data.items)
          
        }
        catch (err) {

        }
    }




    const mainData = useMemo(() => {
        if (searchText == "") {
            return surveyList
        }
        return surveyList.filter(el => el?.name?.toLowerCase().search(searchText?.toLowerCase()) !== -1)
    }, [surveyList, searchText])


    const selectSurveyAnswer = (data) => {
        setSelectedAnswers([
            ...selectedAnswers.filter(el => el.surveyQuestionId !== data.surveyQuestionId),
            data
        ])
    }

    const saveSurveyAnswerBtnDisable = useMemo(() => {
        const idList = selectedSurveyModal.data?.surveyQuestions?.map(el => el.id)
        if (idList) {
            for (let el of idList) {
                let result = selectedAnswers.find(x => x.surveyQuestionId == el)
                if (!result) {
                    return true
                }
            }
            return false
        }
    }, [selectedSurveyModal, selectedAnswers])

    const saveAnswer = async () => {

        AntModal.confirm({
            title: "Anketi bitirmek istediğinize emin misiniz?",
            cancelText: "iptal",
            onOk: async () => {
                try {
                    setLoading(true)
                    await surveyResultsAddApi({
                        "surveyAnswerIds": selectedAnswers.map(el => el.id)
                    })
                    toast.success("Anket Cevaplandı", {
                        autoClose: 2500,
                        position: "top-center"
                    })
                    setSelectedSurveyModal({
                        visible: false,
                        data: null
                    })
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })



    }


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <AntInput
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]?.toString()?.toLowerCase().includes(value?.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });

    useEffect(() => {
  
    }, [selectedAnswers])

    useEffect(() => {
        getSurveyList()
    }, [])

    return (
        <>
            <div className='student_survey_dashboard_container' >
                {/*   <StyledSurveySearchHeader>
                    <StyledInput value={searchText} placeholder='Ara' onChange={e => {
                        console.log("ee", e.target.value)
                        setSearchText(e.target.value)
                    }} />
                </StyledSurveySearchHeader> */}
                <AntTable
                    pagination={{
                        pageSize: 10
                    }}
                    scroll={{
                        x: 800
                    }}
                    dataSource={mainData}
                    columns={[{
                        title: "Anket İsmi",
                        dataIndex: "name",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record.name}
                                </span>
                            )
                        },
                        ...getColumnSearchProps("name")
                    },
                    {
                        title: "Açıklama",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {
                                    try {
                                        AntModal.info({
                                            title: "Açıklama",
                                            okText: "Kapat",
                                            content: (
                                                <p>
                                                    {record?.description}
                                                </p>
                                            )
                                        })
                                    }
                                    catch (err) {

                                    }
                                }}  >
                                    <FaEye />
                                </AntButton>
                            )
                        }

                    },
                    {
                        title: "Kurs İsmi",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record?.courseName}
                                </span>
                            )
                        }
                    },
                    {
                        title: "Kaç Soru?",
                        render: (row, record) => {
                            return (
                                <span>
                                    {record?.surveyQuestions?.length}
                                </span>
                            )
                        }
                    },
                    {
                        title: "İşlem",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {
                                    setSelectedSurveyModal({
                                        visible: true,
                                        data: record
                                    })
                                }}  >
                                    Anket'i Doldur
                                </AntButton>
                            )
                        }
                    }
                    ]}
                />
                {/*   <DataTable
                    customStyles={{
                        headCells: {
                            style: {
                                fontWeight: "600"
                            }
                        },
                        rows: {
                            style: {
                                border: "1px solid #8080801a ",
                            }
                        },
                        cells: {
                            style: {
                                padding: "11px"
                            }
                        }
                    }}
                    data={mainData}
                    columns={columns}
                    pagination
                    noDataComponent={
                        <Card className="w-100 p-5" style={{ marginRight: 0 }} >
                            <center>
                                <h2>Herhangi bir anket bulunamadı.</h2>
                            </center>
                        </Card>
                    }
                    paginationComponentOptions={{
                        rowsPerPageText: "Satır Sayısı",
                        rangeSeparatorText: "-",
                        noRowsPerPage: true
                    }}
                /> */}
            </div>
            <Modal isOpen={selectedSurveyModal.visible} size='lg'  >
                <StyledModalHeader>
                    <div className='student_survey_solution_modal_header'>
                        <p style={{ textTransform: "capitalize" }} >
                            {selectedSurveyModal.data?.name}
                        </p>
                        <p style={{ fontSize: "14px" }} >
                            <span style={{ fontWeight: "bolder", marginRight: "20px" }} >Kurs:</span> {selectedSurveyModal.data?.courseName}
                        </p>
                    </div>
                </StyledModalHeader>
                <ModalBody   >
                    <div className='student_survey_solution_modal_body'>
                        {
                            selectedSurveyModal.data?.surveyQuestions?.map((el, index) => {
                                return (
                                    <div className='student_survey_solution_question_wrapper' >
                                        <div className='student_survey_solution_question_header' >
                                            <div className='student_survey_solution_question_index' >
                                                {index + 1}
                                            </div>
                                            <Input disabled type='textarea' rows={2} value={el.question} className='student_survey_modal_question_input' />
                                        </div>
                                        <div className='student_survey_solution_question_answer_container'>
                                            {
                                                el.surveyAnswers.map(x => {
                                                    return (
                                                        <div className='student_survey_solution_question_answer_item' onClick={() => {

                                                            selectSurveyAnswer(x)
                                                        }} >
                                                            <span></span>
                                                            <div className='student_survey_solution_question_answer_item_content' style={{
                                                                backgroundColor: selectedAnswers.find(i => i.id == x.id) ? "#088F7D" : null,
                                                                color: selectedAnswers.find(i => i.id == x.id) ? "white" : null
                                                            }}  >
                                                                {x.answer}

                                                            </div>
                                                        </div>


                                                    )

                                                })
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <div className='student_survey_solution_modal_footer'>
                        <AntButton loading={loading} type='primary' disabled={saveSurveyAnswerBtnDisable} onClick={() => {
                            saveAnswer()
                        }} >
                            Kaydet
                        </AntButton>
                        <AntButton type='primary' danger onClick={() => {
                            setSelectedSurveyModal({
                                ...selectedSurveyModal,
                                visible: false
                            })
                            setSelectedAnswers([])
                        }} >
                            İptal
                        </AntButton>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default StudentSurveyDashboard

const StyledModalHeader = styled(ModalHeader)`
    width: 100%;

    h5{
        display: flex;
        width: 100%;
    }
`

const StyledSurveySearchHeader = styled.header`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
`

const StyledInput = styled(Input)`
    width: 200px;
    margin-left: 0;
`