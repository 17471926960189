import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import { Button, Card } from 'reactstrap';
import { getRecordingsApi } from '../../../api/RecordingsApi';
import { PiTelevisionSimpleLight } from "react-icons/pi";
import { FaEye } from "react-icons/fa";
const StudentCourseOldRecord = ({ courseData }) => {
    const [recordList, setRecordList] = useState([])



    const recordApiRequest = async () => {
        try {
            let formatData = courseData.filter(el => el.type == "Online").map(el => {
                const { courseCalendars, ...rest } = el
                return el.courseCalendars.map(item => {
                    const { onlineCourseUrls, ...props } = item
                    return {
                        onlineCourseUrls: onlineCourseUrls[0],
                        ...props,
                        ...rest,
                    }
                })
            }).flatMap(x => x).filter(el => el.onlineCourseUrls != undefined)

    
            const recordIdList = formatData.map(el => el.onlineCourseUrls?.id)


            let result = await Promise.all(recordIdList.map(el => getRecordingsApi(el)))
           
            result = result.map(x => x.data)



            const finalData = formatData.map(el => {
                const selected = result.find(x => x.internalMeetingId === el.onlineCourseUrls.internalMeetingId)
                const elements = selected?.playbacks.length !== 0 ? {
                    recordUrl: selected?.playbacks[0].url,
                    files: selected?.playbacks[0].previewImages
                } : null
                return {
                    ...el,
                    ...elements
                }
            }).filter(el => el.recordUrl != undefined)

            setRecordList(finalData)
        }
        catch (err) {

        }
    }


    useEffect(() => {

        recordApiRequest()


    }, [courseData])

    const handleWatch = (url) => {
        window.open(url, '_blank'); // Yeni sekmede açma işlemi
    };

    const columns = [
        {
            name: "İsim",
            selector: (row) => <span> {row?.name} </span>,
        },
        {
            name: "Başlangıç",
            selector: (row) => <span> {new Date(row.startTime).toLocaleString()}   </span>
        },
        {
            name: "Bitiş",
            selector: (row) => {
                return (
                    <span>
                        {new Date(row.endTime).toLocaleString()}
                    </span>
                )
            }
        },
        {
            name: "Kurs Süresi",
            selector: (row) => {
                return (
                    <span>
                        {row.duration} (dk)
                    </span>
                )
            }
        },
        {
            name: "Eğitmen",
            selector: (row) => {
                return (
                    <span>
                        {row.courseAdministrator}
                    </span>
                )
            }
        },
        {
            name: "Kayıt Linki",
            selector: (row) => {
                return (
                    <Button color='primary' onClick={() => {
                        handleWatch(row.recordUrl)
                    }}  >
                        <FaEye style={{ marginRight: 10 }} /> İzle
                    </Button>
                )
            }
        }
    ];


    return (
        <div>
            <DataTable
                data={recordList}
                columns={columns}
                pagination
                noDataComponent={
                    <Card className="w-100 p-5" style={{ marginRight: 0 }}  >
                        <center>
                            <h2>Herhangi bir veri bulunamadı.</h2>
                        </center>
                    </Card>
                }
                paginationComponentOptions={{
                    rowsPerPageText: "Satır Sayısı",
                    rangeSeparatorText: "-",
                    noRowsPerPage: true
                }}
            />
        </div>
    )
}

export default StudentCourseOldRecord