import React, { useEffect } from 'react'
import pdfFile from "../../../assets/files/kuzemaydinlatma.pdf"
const AydinlatmaMetni = () => {
   
    useEffect(() => {
        // assets klasöründeki PDF dosyasının URL'ine yönlendiriyoruz
        window.open(pdfFile, '_blank');
    }, []);

    return (
        <div>AydinlatmaMetni</div>
    )
}

export default AydinlatmaMetni