import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import ProfileForm from '../../Components/Profile/ProfileDashboard'
import styled from 'styled-components'

const ProfilePage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Profil' header={"Profilim"} visible={false} >
                <StyledContainer>
                    <ProfileForm />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:500px)  {
        padding: 0;
    }
`
export default ProfilePage