// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.exam_list_dashboar_container {
  display: flex;
  flex-direction: column;
  padding-top: 30px; }
  .exam_list_dashboar_container .exam_list_dashboard_filter {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between; }
    .exam_list_dashboar_container .exam_list_dashboard_filter .exam_list_dashboard_ant_select {
      width: 200px;
      height: 35px; }
      @media screen and (max-width: 600px) {
        .exam_list_dashboar_container .exam_list_dashboard_filter .exam_list_dashboard_ant_select {
          width: 170px; } }
      .exam_list_dashboar_container .exam_list_dashboard_filter .exam_list_dashboard_ant_select .ant-select-selector {
        border: 1px solid #088F7D;
        /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */ }
        .exam_list_dashboar_container .exam_list_dashboard_filter .exam_list_dashboard_ant_select .ant-select-selector:hover {
          border: 1px solid #088F7D !important; }
    .exam_list_dashboar_container .exam_list_dashboard_filter .exam_list_dashboard_ant_filter {
      width: 150px;
      height: 35px;
      border: 1px solid #088F7D; }

@media screen and (max-width: 450px) {
  .exam_list_dashboard_ant_filter {
    margin-left: 5px; } }
`, "",{"version":3,"sources":["webpack://./src/Components/ExamManagement/ExamListDashboard/examListDashboard.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAA;EAHnB;IAOI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B,EAAA;IAXlC;MAeM,YAAY;MACZ,YAAY,EAAA;MAEZ;QAlBN;UAmBQ,YAAY,EAAA,EAYf;MA/BL;QAuBQ,yBAAyB;QACzB,wEAAA,EAAyE;QAxBjF;UA2BU,oCAAoC,EAAA;IA3B9C;MAkCM,YAAY;MACZ,YAAY;MACZ,yBAAyB,EAAA;;AAS/B;EACE;IACF,gBAAgB,EAAA,EACb","sourcesContent":[".exam_list_dashboar_container {\n  display: flex;\n  flex-direction: column;\n  padding-top: 30px;\n\n\n  .exam_list_dashboard_filter {\n    display: flex;\n    width: 100%;\n    margin-bottom: 30px;\n    align-items: center;\n    justify-content: space-between;\n\n\n    .exam_list_dashboard_ant_select {\n      width: 200px;\n      height: 35px;\n\n      @media screen and (max-width:600px) {\n        width: 170px;\n      }\n\n      .ant-select-selector {\n        border: 1px solid #088F7D;\n        /* Burada istediğiniz border rengini ve kalınlığını ayarlayabilirsiniz */\n\n        &:hover{\n          border: 1px solid #088F7D !important;\n        }\n      }\n\n    }\n\n    .exam_list_dashboard_ant_filter{\n      width: 150px;\n      height: 35px;\n      border: 1px solid #088F7D;\n    }\n  }\n\n\n\n\n}\n\n@media screen and(max-width:450px) {\n  .exam_list_dashboard_ant_filter{\nmargin-left: 5px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
