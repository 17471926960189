import React, { useEffect, useMemo, useState } from 'react'
import styled from "styled-components"
import { Button, Input, Modal, Select, Table } from 'antd'
import { getDynamicUserSearchApi, getUserListApi } from '../../api/UserApi'
import { FaArrowAltCircleRight, FaClosedCaptioning, FaSms } from 'react-icons/fa'
import { IoCloseCircle, IoCloseSharp } from 'react-icons/io5'
import { axiosInstance } from '../../api/axiosInstance'
import { toast } from 'react-toastify'
import { Nav, NavItem } from 'reactstrap'

const SmsStudentTab = () => {
    const [filterType, setFilterType] = useState("firstName")
    const [loading, setLoading] = useState(false)
    const [leftTableStudentList, setLeftTableStudentList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPage, setSelectedPage] = useState(1)
    const staticPageNumber = 10
    const [selectedLeftRowKeys, setSelectedLeftRowKeys] = useState([]);
    const [selectedRightRowKeys, setSelectedRightRowsKeys] = useState([])
    const [rightTableStudentList, setRightTableStudentList] = useState([])
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        message: ""
    })
    const [allMessageModal, setAllMessageModal] = useState({
        visible: false,
        message: ""
    })

    const dynamicSearch = async (e, page = 0) => {
        try {
            setLoading(true)
            if (e !== "") {
                const body = {
                    "sort": [
                        {
                            "field": "firstname",
                            "dir": "asc"
                        }
                    ],
                    "filter": {
                        "field": filterType,
                        "operator": "contains",
                        "value": e,
                    }
                }

                const response = await getDynamicUserSearchApi(body, {
                    page: page,
                    pageSize: staticPageNumber,
                    roleId: 3
                }
                )

                let result = response.data?.items?.map(item => {
                    const { id, ...rest } = item
                    return {
                        key: id,
                        ...rest
                    }
                })
                setLeftTableStudentList(result)
                setTotalCount(response.data.count)
            }
            else {
                setLeftTableStudentList([])
                setTotalCount(0)
            }
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }






    const leftToRightTransfer = () => {
        let filteredRightData = leftTableStudentList?.filter(item => {
            return selectedLeftRowKeys.includes(item?.key)
        })
        const result = [...rightTableStudentList, ...filteredRightData]
        console.log("result ", result)
        setRightTableStudentList(result)
        setSelectedLeftRowKeys([])
    }

    const rightToLeftTransfer = () => {
        let filteredData = rightTableStudentList?.filter(item => {
            return !selectedRightRowKeys?.includes(item?.key)
        })
        setRightTableStudentList(filteredData)
        setSelectedRightRowsKeys([])

    }

    return (
        <MainContainer>
            <MainHeader>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px"
                }} >
                    <StyledSelectInput defaultValue={"firstName"} onChange={(e) => {
                        setFilterType(e)
                    }}

                        options={[
                            {
                                "value": "firstName",
                                "label": "İsime göre ara"
                            },
                            {
                                "value": "lastName",
                                "label": "soyisime göre ara"
                            },
                            {
                                "value": "email",
                                "label": "emaile göre ara"
                            },
                            {
                                "value": "phone",
                                "label": "telefona göre ara"
                            },
                            {
                                "value": "tc",
                                "label": "Tc'ye göre ara"
                            }
                        ]}
                    >
                    </StyledSelectInput>
                    <StyledAntdInput
                        type="text"
                        placeholder="Arama yap..."
                        onChange={(e) => {
                            dynamicSearch(e.target.value)
                            setSearchTerm(e.target.value)
                        }}
                    />
                </div>
                <SendBtnContainer  >
                    <Button onClick={() => {
                        setAllMessageModal({
                            visible: true,
                            message: ""
                        })
                    }} type='primary' style={{
                        display: "flex",
                        columnGap: "5px",
                        alignItems: "center"
                    }} >
                        <FaSms /> Toplu Mesaj
                    </Button>

                    <Button onClick={() => {
                        setModalInfo({
                            visible: true,
                            message: ""
                        })
                    }} disabled={
                        rightTableStudentList.length == 0
                    } type='primary' style={{
                        display: "flex",
                        columnGap: "5px",
                        alignItems: "center"
                    }} >
                        <FaSms /> Gönder
                    </Button>
                </SendBtnContainer>
            </MainHeader>
            <StyledTableContainer>
                <Table
                    scroll={{
                        x: 600
                    }}

                    showHeader={true}
                    bordered={true}
                    className='table_1'
                    loading={loading}
                    dataSource={leftTableStudentList}
                    footer={() => {
                        return (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                <p>
                                    Toplam : {totalCount}
                                </p>
                                <Button type='primary' disabled={selectedLeftRowKeys.length == 0} onClick={() => {
                                    leftToRightTransfer()
                                }} >
                                    {">>"}
                                </Button>
                            </div>
                        )
                    }}
                    pagination={
                        {
                            position: [
                                "bottomLeft"
                            ],
                            current: selectedPage,
                            pageSize: staticPageNumber,
                            showSizeChanger: false,
                            total: totalCount,
                            onChange: (e) => {

                                dynamicSearch(searchTerm, e - 1)
                                setSelectedPage(e)

                            }
                        }
                    }
                    rowSelection={{
                        selectedRowKeys: selectedLeftRowKeys,
                        onChange: (e) => {
                            console.log("ee", e)
                            let control = rightTableStudentList.find(item => item?.key == e)
                            if (!control) {
                                setSelectedLeftRowKeys(e)
                            }

                        },
                        getCheckboxProps: (record) => {
                            let control = rightTableStudentList?.find(el => el?.key == record?.key)
                            return {
                                style: {
                                    display: control == undefined ? "inline-flex" : "none",
                                },

                            }
                        }
                    }}


                    columns={[
                        {
                            title: "İsim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.firstName}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Soyisim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.lastName}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Tc",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.tc}
                                    </p>
                                )
                            }
                        }
                    ]}
                />

                <Table
                    bordered={true}
                    className='table_2'
                    dataSource={rightTableStudentList}
                    scroll={{
                        x: 600
                    }}
                    pagination={
                        {
                            pageSize: 10,
                            showSizeChanger : false
                        }
                    }
                    rowSelection={{
                        selectedRowKeys: selectedRightRowKeys,
                        onChange: (e) => {
                            setSelectedRightRowsKeys(e)
                        },

                    }}
                    footer={() => {
                        return (
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <Button danger type='primary' disabled={selectedRightRowKeys.length == 0} onClick={() => {
                                    rightToLeftTransfer()
                                }} >
                                    <IoCloseSharp size={15} />
                                </Button>
                                <p>
                                    Toplam : {rightTableStudentList?.length}
                                </p>
                            </div>
                        )
                    }}
                    columns={[
                        {
                            title: "İsim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.firstName}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Soyisim",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.lastName}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Tc",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.tc}
                                    </p>
                                )
                            }
                        }
                    ]}
                />

            </StyledTableContainer>

            <Modal title="SMS" footer={() => {
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px"
                    }} >
                        <Button danger type='primary' onClick={() => {
                            setModalInfo({
                                visible: false,
                                message: ""
                            })
                        }} >
                            iptal
                        </Button>
                        <Button type='primary' loading={loading} disabled={modalInfo.message == "" || rightTableStudentList.length == 0} onClick={() => {
                            Modal.confirm({
                                title: "Seçili kişilere bu mesajı göndermek istediğinizden emin misiniz?",
                                cancelText: "İptal",
                                okText: "Eminim",
                                onOk: async () => {
                                    try {
                                        setLoading(true)

                                        await axiosInstance.post("/users/AllUsersSendSms", {
                                            phones: rightTableStudentList?.map(item => item?.phone),
                                            text: modalInfo.message
                                        })
                                        toast.success("Sms seçilen kişilere iletildi", {
                                            position: "top-center",
                                            autoClose: 2500
                                        })
                                        setModalInfo({
                                            visible: false,
                                            message: ""
                                        })
                                    }
                                    catch (err) {

                                    }
                                    finally {
                                        setLoading(false)
                                    }


                                }
                            })
                        }} >
                            Gönder
                        </Button>
                    </div>
                )
            }} open={modalInfo.visible} onCancel={() => {
                setModalInfo({
                    visible: false,
                    message: ""
                })
            }} >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    marginTop: "15px"
                }} >

                    <Input.TextArea placeholder='MESAJ' rows={6} value={modalInfo.message} style={{
                        resize: "none"
                    }} onChange={(e) => {
                        setModalInfo({
                            ...modalInfo,
                            message: e.target.value
                        })
                    }} />
                </div>

            </Modal>




            <Modal title="SMS" footer={() => {
                return (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px"
                    }} >
                        <Button danger type='primary' onClick={() => {
                            setAllMessageModal({
                                visible: false,
                                message: ""
                            })
                        }} >
                            iptal
                        </Button>
                        <Button type='primary' loading={loading} disabled={allMessageModal.message == ""} onClick={() => {
                            Modal.confirm({
                                title: "Kurslara kayıtlı bütün öğrencilere mesaj göndermek istediğinizden emin misiniz?",
                                cancelText: "İptal",
                                okText: "Eminim",
                                onOk: async () => {
                                    try {
                                        setLoading(true)

                                        await axiosInstance.post("/users/AllRegisteredUsersSendSms", {
                                            text: allMessageModal.message
                                        })
                                        toast.success("Sms Toplu olarak iletildi", {
                                            position: "top-center",
                                            autoClose: 3000
                                        })
                                        setAllMessageModal({
                                            visible: false,
                                            message: ""
                                        })
                                    }
                                    catch (err) {

                                    }
                                    finally {
                                        setLoading(false)
                                    }


                                }
                            })
                        }} >
                            Gönder
                        </Button>
                    </div>
                )
            }} open={allMessageModal.visible} onCancel={() => {
                setAllMessageModal({
                    visible: false,
                    message: ""
                })
            }} >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    marginTop: "15px"
                }} >

                    <Input.TextArea placeholder='MESAJ' rows={6} value={allMessageModal.message} style={{
                        resize: "none"
                    }} onChange={(e) => {
                        setAllMessageModal({
                            ...allMessageModal,
                            message: e.target.value
                        })
                    }} />
                </div>

            </Modal>
        </MainContainer>
    )
}

export default SmsStudentTab


const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
`

const MainHeader = styled.div`
    display: flex;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
    justify-content: space-between;

    @media screen and (max-width:600px) {
        flex-direction: column;
        row-gap: 10px;
    }
    
`

const StyledSelectInput = styled(Select)`
  width: 200px;
  height: 35px;
  @media screen  and (max-width:500px) {
      width: 160px;
  }
`

const StyledAntdInput = styled(Input)`
  width: 200px;
  height: 35px;
  border: 1px solid #1677FF;

  @media screen  and (max-width:500px) {
      width: 160px;
  }

`

const StyledTableContainer = styled.div`
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    height: max-content;

    @media screen and (max-width:600px) {
        flex-direction: column;
        row-gap: 30px;
    }

    .table_1, .table_2 {
        width: 50%;
        height: auto; /* Her iki tabloya da tam yükseklik verildi */

        @media screen and (max-width:600px) {
            width: 100%;
        }
    }

    .table_2 {


        .asdx{
            display: none;
        }
        
    }

    .arrow_container {
        display: flex;
        height: 100%;
        background-color: red;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .arrow_right_icon {
        font-size: 30px;
        cursor: pointer;
        color: blue;
    }
`;


const StyledTable = styled(Table)`
    width: 50%;
`


const StyledNav = styled(Nav)`
    width: 100%;
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /* Firefox için */
    scrollbar-width: none;

    /* Diğer tarayıcılar için */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    /* Edge ve IE 11 için */
    & {
        -ms-overflow-style: none; 
    }
`
const StyledNavItem = styled(NavItem)`
    white-space: nowrap;
`

const SendBtnContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;


    @media screen and (max-width:600px) {
        justify-content: space-between;
    }
`