import React, { useState } from 'react'
import MainNavbar from '../navbar/MainNavbar'
import "./homeTopStyle.scss"
import { SearchBar } from '../search/SearchBar'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import HomeCalendarLeaf from '../HomeComponents/HomeCalendarLeafCountdown/HomeCalendarLeaf'
import { useNavigate } from 'react-router-dom'

const HomeTopContainer = () => {
    const navigate = useNavigate()
    return (
        <div className='home_top_container'>
            <MainNavbar />

            <StyledContentContainer >
                {/* <HomeCalendarLeaf 
                    
                /> */}
                <BodyElement>
                    <h3 className='title'>
                        HERKES BURADA ÖĞRENİYOR
                    </h3>
                    <h4 className='sub_title' >
                        Uzaktan eğitimlerle bilgilerinize yenilerini ekleyin
                    </h4>
                    <SearchBar />

                    <div className='btn_container' >
                        <button className='btn_1' onClick={() => {
                            navigate("/egitimler/canliegitim")
                        }}  >
                            Canlı Eğitimler
                        </button>
                        <button className='btn_2' onClick={() => {
                            navigate("/egitimler/videoegitim")
                        }} >
                            Video Eğitimler
                        </button>
                    </div>
                </BodyElement>

            </StyledContentContainer>
        </div>
    )
}

export default HomeTopContainer


const StyledContentContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-top:100px;
`

const BodyElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 800px;
    transform: translateY(-33%);

    @media screen and (max-width:1100px) {
     width: 90%;
     transform: translateY(-13%);
    }

    @media screen and (max-width:600px) {
     width: 90%;
     transform: translateY(-13%);
    }

    .title {
        color: white;
        font-size: 40px;
        font-family: "Inter",sans-serif !important;
        text-align: center;
        font-weight: 700;

        @media screen and (max-width:600px) {
            
        }
    }

    .sub_title {
        color: #FFFFFF;
        font-size: 16px;
        font-family: "Inter",sans-serif !important;
        text-align: center;
    }


    .btn_container{
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 40px;
        margin-top: 20px;
        @media screen and (max-width:600px) {
            column-gap: 20px;
            }
        .btn_1{
            color: white;
            font-size: 18px;
            padding: 10px 60px;
            border-radius: 10px;
            background-color: #FF6905;
            border: 3px solid #BC9DFF;

            @media screen and (max-width:600px) {
                padding: 10px 10px;
            }
        }

        .btn_2{
            color: white;
            font-size: 18px;
            padding: 10px 60px;
            border-radius: 10px;
            background-color: #7F56D9;
            border: 3px solid #BC9DFF;

            @media screen and (max-width:600px) {
                padding: 10px 10px;
            }
        }
    }
`