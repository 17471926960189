import { axiosInstance } from "./axiosInstance";

const createCourseContent = (body) => axiosInstance.post("/CourseContents/Add", body)

const getAllCourseContent = (body) => axiosInstance.post("/CourseContents/GetAll", body)

const courseContentForCourseId = (body, courseId) => axiosInstance.post(`/CourseContents/GetAllForCourseId?courseId=${courseId}`, body)

const updateCourseContent = (body) => axiosInstance.put("/CourseContents/Update", body)

const deleteCourseContent = (contentId) => axiosInstance.delete(`/CourseContents/Delete/${contentId}`)

const addCourseFiles = (courseContentId, description, preview, duration, form) => axiosInstance.post(`/CourseFiles/Add?courseContentId=${courseContentId}&description=${description}&preview=${preview}&duration=${duration}`, form, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

const deleteCourseVideo = (id) => axiosInstance.delete(`/CourseFiles/Delete/${id}`)


const addOnlineCourseContentApi = (courseCalendarId, description, form) => axiosInstance.post(`/OnlineCourseFiles/Add?courseCalendarId=${courseCalendarId}&description=${description}`, form, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})


const CourseViewsAddApi = (body) => axiosInstance.post("/CourseViews/Add", body)

const CourseViewsForStudentApi = (id) => axiosInstance.get(`/CourseViews/GetAllForStudent/${id}`)


export {
    createCourseContent,
    getAllCourseContent,
    courseContentForCourseId,
    updateCourseContent,
    deleteCourseContent,
    addCourseFiles,
    deleteCourseVideo,
    addOnlineCourseContentApi,
    CourseViewsForStudentApi,
    CourseViewsAddApi
}