import React, { useContext, useMemo } from "react";
import ReactEcharts from 'echarts-for-react';
import { Card, CardHeader, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DashboardContext } from "./Dashboard";
import { UserContext } from "../../context/user";


const BarChart = ({ dataColors }) => {
    /* var chartBarColors = getChartColorsArray(dataColors); */

    const { dashboardUserData } = useContext(DashboardContext)
    const [context, dispatch] = useContext(UserContext)

    const barData = useMemo(() => {
      
        return dashboardUserData?.onlineCourseMonitoringChartDto
    }, [dashboardUserData])

    const totalOnlineUser = useMemo(() => {
        return dashboardUserData?.onlineCourseMonitoringCount
    }, [dashboardUserData])




    const textToRole = useMemo(() => {
        if (context.role == "Öğrenci") {
            return "Canlı eğitime katıldın."
        }
        return "Kişi canlı eğitime katıldı."

    }, [])



    var option = {
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            top: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: ['Pzt', 'Salı', 'Çrş', 'Prş', 'Cuma', 'Cmrts', 'Pzr'],
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                lineStyle: {
                    color: '#858d98'
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(133, 141, 152, 0.1)"
                }
            }
        },
        textStyle: {
            fontFamily: 'Poppins, sans-serif'
        },
        color: ["#24D198", "#EAFBF5"],
        series: [{
            data: barData ? Object.values(barData).map(el => el) : [],
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };

    return (
        <React.Fragment>
            <div>
            <Card style={{ border: "none" }} >
                <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1" > <b>Canlı Eğitim</b> </h4>
                  {/*   <div className="flex-shrink-0">
                        <UncontrolledDropdown className="card-header-dropdown">
                            <DropdownToggle tag="a" className="text-reset dropdown-btn" role="button">
                                <span className="text-muted">Tümü<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                          
                        </UncontrolledDropdown>
                    </div> */}
                </CardHeader>
            </Card>
            <ReactEcharts style={{ height: "350px" }} option={option} />


            <div style={{ display: "flex", alignItems: "center", columnGap: 10, marginTop: 20 }}>
                <p style={{ fontWeight: "700", fontSize: "20px",color:"#24D198" }}>
                    {totalOnlineUser}
                </p>
                <p style={{ color: "#6E7485", fontSize: "14px" }} >
                    {textToRole}
                </p>
            </div>
            </div>

        </React.Fragment>
    )
}

export default BarChart;