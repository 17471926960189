import React from 'react'
import styled from 'styled-components'
import { Button as AntButton } from "antd"
import { Link, useNavigate, useParams } from 'react-router-dom'
import LogoBelediye from "../../../../src/assets/images/logo.png"
import ProfileDropdown from '../../../Components/Common/ProfileDropdown'
import KuzemLogo from "../../../../src/assets/images/KuzemBlueLogo.png"
const CourseVideoPageHeader = ({ courseInfo, statusControl }) => {

  const navigate=useNavigate()
  
  return (
    <VideoPageHeader>
      <div className='videoPageHeader_left_section' >
        <Link to={"/"}>
          <img src={KuzemLogo} alt="" id='logoBel1' />
        </Link>
        <div id='lineVertical'>

        </div>
        {/* <img src={VerticalLine} alt="" id='lineVertical' /> */}
        <h5 className='offline_video_page_course_title' >
          {courseInfo?.name?.length > 40 ? `${courseInfo?.name?.substring(0, 40)}...` : courseInfo?.name}
        </h5>
      </div>
      {
        statusControl?.message == "Lütfen giriş yapınız." ? (

          <AntButton type='primary' onClick={() => {
            navigate("/giris")
          }} >
            Oturum Aç
          </AntButton>
        ) : (
          <ProfileDropdown visiblePanel={true} logoutMethod={() => {
            window.location.reload()
          }} />
        )
      }

    </VideoPageHeader>
  )
}

export default CourseVideoPageHeader


export const VideoPageHeader = styled.header`
        background-color: white !important;
         height: 70px;
       /*  padding: 15px; */
        padding-left: 60px;
        padding-right: 60px;
        background-color: #7F56D9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:600px) {
            padding-left: 20px;
            padding-right: 20px;
        }


    .videoPageHeader_left_section{
        display: flex;
        align-items: center;
        height: 100%;

        #logoBel1 {
                height: 50px;
                margin-right: 20px;
               
                @media screen and (max-width:500px) {
                    height: 35px;
                    margin-right: 10px;
                }
            }



        #lineVertical{
            height: 60px;
            color: black;
            width: 2px;
            background-color: black !important;

            @media screen and (max-width:600px) {
            display: none;
        }
        }

        
        .offline_video_page_course_title{
            color: black;
            margin-left: 20px;
            font-size: 15px;
            @media screen and (max-width:600px) {
            display: none;
        }
        }
    }

  
`