import React, { useContext, useEffect } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CategoryDashboard from '../../Components/Category/CategoryDashboard'
import { InfoContext } from '../../context/user'
import styled from 'styled-components'

const CategoryPage = () => {


    return (
        <div className='page-content' >
            <TablePageContainer title='Kategori' header={"Kategori"} visible={false}  >
                <StyledContainer>
                    <CategoryDashboard />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:500px) {
        padding: 0;
    }
`


export default CategoryPage