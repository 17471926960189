import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import BlogUpdateDashboard from '../../Components/Blog/BlogUpdateDashboard'

const BlogUpdatePage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Blog Güncelle"}  >
                <BlogUpdateDashboard />
            </TablePageContainer>
        </div>
    )
}

export default BlogUpdatePage