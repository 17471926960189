import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import TablePageContainer from '../../Components/Common/TablePageContainer';
import { Container, Row, Col } from 'reactstrap';
import Frame322 from "../../../src/assets/images/Frame322.png";
import Frame323 from "../../../src/assets/images/Frame323.png";
import Frame324 from "../../../src/assets/images/Frame324.png";
import avatarFemale from "../../../src/assets/images/avatarFemale.png";
import avatarMale from "../../../src/assets/images/avatarMale.png";

import Progressbar from "./Progress_Bar";
import "./Dashboard.scss";

import LastActivites from './LastActivites';
import SplineAreaChart from './SplineAreaChart';
import BarChart from './BarChart';
import MyRating from './Rating';
import CustomDataLabel from './TersColumn';
import { userDashboardRequest } from '../../api/dashboard';
import { UserContext } from '../../context/user';
import PanelNotice from '../../Components/PanelNotice/PanelNotice';
import styled from 'styled-components';

export const DashboardContext = createContext();

const Dashboard = () => {
  const [dashboardUserData, setDashboardUserData] = useState();
  const [context, dispatch] = useContext(UserContext);



  const getLastActivities = async () => {
    try {
      const response = await userDashboardRequest();
      setDashboardUserData(response.data);
    } catch (err) {

    }
  };

  const fullName = useMemo(() => {
    return context?.UserAndLastname;
  }, [context]);



  const completeProgressValue = useMemo(() => {
    let val = dashboardUserData?.offlineCourseProgress.split("%");
    if (val) {
      return {
        "percentData": val[0],
        "progressPercent": val[1].trim().substring(0, 1)
      };
    }
    return {
      "percentData": "0/2",
      "progressPercent": 0
    };
  }, [dashboardUserData]);

  const courseDetailInfo = useMemo(() => {
    return {
      "registeredVideoCount": dashboardUserData?.registeredVideoCount,
      "onlineCourseCount": dashboardUserData?.onlineCourseCount,
      "completedCourseCount": dashboardUserData?.completedCourseCount,
      "offlineCourseCount": dashboardUserData?.offlineCourseCount
    };
  }, [dashboardUserData]);

  useEffect(() => {
    getLastActivities();
  }, []);


  const avatar = context.gender === "Kadın" ? avatarFemale : avatarMale;

  function capitalizeAllWords(str) {
    let words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
    return words.join(" ");
  }

  return (
    <DashboardContext.Provider value={{ dashboardUserData }}>
      <PanelNotice />
      <div className='page-content'>
        <TablePageContainer header="Anasayfa" title='' headerVisible={false} visible={false}>
          {/* En üstteki 3 kutu */}
          <Row>
            <StyledCol sm={12} md={3} padRight={false} padLeft={true} >
              <StyledCardContainer>
                <img src={Frame322} alt="" className='card_img_dashboard' />
                <div className='content_wrapper'  >
                  <p className='number_element'>{courseDetailInfo?.registeredVideoCount}</p>
                  <p className='title_element'>Kayıtlı Videolar</p>
                </div>
              </StyledCardContainer>
            </StyledCol>
            <StyledCol sm={12} md={3} padRight={false} padLeft={false}  >
              <StyledCardContainer>
                <img src={Frame322} alt="" className='card_img_dashboard' />
                <div className='content_wrapper'  >
                  <p className='number_element'>{courseDetailInfo?.onlineCourseCount}</p>
                  <p className='title_element'>Aktif Kurslar</p>
                </div>
              </StyledCardContainer>
            </StyledCol>

            <StyledCol sm={12} md={3} padRight={true} padLeft={false} >
              <StyledCardContainer>
                <img src={Frame322} alt="" className='card_img_dashboard' />
                <div className='content_wrapper'  >
                  <p className='number_element'>{courseDetailInfo?.completedCourseCount}</p>
                  <p className='title_element'>Tamamlanan Çevrimiçi Kurslar</p>
                </div>
              </StyledCardContainer>
            </StyledCol>
            <StyledCol sm={12} md={3} padRight={true} padLeft={false} >
              <StyledCardContainer>
                <img src={Frame322} alt="" className='card_img_dashboard' />
                <div className='content_wrapper'  >
                  <p className='number_element'>{courseDetailInfo?.offlineCourseCount}</p>
                  <p className='title_element'>Kayıtlı Video Eğitim Sayısı</p>
                </div>
              </StyledCardContainer>
            </StyledCol>
          </Row>


          {/*     <Container fluid className='header-container'>
            <Col sm={12} md={3} className='header-info'>
              <img src={Frame322} alt="" className='image-header-info' />
              <Row className='header-info-text'>
                <p className='number'>{courseDetailInfo?.registeredVideoCount}</p>
                <p className='title'>Kayıtlı Videolar</p>
              </Row>
            </Col>
            <Col sm={12} md={3} className='header-info'>
              <img src={Frame323} alt="" className='image-header-info' />
              <Row className='header-info-text'>
                <p className='number'>{courseDetailInfo?.onlineCourseCount}</p>
                <p className='title'>Aktif Kurslar</p>
              </Row>
            </Col>
            <Col sm={12} md={3} className='header-info'>
              <img src={Frame324} alt="" className='image-header-info' />
              <Row className='header-info-text'>
                <p className='number'>{courseDetailInfo?.completedCourseCount}</p>
                <p className='title'>Tamamlanan Çevrimiçi Kurslar</p>
              </Row>
            </Col>
          </Container> */}

          {/* Kişi profil fotosu vs olan kısım */}
          <Container fluid className='profile-infos'>
            <Row className='profile-infoss'>
              <Col className=' profile-info profile-info1'>
                <img src={avatar} alt="" className='profile-photo' />

                <Row className='name-mail'>
                  <p id='name'>{/* {fullName} */}   {capitalizeAllWords(context?.userAndLastName)}</p>
                  <p id='mail'>{context?.email}</p>
                </Row>
              </Col>
              <Col className=' profile-info profile-info2'>
                <Progressbar
                  bgcolor="#24D198"
                  progress={completeProgressValue?.progressPercent}
                  height={30}
                  text={"Tamamlandı"}
                  text2={`${completeProgressValue?.percentData} Kurs`}
                />
              </Col>
            </Row>
          </Container>

          {/* Son Aktiviteler öğrenci ve canlı eğitim */}
          <Container fluid>
            <Row className='someinfos'>
              <Col className='son-aktiviteler mt-2' sm={12} md={12} lg={12} xl={12} xxl={6}>
                <LastActivites />
              </Col>
              <Col className='p-0 mt-2' sm={12} md={12} lg={12} xl={12} xxl={6}>
                <BarChart />
              </Col>
            </Row>
          </Container>

          {/* En alt kısım */}
          <Row className='mb-4'>
            <Col className='p-0 mt-3' xxl={7} lg={7} id='ratingBody'>
              <MyRating />
            </Col>
            <Col className='p-0 mt-3' xxl={5} lg={6}>
              <CustomDataLabel />
            </Col>
          </Row>
        </TablePageContainer>
      </div>
    </DashboardContext.Provider>
  );
}

export default Dashboard;


const StyledCardContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  align-items: center;
  padding: 15px;
  width: 100%;
  column-gap: 20px;
  min-height: 80px;
  max-height: 80px;
  .card_img_dashboard{
    height: 50px;
    width: 50px;
  }

  .content_wrapper{
    display: flex;
    flex-direction: column;
   /*  row-gap: 10px; */
          .number_element {
                font-size: 20px;
                font-weight: 400;
                color: #1D2026;
            }

            .title_element {
                color: #4E5566;
                font-weight: 400;
                font-size: 12px;
                white-space: nowrap;
            }
  }
`

const StyledCol = styled(Col)`
  /* padding: 0;
  padding-left: 15px; */
  padding-right: ${prop => prop?.padRight ? "" : "5px"};
  padding-left: ${prop => prop?.padLeft ? "" : "0px"};

  @media screen and (max-width:600px) {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
  }
`