import React, { useEffect, useState } from 'react'
import { Table as AntTable, Button as AntButton } from "antd"

import styled from 'styled-components'
import { FaFilePdf } from 'react-icons/fa'
import { axiosInstance } from '../../../../api/axiosInstance'
import TablePageContainer from '../../../Common/TablePageContainer'
import { useParams } from 'react-router-dom'


const UpdateCourseCertificate = () => {
    const [loading, setLoading] = useState(false)
    const [certificateData, setCertificateData] = useState([])
    const { id } = useParams()

    const getAllCertificate = async () => {
        try {
            const response = await axiosInstance.post(`/Certificates/GetAll?courseId=${id}`, {
                page: 0,
                pageSize: 100
            })
            setCertificateData(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllCertificate()
    }, [])

    return (

        <AntTable
            loading={loading}
            size='middle'
            scroll={{
                x: 700
            }}
            pagination={{
                pageSize: 10
            }}
            dataSource={certificateData}
            columns={[
                {
                    title: "İsim",
                    render: (row, record) => {
                        return (
                            <span>
                                {
                                    record?.user.firstName
                                }
                            </span>
                        )
                    }
                },
                {
                    title: "Soyisim",
                    render: (row, record) => {
                        return (
                            <span>
                                {
                                    record?.user.lastName
                                }
                            </span>
                        )
                    }
                },
                {
                    title: "Kurs",
                    dataIndex: "courseName",
                    render: (row, record) => {
                        return (
                            <span>
                                {record?.courseName}
                            </span>
                        )
                    }
                },
                {
                    title: "Ortalama",

                    render: (row, record) => {
                        return (
                            <span>
                                {record?.average}
                            </span>
                        )
                    },
                    sorter: (a, b) => a?.average - b?.average

                },
                {
                    title: "Sertifika",
                    render: (row, record) => {
                        return (
                            <AntButton type='primary' style={{ display: "flex", alignItems: "center" }}   >
                                <FaFilePdf size={14} />
                                <span style={{ marginLeft: "7px" }} >
                                    Görüntüle
                                </span>
                            </AntButton>
                        )
                    }


                }

            ]}
        />



    )
}

const StyledContainer = styled.div`
    padding: 15px;

    @media screen and (max-width:600px) {
        padding: 5px;
    }
`


export default UpdateCourseCertificate