
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CiPhone, CiMail, CiLocationOn } from "react-icons/ci";
import "./index.scss"
import { RxInstagramLogo } from "react-icons/rx";
import { RiTwitterXFill, RiYoutubeFill } from "react-icons/ri";

import PropTypes from 'prop-types'
import { useTranslation } from "react-i18next"

import { Link, NavLink, useNavigate } from 'react-router-dom';
import CardFooter from '../cardFooter/CardFooter';
import footerImg from "../../../assets/images/footerImg.png"
import footerImg2 from "../../../assets/images/footerImg2.png"
import footerImg3 from "../../../assets/images/footerImg3.png"
import footerImg4 from "../../../assets/images/footerImg4.png"
import logoFooter from "../../../assets/images/logoFooter.png"
import { useEffect, useState } from 'react';
import { axiosInstance } from '../../../api/axiosInstance';
import { FaFacebookSquare } from "react-icons/fa";
import styled from 'styled-components';
import pdfFile from "../../../assets/files/kuzemaydinlatma.pdf"
import kuzemkayit from "../../../assets/files/kuzemkayitsart.docx"
import { getAllCategories } from '../../../api/Category';


const MainFooter = (props) => {
    const { t } = useTranslation();
    const [offlineData, setOfflineData] = useState([])
    const navigate = useNavigate()

    /*  const getCategories = async (page) => {
         try {
             const response = await getAllCategories({
                 page: page,
                 pageSize: 10000
             })
             setCategory(response.data.items)
         }
         catch (err) {
 
         }
     } */

    const fetchCourses = async () => {
        try {
            const response = await axiosInstance.post('/branches/GetAll?type=Online', {
                page: 0,
                pageSize: 100
            });

            setOfflineData(response.data.items?.filter(item => item?.coursePreviewStatus).slice(0, 3))
        } catch (error) {

        }
    };
    /* ---------------getCoursesİnformation---------------- */

    const [teacher, setTeacher] = useState("");
    const [student, setStudent] = useState("");
    const [video, setVideo] = useState("");
    const [user, setUser] = useState("");



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/Courses/GetCourseInformation', {
                    page: 0,
                    pageSize: 50
                });
                setTeacher(response.data.teacherCount);
                setStudent(response.data.studentCount);
                setVideo(response.data.videoCount);
                setUser(response.data.totalUserCount);


            } catch (error) {

            }
        };

        fetchData();
        fetchCourses()
    }, []);



    return (
        <div style={{ backgroundColor: '#7F56D9' }}>
            <Container fluid className='pt-3 Footer'>

                <Container className='containerCards' >
                    <Row className='rowCards'>
                        <Col xs={6} md={2} >
                            <CardFooter id='card' section={footerImg} number={320} catagorie={"Branş"} visibility={props.visibility}></CardFooter>

                        </Col>
                        <Col xs={6} md={2}>
                            <CardFooter id='card' section={footerImg2} number={student} catagorie={t("f2")} visibility={props.visibility}></CardFooter>

                        </Col>
                        <Col xs={6} md={2}>
                            <CardFooter id='card' section={footerImg3} number={17} catagorie={"Alan"} visibility={props.visibility}></CardFooter>

                        </Col>
                        <Col xs={6} md={2}>
                            <CardFooter id='card' section={footerImg4} number={video} catagorie={t("f3")} visibility={props.visibility}></CardFooter>
                        </Col>


                    </Row>
                </Container>



                <Row className='mt-3 pt-2  row1'>
                    {/*  <Col md={12}>
                        <p style={{ color: "white", marginBottom: "15px" }} >
                            KOMEK Uzaktan Eğitim Merkezi Konya Büyükşehir Belediyesi kuruluşudur.
                        </p>
                    </Col> */}
                    <Col sm={9} xs={9} md={9} lg={3} style={{ paddingLeft: '0' }} id='collogoFooter'>
                        <img src={logoFooter} alt="" id='logoFooter' />
                    </Col>



                    <Col sm={4} xs={12} md={4} lg={3} className='colcc  colcc1'>
                        <h4 className='title'> {t("f5")}</h4>
                        <StyledElementContainer>
                            {
                                offlineData && offlineData.map(el => {
                                    return (
                                        <StyledLink key={el?.id} to={`/kurs/${el?.label}`}  >
                                            {
                                                el?.name?.length > 20 ? `${el?.name?.substring(0, 20)}...` : el?.name
                                            }
                                        </StyledLink>
                                    )
                                })
                            }

                        </StyledElementContainer>

                    </Col>




                    <Col sm={4} xs={12} md={4} lg={3} className='colcc1'>

                        <h4 className='title' style={{ fontWeight: "bold" }} >{t("f10")}</h4>
                        <StyledElementContainer>

                            <StyledLink to={"/kurumsal"}  >
                                Başkanın Mesajı
                            </StyledLink>
                            <StyledLink to={"/iletisim"} >
                                İletişim
                            </StyledLink>
                        </StyledElementContainer>
                        {/* <Col style={{ display: 'flex', alignItems: "center" }} className='mt-3'> <CiPhone className='text ' /> <p className='text' style={{ whiteSpace: 'pre-wrap' }}>    (505) 555-0104</p>  </Col>
                        <Col style={{ display: 'flex', alignItems: "center" }} className='mt-1'><CiMail className='text ' /> <p className='text' style={{ whiteSpace: 'pre-wrap' }}>     info@komek.com.tr</p> </Col> */}
                    </Col>

                    <Col sm={4} xs={12} md={4} lg={3} className='colcc1'>
                        <h4 className='title' style={{ fontWeight: "bold" }} >{t("f11")}</h4>
                        <StyledElementContainer>
                            <StyledLink to={"/ekitap"} >
                                E Kitap
                            </StyledLink>
                            <StyledLink to={"/guncel"} >
                                Güncel
                            </StyledLink>
                            <StyledLink to={"/blog"} >
                                Blog
                            </StyledLink>
                        </StyledElementContainer>
                    </Col>




                    <Col xs={0} md={0} lg={0} id='colVidee'> </Col>

                </Row>


                <StyledKomekDescription  >
                    KOMEK Uzaktan Eğitim Merkezi Konya Büyükşehir Belediyesi kuruluşudur.
                </StyledKomekDescription>


                <StyledLinkWrapper >
                    <StyledHr />
                    <div className='link_container' >
                        <a className='copyright_element' href='https://sehirteknolojileri.com/' target='_blank'>Copyright 2024 | Şehir Teknolojileri Merkezi</a>
                        <div className='link_element_wrapper' >
                            <p className='link_container_element' onClick={() => {

                            }} >
                                Gizlilik Koşulları
                            </p>
                            <p className='link_container_element' onClick={() => {
                                window.open(kuzemkayit, "_blank")
                            }}  >
                                Kullanım Şartları
                            </p>
                            <p className='link_container_element' onClick={() => {
                                window.open(pdfFile, '_blank');
                            }} >
                                Aydınlatma Metni
                            </p>

                        </div>
                        <div className='social_media_container'>

                            <a  ><FaFacebookSquare className='socialMedia' /></a>
                            <a href="https://x.com/KomekUzem" target='_blank'> <RiTwitterXFill className='socialMedia' /></a>
                            <a href="https://www.instagram.com/komekuzem/" target='_blank'> <RxInstagramLogo className='socialMedia' /></a>
                            <a href="https://www.youtube.com/@komekuzem" target='_blank'> <RiYoutubeFill className='socialMedia' /></a>
                        </div>
                    </div>

                </StyledLinkWrapper>

            </Container>

        </div>
    )
}



const StyledElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 15px;
`
const StyledLink = styled(Link)`
    color: white;
    text-transform: capitalize;
    &:hover{
        color: white !important;
    }
`
const StyledFooterInfoWrapper = styled.div`
    display: flex;
    justify-content: flex-start !important;
    column-gap: 10px;
    align-items: flex-start;
    flex: 1;
    margin-left: 20px;


    
`

const StyledHr = styled.div`
            background-color: white;
            height: 1px;
            width:100%;
            margin-top: 10px;

            @media screen and (max-width:600px) {
                width: 100%;
            }
`

const StyledLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
        
    @media screen and (max-width:600px) {
        width: 100%;
    }

    .link_container{
        display: flex;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        @media screen and (max-width:600px) {
            flex-direction: column;
        }

        .social_media_container{
            display: flex;
            column-gap: 5px;
            
            @media screen and (max-width:600px) {
                justify-content: center;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .socialMedia{
                color: white;
                font-size: 16px;
            }
        }


        .link_element_wrapper{
            display: flex;
            justify-content: flex-start !important;
            column-gap: 10px;
            align-items: flex-start;
            flex: 1;
            margin-left: 20px;

            @media screen and (max-width:600px) {
               margin-left: 0px;
               margin-top: 20px;
               justify-content: center;
               align-items: center;
            }

            .link_container_element{
                border-left: 2px solid white;
                padding-left: 10px;
                color:white;
                cursor: pointer;
                @media screen and (max-width:600px) {
                    border-left: none;
                    padding-left: 0;
                 }
        
            }
        }

        .copyright_element{
            color: white;

            
            @media screen and (max-width:600px) {
                text-align: center;
            }
        }
    }
`

const StyledKomekDescription = styled.p`
    color: white;
    margin-top: 55px;
    text-transform: capitalize;
    text-align: center;
`


export default MainFooter
