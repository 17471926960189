import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { getExamDetailByIdApi } from '../../api/exam'
import { PropagateLoader } from 'react-spinners'
import LoadingElement from '../Loader/LoadinElement'
import ExamUpdateGeneralInfo from './ExamGeneralInfo/ExamGeneralInfo'
import { GetCalendarForAllRoles } from '../../api/Course'
import ExamQuestionList from './ExamQuestions/ExamQuestionList'
import ExamResultDashboard from './ExamResult/ExamResultDashboard'
import ExamGivePoint from './ExamGivePoint/ExamGivePoint'
import styled from 'styled-components'

export const ExamUpdateContext = createContext()

const ExamUpdateDashboard = ({ setHeader }) => {
    const navigation = useNavigate()
    const [activeTab, setActiveTab] = useState(1)
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const [questionIndex, setQuestionIndex] = useState(0)
    const [examDetailData, setExamDetailData] = useState()


    const getExamInfo = async () => {
        try {
            setLoading(true)
            const response = await getExamDetailByIdApi(id)
            setExamDetailData(response.data)
       
            setHeader(response.data.name)
        }
        catch (err) {
       
            navigation(-1)
        }
        finally {
            setLoading(false)
        }
    }




    const courseType = useMemo(() => {
        return examDetailData?.type == "Test"
    }, [examDetailData])


    const loadingControl = useMemo(() => {
        return loading && examDetailData != undefined
    }, [loading, examDetailData])


    const getCourseList = async () => {
        try {
            setLoading(true)
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 3
            })
            const allCount = response.data.count

            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: allCount
            })
            setCourseList(responsev2.data.items)
        

        }
        catch (err) {
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        getExamInfo()
        getCourseList()
    }, [])


    return (
        <ExamUpdateContext.Provider value={{
            examDetailData,
            setExamDetailData,
            setHeader,
            courseList,
            getExamInfo,
            questionIndex,
            setQuestionIndex
        }} >
            <div  >
                <StyledNav tabs  >
                    <StyledNavItem>
                        <NavLink

                            style={activeTab == 1 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                            className={`${activeTab == 1 && "active"}`} onClick={() => setActiveTab(1)}

                        >
                            Sınav bilgileri
                        </NavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <NavLink
                            style={activeTab == 2 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}
                            className={`${activeTab == 2 && "active"}`} onClick={() => { setActiveTab(2) }} >
                            Sınav soruları
                        </NavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                        <NavLink onClick={() => {
                            setActiveTab(3)
                        }} style={activeTab == 3 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}>
                            Sınav sonuçları
                        </NavLink>
                    </StyledNavItem>

                    {
                        !courseType && (
                            <StyledNavItem>
                                <NavLink onClick={() => {
                                    setActiveTab(4)
                                }} style={activeTab == 4 ? { backgroundColor: "#088F7D", color: "white", cursor: "pointer" } : { cursor: "pointer" }}>
                                    Puanlama
                                </NavLink>
                            </StyledNavItem>
                        )
                    }
                </StyledNav>
                {
                    loadingControl ? (
                        <LoadingElement color={"#088F7D"} size={20} />
                    ) : (
                        <TabContent activeTab={activeTab} >
                            <TabPane tabId={1} >
                                <ExamUpdateGeneralInfo />
                            </TabPane>
                            <TabPane tabId={2} >
                                <ExamQuestionList />
                            </TabPane>
                            <TabPane tabId={3} >
                                <ExamResultDashboard />
                            </TabPane>
                            <TabPane tabId={4} >
                                <ExamGivePoint />
                            </TabPane>
                        </TabContent>
                    )
                }

            </div >
        </ExamUpdateContext.Provider >

    )
}

const StyledNav = styled(Nav)`
    width: 100%;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    /* Firefox için */
    scrollbar-width: none;

    /* Diğer tarayıcılar için */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    
    /* Edge ve IE 11 için */
    & {
        -ms-overflow-style: none; 
    }
`
const StyledNavItem = styled(NavItem)`
    white-space: nowrap;
`


export default ExamUpdateDashboard