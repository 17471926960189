import React, { useContext, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { Button } from 'reactstrap'
import { ExamContext } from '../ExamManagementDashboard'
import { Button as AntButton } from "antd"
const ExamDragAndDrop = () => {
    const { examData, setPoolFlag, setExamData, setPreviewSrc, setModalShow, setModalQuestion, setQuestionPoint, setModalChoiceList, setEditMode } = useContext(ExamContext)


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(examData.questions);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setExamData({
            ...examData,
            questions: reorderedItems
        });
    };

    const questionUpdate = (element) => {

        if (element?.poolFlag) {
            setPoolFlag(true)
        }

        setEditMode({
            active: true,
            id: element.id
        })
        setModalShow(true)
        setModalQuestion(element.questionContent)
        setQuestionPoint(element.point)
        setModalChoiceList(element.choices)
        setPreviewSrc(element.file)
    }


    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <Droppable droppableId="droppable">
                {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0 }}  >
                        {examData.questions.map((item, index) => {
                    
                            return (
                                <Draggable key={item.id} draggableId={item.id?.toString()} index={index}>
                                    {(provided) => (
                                        <li
                                            className='sortable_question_item'
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className='sortable_question_item_left_wrapper' >
                                                <span className='sortable_question_item_index_element' >
                                                    {index + 1}
                                                </span>
                                                {item.questionContent?.length > 30 ? item.questionContent?.substring(0, 30) + " ..." : item.questionContent}
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                                                <AntButton type='primary' color='secondary' onClick={() => {
                                     
                                                    questionUpdate(item)
                                                }} >
                                                    <FiEdit className='sortable_question_item_edit_icon' />
                                                </AntButton>
                                                <AntButton type='primary' danger onClick={() => {
                                                    setExamData({
                                                        ...examData,
                                                        questions: examData.questions.filter(el => {
                                                            return el.id !== item.id
                                                        })
                                                    })
                                                }}


                                                >
                                                    <FiTrash className='sortable_question_item_edit_icon' />
                                                </AntButton>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            )
                        })}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default ExamDragAndDrop