import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import OfflineCourseDashboard from '../../Components/Course/OfflineCourse/OfflineCourseDashboard'
import ActiveCourseTable from '../../Components/Course/ActiveCourse/ActiveCourseTable'
import styled from 'styled-components'
import OfflineCourseGeneral from '../../Components/Course/OfflineCourse/OfflineCourseGeneral/OfflineCourseGeneral'

const OfflineCoursePage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Çevrimdışı Kurslar' header={"Çevrimdışı kurslar"} visible={false} >
                <StyledContainer>
                    <OfflineCourseGeneral />
                    {/* <ActiveCourseTable offlineOrOnline={"Offline"} /> */}
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;
    
    @media screen and (max-width:600px) {
        padding: 5px;
    }
`

export default OfflineCoursePage