import React, { useEffect, useMemo, useState } from 'react'
import styled from "styled-components"
import { getAllReceivedNotification, getAllSendNotifications } from '../../api/Notifications'
import { getDetailCourseApi } from '../../api/Course'
import { useNavigate } from 'react-router-dom'
import { Col, Row, Select } from 'antd'
import { axiosInstance } from '../../api/axiosInstance'
const TeacherAllMessageDashboard = ({ courseInfo }) => {
    const [loading, setLoading] = useState(false)
    const [receivedMessage, setReceivedMessage] = useState([])
    const [messageStatus, setMessageStatus] = useState(0)
    const [unReadMessagesCount, setUnReadMessagesCount] = useState(0)
    const navigate = useNavigate()

    const getMessageList = async () => {
        try {
            setLoading(true)
            const receivedMessage = await getAllReceivedNotification({
                page: 0,
                pageSize: 1000
            })
            
            let sortDataList = receivedMessage.data.items?.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt)
            })?.reverse()

            setReceivedMessage(sortDataList)
            setUnReadMessagesCount(receivedMessage.data?.unReadMessagesCount)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const messageList = useMemo(() => {
        if (messageStatus == 0) {
            return receivedMessage.filter(item => !item?.status)
        }
        else {
            return receivedMessage.filter(item => item?.status)
        }
    }, [receivedMessage, messageStatus])

    const formatDate = (time) => {
        const day = time?.split("T")[0]
        const hour = time?.split("T")[1]?.substring(0, 5)

        return `${day}-${hour}`
    }



    useEffect(() => {
        getMessageList()
    }, [])

    return (
        <StyledMainContainer>
            <Row style={{
                width: "100%"
            }} >
                <Col md={24} >
                    <Select
                        style={{
                            width: "100%",
                            height: "40px"
                        }}
                        loading={loading}
                        value={messageStatus}
                        onChange={(e) => {
                            setMessageStatus(e)
                        }}
                        options={[{
                            label: (
                                <StyledUnreadMessage >
                                    Okunmamış Mesajlar
                                    <span className='badge_element'>
                                        {unReadMessagesCount}
                                    </span>
                                </StyledUnreadMessage>
                            ),
                            value: 0
                        }, {
                            label: "Okunmuş Mesajlar",
                            value: 1
                        }
                        ]}
                    />
                </Col>
            </Row>
            <MessageContainer>
                {
                    messageList.map((item, index) => {
                        return (
                            <StyledMessageElement key={`${index}`} onClick={async () => {
                                try {
                                    await axiosInstance.put("/notifications/updateStatus", {
                                        userId: item?.sentUserId
                                    })
                                    navigate(`/panel/egitmen/mesaj?userId=${item?.sentUserId}`)
                                }
                                catch (err) {

                                }
                                finally {

                                }

                            }} >
                                <p className='student_info' >
                                    {item?.sentUser}
                                </p>
                                <div className='user_msg_wrapper' >
                                    <p className='user_msg' >
                                        {item?.message}
                                    </p>
                                    <StyledMessageTime>
                                        {formatDate(item?.createdAt)}
                                    </StyledMessageTime>
                                </div>
                            </StyledMessageElement>
                        )
                    })
                }

            </MessageContainer>
        </StyledMainContainer>
    )
}

export default TeacherAllMessageDashboard



const StyledMainContainer = styled.div`
    display: flex;
    padding: 10px;
    flex-direction: column;
    row-gap: 15px;
    @media screen and (max-width:600px) {
               padding: 5px;
            }
`

const MessageContainer = styled.div`
    height: 600px;
    width: 100%;
    padding-top: 30px;
  /*   border: 1px solid gray; */
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    overflow-y:scroll;
`

const StyledMessageElement = styled.div`
    padding: 10px;
    background-color: #33A293;
    max-width: 900px;
    border-radius:10px ;
    border-top-left-radius: 0;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;

    .user_msg_wrapper{
        display: flex;
        flex-direction: column;

        .user_msg{
        
        color: white;
        padding-top: 5px;
        font-size: 12px;
        @media screen and (max-width:600px) {
               padding-top: 15px;
            }
        }

    }

    .student_info{
            position: absolute;
            top: -20px;
            left: 0;
            border-radius: 5px;
            border-bottom-left-radius: 0;
            padding: 3px 10px;
            color: white;
            background-color: #277CB5;


            @media screen and (max-width:600px) {
                top: -20px
            }
         }
    
`

const StyledUnreadMessage = styled.p`
    position: relative; 
    width: max-content;
    .badge_element{
        position: absolute;
        top: 0;
        right: -18px;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: green;
        color: white;
    }
`

const StyledMessageTime = styled.span`
  color: #73EC8B;
  display: inline-block;
  margin-top: 5px;
  font-size: 12px;
  text-align: end;

`