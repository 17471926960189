import styled from "styled-components";


export const VideoPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #F6F6F6;
    min-height: 100vh;
`

export const VideoPageHeader = styled.header`
        background-color: white !important;
         height: 70px;
       /*  padding: 15px; */
        padding-left: 60px;
        padding-right: 60px;
        background-color: #7F56D9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:600px) {
            padding-left: 20px;
            padding-right: 20px;
        }


    .videoPageHeader_left_section{
        display: flex;
        align-items: center;
        height: 100%;

        #logoBel1 {
                height: 50px;
                margin-right: 20px;
               
                @media screen and (max-width:500px) {
                    height: 35px;
                    margin-right: 10px;
                }
            }



        #lineVertical{
            height: 60px;
            color: black;
            width: 2px;
            background-color: black !important;

            @media screen and (max-width:600px) {
            display: none;
        }
        }

        
        .offline_video_page_course_title{
            color: black;
            margin-left: 20px;

            @media screen and (max-width:600px) {
            display: none;
        }
        }
    }

  
`

export const VideoPageBreadCrumbContainer = styled.div`
    padding-left:60px;
    display: flex;
    height: 60px;
    align-items: center;

    @media screen and (max-width:600px) {
        padding-left: 10px;
    }

`

export const VideoPageMainContentContainer = styled.div`
    display: grid;
    grid-template-columns: 5fr 2fr;
    padding: 0 20px;
    column-gap: 20px;

    @media screen and (max-width:600px)  {
        grid-template-columns: 1fr ;
        padding: 0 10px;
    }
`

export const VideoPageLeftContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const VideoPageRightContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    row-gap: 20px;

    .rightSectionTopElement{
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }


    .rightSectionTeacherWrapper{
        /* display: flex; */
        padding: 15px 20px;
        background-color: white;
    }
`

export const VideoPageleftContentGeneralInfoWrapper = styled.div`
    display: flex;
    background-color: white;
    padding: 20px;
    /* border-radius: 5px; */
    column-gap: 30px;
    border-bottom: 4px solid whitesmoke;


    @media screen and (max-width:600px) {
        flex-direction: column;
        padding: 10px;
    }


    .videoPageCoverPhoto{
        height: 200px;
        width: 350px;
        border-radius: 10px;
        min-width: 350px;


        @media screen and (max-width:600px) {
            width: 100%;
            min-width: 0;
        }
    }

    .videoPageContentInfoSection{
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 20px;
        h5{
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 2px;
            background-color: #F6F6F6;
            width: 100%;
            padding: 10px;
            color: black;
            border-radius: 10px;
        }
        h6{
            font-size: 16px;
        }

        .like_container{
            display: flex;
            align-items: center;
            column-gap: 30px;
            font-size: 16px;

            .like_btn {
                display: flex;
                align-items: center;
                column-gap: 5px;
            }
        }
    }
`

export const StyledJoinRightSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .right_section_info_element{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon_wrapper{
            display: flex;
            align-items: center;
            font-size: 16px;

            #right_icon{
                font-size: 24px;
            }
        }

        .right_result{
            font-size: 16x;
            font-weight: 700;
        }
    }
`