import React, { useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { Button } from 'antd';
import axios from 'axios';

Quill.register('modules/imageResize', ImageResize);

const Editor = ({ editorHtml, setEditorHtml }) => {
  console.log("editorHtml< =", editorHtml)
  const handleChange = (html) => {
    setEditorHtml(html);
  };
  const [loading, setLoading] = useState(false)
  const createContent = async () => {
    try {
      setLoading(true)
      await axios.post("http://localhost:4242/editor", {
        content: editorHtml
      })
    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }


  return (
    <div>
      <ReactQuill
        theme="snow"
        value={editorHtml}
        onChange={handleChange}
        modules={Editor.modules}
        formats={Editor.formats}
        bounds={'#root'}
      />
      <Button type='primary' loading={loading} onClick={() => {
        createContent()
      }} >
        Kaydet
      </Button>
    </div>
  );
};


Editor.modules = {
  toolbar: [

    [
      {
        header: [1, 2, 3, 4, 5, 6, false]
      }
    ],
    [{
      font: []
    }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['link', 'image'],
    [{ color: [] }, { background: [] }], // Renk seçenekleri
    ['clean'], // Biçimlendirmeyi temizle
    ['undo', 'redo'], // Geri alma ve yeniden yapma butonları
    [{ 'history': 'undo' }, { 'history': 'redo' }] // Quill history modülünü ekledik
  ],
  history: {
    delay: 2000,  // Geri alma işlemi için gecikme
    userOnly: true // Sadece kullanıcı aksiyonlarına izin ver
  },
  clipboard: {
    matchVisual: false
  },
  imageResize: {
    parchment: Quill.import('parchment'),
    modules: ['Resize', 'DisplaySize']
  }
};




export default Editor;
