import React, { createContext, useContext, useState } from 'react'
import "./teacherSurveyDashboard.scss"
import { RiSurveyLine } from "react-icons/ri";
import { GoPlusCircle } from "react-icons/go";
import CreateSurvey from './CreateSurvey/CreateSurvey';
import SurveyListDashboard from './SurveyListDashboard/SurveyListDashboard';
import { Row, Col } from 'reactstrap';

export const SurveyContext = createContext()

const TeacherSurveyDashboard = () => {
    const [activeTab, setActiveTab] = useState(1)
    const [courseList, setCourseList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState("")
    const [surveyInfo, setSurveyInfo] = useState({
        name: "",
        description: ""
    })
    const [answerList, setAnswerList] = useState([])
    const [createQuestionModal, setCreateQuestionModal] = useState({
        editMode: false,
        questionId: "",
        visible: false,
        questionContent: ""
    })
    const [questionItems, setQuestionItems] = useState([]);


    return (
        <SurveyContext.Provider value={{
            courseList,
            setCourseList,
            selectedCourse,
            setSelectedCourse,
            createQuestionModal,
            setCreateQuestionModal,
            questionItems,
            setQuestionItems,
            surveyInfo,
            setSurveyInfo,
            answerList,
            setAnswerList
        }} >

            <section className='teacher_survey_dashboard_container' >
                <header className='teacher_survey_dashboard_header' >

                    <div  className='teacher_survey_dashboard_header_item' style={{ backgroundColor: activeTab == 1 ? "#0AB39C" : null, color: activeTab == 1 ? "white" : "black" }} onClick={() => setActiveTab(1)}  >
                        <RiSurveyLine className='teacher_survey_dashboard_header_surveys_icon' style={{ color: activeTab == 1 ? "white" : "#0AB39C" }} />
                        <p className='teacher_survey_dashboard_item_title' >
                            Anketler
                        </p>
                    </div>
                    <div  style={{ backgroundColor: activeTab == 2 ? "#0AB39C" : null, color: activeTab == 2 ? "white" : "black" }} className='teacher_survey_dashboard_header_item' onClick={() => setActiveTab(2)}  >
                        <GoPlusCircle className='teacher_survey_dashboard_header_surveys_icon' style={{ color: activeTab == 2 ? "white" : "#0AB39C" }} />
                        <p className='teacher_survey_dashboard_item_title' >
                            Anket Oluştur
                        </p>
                    </div>


                </header>
                <div className='teacher_survey_dashboard_content'  >
                    {
                        activeTab == 1 && (
                            <SurveyListDashboard />
                        )
                    }
                    {
                        activeTab == 2 && (
                            <CreateSurvey />
                        )
                    }
                </div>
            </section>

        </SurveyContext.Provider>

    )
}

export default TeacherSurveyDashboard