import axios from "axios"
import { baseURL } from "./axiosInstance"
import jwtDecode from "jwt-decode";

const checkTokenExpiration = () => {
    const accessToken = localStorage.getItem("authToken");
    if (!accessToken) return true;

    const decodedToken = jwtDecode(accessToken);
    const expirationTime = decodedToken.exp * 1000; // Unix timestamp'i milisaniyeye çeviriyoruz
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;
    
    // Token'ın süresinin dolmasına 1 dakikadan az kaldıysa
    return timeUntilExpiration < 60000; // 60000 ms = 1 dakika
}

const createRefreshToken = async () => {
    try {
        // Token'ın süresinin dolmasına 1 dakikadan fazla varsa işlem yapma
        if (!checkTokenExpiration()) return;

        const refreshToken = localStorage.getItem("refreshToken");
        const { data } = await axios.post(`${baseURL}/auth/CreateAccessTokenWithRefreshToken`, refreshToken, {
            headers: {
                "Accept": "*/*",
                "Content-Type": "application/json-patch+json"
            }
        });

        localStorage.setItem("authToken", data.accessToken.token);
        localStorage.setItem("refreshToken", `"${data.refreshToken.token}"`);
        
        return data;
    } catch (error) {
        console.error("Token yenileme hatası:", error);
        
    }
}

export {
    createRefreshToken,
    checkTokenExpiration
}
