import { Input, Button } from "antd";
import styled from "styled-components";

export const MainCategoryContainer = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`

export const CategoryHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`


export const StyledCategorySearchInput = styled(Input)`
    padding: 5px;
    width: 200px;
    border: 1px solid #3498db ;
    height: 35px;

    @media screen and (max-width:500px) {
        width: 120px;
    }
`

export const StyledCategoryAntButton = styled(Button)`
    height: 35px;
`