import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner,
} from "reactstrap";
import { Button as AntButton, Input as AntInput, DatePicker, Modal } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { UserContext } from "../../../context/user";
/* import { loginJWT } from "../../api/user"; */

import { toast } from "react-toastify";
import styled from "styled-components";
import AuthSlider from "../../AuthenticationInner/AuthSlider";
import { axiosInstance } from "../../../api/axiosInstance";

/* test */

const Register = (props) => {
    const navigate = useNavigate();

    const [state, dispatch] = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [userLogin, setUserLogin] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const location = useLocation()


    const getQuery = () => {
        const query = new URLSearchParams(location.search)
        const returnPage = query.get("page")
    
        return returnPage
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            tc: "",
            email: "",
            phone: "",
            phoneConfirm: "",
            password: "",
            passwordConfirm: "",
            birthDate: ""
        },
        validationSchema: Yup.object({
            tc: Yup
                .string()
                .length(11, "T.C. Kimlik Numarası 11 haneli olmalıdır.")
                .matches(/^[0-9]+$/, "T.C. Kimlik Numarası sadece rakamlardan oluşmalıdır.")
                .required("T.C. Kimlik Numarası boş bırakılamaz."),
            email: Yup.string().email("email formatında olmalı").required("email boş bırakılamaz."),
            phone: Yup.string()
                .matches(/^[0-9]{10}$/, "Telefon numarası  10 haneli olmalı.")
                .required("Telefon boş bırakılamaz."),
            phoneConfirm: Yup
                .string()
                .oneOf([Yup.ref('phone'), null], "Telefon numaraları aynı olmalıdır.")  // phone ile karşılaştırma
                .required("Telefon numaranızı tekrar giriniz"),
            birthDate: Yup.date().required("Doğum tarihi boş bırakılamaz"),
            password: Yup
                .string()
                .min(6, "Şifre en az 6 karakter olmalıdır.").max(8, "Şifre en fazla 8 karekter olmalıdır")  // En az 5 karakter olmalı
                .required("Şifre boş bırakılamaz."),
            passwordConfirm: Yup
                .string()
                .oneOf([Yup.ref('password'), null], "Şifreler aynı olmalıdır.")  // Şifreyi karşılaştır
                .required("Şifreyi tekrar giriniz.")
        }),
        onSubmit: async (values) => {
            Modal.confirm({
                title: "Kayıt olmak istediğinize emin misiniz ?",
                type: "confirm",
                okText: "Eminim",
                cancelText: "iptal",
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const birthDates = values.birthDate.split("-")
                       
                        const response = await axiosInstance.post("/Auth/register", {
                            phone: values.phone,
                            email: values.email,
                            tc: values.tc,
                            birthDate: `${birthDates[2]}-${birthDates[1]}-${birthDates[0]}`,
                            password: values.password
                        })
                        validation.resetForm()

                        toast.success("Kayıt işleminiz tamamlandı", {
                            autoClose: 2500,
                            position: "top-center"
                        })

                        navigate("/giris")
                    }
                    catch (err) {


                    }
                    finally {
                        setIsLoading(false)
                    }
                }
            })
            
        },
    });



    useEffect(() => {
        let token = localStorage.getItem("authToken")
        if (token) {
            window.history.back()
        }
    }, [])


    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <StyledCard className="overflow-hidden">
                                    <Row className="g-0">
                                        <AuthSlider />

                                        <Col lg={6}>
                                            <StyledFormElementWrapper >
                                                <div className="text-center mt-2">
                                                    <h5 className="text-primary">Hoş Geldiniz !</h5>
                                                    {/* <p className="text-muted">
                                                        Devam etmek için giriş yapın.
                                                    </p> */}
                                                </div>

                                                <div className="p-2 mt-4">
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            validation.handleSubmit();
                                                            return false;
                                                        }}
                                                        action="#"
                                                    >
                                                        <StyledTcBirthDateContainer   >
                                                            <div style={{ width: "100%" }} >
                                                                <Label
                                                                    htmlFor="tc"
                                                                    className="form-label"
                                                                >
                                                                    Tc No
                                                                </Label>
                                                                <StyledAntInput placeholder="Tc No" style={{
                                                                    height: "35px",
                                                                    width: "100%"
                                                                }}

                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.tc}
                                                                    max={11}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("tc", e.target.value)
                                                                    }}
                                                                />
                                                                {validation.touched.tc &&
                                                                    validation.errors.tc ? (
                                                                    <StyledErrorMessage>
                                                                        {validation.errors.tc}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                            <div style={{ width: "100%" }}>
                                                                <Label
                                                                    className="form-label"
                                                                    htmlFor="password-input"
                                                                >
                                                                    Email
                                                                </Label>
                                                                <div className=" mb-3">
                                                                    <StyledAntInput
                                                                        placeholder="Email"
                                                                        style={{
                                                                            height: "35px",
                                                                            width: "100%"
                                                                        }}
                                                                        onChange={(e) => {
                                                                            validation.setFieldValue("email", e.target.value)
                                                                        }}
                                                                        onBlur={validation.handleBlur}

                                                                    />
                                                                    {validation.touched.email &&
                                                                        validation.errors.email ? (
                                                                        <StyledErrorMessage >
                                                                            {validation.errors.email}
                                                                        </StyledErrorMessage>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </StyledTcBirthDateContainer>
                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="birthDate"
                                                            >
                                                                Doğum Tarihi
                                                            </Label>
                                                            <div className="mb-3">
                                                                <input
                                                                    style={{
                                                                        marginLeft: "0px"
                                                                    }}
                                                                    type="date"
                                                                    id="birthDate"
                                                                    name="birthDate"
                                                                    className="form-control" // Stil için Bootstrap sınıfını kullanıyoruz
                                                                    onChange={(e) => {
                                                                        const selectedDate = e.target.value;
                                                                    
                                                                        validation.setFieldValue("birthDate", selectedDate); // Değeri Formik ile güncelle
                                                                    }}
                                                                    onBlur={validation.handleBlur} // Blur işlemi için mevcut Formik fonksiyonunu kullanıyoruz
                                                                />
                                                                {validation.touched.birthDate && validation.errors.birthDate ? (
                                                                    <StyledErrorMessage>
                                                                        {validation.errors.birthDate}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="password-input"
                                                            >
                                                                Telefon
                                                            </Label>
                                                            <div className=" mb-3">
                                                                <PhoneInputContainer>
                                                                    <StyledTrCode
                                                                        disabled
                                                                        value={"+90"}

                                                                    />
                                                                    <StyledAntInput
                                                                        placeholder="Telefon"
                                                                        style={{
                                                                            height: "35px"
                                                                        }}
                                                                        onChange={(e) => {
                                                                            validation.setFieldValue("phone", e.target.value)
                                                                        }}
                                                                        onBlur={validation.handleBlur}

                                                                    />
                                                                </PhoneInputContainer>

                                                                {validation.touched.phone &&
                                                                    validation.errors.phone ? (
                                                                    <StyledErrorMessage >
                                                                        {validation.errors.phone}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="password-input"
                                                            >
                                                                Telefon Onay
                                                            </Label>
                                                            <div className=" mb-3">
                                                                <PhoneInputContainer>
                                                                    <StyledTrCode
                                                                        disabled
                                                                        value={"+90"}

                                                                    />
                                                                    <StyledAntInput
                                                                        placeholder="Telefon Tekrar"
                                                                        style={{
                                                                            height: "35px"
                                                                        }}
                                                                        onChange={(e) => {
                                                                            validation.setFieldValue("phoneConfirm", e.target.value)
                                                                        }}
                                                                        onBlur={validation.handleBlur}

                                                                    />
                                                                </PhoneInputContainer>

                                                                {validation.touched.phoneConfirm &&
                                                                    validation.errors.phoneConfirm ? (
                                                                    <StyledErrorMessage >
                                                                        {validation.errors.phoneConfirm}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="password-input"
                                                            >
                                                                Parola
                                                            </Label>
                                                            <div className=" mb-3">
                                                                <StyledAntInput.Password
                                                                    placeholder="Parola"
                                                                    style={{
                                                                        height: "35px"
                                                                    }}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("password", e.target.value)
                                                                    }}
                                                                    onBlur={validation.handleBlur}

                                                                />


                                                                {validation.touched.password &&
                                                                    validation.errors.password ? (
                                                                    <StyledErrorMessage >
                                                                        {validation.errors.password}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label"
                                                                htmlFor="password-input"
                                                            >
                                                                Parola Tekrar
                                                            </Label>
                                                            <div className=" mb-3">
                                                                <StyledAntInput.Password
                                                                    placeholder="Parola"
                                                                    style={{
                                                                        height: "35px"
                                                                    }}
                                                                    onChange={(e) => {
                                                                        validation.setFieldValue("passwordConfirm", e.target.value)
                                                                    }}
                                                                    onBlur={validation.handleBlur}

                                                                />


                                                                {validation.touched.passwordConfirm &&
                                                                    validation.errors.passwordConfirm ? (
                                                                    <StyledErrorMessage >
                                                                        {validation.errors.passwordConfirm}
                                                                    </StyledErrorMessage>
                                                                ) : null}
                                                            </div>
                                                        </div>

                                                        <div className="mt-4">
                                                            <StyledAntButton

                                                                onClick={() => {
                                                                    validation.handleSubmit()
                                                                }}
                                                                loading={isLoading}
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                                type="primary"
                                                            >
                                                                Kayıt Ol
                                                            </StyledAntButton>
                                                        </div>
                                                    </Form>
                                                </div>
                                                <div className="mt-4 text-center">
                                                    <Link to={"/giris"} >
                                                        Zaten hesabım var
                                                    </Link>
                                                </div>
                                            </StyledFormElementWrapper>
                                        </Col>
                                    </Row>
                                </StyledCard>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                        &copy; 2023 Şehir Teknolojileri Merkezi tarafından yapıldı.{" "}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};


export default Register

const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`

const StyledDateInput = styled(DatePicker)`
    width: 100%;
    height: 35px;
`

const PhoneInputContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`
const StyledTrCode = styled(AntInput)`
    height: 35px;
    width: 60px;
`

const StyledAntButton = styled(AntButton)`
 background-color: #078378 !important;
    transition: .5s all;

     &:hover{
        background-color: #399F95 !important;
     } 
`

const StyledCard = styled(Card)`
     margin-right: 0;
`


const StyledAntInput = styled(AntInput)`
    height: 35px;
    width : default; `


const StyledTcBirthDateContainer = styled.div`
    display: flex;
    column-gap: 15px;
    
    @media screen and (max-width:600px) {
        flex-direction: column;
        row-gap: 10px;
    }
`

const StyledFormElementWrapper = styled.div`
    padding: 3rem 2rem;


    @media screen and (max-width:600px){
        padding: 2rem 10px;
    }
`