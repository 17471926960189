import React, { useEffect, useState, useContext } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner,
} from "reactstrap";
import { Button as AntButton, Input as AntInput, Modal } from "antd"
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import { UserContext } from "../../../context/user";
/* import { loginJWT } from "../../api/user"; */

import AuthSlider from "../../AuthenticationInner/AuthSlider";
import { toast } from "react-toastify";
import styled from "styled-components";
import { axiosInstance } from "../../../api/axiosInstance";





const ResetPassword = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const validation = useFormik({
        initialValues: {
            tc: "",
        },
        validationSchema: Yup.object({
            tc: Yup
                .string()
                .length(11, "T.C. Kimlik Numarası 11 haneli olmalıdır.")
                .matches(/^[0-9]+$/, "T.C. Kimlik Numarası sadece rakamlardan oluşmalıdır.")
                .required("T.C. Kimlik Numarası boş bırakılamaz.")
        }),
        onSubmit: (values) => {
            Modal.confirm({
                okText: "Eminim",
                cancelText: "İptal",
                title: "Şifreyi sıfırlamak istediğinize emin misiniz?",
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        await axiosInstance.put("/users/forgetPassword", {
                            tc: values.tc
                        })

                        toast.success("Parola telefonunuza sms olarak gönderilmiştir", {
                            autoClose: 4000,
                            position: "top-center"
                        })
                        navigate("/giris")
                    }
                    catch (err) {

                    }
                    finally {
                        setIsLoading(false)
                    }
                }
            })

        },
    });


    return (

        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <StyledCard className="overflow-hidden">
                                <Row className="g-0">
                                    <AuthSlider />

                                    <Col lg={6}>
                                        <div className="p-lg-5 p-4 mt-lg-5 mb-lg-5">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">Şifremi Unuttum</h5>

                                            </div>

                                            <div className="mt-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#"
                                                >
                                                    <div className="mb-3">
                                                        <Label
                                                            htmlFor="tc"
                                                            className="form-label"
                                                        >
                                                            Tc No
                                                        </Label>
                                                        <StyledAntInput placeholder="Tc No" style={{
                                                            height: "35px"
                                                        }}

                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.tc}
                                                            max={11}
                                                            onChange={(e) => {
                                                                validation.setFieldValue("tc", e.target.value)
                                                            }}
                                                        />

                                                        {validation.touched.tc &&
                                                            validation.errors.tc ? (
                                                            <StyledErrorMessage>
                                                                {validation.errors.tc}
                                                            </StyledErrorMessage>
                                                        ) : null}
                                                    </div>




                                                    <div className="mt-4">
                                                        <AntButton
                                                            onClick={() => {
                                                                validation.handleSubmit()
                                                            }}
                                                            loading={isLoading}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                            type="primary"
                                                        >
                                                            Şifreyi Sıfırla
                                                        </AntButton>
                                                    </div>
                                                </Form>
                                            </div>

                                            <StyledLinkWrapper>
                                                <Link to={"/giris"} >
                                                    Giriş Yap
                                                </Link>
                                            </StyledLinkWrapper>
                                        </div>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </Col>
                    </Row>
                </Container>
            </div>

            <footer className="footer">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center">
                                <p className="mb-0 text-muted">
                                    &copy; 2023 Şehir Teknolojileri Merkezi tarafından yapıldı.{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>

    )
}

export default ResetPassword

const StyledAntInput = styled(AntInput)`
    height: 35px;
`

const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`


const StyledCard = styled(Card)`
     margin-right: 0;
`

const StyledLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`


const StyledTrCode = styled(AntInput)`
    height: 35px;
    width: 60px;
`

const PhoneInputContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
`