import React, { useState, useMemo, useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from "yup"
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { DatePicker, Select, Steps } from 'antd'
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { createCourseApi } from '../../../api/Course';
import { getAllCategories } from '../../../api/Category';
import styled from 'styled-components';
import { getAllBranch } from '../../../api/Branch';



const AddOfflineCourseForm = ({ formik }) => {
  const [categories, setCategories] = useState([])
  const [branchList, setBranchLİst] = useState([])
  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 1901;
    const yearArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearArray.push(year);
    }
    return yearArray;
  }, []);

  const getCategories = async () => {
    try {
      const response = await getAllCategories({
        page: 0,
        pageSize: 1000
      })

      setCategories(response.data.items)
    }
    catch (err) {

    }
  }



  const getBranchesList = async () => {
    try {
      const response = await getAllBranch({
        page: 0,
        pageSize: 2000
      })
      setBranchLİst(response.data?.items)

    }
    catch (err) {

    }
  }


  useEffect(() => {
    getCategories()
    getBranchesList()
  }, [])



  return (
    <Form onSubmit={formik.handleSubmit} >
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label className="form-label">
              Kategori
            </Label>
            <StyledSelect
              showSearch
              onChange={(e) => {
                formik.setFieldValue("categoryId", e)
              }} value={formik.values.categoryId}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }

            >
              <Select.Option value="">
                Seçim Yapınız
              </Select.Option>
              {
                categories.map((el, index) => {
                  return (
                    <Select.Option value={el.id} key={`${index}`} >
                      {el.name}
                    </Select.Option>
                  )
                })

              }
            </StyledSelect>
            {formik.touched.categoryId && formik.errors.categoryId ? (
              <StyledErrorMessage>{formik.errors.categoryId}</StyledErrorMessage>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label className="form-label">
              Branş
            </Label>
            <StyledSelect
              showSearch
              onChange={(e) => {
                formik.setFieldValue("branchId", e)
              }} value={formik.values.branchId}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }

            >
              <Select.Option value="">
                Seçim Yapınız
              </Select.Option>
              {
                branchList.map((el, index) => {
                  return (
                    <Select.Option value={el.id} key={`${index}`} >
                      {el.name}
                    </Select.Option>
                  )
                })

              }
            </StyledSelect>
            {formik.touched.branchId && formik.errors.branchId ? (
              <StyledErrorMessage>{formik.errors.branchId}</StyledErrorMessage>
            ) : null}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label className="form-label">
              Kayıt Silme Aktif Olsun mu?
            </Label>
            <StyledSelect onChange={(e) => {
              formik.setFieldValue("registerDeleteStatus", e)
            }} value={formik.values.registerDeleteStatus}  >
              <option value="">
                Seçim Yapınız
              </option>
              {
                ["Evet", "Hayır"].map((el, index) => {
                  return (
                    <option value={el} key={`${index}`} >
                      {el}
                    </option>
                  )
                })

              }
            </StyledSelect>
            {formik.touched.registerDeleteStatus && formik.errors.registerDeleteStatus ? (
              <StyledErrorMessage >{formik.errors.registerDeleteStatus}</StyledErrorMessage>
            ) : null}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label className="form-label">
              Kayıt Esnasında Görünsün mü?
            </Label>
            <StyledSelect onChange={(e) => {
              formik.setFieldValue("previewStatus", e)
            }} value={formik.values.previewStatus}>
              <option value="">
                Seçim Yapınız
              </option>
              {
                ["Evet", "Hayır"].map((el, index) => {
                  return (
                    <option value={el} key={`${index}`} >
                      {el}
                    </option>
                  )
                })

              }
            </StyledSelect>
            {formik.touched.previewStatus && formik.errors.previewStatus ? (
              <StyledErrorMessage>{formik.errors.previewStatus}</StyledErrorMessage>
            ) : null}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label className="form-label">
              Toplam Süre
            </Label>
            <StyledInput
              value={formik.values.totalTime}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder='Toplam Süre'
              name='totalTime'
              invalid={formik.touched.totalTime && formik.errors.totalTime ? true : false}
              type='number' />
            {formik.touched.totalTime && formik.errors.totalTime ? (
              <FormFeedback type="invalid"><div>{formik.errors.totalTime}</div></FormFeedback>
            ) : null}
          </div>
        </Col>

        {/*    <Col lg={6} >

          <div className="mb-3">
            <Label className="form-label">
              Ön Sınav Olacakmı ?
            </Label>
            <StyledSelect onChange={(e) => {
              formik.setFieldValue("prelim", e)
            }} value={formik.values.prelim}>
              {
                [{
                  "label": "Evet",
                  "value": true
                }, {
                  "label": "Hayır",
                  "value": false
                }].map((el, index) => {
                  return (
                    <option value={el.value} key={`${index}`} >
                      {el.label}
                    </option>
                  )
                })

              }
            </StyledSelect>
            {formik.touched.prelim && formik.errors.prelim ? (
              <StyledErrorMessage>{formik.errors.prelim}</StyledErrorMessage>
            ) : null}
          </div>
        </Col> */}


        <Col lg={12}>
          <div className="mb-3">
            <Label className="form-label">
              Açıklama
            </Label>
            <Input value={formik.values.description}
              onBlur={formik.handleBlur}
              invalid={formik.touched.description && formik.errors.description ? true : false}
              onChange={formik.handleChange}
              name='description' id='description' type='textarea'
              rows={2} style={{ resize: "none" }} />

            {formik.touched.description && formik.errors.description ? (
              <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
            ) : null}
          </div>
        </Col>


        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button style={{ padding: "7px 40px" }} onClick={() => {
            formik.handleSubmit()
          }} >
            Kaydet
          </Button>
        </div>
      </Row>

    </Form>

  )
}



const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`
const StyledInput = styled(Input)`
    height: 33px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 3px;
font-size: 11px;

`

export default AddOfflineCourseForm