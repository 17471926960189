

import React, { useContext, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
/* import { dealsStatus } from "../../common/data"; */
import icon from "../../../src/assets/images/Icon-comment.png";
import "./LastActivities.scss"
import { DashboardContext } from './Dashboard';
import { UserContext } from '../../context/user';
import { Alert } from 'antd';
import styled from "styled-components"

const LastActivites = () => {
    /*  const navigate = useNavigation() */
    const { dashboardUserData } = useContext(DashboardContext)
    const [context, dispatch] = useContext(UserContext)
    const navigate = useNavigate()

    const lastNotifications = useMemo(() => {
        return dashboardUserData?.lastNotifications
    }, [dashboardUserData])


    const userRole = useMemo(() => {
        return context?.role
    }, [context])



    return (
        <React.Fragment>
            <Col xl={12}>
                <StyledCard >
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">  <b>Son Aktiviteler</b> </h4>
                    </CardHeader>
                    <CardBody style={{ padding: "0" }} >
                        <table className="table table-borderless table-hover table-nowrap align-middle mb-0" >
                            <tbody >

                                <Container fluid className='son-aktiviteler-body' style={{ borderRadius: "0px" }}>
                                    {
                                        lastNotifications?.length !== 0 ? lastNotifications?.map((el, index) => {

                                            return (
                                                <Col key={`${index}`} style={{
                                                    cursor: "pointer"
                                                }} onClick={() => {
                                                    if (userRole == "Öğretmen") {
                                                        navigate(`/panel/egitmen/mesaj?userId=${el?.userId}`)
                                                    }
                                                    else {
                                                        navigate(`/panel/ogrenci/mesaj?userId=${el?.userId}`)
                                                    }

                                                }}  >
                                                    <Row className='comment-box'>
                                                        <img src={icon} alt="" style={{ height: "32px", width: "auto", padding: "0" }} />
                                                        <Col style={{ display: "flex", flexDirection: "column" }}>
                                                            <div> <b>
                                                                {el?.user?.firstName} sana
                                                            </b> "{el?.message}" yazdı. </div>
                                                            <p style={{ color: "#8C94A3", fontSize: "12px", fontWeight: "400" }}>
                                                                {new Date(el?.createdAt).toLocaleString()}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        }) : (
                                            <StyledAlert  message="Herhangi bir aktivite bulunamadı." type="warning" />
                                        )
                                    }


                                    {/*    {
                                        lastNotifications.length == 0 ? (
                                            <div>
                                                <h3 style={{ color: "#FF7044" }}>Gösterilecek aktivite bulunamamıştır.</h3>
                                            </div>
                                        ) : (
                                            ""
                                        )
                                    } */}


                                </Container>


                            </tbody>
                        </table>
                        {/* </div> */}
                    </CardBody>
                </StyledCard>
            </Col>
        </React.Fragment>
    );
};

export default LastActivites;



const StyledCard = styled(Card)`
    border-radius: 7px !important;

    @media screen and (max-width:600px) {
        margin-right: 0 !important;
    }
`

const StyledAlert=styled(Alert)`
    border-bottom-width: 1px !important;
`