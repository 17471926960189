// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#card {
  width: 97%; }
  #card #cardBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
    #card #cardBody #cardTitle {
      display: flex;
      justify-content: center;
      font-size: 80%;
      padding-top: 8%;
      white-space: nowrap; }
    #card #cardBody #imgCard {
      padding-top: 2%;
      width: 35%; }
`, "",{"version":3,"sources":["webpack://./src/Public/components/cardCategories/index.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA;EADd;IAGQ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB,EAAA;IAN9B;MASY,aAAa;MACb,uBAAuB;MACvB,cAAc;MACd,eAAe;MACf,mBAAmB,EAAA;IAb/B;MAkBY,eAAe;MACf,UAAU,EAAA","sourcesContent":["#card {\n    width: 97%;\n    #cardBody {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n\n        #cardTitle {\n            display: flex;\n            justify-content: center;\n            font-size: 80%;\n            padding-top: 8%;\n            white-space: nowrap;\n        }\n\n\n        #imgCard {\n            padding-top: 2%;\n            width: 35%;\n        }\n\n\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
