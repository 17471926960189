import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import UpdateAdminForm from '../../Components/Admin/UpdateAdminForm'
import styled from 'styled-components'

const UpdateAdminPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Admin Güncelle' header={"Admin Güncelle"} visible={false} >
                <StyledContainer>
                    <UpdateAdminForm />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 10px;
`


export default UpdateAdminPage