import jwtDecode from "jwt-decode";

function getDatesBetween(startDate, endDate, dayOfWeek) {
    const dates = [];
    let currentDate = new Date(startDate);

    // Başlangıç tarihinden bitiş tarihine kadar tüm tarihleri kontrol et
    while (currentDate <= endDate) {
        if (currentDate.getDay() === dayOfWeek) {
            dates.push(new Date(currentDate)); // Tarihleri ekleyin
        }
        currentDate.setDate(currentDate.getDate() + 1); // Bir sonraki günü kontrol etmek için bir sonraki günü ayarlayın
    }

    return dates;
}

function getDayIndex(startDay) {
    const daysOfWeek = ['pazar', 'pazartesi', 'salı', 'çarşamba', 'perşembe', 'cuma', 'cumartesi'];
    const startDayIndex = daysOfWeek.indexOf(startDay.toLowerCase());

    if (startDayIndex === -1) {
        throw new Error('Geçersiz başlangıç günü.');
    }

    return startDayIndex;
}

export {
    getDatesBetween,
    getDayIndex
}



export function compareWithCurrentTime(dateTimeString) {
    // Verilen tarihi ve zamanı bir Date nesnesine dönüştür
    const givenDate = new Date(dateTimeString);

    // Şu anki zamanı al
    const currentDate = new Date();
    // Tarihlerin karşılaştırılması için gerekli değerleri al
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenDay = givenDate.getDate();
    const givenHour = givenDate.getHours();
    const givenMinute = givenDate.getMinutes();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();

    // Verilen tarih şu andan ileri mi yoksa geri mi olduğunu kontrol et
    if (givenYear > currentYear ||
        (givenYear === currentYear && givenMonth > currentMonth) ||
        (givenYear === currentYear && givenMonth === currentMonth && givenDay > currentDay) ||
        (givenYear === currentYear && givenMonth === currentMonth && givenDay === currentDay && givenHour > currentHour) ||
        (givenYear === currentYear && givenMonth === currentMonth && givenDay === currentDay && givenHour === currentHour && givenMinute > currentMinute)) {
        return true;
    } else {
        return false;
    }
}


export function isTokenValid(token) {
    if (!token) {
        // Token yoksa geçersiz kabul edilir.
        return false;
    }

    // Tokeni '.' karakterine göre bölerek parçalıyoruz.
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        // JWT standartlarına uygun değilse geçersiz kabul edilir.
        return false;
    }

    // Payload kısmını Base64'den çözüyoruz.
    const payload = JSON.parse(atob(tokenParts[1]));

    // 'exp' (expiration) alanını kontrol ediyoruz.
    if (!payload.exp) {
        // Son kullanma tarihi belirtilmemişse tokeni geçersiz kabul ediyoruz.
        return false;
    }

    // Şu anki zamanı alıyoruz (Unix zaman damgası olarak).
    const currentTime = Math.floor(Date.now() / 1000);

    // Tokenin son kullanma tarihini kontrol ediyoruz.
    return currentTime < payload.exp;
}


export const getMinuteDifference = (startTime, endTime) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
    return differenceInMinutes.toFixed(0);
};


export function isTimeBeforeJwtExpiration(token, minutesToAdd) {
    try {
        // Tokeni decode edip exp alanını alın
        const decoded = jwtDecode(token);
        const exp = decoded.exp;

        // Exp alanını Unix zaman damgasından milisaniyeye çevirin
        const expirationTime = exp * 1000;

        // Şu anki zamanı alın
        const currentTime = new Date();

        // Şu anki zamanın üzerine belirtilen dakikayı ekleyin
        const newTime = new Date(currentTime.getTime() + minutesToAdd * 60000);

        // Yeni zaman expiration zamanından önce mi?
        return newTime.getTime() < expirationTime;
    } catch (error) {
        console.error("Geçersiz token:", error);
        return false;
    }
}