// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/MainBgImage.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_top_container {
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 600px) {
    .home_top_container {
      background-position: 40% center; } }
`, "",{"version":3,"sources":["webpack://./src/Public/components/Home/homeTopStyle.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,kHAAwH;EACxH,sBAAsB;EACtB,4BAA4B;EAC5B,aAAa;EACb,aAAa;EACb,sBAAsB,EAAA;EAGtB;IAVJ;MAWQ,+BAA+B,EAAA,EAEtC","sourcesContent":[".home_top_container {\n    width: 100%;\n    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(\"../../../assets/images/MainBgImage.jpg\");\n    background-size: cover;\n    background-repeat: no-repeat;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n\n\n    @media screen and (max-width:600px) {\n        background-position: 40% center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
