import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { toast } from 'react-toastify'
import { CgClose, CgCloseR } from 'react-icons/cg'
import { getDatesBetween, getDayIndex } from '../../../utils/utils'
import { createCourseApi } from '../../../api/Course';


const AddCourseProgram = ({ programData, programList, setProgramData, setProgramList, setCurrent, formik, programDateList, setProgramDateList }) => {

    const dayList = useMemo(() => {
        return ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"]
    }, [])
    const format = 'HH:mm'


    const [showProgramDateList, setShowProgramDateList] = useState({
        visible: false,
        selectedItem: ""
    })

    const addProgram = () => {

        if (programData.duration < 10 || programData.duration > 60) {
            toast.error("Ders Süresi 10 ila 60dk arasında olmalıdır", {
                autoClose: 1000
            })
        }
        else {
            const randomId = Math.random().toString()
            setProgramList([
                ...programList,
                {
                    ...programData,
                    id: randomId
                }
            ])
            setProgramData({
                id: "",
                day: "Pazartesi",
                startTime: "12:00",
                duration: 0
            })
            const allProgram = []

            const dataList = [...programList, {
                ...programData,
                id: randomId
            }]
   

            for (let item of dataList) {
                allProgram.push({
                    id: item.id,
                    day: item.day,
                    duration: item.duration,
                    startTime: item.startTime,
                })
            }
            setProgramDateList(allProgram)


        }


    }


    function parseISODate(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day);
    }


    const deleteDay = (el) => {
        setProgramList(programList.filter(item => item.id !== el.id))
        setProgramDateList(programDateList.filter(item => item.id !== el.id))
    }

  

    const createCourse = async () => {
        const { startYear, endYear, startDate, endDate, ...rest } = formik.values
        const response = await createCourseApi({
            ...rest,
            startDate: new Date(startDate).toUTCString(),
            endDate: new Date(endDate).toUTCString(),
            startYear: parseInt(startYear),
            endYear: parseInt(endYear),
            registerTime: new Date().toUTCString()
        })
      
        const list = programDateList.map(el => {
            const { id, ...rest } = el
            return {
                ...el,
                startTime: saatStringToISOString(el.startTime),
                days: [
                    el.day
                ]
            }
        })
     

      

    }

    function saatStringToISOString(saatString) {
        // Şu anki tarih ve zaman bilgisini al
        var simdikiZaman = new Date();

        // Yıl, ay ve gün bilgisini al
        var yil = simdikiZaman.getFullYear();
        var ay = simdikiZaman.getMonth() + 1; // JavaScript'te ay 0 ile başlar, bu yüzden 1 ekliyoruz
        var gun = simdikiZaman.getDate();

        // Verilen saat string'ini tarih ve zaman bilgisiyle birleştir
        var tarihVeZaman = new Date(yil, ay - 1, gun, saatString.split(":")[0], saatString.split(":")[1]);

        // İstediğiniz formata dönüştür
        var istenenFormat = tarihVeZaman.toISOString();

        return istenenFormat;
    }


    return (
        <>
            <Row>
                <Col lg={3} >
                    <div className="mb-3">
                        <Label className="form-label">
                            Gün
                        </Label>
                        <select className={`form-control`} value={programData.day} onChange={(e) => {
                            setProgramData({
                                ...programData,
                                day: e.target.value
                            })
                        }} >
                            {
                                dayList.map((el, index) => {
                                    return (
                                        <option key={`${index}`} value={el}  >
                                            {el}
                                        </option>
                                    )
                                })
                            }
                        </select>

                    </div>
                </Col>
                <Col lg={3} >
                    <div className="mb-3">
                        <Label className="form-label">
                            Başlangıç Saati
                        </Label>
                        <div>
                            <TimePicker allowClear={false}
                                onChange={(e) => {
                                    setProgramData({
                                        ...programData,
                                        startTime: `${e.hour()}:${e.minute().toString().padStart(2, "0")}`
                                    })
                                }}

                                value={dayjs(programData.startTime, format)} className='custom-time-picker' format={format} />
                        </div>


                    </div>
                </Col>
                <Col lg={3} >
                    <div className="">
                        <Label className="form-label">
                            Ders Süresi (dk)
                        </Label>
                        <div>
                            <Input type='number' value={programData.duration} placeholder='Ders Süresi' onChange={(e) => {
                                setProgramData({
                                    ...programData,
                                    duration: parseInt(e.target.value)
                                })
                            }} />
                        </div>
                    </div>
                </Col>
                <Col lg={3} >
                    <div className="">
                        <Label className="form-label">

                        </Label>
                        <div style={{ transform: "translateY(20%)" }}>
                            <Button className='px-4' onClick={() => {
                                addProgram()
                            }}  >
                                Ekle
                            </Button>
                        </div>


                    </div>
                </Col>
                <Col lg={12} style={{ marginTop: "30px", marginBottom: "30px" }} >
                    <Row>
                        {
                            programList.map((el, index) => {
                                return (
                                    <Col key={`${index}`} lg={4}>
                                        <div className='program_card mb-3'>
                                            <div className='program_card_header' >

                                                <p >
                                                    <strong style={{ marginRight: "5px" }}>
                                                        Gün :
                                                    </strong>
                                                    <span className='program_schedulee_text_color' >
                                                        {el.day}
                                                    </span>
                                                </p>

                                            </div>
                                            <div className='program_card_footer'>
                                                <p>
                                                    <strong>Başlangıç :</strong> <span className='program_schedulee_text_color' >{el.startTime}</span>
                                                </p>
                                                <p>
                                                    <strong>Süre (dk) :</strong> <span className='program_schedulee_text_color' >{el.duration}</span>
                                                </p>
                                                {/*   <p className='program_card_footer_detail_btn' onClick={() => {
                                                    console.log("ell =>", el)
                                                    console.log("programDae= ", programDateList)
                                                    setShowProgramDateList({
                                                        visible: true,
                                                        selectedItem: el.day
                                                    })
                                                }} >
                                                    İncele
                                                </p> */}

                                            </div>
                                            <CgClose color='white' className='program_card_close_icon' onClick={() => {

                                                deleteDay(el)
                                            }} />
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>


                <Col style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Button className='btn btn-warning me-4' onClick={() => {
                        setCurrent(0)
                    }}  >
                        Geri
                    </Button>
                    <Button className='btn btn-primary' onClick={() => {
                        if (programList.length == 0) {
                            toast.error("en az bir adet seçim yapılmalı", {
                                autoClose: 1000
                            })
                        }
                        else {
                            createCourse()
                        }

                    }}  >
                        Kaydet
                    </Button>
                </Col>
            </Row>

            <Modal isOpen={showProgramDateList.visible} >
                <ModalHeader>
                    Kursun Yapılacağı Tarihler
                </ModalHeader>
                <ModalBody>
                    <div className='course_date_list'>

                        {
                            programDateList.filter(item => item.day === showProgramDateList.selectedItem).map(el => {
                     
                                return el.dates.map(item => {
                                    return (
                                        <div className='date_list_card' >
                                            <div className='date_list_card_section_1' >
                                                <span>
                                                    {item.toLocaleDateString()}
                                                </span>
                                                <span>
                                                    {el.day}
                                                </span>
                                            </div>

                                        </div>
                                    )
                                })
                            })
                        }
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => {
                        setShowProgramDateList({
                            visible: false,
                            selectedItem: ""
                        })
                    }} >
                        İptal
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    )
}

export default AddCourseProgram