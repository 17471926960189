import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CanlıYayınListe from '../../Components/CanlıYayınListe/CanlıYayınListe'

const CanliYayinPage = () => {
  return (
    <div className='page-content' >
      <TablePageContainer header={"Canlı Yayın Listesi"} visible={false} >
        <CanlıYayınListe />
      </TablePageContainer>
    </div>
  )
}

export default CanliYayinPage