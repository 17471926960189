import { Button, Input, Modal, Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { FaPlus, FaTrash } from 'react-icons/fa'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'
import "./index.scss"
import { toast } from 'react-toastify'
import RecommendedCourseModal from './RecommendedCourseModal'
const RecommendedCourseDashboard = () => {
    const [modalInfo, setModalInfo] = useState({
        visible: false
    })
    const [loading, setLoading] = useState(false)
    const [allCourse, setAllCourse] = useState([])
    const [recommededCourse, setRecommendedCourse] = useState([])
    const [selectedCourse, setSelectedCourse] = useState()
    const [searchText, setSearchText] = useState("")
    const getAllCourse = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/courses/getall?type=Online&status=0", {
                page: 0,
                pageSize: 1
            })
            const mainResponse = await axiosInstance.post("/courses/getAll?type=Online&status=0", {
                page: 0,
                pageSize: response.data.count
            })
            const offlineResponse = await axiosInstance.post("/courses/getAll?type=Offine&status=0", {
                page: 0,
                pageSize: 1
            })
            const offlineMain = await axiosInstance.post("/courses/getAll?type=Offine&status=0", {
                page: 0,
                pageSize: offlineResponse.data.count
            })
            const values = [...mainResponse.data.items, ...offlineMain.data.items]
            setAllCourse(values)
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const addRecommendedCourse = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/recommendedCourses/Add", {
                courseIds: [
                    selectedCourse
                ]
            })
            toast.success("Önerilenler listesine eklendi", {
                autoClose: 2000,
                position: "top-center"
            })
            setModalInfo({
                visible: false
            })
            getAllCourse()
            getAllRecommendedCorse()
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getAllRecommendedCorse = async () => {
        try {
            setLoading(true)
            const response = await axiosInstance.post("/recommendedCourses/getall", {
                page: 0,
                pageSize: 10
            })
            setRecommendedCourse(response.data.items)

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const courseData = useMemo(() => {
        return allCourse.filter(item => {
            let control = recommededCourse.findIndex(el => el.courseId == item.id)
            if (control == -1) {
                return true
            }
            else {
                return false
            }
        })
    }, [searchText, allCourse])

    useEffect(() => {
        getAllCourse()
        getAllRecommendedCorse()
    }, [])

    return (
        <StyledContainer>
            <StyledHeader>
                <Button type='primary' style={{ display: "flex", alignItems: "center", columnGap: "5px" }} onClick={() => {
                    setModalInfo({
                        visible: true
                    })

                }} >
                    <FaPlus />  Ekle
                </Button>
            </StyledHeader>
            <StyledContent>
                <Table
                    dataSource={recommededCourse}
                    scroll={{
                        x: 700
                    }}
                    columns={[
                        {
                            title: "id",
                            render: (row, record) => {

                                return (
                                    <p>
                                        {record?.id}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "Kurs ismi",
                            render: (row, record) => {
                                return (
                                    <p>
                                        {record?.course?.name}
                                    </p>
                                )
                            }
                        },
                        {
                            title: "İşlem",
                            render: (row, record) => {
                                return (
                                    <Button danger type='primary' onClick={() => {
                                        Modal.confirm({
                                            title: "Bu kursu önerilenler listesinden kaldırmak istediğinize emin misiniz?",
                                            cancelText: "İptal",
                                            okText: "Eminim",
                                            onOk: async () => {
                                                try {
                                                    setLoading(true)
                                                    await axiosInstance.delete("/RecommendedCourses/delete", {
                                                        data: {
                                                            ids: [record.id]
                                                        }
                                                    })
                                                    toast.success(`${record?.course?.name} isimli kurs listeden kaldırıldı`, {
                                                        autoClose: 2000,
                                                        position: "top-center"
                                                    })
                                                    getAllRecommendedCorse()
                                                }
                                                catch (err) {

                                                }
                                                finally {
                                                    setLoading(false)
                                                }
                                            }
                                        })
                                    }} >
                                        <FaTrash />
                                    </Button>
                                )
                            }
                        }
                    ]}
                />
            </StyledContent>
            <RecommendedCourseModal selectedCourse={selectedCourse} courseData={courseData} modalInfo={modalInfo} setModalInfo={setModalInfo} setSelectedCourse={setSelectedCourse} addRecommendedCourse={addRecommendedCourse} loading={loading} />
        </StyledContainer>
    )
}

export default RecommendedCourseDashboard


const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

`
const StyledHeader = styled.div`
    display: flex;
    border-bottom:1px dashed black;
    padding-bottom: 10px;
    justify-content: flex-start;
`

const StyledContent = styled.div`
    margin-top: 10px;
`

