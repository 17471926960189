import { Alert, Button, DatePicker, Input, Modal, Typography } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import dayjs from "dayjs"
import { formatInTimeZone } from 'date-fns-tz'
import trLocale from "date-fns/locale/tr"
import LoadingElement from '../../Components/Loader/LoadinElement'
import { FaArchive, FaFileArchive, FaTrash } from 'react-icons/fa'
import { FaFile } from 'react-icons/fa6'
import { UserContext } from '../../context/user'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../api/axiosInstance'

const StudentCourseWorkModal = ({ modalInfo, setModalInfo, refreshMethod }) => {

    const [courseWorkData, setCourseWorkData] = useState()
    const fileRef = useRef()
    const [context, dispatch] = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    console.log("contex", context)
    console.log("modalInfx", modalInfo)
    const [selectedFile, setSelectedFile] = useState()
    useEffect(() => {
        if (modalInfo?.data) {
            setCourseWorkData(modalInfo?.data)
        }
    }, [modalInfo])




    const convertTime = (time) => {
        /* const parsedDate = parseISO(time); */
        /* const formattedDate = format(time, "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }); */
        const formattedDate = formatInTimeZone(time, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
        return formattedDate
    }

    const saveWork = () => {
        if (!selectedFile) {
            toast.error("Lütfen Dosya Seçiniz", {
                position: "top-center",
                autoClose: 2500
            })
        }
        else {
            Modal.confirm({
                title: "Yüklemek istediğinize emin misiniz?",
                cancelText: "İptal",
                okText: "Eminim",
                onOk: async () => {
                    try {
                        console.log("modalInfo", modalInfo)
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("formFile", selectedFile)
                        await axiosInstance.post(`/courseWorkUploads/Add?CourseWorkId=${modalInfo?.data?.id}`, formData)
                        toast.success("Ödev dosyası başarıyla yüklendi", {
                            position: "top-center",
                            autoClose: 2500
                        })
                        setModalInfo({
                            visible: false,
                            data: null
                        })
                        setSelectedFile()
                        setCourseWorkData()
                    }
                    catch (err) {

                    }
                    finally {
                        setLoading(false)
                    }
                }
            })

        }


    }

    const fileUserControl = useMemo(() => {
        const fileControl = courseWorkData?.courseWorkUploads?.find(item => item?.userId == context?.userId)
        return fileControl
    }, [courseWorkData, context, modalInfo])


    const deleteCurrentFile = (id) => {

        Modal.confirm({
            title: "Dosyayı silmek istediğinize emin misiniz?",
            okText: "Eminim",
            cancelText: "İptal",

            onOk: () => {
                try {
                    setLoading(true)
                    axiosInstance.delete(`/courseWorkUploads/Delete/${id}`)
                    toast.success("Mevcut ödev dosyası silindi", {
                        position: "top-center",
                        autoClose: 2500
                    })
                    setSelectedFile()
                    refreshMethod()
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })

    }


    const myCourseFile = useMemo(() => {
        if (fileUserControl) {
            return (
                <StyledFileElement style={{
                    position: "relative"
                }} onClick={() => {
                    window.open(fileUserControl?.url, "_blank")
                }} >
                    <FaFileArchive size={40} color='gray' />
                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                        {fileUserControl?.url?.length > 40 ? `${fileUserControl?.url?.substring(0, 40)}...` : fileUserControl?.url}
                    </h6>

                    <span onClick={(e) => {
                        e.stopPropagation()
                        if (!loading) {
                            deleteCurrentFile(fileUserControl?.id)
                        }

                    }} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-10px",
                        right: "-5px",
                        backgroundColor: "red"
                    }} >
                        <FaTrash color='white' />
                    </span>
                </StyledFileElement>
            )
        }
        else if (selectedFile) {
            return (
                <StyledFileElement onClick={() => {
                    const fileURL = URL.createObjectURL(selectedFile);
                    window.open(fileURL, '_blank');
                }}  >
                    <FaFileArchive size={40} color='gray' />
                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                        {selectedFile?.name.length > 40 ? `${selectedFile?.name.substring(0, 40)}...` : selectedFile?.name}
                    </h6>
                </StyledFileElement>
            )
        }
        else {
            return (
                <StyledFileElement   >
                    <FaFileArchive size={40} color='gray' />
                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                        Dosya Seçmediniz
                    </h6>
                </StyledFileElement>
            )

        }
    }, [selectedFile, courseWorkData])


    return (
        <>
            <Modal open={modalInfo?.visible} closable={false} onCancel={() => {
                setModalInfo({
                    visible: false,
                    data: null
                })
            }}
                title={
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }} >
                        <p style={{
                            fontWeight: "bold"
                        }}>
                            Ödevim
                        </p>

                        <Button danger type='primary' onClick={() => {
                            setModalInfo({
                                visible: false,
                                data: null
                            })
                            setSelectedFile()
                            setCourseWorkData()
                        }} >
                            İptal
                        </Button>
                    </div>
                }
                footer={() => {
                    return (
                        <StyledFooter>
                            <Button type='primary' onClick={() => {
                                if (fileUserControl) {
                                    toast.info("Dosya yüklemeden önce mevcut dosyayı siliniz", {
                                        position: "top-center",
                                        autoClose: 2500
                                    })
                                }
                                else {
                                    fileRef.current.click()
                                }

                            }} style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "10px"
                            }} >
                                <FaFile /> Dosya Yükle
                            </Button>
                            <Button loading={loading} type='primary' disabled={fileUserControl} onClick={() => {
                                saveWork()
                            }} >
                                Kaydet
                            </Button>

                        </StyledFooter>
                    )
                }}
            >
                <StyledContentContainer>
                    <StyledInputItem>
                        <p style={{
                            fontWeight: "bold"
                        }} >
                            İsim
                        </p>
                        <StyledContentElement  >
                            {courseWorkData?.name}
                        </StyledContentElement>
                    </StyledInputItem>
                    <StyledInputItem>
                        <p style={{
                            fontWeight: "bold"
                        }}>
                            Başlangıç Zamanı
                        </p>
                        <StyledContentElement>
                            {courseWorkData && convertTime(courseWorkData?.startTime)}
                        </StyledContentElement>

                    </StyledInputItem>
                    <StyledInputItem>
                        <p style={{
                            fontWeight: "bold"
                        }}>
                            Bitiş Zamanı
                        </p>
                        <StyledContentElement>
                            {courseWorkData && convertTime(courseWorkData?.endTime)}
                        </StyledContentElement>
                    </StyledInputItem>
                    <StyledInputItem>
                        <p style={{
                            fontWeight: "bold"
                        }} >
                            Ödev Dosyası
                        </p>
                        {
                            courseWorkData?.path != "Dosya Seçilmedi." ? (
                                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} onClick={() => {
                                    window.open(courseWorkData?.path, '_blank');
                                }} >
                                    <FaFileArchive size={40} color='gray' />
                                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                        {courseWorkData?.path?.length > 40 ? `${courseWorkData?.path?.substring(0, 40)}...` : courseWorkData?.path}
                                    </h6>
                                </div>
                            ) : (
                                <Alert type='info' showIcon message="Henüz Eğitmen Dosya Yüklemedi" />
                            )
                        }
                    </StyledInputItem>
                    <StyledInputItem>
                        <p style={{
                            fontWeight: "bold"
                        }} >
                            Yüklediğim Ödev
                        </p>
                        {
                            myCourseFile
                        }
                    </StyledInputItem>
                </StyledContentContainer>
            </Modal>
            <input style={{
                display: "none"
            }} type="file" ref={fileRef} onChange={(e) => {
                setSelectedFile(e.target.files[0])
            }} />
        </>
    )
}

export default StudentCourseWorkModal


const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
`

const StyledInputItem = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

`

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 20px;
`

const StyledContentElement = styled.p`
    background-color: #E4E0E1;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;

`

const StyledFileElement = styled.div`
    @keyframes border-animation {
    0% {
        border-color: red;
    }
    50% {
        border-color: blue;
    }
    100%    {
        border-color: red;
        }
    }
    display: flex;
    align-items: center;
   /*  border: 1px dashed black; */
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(90deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%), linear-gradient(0deg, black 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
    padding: 10px;
    animation: border-dance 20s infinite linear;
   
  @keyframes border-dance {
    0% {
        background-position: 0 0, 100% 100%, 0 100%, 100% 0;
    }
    100% {
        background-position: 100% 0, 0 100%, 0 0, 100% 100%;
    }

    }

`
