import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../../../api/axiosInstance';
import { Link, useNavigate } from 'react-router-dom';
import { GoArrowUpRight } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import CardLesson from '../../cardLesson/CardLesson';
import imgCard11 from "../../../../assets/images/onlineimage1.png"
import ProfileAvatar from "../../../../assets/images/profile.png"
import imgPlay from "../../../../assets/images/imgPlay.png"

const MostPopularOnlineCourse = () => {
    const { t } = useTranslation()
    const [onlineData, setOnlineData] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/branches/GetAll?type=Online', {
                    page: 0,
                    pageSize: 50
                });

                let result = response.data.items?.splice(0, 4)

                setOnlineData(result)
            } catch (error) {

            }
        };

        fetchData();
    }, []);

    const getImg = (element) => {
        if (element?.url == "0" || element?.url == null || element?.url == undefined) {
            return imgCard11
        }
        return element?.url
    }

    return (
        <div id='most_popular_course_container' /* className='mp_course_online_bg' */  >
            <div id='most_popular_course_wrapper'   >
                <div id='most_popular_course_wrapper_header' >
                    <div className='most_popular_course_wrapper_header_left_conntent' >
                        <p id='text00'>
                            {t("slogan5")}
                            <span id='text01' >
                                {t("slogan99")}
                            </span>
                        </p>
                        <p id='text02'> {t("sloganOnline")} </p>
                    </div>
                    <Link id='text03' to={"/egitimler/canliegitim"}>  {t("slogan8")}<GoArrowUpRight id='Arrow00' /></Link>
                </div>

                <div className='most_popular_offline_course_container'  >
                    {
                        onlineData.map((item, index) => {
                            return (
                                <div style={{ width: "100%" }}>
                                    <CardLesson
                                        infoText={item.name.toUpperCase()}
                                        name={item.courseAdministrator ? item.courseAdministrator.toUpperCase() : "Eğitmen Atanmamış"}

                                        imageSrc={ProfileAvatar}
                                        imgPlay={imgPlay}
                                        imgcard={getImg(item)}
                                        btnColor='#7f56d9'
                                        btnText={t("btnText")}
                                        onClick={() => {
                                            const courseLabel = encodeURIComponent(item?.label)
                                            navigate(`/kurs/${courseLabel}`)
                                        }}
                                        hiddenBtn={true}
                                    ></CardLesson>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default MostPopularOnlineCourse


const StyledContainer = styled.div`
    width: 100%;
    padding: 0 80px;
    padding-top: 40px;
    padding-bottom: 10px;


    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin-top: 0;
    }


    @media screen and (max-width:500px) {
        padding: 20px 20px;
        margin-top: 0;
    }


    #most_popular_course_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 70px;

        #most_popular_course_wrapper_header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;


            @media screen and (max-width:500px) {
                flex-direction: column;
            }




            .most_popular_course_wrapper_header_left_conntent {
                display: flex;
                flex-direction: column;

                #text00 {
                    font-size: 32px;
                    color: #7f56d9;

                    @media screen and (max-width:860px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 20px;
                    }


                    #text01 {
                        color: black;
                        margin-left: 10px;

                    }
                }

                #text02 {
                    font-size: 18px;
                    color: #8585AF;

                    @media screen and (max-width:860px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }

            }


            #text03 {
                font-size: 16px;
                font-weight: bold;
                text-decoration: underline;
                color: #8585AF;

                #Arrow00 {
                    font-size: 26px;
                    color: #7F56D9;
                }

                @media screen and (max-width:500px) {
                    display: none;
                }
            }

        }



        .most_popular_offline_course_container {
            margin-top: 70px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 20px;

            @media screen and (max-width:1100px) {
                grid-template-columns: 1fr 1fr !important;
            }


            @media screen and (max-width:860px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width:500px) {
                grid-template-columns: 1fr;
            }
        }

    }

`