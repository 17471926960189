import React, { useContext, useMemo, useRef, useState } from 'react';
import { Button as AntButton, Pagination, Modal as AntModal, Segmented, Select, Alert } from "antd"
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap"
import { formatDate } from "date-fns"
import trLocale from "date-fns/locale/tr"
import styled from 'styled-components';
import { FaFileArchive } from 'react-icons/fa';
import { MdDownloadForOffline } from 'react-icons/md';
import { axiosInstance } from '../../../../api/axiosInstance';
import { toast } from 'react-toastify';
import { addOnlineCourseContentApi } from '../../../../api/CourseContent';
import { UserContext } from '../../../../context/user';



const EditOnlineContentModal = ({ modalData, setModalData, apiRequest, addFile = false }) => {
    const [selectedContentIndex, setSelectedContentIndex] = useState(0)
    const [tabId, setTabId] = useState("İçerik")
    const [selectedFile, setSelectedFile] = useState()
    const [loading, setLoading] = useState(false)
    const [context, dispatch] = useContext(UserContext)


    const [addContentModal, setAddContentModal] = useState({
        courseCalendarId: null,
        description: ""
    })

    const userRole = useMemo(() => {
        return context?.role == "Öğrenci"
    }, [context])

    const fileInputRef = useRef(null)
    const courseDate = useMemo(() => {
        if (modalData?.data) {
            return formatDate(new Date(modalData?.data?.startTime), "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale })
        }
    }, [modalData])

    const contentLength = useMemo(() => {
        return modalData?.data?.onlineCourseFiles?.length
    }, [modalData])


    const selectedFileView = useMemo(() => {
        return modalData?.data?.onlineCourseFiles[selectedContentIndex]
    }, [modalData, selectedContentIndex])


    const handleFileChange = (event) => {
        const file = event.target.files[0]
       /*  const acceptedTypes = [
            'application/msword',  // .doc
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  // .docx
            'application/vnd.ms-excel',  // .xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // .xlsx
            'application/vnd.ms-powerpoint',  // .ppt
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',  // .pptx
            'application/pdf',  // .pdf
            'image/jpeg',  // .jpg, .jpeg
            'image/png',  // .png
            'image/gif',  // .gif
            'text/csv'  // .csv
        ]; */
        setSelectedFile(file)
       /*  if (file && !acceptedTypes.includes(file.type)) {
            toast.error("Bu dosya formatı uygun değil", {
                autoClose: 1500
            })
            fileInputRef.current.value = '' // Seçilen dosyayı temizlemek için
        } else {
            setSelectedFile(file)

        } */
    }

    const saveFile = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("formFile", selectedFile)
            const response = await addOnlineCourseContentApi(modalData?.data?.id, addContentModal.description, formData)
            toast.success("Döküman kaydedildi", {
                autoClose: 2000
            })
            setAddContentModal({
                courseCalendarId: null,
                description: ""
            })
            setModalData({
                visible: false,
                data: null
            })
            setSelectedFile()
            setTabId("İçerik")
            await apiRequest()
            /*  getOnlineContentList() */
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const disableAddButton = useMemo(() => {
        if (!selectedFile) {
            return true
        }
        if (addContentModal.description == "") {
            return true
        }

    }, [addContentModal, selectedFile])


    return (
        <Modal isOpen={modalData?.visible} centered  >
            <StyledModalHeader>
                <h6 style={{ fontWeight: "bold" }} >
                    {courseDate}
                </h6>
                <AntButton type='primary' onClick={() => {
                    setModalData({
                        visible: false,
                        data: null
                    })
                    setSelectedContentIndex(0)
                    setTabId("İçerik")
                }} >
                    Kapat
                </AntButton>
            </StyledModalHeader>
            <ModalBody>
                <Segmented
                    options={addFile ? ['İçerik', "Ekle"] : ["İçerik"]}
                    onChange={(value) => {
                        setTabId(value)
                    }}
                    block
                />
                {
                    tabId == "İçerik" && (
                        selectedFileView ? <>
                            <div onClick={() => {
                                window.open(selectedFileView?.url, "_blank")

                            }} style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5, position: "relative", margin: "20px 0" }} >
                                <div style={{ display: "flex", alignItems: "center" }} >
                                    <FaFileArchive size={40} color='gray' />
                                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                        {selectedFileView?.description.length > 40 ? `${selectedFileView?.description?.substring(0, 40)}...` : selectedFileView?.description}
                                    </h6>
                                </div>
                                {/* <MdDownloadForOffline /> */}

                                <div style={{ position: "absolute", top: "-10px", right: "5%", borderRadius: "5px", backgroundColor: "green", color: "white", padding: "1px 20px" }} >
                                    {selectedFileView?.type}
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <Pagination simple pageSize={1} total={contentLength} onChange={(e) => {
                                    setSelectedContentIndex(e - 1)
                                }} />
                                {
                                    !userRole && (
                                        <AntButton danger type='primary' onClick={() => {

                                            AntModal.confirm({
                                                title: "Silmek istediğinize emin misiniz ?",
                                                cancelText: "İptal",
                                                okText: "Eminim",
                                                onOk: async () => {
                                                    try {
                                                        await axiosInstance.delete(`OnlineCourseFiles/Delete/${selectedFileView.id}`)
                                                        await apiRequest()
                                                        setModalData({
                                                            visible: false,
                                                            data: null
                                                        })
                                                        toast.success("İçerik başarıyla silindi", {
                                                            autoClose: 2000
                                                        })
                                                    }
                                                    catch (err) {

                                                    }

                                                }
                                            })


                                        }} >
                                            Bu içeriği sil
                                        </AntButton>
                                    )
                                }

                            </div>

                        </> : (
                            <div style={{ marginTop: "15px" }}>
                                <Alert message="Henüz içerik eklenmedi" type="warning" />
                            </div>
                        )

                    )
                }
                {
                    tabId == "Ekle" && (
                        <>
                            <input ref={fileInputRef} type="file" onChange={(e) => {
                                handleFileChange(e)
                            }} style={{ display: "none" }} />
                            <div style={{ paddingTop: "20px" }}>

                                <Label style={{ marginTop: "10px" }} >
                                    Açıklama
                                </Label>
                                <Input value={addContentModal.description} type='textarea' style={{ resize: "none" }} onChange={(e) => {
                                    setAddContentModal({
                                        ...addContentModal,
                                        description: e.target.value
                                    })
                                }} />
                                <StyledSelectedFileContainer>
                                    <Label>
                                        Seçilen Dosya
                                    </Label>
                                    {
                                        selectedFile ? (
                                            <div style={{ display: "flex", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} >
                                                <FaFileArchive size={40} color='gray' />
                                                <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                                    {selectedFile?.name.length > 40 ? `${selectedFile?.name.substring(0, 40)}...` : selectedFile?.name}
                                                </h6>
                                            </div>
                                        ) : (
                                            <h5 style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center", width: "100%" }} >
                                                Henüz dosya seçilmedi
                                            </h5>
                                        )
                                    }
                                </StyledSelectedFileContainer>
                                <StyledFooter>
                                    <AntButton style={{
                                        backgroundColor: "#088F7D",
                                        color: "white"
                                    }} onClick={() => {
                                        fileInputRef.current.click()
                                    }} >
                                        Dosya Seç
                                    </AntButton>
                                    <AntButton loading={loading} disabled={disableAddButton} type='primary' onClick={() => {
                                        saveFile()
                                    }} >
                                        Kaydet
                                    </AntButton>
                                </StyledFooter>
                            </div>

                        </>

                    )
                }


            </ModalBody>

        </Modal>
    )
}

const StyledModalHeader = styled(ModalHeader)`
    h5 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
`


const StyledFooter = styled.footer`
display: flex;
width: 100%;
justify-content: space-between;
`

const StyledSelectedFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

`
export default EditOnlineContentModal