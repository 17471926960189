import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import BreadCrumb from "./BreadCrumb";
import { InfoContext } from "../../context/user";
import styled from "styled-components";

const TablePageContainer = ({ title, children, header, visible = true, headerVisible = true }) => {
    const context = useContext(InfoContext)
   

    const nameData = useMemo(() => {
        return title ?? header
    }, [title, header])

    const StyledContainer = styled.div`
        padding: 0 10px;

        

    `
    const MainCol = styled(Col)`
    padding-left:0;
    padding-right: 0;
      @media screen and (max-width:500px) {
            padding-left:0;
            padding-right: 0;
      }
    `

    const StyledCardHeader = styled(CardHeader)`
           
        @media screen and (max-width:500px)  {
            display: none;
        }
    `
    const StyledCardBody = styled(CardBody)`
        @media screen and (max-width:500px)  {
            padding: 15px 10px;
        }
    `

    return (
        <React.Fragment>
            <StyledContainer fluid   >
                {
                    headerVisible && (
                        <BreadCrumb title={header} pageTitle="Kuzem" />
                    )
                }

                <Row>
                    <MainCol lg={12}>
                        <Card style={{ marginRight: "0px" }} >
                            {
                                visible && (
                                    <StyledCardHeader>
                                        <h4 className="card-title mb-0 flex-grow-1">{nameData} </h4>
                                    </StyledCardHeader>
                                )
                            }

                            <StyledCardBody>
                                <div id="table-gridjs">{children}</div>
                            </StyledCardBody>
                        </Card>
                    </MainCol>
                </Row>
            </StyledContainer>

        </React.Fragment>
    );
};

TablePageContainer.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default TablePageContainer;
