// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher_survey_dashboard_container {
  display: flex;
  flex-direction: column; }
  .teacher_survey_dashboard_container .teacher_survey_dashboard_header {
    display: grid;
    column-gap: 30px;
    grid-template-columns: 1fr 1fr; }
    .teacher_survey_dashboard_container .teacher_survey_dashboard_header .teacher_survey_dashboard_header_item {
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      padding: 20px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      font-weight: 600;
      margin-bottom: 20px;
      cursor: pointer; }
      .teacher_survey_dashboard_container .teacher_survey_dashboard_header .teacher_survey_dashboard_header_item .teacher_survey_dashboard_header_surveys_icon {
        font-size: 32px; }
      .teacher_survey_dashboard_container .teacher_survey_dashboard_header .teacher_survey_dashboard_header_item .teacher_survey_dashboard_item_title {
        font-size: 16px;
        font-weight: 500;
        margin-left: 15px; }
      .teacher_survey_dashboard_container .teacher_survey_dashboard_header .teacher_survey_dashboard_header_item .teacher_survey_dashboard_header_create_survey_icon {
        font-size: 32px;
        color: #0AB39C; }
    @media screen and (max-width: 500px) {
      .teacher_survey_dashboard_container .teacher_survey_dashboard_header {
        display: grid;
        grid-template-columns: 1fr; } }

/* @media screen and (max-width:560px) {
    .teacher_survey_dashboard_header_item{
        width: 100% !important;
    }
} */
`, "",{"version":3,"sources":["webpack://./src/Components/Teacher/TeacherSurvey/teacherSurveyDashboard.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB,EAAA;EAF1B;IAKQ,aAAa;IACb,gBAAgB;IAChB,8BAA8B,EAAA;IAPtC;MAUY,0CAA0C;MAC1C,aAAa;MACb,mBAAmB;MACnB,aAAa;MACb,mBAAmB;MACnB,gBAAgB;MAChB,mBAAmB;MACnB,eAAe,EAAA;MAjB3B;QAoBgB,eAAe,EAAA;MApB/B;QAwBgB,eAAe;QACf,gBAAgB;QAChB,iBAAiB,EAAA;MA1BjC;QA+BgB,eAAe;QACf,cAAc,EAAA;IAMtB;MAtCR;QAuCY,aAAa;QACb,0BAA0B,EAAA,EAIjC;;AAOL;;;;GAjBG","sourcesContent":[".teacher_survey_dashboard_container {\n    display: flex;\n    flex-direction: column;\n\n    .teacher_survey_dashboard_header {\n        display: grid;\n        column-gap: 30px;\n        grid-template-columns: 1fr 1fr;\n\n        .teacher_survey_dashboard_header_item {\n            box-shadow: 1px 1px 1px rgba($color: black, $alpha: .2);\n            padding: 20px;\n            border-radius: 20px;\n            display: flex;\n            align-items: center;\n            font-weight: 600;\n            margin-bottom: 20px;\n            cursor: pointer;\n\n            .teacher_survey_dashboard_header_surveys_icon {\n                font-size: 32px;\n            }\n\n            .teacher_survey_dashboard_item_title {\n                font-size: 16px;\n                font-weight: 500;\n                margin-left: 15px;\n            }\n\n\n            .teacher_survey_dashboard_header_create_survey_icon {\n                font-size: 32px;\n                color: #0AB39C;\n\n            }\n        }\n\n\n        @media screen and (max-width:500px) {\n            display: grid;\n            grid-template-columns: 1fr;\n            \n        }\n\n    }\n\n\n\n}\n\n\n/* @media screen and (max-width:560px) {\n    .teacher_survey_dashboard_header_item{\n        width: 100% !important;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
