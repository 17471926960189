import { axiosInstance } from "./axiosInstance";



const getReviewsApi = (id = 45) => axiosInstance.get(`assessments/GetAssessmentResultByCourseId/${id}`)

const userDashboardRequest = () => axiosInstance.get("/Users/GetDashboard")

export {
    getReviewsApi,
    userDashboardRequest
}