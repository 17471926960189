import React, { useEffect, useState } from 'react'
import MainNavbar from '../../components/navbar/MainNavbar'
import MainFooter from '../../components/mainFooter/MainFooter'
import FontHead from '../../components/fontHead/FontHead'
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance'
import { Card, Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'

const Egitmenler = () => {
    const [loading, setLoading] = useState(false)
    const [allTeacher, setAllTeacher] = useState([])
    const navigate = useNavigate()
    const getAllTeacher = async () => {
        try {
            setLoading(true)
            const response1 = await axiosInstance.post("/teacherInformations/getall", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/teacherInformations/getall", {
                page: 0,
                pageSize: response1.data.count
            })
            let arr = []
            for (let item = 0; item < 12; item++) {
                arr.push(response2.data.items)
            }

            setAllTeacher(arr.flatMap(x => x))
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getAllTeacher()
    }, [])

    return (
        <div>
            <MainNavbar />
            <FontHead page={`Eğitmenler`}></FontHead>
            {
                loading ? (
                    <LoadingContainer>
                        <Skeleton active={true} />
                    </LoadingContainer>

                ) : (
                    <StyledContainer>
                        {
                            allTeacher.map((item, index) => {
                                return (
                                    <Card
                                        onClick={() => {
                                            navigate(`/egitmen/${item.userId}`)
                                        }}
                                        hoverable

                                        cover={<StyledImg alt="example" src={item?.imageUrl} />}
                                    >
                                        <Card.Meta title={item?.userFirstAndLastName} description="Eğitmen" />
                                    </Card>
                                )
                            })
                        }


                    </StyledContainer>
                )
            }

            <MainFooter visibility={'hidden'} />
        </div >
    )
}

export default Egitmenler


const StyledContainer = styled.div`

    padding: 20px 120px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr ;
    row-gap: 50px;
    column-gap: 30px;


 

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr ;
    }
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }
    @media screen and (min-width:1600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    }


`

const StyledImg = styled.img`
    max-height: 330px;
`

const LoadingContainer = styled.div`
    padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }
`