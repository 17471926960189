import React, { useContext, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { IoMoveSharp } from "react-icons/io5";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SurveyContext } from '../TeacherSurveyDashboard';
import { toast } from 'react-toastify';
import { Modal as AntModal, message, Button as AntButton } from "antd"
const DragAndDropList = ({ items, onDragEnd }) => {

    const { setCreateQuestionModal, setQuestionItems, setAnswerList } = useContext(SurveyContext)
    const [messageApi, contextHolder] = message.useMessage();
    const [deleteItemModal, setDeleteItemModal] = useState({
        show: false,
        id: ""
    })
    return (
        <>
            {contextHolder}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0 }}  >
                            {items.map((item, index) => {
                                
                                return (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided) => (
                                            <li
                                                className='sortable_question_item'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <div className='sortable_question_item_left_wrapper' >
                                                    <span className='sortable_question_item_index_element' >
                                                        {index + 1}
                                                    </span>
                                                    {item.questionContent?.length > 40 ? `${item.questionContent?.substring(0, 40)}...` : item.questionContent}
                                                </div>
                                                <div className='edit_btn_sortable_container'>
                                                    <AntButton type='primary' onClick={() => {
                                                    
                                                        setAnswerList(item.answers)
                                                        setCreateQuestionModal({
                                                            editMode: true,
                                                            questionContent: item.questionContent,
                                                            questionId: item.id,
                                                            visible: true
                                                        })
                                                    }} >
                                                        <FiEdit className='sortable_question_item_edit_icon' />
                                                    </AntButton>
                                                    <AntButton type='primary' danger onClick={() => {
                                                        AntModal.confirm({
                                                            title: "Silmek istediğinize emin misiniz ?",
                                                            okText: "Eminim",
                                                            cancelText: "İptal",
                                                            onOk: () => {
                                                                try {
                                                                    setQuestionItems((question) => {
                                                                        return question.filter(el => el.id !== item.id)
                                                                    })
                                                                    setDeleteItemModal({
                                                                        show: false,
                                                                        id: ""
                                                                    })
                                                                    messageApi.open({
                                                                        type: "success",
                                                                        duration: 2,
                                                                        content: "Soru başarıyla silindi",

                                                                    })
                                                                    /* toast.success("Soru Silindi", {
                                                                        autoClose: 1500,
                                                                        
                                                                    }) */
                                                                }
                                                                catch (err) {
                                                                  
                                                                }
                                                            }
                                                        })


                                                    }}


                                                    >
                                                        <FiTrash className='sortable_question_item_edit_icon' />
                                                    </AntButton>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <Modal isOpen={deleteItemModal.show} >
                <ModalHeader>
                    Onay
                </ModalHeader>
                <ModalBody>
                    <p>
                        Silmek İstediğinize emin misiniz ?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}  >
                        <Button color='danger' onClick={() => {
                            setQuestionItems((question) => {
                                return question.filter(el => el.id !== deleteItemModal.id)
                            })
                            setDeleteItemModal({
                                show: false,
                                id: ""
                            })
                            toast.success("Soru Silindi", {
                                autoClose: 1500
                            })
                        }} >
                            Onaylıyorum
                        </Button>
                        <Button onClick={() => {
                            setDeleteItemModal({
                                show: false,
                                id: ""
                            })
                        }} >
                            İptal
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DragAndDropList;
