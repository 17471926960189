import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";

import styled from "styled-components";
import "./index.scss"
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

const MainEditor = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    const getHtmlContent = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        let html = draftToHtml(rawContentState);

        console.log("html",html)
        return html
    };

    return (
        <div>
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                editorClassName="editor-class"  // burada CSS sınıfını ekliyoruz
                toolbarClassName="editor-toolbar"
                toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline'], // Burada bold ve italic seçeneklerini ekliyoruz
                    },
                }}
            />
            <h3>İçerik Önizleme</h3>
            <div
                className="content-preview"
                dangerouslySetInnerHTML={{ __html: getHtmlContent() }} // HTML çıktısını buraya aktarıyoruz
            />
            <em>
                asdasdasdxxxx
            </em>
        </div>
    )
}

export default MainEditor


