import { MdRefresh } from "react-icons/md";
import { Input, Select } from "antd";
import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;

`


export const StyledHeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    @media (max-width: 600px) {
      display: flex;
  }
    @media (max-width:450px) {
    display: flex;
    align-items:start;
    flex-direction: column;
      
  }
`


export const StyledSearchInput = styled(Input)`
    height: 35px !important;
    width: 150px;
   
`

export const RefreshIcon = styled(MdRefresh)`
    font-size: 17px;
`

export const StyledSelect = styled(Select)`
    width: 200px;
    height: 35px;
      @media (max-width: 450px) {
      max-width:150px;
      }
`