import { Button, Row, Col, Segmented, DatePicker, Input, Alert, Modal as AntModal, Select } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FaFileArchive, FaSave, FaTrash } from 'react-icons/fa'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../api/axiosInstance'

const TeacherViewCourseWork = ({ modalInfo, setModalInfo, refreshMethod }) => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [courseWorkData, setCourseWorkData] = useState(null)
    const [tabId, setTabId] = useState("Bilgiler")
    const fileRef = useRef()
    const [loading, setLoading] = useState(false)
    const [selectedStudentId, setSelectedStudentId] = useState()

    useEffect(() => {
        if (modalInfo?.courseWork) {
            console.log("modalıNFOX", modalInfo)
            setCourseWorkData(modalInfo?.courseWork)
        }

    }, [modalInfo])


    const validateForm = () => {
        const currentTime = new Date()
        if (courseWorkData?.name == "") {
            toast.info("İsim boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (courseWorkData?.startTime == "") {
            toast.info("Başlangıç zamanı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData?.startTime) < currentTime) {
            toast.info("Başlangıç zamanı şuanki zamandan geçmişte olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (courseWorkData?.endTime == "") {
            toast.info("Bitiş zamanı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData?.endTime) < currentTime) {
            toast.info("Bitiş zamanı şuanki zamandan geçmişte olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData?.startTime) > new Date(courseWorkData?.endTime)) {
            toast.info("Başlangıç zamanı bitiş zamanından büyük olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        return true
    }

    const updateCourseWork = async () => {
        if (validateForm()) {
            AntModal.confirm({
                title: "Güncellemek istediğinize emin misiniz ?",
                okText: "Eminim",
                cancelText: "İptal",
                onOk: async () => {
                    try {
                        setLoading(true)
                        console.log("courexx", courseWorkData)
                        const startTime = new Date(courseWorkData?.startTime).toUTCString()
                        const endTime = new Date(courseWorkData?.endTime).toUTCString()
                        const formData = new FormData()
                        formData.append("formFile", selectedFile)
                        await axiosInstance.put(`/courseWorks/Update?Id=${courseWorkData?.id}&courseCalendarId=${courseWorkData?.courseCalendarId}&name=${courseWorkData?.name}&startTime=${startTime}&endTime=${endTime}`, formData)

                        toast.success(`${courseWorkData?.courseCalendarId} CalendarId'li canlı yayın için oluşturulan ödev güncellendi`, {
                            position: "top-center",
                            autoClose: 3000
                        })
                        refreshMethod()
                        setModalInfo({
                            visible: false,
                            courseWork: null
                        })
                        setCourseWorkData(null)
                    }
                    catch (err) {

                    }
                    finally {
                        setLoading(false)
                    }
                }
            })

        }
    }

    const courseWorkUploadList = useMemo(() => {
        if (courseWorkData) {
            return courseWorkData?.courseWorkUploads?.map(item => {
                const { user, ...rest } = item
                return {
                    ...rest,
                    fullName: `${user?.firstName} ${user?.lastName}`
                }
            })
        }
        return []
    }, [courseWorkData])


    const fileControl = useMemo(() => {
        if (selectedFile != null) {
            return (
                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} onClick={() => {
                    const fileURL = URL.createObjectURL(selectedFile);
                    window.open(fileURL, '_blank');
                }} >
                    <FaFileArchive size={40} color='gray' />
                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                        {selectedFile?.name.length > 40 ? `${selectedFile?.name.substring(0, 40)}...` : selectedFile?.name}
                    </h6>
                </div>
            )
        }
        else if (courseWorkData?.path != "Dosya Seçilmedi.") {
            return (
                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} onClick={() => {
                    window.open(courseWorkData?.path, '_blank');
                }} >
                    <FaFileArchive size={40} color='gray' />
                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                        {courseWorkData?.path.length > 40 ? `${courseWorkData?.path.substring(0, 40)}...` : courseWorkData?.path}
                    </h6>
                </div>
            )
        }
        else {
            return (
                <h5 style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center", width: "100%" }} >
                    Henüz dosya seçilmedi
                </h5>
            )
        }
    }, [selectedFile, courseWorkData?.path])


    const selectedStudentWorkFile = useMemo(() => {
        return courseWorkUploadList?.find(item => item?.userId == selectedStudentId)
    }, [selectedStudentId, courseWorkUploadList])

    const deleteCourseWork = () => {
        console.log("modalInfo", modalInfo)
        AntModal.confirm({
            title: "Silmek istediğinize emin misiniz?",
            okText: "Eminim",
            cancelText: "İptal",
            onOk: async () => {
                try {
                    setLoading(true)
                    await axiosInstance.delete(`/courseWorks/delete/${modalInfo?.courseWork?.id}`)
                    toast.success(`${modalInfo?.courseWork?.courseCalendarId} CalendarId'li canlı eğitim için ödev silindi `, {
                        position: "top-center",
                        autoClose: 3000
                    })
                    setModalInfo({
                        visible: false,
                        courseWork: null
                    })
                    setSelectedFile(null)
                    setCourseWorkData(null)
                    refreshMethod()
                }
                catch (err) {

                }
                finally {
                    setLoading(false)

                }
            }
        })
    }


    return (
        <Modal isOpen={modalInfo.visible} centered >
            <StyledHeader>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                    <p>
                        Ödev Görüntüle
                    </p>
                    <Button type='primary' danger onClick={() => {
                        setModalInfo({
                            visible: false,
                            courseWork: null
                        })
                        setCourseWorkData(null)
                        setSelectedFile()
                        setSelectedStudentId()
                    }} >
                        Kapat
                    </Button>
                </div>
            </StyledHeader>
            <ModalBody>
                <Row style={{
                    width: "100%"
                }} >
                    <Col md={12} xs={12} >
                        <p onClick={() => {
                            setTabId("Bilgiler")
                        }} style={{
                            backgroundColor: tabId == "Bilgiler" ? "green" : "white",
                            textAlign: "center",
                            padding: "5px",
                            borderRadius: "10px",
                            color: tabId == "Bilgiler" ? "white" : "black",
                            cursor: "pointer"
                        }} >
                            Bilgiler
                        </p>
                    </Col>
                    <Col md={12} xs={12}  >
                        <p onClick={() => {
                            setTabId("Öğrenci")
                        }} style={{
                            backgroundColor: tabId == "Öğrenci" ? "green" : "white",
                            textAlign: "center",
                            padding: "5px",
                            borderRadius: "10px",
                            color: tabId == "Öğrenci" ? "white" : "black",
                            cursor: "pointer"
                        }} >
                            Öğrenciler
                        </p>
                    </Col>
                </Row>
                <StyledBodyContainer>
                    {
                        tabId == "Bilgiler" && (
                            <div>
                                {
                                    courseWorkData != null ? (
                                        <>
                                            <input type="file" ref={fileRef} onChange={(event) => {
                                                setSelectedFile(event.target.files[0])
                                            }} style={{
                                                display: "none"
                                            }} />
                                            <StyledContentContainer>
                                                <StyledInputItem>
                                                    <p>
                                                        İsim
                                                    </p>
                                                    <Input.TextArea rows={3} placeholder='İsim'
                                                        value={courseWorkData?.name}
                                                        style={{
                                                            resize: "none"
                                                        }}
                                                        onChange={(e) => {
                                                            setCourseWorkData({
                                                                ...courseWorkData,
                                                                name: e.target.value
                                                            })
                                                        }}

                                                    />
                                                </StyledInputItem>
                                                <StyledInputItem>
                                                    <p>
                                                        Başlangıç Zamanı
                                                    </p>
                                                    <DatePicker
                                                        value={courseWorkData?.startTime == "" ? "" : dayjs(courseWorkData?.startTime)}
                                                        placeholder='Başlangıç saati seçiniz'
                                                        allowClear={true}
                                                        showTime
                                                        onChange={(value, dateString) => {
                                                            setCourseWorkData({
                                                                ...courseWorkData,
                                                                startTime: dateString
                                                            })
                                                        }}
                                                        style={{
                                                            height: "35px"
                                                        }} />
                                                </StyledInputItem>
                                                <StyledInputItem>
                                                    <p>
                                                        Bitiş Zamanı
                                                    </p>
                                                    <DatePicker
                                                        value={courseWorkData?.endTime == "" ? "" : dayjs(courseWorkData?.endTime)}
                                                        placeholder='Bitiş saati seçiniz'
                                                        allowClear={true}
                                                        showTime
                                                        style={{
                                                            height: "35px"
                                                        }}
                                                        onChange={(value, dateString) => {
                                                            setCourseWorkData({
                                                                ...courseWorkData,
                                                                endTime: dateString
                                                            })
                                                        }}
                                                    />
                                                </StyledInputItem>
                                                <StyledInputItem>
                                                    <p>
                                                        Seçilen Dosya
                                                    </p>
                                                    {
                                                        fileControl
                                                    }
                                                </StyledInputItem>
                                            </StyledContentContainer>
                                        </>
                                    ) : (
                                        <Alert message="Ödev Bulunamadı" type="info" showIcon />
                                    )
                                }

                            </div>
                        )
                    }
                    {
                        tabId == "Öğrenci" && (
                            <div>
                                {
                                    courseWorkData != null ? (
                                        <StyledStudentWorksContainer>
                                            {courseWorkUploadList?.length != 0 ? (

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    rowGap: "25px"
                                                }} >
                                                    <StyledInputItem>
                                                        <p style={{
                                                            fontSize: "14px",
                                                            fontWeight: "bold"
                                                        }} >
                                                            Öğrenciler
                                                        </p>
                                                        <Select
                                                            style={{
                                                                height: "35px"
                                                            }}
                                                            placeholder="Öğrenci Seçiniz"
                                                            value={selectedStudentId}
                                                            options={courseWorkUploadList?.map(item => {
                                                                return {
                                                                    label: item?.fullName,
                                                                    value: item?.userId
                                                                }
                                                            })}
                                                            onChange={(e) => {
                                                                setSelectedStudentId(e)
                                                            }}
                                                        />
                                                    </StyledInputItem>
                                                    <StyledInputItem>
                                                        <p style={{
                                                            fontSize: "14px",
                                                            fontWeight: "bold"
                                                        }}>
                                                            Öğrencinin Yüklediği Dosya
                                                        </p>
                                                        {
                                                            selectedStudentId ? (
                                                                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} onClick={() => {
                                                                    window.open(selectedStudentWorkFile?.url, '_blank');
                                                                }} >
                                                                    <FaFileArchive size={40} color='gray' />
                                                                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                                                        {selectedStudentWorkFile?.url?.length > 40 ? `${selectedStudentWorkFile?.url?.substring(0, 40)}...` : selectedStudentWorkFile?.url}
                                                                    </h6>
                                                                </div>
                                                            ) : (
                                                                <div style={{ display: "flex", cursor: "pointer", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} >
                                                                    <FaFileArchive size={40} color='gray' />
                                                                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                                                        Öğrenci Seçilmedi
                                                                    </h6>
                                                                </div>
                                                            )
                                                        }
                                                    </StyledInputItem>
                                                </div>




                                            ) : (
                                                <Alert message="Henüz hiç ödev yüklenmedi" type="info" showIcon />
                                            )}
                                        </StyledStudentWorksContainer>
                                    ) : (
                                        <Alert message="Ödev Bulunamadı" type="info" showIcon />
                                    )
                                }
                            </div>
                        )
                    }
                </StyledBodyContainer>
            </ModalBody>
            <ModalFooter>
                {
                    tabId == "Bilgiler" && courseWorkData != null && (
                        <StyledFooterContainer>
                            <Button style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "5px"
                            }} type='primary' onClick={() => {
                                fileRef.current.click()
                            }} >
                                <FaFileArchive /> Dosya Seç
                            </Button>

                            <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                                <Button type='primary' danger style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "5px"
                                }} onClick={deleteCourseWork} >
                                    <FaTrash />  Ödevi Sil
                                </Button>
                                <Button type='primary' loading={loading} onClick={() => {
                                    updateCourseWork()
                                }}  >
                                    Güncelle {/* <FaSave /> */}
                                </Button>
                            </div>

                        </StyledFooterContainer>
                    )
                }
            </ModalFooter>
        </Modal>
    )
}

export default TeacherViewCourseWork


const StyledHeader = styled(ModalHeader)`
    h5 {
        width: 100%;
    }
`

const StyledBodyContainer = styled.div`
    margin-top: 20px;
`

const StyledInputItem = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

`

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
`
const StyledFooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledSaveButton = styled(Button)`
    background-color: green;
    transition: .5s all;
    &:hover {
        background-color: green !important ;
        opacity: .7;
    }
`

const StyledStudentWorksContainer = styled.div`
    display: flex;
    flex-direction: column;
`