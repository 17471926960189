import React, { useContext } from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import VerticalLayout from "../Layouts/index";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import { authProtectedRoutes, publicRoutes } from './allRouter';
import { AuthProtected } from "./AuthProtected"
import { UserContext } from '../context/user';
import { useSelector } from 'react-redux';
const Index = () => {
    const [context, Dispatch] = useContext(UserContext)
    const layoutState = useSelector((state) => state.Layout);

    console.log("layoutState",layoutState)
    return (
        <Routes>
            <Route>
                {publicRoutes.map((route, idx) => {
                    return (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    )
                })}
            </Route>

            <Route>
                {authProtectedRoutes.map((route, idx) => {
                    const template = route.template ?? true
                    return (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected role={route.role} >
                                    {
                                        template ? (
                                            <VerticalLayout>{route.component}</VerticalLayout>
                                        ) :
                                            (
                                                <div>
                                                    {route.component}
                                                </div>
                                            )
                                    }

                                </AuthProtected>}
                            key={idx}
                            exact={true}
                        />
                    )

                })}
            </Route>
        </Routes>
    )
}

export default Index