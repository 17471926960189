// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#btn-x {
  display: flex;
  flex-direction: row; }

#btnfirst {
  margin-left: 5px;
  width: 97.1px;
  height: 37.1px; }

@media screen and (max-width: 600px) {
  #btnseconde {
    margin-top: 5px;
    padding: 5px; }
  #btnfirst {
    margin-top: 5px;
    width: auto;
    height: auto;
    padding: 5px !important; }
  #btn-x {
    flex-direction: column; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Course/CourseDetailDashboard/CourseDetailDashboard.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB,EAAA;;AAGvB;EACI,gBAAgB;EAChB,aAAa;EACb,cAAc,EAAA;;AAGlB;EACI;IACI,eAAe;IACf,YAAY,EAAA;EAGhB;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,uBAAuB,EAAA;EAG3B;IACI,sBAAsB,EAAA,EACzB","sourcesContent":["#btn-x {\n    display: flex;\n    flex-direction: row;\n}\n\n#btnfirst {\n    margin-left: 5px;\n    width: 97.1px;\n    height: 37.1px;\n}\n\n@media screen and (max-width:600px) {\n    #btnseconde {\n        margin-top: 5px;\n        padding: 5px;\n    }\n\n    #btnfirst {\n        margin-top: 5px;\n        width: auto;\n        height: auto;\n        padding: 5px !important;\n    }\n\n    #btn-x {\n        flex-direction: column;\n    }\n\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
