import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from "react-player"
import { Await, useParams } from 'react-router-dom'
import { courseContentForCourseId, CourseViewsAddApi, CourseViewsForStudentApi } from '../../../../api/CourseContent'
import { VideoPageRightContentWrapper } from '../videoPageStyle'
import { Alert, Collapse, Skeleton } from 'antd'
import { SlArrowDown } from 'react-icons/sl'
import { FaPlay } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import { axiosInstance } from '../../../../api/axiosInstance'



const VideoPlayerPage = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [statusControl, setStatusControl] = useState()
    const videoRef = useRef()
    const [data, setData] = useState([])
    const [responseViewsList, setResponseViewsList] = useState()
    const [courseViewList, setCourseViewList] = useState([])
    const [selectedVideo, setSelectedVideo] = useState()



    const getCourseVideo = async () => {
        try {
            setLoading(true)
            const response = await courseContentForCourseId({
                page: 0,
                pageSize: 100
            }, id)

            let temp = response.data.items
            
            setStatusControl({
                message: response.data.message,
                status: response.data.status
            })
            setData(temp)
            if (response.data.status) {
                const responseViewsData = await CourseViewsForStudentApi(id)
                setResponseViewsList(responseViewsData.data)
            }

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }



    const courseInfo = useMemo(() => {
        let lastElement = data[data.length - 1]
        return lastElement?.course
    }, [data])


    const getAllCourseViews = async () => {
        try {
            const response = await axiosInstance.get(`/courseViews/getAllForStudent/${id}`)

            setCourseViewList(response.data.items)
        }
        catch (err) {

        }
    }



    const fetchPauseVideo = async (currentTİme) => {
        try {
            await axiosInstance.post("/courseviews/add", {
                courseFileId: selectedVideo.id,
                courseId: id,
                watchTime: parseInt(currentTİme)
            })
        }
        catch (err) {

        }
    }


    let played = 0


    useEffect(() => {
        const fetchAll = async () => {
            await getAllCourseViews()
            await getCourseVideo()
        }
        fetchAll()

    }, [])




    if (loading) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flex: 1, height: "100vh", padding: "0 60px" }}>
                <Skeleton active={true} />
            </div>

        )
    }

    return (
        <StyledPlayerContainer>
            <VideoPlayerContainer>
                {
                    selectedVideo ? (
                        <ReactPlayer ref={videoRef} controls={true}
                            config={{

                                file: {
                                    attributes: {
                                        controlsList: 'nodownload'
                                    }
                                }
                            }}

                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "black"
                            }}


                            width={"100%"}
                            height={"100%"}
                            url={selectedVideo.url}
                            onEnded={() => {

                                fetchPauseVideo(selectedVideo?.duration)
                            }}
                            onProgress={() => {
                                let current = videoRef.current.getCurrentTime()
                                played = current
                            }}

                        />
                    ) : (
                        <NoSelectedVideo >
                            Video Seçilmedi
                        </NoSelectedVideo>
                    )
                }

            </VideoPlayerContainer>
            <VideoRightContentContainer>
                <VideoPlayerRightSectionHeader>
                    <p className='player_title'>
                        {courseInfo?.name}
                    </p>
                </VideoPlayerRightSectionHeader>
                <InfoElement  >
                    İçerik
                </InfoElement>
                <AntCollapse style={{ marginBottom: "30px" }} expandIcon={({ isActive }) => null} >
                    {
                        data?.map((el, index) => {
                            return (
                                <Collapse.Panel header={
                                    <AccHeaderElement>
                                        <div className='left_c'>
                                            <div className='index_el' >
                                                {index + 1}
                                            </div>
                                            <p style={{
                                                textTransform: "capitalize"
                                            }} >
                                                {el.name}
                                            </p>
                                        </div>

                                        <SlArrowDown />
                                    </AccHeaderElement>
                                } >
                                    {
                                        el?.courseFiles.length !== 0 ? el?.courseFiles?.map(item => {
                                            let videoControl = courseViewList.find(element => element.courseFileId == item.id)
                                            /* startAt = videoControl?.watchTime ?? 0 */
                                            return (

                                                <VideoElementItem check={selectedVideo == item.url} onClick={() => {
                                                    setSelectedVideo(item)
                                                    played = 0
                                                }} >
                                                    {
                                                        videoControl?.success ? (
                                                            <FaCheck color='green' />
                                                        ) : (
                                                            <FaPlay color={selectedVideo?.id == item.id ? "blue" : "white"} />
                                                        )
                                                    }

                                                    <p style={{ color: selectedVideo?.id == item.id ? "blue" : "white", textTransform: "capitalize" }} >
                                                        {item?.description?.length > 30 ? `${item?.description?.substring(0, 30)}...` : item?.description}
                                                    </p>
                                                </VideoElementItem>
                                            )
                                        }

                                        ) : (
                                            <Alert message="Henüz içerik eklenmedi" type='warning' />
                                        )
                                    }

                                </Collapse.Panel>
                            )
                        })
                    }
                </AntCollapse>
                {/* {
                    data?.map((el, index) => {
                        return (

                        )
                    })
                } */}
            </VideoRightContentContainer>
        </StyledPlayerContainer>
    )
}

export default VideoPlayerPage





const StyledPlayerContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    max-height: 100vh;
    overflow-y: hidden;

    @media screen and (max-width:1300px)  {
        flex-direction: column;
    }
`


const VideoPlayerContainer = styled.div`
    width: calc(100% - 430px);
    height: 100vh;
    background-color: black;
    @media screen and (max-width:1300px)  {
        width: 100%;
        min-height: 400px;
    }

    @media screen and (max-width:400px)  {
        width: 100%;
        min-height: 300px;
    }
`

const VideoRightContentContainer = styled.div`
    width: 430px;
    height: 100vh;
    background-color: #1E2429;
    overflow-y : scroll;
    
    @media screen and (max-width:1300px)  {
        width: 100%;
    }
`

const AccHeaderElement = styled.div`
    padding: 15px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #62666A;
    color: white;

    .left_c {
        display: flex;
        align-items: center;
        column-gap: 10px;
        .index_el{
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid gray;
            border-radius: 50%;
        }
    }

   /*  border: 1px solid black; */
`
const AntCollapse = styled(Collapse)`

    border: none !important;

    .ant-collapse-content-box{
        padding: 0 !important;
    }

    .ant-collapse-item{
        border: none;
    }

    .ant-collapse-content{
        border: none;
    }
`

const VideoElementItem = styled.div`
    background-color: #181C20;
    padding: 20px 20px;
    display: flex;
    align-items: center;
    color: ${prop => prop.check ? "#298AF2" : "white"};
    column-gap: 10px;
    border-bottom: 1px solid gray;
    transition: .5s all;
    cursor: pointer;
    &:hover{
        opacity: .9;
    }
`

const VideoPlayerRightSectionHeader = styled.div`
    padding: 20px;
    border-bottom: 2px solid white;
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    background-color: #1E2429;
    z-index: 100000;

    @media screen and (max-width:600px) {
           padding: 14px 20px;
        }

    .player_title{
        font-weight: bold;
        font-size: 16px;
        color: white;

        @media screen and (max-width:600px) {
            font-size: 14px;
        }
    }
`

const InfoElement = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    background-color: gray;
    padding: 3px 0;

    @media screen  and (max-width:600px)  {
        display: none;
    }
`

const NoSelectedVideo = styled.div`
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 100%;
`   