import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import CardLesson from '../../cardLesson/CardLesson'
import { axiosInstance } from '../../../../api/axiosInstance'
import { Link, useNavigate } from 'react-router-dom'
import { GoArrowUpRight } from 'react-icons/go'
import imgCard1 from "../../../../assets/images/imgcard.png"
import imgCard2 from "../../../../assets/images/imgcard2.png"
import imgCard3 from "../../../../assets/images/imgcard3.png"
import imgCard4 from "../../../../assets/images/imgcard4.png"
import imgCard5 from "../../../../assets/images/imgcard5.png"
import imgCard6 from "../../../../assets/images/imgcard6.png"

import { useTranslation } from 'react-i18next'
import imgPlay from "../../../../assets/images/imgPlay.png"
import ProfileAvatar from "../../../../assets/images/profile.png"


const MostPopularOfflineCourse = () => {
    const imgList = [imgCard1, imgCard2, imgCard3, imgCard4, imgCard5, imgCard6]
    const { t } = useTranslation();
    const [offlineData, setOfflineData] = useState([])
    const navigate = useNavigate()

    function getRandomNumber() {
        return Math.floor(Math.random() * imgList.length - 1); // 0 ile 6 arasında bir sayı üretir
    }

    const getImg = (course) => {
        if (course?.url == "" || course?.url == null || course?.url == undefined || course?.url == "0") {
            const randomNumber = getRandomNumber()
            return imgList[randomNumber]
        }
        return course?.url
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.post('/branches/GetAll?type=Offline', {
                    page: 0,
                    pageSize: 100
                });

                setOfflineData(response.data.items?.filter(item => item?.url != "0").splice(0, 4))
            } catch (error) {

            }
        };

        fetchData();
    }, []);


    if (offlineData?.length == 0) {

        return (
            <>
            </>
        )

    }

    return (
        <StyledContainer>
            <div id='most_popular_course_wrapper' >
                <div id='most_popular_course_wrapper_header' >
                    <div className='most_popular_course_wrapper_header_left_conntent' >
                        <p id='text00'>
                            {t("slogan5")}
                            <span id='text01' >
                                {t("slogan6")}
                            </span>
                        </p>
                        <p id='text02'> {t("slogan7")} </p>
                    </div>
                    <Link id='text03' to={"/egitimler/videoegitim"} >  {t("slogan8")} <GoArrowUpRight id='Arrow00' /></Link>
                </div>

                <div className='most_popular_offline_course_container'  >

                </div>

                <Row style={{ marginTop: "30px" }} >
                    {
                        offlineData.map((item, index) => {
                            return (
                                <Col xl={3}  lg={6} md={6} key={`${index}`} >
                                    <CardLesson
                                        infoText={item.name.toUpperCase()}
                                        name={"Eğitmen Atanmamış"}

                                        imageSrc={ProfileAvatar}
                                        imgPlay={imgPlay}
                                        imgcard={getImg(item)}
                                        btnColor='#7f56d9'
                                        btnText={t("btnText")}
                                        onClick={() => {
                                            /* console.log("item ==> ", item) */
                                            const courseLabel = encodeURIComponent(item?.label)
                                            navigate(`/kurs/${courseLabel}`)
                                        }}
                                        rate={3}
                                        hiddenBtn={true}
                                    ></CardLesson>
                                </Col>

                            )
                        })
                    }


                </Row>


            </div>

        </StyledContainer>
    )
}

export default MostPopularOfflineCourse


const StyledContainer = styled.div`

    width: 100%;
    padding: 0 80px;
    /* padding-top: 40px; */
    padding-bottom: 10px;


    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin-top: 0;
    }


    @media screen and (max-width:500px) {
        padding: 20px 20px;
        margin-top: 0;
    }


    #most_popular_course_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 70px;

        #most_popular_course_wrapper_header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;


            @media screen and (max-width:500px) {
                flex-direction: column;
            }




            .most_popular_course_wrapper_header_left_conntent {
                display: flex;
                flex-direction: column;

                #text00 {
                    font-size: 32px;
                    color: #7f56d9;

                    @media screen and (max-width:860px) {
                        font-size: 20px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 20px;
                    }


                    #text01 {
                        color: black;
                        margin-left: 10px;

                    }
                }

                #text02 {
                    font-size: 18px;
                    color: #8585AF;

                    @media screen and (max-width:860px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 16px;
                        margin-top: 20px;
                    }
                }

            }


            #text03 {
                font-size: 16px;
                font-weight: bold;
                text-decoration: underline;
                color: #8585AF;

                #Arrow00 {
                    font-size: 26px;
                    color: #7F56D9;
                }

                @media screen and (max-width:500px) {
                    display: none;
                }
            }

        }



        .most_popular_offline_course_container {
            margin-top: 70px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 20px;

            @media screen and (max-width:860px) {
                grid-template-columns: 1fr 1fr;
            }

            @media screen and (max-width:500px) {
                grid-template-columns: 1fr;
            }
        }

    }

`