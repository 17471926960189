import React, { useContext, useEffect } from 'react'
import './assets/scss/themes.scss';
import Route from './Routes/index';
import { ConfigProvider } from 'antd';
import trTR from 'antd/locale/tr_TR';
import { useLocation } from 'react-router-dom';
/* test commit */



const App = () => {

  const location = useLocation();

  useEffect(() => {
    if (window.ym) {
      window.ym("98428588", "hit", location.pathname);
    }
  }, [location]);


  return (
    <>
      <ConfigProvider locale={trTR}>
        <Route />
      </ConfigProvider>

    </>

  )
}

export default App