import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsArrowDown } from 'react-icons/bs'
import { Card } from 'reactstrap'
import styled from 'styled-components'
import { getAllForExamIdApi } from '../../../api/exam'
import { useParams } from 'react-router-dom'
import { Table as AntTable, Input, Button as AntButton, Button, Modal } from "antd"
import { ExamUpdateContext } from '../ExamUpdateDashboard'
import { axiosInstance } from '../../../api/axiosInstance'
import { toast } from 'react-toastify'

const ExamResultDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const { id } = useParams()
    const { examDetailData, courseList } = useContext(ExamUpdateContext)
    console.log("examDatxxx", examDetailData)
    const getStudentResults = async () => {
        try {
            setLoading(true)
            const response = await getAllForExamIdApi(id)
            console.log("rexpxxx", response.data)
            setData(response.data.items)
        }
        catch (err) {

        }
        finally {
            setLoading(false)   
        }
    }


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm()
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <AntButton
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    /* icon={<SearchOutlined />} */
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Ara
                </AntButton>
                <AntButton onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    Reset
                </AntButton>
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        render: (text) =>
            searchedColumn === dataIndex ? (
                <span>{text}</span>
            ) : (
                text
            ),
    });


    const publishExamResult = () => {
        Modal.confirm({
            title: "Sınav sonuçlarını yayınlamak istediğinize emin misiniz?",
            okText: "Eminim",
            cancelText: "İptal",
            onOk: async () => {
                try {
                    setLoading(true)
                    await axiosInstance.put("/exams/updateStatus", {
                        id: examDetailData?.id,
                        status: true
                    })
                    toast.success("Sınav sonuçları yayınlandı", {
                        position: "top-center",
                        autoClose: 3000
                    })
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })
    }


    useEffect(() => {
        getStudentResults()
    }, [])

    return (
        <StyledContainer>
            <AntTable
                loading={loading}
                size='middle'
                dataSource={data}
                pagination={{
                    pageSize: 10
                }}
                scroll={{
                    x: 800
                }}
                columns={[
                    {
                        title: "İsim",
                        dataIndex: "userFistAndLastName",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.userFistAndLastName}
                                </p>
                            )
                        },
                        ...getColumnSearchProps("userFistAndLastName"),
                        sorter: (a, b) => a?.userFistAndLastName?.localeCompare(b?.userFistAndLastName),
                    },
                    {
                        title: "Puan",
                        dataIndex: "totalPoint",
                        render: (row, record) => {
                            return (
                                <p>
                                    {record?.totalPoint}
                                </p>
                            )
                        },
                        sorter: (a, b) => a?.totalPoint - b?.totalPoint
                    }
                ]}
            />
            {
                examDetailData?.type == "Yazılı" && (
                    <StyledFooter  >
                        <Button onClick={publishExamResult} type='primary' loading={loading}  >
                            Sınav Sonuçlarını yayınla
                        </Button>
                    </StyledFooter>
                )

            }

        </StyledContainer>

    )
}

const StyledContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`
const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-start;
`

export default ExamResultDashboard