import React, { useEffect, useState } from 'react';
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function useWindowSize() {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return size;
}

const HomeCalendarLeaf = () => {
    const [width] = useWindowSize();
    const [showRegisterButton, setShowRegisterButton] = useState(false);
    const [showCountdown, setShowCountdown] = useState(false); // Yeni state
    const targetDate = new Date('2024-09-24T08:00:00');
    const navigate = useNavigate()
    // Check if current date is greater than target date
    useEffect(() => {
        const now = new Date();
        if (now > targetDate) {
            setShowRegisterButton(true);
        }
    }, []);

    const authToken = localStorage.getItem("authToken")


    const flipClockResponsive = {
        fontSize: width > 600 ? 50 : 30,
        backgroundColor: '#FAC921',
        width: width > 600 ? 70 : 30,
        height: width > 600 ? 100 : 50,
        color: 'black',
        fontWeight: '400',
    };

    return (
        <StyledContainer>
            {
                showCountdown ? (
                    <h3 className='count_title'>
                        Kurs Kayıtlarının açılmasına kalan süre
                    </h3>
                )
                    : (
                        <h3 className='complete_count'>
                            Kurs Kayıtları Başladı
                        </h3>
                    )

            }


            {/* Geri sayım tamamlanmadan göster */}
            {showCountdown && (
                <FlipClockCountdown
                    labelStyle={{ color: 'white' }}
                    labels={['Gün', 'Saat', 'Dakika', 'Saniye']}
                    to={targetDate}
                    digitBlockStyle={flipClockResponsive}
                    onComplete={() => {
                        setShowCountdown(false); // Geri sayım bittiğinde gizle
                        setShowRegisterButton(true); // Kayıt ol butonunu göster
                    }}
                />
            )}

            {/* Show the register button if current date is greater than the target date or countdown is complete */}
            {showRegisterButton &&  !authToken && (
                <button className='register_button' onClick={() => {
                    navigate("/giris")
                }} >
                    Giriş Yap
                </button>
            )}
        </StyledContainer>
    );
};

export default HomeCalendarLeaf;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;

  .count_title {
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    text-align: center;



    @media screen and (max-width:600px) {
        font-size: 26px;
 
    }

    @media screen and (max-width:400px) {
        font-size: 20px;
 
    }
  }

  .complete_count{
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    text-align: center;
    letter-spacing: 3px;

    @media screen and (max-width:600px) {
        font-size: 26px;
        letter-spacing: 2px;
    }
  }

  .register_button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .register_button:hover {
    background-color: #218838;
  }
`;
