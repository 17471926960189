import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import TeacherCourseDashboard from '../../Components/Course/TeacherCourse/TeacherCourseDashboard'
import styled from 'styled-components'

const TeacherCoursePage = () => {

    return (
        <div className='page-content' >
            <TablePageContainer title='Kurslarım' header={"Kurslarım"} visible={false}  >
                <StyledContainer>
                    <TeacherCourseDashboard />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen  and (max-width:500px){
        padding: 5px;
    }
`

export default TeacherCoursePage