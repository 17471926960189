import React, { useState } from 'react';

import { Button, Menu } from 'antd';
import { FaHome } from 'react-icons/fa';
import { AiOutlineDash } from 'react-icons/ai';
import { BsMenuApp } from 'react-icons/bs';
import styled from 'styled-components';
const items = [
    {
        key: '1',
        icon: <FaHome />,
        label: 'Option 1',
    },
    {
        key: '2',
        icon: <FaHome />,
        label: 'Option 2',
    },
    {
        key: '3',
        icon: <FaHome />,
        label: 'Option 3',
    },
    {
        key: 'sub1',
        label: 'Navigation One',
        icon: <FaHome />,
        children: [
            {
                key: '5',
                label: 'Option 5',
            },
            {
                key: '6',
                label: 'Option 6',
            },
            {
                key: '7',
                label: 'Option 7',
            },
            {
                key: '8',
                label: 'Option 8',
            },
        ],
    },
    {
        key: 'sub2',
        label: 'Navigation Two',
        icon: <FaHome />,
        children: [
            {
                key: '9',
                label: 'Option 9',
            },
            {
                key: '10',
                label: 'Option 10',
            },
            {
                key: 'sub3',
                label: 'Submenu',
                children: [
                    {
                        key: '11',
                        label: 'Option 11',
                    },
                    {
                        key: '12',
                        label: 'Option 12',
                    },
                ],
            },
        ],
    },
];

const AntSidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    

    return (
        <StyledAntMenu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"

            inlineCollapsed={collapsed}
            items={items}
        />
    );
}

export default AntSidebar

const StyledAntMenu = styled(Menu)`
    position: fixed;
    width: 256px;
    top: calc(120px);
    bottom: 100px;
`