import React, { useContext, useEffect, useMemo, useState } from 'react'
import "./createSurvey.scss"
import { Button, FormGroup, Label, Modal, ModalHeader, Input, ModalBody, ModalFooter, Row, Col, Spinner } from 'reactstrap'
import { GetCalendarForAllRoles } from '../../../../api/Course'
import { Select, Spin, Button as AntButton } from "antd"
import DragAndDropList from './DragAndDropList'
import { SurveyContext } from '../TeacherSurveyDashboard'
import { v4 as uuid } from "uuid"
import { addSurveyAnswersApi, createSurveyApi, createSurveyQuestionApi } from '../../../../api/Survey'
import { toast } from 'react-toastify'
import { FiTrash } from 'react-icons/fi'
import CreateQuestionModal from './CreateQuestionModal'
import styled from 'styled-components'
const CreateSurvey = () => {

    const { surveyInfo, setAnswerList, answerList, setSurveyInfo, courseList, setCourseList, selectedCourse, setSelectedCourse, createQuestionModal, setCreateQuestionModal, questionItems, setQuestionItems } = useContext(SurveyContext)
    const [loading, setLoading] = useState(false)
    const getCourseList = async () => {
        try {
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 50
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })

            setCourseList(responsev2.data.items.map(el => ({ name: el.name, courseId: el.id, type: el.type })))
        }
        catch (err) {

        }
    }


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(questionItems);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setQuestionItems(reorderedItems);
    };

    function rakamToHarf(rakam) {
        const asciiOffset = 65;
        const harf = String.fromCharCode(asciiOffset + parseInt(rakam) - 1);
        return harf;
    }

    function hasDuplicateAnswerContent(array) {
        const seen = new Set();
        for (const item of array) {
            if (seen.has(item.answerContent)) {
                return true;
            }
            seen.add(item.answerContent);
        }
        return false;
    }


    const addQuestion = () => {
        if (createQuestionModal.editMode) {

            if (!hasDuplicateAnswerContent(answerList)) {
                setQuestionItems(questionItems.map((el => {
                    if (el.id == createQuestionModal.questionId) {
                        return {
                            ...el,
                            questionContent: createQuestionModal.questionContent,
                            answers: answerList
                        }
                    }
                    return el
                })))
                setCreateQuestionModal({
                    editMode: false,
                    questionId: "",
                    visible: false,
                    questionContent: ""
                })

                setAnswerList([])
            }
            else {
                toast.error("aynı cevaptan iki tane olamaz", {
                    autoClose: 2000
                })
            }

        }
        else {

            if (!hasDuplicateAnswerContent(answerList)) {
                setQuestionItems([
                    ...questionItems,
                    {
                        "questionContent": createQuestionModal.questionContent,
                        "id": uuid(),
                        "answers": answerList
                    }
                ])
                setCreateQuestionModal({
                    visible: false,
                    questionContent: ""
                })
                setAnswerList([])
            }
            else {
                toast.error("aynı cevaptan iki tane olamaz", {
                    autoClose: 2000
                })
            }

        }

    }

    const createSurveyProcess = async () => {
        try {
            setLoading(true)
           

            let includesOption = questionItems.map((el) => {
                return {
                    ...el,
                    answers: el.answers.map((item, index) => {
                        return {
                            ...item,
                            option: rakamToHarf(index + 1)
                        }
                    })
                }
            })
            const response = await createSurveyApi({
                name: surveyInfo.name,
                description: surveyInfo.description,
                courseId: selectedCourse.courseId
            })
            for (let item of includesOption) {
                const questionResponse = await createSurveyQuestionApi({
                    surveyId: response.data.id,
                    question: item.questionContent
                })
                for (let el of item.answers) {
                    await addSurveyAnswersApi({
                        surveyQuestionId: questionResponse.data.id,
                        answer: el.answerContent,
                        option: el.option
                    })
                }
            }
            setSurveyInfo({
                name: "",
                description: ""
            })
            setQuestionItems([])
            toast.success("Anket Oluşturuldu", {
                autoClose: 1500
            })

        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const createFormControl = useMemo(() => {
        if (selectedCourse == "") {
            return false
        }
        else if (surveyInfo.name == "" || surveyInfo.description == "") {
            return false
        }
        else if (questionItems.length == 0) {
            return false
        }
        return true
    }, [selectedCourse, surveyInfo, questionItems])




    useEffect(() => {
        getCourseList()
    }, [])


    return (
        <>
            <div className='create_survey_container'>
                <div className='create_survey_header' >
                    <StyledAntSelect
                        showSearch
                        status='warning'
                        optionFilterProp='label'
                        placeholder="Kurs seçiniz"
                        options={courseList.map(el => {
                            return {
                                "label": `${el?.courseId}-${el.name}-(${el.type})`,
                                "value": el.courseId
                            }
                        })}
                        onChange={(e) => {
                            setSelectedCourse(courseList.find(x => x.courseId == e) ?? "")
                        }}
                    />

                    <AntButton id='create-question-button' style={{ backgroundColor: "#088F7D", color: "white" }} type='primary' disabled={selectedCourse == ""} onClick={() => {
                        setCreateQuestionModal({
                            ...createQuestionModal,
                            visible: true
                        })
                    }} >
                        Soru Oluştur
                    </AntButton>
                </div>
                <div className='create_survey_contents_wrapper' >
                    <div className='create_survey_content_left_section '  >
                        <div >
                            <FormGroup>
                                <Label for="exampleEmail">
                                    Anket Başlığı
                                </Label>
                                <Input
                                    style={{ marginLeft: "0" }}
                                    onChange={(e) => {
                                        setSurveyInfo({
                                            ...surveyInfo,
                                            name: e.target.value
                                        })
                                    }}
                                    value={surveyInfo.name}
                                    id="surveyName"
                                    name="surveyName"
                                    placeholder="Anket Başlığı"
                                    type="text"
                                />
                            </FormGroup>
                            <FormGroup style={{ display: "block" }}>
                                <Label for="exampleEmail">
                                    Açıklama
                                </Label>
                                <Input value={surveyInfo.description}
                                    type='textarea' style={{ resize: "none" }}
                                    rows={7} placeholder='Soru İçeriği'
                                    onChange={(e) => {
                                        setSurveyInfo({
                                            ...surveyInfo,
                                            description: e.target.value
                                        })
                                    }} />
                            </FormGroup>
                        </div>
                        <AntButton type='primary' disabled={!createFormControl || loading} onClick={() => {
                            createSurveyProcess()

                        }} >
                            <span style={{ marginRight: "10px" }} >
                                Kaydet
                            </span>
                            {
                                loading && (
                                    <Spinner size={"sm"} />
                                )
                            }
                        </AntButton>
                    </div>


                    <Button id='mobile_create_question_btn' onClick={() => {
                        setCreateQuestionModal({
                            ...createQuestionModal,
                            visible: true
                        })
                    }} >
                        Soru Ekle
                    </Button>


                    <div className='create_survey_content_right_section '>
                        <DragAndDropList items={questionItems} onDragEnd={onDragEnd} />
                    </div>
                </div>



            </div >
            <CreateQuestionModal addQuestion={addQuestion} answerList={answerList} setAnswerList={setAnswerList} createQuestionModal={createQuestionModal} setCreateQuestionModal={setCreateQuestionModal} />

        </>


    )
}

export default CreateSurvey


const StyledAntSelect = styled(Select)`
    width: 350px;

    @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 20px;
    }
`