import React, { useContext, useEffect, useMemo, useState } from 'react'
import "./courseDetail.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate, useParams } from 'react-router-dom'
import { getDetailCourseApi, registerCourseApi } from '../../../api/Course'
import { toast } from 'react-toastify'
import { AccordionBody, AccordionHeader, AccordionItem, Button, Col, Container, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledAccordion } from 'reactstrap'
import { DetailInfoWidget } from '../DetailInfoWidget'
import { TbCategory, TbFileDescription } from "react-icons/tb";
import { CircleLoader } from 'react-spinners'
import { BsGenderMale } from 'react-icons/bs';
import { CiCalendarDate } from 'react-icons/ci';
import { UserContext } from '../../../context/user';
import { MdCategory } from 'react-icons/md';
import "./CourseDetailDashboard.scss"
import { Alert, Button as AntButton, Modal as AntModal } from "antd"
import { CourseDetailTitleContainer } from './courseDetailStyle';
import { axiosInstance } from '../../../api/axiosInstance';
import { FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import { tr } from "date-fns/locale"
import { format } from "date-fns"

const CourseDetailDashboard = () => {
    const [loading, setLoading] = useState(false)
    const { id } = useParams()
    const [courseDetailData, setCourseDetailData] = useState()
    const navigate = useNavigate()
    const [takenCourse, setTakenCourse] = useState([])
    const [TeacherList, setTeacherList] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState()
    const [selectTeacherModal, setSelectTeacherModal] = useState({
        courseId: "",
        show: false,
        teacher: null
    })
    const [context, dispatch] = useContext(UserContext)
    const [courseContents, setCourseContents] = useState([])

    const getCourseList = async () => {
        try {
            setLoading(true)
            const response = await getDetailCourseApi(id)

            setCourseDetailData(response.data)
            /* const response = await getAllCourseByStatusApi({
                page : 0,
                pageSize : 100
            })
            setCourseDetailData(response.data.items.find(el => el.id == id)) */
        }
        catch (err) {
            navigate(-1)
        }
        finally {
            setLoading(false)
        }
    }


    const CourseType = useMemo(() => {
        return courseDetailData?.type == "Offline"
    }, [courseDetailData])

    const timeDetail = useMemo(() => {
        if (courseDetailData) {
            return {
                startDate: new Date(courseDetailData?.startDate).toLocaleDateString(),
                registerStartTime: new Date(courseDetailData?.registerStartTime).toLocaleString(),
                endDate: new Date(courseDetailData?.endDate).toLocaleDateString(),
                registerTime: new Date(courseDetailData?.registerTime).toLocaleString()
            }
        }
    }, [courseDetailData?.startDate, courseDetailData?.endDate])

    const getCourseContent = async () => {
        try {
            const response = await axiosInstance.post(`/CourseContents/GetAllForCourseId?courseId=${id}`, {
                page: 0,
                pageSize: 100
            })
            let tempData = response.data?.items
            setCourseContents(tempData)
        }
        catch (err) {

        }
    }

    const getTakenCourseForStudent = async () => {
        try {
            if (context.role == "Öğrenci") {
                const response = await axiosInstance.post("/courses/getCalendarForAllRoles", {
                    page: 0,
                    pageSize: 100
                })
                setTakenCourse(response.data.items)
            }

        }
        catch (err) {

        }
    }


    useEffect(() => {
        /* DetailCourseRequest() */
        getCourseList()
        getCourseContent()
        getTakenCourseForStudent()
    }, [])


    const roleControl = useMemo(() => {
        let formatRole = context.role.replace(/\s+/g, '');
        return formatRole == "Admin" || formatRole == "SüperAdmin"
    }, [context])

    const offlineContentControl = useMemo(() => {
        return courseDetailData?.type == "Offline"
    }, [courseDetailData])


    const courseTakenControl = useMemo(() => {
        if (takenCourse.length != 0) {
            let control = takenCourse.find(item => item?.id == id)
            if (control) {
                return false
            }
            return true
        }
        return true
    }, [takenCourse, id])

    const courseAdmin = useMemo(() => {
        if (courseDetailData?.courseAdministrator) {
            return courseDetailData?.courseAdministrator
        }
        return "Eğitmen henüz seçilmedi"
    }, [courseDetailData])



    const studentButton = () => {
        if (!courseTakenControl) {
            return (
                <AntButton type='primary' style={{
                    cursor: "not-allowed",
                    backgroundColor: "yellowgreen"
                }}  >
                    Zaten Kayıtlısınız
                </AntButton>
            )
        }
        else if (courseDetailData?.limitStatus) {
            return (
                <AntButton type='primary' style={{
                    cursor: "not-allowed",
                    backgroundColor: "red"
                }}  >
                    Kontenjan Dolu
                </AntButton>

            )

        }
        else {
            return (
                <AntButton type='primary' onClick={async () => {
                    if (courseDetailData?.prelim) {
                        AntModal.confirm({
                            content: (
                                <div>
                                    <p>
                                        {courseDetailData?.name} isimli kurs için ön sınava girmek istediğinizden emin misiniz ?
                                    </p>
                                </div>
                            ),
                            okText: "Eminim ?",
                            cancelText: "İptal",
                            onOk: async () => {
                                try {
                                    if (courseDetailData.exams.length !== 0) {
                                        navigate(`/panel/sinav/${courseDetailData?.exams[0]?.id}`)
                                    }
                                    else {
                                        toast.info("Bu kurs için henüz ön sınav oluşturulamadı", {
                                            autoClose: 2000,
                                            position: "top-center"
                                        })
                                    }
                                }
                                catch (err) {

                                }
                            }
                        })


                    }
                    else {

                        AntModal.confirm({
                            content: (
                                <div>
                                    <p>
                                        {courseDetailData?.name} isimli kursa kayıt olmak istediğinizden emin misiniz?
                                    </p>
                                </div>
                            ),
                            okText: "Eminim ?",
                            cancelText: "İptal",
                            onOk: async () => {
                                try {
                                    const response = await registerCourseApi({
                                        courseId: id,
                                        userId: context.userId
                                    })
                                    toast.success("Kursa Kayıt işleminiz gerçekleşti", {
                                        autoClose: 4500,
                                        position: "top-center"
                                    })
                                    await getCourseList()
                                    await getCourseContent()
                                    await getTakenCourseForStudent()
                                }
                                catch (err) {

                                }
                            }
                        })


                    }
                }}    >
                    {courseDetailData?.prelim ? "Ön Sınava git" : "Kayıt Ol"}
                </AntButton>
            )
        }

    }

    function addHoursToDate(timeString, hoursToAdd) {
        const date = new Date(timeString);
        date.setHours(date.getHours() + hoursToAdd);
        return date.toISOString().substring(11, 16);
    }


    function groupByCourseCalendars(liste) {

        const groups = {};
        liste?.forEach(item => {
            const startDate = new Date(item?.startTime);
            const day = format(startDate, 'EEEE', { locale: tr });
            if (!groups[day]) {
                groups[day] = [];
            }
            groups[day]?.push(item);
        });
        const resulted = {
        }
        Object.entries(groups).map(([key, value]) => {
            resulted[key] = []
        })
        Object.entries(groups).map(([key, value]) => {
            value?.forEach(el => {
                const startTimePart = addHoursToDate(el?.startTime, 3)
                const endtTimePart = addHoursToDate(el?.endTime, 3)
                let control = resulted[key]?.find(item => item?.startTime == startTimePart)
                const day = format(el?.startTime, 'EEEE', { locale: tr });
                if (!control) {
                    resulted[key].push({
                        startTime: startTimePart,
                        endTime: endtTimePart,
                        day: day
                    })
                }
            })

        })

        return resulted;
    }



    const courseCalendarMemo = useMemo(() => {
        const result = groupByCourseCalendars(courseDetailData?.courseCalendars)
        return result

    }, [courseDetailData])


    if (loading) {
        return <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}>
            <CircleLoader color='green' />
        </div>
    }




    return (
        <div>
            <Row className='gy-3'>
                <Col sm={12} >
                    <CourseDetailTitleContainer >
                        <h3 className='course_detail_screen_course_name' style={{ textTransform: "capitalize" }} >
                            {courseDetailData?.name}
                        </h3>
                        <div >
                            {
                                context.role === "Öğrenci" && studentButton()
                            }

                            {
                                roleControl && (
                                    <div style={{ display: "flex", columnGap: "10px" }} >
                                        <AntButton
                                            size='middle'
                                            type='primary'
                                            style={{
                                                backgroundColor: "#F3B54A"
                                            }}
                                            onClick={() => {
                                                navigate(`/panel/kurs/duzenle/${id}`)
                                            }} >
                                            Düzenle
                                        </AntButton>
                                        {
                                            CourseType && (
                                                <AntButton type='primary' onClick={() => {
                                                    navigate(`/kurs/${id}`)
                                                }}  >
                                                    İzle
                                                </AntButton>
                                            )
                                        }

                                    </div>
                                )
                            }

                        </div>
                    </CourseDetailTitleContainer>
                </Col>
                {
                    CourseType ? (
                        <>
                            <Col sm={6} >
                                <DetailInfoWidget icon={<TbCategory />} title='Kategori' value={courseDetailData?.category?.name ?? "Seçilmedi"} />
                            </Col>
                            <Col sm={6} >
                                <DetailInfoWidget icon={<TbCategory />} title='Ön Sınav' value={courseDetailData?.prelim ? "Var" : "Yok"} />
                            </Col>
                            <Col sm={12} >
                                <DetailInfoWidget icon={<TbFileDescription />} title='Açıklama' value={courseDetailData?.description} />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col sm={3} >
                                <DetailInfoWidget icon={<CiCalendarDate />} title='Kurs Başlangıç' value={timeDetail?.startDate ?? ""} />
                            </Col>
                            <Col sm={3} >
                                <DetailInfoWidget icon={<CiCalendarDate />} title='Kurs Bitişi' value={timeDetail?.endDate ?? ""} />
                            </Col>
                            <Col sm={3} >
                                <DetailInfoWidget icon={<CiCalendarDate />} title='Kayıt Başlangıç Tarihi' value={timeDetail?.registerStartTime ?? ""} />
                            </Col>
                            <Col sm={3} >
                                <DetailInfoWidget icon={<CiCalendarDate />} title='Kayıt Bitiş Tarihi' value={timeDetail?.registerTime ?? ""} />
                            </Col>

                            <Col sm={6} >
                                <DetailInfoWidget icon={<BsGenderMale />} title='Cinsiyet Şartı' value={courseDetailData?.genderType} />
                            </Col>
                            <Col sm={6} >
                                <DetailInfoWidget icon={<TbCategory />} title='Kategori' value={courseDetailData?.category?.name ?? "Seçilmedi"} />
                            </Col>

                            <Col sm={6} >
                                <DetailInfoWidget icon={<MdCategory />} title='Dönem' value={courseDetailData?.semester} />
                            </Col>
                            <Col sm={6} >
                                <DetailInfoWidget icon={<FaUser />} title='Eğitmen' value={courseAdmin} />
                            </Col>
                            <Col sm={12} >
                                <DetailInfoWidget

                                    icon={<TbFileDescription />} title='Açıklama' value={courseDetailData?.description} />
                            </Col>
                        </>

                    )
                }


            </Row>

            <CourseCalendarContainer>
                <CourseCalandarContentHeader>
                    <h4>
                        Kurs Takvimi
                    </h4>

                </CourseCalandarContentHeader>
                {
                    Object.entries(courseCalendarMemo).length != 0 ? (
                        <CourseCalendarItemWrapper>
                            {
                                Object.entries(courseCalendarMemo).map(([key, value], index) => {

                                    return value?.map((item, idx) => {

                                        return (
                                            <div className='course_calendar_element' key={`${idx}`} >
                                                <p className='day'  >
                                                    (
                                                    {
                                                        item?.day
                                                    }
                                                    )
                                                </p>
                                                <p className='start_time' >
                                                    <span>
                                                        {
                                                            item?.startTime
                                                        }
                                                    </span>
                                                    <span>
                                                        -
                                                    </span>
                                                    <span>
                                                        {
                                                            item?.endTime
                                                        }
                                                    </span>
                                                </p>
                                            </div>
                                        )
                                    })

                                })
                            }
                        </CourseCalendarItemWrapper>
                    ) : (
                        <div style={{
                            padding: "0px 10px"
                        }}>
                            <Alert type='info' message="Henüz kurs takvimi eklenmedi" />
                        </div>

                    )
                }

            </CourseCalendarContainer>

            <CourseContentContainer>
                <CourseContentHeader>
                    <h4>
                        Kurs İçeriği
                    </h4>

                </CourseContentHeader>
                <div style={{
                    padding: "0 10px"
                }}>
                    {courseContents?.length != 0 ? (
                        <UncontrolledAccordion stayOpen defaultOpen={[0, 1]} >
                            {
                                courseContents?.map((item, index) => {
                                    return (
                                        <AccordionItem key={`${index}`}  >
                                            <AccordionHeader targetId={index} >
                                                {item.name}
                                            </AccordionHeader>
                                            <AccordionBody accordionId={index} >
                                                {
                                                    item?.onlineCourseContentDetails?.map(el => {
                                                        return (
                                                            <CourseContentSubItem>

                                                                <strong>-</strong>  {el?.description}

                                                            </CourseContentSubItem>
                                                        )
                                                    })
                                                }
                                            </AccordionBody>
                                        </AccordionItem>
                                    )
                                })
                            }
                        </UncontrolledAccordion>

                    ) : (
                        <Alert type='info' message="Henüz kurs içeriği eklenmemiş" />
                    )

                    }
                </div>


            </CourseContentContainer>


        </div>
    )
}

export default CourseDetailDashboard


const CourseContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

const CourseContentHeader = styled.div`
    border: 1px dashed gray;
    padding: 10px;
    margin-top: 30px;
    border-radius: 10px;
`
const CourseContentSubItem = styled.p`
    margin-bottom: 10px;
    font-weight: 700;
`

const CourseCalendarContainer = styled.div`
        display: flex;
    flex-direction: column;
    row-gap: 20px;
`
const CourseCalandarContentHeader = styled.div`
    border: 1px dashed gray;
    padding: 10px;
    margin-top: 30px;
    border-radius: 10px;
`
const CourseCalendarItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr ;
    column-gap: 20px;
    row-gap: 15px;
    padding: 0 20px;

    @media screen and (max-width:600px) {
        grid-template-columns: 1fr;
    }

    .course_calendar_element {
        border: 1px solid gray;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        .day{
            font-weight: bold;
            font-size: 15px;
        }

        .start_time{
            font-weight: bold;
            font-size: 15px;
            display: flex;
            align-items: center;
            column-gap: 2px;
        }
    }

`