import React from 'react'
import styled from 'styled-components'
import Ellipse40 from "../../../../assets/images/ellipse40.png"
import MySlider from '../../MySlider/MySlider'
import { useTranslation } from 'react-i18next'

const HomeCategories = () => {
    const { t } = useTranslation()
    return (
        <StyledContainer >
            <div className='categories_header_container'  >
                <div className='categories_header_content_wrapper'  >
                    <p id='category_p1' >
                        {t("slogan9")}
                        <span>
                            {t("slogan10")}
                        </span>
                    </p>
                    <p id='category_p2'>{t("slogan11")} </p>
                </div>
                <img src={Ellipse40} alt="" id='ellipse40' />
            </div>
            <MySlider></MySlider>

        </StyledContainer>
    )
}

export default HomeCategories


const StyledContainer = styled.div`
    padding: 80px 80px;
    display: flex;
    flex-direction: column;
    max-width: 100vw;

    @media screen and (max-width:1100px) {
        padding: 60px 40px;
    }

    @media screen and (max-width:860px) {
        padding: 60px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 60px 20px;
    }


    .categories_header_container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        @media screen and (max-width:860px) {
            margin-bottom: 60px;
        }

        @media screen and (max-width:500px) {
            margin-bottom: 60px;
        }

        .categories_header_content_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            #category_p1 {
                color: #7F56D9;
                font-weight: bold;
                font-size: 32px;

                @media screen and (max-width:500px) {
                    font-size: 26px;
                }

                span {
                    font-weight: bold;
                    color: black;
                }
            }

            #category_p2 {
                font-size: 18px;
                color: #ababc9;

                @media screen and (max-width:500px) {
                    font-size: 14px;

                }

            }
        }

        #ellipse40 {
            position: absolute;
            right: 10%;
            max-height: 150%;

            @media screen and (max-width:500px) {
                display: none;

            }
        }

    }
`