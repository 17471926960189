import React, { useEffect } from 'react'
import styled from 'styled-components'
import MainNavbar from '../../components/navbar/MainNavbar'
import MainFooter from '../../components/mainFooter/MainFooter'
import FontHead from '../../components/fontHead/FontHead'
import { Skeleton } from 'antd'
import Slider from 'react-slick'
import kmk1 from "../../../assets/images/kmk1.svg"
import kmk2 from "../../../assets/images/kmk2.svg"
import kmk3 from "../../../assets/images/kmk3.svg"
import kmk4 from "../../../assets/images/kmk4.png"
import kmk5 from "../../../assets/images/kmk5.svg"
import İletisimBg from "../../../assets/images/iletisimBg.png"
import b1 from "../../../assets/images/b1.png"
import { blogDataList } from '../blog/blogData'
import TopBgElement from '../TopBgElement/TopBgElement'
import { Helmet } from 'react-helmet-async'

const UzaktanEgitim = () => {
    const content = blogDataList[0]["content"]
    const images = [
        kmk1,
        kmk2,
        kmk3,
        kmk5,
        kmk4,
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToScroll: 1,      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1025, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true
                }
            },
            {
                breakpoint: 860, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 500, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    useEffect(() => {
        window.scroll(0, 0)

    }, [])

    return (
        <StyledMainContainer>
            <Helmet>
        
                <meta name="description" content="KUZEM Uzaktan Eğitim ile ilgili detaylı bilgiye buradan ulaşabilirsiniz." />
                <meta name="keywords" content="uzaktan eğitim, online eğitim, eğitim, teknoloji, blog" />
            </Helmet>
            <TopBgElement img={İletisimBg} mainTitle={"Uzaktan Eğitim"} subTitle={"Anasayfa/ Uzaktan Eğitim"} />
            <MainNavbar />


            <div className="middle">
                <StyledContainer >
                    <div className="blog_left_section">
                        <img src={b1} className="blog_img" />
                        <h3 className="blog_main_label" >
                            Uzaktan Eğitim Nedir
                        </h3>
                        {
                            content.map((item, index) => {
                                return (
                                    <div key={`${index}`} className="content_element_wrapper" >
                                        <p className="content_element_title" >
                                            {item.title}
                                        </p>
                                        <p className="content_element_text">
                                            {item.content}
                                        </p>
                                    </div>
                                )
                            })
                        }


                    </div>
                </StyledContainer>
                <KomekSliderContainer>
                    <Slider
                        {...settings}
                    >
                        {images.map((image, index) => (

                            <img src={image} alt={`Slide ${index + 1}`} className='slider_item_img' />

                        ))}
                    </Slider>
                </KomekSliderContainer>
            </div>







            <MainFooter visibility={'hidden'} ></MainFooter>
        </StyledMainContainer>
    )
}

export default UzaktanEgitim



const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px 120px;
    margin-bottom: 100px;
    column-gap: 20px;
    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr;
        row-gap: 20px;
    }

    .blog_left_section{
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        padding-bottom: 50px;
        .blog_img{
            width: 100%;
            border-radius: 0;
            height: 350px;
            object-position:  top;
            object-fit: cover;

            @media screen and (max-width:600px) {
               height: max-content;
                object-fit: contain;
            }
     
        }

        .blog_main_label{
            text-align: center;
            font-weight: 700;
            margin-bottom: 15px;
        }

        .content_element_cont{
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            .content_element_wrapper{
            display: flex;
            flex-direction: column;
            row-gap:5px;
            .content_element_title{
                font-weight: 700;
                font-size: 16px;
            }
            .content_element_text{
                text-transform: capitalize;
                /* padding-left: 5px; */
                line-height: 30px;
            }
        }

        }
        
        .content_element_wrapper{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            
            .content_element_title{
                font-weight: bold;
                font-size: 18px;
            }
            .content_element_text{


            }
        }

    }


    .other_blogs_container{
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: white;
        border-radius: 10px;
        height: max-content;
        padding-bottom: 70px;
        .other_blog_title{
            font-weight: 700;
            text-align: center;
            border-bottom: 1px solid gray;
            font-size: 18px;
            padding-bottom: 7px;
        }

        .other_blog_element_wrapper{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin-top: 30px;
        }
    }
`

const KomekSliderContainer = styled.div`
  padding: 20px 120px;
    margin-bottom: 100px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }


    .slider_item_img{
        height: 80px;
    }
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`