// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonx {
  background: transparent;
  color: #A2ABBA;
  white-space: nowrap;
  font-weight: 700 !important;
  display: flex;
  border: none;
  text-transform: capitalize; }

.buttonx:hover {
  background: #dadadd !important; }

#number {
  color: #000;
  background: #E0E5EE !important;
  font-weight: 500; }

.containerButtons {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  column-gap: 20px;
  overflow-x: scroll;
  scrollbar-width: none; }

#egitimler2 {
  padding-top: 10px; }

#dropdown-basic1 {
  background-color: #7f56d9; }

@media screen and (min-Width: 1050px) {
  #egitimler2 {
    display: none; } }

@media screen and (max-Width: 1050px) {
  .containerButtons {
    display: none; }
  #egitimler2 {
    display: block; } }

/* .ant-drawer-right{
    z-index: 100000 !important;
} */
`, "",{"version":3,"sources":["webpack://./src/Public/pages/egitimler/index.scss"],"names":[],"mappings":"AAAA;EACI,uBAAwB;EACxB,cAAe;EACf,mBAAmB;EACnB,2BAA2B;EAC3B,aAAa;EACb,YAAW;EACX,0BAA0B,EAAA;;AAG9B;EACI,8BAA8B,EAAA;;AAKlC;EACI,WAAW;EACX,8BAA8B;EAC9B,gBAAgB,EAAA;;AAIpB;EACI,aAAa;EACb,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB,EAAA;;AAGzB;EACI,iBAAiB,EAAA;;AAIrB;EACI,yBAAyB,EAAA;;AAQ5B;EAEG;IACI,aAAa,EAAA,EAChB;;AAGJ;EACG;IACI,aAAa,EAAA;EAIjB;IACI,cAAc,EAAA,EACjB;;AAIL;;GAtBG","sourcesContent":[".buttonx {\n    background: transparent ;\n    color: #A2ABBA ;\n    white-space: nowrap;\n    font-weight: 700 !important;\n    display: flex;\n    border:none;\n    text-transform: capitalize;\n}\n\n.buttonx:hover {\n    background: #dadadd !important;\n\n   \n}\n\n#number {\n    color: #000;\n    background: #E0E5EE !important;\n    font-weight: 500;\n}\n\n\n.containerButtons {\n    display: flex;\n    justify-content: center;\n    padding-top: 15px;\n    column-gap: 20px;\n    overflow-x: scroll;\n    scrollbar-width: none;\n}\n\n#egitimler2 {\n    padding-top: 10px;\n\n}\n\n#dropdown-basic1 {\n    background-color: #7f56d9;\n}\n\n\n\n\n//-----------------------------------------\n\n @media screen and (min-Width:1050px) {\n\n    #egitimler2 {\n        display: none;\n    }\n}\n\n @media screen and (max-Width:1050px) {\n    .containerButtons {\n        display: none;\n\n    }\n\n    #egitimler2 {\n        display: block;\n    }\n} \n\n\n/* .ant-drawer-right{\n    z-index: 100000 !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
