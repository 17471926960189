import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CreateCourseForm from '../../Components/Course/CreateCourse/CreateCourseForm'
import styled from 'styled-components'

const CreateOnlineCoursePage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Kurs Ekleme' header={"Çevrimiçi Kurs Ekle"} visible={false} >
                <StyledContainer>
                    <CreateCourseForm />
                </StyledContainer>
            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;

    @media screen and (max-width:600px) {
    padding: 10px;
  }
`

export default CreateOnlineCoursePage