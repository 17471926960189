import styled from "styled-components"

export const StyledAddQuestionModalContentContainer = styled.div`
display: grid;
column-gap: 20px;
grid-template-columns: ${props => props.file == null ? "1fr" : "4fr 2fr"};
margin: 10px 0;

@media screen and (max-width:600px) {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 20px;
}

.remove_img_wrapper {
    position: relative;
    max-height: 150px;
    .remove_img_close_icon_container {
        position: absolute;
        top: -7%;
        right: -2%;
        background-color: red;
        cursor: pointer;
        z-index: 1000;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        .remove_img_close_icon {
            color: white;
            font-size: 25px;
        }
    }

}
`

export const AddQuestionModalHeader = styled.div`
  display: flex;
    justify-content: space-between;
    width: 100%;
`

export const AddQuestionModalBody = styled.div`
    display: flex;
    flex-direction: column;

    .question_type_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 20px;

        .question_point {
            width: 100px;
        }

        .question_time {
            width: 100px;
        }

        .select_question_type {
            width: 120px;
        }

        .add_question_btn {
            align-self: flex-end;

        }

    }

    .add_question_model_choice_list {
        margin-top: 20px;
        
        .add_question_modal_choice {
            display: grid;
            align-items: center;
            grid-template-columns: 13fr 1fr;
            margin-bottom: 10px;
            width: 100%;

            .add_question_choice_content {
                display: flex;
                align-items: center;

                .choice_icerik {
                    resize: none;
                }

                .choice_letter {
                    margin-right: 10px;
                    background-color: #3577F1;
                    border-radius: 50%;
                    padding: 5px 9px;
                    color: white;
                }

            }

            .add_question_choice_process {
                display: flex;
                align-items: center;
         /*        margin-left: 15px; */
                justify-content: flex-end;
                .choice_check {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 10px;
                    margin-left: 10px;
                }


                .choice_delete {
                    background-color: red;
                    color: white;
                    font-size: 27px;
                    padding: 5px;
                    border-radius: 50%;
                    transition: .5s all;
                    cursor: pointer;

                    &:hover {
                        opacity: .5;
                    }
                }
            }

        }
    }

`