import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance';
import Slider from 'react-slick';
import CoverPhoto from "../../../assets/images/auth-one-bg.jpg"
import CoverPhoto2 from "../../../assets/images/b1.png"
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';

const SameCourse = ({ course }) => {
    const list = [CoverPhoto, CoverPhoto2]

    const { id } = useParams()
    const [offlineData, setOfflineData] = useState([])
    const navigate = useNavigate()



    const fetchData = async () => {
        try {
            const response = await axiosInstance.post(`/Courses/GetAll?type=${course.type ?? "Offline"}`, {
                page: 0,
                pageSize: 50
            });
            setOfflineData(response.data.items.filter(item => item.id != id))
        } catch (error) {

        }
    };



    const settings = {
        dots: true,
        infinite: true,

        slidesToShow: 5,
        slidesToScroll: 3,      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1024, // Bu noktayı ekrana göre ayarlaaa responsive yap 
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    useEffect(() => {
        fetchData()
    }, [id])

    return (
        <SameCourseContainer>
            <h5 className='same_course_header_title'>
                Diğer Eğitimler
            </h5>
            <div className='slider_same_course_container'>
                <Slider {...settings}>
                    {
                        offlineData?.map((el, index) => {
                            return (
                                <CardContainer onClick={() => {

                                }} >
                                    <Container2>
                                        <CoverImg src={CoverPhoto} alt="" />
                                        <div className='same_course_name_wrapper'>
                                            <p className='same_course_name'>
                                                {el?.name?.length > 20 ? `${el?.name?.substring(0, 20)}...` : el.name}
                                            </p>
                                        </div>

                                        <div className='same_course_card_footer'>
                                            <div className='category_el' >
                                                {el?.category?.name?.length > 15 ? `${el.category?.name?.substring(0, 15)}...` : el?.category?.name}
                                            </div>
                                            <Button type='primary' size='small' onClick={() => {
                                                const uri = encodeURIComponent(el?.id)
                                                navigate(`/kurs/${uri}`)
                                            }} >
                                                İncele
                                            </Button>
                                        </div>
                                    </Container2>

                                </CardContainer>

                            )
                        })
                    }



                </Slider>

            </div>

        </SameCourseContainer>
    )
}

export default SameCourse


const SameCourseContainer = styled.div`
    padding-left: 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    .same_course_header_title{
        font-size: 18px;
        font-weight: bold;
    }

    .slider_same_course_container{
        margin-bottom: 100px;

    }

    @media screen and (max-width:600px) {
        margin-bottom: 50px;
        margin-top: 50px;
    }

`

const CardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Container2 = styled.div`
    width: 90%;
    border: 1px solid #D1D5DB;
    border-radius: 10px;

    .same_course_name_wrapper{
        height: 70px;
        padding-top: 10px;
        padding-left: 10px;
        .same_course_name{
        
        font-size: 16px;
        text-transform: capitalize;
        color: #80949F;


     }

    }
    
    .same_course_card_footer{
        background-color: #EFEFEF;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;  
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category_el{
            background-color: #B9F4B9;
            padding: 3px 10px;
            border-radius: 5px;
            letter-spacing: 2px;
            width: max-content;
            text-transform: capitalize;
        }
    }
`

const CoverImg = styled.img`
    width:100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`