
import { useTranslation } from "react-i18next";
import MainFooter from '../../components/mainFooter/MainFooter';
import MainNavbar from '../../components/navbar/MainNavbar';
import { useEffect } from 'react';
import styled from 'styled-components';
import kurumsalBg from "../../../assets/images/kurumsalbg.png";
import TopBgElement from '../TopBgElement/TopBgElement';
import KurumsalContent from './KurumsalContent';
import { Helmet } from "react-helmet-async";

const Kurumsal = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <StyledKurumsalContainer>
            <Helmet>
                <title>Kurumsal</title>
                <meta name="description" content={t('Kurumsal sayfamızda şirketimiz hakkında bilgilere ulaşabilirsiniz')} />
                <meta name="keywords" content={t('Kurumsal, Şirket, Hakkımızda, İletişim')} />
                <meta property="og:url" content="https://www.kuzem.org/kurumsal" />
                <meta property="og:image" content={kurumsalBg} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={kurumsalBg} mainTitle={"Kurumsal"} subTitle={"Anasayfa / Kurumsal"} />

            <KurumsalContent />



            <MainFooter visibility={'visible'}></MainFooter>

        </StyledKurumsalContainer>

    )
}

export default Kurumsal


const StyledKurumsalContainer = styled.div`
    width: 100%;
    flex-direction: column;
`

