// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper {
  width: 100%;
  height: 2.5rem;
  border: none;
  border-radius: 10px;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  background-color: white;
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 70%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
}
`, "",{"version":3,"sources":["webpack://./src/Public/components/search/SearchBar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,4BAA4B;EAC5B,uBAAuB;EACvB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".input-wrapper {\n  width: 100%;\n  height: 2.5rem;\n  border: none;\n  border-radius: 10px;\n  padding: 0 15px;\n  box-shadow: 0px 0px 8px #ddd;\n  background-color: white;\n  display: flex;\n  align-items: center;\n}\n\ninput {\n  background-color: transparent;\n  border: none;\n  height: 100%;\n  font-size: 1.25rem;\n  width: 70%;\n  margin-left: 5px;\n}\n\ninput:focus {\n  outline: none;\n}\n\n#search-icon {\n  color: royalblue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
