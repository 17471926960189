// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 480px) {
  #col-administrative {
    margin-top: 10px; }
  .parent-header {
    display: flex;
    flex-direction: column; }
  .div-btn {
    padding-top: 10px; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Course/UpdateCourse/EditCourseProgram/EditCourseProgram.scss"],"names":[],"mappings":"AACA;EACE;IACF,gBAAgB,EAAA;EAEhB;IACE,aAAa;IACb,sBAAsB,EAAA;EAExB;IACE,iBAAiB,EAAA,EAClB","sourcesContent":["\n@media screen and (max-width:480px) {\n  #col-administrative{\nmargin-top: 10px;\n}  \n.parent-header{\n  display: flex;\n  flex-direction: column;\n}\n.div-btn{\n  padding-top: 10px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
