import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import MeetingDashboard from '../../Components/MeetingDashboard/MeetingDashboard'

const MeetingPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='' header={"Toplantı"} visible={false} >
                <MeetingDashboard />
            </TablePageContainer>
        </div>
    )
}

export default MeetingPage