import styled from "styled-components";

export const CourseDetailTitleContainer = styled.div`
        border: 1px dashed gray;
    border-radius: 10px;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    .course_title {
        text-transform: capitalize;
        margin: 0 !important;
            

        @media screen and (max-width:500px) {
            font-size: 14px;
        }
    }

    
    .course_detail_screen_course_name {
        font-size: 18px;
        text-transform: capitalize;
        font-weight: bold;
        @media screen and (max-width:400px) {
            font-size: small;
        }

        @media screen and (max-width:350px) {
            .course_detail_screen_course_name {
                 font-size: x-small;
                 }
            }
    }



`