import React from 'react'
import TablePageContainer from '../../../Components/Common/TablePageContainer'
import StudentExamDashboard from '../../../Components/Student/StudentExam/StudentExamDashboard'

const StudentExamPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer header={"Sınavlarım"} visible={false}  >
                <StudentExamDashboard />
            </TablePageContainer>
        </div>
    )
}

export default StudentExamPage