import { axiosInstance } from "./axiosInstance";

const createExamApi = (body) => axiosInstance.post("/exams/add", body)


const createExamQuestionApi = (file, examId, point, question) => axiosInstance.post(`/ExamQuestions/Add?examId=${examId}&point=${point}&question=${question}`, file, {
    headers: {
        "Content-Type": "multipart/form-data"
    }
})

const createExamAnswerApi = (body) => axiosInstance.post("/ExamAnswers/Add", body)

const getExamListApi = (body, type) => axiosInstance.post(`/Exams/GetAll?type=${type}`, body)

const getExamDetailByIdApi = (id) => axiosInstance.get(`/Exams/GetById/${id}`)

const ExamQuestionsUpdateApi = (id, file, examId, point, question) => {
    if (file) {
        return axiosInstance.put(`/ExamQuestions/update?examId=${examId}&point=${point}&question=${question}&id=${id}`, file)
    }
    return axiosInstance.put(`/ExamQuestions/update?examId=${examId}&point=${point}&question=${question}&id=${id}`)
}

const updateExamAnswerApi = (body) => {
    return axiosInstance.put("/ExamAnswers/update", body)
}

const deleteQuestionApi = (id) => {
    return axiosInstance.delete(`/examQuestions/Delete/${id}`)
}


const examGetAllForStudentApi = (body) => {
    return axiosInstance.post("/exams/getAllForStudent", body)
}

const deleteExamApi = (id) => {
    return axiosInstance.delete(`/exams/delete/${id}`)
}

const examUpdateApi = (body) => {
    return axiosInstance.put("/exams/update", body)
}


const studentGetByExamIdApi = (id) => {
    return axiosInstance.get(`/ExamQuestions/GetByExamId/${id}`)
}

const examTestResultApi = (body) => {
    return axiosInstance.post("/ExamResults/Add", body)
}

const examYaziliResultApi = (body) => {
    return axiosInstance.post("/examWrittenAnswers/Add", body)
}

const getResultByIdForStudent = (id) => {
    return axiosInstance.get(`/Exams/GetResultByIdForStudent/${id}`)
}

const getAllForExamIdApi = (id) => {
    return axiosInstance.get(`/ExamResults/GetAllForExamId/${id}`)
}

const examWrittenAnswerApi = (body) => {
    return axiosInstance.put("/ExamWrittenAnswers/Update", body)
}


export {
    createExamApi,
    createExamQuestionApi,
    createExamAnswerApi,
    getExamListApi,
    getExamDetailByIdApi,
    ExamQuestionsUpdateApi,
    updateExamAnswerApi,
    deleteQuestionApi,
    examGetAllForStudentApi,
    deleteExamApi,
    examUpdateApi,
    studentGetByExamIdApi,
    examTestResultApi,
    examYaziliResultApi,
    getResultByIdForStudent,
    getAllForExamIdApi,
    examWrittenAnswerApi
}