import React from 'react'
import ExamManagementDashboard from '../../Components/ExamManagement/ExamManagementDashboard'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import styled from 'styled-components'
const TeacherExamPage = () => {
  return (
    <div className='page-content'>
      <TablePageContainer header={"Sınav"} visible={false} >
        <StyledContainer>
          <ExamManagementDashboard />
        </StyledContainer>

      </TablePageContainer>
    </div>
  )
}

const StyledContainer = styled.div`
  padding: 15px;

  @media screen and (max-width:600px) {
    padding: 5px;
  }
`

export default TeacherExamPage