import React from 'react'
import styled from 'styled-components'

const FilterElement = ({
    onlineOrOffline,
    setOnlineOrOffline,
    setSelectedcategory,
    categorieList,
    selectedCategory
}) => {
    return (
        <FilterSection>
            <div className='course_type_wrapper'>
                <p className='course_type_title'   >
                    Eğitim Tipi
                </p>
                <p className='course_type_element' style={{ border: onlineOrOffline == "" ? "2px solid blue" : "", zIndex: 10000 }} onClick={() => {
                    setOnlineOrOffline("")

                }}  >
                    Hepsi
                </p>
                <p className='course_type_element' style={{ border: onlineOrOffline == "offline" ? "2px solid blue" : "" }} onClick={() => {
                    setOnlineOrOffline("offline")
                }} >
                    Videolu Eğitim
                </p>
                <p className='course_type_element' style={{ border: onlineOrOffline == "online" ? "2px solid blue" : "" }} onClick={() => {
                    setOnlineOrOffline("online")
                }} >
                    Canlı Eğitim
                </p>
            </div>
            <div className='course_category_wrapper' >
                <p className='course_category_title' >
                    Kategori
                </p>
                <div className='category_element_container' >
                    {
                        categorieList.map(el => {
                            return (
                                <p key={`${el}`} className='course_category_element' style={{
                                    border: el == selectedCategory ? "2px solid blue" : "",
                                    cursor: "pointer"
                                }} onClick={() => {
                                    setSelectedcategory(el)
                                }} >
                                    {el}
                                </p>
                            )
                        })
                    }
                </div>

            </div>
        </FilterSection>
    )
}

export default FilterElement



const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 100px;
  width: 100%;
  border: 2px solid #CACAF4;
  border-radius: 5px;
  padding: 20px;
  row-gap: 30px;
  /* height: calc(100vh - 100px);  */
    height: max-content;
    padding-bottom: 60px;

    @media screen  and (max-width:600px)  {
        display: none;
    }

  .course_type_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    .course_type_title{
        border-bottom: 1px solid gray;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 5px;
        color: #876262;
    }
    .course_type_element{
      border: 2px solid #DDDDDD;
      padding: 7px;
      font-weight: bold;
      border-radius: 6px;
      cursor: pointer;
      transition: .3s all;

      &:hover{
        
      }
    }
  }

  .course_category_wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .course_category_title{
        border-bottom: 1px solid gray;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 5px;
        color: #876262;
    }

    .category_element_container{
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        height: 300px;
        overflow-y: scroll;
        scrollbar-width: thin; /* Firefox */
        scrollbar-color: #CCCCCC transparent; /* Firefox */

        .course_category_element{
            border: 2px solid #DDDDDD;
            padding: 7px;
            font-weight: bold;
            border-radius: 6px;

            .category_element_container::-webkit-scrollbar {
                width: 8px; /* Scrollbar genişliğini belirler */
            }

            .category_element_container::-webkit-scrollbar-thumb {
                background-color: #CCCCCC; /* Scrollbar'ın rengi */
                border-radius: 4px; /* Scrollbar köşelerini yuvarlar */
            }

         }
    }
    
  }
`