import { axiosInstance } from "./axiosInstance";


const createCategoryApi = (body) => axiosInstance.post("/categories/add", body)

const getAllCategories = (body) => axiosInstance.post("/categories/getall", body)

const updateCategoryApi = (body) => axiosInstance.put("/categories/update", body)

export {
    createCategoryApi,
    getAllCategories,
    updateCategoryApi
}