import React from 'react'
import TablePageContainer from '../../../Components/Common/TablePageContainer'
import StudentMessage from '../../../Components/Course/StudentCourse/StudentMessage'

const StudentMessagePage = () => {
  return (
    <div className='page-content' >
        <TablePageContainer title='Mesajlar' header={"Mesajlar"} visible={false}  >
            <StudentMessage />
        </TablePageContainer>
    </div>
  )
}

export default StudentMessagePage