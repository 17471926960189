import { Button, DatePicker, Input, Row, TimePicker, Modal as AntModal } from 'antd'
import React, { useRef, useState } from 'react'
import { FaFileArchive, FaSave } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'

const TeacherAddCourseWorkModal = ({ modalInfo, setModalInfo, refreshMethod }) => {
    const fileRef = useRef()
    const [selectedFile, setSelectedFile] = useState()
    const [courseWorkData, setCourseWorkData] = useState({
        name: "",
        startTime: "",
        endTime: ""
    })
    const [loading, setLoading] = useState(false)
    const validateForm = () => {
        const currentTime = new Date()
        if (courseWorkData.name == "") {
            toast.info("İsim boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (courseWorkData.startTime == "") {
            toast.info("Başlangıç zamanı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData?.startTime) < currentTime) {
            toast.info("Başlangıç zamanı şuanki zamandan geçmişte olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (courseWorkData.endTime == "") {
            toast.info("Bitiş zamanı boş bırakılamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData?.endTime) < currentTime) {
            toast.info("Bitiş zamanı şuanki zamandan geçmişte olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        else if (new Date(courseWorkData.startTime) > new Date(courseWorkData?.endTime)) {
            toast.info("Başlangıç zamanı bitiş zamanından büyük olamaz", {
                autoClose: 2000,
                position: "top-center"
            })
            return false
        }
        return true
    }

    const saveCourseWork = async () => {

        if (validateForm()) {
            AntModal.confirm({
                title: "Kaydetmek istediğinize emin misiniz?",
                okText: "Eminim",
                onCancel: "İptal",
                onOk: async () => {
                    try {
                        setLoading(true)
                        const formData = new FormData()
                        formData.append("formFile", selectedFile)
                        const startTime = new Date(courseWorkData?.startTime).toUTCString()
                        const endTime = new Date(courseWorkData?.endTime).toUTCString()
                        await axiosInstance.post(`/courseWorks/add?courseCalendarId=${modalInfo.calendarId}&name=${courseWorkData.name}&startTime=${startTime}&endTime=${endTime}`, formData)

                        toast.success("Ödev kayıt edildi", {
                            position: "top-center",
                            autoClose: 2500
                        })
                        setModalInfo({
                            calendarId: "",
                            visible: false
                        })
                        setCourseWorkData({
                            endTime: "",
                            name: "",
                            startTime: ""
                        })
                        refreshMethod()
                    }
                    catch (err) {

                    }
                    finally {
                        setLoading(false)
                    }
                }
            })


        }


    }


    return (
        <Modal isOpen={modalInfo.visible} centered   >
            <StyledHeader>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}>
                    <p>
                        Ödev Ekle
                    </p>
                    <Button type='primary' danger onClick={() => {
                        setModalInfo({
                            visible: false,
                            calendarId: ""
                        })
                        setCourseWorkData({
                            name: "",
                            startTime: "",
                            endTime: "",
                        })
                        setSelectedFile()
                    }} >
                        Kapat
                    </Button>
                </div>
            </StyledHeader>
            <ModalBody>
                <input type="file" ref={fileRef} onChange={(event) => {
                    setSelectedFile(event.target.files[0])
                }} style={{
                    display: "none"
                }} />
                <StyledContentContainer>
                    <StyledInputItem>
                        <p>
                            İsim
                        </p>
                        <Input placeholder='İsim' style={{
                            height: "35px"
                        }}
                            onChange={(e) => {
                                setCourseWorkData({
                                    ...courseWorkData,
                                    name: e.target.value
                                })
                            }}

                        />
                    </StyledInputItem>
                    <StyledInputItem>
                        <p>
                            Başlangıç Zamanı
                        </p>
                        <DatePicker
                            placeholder='Başlangıç saati seçiniz'
                            allowClear={true}
                            showTime
                            onChange={(value, dateString) => {
                                setCourseWorkData({
                                    ...courseWorkData,
                                    startTime: dateString
                                })
                            }}
                            style={{
                                height: "35px"
                            }} />
                    </StyledInputItem>
                    <StyledInputItem>
                        <p>
                            Bitiş Zamanı
                        </p>
                        <DatePicker
                            placeholder='Bitiş saati seçiniz'
                            allowClear={true}
                            showTime
                            style={{
                                height: "35px"
                            }}
                            onChange={(value, dateString) => {
                                setCourseWorkData({
                                    ...courseWorkData,
                                    endTime: dateString
                                })
                            }}
                        />
                    </StyledInputItem>
                    <StyledInputItem>
                        <p>
                            Seçilen Dosya
                        </p>
                        {
                            selectedFile ? (
                                <div style={{ display: "flex", alignItems: "center", width: "100%", border: "1px dashed black", padding: "10px", borderRadius: 5 }} >
                                    <FaFileArchive size={40} color='gray' />
                                    <h6 style={{ marginLeft: "10px", fontWeight: "bold", fontSize: "12px" }} >
                                        {selectedFile?.name.length > 40 ? `${selectedFile?.name.substring(0, 40)}...` : selectedFile?.name}
                                    </h6>
                                </div>
                            ) : (
                                <h5 style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center", width: "100%" }} >
                                    Henüz dosya seçilmedi
                                </h5>
                            )
                        }
                    </StyledInputItem>
                </StyledContentContainer>

            </ModalBody>
            <ModalFooter>
                <StyledFooterContainer>
                    <Button style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px"
                    }} type='primary' onClick={() => {
                        fileRef.current.click()
                    }} >
                        <FaFileArchive /> Dosya Seç
                    </Button>

                    <Button type='primary' loading={loading} onClick={() => {
                        saveCourseWork()
                    }} style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "7px"
                    }} >
                        Kaydet <FaSave />
                    </Button>

                </StyledFooterContainer>

            </ModalFooter>
        </Modal>
    )
}

export default TeacherAddCourseWorkModal

const StyledFooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledInputItem = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

`

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
`

const StyledHeader = styled(ModalHeader)`
    h5 {
        width: 100%;
    }
`