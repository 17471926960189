import { Helmet } from "react-helmet-async"
import { Container, } from "reactstrap"
import MainFooter from '../../components/mainFooter/MainFooter'
import "./index.scss"
import { useTranslation } from "react-i18next";
import MainNavbar from '../../components/navbar/MainNavbar';
import FontHead from '../../components/fontHead/FontHead';
import i1 from "../../../assets/images/i1.png"
import i2 from "../../../assets/images/i2.png"
import i3 from "../../../assets/images/i3.png"
import i4 from "../../../assets/images/i4.png"
import i5 from "../../../assets/images/i5.png"
import i6 from "../../../assets/images/i6.png"
import EKitapCardComponent from "./EKitapCardComponent";
import { useEffect, useState } from "react";
import styled from "styled-components";
import TopBgElement from "../TopBgElement/TopBgElement";
import ekitapbg from "../../../assets/images/ekitapbg.png"
import { Alert, Card, Pagination, Row, Col } from "antd";
import OtherCourseSlider from "../../components/OtherCourseSlider/OtherCourseSlider";
import { axiosInstance } from "../../../api/axiosInstance";

const { Meta } = Card;

const Ekitap = () => {
  const { t } = useTranslation();
  const [bookList, setEbookList] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 9

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  const getAllEbooks = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.post("/electronicbooks/getall", {
        page: 0,
        pageSize: 1
      })
      const responsev2 = await axiosInstance.post("/electronicbooks/getall", {
        page: 0,
        pageSize: response.data.count
      })

      setEbookList(responsev2.data.items)

    }
    catch (err) {

    }
    finally {
      setLoading(false)
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const indexOfLastBook = currentPage * pageSize
  const indexOfFirstBook = indexOfLastBook - pageSize
  const currentBooks = bookList.slice(indexOfFirstBook, indexOfLastBook)

  useEffect(() => {
    getAllEbooks()
  }, [])

  return (
    <StyledContainer>

      <Helmet>
        <meta name="description" content="KUZEM E-kitap arşivimize göz atın. Anasayfa / E-Kitap sayfamız güncellenmektedir." />
        <meta name="keywords" content="E-kitap, Kitap, Arşiv, Eğitim" />
        <meta property="og:description" content="KUZEM E-kitap arşivimize göz atın. Anasayfa / E-Kitap sayfamız güncellenmektedir." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.kuzem.org/ekitap" />
        <meta property="og:image" content={ekitapbg} />

      </Helmet>
      <MainNavbar />
      <TopBgElement img={ekitapbg} mainTitle={"E-Kitap"} subTitle={"Anasayfa / E-Kitap"} />

      <EKitapCardContainer >
        <MainContentContainer>
          {
            currentBooks.length == 0 && (
              <Alert type="info" message="Bu alan güncellenmektedir" />
            )
          }


          <Row gutter={[24, 24]}>
            {currentBooks.map((book) => (
              <Col xs={24} sm={6} md={6} key={book.id}>
                <Card
                  hoverable
                  loading={loading}
                  onClick={() => window.open(book.fileUrl, '_blank')}
                  cover={
                    <img
                      alt={book.name}
                      src={ekitapbg}
                      style={{ height: 200, objectFit: 'cover' }}
                    />
                  }
                >
                  <Meta
                    title={book.name}
                    description={book.category.name}
                  />
                </Card>
              </Col>
            ))}
          </Row>

          <PaginationWrapper>
            <Pagination
              current={currentPage}
              total={bookList.length}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </PaginationWrapper>

          <OtherCourseWrapper>

            <OtherCourseSlider />
          </OtherCourseWrapper>

        </MainContentContainer>
      </EKitapCardContainer>
      <MainFooter visibility={"visible"} />
    </StyledContainer>


  )
}

export default Ekitap

const StyledContainer = styled.div`
    width: 100%;
    flex-direction: column;
`

const EKitapCardContainer = styled.div`
    padding: 40px 120px;
    margin: 40px 0 80px 0;
    row-gap: 50px;
    column-gap: 30px;

    @media screen and (max-width:1100px) {
        padding: 20px 40px;
        margin: 50px 0;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
        grid-template-columns: 1fr ;
    }
`

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`

const OtherCourseWrapper = styled.div`
  display: flex;
  flex-direction: column;

`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;