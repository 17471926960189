import React, { useState } from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import ExamUpdateDashboard from '../../Components/ExamUpdate/ExamUpdateDashboard'
import styled from 'styled-components'

const ExamUpdatePage = () => {
    const [header, setHeader] = useState()
    return (
        <div className='page-content' >
            <TablePageContainer header={header ?? "Sınav Düzenle"} visible={false}  >
                <ExamContainer>
                    <ExamUpdateDashboard setHeader={setHeader} />
                </ExamContainer>

            </TablePageContainer>
        </div>
    )
}


const ExamContainer = styled.div`
    padding: 20px;

    @media screen  and (max-width:500px) {
        padding: 10px;
    }
`


export default ExamUpdatePage