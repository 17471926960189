import React, { FC, useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from "yup"
import { Col, FormFeedback, Input, Label, Row, Form } from 'reactstrap'
import { toast } from 'react-toastify'
import { GetAllBranch, getAllBranch } from '../../api/Branch'
import { UserContext } from '../../context/user'
import { createUserApi } from '../../api/UserApi'
import { Select, Button as AntButton, Modal as AntModal } from 'antd'
import "./index.scss"
import styled from 'styled-components'
const today = new Date();
const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
const eightyYearsAgo = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate());

function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 arasında olduğu için +1 eklenir
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}


const CreateTeacherForm = () => {
    const [state, dispatch] = useContext(UserContext)
    const [branches, setBranch] = useState([])
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            birthDate: "",
            email: "",
            phone: "",
            tc: "",
            branch: []
        },
        validationSchema: yup.object({
            email: yup.string().email().required(),
            phone: yup.string()
                .matches(/^(\d{10})$/, "Geçerli bir Türkiye telefon numarası girin") // Türkiye telefon numarası formatı (Başında 0 ve 10 rakam)
                .required("Telefon numarası boş bırakılamaz"),
            /* branch: yup.string().required("Branş seçimi zorunludur"), */
            tc: yup
                .string()
                .length(11, "T.C. Kimlik Numarası 11 haneli olmalıdır.")
                .matches(/^[0-9]+$/, "T.C. Kimlik Numarası sadece rakamlardan oluşmalıdır.")
                .required("T.C. Kimlik Numarası boş bırakılamaz."),
            birthDate: yup.date().max(eighteenYearsAgo, 'You must be at least 18 years old.').min(eightyYearsAgo, 'You must be at most 80 years old.').required("Doğum Tarihi Seçiniz"),
            branch: yup.array().min(1, "En az bir adet seçmelisiniz")
        }),
        onSubmit: async (value, { resetForm }) => {

            AntModal.confirm({
                title: "Bu eğitmeni eklemek istediğinizden emin misiniz?",
                okText: "Eminim",
                cancelText: "iptal",
                onOk: async () => {
                    try {
                        setLoading(true)
                        const { birthDate, firstName, lastName, tc, phone, email, ...rest } = value
                    
                        await createUserApi({
                            RoleId: 2,
                            tc: tc,
                            phone: phone,
                            email: email,
                            birthDate: formatDate(new Date(birthDate)),
                            branches: value.branch.map(el => {
                                return {
                                    branchId: el
                                }
                            })
                        })
                        toast.success("Eğitmen kayıt edildi", {
                            autoClose: 1500
                        })
                        resetForm()
                    }
                    catch (err) {
                       
                    }
                    finally {
                        setLoading(false)
                    }
                }
            })


        }
    })

    const fetchBranches = async () => {
        try {
            const branches = await getAllBranch({
                page: 0,
                pageSize: 100
            });
       
            setBranch(branches.data.items);
        } catch (err) {
            
        }
    };


    useEffect(() => {
        fetchBranches()
    }, [])

    return (
        <div className='' >
            <Form onSubmit={formik.handleSubmit}>
                <Row>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="phonenumberInput" className="form-label">
                                Tc No
                            </Label>
                            <Input style={{ marginLeft: 0 }} type="text" className="form-control "
                                placeholder='Tc No'
                                name='tc'
                                value={formik.values.tc}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched.tc && formik.errors.tc ? true : false
                                }
                            />
                            {formik.touched.tc && formik.errors.tc ? (
                                <FormFeedback type="invalid"><div>{formik.errors.tc}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="phonenumberInput" className="form-label">
                                Doğum Tarihi
                            </Label>
                            <StyledInput

                                name="birthDate"
                                type="date"
                                placeholde1r="Doğum Tarihi"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.birthDate}
                                invalid={
                                    formik.touched.birthDate && formik.errors.birthDate ? true : false
                                }
                            />
                            {formik.touched.birthDate && formik.errors.birthDate ? (
                                <FormFeedback type="invalid"><div>{formik.errors.birthDate}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="phonenumberInput" className="form-label">
                                Telefon
                            </Label>
                            <StyledInput
                                type="text" className="form-control"
                                placeholder='Phone'
                                onChange={formik.handleChange}
                                id="phone"
                                onBlur={formik.handleBlur}
                                name='phone'
                                invalid={
                                    formik.touched.phone && formik.errors.phone ? true : false
                                }
                                value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <FormFeedback type="invalid"><div>{formik.errors.phone}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="mb-3">
                            <Label htmlFor="emailInput" className="form-label ">Email</Label>
                            <StyledInput type="email" className="form-control"
                                name='email'
                                placeholder='email'
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                    formik.touched.email && formik.errors.email ? true : false
                                }
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <FormFeedback type="invalid"><div>{formik.errors.email}</div></FormFeedback>
                            ) : null}
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="mb-3">
                            <Label htmlFor="emailInput" className="form-label">
                                Branş
                            </Label>
                            <StyledSelect
                                mode='multiple'
                                allowClear
                                showSearch
                                options={branches.map(el => {
                                    return {
                                        value: el.id,
                                        label: el.name
                                    }
                                })}
                                filterOption={(input, option) =>
                                    option?.label.toLowerCase().includes(input.toLowerCase()) // Arama için filtreleme
                                }
                                onChange={(value) => {
                                    formik.setFieldValue('branch', value); // Formik değerini güncelle
                                }}
                                value={formik.values.branch}
                            />
                            {formik.touched.branch && formik.errors.branch ? (
                                <StyledErrorMessage>
                                    {formik.errors.branch}
                                </StyledErrorMessage>
                            ) : null}
                        </div>
                    </Col>


                    <Col lg={12}>
                        <div className="d-flex justify-content-end">
                            <StyledButton loading={loading} type='primary' size='middle' onClick={() => {
                                formik.handleSubmit()
                            }} >
                                Ekle
                            </StyledButton>
                        </div>
                    </Col>



                </Row>
            </Form>
        </div>

    )
}

export default CreateTeacherForm

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`
const StyledInput = styled(Input)`
    height: 35px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`

const StyledButton = styled(AntButton)`
    padding: 0 40px;
`