import { axiosInstance } from "./axiosInstance";


const sendMessageApi = (data) => axiosInstance.post("/Notifications/Add", data)

const getAllSendNotifications = (data) => axiosInstance.post("/Notifications/GetAllSentNotifications", data)

const getAllReceivedNotification = (data) => axiosInstance.post("/Notifications/GetAllReceivedNotifications", data)

export {
    sendMessageApi,
    getAllSendNotifications,
    getAllReceivedNotification
}