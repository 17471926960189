// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guncel_page_container {
  padding: 20px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 200px; }
  @media screen and (max-width: 1100px) {
    .guncel_page_container {
      padding: 20px 40px;
      grid-template-columns: 1fr 1fr; } }
  @media screen and (max-width: 500px) {
    .guncel_page_container {
      grid-template-columns: 1fr;
      padding: 20px 20px; } }
`, "",{"version":3,"sources":["webpack://./src/Public/pages/guncel/index.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,aAAa;EACb,kCAAoC;EACpC,oBAAoB,EAAA;EAEpB;IANJ;MAOQ,kBAAkB;MAClB,8BAA8B,EAAA,EAQrC;EAJG;IAZJ;MAaQ,0BAA2B;MAC3B,kBAAkB,EAAA,EAEzB","sourcesContent":[".guncel_page_container{\n    padding: 20px 120px;\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr  ;\n    margin-bottom: 200px;\n\n    @media screen and (max-width:1100px) {\n        padding: 20px 40px;\n        grid-template-columns: 1fr 1fr;\n    }\n\n\n    @media screen and (max-width:500px) {\n        grid-template-columns: 1fr ;\n        padding: 20px 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
