import React, { useEffect, useState } from 'react'
import { Card, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import "./Rating.scss"
import { FaStar, FaRegStar } from 'react-icons/fa';
import Rating from 'react-rating';
import fausseChart from "../../../src/assets/images/fausseChart.png";
import Progressbar from "./Progress_Bar";
import { GetCalendarForAllRoles } from '../../api/Course';
import { getReviewsApi } from '../../api/dashboard';
import { Select } from 'antd';
import styled from 'styled-components';


const MyRating = () => {
  const [courseList, setCourseList] = useState([])
  const [selectedCourseData, setSelectedCourseData] = useState()
  const [selectedId, setSelectedId] = useState()
  const getCourseList = async () => {
    try {
      const response = await GetCalendarForAllRoles({
        page: 0,
        pageSize: 50
      })
      const responsev2 = await GetCalendarForAllRoles({
        page: 0,
        pageSize: response.data.count
      })
      
      setCourseList(responsev2.data.items)
      setSelectedId(response.data.items[0]?.id)
      const ratingResponse = await getReviewsApi(response.data.items[0]["id"])
  
      setSelectedCourseData(ratingResponse.data)

    }
    catch (Err) {

    }
  }

  const RatingSectionContainer = styled(Col)`
  padding-left: 30px;
    @media screen and (max-width:500px) {
        justify-content: center;
        display: flex;
        margin-bottom: 20px;
    }
  `



  useEffect(() => {
    getCourseList()
  }, [])

  return (
    <React.Fragment className="parent-element">
      
      <Card style={{ border: "none" }} >
        <CardHeader className="align-items-center d-flex " style={{ border: "none" }} >
          <Row style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>

            <Col xs={12} sm={6}><h4 className="card-title mb-0 flex-grow-1" > <b>Genel Kurs Değerlendirmesi</b> </h4></Col>
            <Col xs={12} sm={6} className="flex-shrink-0 select-col">

              <Select
                showSearch
                value={selectedId}
                style={{ width: '200px' }}
                placeholder="Kurslar"
                onChange={async (e) => {
                  try {
                 
                    const ratingResponse = await getReviewsApi(e)
                    setSelectedCourseData(ratingResponse.data)
                    setSelectedId(e)
                  }
                  catch (err) {

                  }
                }}
                options={courseList?.map(el => ({
                  label: el?.name,
                  value: el?.id
                }))}
              />
            </Col>

          </Row>

        </CardHeader>
      </Card>


      <Row>
        <RatingSectionContainer md={5} lg={3}  >
          <div className='rating-moyenne '>
            <h2 style={{ display: "flex", justifyContent: "center", fontSize: "48px" }}>
              {isNaN(parseInt(selectedCourseData?.average)) ? 0 : parseInt(selectedCourseData?.average) }
            </h2>
            <div style={{ maxHeight: "30px", maxWidth: "100%", display: "flex", justifyContent: "center" }}>
              <Rating
                initialRating={selectedCourseData?.average}
                emptySymbol={<FaRegStar color="#ffc107" size={20} />}
                fullSymbol={<FaStar color="#ffc107" size={20} />}
                readonly
/>
            </div>
            <p style={{ fontWeight: "700", fontSize: "14px", display: "flex", justifyContent: "center", paddingTop: "15px" }}>Genel Ortalama</p>
          </div>

        </RatingSectionContainer>
        <Col md={7} lg={9} style={{ display: "flex", alignItems: "flex-end", justifyContent: "center",padding:"0"}} className='img-column'>
          <img src={fausseChart} alt='info' className='rating-chart' />
        </Col>

      </Row>



      {/* ------------------------yıldızlarrrr ------------------*/}

      <Row className='rating-detail'>
        <Col lg={4} style={{ padding: "0" }}>
          <div className='starandtext'>
            <Rating
              initialRating={5}
              emptySymbol={<FaRegStar color="#ffc107" size={20} />}
              fullSymbol={<FaStar color="#ffc107" size={20} />}
              readonly
            />
            <p className='star-number'  >5 Yıldız</p>
          </div>
        </Col>
        <Col lg={8} className='progress-column'>
          <Progressbar
            bgcolor="#FD8E1F"
            progress={selectedCourseData?.fivePointPercentage}
            height={10}
            text={""}
            text2={""}

          />
        </Col>
      </Row>





      <Row className='rating-detail' >
        <Col lg={4} style={{ padding: "0" }}>
          <div className='starandtext'>
            <Rating
              initialRating={4}
              emptySymbol={<FaRegStar color="#ffc107" size={20} />}
              fullSymbol={<FaStar color="#ffc107" size={20} />}
              readonly
            />
            <p className='star-number'  >4 Yıldız</p>
          </div>
        </Col>
        <Col lg={8} className='progress-column'>
          <Progressbar
            bgcolor="#FD8E1F"
            progress={selectedCourseData?.fourPointPercentage}
            height={10}
            text={""}
            text2={""}

          />
        </Col>
      </Row>






      <Row className='rating-detail' >
        <Col lg={4} style={{ padding: "0" }}>
          <div className='starandtext'>
            <Rating
              initialRating={3}
              emptySymbol={<FaRegStar color="#ffc107" size={20} />}
              fullSymbol={<FaStar color="#ffc107" size={20} />}
              readonly
            />
            <p className='star-number'  >3 Yıldız</p>
          </div>
        </Col>
        <Col lg={8} className='progress-column'>
          <Progressbar
            bgcolor="#FD8E1F"
            progress={selectedCourseData?.threePointPercentage}
            height={10}
            text={""}
            text2={""}

          />
        </Col>
      </Row>




      <Row className='rating-detail' >
        <Col lg={4} style={{ padding: "0" }}>
          <div className='starandtext'>
            <Rating
              initialRating={2}
              emptySymbol={<FaRegStar color="#ffc107" size={20} />}
              fullSymbol={<FaStar color="#ffc107" size={20} />}
              readonly
            />
            <p className='star-number'  >2 Yıldız</p>
          </div>
        </Col>
        <Col lg={8} className='progress-column'>
          <Progressbar
            bgcolor="#FD8E1F"
            progress={selectedCourseData?.twoPointPercentage}
            height={10}
            text={""}
            text2={""}

          />
        </Col>
      </Row>




      <Row className='rating-detail' >
        <Col lg={4} style={{ padding: "0" }}>
          <div className='starandtext'>
            <Rating
              initialRating={1}
              emptySymbol={<FaRegStar color="#ffc107" size={20} />}
              fullSymbol={<FaStar color="#ffc107" size={20} />}
              readonly
            />
            <p className='star-number'  >1 Yıldız</p>
          </div>
        </Col>
        <Col lg={8} className='progress-column'>
          <Progressbar
            bgcolor="#FD8E1F"
            progress={selectedCourseData?.onePointPercentage}
            height={10}
            text={""}
            text2={""}

          />
        </Col>
      </Row>









    </React.Fragment>
  )
}

export default MyRating
