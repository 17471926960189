import React, { useEffect, useMemo, useState } from 'react'
import { CourseRegisterGetAll, CourseRegisterStudentApi, GetCalendarForAllRoles, getDetailCourseApi } from '../../../api/Course'
import { Collapse, Select as AntSelect, Alert, Button, Skeleton, Row, Col, Table } from 'antd'
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import trLocale from "date-fns/locale/tr"
import { SlArrowDown } from 'react-icons/sl'
import test from "../../../assets/images/b1.png"
import Accordion from "react-bootstrap/Accordion"
import CustomAccordionHeader from '../Common/CustomAccordionHeader'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { axiosInstance } from '../../../api/axiosInstance'
import { toast } from 'react-toastify'
import { formatInTimeZone } from 'date-fns-tz'
import { tr } from "date-fns/locale"

const StudentCourseTable = () => {
    const [loading, setLoading] = useState(false)
    const [absenceLoading, setAbsenceLoading] = useState(false)
    const [courseType, setCourseType] = useState(0)
    const [courseData, setCourseData] = useState([])
    const navigate = useNavigate()
    const [modalInfo, setModalInfo] = useState({
        visible: false
    })
    const [absenceData, setAbsenceData] = useState()
    const convertTrFormat = (date) => {
        const tarih = new Date(date);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        const turkishDate = new Intl.DateTimeFormat('tr-TR', options).format(tarih);

        return turkishDate;
    }

    const convertTime = (time) => {
        /* const parsedDate = parseISO(time); */
        /* const formattedDate = format(time, "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale }); */
        const formattedDate = formatInTimeZone(time, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: tr });
        return formattedDate
    }

    const getStudentCourse = async () => {

        try {
            const response = await GetCalendarForAllRoles({
                page: 0,
                pageSize: 10
            })
            const responsev2 = await GetCalendarForAllRoles({
                page: 0,
                pageSize: response.data.count
            })


            setCourseData(responsev2.data.items)

        }
        catch (err) {

        }
        finally {

        }

    }

    const mainData = useMemo(() => {
        if (courseType === 0) {
            return courseData;
        } else if (courseType === 1) {
            return courseData?.filter(el => el?.type === "Offline");
        } else {
            return courseData?.filter(el => el?.type === "Online");
        }
    }, [courseData, courseType]);


    useEffect(() => {
        getStudentCourse()
    }, [])

    const getStudentAbsence = async (id) => {
        try {
            setAbsenceLoading(true)
            const response = await axiosInstance.get(`/Absences/GetStudentResultByCourseId/${id}`)
            console.log("respox", response.data)
            setAbsenceData(response.data)
            setModalInfo({
                visible: true
            })
        }
        catch (err) {
            console.log("err", err)
        }
        finally {
            setAbsenceLoading(false)
        }
    }

    const FieldElement = () => {
        return (
            <div>

            </div>
        )
    }


    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "30px" }} >
                <Skeleton />
            </div>
        )
    }

    return (
        <>
            <StyledHeader>
                <StyledAntSelect
                    value={courseType}
                    onChange={(e) => {
                        setCourseType(e)
                    }}
                    options={[
                        {
                            label: "Hepsi",
                            value: 0,
                        },
                        {
                            "label": "Video Eğitim",
                            "value": 1
                        },
                        {
                            "label": "Canlı Eğitim",
                            "value": 2
                        }
                    ]}
                >
                </StyledAntSelect>
            </StyledHeader>
            {
                mainData.length !== 0 ? mainData?.map((el, index) => {

                    return (
                        <Collapse key={`${index}`} style={{ marginTop: "30px" }} className='course_collapse_panel' expandIcon={({ isActive }) => {
                            return null
                        }} >
                            <Collapse.Panel header={CustomAccordionHeader(el, navigate, absenceLoading, (id) => {
                                getStudentAbsence(id)
                            })} key={index}>
                                {
                                    el.type == "Online" ? (
                                        <Row
                                            gutter={[
                                                {
                                                    md: 8
                                                },
                                                {
                                                    md: 8
                                                }
                                            ]}
                                        >

                                            <Col lg={12} xs={24} >
                                                <div className='course_card_item ' >
                                                    <Label className='course_card_item_title'>
                                                        Kurs Başlangıç Tarihi
                                                    </Label>
                                                    <p>
                                                        {convertTime(el.startDate)}
                                                    </p>
                                                </div>
                                            </Col>

                                            <Col lg={12} xs={24} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Bitiş Tarihi
                                                    </Label>
                                                    <p>
                                                        {convertTime(el.endDate)}
                                                    </p>
                                                </div>

                                            </Col>
                                            <Col lg={8} xs={24}  >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Eğitmen
                                                    </Label>
                                                    <p>
                                                        {el?.courseAdministrator}
                                                    </p>
                                                </div>

                                            </Col>
                                            <Col lg={8} xs={24}  >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Dönem
                                                    </Label>
                                                    <p>
                                                        {el.semester}
                                                    </p>
                                                </div>
                                            </Col>


                                            <Col lg={8} xs={24}  >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Türü
                                                    </Label>
                                                    <p>
                                                        Canlı Eğitim
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={24} xs={24}  >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Açıklama
                                                    </Label>
                                                    <p>
                                                        {el.description}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={24} xs={24}  >
                                                <Button type='primary' danger style={{
                                                    width: "100%",
                                                    marginTop: "15px"
                                                }}
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: `${el?.name} isimli kurstan kaydınızı silmek istediğinize emin misiniz?`,
                                                            type: "confirm",
                                                            okText: "Eminim",

                                                            cancelText: "İptal",
                                                            onOk: async () => {
                                                                try {
                                                                    setLoading(true)
                                                                    const response = await axiosInstance.delete(`/courseRegisters/delete/${el?.courseRegisterId}`)

                                                                    await getStudentCourse()
                                                                    setCourseType(0)

                                                                    toast.success(`${el?.name} isimli kurstan kaydınız silindi`, {
                                                                        autoClose: 4000,
                                                                        position: "top-center"
                                                                    })
                                                                }
                                                                catch (err) {

                                                                }
                                                                finally {
                                                                    setLoading(false)
                                                                }
                                                            }
                                                        })
                                                    }}

                                                >
                                                    Kurstan kaydımı silmek istiyorum
                                                </Button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row gutter={
                                            [
                                                {
                                                    md: 6
                                                },
                                                {
                                                    md: 6
                                                }
                                            ]
                                        } >
                                            <Col md={12} xs={24}  >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Kurs Türü
                                                    </Label>
                                                    <p>
                                                        Video Eğitim
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col lg={12} xs={24} >
                                                <div className='course_card_item '  >
                                                    <Label className='course_card_item_title' >
                                                        Eğitmen
                                                    </Label>
                                                    <p>
                                                        {el.courseAdministrator ?? "Eğitmen Seçilmemiş"}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={24} xs={24} >
                                                <div className='course_card_item'  >
                                                    <Label className='course_card_item_title' >
                                                        Açıklama
                                                    </Label>
                                                    <p>
                                                        {el.description}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={24} xs={24} >
                                                <Button type='primary' danger style={{
                                                    width: "100%",
                                                    marginTop: "15px"
                                                }}
                                                    onClick={() => {
                                                        Modal.confirm({
                                                            title: `${el?.name} isimli kurstan kaydınızı silmek istediğinize emin misiniz?`,
                                                            type: "confirm",
                                                            okText: "Eminim",

                                                            cancelText: "İptal",
                                                            onOk: async () => {
                                                                try {
                                                                    setLoading(true)
                                                                    const response = await axiosInstance.delete(`/courseRegisters/delete/${el?.courseRegisterId}`)

                                                                    await getStudentCourse()
                                                                    setCourseType(0)

                                                                    toast.success(`${el?.name} isimli kurstan kaydınız silindi`, {
                                                                        autoClose: 4000,
                                                                        position: "top-center"
                                                                    })
                                                                }
                                                                catch (err) {

                                                                }
                                                                finally {
                                                                    setLoading(false)
                                                                }
                                                            }
                                                        })
                                                    }}

                                                >
                                                    Kurstan kaydımı silmek istiyorum
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }

                            </Collapse.Panel>
                        </Collapse>
                    )
                }) : (
                    <div style={{ marginTop: "30px" }}>
                        <Alert message="Herhangi bir kayıt bulunamadı" type="warning" />
                    </div>

                )
            }
            <Modal isOpen={modalInfo.visible} size='lg' >
                <StyledModalHeader>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} >
                        <p>
                            Devamsızlık
                        </p>
                        <Button type='primary' danger onClick={() => {
                            setModalInfo({
                                visible: false
                            })
                        }} >
                            Kapat
                        </Button>
                    </div>
                </StyledModalHeader>
                <ModalBody>
                    <Row style={{ width: "100%" }} gutter={[
                        {
                            md: 6,
                            xs: 4
                        },
                        {
                            md: 6,
                            xs: 4
                        }
                    ]} >
                        <Col md={12} xs={24}  >
                            <StyledItemElement>
                                <p className='element_title'>
                                    Toplam Yapılan Eğitim Süresi (Dk)
                                </p>
                                <p className='element_value'>
                                    {absenceData?.totalEducationDuration} (Dk)
                                </p>
                            </StyledItemElement>
                        </Col>
                        <Col md={12} xs={24} >
                            <StyledItemElement>
                                <p className='element_title'>
                                    Yapılan Eğitime Girilmeyen Toplam Süre (Dk)
                                </p>
                                <p className='element_value'>
                                    {absenceData?.notExistLessonTotalDuration?.toFixed(2)}
                                </p>
                            </StyledItemElement>
                        </Col>
                        <Col md={12} xs={24} >
                            <StyledItemElement>
                                <p className='element_title'>
                                    Devamsızlık Hakkı (Dk)
                                </p>
                                <p className='element_value'>
                                    {absenceData?.absenceDuration} (Dk)
                                </p>
                            </StyledItemElement>
                        </Col>
                        <Col md={12} xs={24} >
                            <StyledItemElement>
                                <p className='element_title'>
                                    Yapılan Eğitime Girilen Toplam Süre (Dk)
                                </p>
                                <p className='element_value'>
                                    {absenceData?.totalActiveTime?.toFixed(2)} (Dk)
                                </p>
                            </StyledItemElement>
                        </Col>
                        <Col md={24} xs={24} >
                            <Alert type='warning' message={absenceData?.message} style={{
                                textAlign: "center",
                                color: "red",
                                fontWeight: "bold"
                            }} />
                        </Col>
                        <Col md={24} >
                            <StyledTableContainer >
                                <p className='header_element'  >
                                    Girilen Canlı Eğitim Detayları
                                </p>
                                <Table
                                    scroll={{
                                        x: 500
                                    }}
                                    pagination={{
                                        pageSize: 3,
                                        showSizeChanger: false
                                    }}
                                    bordered
                                    dataSource={absenceData?.absences ?? []}
                                    columns={[
                                        {
                                            title: "Eğitime Giriş Zamanı",
                                            width: 270,
                                            render: (row, record) => {
                                                let formatDate = formatInTimeZone(record?.registeredOn, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
                                                return (
                                                    <p>
                                                        {formatDate}
                                                    </p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Eğitimden Ayrılış Zamanı",
                                            width: 270,
                                            render: (row, record) => {
                                                let formatDate = formatInTimeZone(record?.leftOn, 'Europe/Istanbul', "dd MMMM yyyy EEEE HH:mm:ss", { locale: trLocale });
                                                return (
                                                    <p>
                                                        {formatDate}
                                                    </p>
                                                )
                                            }
                                        },
                                        {
                                            title: "Eğitimde Geçirilen Süre",
                                            render: (row, record) => {
                                                return (
                                                    <p>
                                                        {record?.userActiveTime?.toFixed(3)} (Dk)
                                                    </p>
                                                )
                                            }
                                        },

                                    ]}
                                />
                            </StyledTableContainer>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
            {/* <Modal open={modalInfo.visible} onCancel={() => {
                setModalInfo({
                    visible: false
                })
                setAbsenceData()
            }} title="Devamsızlık"
                footer={() => {
                    return (
                        <div>
                            <Button danger onClick={() => {
                                setModalInfo({
                                    visible: false
                                })
                            }}  >
                                Kapat
                            </Button>
                        </div>
                    )
                }}
            >


            </Modal> */}
        </>
    )
}

export default StudentCourseTable

const StyledHeader = styled.div`
    border-bottom: 1px dashed black;
    padding-bottom: 10px;

    display: flex;
    /* justify-content: flex-end; */
`

const StyledAntSelect = styled(AntSelect)`
    width: 100%;
    height: 35px;
    @media screen and (max-width:600px) {
        width: 100%;
    }
`

const StyledItemElement = styled.div`
    width: 100%;
    background-color: cornflowerblue;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .element_title{
        color: white;
        text-align: center;
    }

    .element_value{
        color: green;
        font-weight: bold;
        text-align: center;
    }
`


const StyledTableContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .header_element{
        border: 1px solid gray;
        padding: 8px;
        border-radius: 10px;
        text-align: center;
        font-weight: bold;
    }
`






const StyledModalHeader = styled(ModalHeader)`
    h5{
        width: 100%;
    }
`