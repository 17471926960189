// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teacher_table_main_container {
  display: flex;
  flex-direction: column; }

.teacher_table_filter_header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px; }
  .teacher_table_filter_header .teacher_table_filter_left_section {
    display: flex;
    align-items: center;
    column-gap: 20px; }

@media screen and (max-width: 1000px) {
  #input1 {
    margin-left: 0 !important;
    margin-top: 5px; }
  #btn1 {
    margin-top: 15px; } }

@media screen and (max-width: 600px) {
  .teacher_table_filter_header {
    display: grid;
    grid-template-columns: 1fr; }
    .teacher_table_filter_header .teacher_table_filter_left_section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; } }

@media screen and (max-width: 310px) {
  .teacher_table_filter_header .teacher_table_filter_left_section {
    display: grid;
    grid-template-columns: 1fr; } }
`, "",{"version":3,"sources":["webpack://./src/pages/Teacher/TeacherTable/TeacherTable.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB,EAAA;;AAG1B;EACI,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;EAC9B,mBAAmB,EAAA;EALvB;IAOQ,aAAa;IACb,mBAAmB;IACnB,gBAAgB,EAAA;;AAMxB;EACI;IACI,yBAAyB;IACzB,eAAe,EAAA;EAGnB;IACI,gBAAgB,EAAA,EACnB;;AAIL;EACI;IACI,aAAa;IACb,0BAA0B,EAAA;IAF9B;MAKQ,aAAa;MACb,8BAA8B;MAC9B,mBAAmB,EAAA,EACtB;;AAIT;EACI;IAIQ,aAAa;IACb,0BAA0B,EAAA,EAE7B","sourcesContent":[".teacher_table_main_container {\n    display: flex;\n    flex-direction: column;\n}\n\n.teacher_table_filter_header {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between; \n    margin-bottom: 20px;\n    .teacher_table_filter_left_section {\n        display: flex;\n        align-items: center;\n        column-gap: 20px;\n\n    }\n}\n\n\n@media screen and(max-width:1000px) {\n    #input1 {\n        margin-left: 0 !important;\n        margin-top: 5px;\n    }\n\n    #btn1 {\n        margin-top: 15px;\n    }\n\n}\n\n@media screen and(max-width:600px) {\n    .teacher_table_filter_header {\n        display: grid;\n        grid-template-columns: 1fr;\n\n        .teacher_table_filter_left_section {\n            display: flex;\n            justify-content: space-between;\n            margin-bottom: 10px;\n        }\n    }\n}\n\n@media screen and(max-width:310px) {\n    .teacher_table_filter_header {\n\n\n        .teacher_table_filter_left_section {\n            display: grid;\n            grid-template-columns: 1fr;\n           \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
