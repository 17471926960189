import React from 'react'
import Slider from 'react-slick';
import kmk1 from "../../../../assets/images/kmk1.svg"
import kmk2 from "../../../../assets/images/kmk2.svg"
import kmk3 from "../../../../assets/images/kmk3.svg"
import kmk5 from "../../../../assets/images/kmk5.svg"
import KuzemBlue from "../../../../assets/images/komeklogomain.png"
import Group from "../../../../assets/images/Group.png"
import styled from 'styled-components';

const HomeSliderKomek = () => {

    const goToSite = (link) => {
        window.open(link, "_blank")
    }

    const images = [
        {
            img: kmk1,
            link: "https://www.zeybe.com.tr"
        },
        {
            img: kmk2,
            link: "https://www.zenobya.com.tr"
        },
        {
            img: kmk3,
            link: "https://www.anatoya.com"
        },
        {
            img: kmk5,
            link: "https://www.komekpazari.com"
        },
        {
            img: KuzemBlue,
            link: "https://www.komek.org.tr2"
        }
    ];

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,      // değişikilik yapıldı default değer 3
        responsive: [
            {
                breakpoint: 1025, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    dots: true
                }
            },
            {
                breakpoint: 860, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            },
            {
                breakpoint: 500, // Bu noktayı ekrana göre ayarlaaa
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            }
        ]
    };

    return (
        <StyledSliderContainer  >
            <div className='home_komek_slider_wrapper' >
                <img src={Group} alt='' id='squareElement'></img>
                <Slider
                    {...settings}
                >
                    {images.map((item, index) => (
                        <div className='komek_slider_img_container' key={`${index}`} onClick={() => {
                            goToSite(item.link)
                        }}  >
                            <img src={item.img} alt={`Slide ${index + 1}`} className='komek_slider_img_item' />
                        </div>
                    ))}
                </Slider>
            </div>
        </StyledSliderContainer>
    )
}

export default HomeSliderKomek


const StyledSliderContainer = styled.div`
    padding: 20px 120px;
    max-width: 100%;

    @media screen and (max-width:1100px) {
        padding: 20px 60px;
    }
    @media screen and (max-width:500px) {
        padding: 20px 20px;
    }
    .home_komek_slider_wrapper {
        margin-top: 100px;
        width: 100%;
        position: relative;
        justify-content: center;
        align-items: center;

        #squareElement {
            position: absolute;
            top: -120%;
            left: 0;

            @media screen and (max-width:500px) {
                display: none;
            }
        }

        .komek_slider_wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            align-items: center;
            justify-content: center;
            column-gap: 10px;


        }

        @media screen and (max-width:500px) {
            margin-top: 70px;
        }


        .komek_slider_img_container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100%;
        cursor: pointer;
        }

        .komek_slider_img_item {
            height: 100px;
            width: 180px;
            object-fit: contain;
        }
    }


`