import React from 'react'
import TopBgElement from '../TopBgElement/TopBgElement'
import EgitimlerBg from "../../../assets/images/egitimlerBg.png"
import styled from "styled-components"
import MainNavbar from '../../components/navbar/MainNavbar'

const SiteHaritasi = () => {
    return (
        <StyledMainContainer>
            <MainNavbar />
            <TopBgElement img={EgitimlerBg} mainTitle={"Site Haritası"} />
            <StyledMainContent>
                <ul>
                    <li>
                        asdsa
                    </li>
                    <li>
                        asdsa
                    </li>
                    <li>
                        asdsa
                    </li>
                    <li>
                        asdsa
                    </li>
                    <li>
                        asdsa
                    </li>
                    <li>
                        asdsa
                    </li>
                </ul>
            </StyledMainContent>

        </StyledMainContainer>
    )
}

export default SiteHaritasi


const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledMainContent = styled.div`
    display: Flex;
    border: 1px solid gray;
    padding: 40px 80px;
`