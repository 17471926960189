// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  .Container003{
    display: flex !important;
    justify-content: center !important;
    flex-direction: column;
    margin-left:  10%;
    
    #imgg{
    max-width: 90% !important;
    
}
h3{
    padding-top: 5%;
    font-weight: bold;
    width: fit-content;
}
p{
    color: black;
    font-weight: 400;
    max-width: 85%;
    width: fit-content;

}

}
.blog_detail_desc{
    margin-bottom: 20px;
    h4{
        font-weight: 600;
    }
}  */
.Container003 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.blog_detail_desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .blog_detail_desc h4 {
    font-size: x-large;
    align-self: flex-start;
    margin-left: 7.5%;
    padding-top: 15px; }
  .blog_detail_desc p {
    font-size: larger; }

#imgg {
  padding-left: 8%;
  padding-right: 8%; }
`, "",{"version":3,"sources":["webpack://./src/Public/pages/blogDetay/index.scss"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;IA6BI;AAEJ;EACI,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;;AAEvB;EACI,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB,EAAA;EAJvB;IAMQ,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,iBAAiB,EAAA;EATzB;IAYQ,iBAAiB,EAAA;;AAGzB;EACI,gBAAgB;EAChB,iBAAiB,EAAA","sourcesContent":["/*  .Container003{\n    display: flex !important;\n    justify-content: center !important;\n    flex-direction: column;\n    margin-left:  10%;\n    \n    #imgg{\n    max-width: 90% !important;\n    \n}\nh3{\n    padding-top: 5%;\n    font-weight: bold;\n    width: fit-content;\n}\np{\n    color: black;\n    font-weight: 400;\n    max-width: 85%;\n    width: fit-content;\n\n}\n\n}\n.blog_detail_desc{\n    margin-bottom: 20px;\n    h4{\n        font-weight: 600;\n    }\n}  */\n\n.Container003{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.blog_detail_desc{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    h4{\n        font-size: x-large;\n        align-self: flex-start;\n        margin-left: 7.5%;\n        padding-top: 15px;\n    }\n    p{\n        font-size: larger;\n    }\n}\n#imgg{\n    padding-left: 8%;\n    padding-right: 8%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
