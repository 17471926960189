// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#primaryButton {
  background: #7f56d9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  white-space: nowrap;
  min-width: 6rem;
  margin-bottom: 0px;
  color: white; }
  #primaryButton:hover {
    background: gray; }

#primaryButton2 {
  background: #cbc2ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  white-space: nowrap;
  min-width: 6rem;
  margin-bottom: 10px;
  color: white; }
  #primaryButton2:hover {
    background: gray; }

.code_section {
  display: flex;
  margin: 10px 0; }

.cardxyz {
  border: none !important;
  background: #dbdafa !important; }

#kvk {
  font-size: small;
  margin-bottom: 15px; }

.captcha_text_input {
  margin: 20px 0; }
`, "",{"version":3,"sources":["webpack://./src/Public/components/Captcha/index.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,4BAA4B;EAC5B,6BAA6B;EAC7B,gCAAgC;EAChC,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,eAAe;EAChB,kBAAkB;EAClB,YAAY,EAAA;EAVf;IAaM,gBAAgB,EAAA;;AAItB;EACI,mBAAmB;EACnB,4BAA4B;EAC5B,6BAA6B;EAC7B,gCAAgC;EAChC,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,eAAe;EAChB,mBAAmB;EACnB,YAAY,EAAA;EAVf;IAaM,gBAAgB,EAAA;;AAItB;EACI,aAAa;EACb,cAAc,EAAA;;AAGlB;EACI,uBAAuB;EACvB,8BAA8B,EAAA;;AAGlC;EACE,gBAAgB;EAChB,mBAAmB,EAAA;;AAGrB;EACI,cAAc,EAAA","sourcesContent":["#primaryButton {\n    background: #7f56d9;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n    border-bottom-left-radius: 20px;\n    border: none;\n    white-space: nowrap;\n    min-width: 6rem;\n   margin-bottom: 0px;\n   color: white;\n  \n    &:hover {\n      background: gray;\n      \n    }\n}\n#primaryButton2 {\n    background: #cbc2ff;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n    border-bottom-left-radius: 20px;\n    border: none;\n    white-space: nowrap;\n    min-width: 6rem;\n   margin-bottom: 10px;\n   color: white;\n  \n    &:hover {\n      background: gray;\n      \n    }\n}\n.code_section{\n    display: flex;\n    margin: 10px 0;\n}\n\n.cardxyz{\n    border: none !important;\n    background: #dbdafa !important;\n    \n}\n#kvk{\n  font-size: small;\n  margin-bottom: 15px;\n}\n\n.captcha_text_input{\n    margin: 20px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
