
import "./Home.scss"
import MainFooter from './components/mainFooter/MainFooter';
import React from "react";
import styled from 'styled-components';
import HomeTopContainer from './components/Home/HomeTopContainer';
import PublicNotice from './components/PublicNotice/PublicNotice';
import HomeSliderKomek from './components/HomeComponents/HomeSliderKomek/HomeSliderKomek';
import MostPopularOfflineCourse from './components/HomeComponents/MostPopularOfflineCourse/MostPopularOfflineCourse';
import MostPopularOnlineCourse from './components/HomeComponents/MostPopularOnlineCourse/MostPopularOnlineCourse';
import HomeCategories from './components/HomeComponents/HomeCategories/HomeCategories';
import HomeUzaktanEgitim from './components/HomeComponents/UzaktanEgitim/HomeUzaktanEgitim';
import HomeOnerilenKurslar from './components/HomeComponents/OnerilenKurslarimiz/HomeOnerilenKurslar';
import { Helmet } from "react-helmet-async";

const Home = () => {
    return (
        <StyledContainer >
            <Helmet>
                <title>{'Ana Sayfa - Eğitim Platformu'}</title>
                <meta name="description" content={'Eğitim platformumuz, çeşitli eğitim programları ve kaynakları ile öğrenme deneyiminizi zenginleştirir.'} />
                <meta name="keywords" content={'Ana Sayfa, Eğitim, Eğitim Programları, Öğrenme, Kaynaklar, Online Eğitim'} />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content={'Ana Sayfa - Eğitim Platformu'} />
                <meta property="og:description" content={'Eğitim platformumuz, çeşitli eğitim programları ve kaynakları ile öğrenme deneyiminizi zenginleştirir.'} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={'Ana Sayfa - Eğitim Platformu'} />
                <meta name="twitter:description" content={'Eğitim platformumuz, çeşitli eğitim programları ve kaynakları ile öğrenme deneyiminizi zenginleştirir.'} />
 
            </Helmet>
            <HomeTopContainer />
            <PublicNotice />
            <HomeSliderKomek />
            <MostPopularOfflineCourse />
            <MostPopularOnlineCourse />
            <HomeCategories />
            <HomeUzaktanEgitim />
            <HomeOnerilenKurslar />
            <MainFooter  ></MainFooter>
        </StyledContainer>
    )
}

export default Home;


const StyledContainer = styled.div`

    width: 100%;

`