import React, { useEffect, useState } from 'react'
import { administratorsGetAll } from '../../api/Course'
import { useNavigate, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { Button, Card } from 'reactstrap'
import { Button as AntButton, Table as AntTable } from "antd"

const TeacherResponsibilityCourse = () => {

    const [courses, setCourses] = useState([])
    const { id } = useParams()
    const navigate = useNavigate()
    const responsibleCourse = async () => {
        try {
            const response = await administratorsGetAll({
                page: 0,
                pageSize: 50000
            })
            const data = response.data.items.filter(el => el.user.id == id).map(el => el.course)
            setCourses(data)
        }
        catch (err) {

        }
    }
    useEffect(() => {
        responsibleCourse()
    }, [])


    return (
        <div>
            <AntTable
                scroll={{
                    x: 800
                }}
                size='middle'
                dataSource={courses}
                pagination={{
                    pageSize: 5
                }}
                columns={[
                    {
                        title: "İsim",
                        render: (row, record) => {
                            return (
                                <span> {record?.name} </span>
                            )
                        }
                    },
                    {
                        title: "Kontenjan",
                        render: (row, record) => {
                            return (
                                <span style={{ textTransform: "capitalize" }}> {record?.limit} </span>
                            )
                        }
                    },
                    {
                        title: "Dönem",
                        render: (row, record) => {
                            return (
                                <span> {record?.semester}</span>
                            )
                        }
                    },
                    {
                        title: "Format",
                        render: (row, record) => {
                            return (
                                <span>{row?.type}</span>
                            )
                        }
                    },
                    {
                        title: "İncele",
                        render: (row, record) => {
                            return (
                                <AntButton type='primary' onClick={() => {
                                    navigate(`/panel/kurs/${record?.id}`)
                                }}  >
                                    İncele
                                </AntButton>
                            )
                        }
                    }


                ]}
            />
    
        </div>
    )
}

export default TeacherResponsibilityCourse