// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.editor-class {
  background-color: white;
  padding: 10px;
  min-height: 300px;
  /* içerik alanının yüksekliği */
  border: 1px solid #ddd;
  border-radius: 4px; }

.editor-toolbar {
  margin-bottom: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/Components/Editor/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,uBAAuB;EACvB,aAAa;EACb,iBAAiB;EAAE,+BAAA;EACnB,sBAAsB;EACtB,kBAAkB,EAAA;;AAItB;EACI,2BAA2B,EAAA","sourcesContent":[".editor-class {\n    background-color: white;\n    padding: 10px;\n    min-height: 300px; /* içerik alanının yüksekliği */\n    border: 1px solid #ddd;\n    border-radius: 4px;\n  }\n\n\n.editor-toolbar {\n    margin-bottom: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
