import { useState, useEffect } from 'react';

const useScreenSize = (threshold = 766) => {
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < threshold);

    const handleResize = () => {
        setIsScreenSmall(window.innerWidth < threshold);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [threshold]);

    return isScreenSmall;
};

export default useScreenSize;
