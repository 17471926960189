import React, { useMemo } from 'react'
import { FiTrash } from 'react-icons/fi';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { v4 as uuid } from "uuid"
import { Button as AntButton } from "antd"
import styled from 'styled-components';
const CreateQuestionModal = ({ createQuestionModal, setCreateQuestionModal, setAnswerList, answerList, addQuestion }) => {

    function rakamToHarf(rakam) {
        const asciiOffset = 65;
        const harf = String.fromCharCode(asciiOffset + parseInt(rakam) - 1);
        return harf;
    }

    const questionModalBtnDisable = useMemo(() => {
        if (createQuestionModal.questionContent == "") {
            return true
        }
        else if (answerList.some(el => el.answerContent == "") || answerList.length <= 1) {
            return true
        }
        return false
    }, [answerList, createQuestionModal])

    return (
        <Modal isOpen={createQuestionModal.visible} size={"lg"} centered >
            <ModalHeader>
                {createQuestionModal.editMode ? "Güncelle" : "Soru Ekle"}
            </ModalHeader>
            <StyledModalBody>
                <Input type='textarea' style={{ resize: "none" }} rows={3} value={createQuestionModal.questionContent} placeholder='Soru İçeriği' onChange={(e) => {
                    setCreateQuestionModal({
                        ...createQuestionModal,
                        questionContent: e.target.value
                    })
                }} />
                <div className='create_question_modal_content_container'>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center",marginBottom:"10px" }} >
                        <p style={{ fontWeight: "500", fontSize: 16 }} >
                            Seçenekler
                        </p>
                        <AntButton size='small' type='primary' disabled={answerList.length >= 5} className='create_option_btn' onClick={() => {
                            setAnswerList([
                                ...answerList, {
                                    answerContent: "",
                                    answerId: uuid()
                                }
                            ])
                        }} >
                            Seçenek Ekle
                        </AntButton>
                    </div>
                    {
                        answerList.map((el, index) => {
                            return (
                                <div className='answer_option_item'>
                                    <Input disabled placeholder='key' className='answer_opt_item'  value={rakamToHarf(index + 1)} />
                                    <Input value={el.answerContent} placeholder='Seçenek' className='answer_content_item' onChange={({ target: { value } }) => {
                                        setAnswerList(answerList.map(item => {
                                            if (item.answerId == el.answerId) {
                                                return {
                                                    ...item,
                                                    answerContent: value
                                                }
                                            }
                                            return item
                                        }))
                                    }} />
                                    <Button color='danger' onClick={() => {
                                        setAnswerList(answerList.filter(item => item.answerId !== el.answerId))
                                    }}  >
                                        <FiTrash />
                                    </Button>
                                </div>
                            )
                        })
                    }
                </div>
            </StyledModalBody>
            <StyledModalFooter  >
                <div className='create_survey_add_question_modal_footer' >
                    <AntButton type='primary' disabled={questionModalBtnDisable} onClick={() => {
                        if (addQuestion) {
                            addQuestion()
                        }
                    }}  >
                        {
                            createQuestionModal.editMode ? "Güncelle" : "Kaydet"
                        }
                    </AntButton>
                    <AntButton danger type='primary' onClick={() => {
                        setCreateQuestionModal({
                            editMode: false,
                            questionId: "",
                            visible: false,
                            questionContent: ""
                        })
                        setAnswerList([])
                    }} >
                        İptal
                    </AntButton>
                </div>
            </StyledModalFooter>
        </Modal>
    )
}

export default CreateQuestionModal


const StyledModalBody=styled(ModalBody)`

    @media screen and (max-width:600px) {
        padding: 10px;
    }
`

const StyledModalFooter=styled(ModalFooter)`
    
    @media screen and (max-width:600px) {
        padding: 5px;
        margin-top: 10px;
    }
`