import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CevrimiciDetayDashboard from '../CevrimiciDetayDashboard/CevrimiciDetayDashboard'

const CevrimiciDetay = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Çevrimiçi kurs detay ekranı"} >
                <CevrimiciDetayDashboard />
            </TablePageContainer>
        </div>
    )
}

export default CevrimiciDetay