import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { UserContext } from '../../../context/user'
import { Alert, Button as AntButton, Modal as AntModal, Rate, Tabs } from "antd"
import { registerCourseApi } from '../../../api/Course'
import { LuPercent } from 'react-icons/lu'
import { CourseViewsForStudentApi } from '../../../api/CourseContent'
import { useNavigate, useParams } from 'react-router-dom'
import { TiTickOutline } from 'react-icons/ti'
import { IoMdPlayCircle } from 'react-icons/io'
import { axiosInstance } from '../../../api/axiosInstance'
import { toast } from 'react-toastify'
import axios from 'axios'

const CourseVideoRightContent = ({ courseInfo, statusControl, fetchLabelRequest }) => {
    const [context, dispatch] = useContext(UserContext)
    const { id } = useParams()
    const navigate = useNavigate()

    const token = localStorage.getItem("authToken")
    const [responseViewsList, setResponseViewsList] = useState({
        progress: "",
        items: []
    })


    const courseId = useMemo(() => {
        return courseInfo?.course?.id
    }, [courseInfo])



    const statusRateControl = () => {
        if (courseInfo?.type == "Online") {
            return false
        }
        else {
            if (statusControl?.message == "Erişebilmeniz için kayıt olmanız gerekmektedir.") {
                return false
            }
            else if (statusControl?.message == "Lütfen giriş yapınız.") {
                return false
            }
            else if (context.role == "Süper Admin" || context.role == "Öğretmen") {
                return false
            }
            return true
        }

    }

    const getResponseView = async () => {
        try {
            if (courseInfo?.course) {
                /* test yorum satırı */

                const responseViewsData = await axios.get(`/CourseViews/GetAllForStudent/${courseInfo?.course?.id}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })



                setResponseViewsList(responseViewsData.data)

            }



        }
        catch (err) {

        }
    }

    const userControl = useMemo(() => {
        let control = context.role == "Süper Admin" || context.role == "Öğretmen"
        return !control
    }, [courseInfo])

    const circlePercent = useMemo(() => {

        if (statusControl?.success) {

            if (responseViewsList.progress == "") {
                return 0
            }
            else {
                let percentValue = responseViewsList.progress.split("%")[1]

                return parseInt(percentValue?.trim())
            }

        }

    }, [responseViewsList, statusControl])

    const StatusBtnControl = useMemo(() => {
        if (statusControl?.message == "Erişebilmeniz için kayıt olmanız gerekmektedir.") {
            return (
                <VideoPageJoinStyledBtn type='primary' onClick={() => {
                    AntModal.confirm({
                        title: "Kayıt olmak istediğinize emin misiniz ?",
                        cancelText: "İptal",
                        okText: "Eminim",

                        onOk: async () => {
                            try {
                                const response = await registerCourseApi({
                                    courseId: courseInfo?.course?.id,
                                    userId: context.userId
                                })
                                toast.success("Kursa kaydınız yapıldı, sayfa yenileniyor", {
                                    autoClose: 2000,
                                    position: "top-center"
                                })
                                setTimeout(() => {
                                    window.location.reload()
                                }, 2100)

                                /* await fetchLabelRequest() */

                            }
                            catch (err) {

                            }
                        }
                    })
                }}  >
                    <IoMdPlayCircle id='play_btn' />
                    <span>
                        Programa Katıl
                    </span>
                </VideoPageJoinStyledBtn>
            )
        }
        else if (statusControl?.message == "Lütfen giriş yapınız.") {
            return (
                <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                    <p style={{ textTransform: "capitalize", fontSize: "13px", textAlign: "center" }}>
                        Eğitime başlamak için lütfen giriş yapınız.
                    </p>
                    <VideoPageJoinStyledBtn type='primary' onClick={() => {
                        navigate("/giris")
                    }} >
                        <span>
                            Giriş Yapınız
                        </span>
                    </VideoPageJoinStyledBtn>
                </div>

            )
        }
        else {
            return (
                <StyledJoinRightSectionWrapper>
                    <div className='right_section_info_element'>
                        <div className='icon_wrapper' >
                            <TiTickOutline id='right_icon' />
                            <span>
                                Katılım durumu
                            </span>
                        </div>
                        <span className='right_result'>
                            Devam Ediyor
                        </span>
                    </div>
                    <div className='right_section_info_element'>
                        <div className='icon_wrapper' >
                            <LuPercent id='right_icon' />
                            <span>
                                Tamamlanma
                            </span>
                        </div>
                        <span className='right_result'>
                            %{circlePercent}
                        </span>
                    </div>
                    <VideoPageJoinStyledBtn type='primary' onClick={() => {
                        navigate(`/kurs/player/${courseInfo?.course?.id}`)
                    }} >
                        <IoMdPlayCircle id='play_btn' />
                        <span>
                            Devam et
                        </span>
                    </VideoPageJoinStyledBtn>
                </StyledJoinRightSectionWrapper>
            )
        }
    }, [statusControl, circlePercent])


    const OnlineStatusBtn = useMemo(() => {
        // console.log("statusCX", statusControl)
        if (statusControl?.message == "Lütfen giriş yapınız.") {
            return (
                <VideoPageJoinStyledBtn type='primary' onClick={() => {
                    navigate("/giris")
                }} >
                    <span>
                        Giriş Yapınız
                    </span>
                </VideoPageJoinStyledBtn>
            )
        }
        return (
            <VideoPageJoinStyledBtn type='primary' onClick={() => {
                navigate("/panel/cevrimici")
            }}  >
                <span>
                    Kayıt Ol
                </span>
            </VideoPageJoinStyledBtn>
        )
    }, [statusControl])












    const items = [
        {
            key: '1',
            label: 'Eğitmen',
            children: courseInfo?.type == "Offline" ? (
                <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                    <AntButton type='primary' onClick={() => {
                        if (courseInfo?.course?.courseAdministrator) {
                            /* getTeacherCv() */
                            navigate(`/egitmen/${courseInfo?.course?.courseAdministratorId}`)
                        }

                    }} >
                        {courseInfo?.course?.courseAdministrator ? courseInfo?.course?.courseAdministrator : "Eğitmen henüz seçilmedi"}
                    </AntButton>
                </div>
            ) : (
                <StyledOnlineTeacherList>
                    {
                        courseInfo?.teachers && Object.entries(courseInfo?.teachers).length == 0 ? (
                            <Alert type='info' message="Eğitmen eklendi" />
                        ) : <div style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px"
                        }}>
                            {
                                courseInfo?.teachers && Object.entries(courseInfo?.teachers).map(([key, value], index) => {
                                    return (
                                        <AntButton type='primary' onClick={() => {

                                            navigate(`/egitmen/${key}`)


                                        }} >
                                            {value}
                                        </AntButton>
                                    )
                                })
                            }

                        </div>


                    }
                </StyledOnlineTeacherList >
            )
        },
    ];

    if (statusRateControl()) {
        items.push({
            key: "3",
            label: "Puanla",
            children: (
                <Rate onChange={async (e) => {
                    try {
                        const response = await axiosInstance.post("/Assessments/Add", {
                            point: e,
                            courseId: courseInfo?.course?.id
                        })
                        toast.success("Puanınız başarıyla verildi", {
                            autoClose: 2000,
                            position: "top-center"
                        })
                    }
                    catch (err) {

                    }
                }} />
            )
        })
    }


    const mainFetchRequest = () => {
        try {
            if (courseInfo?.type == "Offline") {
                getResponseView()
            }
        }
        catch (err) {

        }
    }


    useEffect(() => {
        mainFetchRequest()
    }, [courseInfo])

    return (
        <VideoPageRightContentWrapper>
            {
                userControl && (
                    <div className='rightSectionTopElement'>
                        {
                            courseInfo?.type == "Offline" ? StatusBtnControl : OnlineStatusBtn
                        }
                    </div>
                )
            }
            <div className='rightSectionTeacherWrapper'>
                <Tabs items={items} />
            </div>
        </VideoPageRightContentWrapper>
    )
}

export default CourseVideoRightContent


const VideoPageRightContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    row-gap: 20px;  
    width: 30%;

    @media screen and (max-width:1100px) {
        width: 100%;
    }
    @media screen and (max-width:600px) {
        width: 100%;
    }

    .rightSectionTopElement{
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        width: 100%;
    }


    .rightSectionTeacherWrapper{
        /* display: flex; */
        padding: 15px 20px;
        background-color: white;
    }
`

const VideoPageJoinStyledBtn = styled(AntButton)`
    width: 100%;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    #play_btn{
        font-size: 24px;
    }
`

const StyledJoinRightSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;
   
    .right_section_info_element{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon_wrapper{
            display: flex;
            align-items: center;
            font-size: 16px;

            #right_icon{
                font-size: 24px;
            }
        }

        .right_result{
            font-size: 16x;
            font-weight: 700;
        }
    }
`

const StyledOnlineTeacherList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`