import React from 'react'
import styled from 'styled-components'

const TopBgElement = ({ img, mainTitle, subTitle }) => {
    return (
        <TopBgContainer img={img} >
            <ContentContainer>
                <div className='content_wrapper'>
                    <h3 className='main_title' >
                        {mainTitle}
                    </h3>
                    <h4 className='sub_title'>
                        {subTitle}
                    </h4>
                </div>

            </ContentContainer>
        </TopBgContainer>
    )
}

export default TopBgElement

const TopBgContainer = styled.div`

    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    height: 35vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (max-width:600px) {
        background-position: 40% center;
    }

`

const ContentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .content_wrapper{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        transform: translateY(20%);

        .main_title{
            color: white;
        }
        .sub_title{
            color:  white;
            text-align: center;
        }
    }
`

