

import './index.scss'
import { useTranslation } from "react-i18next";
import MainNavbar from '../../components/navbar/MainNavbar';
import MainFooter from '../../components/mainFooter/MainFooter';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card, CardBody, CardText, CardFooter, CardImg, CardTitle, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import ButtonPurple from '../../components/buttons/ButtonPurple.jsx';
import { axiosInstance } from '../../../api/axiosInstance.js';
import { Alert, Button } from 'antd';
import TopBgElement from '../TopBgElement/TopBgElement.jsx';
import BlogBg from "../../../assets/images/blogBg.png"
import Slider from 'react-slick';
import CoverPhoto from "../../../assets/images/b3.png"
import OtherCourseSlider from '../../components/OtherCourseSlider/OtherCourseSlider.jsx';
import { Helmet } from 'react-helmet-async';

const Blog = () => {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const [blogList, setBlogList] = useState([])


    const getAllBlog = async () => {
        try {
            const response = await axiosInstance.post("/blogs/getall", {
                page: 0,
                pageSize: 1
            })
            const response2 = await axiosInstance.post("/blogs/getall", {
                page: 0,
                pageSize: response.data.count
            })


            setBlogList(response2.data.items)
        }
        catch (err) {

        }
    }









    useEffect(() => {
        window.scroll(0, 0)
        getAllBlog()
    }, [])

    return (
        <StyledMainContainer>
            <Helmet>
         
                <meta name="description" content={'KUZEM Eğitim platformumuzda yer alan eğitici yazılar ve içerikleri inceleyin.'} />
                <meta name="keywords" content={'Blog, Eğitim, İçerik, Yazılar, Öğrenme, Eğitim Platformu'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.kuzem.org/blog" />
                <meta property="og:image" content={BlogBg} />
            </Helmet>
            <MainNavbar />
            <TopBgElement img={BlogBg} mainTitle={"Blog"} subTitle={"Anasayfa / Blog"} />
            {/*         <FontHead page={`${t("blog0")}`}></FontHead> */}
            <BlogContainer>
                <StyledContentContainer>
                    <StyledRow>
                        {
                            blogList.length !== 0 ? blogList.map((item, index) => {
                                return (
                                    <Col lg={4} key={`${index}`} >
                                        <StyleCard>
                                            <StyledCardImg variant="top" src={item.imageUrl} />
                                            <CardBody  >
                                                <CardTitle style={{ textTransform: "capitalize" }} > {item.title}  </CardTitle>
                                                <StyledCardText >
                                                    
                                                    {item?.description?.substring(0, 200)}...
                                                </StyledCardText>
                                            </CardBody>

                                            <CardFooter style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                <CardText id='date'>{"31.01.2024"}</CardText>
                                                <ButtonPurple buttonText={t("btnxx")} /* onClick={props.onClick} */ onClick={() => {
                                                    navigate(`/blog/${item.id}`)
                                                }} ></ButtonPurple>
                                            </CardFooter>
                                        </StyleCard>
                                    </Col>
                                )
                            }) : (
                                <StyledNoContentWrapper>
                                    <Alert type='info' message="İçerik Bulunamadı" />
                                </StyledNoContentWrapper>

                            )
                        }
                    </StyledRow>
                    <OtherCourseContainer>

                        <OtherCourseSlider />
                    </OtherCourseContainer>
                </StyledContentContainer>


            </BlogContainer>






            <MainFooter visibility={'visible'}></MainFooter>
        </StyledMainContainer >
    )
}

export default Blog

const BlogContainer = styled.div`
     padding: 20px 120px;
    margin-bottom: 70px;
    @media screen and (max-width:1100px) {

        padding: 20px 40px;
    }

    @media screen and (max-width:500px) {
        padding: 20px 20px;
    
    }
`

const StyledRow = styled(Row)`
        padding-right: 0;
`

const StyleCard = styled(Card)`
width: "100%";
height: 370px;
max-height: 370px;

@media screen and (max-width:600px) {
    margin-right: 0;
}
`
const StyledCardImg = styled(CardImg)`
    object-fit :cover;
    height: 150px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`
const StyledCardText = styled(CardText)`
    font-size: 12px;
    
`

const StyledNoContentWrapper = styled.div`
    margin-bottom: 100px;
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const OtherCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 70px;
    .other_course_title{

    }
`

const StyledSliderCardContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Container2 = styled.div`
    width: 90%;
    border: 1px solid #D1D5DB;
    border-radius: 10px;

    .same_course_name_wrapper{
        height: 70px;
        padding-top: 10px;
        padding-left: 10px;
        .same_course_name{
        
        font-size: 13px;
        text-transform: capitalize;
        color: #80949F;


     }

    }
    
    .same_course_card_footer{
        background-color: #EFEFEF;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 10px;  
        display: flex;
        justify-content: space-between;
        align-items: center;
        .category_el{
           /*  background-color: #B9F4B9; */
           /*  padding: 3px 10px; */
            border-radius: 5px;
            letter-spacing: 2px;
            width: max-content;
            text-transform: capitalize;
            font-size: 10px;
        }
    }
`

const CoverImg = styled.img`
    width:100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 150px;
`