import { Container } from "reactstrap"
import "./index.scss"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import Rectangle from "../../../assets/images/rectangleBg.png"

const FontHead = (props) => {
    const { t } = useTranslation();

    return (
        <div  className="kurumsal_container" >
                <div  className="kurumsal_font_bg_wrapper" >
                    <img src={Rectangle} alt="" id="kurumsal_img" />
                    <h3>{props.page}</h3>
                    <p >{t("titleHome")} / {props.page} </p> 
                </div>
        </div>
    )
};
FontHead.propTypes = {
    page: PropTypes.string,
};

export default FontHead

