import { useFormik } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ExamUpdateContext } from '../ExamUpdateDashboard'
import "./examGeneralInfo.scss"
import { Button, Col, FormFeedback, Input, Label, Row } from 'reactstrap'
import { DatePicker, Select, Button as AntButton } from 'antd'
import dayjs from "dayjs"
import * as yup from "yup"
import styled from 'styled-components'
import { getMinuteDifference } from '../../../utils/utils'
import { Modal } from "antd"
import { Await, useNavigate, useParams } from 'react-router-dom'
import { deleteExamApi, examUpdateApi } from '../../../api/exam'
import { toast } from 'react-toastify'



const ExamUpdateGeneralInfo = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [differenceMinute, setDifferenceMinute] = useState()
    const { examDetailData, courseList } = useContext(ExamUpdateContext)

    const FormalTypeControl = useMemo(() => {
        return examDetailData?.formal == "Ön Sınav"
    }, [examDetailData])


    let validObj = {}
    if (examDetailData?.formal !== "Ön Sınav") {
        validObj = {
            courseId: yup.string().required("Lütfen kurs seçiniz"),
            name: yup.string().required("Sınav ismi zorunludur"),
            description: yup.string().required("Açıklama zorunludur"),
            startTime: yup
                .date()
                .required("Başlangıç saati zorunludur")
                .test('start-date', 'Başlangıç tarihi bitiş tarihinden önce ve günümüzden ileride olmalıdır', function (value) {
                    const endTime = this.parent.endTime;
                    return endTime && new Date(value) < new Date(endTime) && new Date() < new Date(value);
                }),
            endTime: yup
                .date()
                .required("Bitiş saati zorunludur")
                .test('end-date', 'Bitiş tarihi başlangıç tarihinden sonra ve günümüzden ileride olmalıdır', function (value) {
                    const startTime = this.parent.startTime;
                    return startTime && new Date(startTime) < new Date(value) && new Date() < new Date(value);
                }),
        }
    }
    else {
        validObj = {
            courseId: yup.string().required("Lütfen kurs seçiniz"),
            name: yup.string().required("Sınav ismi zorunludur"),
            description: yup.string().required("Açıklama zorunludur"),
        }

    }
    const ExamValidSchema = yup.object(validObj)

    const formik = useFormik({
        initialValues: {
            "courseId": null,
            "description": "",
            "startTime": null,
            "endTime": null,
            "type": "",
            "name": "",
            "formal": ""
        },
        validationSchema: ExamValidSchema,
        onSubmit: async (value) => {
            try {
             
                const response = await examUpdateApi({
                    id: id,
                    name: value.name,
                    courseId: value.courseId,
                    type: examDetailData.type,
                    startTime: new Date(value.startTime).toUTCString(),
                    endTime: new Date(value.endTime).toUTCString(),
                    description: value.description,
                    formal: value.formal
                })
                toast.success("Güncelleme başarılı", {
                    autoClose: 2000
                })
            }
            catch (err) {

            }
        }
    })

    useEffect(() => {
        if (examDetailData) {
            try {
              

                formik.setFieldValue("name", examDetailData.name)
                formik.setFieldValue("startTime", dayjs(examDetailData.startTime))
                formik.setFieldValue("endTime", dayjs(examDetailData.endTime))
                formik.setFieldValue("description", examDetailData.description)
                formik.setFieldValue("type", examDetailData.type)
                formik.setFieldValue("formal", examDetailData?.formal)
           
                let selectCourse = courseList?.find(el => el?.name == examDetailData.courseName)
              
                formik.setFieldValue("courseId", selectCourse?.id)

                let diff = getMinuteDifference(examDetailData.startTime, examDetailData.endTime)
                setDifferenceMinute(diff)
            }
            catch (err) {

            }


        }

    }, [examDetailData, courseList])


    const deleteExamProcess = async () => {
        try {

            const response = await deleteExamApi(id)
            toast.success("Sınav başarıyla silindi", {
                autoClose: 2000
            })
            navigate(`/panel/sinavlar?active=${examDetailData.type == "Test" ? 1 : 2}`)
        }
        catch (err) {
      
        }
    }

    return (
        <div className='exam_update_general_info_container' >
            <Row >
                <Col lg={5}>
                    <div className="mb-4">
                        <Label className="form-label">
                            Sınav ismi
                        </Label>
                        <StyledInput placeholder='isim' type="text" className="form-control" name='name'
                            value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.name && formik.errors.name ? true : false
                            }
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <FormFeedback type="invalid"><div>{formik.errors.name}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <Col lg={5}>
                    <div className="mb-4">
                        <Label className="form-label">
                            Kurs ismi
                        </Label>
                        <StyledSelect
                            id="exampleSelect"
                            name="select"
                            type="select"
                            onChange={(e) => {
                                formik.setFieldValue("courseId", e.target.value)
                            }}
                            value={formik.values.courseId}
                        >
                            {
                                courseList.map(item => {
                                    return (
                                        <option value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                })
                            }
                        </StyledSelect>
                        {formik.touched.courseName && formik.errors.courseName ? (
                            <StyledErrorMessage >{formik.errors.courseName}</StyledErrorMessage>
                        ) : null}
                    </div>
                </Col>
                <Col lg={2}>
                    <div className="mb-4">
                        <Label className="form-label">
                            Sınav Tipi
                        </Label>
                        <Input disabled style={{ marginLeft: 0 }} name='name'
                            value={formik.values.formal}
                        />

                    </div>
                </Col>
                {
                    !FormalTypeControl && (
                        <Col lg={5}>
                            <div className="mb-4">
                                <Label className="form-label">
                                    Başlangıç Saati
                                </Label>
                                <DatePicker
                                    style={{
                                        width: "100%"
                                    }}
                                    allowClear={false}
                                    showTime
                                    onChange={(value, dateString) => {
                                        let diff = getMinuteDifference(value, formik.values.endTime)
                                        setDifferenceMinute(diff)
                                        formik.setFieldValue("startTime", value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.startTime}
                                />
                                {formik.touched.startTime && formik.errors.startTime ? (
                                    <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.startTime}</div>

                                ) : null}
                            </div>
                        </Col>
                    )
                }
                {
                    !FormalTypeControl && (
                        <Col lg={5}>
                            <div className="mb-4">
                                <Label className="form-label">
                                    Bitiş saati
                                </Label>
                                <DatePicker
                                    style={{
                                        width: "100%"
                                    }}
                                    allowClear={false}
                                    showTime
                                    onChange={(value, dateString) => {
                                        let diff = getMinuteDifference(formik.values.startTime, value)
                                        setDifferenceMinute(diff)
                                        formik.setFieldValue("endTime", value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.endTime}
                                />
                                {formik.touched.endTime && formik.errors.endTime ? (
                                    <div style={{ marginTop: "5px", fontSize: 12, color: "#F3846D" }} >{formik.errors.endTime}</div>

                                ) : null}
                            </div>
                        </Col>
                    )
                }
                {
                    !FormalTypeControl && (
                        <Col lg={2}>
                            <div className="mb-4">
                                <Label className="form-label">
                                    Sınav Süresi
                                </Label>
                                <StyledInput disabled value={`${differenceMinute} (Dk)`}

                                />
                            </div>
                        </Col>
                    )

                }

                <Col lg={12}>
                    <div className="mb-4">
                        <Label className="form-label">
                            Açıklama
                        </Label>
                        <Input value={formik.values.description}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.description && formik.errors.description ? true : false}
                            onChange={formik.handleChange}
                            name='description' id='description' type='textarea'
                            rows={2} style={{ resize: "none" }} />

                        {formik.touched.description && formik.errors.description ? (
                            <FormFeedback type="invalid"><div>{formik.errors.description}</div></FormFeedback>
                        ) : null}
                    </div>
                </Col>
                <div className='update_btn_container' >

                    <AntButton danger type='primary' onClick={() => {
                        Modal.confirm({
                            title: "Silmek istediğinize emin misiniz ?",
                            cancelText: "İptal",
                            okText: "Eminim",
                            onOk: async () => {
                                deleteExamProcess()
                            }
                        })
                    }} >
                        Sınavi sil
                    </AntButton>
                    <AntButton type='primary' onClick={formik.handleSubmit} >
                        Güncelle
                    </AntButton>
                </div>
            </Row>
        </div>
    )
}

export default ExamUpdateGeneralInfo


/* const StyledSelect = styled(Input)`
    padding: 6px 15px;
`
const TotalTimeInput = styled(Input)`
 padding: 6px 15px;
` */

const StyledSelect = styled(Select)`
    height: 35px;
    width: 100%;

    .ant-select-selection-item{
        padding-left: 10px !important;
    }
`
const StyledInput = styled(Input)`
    height: 35px;
    margin-left: 0;
`
const StyledErrorMessage = styled.div`
color: #F2846B;
margin-top: 5px;

`