import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CgLogOut, CgProfile } from "react-icons/cg";
import avatar1 from "../../../../assets/images/profile.png"
import { UserContext } from '../../../../context/user';
import styled from 'styled-components';


const MobileProfileDropwn = () => {
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const [state, dispatch] = useContext(UserContext)


    return (

        <StyledDropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className=" header-item topbar-user">
            <StyledDropdownToggle tag="button" type="button" >
                <div  className='img_profile_container' >
                    <img className="rounded-circle header-profile-user" src={avatar1}
                        alt="Header Avatar" />
                    <span className="profile_name" > {state?.userAndLastName}</span>

                </div>
            </StyledDropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
                <h6 className="dropdown-header">Hoş Geldiniz {state?.name}!</h6>
                <DropdownItem className='p-0'>
                    <Link to="/panel/anasayfa" className="dropdown-item">
                        <CgProfile size={"1.2rem"} style={{ marginRight: "10px" }} />
                        <span className="align-middle">
                            Panel
                        </span>
                    </Link>
                </DropdownItem>
                <DropdownItem className='p-0 mt-1'>
                    <p className="dropdown-item" onClick={() => {
                        dispatch({ type: "LOGOUT" })
                    }} >
                        <CgLogOut size={"1.2rem"} style={{ marginRight: "10px" }} />
                        {/* <CgProfile size={"1.2rem"} style={{ marginRight: "10px" }} /> */}
                        <span className="align-middle">
                            Çıkış
                        </span>
                    </p>
                </DropdownItem>
            </DropdownMenu>
        </StyledDropdown>

    )
}

export default MobileProfileDropwn


const StyledDropdown = styled(Dropdown)`
    z-index: 10000;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
`

const StyledDropdownToggle = styled(DropdownToggle)`
    border: 1px solid gray;
    padding: 10px 25px;
    border-radius: 10px;

    .img_profile_container{
        display: flex;
        column-gap: 10px;
        align-items: center;
    }
    .profile_name{
        font-size:12px;
        font-weight: 600;
    }
`