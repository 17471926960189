import { Button, Col, Input, Modal, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { axiosInstance } from '../../../api/axiosInstance'
import { useParams } from 'react-router-dom'

const StudenStatusTab = ({ formik, refreshMethod }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        registerLimit: 0,
        isActive: true
    })
    const { id } = useParams()
    useEffect(() => {
        setValues({
            registerLimit: formik?.values?.registerLimit,
            isActive: formik?.values?.isActive
        })
    }, [formik])


    const updateStatus = () => {
        Modal.confirm({
            type: "confirm",
            okText: "Eminim",
            title: !formik.values?.isActive && values?.isActive ? (
                <p>
                    Öğrencinin devamsızlığını kaldırmak istediğinizden emin misiniz ,
                    <span style={{
                        color: "red",
                        fontWeight: "bold"
                    }} >
                        bu işlem geri döndürelemez ,
                        devam etmek istediğinize emin misiniz?
                    </span>
                </p>
            ) : (
                <p>
                    Bu öğrencinin kayıt hakkını güncellemek istediğinize emin misiniz ?
                </p>
            ),
            cancelText: "İptal",
            onOk: async () => {
                try {
                    setLoading(true)

                    await axiosInstance.put(`/users/UpdateLimitOrIsActiveForSuperAdmin`, {
                        id: id,
                        isActive: values.isActive,
                        registerLimit: values.registerLimit
                    })
                    toast.success("Güncelleme başarılı", {
                        position: "top-center",
                        autoClose: 2500
                    })
                    refreshMethod()
                }
                catch (err) {

                }
                finally {
                    setLoading(false)
                }
            }
        })
    }



    return (
        <StyledContainer>
            <Row gutter={[
                {
                    md: 16
                }
            ]} >
                <Col md={12} >
                    <StyledElement>
                        <p>
                            Kayıt Hakkı
                        </p>
                        <Input value={values.registerLimit} className='input_element' onChange={(e) => {
                            setValues({
                                ...values,
                                registerLimit: e.target.value
                            })
                        }} />
                    </StyledElement>
                </Col>
                <Col md={12} >
                    <StyledElement>
                        <p>
                            Devamsızlıktan Kaldımı ?
                        </p>
                        <Select
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    isActive: e
                                })
                            }}

                            value={values.isActive}
                            className='select_element'
                            options={[
                                {
                                    label: "Hayır",

                                    value: true,
                                },
                                {
                                    label: "Evet",
                                    value: false
                                }
                            ]}
                        />
                    </StyledElement>
                </Col>
                <StyledBtnContainer>
                    <Button type='primary' onClick={() => {
                        if (values?.registerLimit < 0) {
                            toast.error("Kayıt hakkı 0 dan az olamaz", {
                                position: "top-center",
                                autoClose: 2500
                            })
                            return;
                        }
                        else if (formik.values?.isActive && !values.isActive) {
                            toast.error("Devamsızlıktan kalmayan öğrenci , devamsızlıktan kaldı olarak işaretlenemez", {
                                position: "top-center",
                                autoClose: 3500
                            })
                            return;
                        }
                        /* else if (formik.values?.isActive == true && values.isActive == false) {
                            toast.error("hata", {
                                position: "top-center",
                                autoClose: 2000
                            })
                        } */
                        else {
                            updateStatus()
                        }
                    }} >
                        Güncelle
                    </Button>
                </StyledBtnContainer>

            </Row>

        </StyledContainer>
    )
}

export default StudenStatusTab

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledElement = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .input_element{
        height: 35px;
    }

    .select_element{
        height: 35px;
    }
`

const StyledBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 10px;
    margin-top: 20px;
`