import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import BlogDashboard from '../../Components/Blog/BlogDashboard'

const BlogPage = () => {
    return (
        <div className='page-content' >
            <TablePageContainer visible={false} header={"Blog"}  >
                <BlogDashboard />
            </TablePageContainer>
        </div>
    )
}

export default BlogPage