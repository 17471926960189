import React from "react";
import { Col } from "reactstrap";
import logo from "../../../src/assets/images/kuzem-logo3.png" 
import { Link } from "react-router-dom";
import styled from "styled-components";

const AuthSlider = () => {
    return (
        <React.Fragment>
 
             <Col lg={6} style={{opacity:"0.8", background: 'linear-gradient(to right, #088D7C, #013E5E)' }}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center",overflow:"hidden", height:"100%",width:"100%"}}>
                  <Link to={"/"}> <StyledImg src={logo} alt=""  /></Link> 
                    
                </div>
             
            </Col>  
        </React.Fragment >
    );
};

export default AuthSlider;


const StyledImg = styled.img`
    max-height: 120px;

    @media screen and (max-width:600px) {
        max-height: 100px;
    }
`