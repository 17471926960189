import { Button, Checkbox, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../../api/axiosInstance'
import styled from 'styled-components'

const PublicNotice = () => {
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        img: null,
        id: ""
    })

    const getPublicNotices = async () => {
        try {
            const response = await axiosInstance.get("/notices/getForWebSite")

            if (response.data.success) {

                let noticeControl = localStorage.getItem(`notice-${response.data.notice?.id}`)

                if (!noticeControl) {
                    setModalInfo({
                        visible: true,
                        img: response.data.notice?.imageUrl,
                        id: response.data?.notice?.id
                    })

                }




            }
            else {
                setModalInfo({
                    visible: false,
                    img: response.data.notice?.imageUrl
                })
            }
        }
        catch (err) {

        }
    }

    useEffect(() => {
        getPublicNotices()
    }, [])


    return (
        <Modal title="Duyuru"
            footer={
                () => {
                    return (
                        <StyledFooter>
                            <div className='check_once' >
                                <Checkbox onChange={(e) => {
                                    if (e.target.checked) {
                                        localStorage.setItem(`notice-${modalInfo.id}`, true)
                                    }
                                    else {
                                        localStorage.removeItem(`notice-${modalInfo.id}`)
                                    }
                                }} >
                                    Bir daha gösterme
                                </Checkbox>
                            </div>
                            <Button danger type='primary' onClick={() => {
                                setModalInfo({
                                    visible: false,
                                    img: null,
                                    id: ""
                                })
                            }} >
                                Kapat
                            </Button>
                        </StyledFooter>
                    )
                }
            }
            open={modalInfo.visible} onCancel={() => {
                setModalInfo({
                    visible: false
                })
            }} >
            <StyledImg src={modalInfo.img} />

        </Modal>
    )
}

export default PublicNotice


const StyledImg = styled.img`
    width: 100%;
    height: 300px;
    object-fit: contain;
    margin: 20px 0;
`

const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .check_once{
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
`