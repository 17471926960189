import { Drawer, Button } from 'antd'
import React from 'react'
import { useState } from 'react';
import { Label } from 'reactstrap'
import styled from 'styled-components';
import { StyledAntSelect } from '../ActiveCourseStyle';
import { useMemo } from 'react';

const FilterDrawer = ({ staticNumber, drawerOpen, setDrawerOpen, filters, setFilters, getCourseList, setActivePageIndex, setSearchTerm, setCourseData, tempData, setPageCount }) => {
    const [courseStatus, setCourseStatus] = useState(false)
    const [semesterFilter, setSemesterFilter] = useState("")
    const [courseYear, setCourseYear] = useState("")
    const showDrawer = () => {
        setDrawerOpen(true)
    };
    const onClose = () => {
        setDrawerOpen(false)
    };
   
    const years = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2010;
        const yearArray = [];
        for (let year = currentYear; year >= startYear; year--) {
            yearArray.push(year);
        }
        return yearArray;
    }, []);


    const confirmFilter = () => {
        let result = tempData
      
        if (filters?.semester) {
           
            result = result?.filter(el => el?.semester == filters?.semester)
            let bolum = result.length / staticNumber
            let kalan = result.length % staticNumber
            if (kalan != 0) {
                bolum += 1
            }
            setPageCount(parseInt(bolum))
            setCourseData(result)
        }
        if (filters?.year) {

            result = result?.filter(item => {
                const tarih = new Date(item?.startDate);
                const yil = tarih.getUTCFullYear();
                return filters?.year == yil
            })
            let bolum = result.length / staticNumber
            let kalan = result.length % staticNumber
            if (kalan != 0) {
                bolum += 1
            }
            setPageCount(parseInt(bolum))
            setCourseData(result)
        }
        if (filters?.year == "" && filters?.semester == "") {
     
            let bolum = result.length / staticNumber
            let kalan = result.length % staticNumber
            if (kalan != 0) {
                bolum += 1
            }
            setPageCount(parseInt(bolum))
            setCourseData(result)
        }
        setSearchTerm("")
        /* let result = tempData.filter(el => el.name.toLowerCase().search(text.toLowerCase()) != -1)
        let bolum = result.length / staticNumber
        let kalan = result.length % staticNumber
        if (kalan != 0) {
            bolum += 1
        }
        setPageCount(parseInt(bolum))
        setCourseData(result) */

    }


    return (
        <StyledDrawer title="Filtrele" placement='right' onClose={onClose} open={drawerOpen}>
            <StyledFilterElementContainer>
                <StyledFilterElement>
                    <Label>
                        Kurs Durumu
                    </Label>
                    <StyledAntSelect
                        value={courseStatus}
                        onChange={(value) => {
                            setCourseStatus(value)

                            getCourseList(0, value)

                            setActivePageIndex(1)
                            setSearchTerm("")
                        }}
                        options={[

                            { value: true, label: 'Tamamlanmış' },
                            { value: false, label: 'Devam eden' },
                        ]}
                    />
                </StyledFilterElement>

                <StyledFilterElement>
                    <Label>
                        Dönem
                    </Label>
                    <StyledAntSelect
                        value={semesterFilter}
                        onChange={(value) => {
                            setSemesterFilter(value)
                            setFilters({
                                ...filters,
                                "semester": value
                            })
                            /* console.log("val => ", value)
                            getCourseList(0, value)
                            setActivePageIndex(1)
                            setSearchTerm("") */
                        }}
                        options={[
                            {
                                value: "", label: "Seçim yapınız"
                            },
                            { value: "Bahar", label: 'Bahar' },
                            { value: "Yaz", label: 'Yaz' },
                            {
                                value: "Güz", label: "Güz"
                            }
                        ]}
                    />
                </StyledFilterElement>
                <StyledFilterElement>
                    <Label>
                        Yıl
                    </Label>
                    <StyledAntSelect
                        value={courseYear}
                        onChange={(value) => {
                            setCourseYear(value)
                            setFilters({
                                ...filters,
                                "year": value
                            })
                            /* console.log("val => ", value)
                            getCourseList(0, value)
                            setActivePageIndex(1)
                            setSearchTerm("") */
                        }}
                        options={
                            [
                                {
                                    "label": "Seçim yapınız",
                                    "value": ""
                                },
                                ...years.map((el) => {
                                    return {
                                        "label": el,
                                        "value": el
                                    }
                                })
                            ]

                        }
                    />
                </StyledFilterElement>

                <Button type='primary' onClick={() => {
                    confirmFilter()
                }} >
                    Filtrele
                </Button>
                <Button danger type='primary' onClick={() => {
                    let result = tempData
                    let bolum = result.length / staticNumber
                    let kalan = result.length % staticNumber
                    if (kalan != 0) {
                        bolum += 1
                    }
                    setPageCount(parseInt(bolum))
                    setCourseData(result)
                    setFilters({
                        "year" : "",
                        "semester": ""
                    })
                    setSemesterFilter("")
                    setCourseYear("")
                }} >
                    Sıfırla
                </Button>
            </StyledFilterElementContainer>

        </StyledDrawer>
    )
}

export default FilterDrawer



const StyledDrawer = styled(Drawer)`
    z-index: 100000 !important;
    top: 0;
`

const StyledFilterElement = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledFilterElementContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`