// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#primaryButton {
  background: #7f56d9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  white-space: nowrap;
  min-width: 6rem; }
  #primaryButton:hover {
    background: gray;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px; }
`, "",{"version":3,"sources":["webpack://./src/Public/components/buttons/buttonPurple.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,4BAA4B;EAC5B,6BAA6B;EAC7B,gCAAgC;EAChC,+BAA+B;EAC/B,YAAY;EACZ,mBAAmB;EACnB,eAAe,EAAA;EARnB;IAWM,gBAAgB;IAChB,4BAA4B;IAC5B,6BAA6B;IAC7B,gCAAgC;IAChC,+BAA+B,EAAA","sourcesContent":["#primaryButton {\n    background: #7f56d9;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n    border-bottom-left-radius: 20px;\n    border: none;\n    white-space: nowrap;\n    min-width: 6rem;\n  \n    &:hover {\n      background: gray;\n      border-top-left-radius: 20px;\n      border-top-right-radius: 20px;\n      border-bottom-right-radius: 20px;\n      border-bottom-left-radius: 20px;\n    }\n  \n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
