import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import CardLesson from '../cardLesson/CardLesson';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import imgCard from "../../../assets/images/imgcard.png";
import imgCard2 from "../../../assets/images/imgcard2.png";
import imgCard3 from "../../../assets/images/imgcard3.png";
import imgCard4 from "../../../assets/images/imgcard4.png";
import imgCard5 from "../../../assets/images/imgcard5.png";
import imgCard6 from "../../../assets/images/imgcard6.png";
import OnlineImg from "../../../assets/images/onlineimage1.png";
import imgProfile from "../../../assets/images/imgProfile.png";
import styled from 'styled-components';
import { Card, CardBody, CardFooter, CardImg, CardText, CardTitle } from 'reactstrap';
import Rating from 'react-rating';
import { FaRegStar, FaStar, FaUser } from 'react-icons/fa';
import ButtonPurple from '../buttons/ButtonPurple';
import { Alert, Skeleton } from 'antd';

const Posts = ({ posts, loading }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (loading) {
    return (
      <Skeleton active={true} />
    )
  }


  const getImg = (item) => {
    if (item?.type == "Online") {
      return item?.url == "0" || item?.url == null || item?.url == undefined ? OnlineImg : item.url
    }
    else {

      if (item?.courseImages?.length == 0 || item?.courseImages == undefined) {

        if (item?.url == "0") {
          return imgCard
        }
        else {
          return item?.url
        }
      }
      else {
        return item?.courseImages[0]?.url
      }

    }


  }


  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {posts.length !== 0 ? posts.map((item, index) => {

        return (
          <StyledCol md={6} lg={4} key={index}>
            <StyledCard onClick={() => {
              // console.log("item => ", item)
              if (item?.type == "Offline") {
                if (item?.branch?.label) {
                  const uri = encodeURIComponent(item?.branch?.label)
                  navigate(`/kurs/${uri}`)
                }
                else {
                  const uri = encodeURIComponent(item?.label)
                  navigate(`/kurs/${uri}`)
                }
              }
              else {
                if (item?.label) {
                  const uri = encodeURIComponent(item?.label)
                  navigate(`/kurs/${uri}`)
                }
                else {
                  const uri = encodeURIComponent(item?.branch?.label)
                  navigate(`/kurs/${uri}`)
                }
              }


            }}  >
              <StyledCardImg variant="top" src={getImg(item)} id='img' />
              <StyledCardBody >
                <CardTitle id='doubleImg' style={{ display: "flex", alignItems: "center" }}>
                  <StyledCardBodyElement >
                    <p className="">
                      {item?.name}
                    </p>
                    <Rating
                      initialRating={3}
                      emptySymbol={<FaRegStar color="#ffc107" size={15} />}
                      fullSymbol={<FaStar color="#ffc107" size={15} />}
                      readonly
                      className="rating"
                    />
                  </StyledCardBodyElement>

                </CardTitle>

              </StyledCardBody>

              <StyledCardFooter >
                <div className="footer_container">
                  <p className='category_item' >
                    {item?.category?.name}
                  </p>
                </div>
              </StyledCardFooter>
            </StyledCard>
          </StyledCol>
        )
      }) : (
        <Alert type='warning' message="Kurs bulunamadı" style={{ width: "100%", textAlign: "center" }} />

      )}
    </div>
  );
};

Posts.propTypes = {
  posts: PropTypes.any,
  loading: PropTypes.any,
};

const StyledCardBody = styled(CardBody)`
`

const StyledCard = styled(Card)`
border: 1px solid transparent;
height: 350px;
cursor: pointer;
/* margin-right: 0 !important; */

    @media screen and (max-width:600px) {
        margin-right: 0px;
    }
`

const StyledCardFooter = styled(CardFooter)`
  width: 100%;
  z-index: -122;
    height: 40px;
    padding: 0;
    padding-right: 10px;
  .footer_container{
    display: flex;
    height: 100%;
    justify-content: flex-end;

    align-items: center;
    .person_count{
      display: flex;
      align-items: center;
      font-size: 16px;
      column-gap: 5px;
    }

    .category_item{
       font-size: 12px;
       color: gray;
       opacity: .3;
       padding: 5px 15px;
       border-radius: 5px;
       /* background-color: gainsboro; */
    }
  }
`
const StyledCardBodyElement = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

const StyledCardImg = styled(CardImg)`
    height: 170px;
    /* object-fit: cover; */
    border-radius:10px;
`

const StyledCol = styled(Col)`
   
   @media screen and (max-width:600px) {
      width: 100%;
   }
`


export default Posts;
