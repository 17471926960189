import { Pagination, Tooltip, Button as AntButton } from 'antd'
import React, { useMemo, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'

const HeaderContainer = styled.div`


`



const SurveyResultModal = ({ resultModalData, setResultModalData }) => {

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0)

  const modalData = useMemo(() => {
    if (resultModalData.data) {
      
      return resultModalData.data[0]
    }
    return null
  }, [resultModalData.data])

  const questionLength = useMemo(() => {
    if (resultModalData.data) {
      return resultModalData.data[0].questions?.length
    }
    return 0
  }, [resultModalData.data])

  const selectedQuestion = useMemo(() => {
    if (modalData?.questions) {

      return modalData?.questions[selectedQuestionIndex]
    }
    return null
  }, [modalData, selectedQuestionIndex])


  const surveyName = useMemo(() => {
    if (resultModalData?.surveyName?.length > 20) {
      return `${resultModalData?.surveyName.substring(0, 20)} ...`
    }
    return resultModalData?.surveyName
  }, [resultModalData])


  const StyledModalHeader = styled(ModalHeader)`
    h5 {
      width: 100%;

      .survey_result_modal_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    }
    }
   `


  return (
    <Modal isOpen={resultModalData.visible} size='lg' centered >
      <StyledModalHeader>
        <div className='survey_result_modal_header' >
          <p style={{ textTransform: "capitalize" }} >
            {surveyName}
          </p>
          <p>
            Anket Sonuçları
          </p>

        </div>
      </StyledModalHeader>
      <ModalBody>
        {
          selectedQuestion ? (
            <div className='survey_result_modal_body'  >
              <Input type='textarea' rows={2} style={{ resize: "none" }} disabled value={selectedQuestion?.question} />
              <div>
                {
                  Object.entries(selectedQuestion.answers).map(([key, val]) => {
                    return (
                      <div className='survey_result_modal_body_option_item' >
                        <div className='survey_result_modal_body_option_item_letter' >
                          {key.split(":")[0]}
                        </div>
                        <Input disabled value={key.split(":")[1]} />
                        <Tooltip title={
                          `Bu seçeneği ${val} kişi işaretledi`
                        } placement='right'  >
                          <div className='survey_result_modal_body_option_item_count' >
                            <FaUser size={18} color='#3577F1' />
                            <span>
                              {val}
                            </span>
                          </div>
                        </Tooltip>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          ) : (
            <div>
              <h5 style={{ textAlign: "center" }} >
                Anket cevabı bulunamadı
              </h5>
            </div>
          )
        }
      </ModalBody>
      <ModalFooter>
        <div className='survey_result_modal_footer'>
          <AntButton danger type='primary' onClick={() => {
            setResultModalData({
              visible: false,
              data: null
            })
            setSelectedQuestionIndex(0)
          }} >
            Kapat
          </AntButton>

          <Pagination pageSize={1} total={questionLength} simple onChange={(e) => {
     
            setSelectedQuestionIndex(e - 1)
          }} />
        </div>

      </ModalFooter>
    </Modal>
  )
}

export default SurveyResultModal