import { Button, Input, Modal, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { FaEdit, FaImage, FaPlus, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { axiosInstance } from '../../api/axiosInstance'

const TopicalDashboard = () => {
    const [loading, setLoading] = useState(false)
    const [topicList, setTopicList] = useState([])
    const [modalInfo, setModalInfo] = useState({
        visible: false,
        editMode: false,
        data: {
            title: "",
            description: ""
        }
    })
    const fileRef = useRef()
    const [topicFile, setTopicFile] = useState({
        name: "",
        file: null
    })

    const handleImage = (e) => {
        const allowedTypes = ['image/jpeg', 'image/png', "image/jpg"];
        const file = e.target.files[0]
        if (allowedTypes.includes(file.type)) {
            setTopicFile({
                name: file.name,
                file: file
            })
        }
        else {
            toast.error("Lütfen fotoğraf seçiniz", {
                position: "top-center",
                autoClose: 2000
            })
        }
    }

    const PreviewTopicImg = () => {
        if (topicFile.file instanceof File) {
            window.open(URL.createObjectURL(topicFile.file), "_blank")
        }
        else {
            window.open(topicFile.file, "_blank")
        }

    }

    const AddTopicFetch = async () => {
        try {
            setLoading(true)
            const formData = new FormData()
            formData.append("formFile", topicFile.file)
            const response = await axiosInstance.post(`/news/Add?title=${modalInfo.data.title}&description=${modalInfo.data.description}`, formData)

            toast.success("İçerik eklendi", {
                position: "top-center",
                autoClose: 2500
            })
            setModalInfo({
                visible: false,
                data: {
                    description: "",
                    title: ""
                },
                editMode: false
            })
            getAllTopic()
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }


    const fetchUpdateTopic = async () => {
        try {
            if (topicFile.file instanceof File) {
                const form = new FormData()
                form.append("formFile", topicFile.file)
                const response = await axiosInstance.put(`/news/update?description=${modalInfo.data.description}&title=${modalInfo.data.title}&id=${modalInfo.data.id}`, form)

                setModalInfo({
                    data: {
                        description: "",
                        title: "",
                        id: ""
                    },
                    editMode: false,
                    visible: false
                })
                toast.success("Güncelleme başarılı", {
                    autoClose: 2000,
                    position: "top-center",

                })
                await getAllTopic()
            }
            else {
                const response = await axiosInstance.put(`/news/update?description=${modalInfo.data.description}&title=${modalInfo.data.title}&id=${modalInfo.data.id}`)
                setModalInfo({
                    editMode: false,
                    visible: false,
                    data: {
                        id: "",
                        description: "",
                        title: ""
                    }
                })
                toast.success("Güncelleme başarılı", {
                    autoClose: 2000,
                    position: "top-center",

                })
                await getAllTopic()
            }
        }
        catch (err) {

        }
        finally {
            setLoading(false)
        }
    }

    const getAllTopic = async () => {
        try {
            const response = await axiosInstance.post("/news/getAll", {
                page: 0,
                pageSize: 100
            })
      
            setTopicList(response.data.items)
        }
        catch (err) {

        }
    }

    useEffect(() => {
        getAllTopic()
    }, [])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Button type='primary' style={{ display: "flex", alignItems: "center", columnGap: "10px" }} onClick={() => {
                        setModalInfo({
                            visible: true,
                            data: null
                        })
                    }} >
                        Ekle <FaPlus />
                    </Button>
                </StyledHeader>
                <StyledContentContainer>
                    <Table
                        style={{
                            width: "100%"
                        }}
                        scroll={{
                            x: 700
                        }}
                        dataSource={topicList}
                        columns={[
                            {
                                title: "İd",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.id}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Başlık",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record.title}
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "İçerik",
                                render: (row, record) => {
                                    return (
                                        <p>
                                            {record?.description?.substring(0, 50)}...
                                        </p>
                                    )
                                }
                            },
                            {
                                title: "Düzenle",
                                render: (row, record) => {
                                    return (
                                        <div style={{ display: "flex", columnGap: "10px" }} >
                                            <Button type='primary' onClick={(e) => {
                                                setModalInfo({
                                                    visible: true,
                                                    editMode: true,
                                                    data: {
                                                        id: record?.id,
                                                        description: record?.description,
                                                        title: record?.title
                                                    }
                                                })
                                                setTopicFile({
                                                    file: record?.imageUrl,
                                                    name: record?.imageUrl
                                                })
                                            }} >
                                                <FaEdit />
                                            </Button>
                                            <Button danger type='primary' onClick={() => {
                                                Modal.confirm({
                                                    title: "Silmek istediğinize emin misiniz ?",
                                                    okText: "Eminim",
                                                    cancelText: "iptal",
                                                    onOk: async () => {
                                                        try {
                                                            await axiosInstance.delete(`/news/delete/${record?.id}`)

                                                            getAllTopic()

                                                            toast.success("Silme işlemi tamamlandı", {
                                                                position: "top-center",
                                                                autoClose: 2000
                                                            })
                                                        }
                                                        catch (err) {

                                                        }
                                                    }
                                                })
                                            }} >
                                                <FaTrash />
                                            </Button>
                                        </div>
                                    )
                                }
                            }
                        ]}
                    />
                </StyledContentContainer>
            </StyledContainer>
            <Modal
                onCancel={() => {
                    setModalInfo({
                        visible: false,
                        editMode: false,
                        data: {
                            id: "",
                            title: "",
                            description: ""
                        }
                    })
                    setTopicFile({
                        name: "",
                        file: null
                    })
                }}
                open={modalInfo.visible}
                title="İçerik Ekle"
                footer={
                    () => {
                        return (
                            <StyledFooter>
                                <Button onClick={() => {
                                    fileRef.current.click()
                                }} type='primary' style={{ display: "flex", alignItems: "center", columnGap: "10px" }} >
                                    Fotoğraf <FaImage />
                                </Button>
                                <div style={{ display: "flex", columnGap: "10px" }} >
                                    <Button danger type='primary' onClick={() => {
                                        setModalInfo({
                                            visible: false,
                                            editMode: false,
                                            data: {
                                                id: "",
                                                title: "",
                                                description: ""
                                            }
                                        })
                                        setTopicFile({
                                            file: null,
                                            name: ""
                                        })
                                    }} >
                                        Kapat
                                    </Button>
                                    <Button onClick={() => {
                                        if (modalInfo.editMode) {
                                            fetchUpdateTopic()
                                        }
                                        else {
                                            AddTopicFetch()
                                        }

                                    }} loading={loading} type='primary' disabled={modalInfo.data?.description == "" || modalInfo.data?.title == "" || !topicFile.file}  >
                                        {loading ? "Yükleniyor" : "Kaydet"}
                                    </Button>
                                </div>
                            </StyledFooter>
                        )
                    }
                }
            >
                <StyledModalContent>
                    <Input placeholder='Başlık' value={modalInfo.data?.title} onChange={(e) => {
                        setModalInfo({
                            ...modalInfo,
                            data: {
                                ...modalInfo.data,
                                title: e.target.value
                            }
                        })
                    }} />
                    <input type="file" style={{ display: "none" }} ref={fileRef} onChange={handleImage} />
                    <Input.TextArea value={modalInfo.data?.description} placeholder='İçerik' style={{ resize: "none" }} rows={6} onChange={(e) => {
                        setModalInfo({
                            ...modalInfo,
                            data: {
                                ...modalInfo.data,
                                description: e.target.value
                            }
                        })
                    }} />
                    {
                        topicFile.file ? (
                            <StyledModalImageContent style={{ cursor: "pointer" }} onClick={() => {
                                PreviewTopicImg()
                            }}  >
                                <FaImage /> {topicFile.name?.substring(0, 35)}...  <Button danger type='primary' size='small' style={{ display: "flex", alignItems: "center" }} onClick={(e) => {
                                    e.stopPropagation()
                                  setTopicFile({
                                        file: null,
                                        name: ""
                                    })
                                }} >
                                    <FaTrash />
                                </Button>
                            </StyledModalImageContent>
                        ) : (
                            <StyledModalImageContent>
                                <p>
                                    Henüz Fotoğraf seçilmedi
                                </p>
                            </StyledModalImageContent>
                        )
                    }
                </StyledModalContent>

            </Modal>
        </>

    )
}

export default TopicalDashboard

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
`

const StyledHeader = styled.div`
    display: flex;
    border-bottom: 1px dashed gray;
    padding-bottom: 10px;
`

const StyledContentContainer = styled.div`
    display: flex;
    margin-top: 20px;

`
const StyledFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap:15px;
`

const StyledModalImageContent = styled.div`
    display: flex;
    border: 1px dashed gray;
    padding: 10px;
    border-radius: 7px;
    align-items: center;
    column-gap: 10px;
`