import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import CreateTeacherForm from '../../Components/Teacher/CreateTeacherForm'
import styled from 'styled-components'

const AddTeacherPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer title='Eğitmen Ekle' header={"Eğitmen Ekle"} visible={false} >
                <StyledContainer>
                    <CreateTeacherForm />
                </StyledContainer>

            </TablePageContainer>
        </div>
    )
}

const StyledContainer = styled.div`
    padding: 20px;
`

export default AddTeacherPage