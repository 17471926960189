// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 1000px) {
  #inputBrans {
    margin-left: 0; } }

@media screen and (max-width: 500px) {
  .search-btn-parent {
    display: flex;
    flex-direction: column; }
  .button-branch-add {
    margin-top: 10px; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Branch/BranchTable.scss"],"names":[],"mappings":"AAAA;EACI;IACI,cAAc,EAAA,EACjB;;AAGL;EACI;IACG,aAAa;IACb,sBAAsB,EAAA;EAEzB;IACI,gBAAgB,EAAA,EAEnB","sourcesContent":["@media screen and(max-width:1000px) {\n    #inputBrans{\n        margin-left: 0;\n    }\n}\n\n@media screen and(max-width:500px) {\n    .search-btn-parent{\n       display: flex;\n       flex-direction: column;\n    }\n    .button-branch-add{\n        margin-top: 10px;\n        \n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
