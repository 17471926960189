import React from 'react'
import TablePageContainer from '../../Components/Common/TablePageContainer'
import PuantajDashboard from '../../Components/PuantajDashboard/PuantajDashboard'

const PuantajPage = () => {
    return (
        <div className='page-content'>
            <TablePageContainer visible={false} header={"Puantaj"} title='Puantaj'  >
                <PuantajDashboard />
            </TablePageContainer>
        </div>
    )
}

export default PuantajPage