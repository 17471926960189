import React, { useContext, useState, useEffect, useMemo } from 'react'
import "./MainNavbar.scss"
import Logo from "../../../assets/images/logo.png"
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { TfiWorld } from 'react-icons/tfi'
import { Form } from "react-bootstrap"
import { FaLaugh } from 'react-icons/fa'
import LanguagePicker from '../LanguagePicker/LanguagePicker'
import { UserContext } from '../../../context/user'
import ProfileDropdown from '../ProfileDropdown/ProfileDropdown'
import { GiHamburgerMenu } from "react-icons/gi";
import { RxHamburgerMenu } from 'react-icons/rx'
import KuzemLogo from "../../../assets/images/KuzemMainLogo.png"
import KuzemBlue from "../../../assets/images/KuzemBlueLogo.png"
import styled from 'styled-components'
import useScrollCheck from '../../../hooks/useScrollCheck'
import { IoCloseCircleSharp } from 'react-icons/io5'
import MobileProfileDropwn from './MobileProfileDropdown/MobileProfileDropdown'
import MobileNavbarMenu from './MobileNavbar/MobileNavbarMenu'



const MainNavbar = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation()

    const [state, dispatch] = useContext(UserContext)

    const navigate = useNavigate()


    const clickHandle = async lang => {
        await i18n.changeLanguage(lang)
    }



    const [toggle, setToggle] = useState(false)
    const clickNav = () => {
        setToggle(!toggle)
    }

    const location = useLocation()
    const [page, setPage] = useState("")
    const isScrolled = useScrollCheck(80);

    const textColor = useMemo(() => {
        if (pathname !== "/") {
            return true
        }
        return false
    }, [pathname])


    const Logos = useMemo(() => {

        if (isScrolled) {
            return KuzemBlue
        }
        return KuzemLogo

    }, [textColor, isScrolled])


    const NavLinkColor = (path) => {
       
        const basePath = location.pathname.split('/').slice(0, 2).join('/');
     
        if (isScrolled) {
            if (path == basePath) {
                return "#8459EB"
            }
            else {
                return "#55557A"
            }
        }
        else {
            if (path == basePath) {
                return "#8459EB"
            }
            else {
                return "white"
            }
        }
    }





    return (
        <>
            <StyledContainer style={{
                backgroundColor: isScrolled ? "white" : "transparent"
            }}   >
                <NavLink to={"/"}><img src={Logos} alt="" id='logo' /></NavLink>
                <div className='navbar_links' >
                    <NavLink to={"/"} className={"navbar_link"} >
                        <StyledNavLink home={NavLinkColor("/")}>{t('Home')}</StyledNavLink>

                    </NavLink>
                    <NavLink to={"/kurumsal"} className={"navbar_link"}  >
                        <StyledNavLink home={NavLinkColor("/kurumsal")}> {t('Kurumsal')}</StyledNavLink>
                    </NavLink>
                    <NavLink to={"/ekitap"} className={"navbar_link navbar_link2 "}   >
                        <StyledNavLink home={NavLinkColor("/ekitap")}> {t('ebook')}</StyledNavLink>
                    </NavLink>
                    <NavLink to={"/egitimler/tumegitimler"} className={"navbar_link"}  >
                        <StyledNavLink home={NavLinkColor("/egitimler")}> {t('education')}</StyledNavLink>
                    </NavLink>
                    <NavLink to={"/guncel"} className={"navbar_link"}  >
                        <StyledNavLink home={NavLinkColor("/guncel")}> {t('news')}</StyledNavLink>
                    </NavLink>
                    <NavLink to={"/blog"} className={"navbar_link"} >
                        <StyledNavLink home={NavLinkColor("/blog")}> {t('blog')}</StyledNavLink>
                    </NavLink>
                    <NavLink to={"/iletisim"} className={"navbar_link"} >
                        <StyledNavLink home={NavLinkColor("/iletisim")}> {t('contact')}</StyledNavLink>
                    </NavLink>
                </div>




                <div className='navbar_btn_container'>
                    {
                        !state.isLoggedIn && (
                            <div style={{ display: "flex", columnGap: "10px" }}>
                                <Button color='primary' id='butonlogin' style={{ color: "white" }} onClick={() => {
                                    navigate("/giris")
                                }}  >
                                    {t('login')}
                                </Button>

                                <Button color='primary' id='butonlogin' style={{
                                    color: "white",
                                    backgroundColor: "#28a745"


                                }} onClick={() => {
                                    navigate("/kayit")
                                }}  >
                                    Kayıt Ol
                                </Button>
                            </div>

                        )
                    }
                    {
                        state.isLoggedIn && (
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }} >
                                <ProfileDropdown />
                            </div>

                        )
                    }

                </div>

                <HamburgerContainer color={isScrolled ? "black" : "white"} >
                    <RxHamburgerMenu className='hamb_icon' onClick={clickNav} />
                    {/*    <GiHamburgerMenu id="hamburger" onClick={clickNav} /> */}

                </HamburgerContainer>

            </StyledContainer>

            {/* ------------------resposive part-------------------
 */}


            <MobileNavbarMenu toggle={toggle} setToggle={setToggle} />

        </>
    )
}

export default MainNavbar


const StyledNavLink = styled.p`
    color: ${props => props.home};
    font-weight: 600;
    font-size: 14px;
    font-family: "Inter",sans-serif;
`



const HamburgerContainer = styled.div`
    display: none;

    @media screen and (max-width:1100px) {
        display: inline-block;
    }

    @media screen and (max-width: 500px) {

        padding-right: 15px;
        display: inline-block;

    }

    .hamb_icon{
        font-size: 25px;
        color: ${props => props.color};
    }
    
`


const StyledContainer = styled.div`
    display: flex;
    padding: 0px 120px;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    background-color: transparent;
    top: 0;
    z-index: 100;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    /* background-color: rgba($color: gray, $alpha: .1); */



    @media screen and (max-width:1025px) {
        padding: 20px 40px;
    }



    @media screen and (max-width:500px) {
        padding: 20px 20px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }



    .navbar_links {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
        padding: 0 120px;

        .navbar_link {
            align-self: center;
        }


        @media screen and (max-width:1025px) {
            display: none;
        }


        @media screen and (max-width:860px) {
            padding: 0 30px;
        }

        @media screen and (max-width:500px) {
            display: none;
        }



    }

    #ogr_panel {
        background-color: $firstColor !important;
        border: none;
        margin-left: 20px;
    }

    .navbar_btn_container {
        display: flex;
        align-items: center;
        /*  margin-left: 30px; */

        @media screen and (max-width:1100px) {
            display: none;
        }

        #profile_dropdown {
            margin-left: 0;

            @media screen and (max-width:500px) {
                display: none;

            }
        }

    }




    .navbar_lang_picker {

        @media screen and (max-width:1100px) {
            display: none;
        }

        @media screen and (max-width:500px) {
            display: none;
        }
    }



`