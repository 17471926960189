import styled from "styled-components";
import { Pagination, Input as AntdInput, Select, Button } from 'antd'

export const ActiveCourseDashboardWrapper = styled.div`
   display: flex;
   flex-direction: column;  
   

   /* @media screen and (max-width:600px) {
    padding: 10px;
   } */

   .active_course_container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        gap: 20px;




        @media screen and (max-width:2000px) {
            grid-template-columns: 1fr 1fr 1fr !important;
        }
        @media screen and (max-width:1200px) {
            grid-template-columns: 1fr 1fr !important;
        }
        @media screen and (max-width:570px) {
            grid-template-columns: 1fr  !important;
            /* margin-left:14%; */
            }
        @media screen and (max-width:400px) {
            /* margin-left:12%; */
            }
        @media screen and (max-width:350px) {
           /*  margin-left:8%; */
            }
        @media screen and (max-width:320px) {
        /*     margin-left:5%; */
            }

        .active_course_item {
            border: 1px solid #D1D5DB;
            border-radius: 10px;
            padding: 0;
            cursor: pointer;

            .active_course_item_content {
                padding-left: 10px;
                padding-top: 8px;

                &_title {
                    font-size: 16px;
                    color: #80949F;
                    text-transform: capitalize;
                    /*   text-transform: capitalize; */
                }

                min-height: 80px;

            }

            .active_course_item_image {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                max-width: 100%;
                min-height: 140px;
            }


            .active_course_item_footer {
                padding: 15px;
                background-color: rgba($color: gray, $alpha: .1);
                display: flex;
                justify-content: space-between;
                align-items: center;

                &_limit {
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 16px;
                        margin-left: 5px;
                    }
                }

                &_semester {
                    display: flex;
                    font-weight: 600;
                }
            }
        }

        @media screen and (max-width:1100px) {
            grid-template-columns: 1fr 1fr 1fr;
        }


        @media screen and (max-width:500px) {
            grid-template-columns: 1fr;

        }
    }

    .active_course_table_pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
`

export const StyledMainCourseContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
`

export const StyledInput = styled(AntdInput)`
    height: 35px;
    width: 200px;

    @media screen and (max-width:500px)  {
        width: 150px;
    }

`

export const StyledFilterBtn = styled(Button)`
    height: 35px;

`


export const StyledAntSelect = styled(Select)`
    height: 35px;
    width: 100%;
    /* margin-left: 20px; */

    @media screen and (max-width:500px)  {
        width: 100%;
        margin-left: 0;
        margin-top: 10px;
    }
    
`



export const StyledHeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    margin-bottom: 20px;
    @media screen  and (max-width:500px) {
        flex-direction: column;

    }
`

export const StyledHeaderFilter = styled.div`
    display: flex;
    align-items: center;    
    width: 100%;
    column-gap: 20px;
    @media screen and (max-width:500px) {
        justify-content: space-between;
        /* flex-direction: column;
        padding: 10px; */
    }
`

export const AddCourseBtn = styled(Button)`


    @media screen  and (max-width:500px) {
            width: 100%;
            margin-top: 10px;
    }
`